import React from "react";
import { fabric } from "fabric";
import { Form } from "react-bootstrap";
import { language, languageGr } from "../../../../api/lang";
import axios from "axios";
import { Buffer } from "buffer";

export const ImageURL = (props, mapId) => {
  const onAddImageFromURL = async (imageURL) => {
    if (imageURL !== "") {
      let imageToBase64;

      try {
        let image = await axios.get(imageURL, { responseType: "arraybuffer" });
        let raw = Buffer.from(image.data).toString("base64");
        imageToBase64 =
          "data:" + image.headers["content-type"] + ";base64," + raw;
      } catch (e) {
        console.log(e);
      }

      if (props.mapId !== null) {
        props.canvas.clear();
      }

      const imageForCanvas = imageToBase64 ?? imageURL;

      fabric.Image.fromURL(imageForCanvas, (img) => {
        img.set({
          scaleX: props.canvas.width / img.width,
          scaleY: props.canvas.height / img.height,
          top: 0,
          left: 0,
          originX: "left",
          originY: "top",
        });
        props.canvas.setBackgroundImage(
          img,
          props.canvas.renderAll.bind(props.canvas)
        );
        props.canvas.renderAll();
      });
    }
  };

  return (
    <>
      <Form>
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label>URL</Form.Label>
          <Form.Control
            type="url"
            placeholder="Paste url here"
            onChange={(e) => onAddImageFromURL(e.target.value)}
          />
        </Form.Group>
      </Form>
    </>
  );
};

export const ImageFromFile = (props) => {
  function onAddImageFromFile(e) {
    if (props.mapId !== null) {
      props.canvas.clear();
    }

    let reader = new FileReader();

    reader.onload = function (event) {
      let imgObj = new Image();
      imgObj.src = event.target.result;
      imgObj.onload = function () {
        let image = new fabric.Image(imgObj);
        // image.scaleToHeight(props.canvas.height);
        props.canvas.setBackgroundImage(
          image,
          props.canvas.renderAll.bind(props.canvas),
          {
            scaleX: props.canvas.width / image.width,
            scaleY: props.canvas.height / image.height,
          }
        );
      };
    };
    reader.readAsDataURL(e.target.files[0]);
  }

  return (
    <>
      <Form>
        <Form.Group controlId="formFile" className="mb-3">
          <Form.Label>
            {props.languageChosenSite === "EL"
              ? languageGr.indoor.topicUpload
              : language.indoor.topicUpload}
          </Form.Label>
          <Form.Control
            type="file"
            name="uploadedImage"
            onChange={(e) => onAddImageFromFile(e)}
          />
        </Form.Group>
      </Form>
    </>
  );
};
