import React, { useEffect, useState, useRef } from "react";

// Fabric
import { fabric } from "fabric";

import ExhibitMarker from "./ExhibitMarker";
import MapItemConfig from "./MapItemConfig";
import { language, languageGr } from "../../api/lang";

// Canvas Objects
import Circle from "./Canvas/Objects/Circle.js";
import Line from "./Canvas/Objects/Line.js";
import Square from "./Canvas/Objects/Square";
import Rectangle from "./Canvas/Objects/Rectangle";
import Triangle from "./Canvas/Objects/Triangle";
import Wall from "./Canvas/Objects/Wall.js";
import Text from "./Canvas/Objects/Text.js";
import MapIcons from "./Canvas/Objects/MapIcons";
import Paint from "./Canvas/Objects/Paint";
import SaveJSON from "./Canvas/Objects/SaveJSON";
import AddNewMap from "./Canvas/Objects/AddNewMap";
import EditMap from "./Canvas/Objects/EditMap";
import DeleteMap from "./Canvas/Objects/DeleteMap";
import ImageAsObject from "./Canvas/Objects/ImageAsObject";
import Preview from "./Canvas/Preview";

// API
import {
  MapsApi,
  LanguageApi,
  CategoryApi,
  SiteApi,
} from "../../api/api.service";

//jQuery
import $ from "jquery";


// React Select
import Select from "react-select";

// Icons
import ImageIcon from "@mui/icons-material/Image";
import categories from "../../images/Sidebar/categories-green.png";
import RectangleIcon from "@mui/icons-material/Rectangle";
import FormatSizeIcon from "@mui/icons-material/FormatSize";
import SmartphoneIcon from "@mui/icons-material/Smartphone";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import TabletAndroidIcon from "@mui/icons-material/TabletAndroid";
import DesktopWindowsIcon from "@mui/icons-material/DesktopWindows";
import ColorLensIcon from '@mui/icons-material/ColorLens';

import DownloadIcon from "@mui/icons-material/Download";
import { ClearAll } from "@mui/icons-material";
import { markerIcon } from "./IconsSVG";
import * as IOIcons from "react-icons/io";
import * as BIcons from "react-icons/bi";


// Flag icons
import greekFlag from "../../images/Languages/greece-circular.png";
import englishFlag from "../../images/Languages/great-britain-circular.png";
import gernamFlag from "../../images/Languages/germany-circular.png";
import spanishFlag from "../../images/Languages/spain2-circular.png";
import italianFlag from "../../images/Languages/italy.png";
import franceFlag from "../../images/Languages/france.png";
import turkishFlag from "../../images/Languages/turkey.png";
import arabicFlag from "../../images/Languages/united-arab-emirates.png";
import chineseFlag from "../../images/Languages/china.png";

// CSS
import "./map.css";
import "./mapProperties.css";

// Bootstrap
import { Row, Col } from "react-bootstrap";

import { addFlagOnMapName } from "./addFlagOnMapName";

const IndoorMap = () => {

  const [canvas, setCanvas] = useState(null);
 
  const [maps, setMaps] = useState([]);
  const [allMaps, setAllMaps] = useState([]);
  const [initMapId, setInitMapId] = useState(false);
  const [selectedMapValues, setSelectedMapValues] = useState([]);
  const [exhibitText, setExhibitText] = useState("");
  const [saveText, setSaveText] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [languages, setLanguages] = useState([]);
  const [lang, setLang] = useState([]);
  const [mapId, setMapId] = useState(null);
  const [wpSize, setWpSize] = useState(35);
  const [markerChosen, setMarkerChosen] = useState(markerIcon.path);
  const [lineMode, setLineMode] = useState();
  const [isDrawingLine, setIsDrawingLine] = useState(false);
  const [markerColor, setMarkerColor] = useState("#111111");
  const [squares, setSquares] = useState(true);
  const [fillColor, setFillColor] = useState("transparent");
  const [fontSize, setFontSize] = useState("25");
  const [languageChosenSite, setLanguageChosenSite] = useState("");
  const [strokeWidth, setStrokeWidth] = useState("0");
  const [configOption, setConfigOption] = useState("");
  const [isClickable, setIsClickable] = useState(false);
  const [fontFamily, setFontFamily] = useState("Times New Roman");
  const [canvasLoaded, setCanvasLoaded] = useState(false);
  const [selectedMap, setSelectedMap] = useState(null);
  const [categoriesByLang, setCategoriesByLang] = useState([]);
  const [showUndo, setShowUndo] = useState(false);
  const canvasRef = useRef(null);
  const whiteboardRef = useRef(null);
  const initLanguage = sessionStorage.getItem("languageChosen");

  useEffect(() => {
    console.log("USE EFFECT AGAIN");
    setLanguageChosenSite(sessionStorage.getItem("languageChosen"));
    getMaps();
    document.body.className = "";

    console.log(whiteboardRef);
    console.log(whiteboardRef.current);
    console.log(whiteboardRef.current.clientWidth);

    if (!canvas && canvasRef.current) {
      const canvas = initCanvas(
        whiteboardRef.current.clientWidth,
        whiteboardRef.current.clientWidth / (16 / 9)
      );
      setCanvas(() => canvas);
      setCanvasLoaded(true);

      handleResize(
        resizeCanvasForDesktop(canvas, whiteboardRef.current)
      ).observe(whiteboardRef.current);
    }

    if (sessionStorage.getItem("languageChosen") === "EL")
      setSaveText(languageGr.indoor.save);
    else setSaveText(language.indoor.save);
    if (document.getElementById("v-pills-map-tab")) {
      document.getElementById("v-pills-map-tab").click();
    }

    console.log("Initialize map on canvas");
    initialMapOnCanvas();
   
  }, [canvasRef, allMaps.length !== 0]);

  const getMaps = async () => {
    let selectLangOptions = [];
    let selectMapOptions = [];
    const lang = await LanguageApi.Get();

    for (let element of lang) {
      selectLangOptions.push({
        value: element.languageId,
        label: element.name,
      });
    }

    setLanguages(selectLangOptions);

    const allMaps = await MapsApi.GetAllMaps();
    setAllMaps(allMaps);

    for (let element of allMaps) {
      console.log(element);
      let langIcon = selectLangOptions
        // .map((lang) =>
        //   lang.value === element.languageid
        //     ? addFlagOnMapsName(lang.value)
        //     : null
        // )
        // .join("");
        .map((lang) =>
          lang.value === element.languageid
            ? addFlagOnMapName(lang.value)
            : null
        )
        .join("");

      selectMapOptions.push({
        value: element.mapId,
        label: (
          <div className="d-flex justify-content-between">
            <div>{element.name}</div>
            <div>
              <img src={langIcon} height="24px" width="24px" />
            </div>
          </div>
        ),
        lang: element.languageid,
        langInitials: langInitials(element.languageid),
      });
    }

    setMaps(selectMapOptions);
  };

  const initialMapOnCanvas = async () => {
    if (initMapId !== null) {
      canvas.loadFromJSON(JSON.parse(allMaps[0].value), function () {
        canvas.renderAll();
      });
      console.log(maps[0]);
      console.log(allMaps[0]);
      setSelectedMap(maps[0]);
      setSelectedMapValues(allMaps[0]);
      setIsClickable(true);
      setTitle(allMaps[0].name);
      setDescription(allMaps[0].description);
      setMapId(maps[0].value);
      onChangeCategory(maps[0].langInitials);
      setInitMapId(maps[0].value);
      setIsClickable(true);

      languages.map((lang) => {
        if (lang.value === maps[0].lang) {
          setLang(lang);
        }
      });
    }
  };

  const setMapOnCanvas = async (option) => {
    setSelectedMap(option);

    if (option !== null) {
      for (let element of allMaps) {
        if (element.mapId === option.value) {
          canvas.loadFromJSON(JSON.parse(element.value), function () {
            canvas.renderAll();
          });

          console.log(element);
          setSelectedMapValues(element);
          setIsClickable(true);
          setTitle(element.name);
          setDescription(element.description);
          setMapId(option.value);
          onChangeCategory(option.langInitials);

          languages.map((lang) => {
            if (lang.value === element.languageid) {
              setLang(lang);
            }
          });
        }
      }
    } else {
      setSelectedMap(null);
      canvas.clear();
      canvas.setBackgroundColor("rgba(236, 236, 236, 0.653)");
      setTitle("");
      setDescription("");
      setIsClickable(false);
      setMapId(null);
      setCategoriesByLang([]);
      setLang([]);
      sessionStorage.setItem("languageChosen", initLanguage);
    }
  };



  const onChangeCategory = async (langInitials) => {
    sessionStorage.setItem("languageChosen", langInitials);

    const categoriesBySelectedMap = await CategoryApi.Get();
    console.log("categoriesBySelectedMap");
    console.log(categoriesBySelectedMap);

    let newCategoryOptions = [];

    if (categoriesBySelectedMap.length !== 0) {
      for (let element of categoriesBySelectedMap) {
        newCategoryOptions.push({
          value: element.categoryId,
          label: element.name,
          translationId: element.translationId,
        });
      }

      setCategoriesByLang(newCategoryOptions);
    }
  };

  const cleanCanvas = () => {
    canvas.remove(...canvas.getObjects());
  };


  //////////////////////////////////////////////////////////////////////////////////////UNDO REDO ///////////////////////////////////////////////////////////////////////////////////////////

       //array to store canvas objects history
//  let canvas_history = [];
//  let s_history = true;
//  let cur_history_index = 0;
 

 
//  function save_history() {
//    //if we already used undo button and made modification - delete all forward history
//    if (cur_history_index < canvas_history.length - 2) {
//       canvas_history = canvas_history.slice(0, cur_history_index + 2);
     

//      cur_history_index++;
//      $('#text_redo').addClass("disabled");
//    }
//    let cur_canvas = JSON.stringify(canvas.toJSON());
//    //if current state identical to previous don't save identical states
//    if (cur_canvas != canvas_history[cur_history_index]) {
//      canvas_history.push(cur_canvas);

//      cur_history_index = canvas_history.length - 1;
//    }

//    console.log('saved ' + canvas_history.length + " " + cur_history_index);

//    $('#text_undo').removeClass("disabled");
//  }


//  function history_undo() {
//    if (cur_history_index > 0) {
//      s_history = false;
//      let canv_data = JSON.parse(canvas_history[cur_history_index - 1 ]);
//      canvas.loadFromJSON(canv_data);
//      cur_history_index--;
//      console.log('undo ' + canvas_history.length + " " + cur_history_index);
//      $('#text_redo').removeClass("disabled");
//    } else {
//      $('#text_undo').addClass("disabled");
//    }
//  }

//  function history_redo() {
//    if (canvas_history[cur_history_index + 1]) {
//      s_history = false;
//      let canv_data = JSON.parse(canvas_history[cur_history_index + 1]);
//      canvas.loadFromJSON(canv_data);
//      cur_history_index++;
//      console.log('redo ' + canvas_history.length + " " + cur_history_index);
//      $('#text_undo').removeClass("disabled");
//    } else {
//      $('#text_redo').addClass("disabled");
//    }
//  }



//  $('#text_undo').click(function(e) {
  
//   console.log(cur_history_index);
// });
// $('#text_redo').click(function(e) {
 
//   console.log(cur_history_index);

// });

// $(function() {

  
//   //let canvas = new fabric.Canvas('cnv');
//   // canvas.backgroundColor = '#ffffff';
//   // canvas.selectionColor = 'rgba(255,255,255,0.3)';
//   // canvas.selectionBorderColor = 'rgba(0,0,0,0.1)';
//   // canvas.hoverCursor = 'pointer';
//   // // canvas.setWidth(300);
//   // // canvas.setHeight(300);
//   // canvas.calcOffset();
//   // canvas.renderAll();


//   //save history on each object's modification
  
   
//     canvas.on('object:modified', function() {
//       save_history();
//     });

   

// });



 //////////////////////////////////////////////////////////////////////////////////////UNDO REDO ///////////////////////////////////////////////////////////////////////////////////////////

 //////////////////////////////////////////////////////////////////////////////////////COPY PASTE///////////////////////////////////////////////////////////////////////////////////////////
 let _clipboard;
 function Copy() {
	// clone what are you copying since you
	// may want copy and paste on different moment.
	// and you do not want the changes happened
	// later to reflect on the copy.
	canvas.getActiveObject().clone(function(cloned) {
		_clipboard = cloned;
	});
}

function Paste() {
	// clone again, so you can do multiple copies.
	_clipboard.clone(function(clonedObj) {
		canvas.discardActiveObject();
		clonedObj.set({
			left: clonedObj.left + 10,
			top: clonedObj.top + 10,
			evented: true,
		});
		if (clonedObj.type === 'activeSelection') {
			// active selection needs a reference to the canvas.
			clonedObj.canvas = canvas;
			clonedObj.forEachObject(function(obj) {
				canvas.add(obj);
			});
			// this should solve the unselectability
			clonedObj.setCoords();
		} else {
			canvas.add(clonedObj);
		}
		_clipboard.top += 10;
		_clipboard.left += 10;
		canvas.setActiveObject(clonedObj);
		canvas.requestRenderAll();
	});
}

 //////////////////////////////////////////////////////////////////////////////////////COPY PASTE///////////////////////////////////////////////////////////////////////////////////////////


 //////////////////////////////////////////////////////////////////////////////////////LAYERING///////////////////////////////////////////////////////////////////////////////////////////
// console.log(canvas);
//  (function() {
//   if(canvas !=null) {
//     canvas.on('mouse:move', function(options) {

//       var p = canvas.getPointer(options.e);

//       canvas.forEachObject(function(obj) {
//         var distX = Math.abs(p.x - obj.left),
//             distY = Math.abs(p.y - obj.top),
//             dist = Math.round(Math.sqrt(Math.pow(distX, 2) + Math.pow(distY, 2)));

//         obj.set('opacity', 1 / (dist / 317));
//       });

//       canvas.renderAll();
//     });

//     canvas.on('object:selected', function(options) {

//       var p = canvas.getPointer(options.e);

//       canvas.forEachObject(function(obj) {
//         var distX = Math.abs(p.x - obj.left),
//             distY = Math.abs(p.y - obj.top),
//             dist = Math.round(Math.sqrt(Math.pow(distX, 2) + Math.pow(distY, 2)));

//         obj.set('opacity', 1);
//       });

//       canvas.renderAll();
//     });
//   }
    
// })();



  (function() {
    
    if(canvas != null) {

      
      canvas.on({
        'object:moving': onChange,
        'object:scaling': onChange,
        'object:rotating': onChange,
      });
  
      function onChange(options) {
        options.target.setCoords();
        canvas.forEachObject(function(obj) {
          if (obj === options.target) return;
          obj.set('opacity' ,options.target.intersectsWithObject(obj) ? 0.5 : 1);
        });
      }
    } 

    
  })();



 //////////////////////////////////////////////////////////////////////////////////////LAYERING///////////////////////////////////////////////////////////////////////////////////////////



  // const addFlagOnMapsName = (langId, langName) => {
  //   if (langId === 1) return greekFlag;
  //   else if (langId === 2) return englishFlag;
  //   else if (langId === 3) return gernamFlag;
  //   else if (langId === 11) return spanishFlag;
  //   else if (langId === 16) return franceFlag;
  //   else if (langId === 17) return arabicFlag;
  //   else if (langId === 18) return chineseFlag;
  //   else if (langId === 21) return turkishFlag;
  //   else if (langId === 23) return italianFlag;
  //   else return langName;
  // };

  const langInitials = (langId) => {
    if (langId === 1) return "EL";
    else if (langId === 2) return "EN";
    else if (langId === 3) return "DE";
    else if (langId === 11) return "ES";
    else if (langId === 16) return "FR";
    else if (langId === 17) return "AR";
    else if (langId === 18) return "ZH";
    else if (langId === 21) return "TR";
    else if (langId === 23) return "IT";
    else return null;
  };

  return (
    <>
      <Row>
        <Col sm={3} style={{ paddingTop: 0 }}>
          <h5 className="fw-light text-center">
            {languageChosenSite === "EL"
              ? languageGr.indoor.options
              : language.indoor.options}
          </h5>
          <div className="editor-left-sidebar">
            <div className="editor-items">
              <div className="d-flex align-items-start"></div>

              <div
                className="d-flex align-items-start"
                style={{ justifyContent: "space-between" }}
              >
                <div
                  className="nav flex-column nav-pills me-3 leftSidebar"
                  id="v-pills-tab"
                  role="tablist"
                  aria-orientation="vertical"
                >
                  <button
                    className="nav-link  borderBottom mapProperties"
                    id="v-pills-map-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-home"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-home"
                    aria-selected="true"
                    onClick={() => {
                      setConfigOption("map");
                    }}
                  >
                    <ImageIcon />
                  </button>
                  <button
                    className="nav-link borderBottom mapProperties"
                    id="v-pills-exhibits-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-profile"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-profile"
                    aria-selected="false"
                    onClick={() => {
                      setConfigOption("marker");
                    }}
                  >
                    {/* <PersonIcon /> */}
                    <img style={{ width: "3vh" }} src={categories} />
                  </button>
                  <button
                    className="nav-link borderBottom mapProperties"
                    id="v-pills-shapes-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-messages"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-messages"
                    aria-selected="false"
                    onClick={() => {
                      setConfigOption("marker");
                    }}
                  >
                    <RectangleIcon />
                  </button>
                  <button
                    className="nav-link borderBottom mapProperties"
                    id="v-pills-text-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-settings"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-settings"
                    aria-selected="false"
                    onClick={() => {
                      setConfigOption("text");
                    }}
                  >
                    <FormatSizeIcon />
                  </button>

                  <button
                    className="nav-link borderBottom mapProperties"
                    id="v-pills-marker-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-marker"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-marker"
                    aria-selected="false"
                    onClick={() => {
                      setConfigOption("marker");
                    }}
                  >
                    <NewReleasesIcon />
                  </button>
                  <button
                    className="nav-link borderBottom mapProperties"
                    id="v-pills-paint-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-paint"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-paint"
                    aria-selected="false"
                    onClick={() => {
                      setConfigOption("paint");
                    }}
                  >
                    <ColorLensIcon />
                  </button>
                </div>
                <div
                  className="tab-content"
                  id="v-pills-tabContent"
                  style={{ marginTop: "9%", paddingRight: "7%" }}
                >
                  <div
                    className="tab-pane fade show"
                    id="v-pills-home"
                    role="tabpanel"
                    aria-labelledby="v-pills-map-tab"
                  >
                    <ImageAsObject
                      canvas={canvas}
                      languageChosenSite={languageChosenSite}
                    />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="v-pills-profile"
                    role="tabpanel"
                    aria-labelledby="v-pills-exhibits-tab"
                    aria-selected="true"
                  >
                    <ExhibitMarker
                      canvas={canvas}
                      iconColor={markerColor}
                      languageChosenSite={languageChosenSite}
                      fontSize={fontSize}
                      setIconColor={setMarkerColor}
                      setFontSize={setFontSize}
                      fillColor={fillColor}
                      setFillColor={setFillColor}
                      iconPath={markerChosen}
                      setMarkerChosen={setMarkerChosen}
                      exhibitText={exhibitText}
                      setExhibitText={setExhibitText}
                      strokeWidth={strokeWidth}
                      setStrokeWidth={setStrokeWidth}
                      fontFamily={fontFamily}
                      setFontFamily={setFontFamily}
                      categoriesByLang={categoriesByLang}
                      setSquares={setSquares}
                      setShowUndo={setShowUndo}

                    />
                  </div>

                  <div
                    className="tab-pane fade mapPropertiesOptions"
                    id="v-pills-messages"
                    role="tabpanel"
                    aria-labelledby="v-pills-shapes-tab"
                  >
                    <div className="mapPropertiesOptions">
                      <Circle
                        wpSize={wpSize}
                        canvas={canvas}
                        languageChosenSite={languageChosenSite}
                        iconColor={markerColor}
                        setIconColor={setMarkerColor}
                        fillColor={fillColor}
                        setFillColor={setFillColor}
                        strokeWidth={strokeWidth}
                        setStrokeWidth={setStrokeWidth}
                        setSquares={setSquares}
                        setShowUndo={setShowUndo}
                      />

                      <Triangle
                        canvas={canvas}
                        iconColor={markerColor}
                        fillColor={fillColor}
                        languageChosenSite={languageChosenSite}
                        setIconColor={setMarkerColor}
                        setFillColor={setFillColor}
                        strokeWidth={strokeWidth}
                        setStrokeWidth={setStrokeWidth}
                        setSquares={setSquares}
                      />

                      <Square
                        canvas={canvas}
                        iconColor={markerColor}
                        languageChosenSite={languageChosenSite}
                        setIconColor={setMarkerColor}
                        fillColor={fillColor}
                        setFillColor={setFillColor}
                        setSquares={setSquares}
                      />

                      <Rectangle
                        canvas={canvas}
                        iconColor={markerColor}
                        languageChosenSite={languageChosenSite}
                        setIconColor={setMarkerColor}
                        fillColor={fillColor}
                        setFillColor={setFillColor}
                        setSquares={setSquares}
                      />

                      <Line
                        wpSize={wpSize}
                        canvas={canvas}
                        iconColor={markerColor}
                        languageChosenSite={languageChosenSite}
                        setIconColor={setMarkerColor}
                        fillColor={fillColor}
                        setFillColor={setFillColor}
                        setSquares={setSquares}
                      />
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="v-pills-settings"
                    role="tabpanel"
                    aria-labelledby="v-pills-text-tab"
                  >
                    <Text
                      canvas={canvas}
                      iconColor={markerColor}
                      languageChosenSite={languageChosenSite}
                      fontSize={fontSize}
                      setIconColor={setMarkerColor}
                      setFontSize={setFontSize}
                      fillColor={fillColor}
                      setFillColor={setFillColor}
                      exhibitText={exhibitText}
                      setExhibitText={setExhibitText}
                      fontFamily={fontFamily}
                      setFontFamily={setFontFamily}
                      setSquares={setSquares}
                    />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="v-pills-wall"
                    role="tabpanel"
                    aria-labelledby="v-pills-wall-tab"
                  >
                    <Wall
                      isDrawingLine={isDrawingLine}
                      lineMode={lineMode}
                      canvas={canvas}
                    />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="v-pills-marker"
                    role="tabpanel"
                    aria-labelledby="v-pills-marker-tab"
                  >
                    <MapIcons
                      canvas={canvas}
                     
                      iconColor={markerColor}
                      setIconColor={setMarkerColor}
                      languageChosenSite={languageChosenSite}
                    />
                  </div>

                  <div
                    className="tab-pane fade"
                    id="v-pills-paint"
                    role="tabpanel"
                    aria-labelledby="v-pills-paint-tab"
                  >
                    <Paint
                      canvas={canvas}
                      iconColor={markerColor}
                      languageChosenSite={languageChosenSite}
                      fontSize={fontSize}
                      setCanvas={setCanvas}
                      setIconColor={setMarkerColor}
                      setFontSize={setFontSize}
                      fillColor={fillColor}
                      setFillColor={setFillColor}
                      exhibitText={exhibitText}
                      setExhibitText={setExhibitText}
                      fontFamily={fontFamily}
                      setFontFamily={setFontFamily}
                      setSquares={setSquares}
                    />
                  </div>

                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col sm={7}>
          <div ref={whiteboardRef}>
            <h5 className="fw-light text-center">
              {languageChosenSite === "EL"
                ? languageGr.indoor.canvas
                : language.indoor.canvas}
            </h5>
            <Row>
              <Col xl={4} lg={7} md={12} className="mb-2">
                <Select
                  className="custom-react-select"
                  options={maps}
                  value={selectedMap}
                  onChange={setMapOnCanvas}
                  placeholder={
                    languageChosenSite === "EL"
                      ? languageGr.indoor.selectMap
                      : language.indoor.selectMap
                  }
                  isSearchable
                  // isClearable
                />
              </Col>
              <Col xl={4} lg={6} md={12} className="mb-2">
                <div
                  className="d-flex 
                  justify-content-xl-center 
                  justify-content-lg-start 
                  justify-content-md-start"
                >
                  <AddNewMap
                    setMapId={setMapId}
                    setTitle={setTitle}
                    setDescription={setDescription}
                    setLang={setLang}
                    maps={maps}
                    setSaveText={setSaveText}
                    setIsClickable={setIsClickable}
                    cleanCanvas={cleanCanvas}
                    getMaps={getMaps}
                    selectedMap={selectedMap}
                    setSelectedMap={setSelectedMap}
                    allMaps={allMaps}
                    // setExistingMapOnCanvas={setExistingMapOnCanvas}
                    setMapOnCanvas={setMapOnCanvas}
                    initMapId={initMapId}
                    mapId={mapId}
                    languages={languages}
                    languageChosenSite={languageChosenSite}
                    canvas={canvas}
                    lang={lang}
                  />
                  <EditMap
                    setTitle={setTitle}
                    setDescription={setDescription}
                    setLang={setLang}
                    getMaps={getMaps}
                    setSelectedMap={setSelectedMap}
                    title={title}
                    description={description}
                    languages={languages}
                    
                    lang={lang}
                    canvas={canvas}
                    languageChosenSite={languageChosenSite}
                    isClickable={isClickable}
                    mapId={mapId}
                  />
                  <DeleteMap
                    setMapId={setMapId}
                    getMaps={getMaps}
                    setSelectedMap={setSelectedMap}
                    setIsClickable={setIsClickable}
                    canvas={canvas}
                    title={title}
                    mapId={mapId}
                    languages={languages}
                    languageChosenSite={languageChosenSite}
                    isClickable={isClickable}
                  />
                  <button
                    className="responsive-btn"
                    onClick={resizeCanvasForMobile(canvas)}
                  >
                    <SmartphoneIcon />
                  </button>
                  {/* <button
                    className="responsive-btn"
                    onClick={resizeCanvasForTablet(canvas)}
                  >
                    <TabletAndroidIcon />
                  </button> */}
                  <button
                    className="responsive-btn"
                    onClick={resizeCanvasForDesktop(
                      canvas,
                      whiteboardRef.current
                    )}
                  >
                    <DesktopWindowsIcon />
                  </button>
                  <Preview
                    canvas={canvas}
                    languageChosenSite={languageChosenSite}
                  />
                </div>
              </Col>
              <Col xl={4} lg={6} md={12} className="mb-2">
                <div
                  className="d-flex 
                  justify-content-xl-end 
                  justify-content-lg-end 
                  justify-content-center"
                >
                  <SaveJSON
                    getMaps={getMaps}
                    selectedMapValues={selectedMapValues}
                    canvas={canvas}
                    title={title}
                    description={description}
                    mapId={mapId}
                    lang={lang}
                    saveText={saveText}
                    isClickable={isClickable}
                    languageChosenSite={languageChosenSite}
                  />
                  <button className="clean-canvas-btn" onClick={cleanCanvas}>
                    <ClearAll />
                    <br />
                    {languageChosenSite === "EL"
                      ? languageGr.indoor.clean
                      : language.indoor.clean}
                  </button>
                </div>
              </Col>
            </Row>
          </div>
          <div className="c">
            <canvas ref={canvasRef} id="canvas" className="canvas-a" />
          </div>
        </Col>
        <Col sm={2}>
          <h5 className="fw-light text-center">
            {languageChosenSite === "EL"
              ? languageGr.indoor.configuration
              : language.indoor.configuration}
          </h5>
          
          {/* <div className="redo">
           
            <div>
             
              <button title="Undo" id="text_undo" className="disabled btn btn-warning btn-md" onClick={history_undo}><IOIcons.IoIosUndo /></button>
            </div>
            <div>
              
              <button title="Redo" id="text_redo" className="disabled btn btn-warning btn-md" onClick={history_redo}><IOIcons.IoIosRedo /></button>
            </div>
            
          </div> */}
          <div className="copyPaste">
          <div>
              <p></p>
              <button title="Copy" id="copy" className="btn copyPasteBtn" onClick={Copy}><BIcons.BiCopy /></button>
            </div>
            <div>
              <p></p>
              <button id="paste" title="Paste" className="btn copyPasteBtn" onClick={Paste}><BIcons.BiPaste /></button>
            </div>
          </div>
          {/* <br /> */}

          <MapItemConfig
            option={configOption}
            markerColor={markerColor}
            setMarkerColor={setMarkerColor}
            canvas={canvas}
            languageChosenSite={languageChosenSite}
            fontSize={fontSize}
            setFontSize={setFontSize}
            fillColor={fillColor}
            setFillColor={setFillColor}
            iconPath={markerChosen}
            setMarkerChosen={setMarkerChosen}
            exhibitText={exhibitText}
            setExhibitText={setExhibitText}
            strokeWidth={strokeWidth}
            setStrokeWidth={setStrokeWidth}
            fontFamily={fontFamily}
            setFontFamily={setFontFamily}
            // setSquares={setSquares}
            squares={squares}
          />
        </Col>
      </Row>
    </>
  );
};

export default IndoorMap;

const initCanvas = (width, height) => {
  const canvas = new fabric.Canvas("canvas", {
    height,
    width,
    backgroundColor: "rgba(236, 236, 236, 0.653)",
    selectionLineWidth: 2,
    enableRetinaScaling: true,
  });
  return canvas;
};

function handleResize(callback) {
  const resize_ob = new ResizeObserver(callback);

  return resize_ob;
}

function resizeCanvasForDesktop(canvas, whiteboard) {
  return () => {
    const ratio = canvas.getWidth() / canvas.getHeight();
    const whiteboardWidth = whiteboard.clientWidth;

    const scale = whiteboardWidth / canvas.getWidth();

    const zoom = canvas.getZoom() * scale;
    canvas.setDimensions({
      width: whiteboardWidth,
      height: whiteboardWidth / ratio,
    });

    canvas.setViewportTransform([zoom, 0, 0, zoom, 0, 0]);

    console.log("scale: " + scale);
    console.log("width: " + whiteboardWidth);
    console.log("height: " + whiteboardWidth / ratio);
  };
}

function resizeCanvasForMobile(canvas) {
  return () => {
    const ratio = canvas.getWidth() / canvas.getHeight();
    const whiteboardWidth = 650;

    const scale = whiteboardWidth / canvas.getWidth();

    const zoom = canvas.getZoom() * scale;
    canvas.setDimensions({
      width: whiteboardWidth,
      height: whiteboardWidth / ratio,
    });
    canvas.setViewportTransform([zoom, 0, 0, zoom, 0, 0]);

    console.log("scale: " + scale);
    console.log("width: " + whiteboardWidth);
    console.log("height: " + whiteboardWidth / ratio);
  };
}

function downloadCanvasToJSON(canvas) {
  return () => {
    let mapToJSON = JSON.stringify(canvas.toJSON());
    let data = "data:text/json;charset=utf-8," + encodeURIComponent(mapToJSON);
    let downloadAnchorNode = document.createElement("a");

    downloadAnchorNode.setAttribute("href", data);
    downloadAnchorNode.setAttribute("download", "canvas" + ".json");
    document.body.appendChild(downloadAnchorNode); // required for firefox
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
  };
}




