import React, { useState } from "react";
import { fabric } from "fabric";
import { Modal, Form } from "react-bootstrap";
import { language, languageGr } from "../../../../api/lang";
import ImageIcon from "@mui/icons-material/Image";

function ImageAsObject(props) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const onAddImageFromURL = (imageURL) => {
    fabric.Image.fromURL(imageURL, (img) => {
      img.scaleToHeight(props.canvas.height);
      props.canvas.add(img);
      props.canvas.renderAll();
    });
  };

  function onAddImageFromFile(e) {
    let reader = new FileReader();

    reader.addEventListener("load", () => {
      fabric.Image.fromURL(reader.result, (img) => {
        img.scaleToHeight(props.canvas.height);
        props.canvas.add(img);
      });
    });

    reader.readAsDataURL(e.target.files[0]);
    handleClose();
  }

  return (
    <div>
      <button
        className="mapPropertiesItem"
        style={{ width: "100%" }}
        onClick={handleShow}
      >
        <ImageIcon />
        {props.languageChosenSite === "EL"
          ? languageGr.indoor.addImageAsObject
          : language.indoor.addImageAsObject}
      </button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {props.languageChosenSite === "EL"
              ? languageGr.indoor.addImageAsObject
              : language.indoor.addImageAsObject}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>URL</Form.Label>
              <Form.Control
                type="url"
                placeholder="Paste url here"
                onChange={(e) => onAddImageFromURL(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formFile" className="mb-3">
              <Form.Label>
                {props.languageChosenSite === "EL"
                  ? languageGr.indoor.topicUpload
                  : language.indoor.topicUpload}
              </Form.Label>
              <Form.Control
                type="file"
                name="uploadedImage"
                onChange={(e) => onAddImageFromFile(e)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <button className="close-modal-button " onClick={handleClose}>
            {props.languageChosenSite === "EL"
              ? languageGr.indoor.close
              : language.indoor.close}
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ImageAsObject;
