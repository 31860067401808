import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import React, { useState, useEffect } from "react";

import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
//import Skeleton from 'react-loading-skeleton';
// import 'react-loading-skeleton/dist/skeleton.css';
import { ExhibitNew, PresentationApi, LanguageApi, SiteApi } from "../../api/api.service";

import CardSkeleton from "./CardSkeleton";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import ModalParent from './ModalParent'
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";

//import ToolkitProvider, {CSVExport} from "react-bootstrap-table2-toolkit";
import ModalB from "./Modal";
//import axios from "axios";


import BootstrapTable from "react-bootstrap-table-next";
import * as FaIcons from "react-icons/fa";
import Swal from "sweetalert2";



const TableGeneral = (props) => {
  
  //const [modalShow, setModalShow] = useState(false);
  const [isLoading, setIsLoading]  = useState(true);
  const [searchFilter, setSearchFilter]  = useState('');
  const [data, setData] = useState(props.data);
  // const [idClicked, setIdClicked] = useState(null);
  // const [data, setData] = useState([]);
  // const [deleteId, setDeleteId] = useState(null);
  // const [checkedList, setCheckedList] = useState([]);
  // const [languages, setLanguages] = useState([]);
  // const [trans, setTrans] = useState([]);
  // const [translations, setTranslations] = useState([]);
  
  useEffect(() => {
    Loader();
    
  }, [props.data]);
  //"https://jsonplaceholder.typicode.com/comments"
  const Loader =  () => {
    
    setIsLoading(false);
    
   
  };

 //let targetEdit;
  //let target;

  const pagination = paginationFactory({
    page: 1,
    sizePerPage: 10,
    lastPageText: ">>",
    firstPageText: "<<",
    nextPageText: ">",
    prePageText: "<",
    showTotal: true,
    alwaysShowAllBtns: true,
    onPageChange: function (page, sizePerPage) {
      console.log("page", page);
      console.log("sizePerPage", sizePerPage);
    },
    onSizePerPageChange: function (page, sizePerPage) {
      console.log("page", page);
      console.log("sizePerPage", sizePerPage);
    },
  });



  // const editClick = async (e) => {
  //   setModalShow(true);
  //   console.log(modalShow);
    
  //   targetEdit = e.target.parentElement.parentElement.children[0].innerText;
  //   console.log(targetEdit);
  //   let translationA = await PresentationApi.GetByTranslation(deleteId);
  //   setTranslations(translationA);
  //   //setTrans(translations);
  //   //editableObject = await PresentationApi.GetById(parseInt(targetEdit));
  //   //console.log(editableObject.presentationId);
  //   //typeof editableOb === "NaN" ? console.log("not a number") :  setBody(editableOb);
  //   //setBody(editableObject)
    
    
    
    
  //   // console.log(targetElement);
  //   //setIdClicked(e.target.parentElement.parentElement.children[0].innerHTML);
    
  //   console.log(translations);
    
    
    
  // }


  // const edit = (cell, row, rowIndex, formatExtraData) => {
  //   return (
      
  //     <FaIcons.FaRegEdit
        
  //       id={row.presentationId}
  //       onClick={editClick}
  //       Edit
  //     />
      
  //   );
  // };

  // function onRowSelect(row, isSelected, e) {
    
  //   if(!isSelected) {
  //     console.log(e);
      
  //     console.log(data[e]);
  //     setCheckedList((prevState) => [...prevState, data[e].objectS_ID]);
  //   }
  //   let rowStr = '';
  //   for (const prop in row) {
  //     rowStr += prop + ': "' + row[prop] + '"';
  //   }
    
  //  console.log(checkedList);
  // }

  // const deleteClick =  (e) => {
  //   //e.stopPropagation();
  //   console.log(e);
  //   // let target = e.target.parentElement.parentElement.children[0].innerText;
  //   // let targetElement = e.target.parentElement.parentElement;
  //   // console.log(targetElement);
  //   // setDeleteId(target);
  //   console.log(e.target);
  //   Swal.fire({
  //     title: 'Are you sure?',
  //     text: `You are about to delete the presentation with id ${idClicked}`,
  //     icon: 'warning',
  //     showCancelButton: true,
  //     confirmButtonColor: '#3085d6',
  //     cancelButtonColor: '#d33',
  //     confirmButtonText: 'Go to Hell'
  //   }).then(async (result) => {
  //     if (result.isConfirmed) {
  //       //const deleted = await PresentationApi.PresentationDelete(parseInt(target));
  //       //removeFadeOut(targetElement, 600);

  //       Swal.fire(
  //         'Deleted!',
  //         'Your file has been sent to hell.',
  //         'success'
  //       )
  //     }
  //   })
  //   //console.log(deleteId);
  // }

  // const del = (cell, row, rowIndex, formatExtraData) => {
  //   return (
  //     <FaIcons.FaRegTrashAlt
        
  //       onClick={deleteClick}
  //       Delete
  //     />
  //   );
  // };

  // const selectRowProp = {
  //   mode: 'checkbox',
  //   // clickToSelect: true,
  //   // clickToExpand: true,
  //   hideSelectAll: true,
  //   onSelect: onRowSelect,
  //   //onSelectAll: onSelectAll
  // };

  // const rowEvents = {
  //   onClick: (e, row, rowIndex) => {
  //     // console.log(e);
  //     // console.log({ row, rowIndex });
      
  //     console.log(row.presentationId);
  //     setIdClicked(row.presentationId);
  //     //setCheckedList((prevState) => [...prevState, row.objectS_ID]);
  //     setDeleteId(row.presentationId);
  //   }
  // };




//   const editClick = () => {
//     setModalShow(!modalShow);
//     console.log(modalShow);
//   }

//   const deleteClick = () => {
//     setModalShow(!modalShow);
//     console.log(modalShow);
//   }

//   const edit = (cell, row, rowIndex, formatExtraData) => {
//     return (
//       <FaIcons.FaRegEdit
//         onClick={editClick}
//         Edit
//       />
//     );
//   };

//   const del = (cell, row, rowIndex, formatExtraData) => {
//     return (
//       <FaIcons.FaRegTrashAlt
//         onClick={deleteClick}
//         Delete
//       />
//     );
//   };
  // let searchArray = [];
  let searchArray = data;
  let newArray = [];
  const onChangeSearchFilter = (e) => {
    console.log(e.target.value);
    setSearchFilter(e.target.value);
    newArray = data.filter(d=> {
      console.log(d);
      let searchValue = d.name.toLowerCase();
      return e.target.value !=="" ? searchValue.indexOf(e.target.value) !== -1 : setData(props.data);
      

    });
    // console.log(newArray);
    // setData(newArray);
  }
  //const { SearchBar } = Search;
  

  return (
    <>
    
    {/* {modalShow && (<ModalParent show={modalShow} onSetModalShow={setModalShow} targetId={idClicked} languages={languages} rowEvents={props.rowEvents} ></ModalParent>)} */}
      <div style={{ marginTop: "10vh" }}>
      {/* <ToolkitProvider
          bootstrap4
          keyField="id"
          data={data}
          columns={props.columns}
          search
        > */}


       
        <div className="col-10 offset-1">
              {/* <input type="text" onChange={onChangeSearchFilter} placeholder="Search.." value={searchFilter} />
              */}
            <BootstrapTable
              keyField="id"
              data={props.data }
              rowEvents={props.rowEvents}
              columns={props.columns}
              selectRow={props.selectRow}

             
              striped
              bootstrap4
              hover
              pagination={pagination}
              filter={filterFactory()}
              
            /> 

        
        </div>
        {/* </ToolkitProvider> */}
      </div>
    </>
  );
};

export default TableGeneral;
