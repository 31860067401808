import { Fragment } from "react";

const LoginLoading = ({show}) => {
  return (
    <Fragment>
      <svg
        id="loadingBtn"
        visibility={show ? "visible" : "hidden"}
        style={{ marginRight: -58 }}
        width="60px"
        height="55px"
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid"
      >
        <circle
          cx="50"
          cy="50"
          r="32"
          stroke-width="8"
          stroke="#ffffff"
          stroke-dasharray="50.26548245743669 50.26548245743669"
          fill="none"
          stroke-linecap="round"
          transform="rotate(279.375 50 50)"
        >
          <animateTransform
            attributeName="transform"
            type="rotate"
            dur="2.0833333333333335s"
            repeatCount="indefinite"
            keyTimes="0;1"
            values="0 50 50;360 50 50"
          ></animateTransform>
        </circle>
        <circle
          cx="50"
          cy="50"
          r="23"
          stroke-width="8"
          stroke="#484a54"
          stroke-dasharray="36.12831551628262 36.12831551628262"
          stroke-dashoffset="36.12831551628262"
          fill="none"
          stroke-linecap="round"
          transform="rotate(-279.375 50 50)"
        >
          <animateTransform
            attributeName="transform"
            type="rotate"
            dur="2.0833333333333335s"
            repeatCount="indefinite"
            keyTimes="0;1"
            values="0 50 50;-360 50 50"
          ></animateTransform>
        </circle>
      </svg>
    </Fragment>
  );
};

export default LoginLoading;
