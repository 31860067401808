import { useState, useEffect, Fragment } from "react";

// API
import {
  CategoryApi,
  LanguageApi,
  SiteApi,
  MediaApi,
} from "../../api/api.service";
import { language, languageGr } from "../../api/lang";

// Bootstrap
import { Modal, Form, Button, Row, Col } from "react-bootstrap";
import { textFilter } from "react-bootstrap-table2-filter";

// Components
import CardSkeleton from "../UI/CardSkeleton";
import TableGeneral from "../UI/TableGeneral";
import BundledEditor from "../../BundledEditor";
import CatModal from "../UI/CatModal";

// Swal Alert
import Swal from "sweetalert2";

//Authservice
import { authService } from "../../api/auth.service";

// Icons
import * as FaIcons from "react-icons/fa";
import manage from "../../images/manage-orange.png";

// Jquery
import $ from "jquery";

// CSS
import "../../css/components/style.css";

const Category = () => {
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [modalShow, setModalShow] = useState(false);
  const [idClicked, setIdClicked] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const [checkedList, setCheckedList] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [sites, setSites] = useState([]);
  const [galleryChooseImage, setGalleryChooseImage] = useState(false);
  const [trans, setTrans] = useState([]);
  const [translations, setTranslations] = useState([]);
  const [addBox, setAddBox] = useState(false);
  const [languageChosenSite, setLanguageChosenSite] = useState("");
  const [tableChanged, setTableChanged] = useState(false);
  const [toggleParentCat, setToggleParentCat] = useState(false);
  const [openAddNewModal, setOpenAddNewModal] = useState(false);
  const [catDesc, setCatDesc] = useState("");
  const [userRole, setUserRole] = useState("");
  const [catName, setCatName] = useState("");
  let siteOfCms;
  let categoryArray;

  useEffect(() => {
    document.body.className = "";
    getData();
    changeHead();
  }, [tableChanged]);

  const changeHead = () => {
    if (sessionStorage.getItem("languageChosen") === "EL") {
      document.querySelector(".pageHead").innerHTML =`<div style="width: 100%; height:100%; display: inline-flex; justify-content: center;"><h4 style='padding-top: 3.5vh;text-align: center;color: #f7aa47;'><img style="height: 4vh;margin-top:-1vh;" class='fa fa-fw fa-file' src='${manage}' />${languageGr.global.titles.category}</h4></div>`;
    } else {
      document.querySelector(".pageHead").innerHTML =`<div style="width: 100%; height:100%; display: inline-flex; justify-content: center;"><h4 style='padding-top: 3.5vh;text-align: center;color: #f7aa47;'><img style="height: 4vh;margin-top:-1vh;" class='fa fa-fw fa-file' src='${manage}' />${language.global.titles.category}</h4></div>`;
    }
  };

  const fetchUser = async () => {
    const user = await authService.getUserAsync();

    setUserRole(user.claims[0].value);
  };

  const getData = async () => {
    siteOfCms = await SiteApi.Get();
    setSites(siteOfCms);
    setLanguageChosenSite(sessionStorage.getItem("languageChosen"));

    const languagesArray = await LanguageApi.Get();

    categoryArray = await CategoryApi.GetCategoriesOfSite(siteOfCms[0].siteId);

    console.log(sites);
    fetchUser();
    setLanguages(languagesArray);

    setData(categoryArray);

    setLoading(false);

    console.log(languageChosenSite);
  };

  const editClick = async (e) => {
    setModalShow(true);
    console.log(modalShow);
    let targ = e.target.parentElement.parentElement.children[0].id;
    console.log(translations);
  };

  const hoverButtons = (e) => {
    console.log(e);
  };

  const toggleSelectParentCategory = () => {
    setToggleParentCat(!toggleParentCat);
  };

  async function addCategory() {
    console.log("add");
    if (userRole == "Viewer") {
      Swal.fire({
        title: language.media.invalidAction,
        text: language.global.userRole,
        icon: "warning",
        button: "OK",
      });
      return;
    }
    const form = document.getElementById("addCatForm");
    console.log(form);
    if (form.checkValidity() === false) {
      form.classList.add("was-validated");
    } else {
      const english = languages.find((l) => l.initials === "EN");
      console.log(english);
      if (!english) {
        alert("Add English Language First");
      } else {
        let isSubCategory;

        let name = catName;
        console.log(name);
        name = name.trim();
        if (name === "") {
          Swal.fire({
            title: "Something went wrong",
            text: `Please enter a title`,
            icon: "warning",
          });
          return;
        }
        let description = catDesc;
        description = description.trim();
        console.log(description);

        const languageId = english.languageId;
        if (document.getElementById("isSubCat").checked == true) {
          isSubCategory = parseInt($("#categoriesDrop option:selected").val());
        } else {
          isSubCategory = null;
        }
        //photo upload code

        uploadPhoto();
        let image = document.getElementById("imageUpdateStringAdd").innerText;

        const media = {
          Name: name,
          Description: "",
          TypeId: 10,
          LanguageId: parseInt(languageId),
          Value: image,
          ObjectValId: 0,
          RefPath: name,
        };
        let category;
        const mediaCreated = await MediaApi.Create(media);
        console.log(mediaCreated);
        if (mediaCreated) {
          console.log("done");

          category = {
            name: name,
            description: description,
            index: 1,
            image_def: mediaCreated.mediA_ID ? mediaCreated.mediA_ID : 1,
            parentId: isSubCategory,
            mobile: true,
            translations: [],
          };
        } else {
          category = {
            name: name,
            description: description,
            index: 1,
            image_def: 1,
            parentId: isSubCategory,
            mobile: true,
            translations: [],
          };
        }
        category.translations.push({
          LanguageId: parseInt(languageId),
          Name: name,
          Description: description,
        });
        const categoryCreated = await CategoryApi.Create(category);
        console.log(categoryCreated);
        const addCat = await CategoryApi.AddToSite(
          categoryCreated.categoryId,
          sites[0].siteId
        );
        console.log(addCat);

        form.reset();
        setTableChanged(!tableChanged);

        document.getElementById("usingImage").src = "";
        setOpenAddNewModal(false);
      }
      //getData();
    }
  }

  const edit = (cell, row, rowIndex, formatExtraData) => {
    return (
      <FaIcons.FaRegEdit
        id={row.categoryId}
        className="editIcon"
        onClick={editClick}
        Edit
      />
    );
  };

  const deleteClick = async (row) => {
    if (userRole == "Viewer") {
      Swal.fire({
        title: language.media.invalidAction,
        text: language.global.userRole,
        icon: "warning",
        button: "OK",
      });
      return;
    }
    let tar = row.categoryId;
    //console.log(e.target.parentElement.id);
    setDeleteId(tar);
    //let tarObj = await CategoryApi.GetById(tar);
    let tarName = row.name;

    Swal.fire({
      title: "Are you sure?",
      html: `You are about to delete the <strong>${tarName}</strong> category`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const deleted = await CategoryApi.CategoryDelete(parseInt(tar));
        setTableChanged(!tableChanged);
      }
    });
  };

  const del = (cell, row, rowIndex, formatExtraData) => {
    return (
      <FaIcons.FaRegTrashAlt
        id={row.categoryId}
        className="deleteIcon"
        onClick={() => deleteClick(row)}
        Delete
      />
    );
  };

  const selectRowProp = {
    mode: "checkbox",
    hideSelectAll: true,
  };

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      console.log(e);
      console.log({ row, rowIndex });
      setIdClicked(row.categoryId);
    },
  };

  function uploadPhoto() {
    let image = document.getElementById("usingImage");
    let canvas = document.createElement("canvas"),
      ctx = canvas.getContext("2d");
    canvas.height = image.naturalHeight;
    canvas.width = image.naturalWidth;
    ctx.drawImage(image, 0, 0);
    let uri = canvas.toDataURL("image/png"),
      b64 = uri.replace(/^data:image.+;base64,/, "");
    document.getElementById("imageUpdateStringAdd").innerText = b64;
  }

  const onFileInputChange = (e) => {
    console.log("blabla");

    for (let i = 0; i < e.target.files.length; i++) {
      let file = e.target.files[i];

      if (file.size > 1000000) {
        alert(
          `Media with name ${file.name} is not loaded. Media size exceeds the size limit. Please load images with maximum size of 1Mb`
        );
        continue;
      } else {
        let img = document.getElementById("usingImage");
        let reader = new FileReader();
        console.log(reader);
        reader.onloadend = function () {
          img.src = reader.result;
        };
        reader.readAsDataURL(file);
      }
    }
  };

  const IdToParentName = (parentId) => {
    if (parentId === null) {
      return "";
    } else {
      console.log(parentId);
      // const categories = await CategoryApi.GetCategoriesOfSite();
      let parentCat = data.find((c) => c.categoryId == parentId);
      console.log(parentCat.name);

      return parentCat.name;
    }
  };

  const onUploadClick = (e) => {
    console.log("cl;ickcick");
    if (userRole == "Viewer") {
      Swal.fire({
        title: language.media.invalidAction,
        text: language.global.userRole,
        icon: "warning",
        button: "OK",
      });
      return;
    }
    e.preventDefault();

    document.getElementById("file-input").click();
  };

  const descriptionFormat = (d) => {
    if (d != null) {
      return d.replace(/<[^>]+>/g, "").substring(0, 50) + " ....";
    }
  };

  const categoryHeaders = [
    // {
    //   dataField: "categoryId",
    //   text: "Id",
    //   sort: true,
    // },
    {
      dataField: "name",
      text: languageChosenSite === "EL"
      ? languageGr.table.title
      : language.table.title,
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: "parentId",

      text: languageChosenSite === "EL"
      ? languageGr.table.parent
      : language.table.parent,
      sort: true,
      formatter: IdToParentName,
      //filter: textFilter(),
    },
    {
      dataField: "description",
      text: languageChosenSite === "EL"
      ? languageGr.table.description
      : language.table.description,
      sort: true,
      formatter: descriptionFormat,
    },

    {
      dataField: "edit",
      text: languageChosenSite === "EL"
      ? languageGr.table.edit
      : language.table.edit,
      formatter: edit,
    },
    {
      dataField: "delete",
      text: languageChosenSite === "EL"
      ? languageGr.table.delete
      : language.table.delete,
      formatter: del,
    },
  ];

  const toggler = () => {
    setGalleryChooseImage(!galleryChooseImage);
  };

  const handleHide = () => {
    setModalShow(false);
  };

  function onRowSelect(row, isSelected, e) {
    if (!isSelected) {
      console.log(e);

      console.log(data[e]);
      setCheckedList((prevState) => [...prevState, data[e].objectS_ID]);
    }
    let rowStr = "";
    for (const prop in row) {
      rowStr += prop + ': "' + row[prop] + '"';
    }

    console.log(checkedList);
  }

  return (
    <Fragment>
      {modalShow && (
        <CatModal
          onHide={handleHide}
          data={data}
          show={modalShow}
          tableChanged={tableChanged}
          setTableChanged={setTableChanged}
          onSetGalleryChooseImage={toggler}
          getData={getData}
          onSetModalShow={setModalShow}
          targetId={idClicked}
          languages={languages}
          translations={translations}
          rowEvents={rowEvents}
          userRole={userRole}
        ></CatModal>
      )}
      {isLoading ? (
        <div className="col-10 offset-1">
          <CardSkeleton />
        </div>
      ) : (
        <TableGeneral
          data={data}
          columns={categoryHeaders}
          rowEvents={rowEvents}
          //selectRow={selectRowProp}
        />
      )}
      <Button
        className="addNewButton"
        variant="addNewButton"
        onClick={() => setOpenAddNewModal(true)}
      >
        {languageChosenSite === "EL"
          ? languageGr.global.word.addNew
          : language.global.word.addNew}
      </Button>

      <Modal
        show={openAddNewModal}
        onHide={() => setOpenAddNewModal(false)}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {languageChosenSite === "EL"
              ? languageGr.global.categoryWords.addCategory
              : language.global.categoryWords.addCategory}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form id="addCatForm" novalidate>
            <div className="text-center">
              <img
                id="usingImage"
                onHover={hoverButtons}
                loading="lazy"
                style={{ "max-height": "200px" }}
                className="my-1 rounded"
              />
              <br />
              <Button
                variant="success"
                id="selectPhoto"
                className="addNewButton"
                onClick={onUploadClick}
              >
                {languageChosenSite === "EL"
                  ? languageGr.global.categoryWords.uploadImage
                  : language.global.categoryWords.uploadImage}
              </Button>
              <input
                id="file-input"
                type="file"
                onChange={onFileInputChange}
                name="name"
                style={{ display: "none" }}
              />
            </div>
            <Form.Group className="mb-2">
              <Form.Label>
                {languageChosenSite === "EL"
                  ? languageGr.global.transName
                  : language.global.transName}
              </Form.Label>
              <Form.Control
                // id="catName"
                type="text"
                maxlength="30"
                required
                placeholder={
                  languageChosenSite === "EL"
                    ? languageGr.global.enterTitle
                    : language.global.enterTitle
                }
                onChange={(newN) => setCatName(newN.target.value)}
              />
              <div className="valid-feedback">Valid.</div>
              <div className="invalid-feedback">
                {languageChosenSite === "EL"
                  ? languageGr.global.fillThis
                  : language.global.fillThis}
              </div>
            </Form.Group>

            <Form.Group className="mb-2" controlId="">
              <Form.Label>
                {" "}
                {languageChosenSite === "EL"
                  ? languageGr.global.description
                  : language.global.description}
              </Form.Label>
              <BundledEditor
                init={{
                  height: 400,
                  selector: "textarea",
                  plugins:
                    "advlist anchor autolink help image link lists searchreplace table wordcount emoticons insertdatetime code",
                  toolbar:
                    "undo redo blocks image link media numlist bullist insertdatetime|",
                  content_style:
                    "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                }}
                onEditorChange={(newD) => setCatDesc(newD)}
              />
            </Form.Group>

            <Row className="mb-1">
              <Col
                sm={4}
                className="d-flex justify-content-start align-self-end"
              >
                <Form.Group className="mb-3">
                  <Form.Check
                    onClick={toggleSelectParentCategory}
                    type="checkbox"
                    id="isSubCat"
                    label={
                      languageChosenSite === "EL"
                        ? languageGr.global.categoryWords.isSub + "?"
                        : language.global.categoryWords.isSub + ";"
                    }
                  />
                </Form.Group>
              </Col>
              <Col sm={8}>
                <Form.Group>
                  <Form.Label disabled={toggleParentCat}>
                    {languageChosenSite === "EL"
                      ? languageGr.global.categoryWords.category
                      : language.global.categoryWords.category}
                  </Form.Label>
                  <Form.Select id="categoriesDrop" disabled={!toggleParentCat}>
                    {data.map((cat) => (
                      <option value={cat.categoryId}>{cat.name}</option>
                    ))}
                  </Form.Select>
                </Form.Group>
                <input
                  id="imageUpdateStringAdd"
                  type="text"
                  name="name"
                  style={{ display: "none" }}
                />
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="addNewButton"
            variant="addNewButton"
            onClick={addCategory}
          >
            {languageChosenSite === "EL"
              ? languageGr.global.word.addNew
              : language.global.word.addNew}
          </Button>
          <Button
            className="closeButton"
            variant="closeButton"
            onClick={() => setOpenAddNewModal(false)}
          >
            {languageChosenSite === "EL"
              ? languageGr.global.close
              : language.global.close}{" "}
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default Category;
