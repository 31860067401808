import {Fragment, useEffect, useState} from "react";



import "./ContentManagement.css";
import TableContent from '../UI/TableContent'
import manage from "../../images/manage-orange.png";
import {language, languageGr} from '../../api/lang'


const ContentManagement = () => {

  

  useEffect(() => {
    document.body.className = "";
    document.querySelector(".pageHead").style.display = "block"
    changeHead();
  }, []);

  const changeHead = () => {
    if(sessionStorage.getItem('languageChosen') === "EL") {
      
      document.querySelector(".pageHead").innerHTML =`<div style="width: 100%; height:100%; display: inline-flex; justify-content: center;"><h4 style='padding-top: 3.5vh;text-align: center;color: #f7aa47;'><img style="height: 4vh;margin-top:-1vh;" class='fa fa-fw fa-file' src='${manage}' />${languageGr.global.titles.content}</h4></div>`;

    } else {
      document.querySelector(".pageHead").innerHTML =`<div style="width: 100%; height:100%; display: inline-flex; justify-content: center;"><h4 style='padding-top: 3.5vh;text-align: center;color: #f7aa47;'><img style="height: 4vh;margin-top:-1vh;" class='fa fa-fw fa-file' src='${manage}' />${language.global.titles.content}</h4></div>`;
      
      

    }
  }

  return (
    <Fragment>
      
        <TableContent/>
        
      
  </Fragment>
  );
};

export default ContentManagement;
