import { useEffect, useRef, useState } from "react";
import { LayoutEditor } from "../layoutEditor";
import {
  iconList,
  screenList,
  tileSizes,
  widgetTypes,
} from "../data/editorData";
import { CategoryApi, ExhibitNew } from "../../../api/api.service";

const layoutEditor = new LayoutEditor();

export default function EditWidget(props) {
  const selected = props.selected;
  const tiles = props.tiles;
  const setTiles = props.setTiles;
  const [type, setType] = useState("screen");
  const [exhibits, setExhibits] = useState([]);
  const [categories, setCategories] = useState([]);

  async function fetchExhibits() {
    if (exhibits.length === 0 && type === "exhibit") {
      let resp = await ExhibitNew.Get();
      if (resp) {
        setExhibits(resp);
      }
    }
  }

  async function fetchCategories() {
    if (categories.length === 0 && type === "category") {
      let resp = await CategoryApi.Get();
      if (resp) {
        setCategories(resp);
      }
    }
  }

  useEffect(() => {
    fetchCategories();
  }, [categories, type]);

  useEffect(() => {
    fetchExhibits();
  }, [exhibits, type]);

  return (
    <div className="editorSection">
      <div className="editorTitle">Customize Widgets</div>
      <div className="editorText">Select Size</div>
      <div className="editorBtnContainer">
        {tileSizes.map((item, index) => (
          <div
            key={index}
            className="editorOption"
            onClick={() => {
              layoutEditor.changeSize(item.value, selected, tiles, setTiles);
            }}
          >
            {item.title}
          </div>
        ))}
      </div>
      <div className="editorText">Widget name</div>
      <input
        className="editorInput"
        type="text"
        placeholder="Enter..."
        onChange={(e) => {
          layoutEditor.changeName(selected, e.target.value, tiles, setTiles);
        }}
      />
      <div className="editorText">Widget icon</div>
      <select
        className="editorDropdown"
        onChange={(e) => {
          let icon = e.target.value;
          console.log(icon);
          layoutEditor.changeIcon(icon, selected, tiles, setTiles);
        }}
      >
        <option>Select icon</option>
        {iconList.map((item, index) => (
          <option value={item.path} key={index}>
            {item.name}
          </option>
        ))}
      </select>
      <div className="editorText">Navigate to</div>
      {type === "screen" && (
        <select
          className="editorDropdown"
          onChange={(e) => {
            let route = e.target.value;
            layoutEditor.changeRoute(route, selected, tiles, setTiles);
          }}
        >
          <option>Select Screen</option>
          {screenList.map((item, index) => (
            <option value={item.path} key={index}>
              {item.name}
            </option>
          ))}
        </select>
      )}
      {type === "exhibit" && (
        <select
          className="editorDropdown"
          onChange={(e) => {
            let exhibitId = e.target.value;
            layoutEditor.addExhibit(
              exhibits,
              exhibitId,
              selected,
              tiles,
              setTiles
            );
          }}
        >
          <option>Choose exhibit</option>
          {exhibits.map((item, index) => (
            <option value={item.objectS_ID}>{item.name}</option>
          ))}
        </select>
      )}
      {type === "category" && (
        <select
          className="editorDropdown"
          onChange={(e) => {
            let categoryId = e.target.value;
            console.log(categoryId);
            layoutEditor.addCategory(
              categories,
              categoryId,
              selected,
              tiles,
              setTiles
            );
          }}
        >
          <option>Choose category</option>
          {categories.map((item, index) => (
            <option value={item.categoryId}>{item.name}</option>
          ))}
        </select>
      )}
      <div className="editorText">Background Img</div>
      <input
        className="editorInput"
        type="file"
        onChange={(e) => {
          const file = e.target.files[0];
          const reader = new FileReader();
          reader.onload = (event) => {
            const imageUrl = event.target.result;
            layoutEditor.changeBackground(imageUrl, selected, tiles, setTiles);
          };
          reader.readAsDataURL(file);
        }}
      />
      <div className="editorText">Widget Type</div>
      <div className="editorBtnContainer">
        {widgetTypes.map((item, index) => (
          <div
            key={index}
            className="editorOption"
            onClick={() => {
              layoutEditor.addAttribute(item.value, selected, tiles, setTiles);
              setType(item.value);
            }}
          >
            {item.title}
          </div>
        ))}
      </div>
      <div className="editorBtnContainer">
        <div
          className="editorBtn"
          onClick={() => {
            layoutEditor.export(tiles);
          }}
        >
          Export
        </div>
        <div
          className="editorDelete"
          onClick={() => {
            layoutEditor.removeElement(selected, tiles, setTiles);
          }}
        >
          Delete
        </div>
      </div>
    </div>
  );
}
