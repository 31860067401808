import { useState, useEffect, useCallback, useMemo } from "react";
import { next, prev } from "./F&BButtons";
import * as FaIcons from "react-icons/fa";
import { ObjectApi, ExhibitNew } from "../../api/api.service";
// import GoogleMapReact from "google-map-react";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import Swal from "sweetalert2";
import { language, languageGr } from "../../api/lang";
import { ImContrast } from "react-icons/im";
import { Button, Row, Col, Form } from "react-bootstrap";

const LocationManagement = ({ object, languageChosenSite, userRole }) => {
  useEffect(() => {
    getData();
  }, [object]);
  //let object;
  const getData = async () => {
    //object = await ExhibitNew.GetAllValuesOfObject(props.targetId);
    setMarkerAdd({ lat: object.latitude, lng: object.longitude });

    setObj(object);
    setCentered({ lat: object.latitude, lng: object.longitude });

    // console.log(object);
    // console.log(object);
    // console.log(obj);
    // const start = document.getElementsByClassName("act")[1];
    // console.log(start);

    // start.click();
  };

  const [map, setMap] = useState(null);
  const [obj, setObj] = useState({});
  const [longitude, setLongitude] = useState(0);
  const [latitude, setLatitude] = useState(0);
  const [markerAdd, setMarkerAdd] = useState({});
  const [centered, setCentered] = useState({});
  // const [objectName, setObjectName] = useState("");
  // const [objectSerial, setSerial] = useState(0);

  const onLoad = useCallback(function callback(map) {
    //const bounds = new window.google.maps.LatLngBounds(centered);
    map.setZoom(12);

    // map.fitBounds(bounds);
    //new.window.
    setMap(map);
  }, []);

  const onUnmount = useCallback(function callback(map) {
    setMap(null);
  }, []);

  const onPrevious = () => {
    console.log("previous");
  };

  const onForward = () => {
    console.log("forward");
  };

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyDE3AVYlMuR5mpBqDl_lZLenbmyFhbkwaQ",
  });

  const longitudeChange = (e) => {
    console.log(e.target.value);
    setLongitude(parseFloat(e.target.value));
    //setMarkerAdd({...markerAdd, lng: parseFloat(e.target.value)})
  };

  const latitudeChange = (e) => {
    console.log(e.target.value);

    setLatitude(parseFloat(e.target.value));
    //setMarkerAdd({...markerAdd, lat: parseFloat(e.target.value)})
  };
  // const position = {
  //   lat: parseInt(object.latitude),
  //   lng: parseInt(object.longitude)
  // }

  const saveCoords = async () => {
    // object = { ObjectId: 0, Code: '', Image: 0, Index: 0, CriticalRate: false }

    if (userRole != "Admin") {
      Swal.fire({
        title: languageChosenSite == "EL" ? languageGr.media.invalidAction : language.media.invalidAction,
        text: languageChosenSite == "EL" ? languageGr.global.userRole : language.global.userRole,
        icon: "warning",
        button: "OK",
      });
    } else {
      console.log(latitude);
      console.log(longitude);
      const object13 = {
        ExhibitId: obj.objectS_ID,
        Code: obj.objectS_CODE,
        Image: parseInt(obj.imagE_DEF),
        Index: parseInt(obj.lisT_INDEX),
        CriticalRate: false,
        Latitude: parseFloat(latitude),
        Longitude: parseFloat(longitude),
      };
      const objectUpdated = await ObjectApi.Update(object13);
      //console.log(object13);
      Swal.fire({
        title:
          languageChosenSite === "EL"
            ? languageGr.media.successTitle
            : language.media.successTitle,
        text:
          languageChosenSite === "EL"
            ? languageGr.media.successDesc
            : language.media.successDesc,
        icon: "success",
        button: "OK",
      });
    }
  };

  //console.log(object.latitude, object.longitude);

  const containerStyle = {
    width: "auto",
    height: "400px",
  };

  const markerLabel = obj.name;

  // const center = useMemo(() => ({
  //   lat: 41,
  //   lng: 25
  // }), []);

  const onShowOnMap = () => {
    //position = {lat: latitude, lng: longitude}
    console.log(latitude, longitude);
    setMarkerAdd({ lat: parseFloat(latitude), lng: parseFloat(longitude) });
    setCentered({ lat: parseFloat(latitude), lng: parseFloat(longitude) });
  };

  const mapClick = (e) => {
    console.log(e.latLng.lat());
    console.log(e.latLng.lng());
    setMarkerAdd({ lat: e.latLng.lat(), lng: e.latLng.lng() });
    setLatitude(e.latLng.lat());
    setLongitude(e.latLng.lng());
    // return (<Marker position={markerAdd} />)
  };

  return (
    <>
      <div role="tabpanel" className="tab-pane container" id="tab_default_5">
        <div
          className="row"
          style={{
            width: "100%",
            "margin-left": "0px",
            "margin-right": "0px",
            height: "2vh",
          }}
        ></div>
        <div className="jumbotron" id="googleMaps">
          <div id="container"></div>
          {isLoaded ? (
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={centered}
              defaultZoom={10}
              onLoad={onLoad}
              onUnmount={onUnmount}
              onClick={mapClick}
            >
              {/* Child components, such as markers, info windows, etc. */}
              <Marker position={markerAdd} animation={2}>
                <InfoWindow
                  //onClick={onMarkerClick}
                  position={markerAdd}
                  visible={true}
                >
                  <div style={{ "text-align": "center" }} id="content">
                    <div id="siteNotice"></div>
                    <h5 id="firstHeading" className="firstHeading">
                      {obj.name}
                    </h5>

                    <div id="bodyContent">
                      {/* <p><b>Exhibition : </b><div style={{"color": "green"}}>{m.exhibitionName}</div></p> */}

                      <p>
                        <b>
                          {languageChosenSite === "EL"
                            ? languageGr.global.editWords.serialNumber
                            : language.global.editWords.serialNumber}
                          :{" "}
                        </b>
                        <div style={{ color: "green" }}>
                          {" "}
                          {obj.objectS_CODE}
                        </div>{" "}
                      </p>

                      <p>
                        <b>
                          {languageChosenSite === "EL"
                            ? languageGr.content.tab5.long
                            : language.content.tab5.long}{" "}
                          :
                        </b>
                        <div style={{ color: "green" }}>
                          {longitude == 0 ? obj.longitude : longitude}
                        </div>
                      </p>

                      <p>
                        <b>
                          {languageChosenSite === "EL"
                            ? languageGr.content.tab5.lat
                            : language.content.tab5.lat}{" "}
                          :
                        </b>
                        <div style={{ color: "green" }}>
                          {latitude == 0 ? obj.latitude : latitude}
                        </div>
                      </p>
                    </div>
                  </div>
                </InfoWindow>
              </Marker>
            </GoogleMap>
          ) : (
            <></>
          )}

          <div
            className="coordinates"
            style={{ "margin-top": "3vh", "margin-bottom": "3vh" }}
          >
            <div>
              <div className="coordinatesInputs">
                <Row className="align-items-center">
                  <Col sm={9}>
                    <Form>
                      <Form.Group as={Row} className="align-items-center">
                        <Form.Label
                          column
                          sm="6"
                          lg="3"
                          className="d-flex justify-content-md-end fw-light"
                        >
                          {languageChosenSite === "EL"
                            ? languageGr.content.tab5.long
                            : language.content.tab5.long}
                        </Form.Label>
                        <Col sm="6" lg="3">
                          <Form.Control
                            type="number"
                            id="markerLng"
                            defaultValue={markerAdd.lng}
                            onChange={longitudeChange}
                          />
                        </Col>
                        <Form.Label
                          column
                          sm="6"
                          lg="3"
                          className="d-flex justify-content-md-end fw-light"
                        >
                          {languageChosenSite === "EL"
                            ? languageGr.content.tab5.lat
                            : language.content.tab5.lat}
                        </Form.Label>
                        <Col sm="6" lg="3">
                          <Form.Control
                            type="number"
                            id="markerLat"
                            defaultValue={markerAdd.lat}
                            onChange={latitudeChange}
                          />
                        </Col>
                      </Form.Group>
                    </Form>
                  </Col>
                  <Col sm={3} className="d-flex justify-content-lg-end pt-1">
                    <Button
                      className="addNewButton"
                      variant="addNewButton"
                      onClick={onShowOnMap}
                      id="butt"
                    >
                      {languageChosenSite === "EL"
                        ? languageGr.content.tab5.showOnMap
                        : language.content.tab5.showOnMap}
                    </Button>
                  </Col>
                </Row>
              </div>
              {/* <span id="saved" style="margin-left: 3vh; color: green;">${language.content.tab5.successSave}</span> */}
            </div>
          </div>
          {/* <div style={{ height: "100vh", width: "100%" }}>
            <GoogleMapReact
              bootstrapURLKeys={{ key: "AIzaSyDE3AVYlMuR5mpBqDl_lZLenbmyFhbkwaQ" }}
              defaultCenter={defaultProps.center}
              defaultZoom={defaultProps.zoom}
              yesIWantToUseGoogleMapApiInternals
              onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
            >
              <ExhibitComp
                lat={59.955413}
                lng={30.337844}
                text="My Marker"
              />
            </GoogleMapReact>
          </div> */}
          <div className="d-flex justify-content-center modal-footer">
            <Button style={{}} onClick={onPrevious} className="backwards">
              <FaIcons.FaChevronLeft />
            </Button>
            <Button
              id="save"
              className="tab-save-button me-2"
              variant="tab-save-button"
              onClick={saveCoords}
            >
              {languageChosenSite === "EL"
                ? languageGr.content.button
                : language.content.button}
            </Button>

            <Button style={{}} onClick={onForward} className="forward">
              <FaIcons.FaChevronRight />
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default LocationManagement;
