import React, { useEffect, useState } from "react";
import "./ControlPanel.css";
import { SiteApi } from "../../api/api.service";

import content from "../../images/content.png";
import Maps from "../../images/Maps.png";
import Notifications from "../../images/Notifications.png";
import multimedia from "../../images/multimedia-cp.png";
import Events from "../../images/Events.png";
import routes from "../../images/routes.png";
import settings from "../../images/settings.png";
import Statistics from "../../images/Statistics.png";
import fieldIcon from "../../images/fields-green.png"
import Card from "../UI/Card";
import Sidebar from "../Sidebar/Sidebar";
import { language, languageGr } from "../../api/lang";
import { Link } from "react-router-dom";
import { authService } from "../../api/auth.service";
import manage from "../../images/manage-orange.png";

// Bootstrap
import {
  Row,
  Col,
  Image,
  Card as BootstrapCard,
  Container,
} from "react-bootstrap";

{
  /* <Card className={classes.input}>
        <form onSubmit={addUserHandler}>
          <label htmlFor="username">Username</label>
          <input
            id="username"
            type="text"
            value={enteredUsername}
            onChange={usernameChangeHandler}
          />
          <label htmlFor="age">Age (Years)</label>
          <input
            id="age"
            type="number"
            value={enteredAge}
            onChange={ageChangeHandler}
          />
          <Button type="submit">Add User</Button>
        </form>
      </Card> */
}

const ControlPanel = () => {
  const [languageChosenSite, setLanguageChosenSite] = useState("");
  const [site, setSite] = useState({});
  const [userRole, setUserRole] = useState("");

  useEffect(() => {
    start();
    // document.querySelector(".navbar").style.display = "flex";
    // document.querySelector(".pageHead").style.display = "block";
    document.body.className = "bodC";
    setLanguageChosenSite(sessionStorage.getItem("languageChosen"));

    // document.querySelector(".pageHead").innerHTML = `<h4 style='padding-top: 2%;text-align: center;color: #f7aa47;margin-left: -39vw;'><img style="height: 4vh;" class='fa fa-fw fa-file' src='${manage}' />${language.global.titles.controlPanel}</h4>`;
    // if(userRole != "") {
      
    //   document.querySelector("#userRole").innerHTML = `${languageChosenSite === "EL" ? languageGr.global.hi : language.global.hi}, ${userRole}`;

    // }
    // if(site.name != undefined) {
    //   document.querySelector(
    //     "#siteSelector"
    //   ).innerHTML = `<option>${site.name}</option>`;
    // }
    
    changeHead();
  }, []);

  const start = async () => {
    const sitesArray = await SiteApi.Get();
    console.log(sitesArray);
    setSite(sitesArray[0]);
    const user = await authService.getUserAsync();

    setUserRole(user.claims[0].value);
    console.log(site);
  };

  const changeHead = () => {
    if (sessionStorage.getItem("languageChosen") === "EL") {
      document.querySelector(
        ".pageHead"
      ).innerHTML = `<div style="width: 100%; height:100%; display: inline-flex; justify-content: center;"><h4 style='padding-top: 3.5vh;text-align: center;color: #f7aa47;'><img style="height: 4vh;margin-top:-1vh;" class='fa fa-fw fa-file' src='${manage}' />${languageGr.global.titles.controlPanel}</h4></div>`;
    } else {
      document.querySelector(
        ".pageHead"
      ).innerHTML = `<div style="width: 100%; height:100%; display: inline-flex; justify-content: center;"><h4 style='padding-top: 3.5vh;text-align: center;color: #f7aa47;'><img style="height: 4vh;margin-top:-1vh;" class='fa fa-fw fa-file' src='${manage}' />${language.global.titles.controlPanel}</h4></div>`;
    }
  };

  return (
    <>
      <Container className="pt-5">
        <Row sm={12} lg={10}>
          <Col sm={6} lg={3} className="p-4 d-flex justify-content-center">
            <BootstrapCard className="control-panel-card">
              <Link to={"/Management"}>
                <BootstrapCard.Img src={content} />
                <div>
                  <p>
                    {languageChosenSite === "EL"
                      ? languageGr.global.titles.controlPanelContent
                      : language.global.titles.controlPanelContent}
                  </p>
                </div>
              </Link>
            </BootstrapCard>
          </Col>
          {site.type == 2 ? (
            <Col sm={6} lg={3} className="p-4 d-flex justify-content-center">
              <BootstrapCard className="control-panel-card">
                <Link to={"/OutdoorMap"}>
                  <BootstrapCard.Img src={Maps} />
                  <div>
                    <p>
                      {languageChosenSite === "EL"
                        ? languageGr.global.titles.map
                        : language.global.titles.map}
                    </p>
                  </div>
                </Link>
              </BootstrapCard>
            </Col>
          ) : 
            <Col sm={6} lg={3} className="p-4 d-flex justify-content-center">
              <BootstrapCard className="control-panel-card">
                <Link to={"/IndoorMap"}>
                  <BootstrapCard.Img src={Maps} />
                  <div>
                    <p>
                      {languageChosenSite === "EL"
                        ? languageGr.global.titles.indoor
                        : language.global.titles.indoor}
                    </p>
                  </div>
                </Link>
              </BootstrapCard>
            </Col>
          }
          <Col sm={6} lg={3} className="p-4 d-flex justify-content-center">
            <BootstrapCard className="control-panel-card">
              <Link to={"/Notifications"}>
                <BootstrapCard.Img src={Notifications} />
                <div>
                  <p>
                    {languageChosenSite === "EL"
                      ? languageGr.global.titles.notifications
                      : language.global.titles.notifications}
                  </p>
                </div>
              </Link>
            </BootstrapCard>
          </Col>
          <Col sm={6} lg={3} className="p-4 d-flex justify-content-center">
            <BootstrapCard className="control-panel-card">
              <Link to={"/MediaGallery"}>
                <BootstrapCard.Img src={multimedia} />
                <div>
                  <p>
                    {languageChosenSite === "EL"
                      ? languageGr.global.titles.media
                      : language.global.titles.media}
                  </p>
                </div>
              </Link>
            </BootstrapCard>
          </Col>
          <Col sm={6} lg={3} className="p-4 d-flex justify-content-center">
            <BootstrapCard className="control-panel-card">
              <Link to={"/Events"}>
                <BootstrapCard.Img src={Events} />
                <div>
                  <p>
                    {languageChosenSite === "EL"
                      ? languageGr.global.titles.events
                      : language.global.titles.events}
                  </p>
                </div>
              </Link>
            </BootstrapCard>
          </Col>
          {site.type == 2 ? (
            <Col sm={6} lg={3} className="p-4 d-flex justify-content-center">
              <BootstrapCard className="control-panel-card">
                <Link to={"/Exhibitions"}>
                  <BootstrapCard.Img src={routes} />
                  <div>
                    <p>
                      {languageChosenSite === "EL"
                        ? languageGr.global.titles.routes
                        : language.global.titles.routes}
                    </p>
                  </div>
                </Link>
              </BootstrapCard>
            </Col>
          ) : 
            <Col sm={6} lg={3} className="p-4 d-flex justify-content-center">
              <BootstrapCard className="control-panel-card">
                <Link to={"/Field"}>
                  <BootstrapCard.Img src={fieldIcon} />
                  <div>
                    <p>
                      {languageChosenSite === "EL"
                        ? languageGr.global.titles.field
                        : language.global.titles.field}
                    </p>
                  </div>
                </Link>
              </BootstrapCard>
            </Col>  
          }
          <Col sm={6} lg={3} className="p-4 d-flex justify-content-center">
            <BootstrapCard className="control-panel-card">
              <Link to={"/Languages"}>
                <BootstrapCard.Img src={settings} />
                <div>
                  <p>
                    {languageChosenSite === "EL"
                      ? languageGr.global.titles.settings
                      : language.global.titles.settings}
                  </p>
                </div>
              </Link>
            </BootstrapCard>
          </Col>
          <Col sm={6} lg={3} className="p-4 d-flex justify-content-center">
            <BootstrapCard className="control-panel-card">
              <Link to={"/Statistics"}>
                <BootstrapCard.Img src={Statistics} />
                <div>
                  <p>
                    {languageChosenSite === "EL"
                      ? languageGr.global.titles.statistics
                      : language.global.titles.statistics}
                  </p>
                </div>
              </Link>
            </BootstrapCard>
          </Col>
          {/* <Col sm={6} lg={3} className="p-4 d-flex justify-content-center">
            <BootstrapCard className="control-panel-card">
              <Link to={"/LayoutEditor"}>
                <BootstrapCard.Img src={Statistics} />
                <div>
                  <p>
                    Layout Editor
                  </p>
                </div>
              </Link>
            </BootstrapCard>
          </Col> */}
        </Row>
      </Container>
    </>
  );
};

export default ControlPanel;
