import { useState, useEffect, Fragment } from "react";

// API
import { PostsApi, LanguageApi, SiteApi } from "../../api/api.service";
import { language, languageGr } from "../../api/lang";

// Components
import CardSkeleton from "../UI/CardSkeleton";
import EventsModal from "../UI/EventsModal";
import TableGeneral from "../UI/TableGeneral";
import BundledEditor from "../../BundledEditor";

//Authservice
import { authService } from "../../api/auth.service";

// Bootstrap
import { Modal, Form, Button, Row, Col } from "react-bootstrap";
import { textFilter } from "react-bootstrap-table2-filter";

// Icons
import * as FaIcons from "react-icons/fa";
import manage from "../../images/manage-orange.png";

// Swal Alert
import Swal from "sweetalert2";

// Moment
import Moment from "react-moment";

// CSS
import "../../css/components/style.css";

const Events = () => {
  const [events, setEvents] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [modalShow, setModalShow] = useState(false);
  const [idClicked, setIdClicked] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const [languages, setLanguages] = useState([]);
  const [sites, setSites] = useState([]);
  const [addEvent, setAddEvent] = useState(false);
  const [openAddNewModal, setOpenAddNewModal] = useState(false);
  const [tableChanged, setTableChanged] = useState(false);
  const [userRole, setUserRole] = useState("");
  const [languageChosenSite, setLanguageChosenSite] = useState("");
  const [articleName, setArticleName] = useState("");
  const [articleDesc, setArticleDesc] = useState("");

  useEffect(() => {
    document.body.className = "";
    getData();
  }, [tableChanged]);



  const getData = async () => {
    const eventsArray = await PostsApi.Get();
    const languagesArray = await LanguageApi.Get();
    setLanguages(languagesArray);
    setEvents(eventsArray);
    const sitesOfCms = await SiteApi.Get();
    setSites(sitesOfCms);
    setLanguageChosenSite(sessionStorage.getItem("languageChosen"));

    fetchUser();
    setLoading(false);
    console.log(eventsArray);
    changeHead();
  };

  // const changeTableData = async() => {
  //   const eventsArray = await PostsApi.Get();
  //   const languagesArray = await LanguageApi.Get();
  //   setLanguages(languagesArray);
  //   setEvents(eventsArray);
  // }

  const fetchUser = async () => {
    const user = await authService.getUserAsync();

    setUserRole(user.claims[0].value);
  };

  const changeHead = () => {
    if (sessionStorage.getItem("languageChosen") === "EL") {
      document.querySelector(".pageHead").innerHTML =`<div style="width: 100%; height:100%; display: inline-flex; justify-content: center;"><h4 style='padding-top: 3.5vh;text-align: center;color: #f7aa47;'><img style="height: 4vh;margin-top:-1vh;" class='fa fa-fw fa-file' src='${manage}' />${languageGr.global.titles.events}</h4></div>`;
    } else {
      document.querySelector(".pageHead").innerHTML =`<div style="width: 100%; height:100%; display: inline-flex; justify-content: center;"><h4 style='padding-top: 3.5vh;text-align: center;color: #f7aa47;'><img style="height: 4vh;margin-top:-1vh;" class='fa fa-fw fa-file' src='${manage}' />${language.global.titles.events}</h4></div>`;
    }
  };

  const editClick = (row) => {
    console.log(row);
    let targ =
      row.id;
    console.log(targ);
    setIdClicked(targ);

    setModalShow(true);
    //setTableChanged(!tableChanged);

    //console.log(modalShow);
  };

  const onAddEvent = async () => {
    if (userRole == "Viewer") {
      Swal.fire({
        title: languageChosenSite == "EL" ? languageGr.media.invalidAction : language.media.invalidAction,
        text: languageChosenSite == "EL" ? languageGr.global.userRole : language.global.userRole,
        icon: "warning",
        button: "OK",
      });
      return;
    }
    const form = document.getElementById("addArticleForm");

    if (form.checkValidity() === false) {
      form.classList.add("was-validated");
    } else {
      const english = languages.find((l) => l.initials === "EN");
      if (!english) {
        alert("Add English Language First");
      } else {
        let name = articleName;
        name = name.trim();
        let description = articleDesc;
        description = description.trim();

        const languageId = english.languageId;
        const publishAt = document.getElementById("articlePublishDate").value;
        const expiresAt = document.getElementById("articleExpireDate").value;
        const createdAt = new Date(Date.now());

        const article = {
          Title: name,
          Content: description,
          Userid: 1,
          SiteId: sites[0].siteId,
          CreatedAt: createdAt,
          ExpiresAt: expiresAt,
          PublishedAt: publishAt,
          Status: true,
          translations: [],
        };
        console.log(article);
        article.translations.push({
          Title: name,
          Content: description,
          LanguageId: parseInt(languageId),
        });
        const insertedArticle = await PostsApi.Create(article);
        setTableChanged(!tableChanged);
        


        form.reset();
        setOpenAddNewModal(false);
      }
    }

    // getData();
  };

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      console.log(e);
      console.log({ row, rowIndex });
      //setIdClicked(row.id);
      setDeleteId(row.id);
    },
  };

  const deleteClick = async (row) => {
    if (userRole == "Viewer") {
      Swal.fire({
        title: languageChosenSite == "EL" ? languageGr.media.invalidAction : language.media.invalidAction,
        text: languageChosenSite == "EL" ? languageGr.global.userRole : language.global.userRole,
        icon: "warning",
        button: "OK",
      });
      return;
    }

    let tar = row.id;
    console.log(tar);

    setIdClicked(tar);
    setDeleteId(tar);
    
    let tarName = row.title;

    Swal.fire({
      title: "Are you sure?",
      html: `You are about to delete the <strong>${tarName}</strong> Event`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const deleted = await PostsApi.PostsDelete(parseInt(tar));
        setTableChanged(!tableChanged);
      }
    });
  };

  const edit = (cell, row, rowIndex, formatExtraData) => {
    return <FaIcons.FaRegEdit onClick={() => editClick(row)} className="editIcon" Edit />;
  };

  const del = (cell, row, rowIndex, formatExtraData) => {
    return (
      <FaIcons.FaRegTrashAlt
        onClick={() => deleteClick(row)}
        className="deleteIcon"
        Delete
      />
    );
  };

  function dateFormatter(cell, row) {
    if (row.createdAt) {
      return (
        <span>
          <Moment format="DD/MM/YY">{cell}</Moment>
        </span>
      );
    }
  }
  function statusFormatter(cell, row) {
    return (
      <div class="form-check form-switch" style={{ "margin-left": "35%" }}>
        <input
          class="form-check-input"
          type="checkbox"
          onChange={(e) => StatusChange(row, e)}
          checked={row.status}
        />
      </div>
    );
  }

  const StatusChange = (row, e) => {
    console.log(userRole);
    if (userRole == "Viewer") {
      Swal.fire({
        title: languageChosenSite == "EL" ? languageGr.media.invalidAction : language.media.invalidAction,
        text: languageChosenSite == "EL" ? languageGr.global.userRole : language.global.userRole,
        icon: "warning",
        button: "OK",
      });
      return;
    }
    e.preventDefault();
    const pubTar =
      row.id;
    const pubTarName =
      row.title;
    console.log(!e.target.checked);

    Swal.fire({
      title: "Are you sure?",
      html: `You are about to change the status of the <strong>${pubTarName}</strong> Event`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const postObject = await PostsApi.GetById(parseInt(pubTar));
        console.log(postObject);
        const article = {
          Id: postObject.id,
          UserId: postObject.userId,
          SiteId: postObject.siteId,
          ExpiresAt: postObject.publishedAt,
          PublishedAt: postObject.expiresAt,
          Status: !postObject.status,
        };
        console.log(article);

        const updated = await PostsApi.Update(article);
        console.log(updated);
        e.target.checked = updated.status;
      } else {
        e.target.checked = e.target.checked;
      }
    });
  };

  function isPublishedFormatter(cell, row) {
    return (
      <div class="" style={{ "margin-left": "25%", pointerEvents: "none" }}>
        <input
          style={{ background: "#ffa500a1" }}
          class="form-check-input"
          type="radio"
          checked={row.isPublished}
        />
      </div>
    );
  }

  const eventHeaders = [
    // {
    //   dataField: "id",
    //   text: "Id",
    //   sort: true,
    // },
    {
      dataField: "title",
      text: languageChosenSite === "EL"
      ? languageGr.table.title
      : language.table.title,
      sort: true,
      filter: textFilter(),
    },

    {
      dataField: "createdAt",
      formatter: dateFormatter,
      text:languageChosenSite === "EL"
      ? languageGr.table.created
      : language.table.created,
      sort: true,
    },
    {
      dataField: "publishedAt",
      formatter: dateFormatter,

      text: languageChosenSite === "EL"
      ? languageGr.table.published
      : language.table.published,
      sort: true,
    },
    {
      dataField: "expiresAt",
      formatter: dateFormatter,

      text: languageChosenSite === "EL"
      ? languageGr.table.expires
      : language.table.expires,
      sort: true,
    },
    {
      dataField: "isPublished",
      formatter: isPublishedFormatter,

      text: languageChosenSite === "EL"
      ? languageGr.table.active
      : language.table.active,
    },
    {
      dataField: "status",
      formatter: statusFormatter,
      text: languageChosenSite === "EL"
      ? languageGr.table.approved
      : language.table.approved,
    },

    {
      dataField: "edit",
      text: languageChosenSite === "EL"
      ? languageGr.table.edit
      : language.table.edit,
      formatter: edit,
    },
    {
      dataField: "delete",
      text: languageChosenSite === "EL"
      ? languageGr.table.delete
      : language.table.delete,
      formatter: del,
    },
  ];

  const handleHide = () => {
    setModalShow(false);
  };

  return (
    <Fragment>
      {modalShow && (
        <EventsModal
          onHide={handleHide}
          show={modalShow}
          targetId={idClicked}
          languages={languages}
          rowEvents={rowEvents}
          data={events}
          getData={getData}
          userRole={userRole}
          tableChanged={tableChanged}
          setTableChanged={setTableChanged}
          setIdClicked={setIdClicked}
        ></EventsModal>
      )}
      {isLoading ? (
        <div className="col-10 offset-1">
          <CardSkeleton />
        </div>
      ) : (
        <TableGeneral data={events} columns={eventHeaders} responsive />
      )}
      <Button
        className="addNewButton"
        variant="addNewButton"
        onClick={() => setOpenAddNewModal(true)}
      >
        {languageChosenSite === "EL"
          ? languageGr.global.word.addNew
          : language.global.word.addNew}
      </Button>

      <Modal
        show={openAddNewModal}
        onHide={() => setOpenAddNewModal(false)}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {languageChosenSite === "EL"
              ? languageGr.global.eventsWords.addEvent
              : language.global.eventsWords.addEvent}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form id="addArticleForm">
            <Row>
              <Col sm={6}>
                <Form.Group>
                  <Form.Label>
                    {languageChosenSite === "EL"
                      ? languageGr.global.eventsWords.publish
                      : language.global.eventsWords.publish}
                  </Form.Label>
                  <input
                    className="form-control"
                    id="articlePublishDate"
                    type="date"
                    required
                  />
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group>
                  <Form.Label>
                    {languageChosenSite === "EL"
                      ? languageGr.global.eventsWords.expires
                      : language.global.eventsWords.expires}
                  </Form.Label>
                  <input
                    className="form-control"
                    id="articleExpireDate"
                    type="date"
                    required
                  />
                </Form.Group>
              </Col>
            </Row>

            <Form.Group className="mb-2">
              <Form.Label>
                <Form.Label>
                  {languageChosenSite === "EL"
                    ? languageGr.global.transName
                    : language.global.transName}
                </Form.Label>
              </Form.Label>
              <Form.Control
                type="text"
                maxlength="30"
                required
                placeholder={
                  languageChosenSite === "EL"
                    ? languageGr.global.enterTitle
                    : language.global.enterTitle
                }
                onChange={(newN) => setArticleName(newN.target.value)}
              />
              <div className="valid-feedback">Valid.</div>
              <div className="invalid-feedback">
                {languageChosenSite === "EL"
                  ? languageGr.global.fillThis
                  : language.global.fillThis}
              </div>
            </Form.Group>

            <Form.Group className="mb-2" controlId="">
              <Form.Label>
                {languageChosenSite === "EL"
                  ? languageGr.global.transDesc
                  : language.global.transDesc}
              </Form.Label>
              <BundledEditor
                init={{
                  height: 400,
                  selector: "textarea",
                  plugins:
                    "advlist anchor autolink help image link lists searchreplace table wordcount emoticons insertdatetime code",
                  toolbar:
                    "undo redo blocks image link media numlist bullist insertdatetime|",
                  content_style:
                    "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                }}
                onEditorChange={(newD) => setArticleDesc(newD)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="addNewButton"
            variant="addNewButton"
            onClick={onAddEvent}
          >
            {languageChosenSite === "EL"
              ? languageGr.global.word.addNew
              : language.global.word.addNew}
          </Button>
          <Button
            className="closeButton"
            variant="closeButton"
            onClick={() => setOpenAddNewModal(false)}
          >
            {languageChosenSite === "EL"
              ? languageGr.global.close
              : language.global.close}
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default Events;
