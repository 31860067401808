import React, { useState, useEffect } from "react";
import { fabric } from "fabric";
import CropSquareIcon from "@mui/icons-material/CropSquare";
import { language, languageGr } from "../../../../api/lang";

function Square(props) {
  const [selected, setSelected] = useState(false);
  

  useEffect(() => {
    if (selected) {
      props.canvas.getActiveObject().set("stroke", props.iconColor);
      props.canvas.requestRenderAll();
      const activeSelection = props.canvas.getActiveObject();

  console.log(activeSelection);
    }
  }, [ props.iconColor]);

  useEffect(() => {
    if (selected) {
      props.canvas.getActiveObject().set("fill", props.fillColor);
      props.canvas.requestRenderAll();

    }
  }, [ props.fillColor]);

  const workPoint = new fabric.Rect({
    top: 150,
    left: 200,
    width: 150,
    height: 150,
    stroke: props.iconColor,
    fill: props.fillColor,
    cornerColor: "black"
  });

  const onAddTriangle = () => {
    props.canvas.add(workPoint);
  };

  workPoint.on("selected", () => {
    props.setSquares(false);

    setSelected(true);
    props.setIconColor(props.canvas.getActiveObject().stroke);
    props.setFillColor(props.canvas.getActiveObject().fill);
    // document.getElementById("fontSizeDiv").style.display = "none";
    props.canvas.getActiveObject().cornerColor = "black";

    // document.getElementById("textExhibit").style.display = "none";
    // document.getElementById("fontFamilyDiv").style.display = "none";
    

  });

  workPoint.on("deselected", () => {
    setSelected(false);
    // document.getElementById("fontSizeDiv").style.display = "flex";
    // document.getElementById("textExhibit").style.display = "flex";
    // document.getElementById("fontFamilyDiv").style.display = "flex";
     

  });


  if(props.canvas) {
    props.canvas.on("selection:created", (e) => {
      if(e.selected[0].type === "square") {
        console.log("circle Selection Created");
        props.setSquares(false);

        setSelected(true);
        props.setIconColor(props.canvas.getActiveObject().stroke);
        props.canvas.getActiveObject().cornerColor = "black";

        props.setFillColor(props.canvas.getActiveObject().fill);
        //props.setStrokeWidth(props.canvas.getActiveObject().strokeWidth);
        // document.getElementById("fontSizeDiv").style.display = "none";
        // document.getElementById("textExhibit").style.display = "none";
        // document.getElementById("fontFamilyDiv").style.display = "none";
      } else {
        console.log("NOT circle Selection Created");

        setSelected(false);
        // document.getElementById("fontSizeDiv").style.display = "flex";
        // document.getElementById("textExhibit").style.display = "flex";
        // document.getElementById("fontFamilyDiv").style.display = "flex";
      }
      
      //document.getElementById("strokeWidth").style.display = "block";
    });
  }

  if(props.canvas) {
    props.canvas.on("selection:updated", (e) => {
      console.log(e);

      if(e.selected[0].type === "square") {
        console.log("circle Selection Updated");
        props.setSquares(false);

        setSelected(true);
        props.setIconColor(props.canvas.getActiveObject().stroke);
        props.setFillColor(props.canvas.getActiveObject().fill);
        props.canvas.getActiveObject().cornerColor = "black";

        //props.setStrokeWidth(props.canvas.getActiveObject().strokeWidth);
        // document.getElementById("fontSizeDiv").style.display = "none";
        // document.getElementById("textExhibit").style.display = "none";
        
        // document.getElementById("fontFamilyDiv").style.display = "none";
      } else {
        console.log("NOT circle Selection Updated");

        setSelected(false);
        // document.getElementById("fontSizeDiv").style.display = "flex";
        // document.getElementById("textExhibit").style.display = "flex";
        // document.getElementById("fontFamilyDiv").style.display = "flex";
      }
    });
  }

  return (
    <button
      className="mapPropertiesItem"
      
      onClick={onAddTriangle}
    >
      <CropSquareIcon />
      {props.languageChosenSite === "EL"
        ? languageGr.indoor.square
        : language.indoor.square}
    </button>
  );
}

export default Square;
