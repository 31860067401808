import React, { useState, useEffect } from "react";
// import "../";
import '../Header/Header.css'
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import { Link } from "react-router-dom";
import { SidebarDataConfig } from "./SidebarDataConfig";
import { SidebarDataConfigGr } from "./SidebarDataConfig";
import { authService } from "../../api/auth.service";
// import Header from '../Header/Header'
import LanguageList from "../UI/LanguageList";
import { SiteApi } from "../../api/api.service";
import Manual from '../../Manual/CMSManual.pdf';
import ManualGR from '../../Manual/CMSManualGR.pdf';

import xenagosLogo from "../../images/Sidebar/xenagos-logo1.png";

import settings from "../../images/Sidebar/settings-chaki.png";
import user from "../../images/Sidebar/user-chaki.png";
import museum from "../../images/Sidebar/museum-orange.png";
import england from "../../images/Sidebar/great-britain-circular.png";
import greece from "../../images/Sidebar/greece-circular.png";
import help from "../../images/Sidebar/help-chaki.png";
import { language, languageGr } from "../../api/lang";
import exit from "../../images/Sidebar/exit-chaki.png";



import { IconContext } from "react-icons";

const SidebarConfig = (props) => {
  const [sidebar, setSidebar] = useState(false);
  const [languageChosenSite, setLanguageChosenSite] = useState("");
  const [toggleLanguage, setToggleLanguage] = useState(false);
  const [site, setSite] = useState("");
  const [userRole, setUserRole] = useState("");

  

  useEffect(() => {
    
    setLanguageChosenSite(sessionStorage.getItem('languageChosen'));

    getData();

  }, [userRole]);

  const getData = async () => {
    const sites = await SiteApi.Get();
    const user = await authService.getUserAsync();
    console.log(user);
    setUserRole(user.claims[0].value);

    setSite(sites[0]);
  }

  function deleteAllCookies() {
    document.cookie.split(";").forEach((c) => {
      document.cookie = c
        .replace(/^ +/, "")
        .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
    });
  } 


  const showSidebar = () => {
    setSidebar(!sidebar);
  };

  const toggled = () => {
    setToggleLanguage(!toggleLanguage);
  }

  const logout = () => {
    authService.logOut();
    sessionStorage.clear();
    deleteAllCookies();
    props.setLogin();

  }

  const toggleChangeUrl = () => {
    props.changeUrl(false);
    console.log("changeURL");
  }

  // const NavigateToPanel = () => {
  //   window.location.href = '/ControlPanel'
  // }
   

  return (
    <>
    <IconContext.Provider value={{color: "#fff"}}>
      <div className="navbar">
        <Link to="#" className="menu-bars">
          <FaIcons.FaBars onClick={showSidebar} />
        </Link>
        
        {/* code for Header */}
        <div className="xenagosHeader">
        <div className="line spBetw">
          <div className="line">
            <div>
              <Link to="/ControlPanel" onClick={toggleChangeUrl}>
                <img id="xenagosLogo" src={xenagosLogo} alt="Xenagos logo" />
              </Link>
            </div>
           
                <ul className="navbar-nav">
                <li className="nav-item">
                    <div
                    className="nav-link selectEffect"
                    
                    >
                    <div className="displayStyle rowFlex">
                        <img
                        className=""
                        src={museum}
                        style={{marginTop: 1}}
                        />
                        <select
                        id="siteSelector"
                        className="form-control"
                        style={{"margin-left": "0.5vw"}}
                        >
                            <option>{site.name}</option>
                        </select>
                    </div>
                    </div>
                </li>
                </ul>
            
          </div>

          <div className="">
            <div className="logoutBtn">
              <div
                className="dropdown"
                style={{ "height": "7vh" }}
              >
                <button
                  
                  className="btn btn-sm dropdown-toggle"
                  type="button"
                  style={{ "height": "90%" }}
                  id="menu1"
                  data-toggle="dropdown"
                  onClick={toggled}
                >
                  <img style={{ "height": "100%" }} src={sessionStorage.getItem("languageChosen") === "EL" ? greece : england} heightImg id="imageSelected"/>
                  <span className="caret"></span>
                </button>
                {toggleLanguage &&<LanguageList />}
              </div>

              <div>
                <a title="User" style={{ "margin-top": ".5vh" }} className="nav-link">
                  <span
                    style={{ "color": "orange" }}
                    id="userRole"
                  >{languageChosenSite === "EL" ? languageGr.global.hi : language.global.hi}, {userRole}</span>
                  <img
                    className="heightImg"
                    src={user}
                    style={{marginTop: -4}}
                  />
                </a>
              </div>

              <a
                title="Settings"
                id="leave"
                // href="/Languages"
                className="nav-link"
                style={{marginTop: 2, marginLeft: -2, marginRight: 2}}
              >
                <img
                  className="heightImg"
                  src={settings}
                  
                />
              </a>

              {languageChosenSite === "EL" ? <a
                title="User Manual"
                target="_blank"
                href={ManualGR}
                style={{marginTop: 9}}
              >
                <img
                  className="heightImg"
               
                  src={help}
                />
              </a> : 
                <a
                title="User Manual"
                target="_blank"
                href={Manual}
                style={{marginTop: 9}}
              >
                <img
                  className="heightImg"
              
                  src={help}
                />
              </a> }
             
              <Link to={'/'}
                title="Logout"
                //href=""
                onClick={logout}
                className="nav-link"
                style={{marginTop: 2, "cursor": "pointer"}}
              >
                <img
                  className="heightImg"
                  src={exit}
                  
                />
                </Link>
             
            </div>
          </div>
        </div>
      </div>

        {/* code for header */}






      </div>
      <nav className={sidebar ? "nav-menu active" : "nav-menu"}>
        <ul className="nav-menu-items" onClick={showSidebar}>
          <li className="navbar-toggle">
            <Link to="#" className="menu-bars">
              <AiIcons.AiOutlineClose />
            </Link>
          </li>
          {languageChosenSite === "EL"  ? SidebarDataConfigGr.map((item, index) => {
            return (
              <li key={index} className={item.cName}>
                <Link to={item.path} onClick={() => {if(item.path == "/Management") {return props.changeUrl(false)} }}>
                {item.icon}
                  <span className="sidebar_span">{item.title}</span>
                  
                </Link>
              </li>
            );
          }) : SidebarDataConfig.map((item, index) => {
            return (
              <li key={index} className={item.cName}>
                <Link to={item.path} onClick={() => {if(item.path == "/Management") {return props.changeUrl(false)} }}>
                {item.icon}
                  <span className="sidebar_span">{item.title}</span>
                  
                </Link>
              </li>
            );
          }) }





        </ul>
      </nav>
      </IconContext.Provider>
      <div className="pageHead">

    </div>
    </>
  );
};

export default SidebarConfig;