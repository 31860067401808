import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const CardSkeleton = () => {

   

  return (
    <div style={{ marginTop: "10vh" }}>
      <div className="react-bootstrap-table">
      <Skeleton style={{marginTop: "2vh", height: "3vh" }} count={10}>
        <table style={{ height:"4vh"}}>
            <th></th>
            
            <tbody>
            
                
               
            </tbody>
            
        </table>
        </Skeleton>
        
      </div>
    </div>
  );
};

export default CardSkeleton;
