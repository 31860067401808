import React, { useState, Fragment, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./Modal.css";
import FillModal from "./Tabs";
import { language, languageGr } from "../../api/lang";
import editOrange from "../../images/edit-orange-s.png";

const ModalB = (props) => {
  //const [idClicked, setIdClicked] = useState(props.targetId);
  //const [data, setData] = useState(props.data);
  //let idClicked = props.targetId
  console.log(props.targetId);
  console.log(props.site);
  console.log(props);
  const nameOfExhibit = props.data.find(d => d.objectS_ID == props.targetId);
  // const [modalShow, setModalShow] = useState(false);

  

  const handleHide = () => {
    if (props.onSetModalShow) {
      props.onSetModalShow(false);
    }
  };

  return (
    <>
      {/* <Button variant="primary" onClick={() => setModalShow(true)}>
        Launch vertically centered modal
      </Button> */}

      <MyVerticallyCenteredModal
        show={props.show}
        onHide={handleHide}
        data={props.data}
        targetId={props.targetId}
        site={props.site}
        alterTable={props.alterTable}
        languageChosenSite={props.languageChosenSite}
        userRole={props.userRole}
        nameOfExhibit={nameOfExhibit}
      />
    </>
  );
};
function MyVerticallyCenteredModal(props) {
  console.log(props);

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <img
            src={editOrange}
            style={{ height: "3vh", "margin-right": "1vw" }}
          />
          <span id="exhibitName">
            {props.nameOfExhibit.name}
          </span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="container">
        <FillModal
          alterTable={props.alterTable}
          tableChanged={props.tableChanged}
          site={props.site}
          targetId={props.targetId}
          data={props.data}
          rowEvents={props.rowEvents}
          userRole={props.userRole}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="closeButton"
          variant="closeButton"
          // style={{ width: "100%", "border-radius": "13px" }}
          onClick={props.onHide}
        >
          {props.languageChosenSite === "EL"
            ? languageGr.global.close
            : language.global.close}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
export default ModalB;
