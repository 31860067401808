import React, { useEffect, useState } from "react";
import { fabric } from "fabric";
import { Button } from "react-bootstrap";

import { language, languageGr } from "../../../../api/lang";
import {
  markerIcon,
  stairIcon,
  wcIcon,
  storeIcon,
  fireIcon,
} from "../../IconsSVG";

import StairsIcon from "@mui/icons-material/Stairs";
import RoomIcon from "@mui/icons-material/Room";
import FireExtinguisherIcon from "@mui/icons-material/FireExtinguisher";
import StorefrontIcon from "@mui/icons-material/Storefront";
import WeekendIcon from "@mui/icons-material/Weekend";
import "../../mapProperties.css";


function MapIcons(props) {
  const [selected, setSelected] = useState(false);
  const iconColor = props.iconColor;

  const originalToObject = fabric.Object.prototype.toObject;

  const myAdditional = ["iconName", "iconPath"];

  fabric.Object.prototype.toObject = function (additionalProperties) {
    return originalToObject.call(
      this,
      myAdditional.concat(additionalProperties)
    );
  };


  if(props.canvas) {
   
    props.canvas.on("selection:created", (e) => {
      if(e.selected[0].iconName && e.selected[0].iconName !== "Exhibit") {
        setSelected(true);
        console.log("canvas sel");
        console.log(e);
        props.setIconColor(props.canvas.getActiveObject().fill);
        props.canvas.getActiveObject().cornerColor = "black";

        document.getElementById("fontSizeDiv").style.display = "none";
        document.getElementById("textExhibit").style.display = "none";
        document.getElementById("fillColorDiv").style.display = "none";
        document.getElementById("fontFamilyDiv").style.display = "none";
      } else {
        setSelected(false);
      
     
        document.getElementById("fontSizeDiv").style.display = "flex";
        document.getElementById("textExhibit").style.display = "flex";
        document.getElementById("fillColorDiv").style.display = "flex";
        document.getElementById("fontFamilyDiv").style.display = "flex";
      }
      
      //document.getElementById("strokeWidth").style.display = "block";
    });
  }

  if(props.canvas) {
    props.canvas.on("selection:updated", (e) => {
 

      if(e.selected[0].iconName && e.selected[0].iconName !== "Exhibit") {
        console.log("sel updated iconname exists");
        setSelected(true);
        console.log("canvas sel");
        console.log(e);
        props.setIconColor(props.canvas.getActiveObject().fill);
        props.canvas.getActiveObject().cornerColor = "black";
        document.getElementById("fontSizeDiv").style.display = "none";
        document.getElementById("textExhibit").style.display = "none";
        document.getElementById("fillColorDiv").style.display = "none";
        document.getElementById("fontFamilyDiv").style.display = "none";
      } else {
        setSelected(false);
        
        
     
        document.getElementById("fontSizeDiv").style.display = "flex";
        document.getElementById("textExhibit").style.display = "flex";
        document.getElementById("fillColorDiv").style.display = "flex";
        document.getElementById("fontFamilyDiv").style.display = "flex";
      }
    });
  }


 

  const addMarker = (iconPath, iconName) => {
    const workpoint = new fabric.Path(iconPath);

    workpoint.set({
      top: 100,
      left: 150,
      originX: "center",
      originY: "center",
      stroke: "black",
      cornerColor: "black",
      fill: iconColor,
    });
    workpoint.iconName = iconName;
    workpoint.iconPath = iconPath;
    workpoint.on("selected", () => {
     

      setSelected(true);
      
      props.setIconColor(props.canvas.getActiveObject().fill);
      document.getElementById("fontSizeDiv").style.display = "none";
      document.getElementById("textExhibit").style.display = "none";
      document.getElementById("fillColorDiv").style.display = "none";
      document.getElementById("fontFamilyDiv").style.display = "none";
      //document.getElementById("strokeWidth").style.display = "none";
    });
    workpoint.on("deselected", () => {
      setSelected(false);
      document.getElementById("fontSizeDiv").style.display = "flex";
      document.getElementById("textExhibit").style.display = "flex";
      document.getElementById("fillColorDiv").style.display = "flex";
      document.getElementById("fontFamilyDiv").style.display = "flex";
      //document.getElementById("strokeWidth").style.display = "block";
    });

    props.canvas.add(workpoint);
  };

  

  useEffect(() => {
    if (selected) {
      props.canvas.getActiveObject().set("fill", iconColor);
     
      
      props.canvas.requestRenderAll();
    }
  }, [iconColor]);

  // useEffect(() => {
  //   if (selected) {
  //     console.log("selected object");
  //   } else {
  //     console.log("NOt selected object");
      
  //   }
  // }, [selected]);

  // useEffect(() => {
    
  //     if(props.objectTargeted.iconName) {
      
  //       setSelected(true);
  //       console.log(props.objectTargeted);
  //       props.setIconColor(props.canvas.getActiveObject().fill);
  //       // document.getElementById("fontSizeDiv").style.display = "none";
  //       // document.getElementById("textExhibit").style.display = "none";
  //       // document.getElementById("fillColorDiv").style.display = "none";
  //       // document.getElementById("fontFamilyDiv").style.display = "none";
  //       //document.getElementById("strokeWidth").style.display = "none";
       

  //     } else {
  //       console.log("not an icon");
  //       setSelected(false);
        
  //     }
      
    
  // }, [props.objectTargeted]);



  return (
    <div className="mapPropertiesOptions">
      {/* <button
        className="mapPropertiesItem"
        onClick={() => {
          addMarker(markerIcon.path,"Marker");
        }}
      >
        <RoomIcon />{" "}
        {props.languageChosenSite === "EL"
          ? languageGr.indoor.misc
          : language.indoor.misc}
      </button> */}
      <button
        className="mapPropertiesItem"
        onClick={() => {
          addMarker(stairIcon.path, stairIcon.name);
        }}
      >
        <StairsIcon />{" "}
        {props.languageChosenSite === "EL"
          ? languageGr.indoor.stairs
          : language.indoor.stairs}
      </button>
      <button
        className="mapPropertiesItem"
        onClick={() => {
          addMarker(fireIcon.path, fireIcon.name);
        }}
      >
        <FireExtinguisherIcon />{" "}
        {props.languageChosenSite === "EL"
          ? languageGr.indoor.fireExtinguisher
          : language.indoor.fireExtinguisher}
      </button>
      <button
        className="mapPropertiesItem"
        onClick={() => {
          addMarker(wcIcon.path, wcIcon.name);
        }}
      >
        <WeekendIcon />{" "}
        {props.languageChosenSite === "EL"
          ? languageGr.indoor.restroom
          : language.indoor.restroom}
      </button>
      <button
        className="mapPropertiesItem"
        onClick={() => {
          addMarker(storeIcon.path, storeIcon.name);
        }}
      >
        <StorefrontIcon />{" "}
        {props.languageChosenSite === "EL"
          ? languageGr.indoor.shop
          : language.indoor.shop}
      </button>
    </div>
  );
}

export default MapIcons;
