import React from 'react'

//Google Maps
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

function Map(props) {
    const _API_KEY_GOOGLE_MAPS = "AIzaSyBfQ9ycyktNQ4lBVZM3y_1L1WBv3DBRgSE";

    return (
        <div>
            <LoadScript
                googleMapsApiKey={_API_KEY_GOOGLE_MAPS}
            >
                <GoogleMap
                    mapContainerStyle={{ width: '100%', height: '450px' }}
                    center={(props.position)}
                    zoom={14}
                >
                    <Marker
                        position={props.position} />
                </GoogleMap>
            </LoadScript>
        </div>
    )
}

export default Map