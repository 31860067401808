import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import React, { useState, useEffect } from "react";
//import Button from "react-bootstrap/Button";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";

import CardSkeleton from "./CardSkeleton";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "./TableContent.css";
// import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import ModalB from "./Modal";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Modal";
import { authService } from "../../api/auth.service";
import removeFadeOut from "./RemoveItemDom";
import Swal from "sweetalert2";

import { language, languageGr } from "../../api/lang";
import QRCode from "qrcode.react";

//import * as QRCode from 'https://cdn.rawgit.com/davidshimjs/qrcodejs/gh-pages/qrcode.min.js'

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";

//import ToolkitProvider, {CSVExport} from "react-bootstrap-table2-toolkit";

import * as FaIcons from "react-icons/fa";
import { ExhibitNew, SiteApi } from "../../api/api.service";

const TableContent = (props) => {
  const [data, setData] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [QRModalOpen, setQRModalOpen] = useState(false);
  const [site, setSite] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [idClicked, setIdClicked] = useState(0);

  const [deleteId, setDeleteId] = useState(null);
  const [checkedList, setCheckedList] = useState([]);
  const [qrValue, setQrValue] = useState("");
  const [tableChanged, setTableChanged] = useState(false);
  const [exhibitChanged, setExhibitChanged] = useState(false);
  const [languageChosenSite, setLanguageChosenSite] = useState("");
  const [rowData, setrowData] = useState([]);
  const [rowSelect, setrowSelect] = useState([]);
  const [multiDel, setMultiDel] = useState(false);
  const [name, setName] = useState("");
  const [userRole, setUserRole] = useState({});
  const [indoorSite, setIndoorSite] = useState(false);

  // let obj =[];
  // let cats = [];
  //  let siteTemp = [];
  // const showMultiDel = () => {
  //   console.log(rowData);
  //   console.log(rowSelect);
  //   if(rowData.length > 1) {

  //     setMultiDel(true);
  //   } else {
  //     setMultiDel(false);
  //   }
  // }

  const ExhibitFetch = async () => {

    const exhibit = await ExhibitNew.GetById(idClicked);
    setName(exhibit.name)
    

  }

  const ExhibitChanged = () => {
    setExhibitChanged(!exhibitChanged)

  }

  const changeTable = (e) =>{
    setTableChanged(!tableChanged)
  }
  const mulitpleDelete = () => {

    if (userRole == "Viewer") {
      Swal.fire({
        title: languageChosenSite == "EL" ? languageGr.media.invalidAction : language.media.invalidAction,
        text: languageChosenSite == "EL" ? languageGr.global.userRole : language.global.userRole,
        icon: "warning",
        button: "OK",
      });
      return;
    }

    if (rowData.length == 0) {
               
      Swal.fire({
          title: languageChosenSite === "EL" ? languageGr.media.noItemsTitle : language.media.noItemsTitle,
          text: languageChosenSite === "EL" ? languageGr.media.noItemsChosen : language.media.noItemsChosen,
          icon: "warning",
          button: "OK",
          
      })
    } else { 

      Swal.fire({
        title:
          languageChosenSite === "EL"
            ? languageGr.media.confirmMessage
            : language.media.confirmMessage,
        html: `${
          languageChosenSite === "EL"
            ? languageGr.deleteSelected.multiDelete
            : language.deleteSelected.multiDelete
        }   <b>${rowData.length}</b>`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText:
          languageChosenSite === "EL"
            ? languageGr.content.del
            : language.content.del,
      }).then(async (result) => {
        if (result.isConfirmed) {
          //const deleted = await ExhibitNew.ObjectDelete(parseInt(target));
          rowData.forEach(async function (selectedId) {
            console.log(selectedId.objectS_ID);
            await ExhibitNew.ObjectDelete(parseInt(selectedId.objectS_ID));
      
      
          });
          setTableChanged(!tableChanged);
         
        }
      });
    }
  }

  useEffect(() => {
    
    ExhibitFetch();
  }, [exhibitChanged]);

  useEffect(() => {
    
    getData();
    console.log('changed!!!')
  }, [tableChanged]);

  // useEffect(() => {
    
    
  // }, [rowData]);

  //"https://jsonplaceholder.typicode.com/comments"
  const getData = async () => {
    const user = await authService.getUserAsync();
    setUserRole(user.claims[0].value);
    const sites = await SiteApi.Get();
    //site = sites[0];
    setSite(sites[0]);
    console.log(site);
    setLanguageChosenSite(sessionStorage.getItem("languageChosen"));

    // cats = await CategoryApi.GetCategoriesOfSite(sites[0].siteId);

    // for (let i = 0; i < cats.length; i++) {
    //   let catObj = await ObjectApi.GetByCategoryId(cats[i].categoryId);

    //   for (let j = 0; j < catObj.length; j++) {
    //       obj.push(catObj[j]);
    //   }
    // }
    let objectsAll = await ExhibitNew.Get();
    console.log(objectsAll);
    // let objects = objectsAll.filter((c, index) => {
    const objects = [];

    objectsAll.map((x) =>
      objects.filter((a) => a.objectS_ID == x.objectS_ID).length > 0
        ? null
        : objects.push(x)
    );
    console.log(objects);


    
    //   console.log(c);
    //   console.log(index);
    //   return objectsAll[objectsAll.indexOf(c)].objectS_ID === objectsAll[index].objectS_ID;
    // });
    //objects.filter(ob => ob.objectS_ID);

    //console.log(testDuplicates);
    // var seen = {};
    // objects = objects.filter(function (entry) {

    //     let previous;

    //     // Have we seen this label before?
    //     if (seen.hasOwnProperty(entry.objectId)) {
    //         // Yes, grab it and add this data to it
    //         previous = seen[entry.objectId];
    //         previous.category.push(entry.category);

    //         // Don't keep this entry, we've merged it into the previous one
    //         return false;
    //     }

    //     // entry.data probably isn't an array; make it one for consistency
    //     if (!Array.isArray(entry.category)) {
    //         entry.category = [entry.category];
    //     }

    //     // Remember that we've seen it
    //     seen[entry.objectId] = entry;

    //     // Keep this one, we'll merge any others that match into it
    //     return true;
    // });

    setData(objects);
    //data = objects;
    setIsLoading(false);
    //console.log(objects);
    console.log(data);
  };
  //let idClicked;
  let target;
  let tar;

  //const { SearchBar } = Search;

  const pagination = paginationFactory({
    page: 1,
    sizePerPage: 10,
    lastPageText: ">>",
    firstPageText: "<<",
    nextPageText: ">",
    prePageText: "<",
    showTotal: true,
    alwaysShowAllBtns: true,
    onPageChange: function (page, sizePerPage) {
      console.log("page", page);
      console.log("sizePerPage", sizePerPage);
    },
    onSizePerPageChange: function (page, sizePerPage) {
      console.log("page", page);
      console.log("sizePerPage", sizePerPage);
    },
  });

  // const alterTable = () => {
  //   setTableChanged(!tableChanged);
  // };

  const editClick = (e) => {
    setModalShow(true);
    console.log(modalShow);
    //console.log(e.target, "THIS IS THE TARGET");
    tar = parseInt(
      e.currentTarget.parentElement.parentElement.children[1].innerText
    );
    setIdClicked(tar);

    //idClicked = parseInt(e.target.parentElement.parentElement.children[1].innerText);

    //setIdClicked(e.target.parentElement.parentElement.children[0].innerHTML);
    //console.log(idClicked);
  };

  const deleteClick = (row) => {
    
    //e.stopPropagation();
    console.log(userRole);
    if (userRole == "Viewer") {
      Swal.fire({
        title: languageChosenSite == "EL" ? languageGr.media.invalidAction : language.media.invalidAction,
        text: languageChosenSite == "EL" ? languageGr.global.userRole : language.global.userRole,
        icon: "warning",
        button: "OK",
      });
    } else {
      //console.log(e);
      target = row.objectS_ID
      console.log(target);
      //let targetElement = e.target.parentElement.parentElement;
      //console.log(targetElement);
      setDeleteId(target);
      setIdClicked(target);
      Swal.fire({
        title:
          languageChosenSite === "EL"
            ? languageGr.media.confirmMessage
            : language.media.confirmMessage,
        html: `${
          languageChosenSite === "EL"
            ? languageGr.deleteSelected.exhibitAlert
            : language.deleteSelected.exhibitAlert
        }   <b>${row.name}</b>`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText:
          languageChosenSite === "EL"
            ? languageGr.content.del
            : language.content.del,
      }).then(async (result) => {
        if (result.isConfirmed) {
          const deleted = await ExhibitNew.ObjectDelete(parseInt(target));
          //removeFadeOut(targetElement, 600);

          // Swal.fire(
          //   'Deleted!',
          //   'Your file has been deleted permanently.',
          //   'success'
          // )
          setTableChanged(!tableChanged);
        }
      });
    }
    //console.log(deleteId);
  };

  const edit = (cell, row, rowIndex, formatExtraData) => {
    return (
      <FaIcons.FaRegEdit
        style={{ "font-size": "20px", cursor: "pointer" }}
        className="editIcon"
        //id={row.objectS_ID}
        onClick={editClick}
        Edit
      />
    );
  };

  const del = (cell, row, rowIndex, formatExtraData) => {
    return (
      <FaIcons.FaRegTrashAlt
        style={{ "font-size": "20px", cursor: "pointer" }}
        //id={row.objectS_ID}
        className="deleteIcon"
        onClick={() => deleteClick(row)}
        Delete
      />
    );
  };

  // const code = (cell, row, rowIndex, formatExtraData) => {
  //   return (
  //     <FaIcons.FaRegTrashAlt
  //     //id={row.objectS_ID}
  //       onClick={() => console.log("code")}
  //       Delete
  //     />
  //   );
  // };

  // const targetId = (e) => {
  //   setIdClicked(e.target)
  //   console.log(e.target);
  // }

  // function dateFormatter(cell, row) {
  //   if (row.createdAt) {
  //     return (
  //       <span>
  //         <Moment format="DD/MM/YY">{cell}</Moment>
  //       </span>
  //     );
  //   }
  // }

  const generateQRCode = (row, cell) => {
    //setQrValue(row);
    console.log(row);
    console.log(cell);
    setQrValue(cell);
    return (
      <button
        className="btn btn-success btn-sm qrIcon"
        onClick={() => setQRModalOpen(true)}
      >
        {row}
      </button>
    );
  };

  // const createQRCode = (row) => {
  //   return (
  //     <Modal
  //       isopen={QRModalOpen}
  //       onRequestClose={() => setQRModalOpen(false)}
  //     >
  //     <QRCode
  //         id="qr-gen"
  //         value={row}
  //         size={50}
  //         level={"H"}
  //         includeMargin={true}

  //       />
  //       <button onClick={() => downloadQRCode(row)}></button>
  //       </Modal>
  //   );
  // }

  const downloadQRCode = () => {
    // Generate download with use canvas and stream
    //setQrValue(row);

    //console.log(qrValue);

    //console.log(e.target);
    //console.log(row.objectS_CODE);
    const canvas = document.getElementById("qr-gen");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `${qrValue}.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  let columns = [
    // {
    //   dataField: 'objectS_ID',
    //   text: "Id",
    //   sort: true,
    // },
    {
      dataField: "objectS_CODE",
      text: languageChosenSite === "EL"
      ? languageGr.table.code
      : language.table.code,
      sort: true,
      formatter: generateQRCode,
    },
    {
      dataField: "name",
      text: languageChosenSite === "EL"
      ? languageGr.table.title
      : language.table.title,
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: "category",
      text: languageChosenSite === "EL"
      ? languageGr.table.category
      : language.table.category,
      sort: true,
    },
    {
      dataField: "edit",
      text: languageChosenSite === "EL"
      ? languageGr.table.edit
      : language.table.edit,
      formatter: edit,
    },
    {
      dataField: "delete",
      text: languageChosenSite === "EL"
      ? languageGr.table.delete
      : language.table.delete,
      formatter: del,
    },
  ];

  // function onRowSelect(row, isSelected, e) {
  //   if (!isSelected) {
  //     console.log(e);

  //     console.log(data[e]);
  //     setCheckedList((prevState) => [...prevState, data[e].objectS_ID]);
  //   }
  //   let rowStr = "";
  //   for (const prop in row) {
  //     rowStr += prop + ': "' + row[prop] + '"';
  //   }

  //   console.log(checkedList);
  // }

  // function onSelectAll(isSelected, data, e) {
  //   console.log(e);
  //   // for (let i = 0; i < data.length; i++) {
  //   //   alert(rows[i].id);
  //   // }
  // }

  // const selectRowProp = {
  //   mode: "checkbox",
  //   clickToSelect: true,
  //   // clickToExpand: true,
  //   //hideSelectAll: true,
  //   onSelect: onRowSelect,
  //   onSelectAll: onSelectAll,
  // };



  const selectRowProp = {
    mode: "checkbox",
    clickToSelect: false,
    classes: "selection-row",
    onSelect: (row, isSelect, rowIndex, e) => {
      if (isSelect === true) {
        console.log(row);
        setrowData((prevState) => [...prevState, row]);
        setrowSelect([isSelect]);
      } else {
        let arr = rowData;
        const index = arr.indexOf(
          arr.find((file) => file.objectS_ID === parseInt(row.objectS_ID))
        );
        console.log(index);
        arr.splice(index, 1);
        setrowData((prevState) => (prevState = arr));
        //setrowData((rowData) => rowData.filter((x, i) => i !== rowIndex));
        setrowSelect([]);
      }
    },

    onSelectAll: (isSelect, rows, e) => {
      if (isSelect === true) {
        setrowData(rows);
      } else setrowData([]);
      //	setrowSelect(isSelect);
    }
  };

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      console.log(e);
      console.log({ row, rowIndex });
      setQrValue(row.objectS_CODE);
      setIdClicked(row.objectS_ID);

      //idClicked = row.objectS_ID;
      console.log(idClicked);
      //setCheckedList((prevState) => [...prevState, row.objectS_ID]);
      setDeleteId(row.objectS_ID);
    },
  };

  // console.log(data);
  // console.log(idClicked);
  // console.log(tar);


  //console.log(checkedList);
  //console.log(deleteId);

  return (
    <>
      {/* {alertShow && <Alert />} */}
      {modalShow && (
        <ModalB
          userRole={userRole}
          languageChosenSite={languageChosenSite}
          alterTable={changeTable}
          tableChanged={tableChanged}
          show={modalShow}
          onSetModalShow={setModalShow}
          site={site}
          targetId={idClicked}
          data={data}
          rowEvents={rowEvents}
          exhibitChanged={ExhibitChanged}
          name={name}
        ></ModalB>
      )}
      {QRModalOpen && (
        <Modal
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={QRModalOpen}
          onHide={() => setQRModalOpen(false)}
          rowEvents={rowEvents}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              {languageChosenSite === "EL"
                ? languageGr.global.editWords.qrCode
                : language.global.editWords.qrCode}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <QRCode
              id="qr-gen"
              value={qrValue}
              size={450}
              level={"H"}
              includeMargin={true}
            />
          </Modal.Body>
          <Modal.Footer>
            <button
              style={{ width: "100%", "border-radius": "12px" }}
              className="btn btn-success"
              onClick={(e) => downloadQRCode(e)}
            >
              {languageChosenSite === "EL"
                ? languageGr.global.editWords.qrDownload
                : language.global.editWords.qrDownload}
            </button>
          </Modal.Footer>
        </Modal>
      )}
       
      <div style={{ marginTop: "8vh" }}>
        <div className="col-10 offset-1">
        
         
          {isLoading ? (
            <CardSkeleton count={10} />
          ) : (
            <BootstrapTable
              keyField="objectS_CODE"
              data={data}
              columns={columns}
              selectRow={selectRowProp}
              rowEvents={rowEvents}
              striped
              bootstrap4
              hover
              pagination={pagination}
              filter={filterFactory()}
            />
          )}
          
        </div>
        <button
            className="btn btn-danger"
            
            style={{ borderRadius: 18 }}
            onClick={mulitpleDelete}
          >
            {languageChosenSite === "EL"
              ? languageGr.global.multipleDel
              : language.global.multipleDel}
              
          </button>
      </div>
    </>
  );
};

export default TableContent;
