import HttpService from './http.service.js';
import { authService } from './auth.service.js';

// const auth = await authService.getUserAsync();
const url = "https://xenagos.discoverkavala.eu/xenagos/"; 
//const urlΑ = "https://api.dei.prismasense.com/xenagos/en/api/v1/whatever"; 

/*const language = "EL";*/  
const urlTest = "http://localhost:40004/xenagos/";
//let siteIdMatomo; //used to be 13 = 16; 

const getInsightsOfSite = async () => {
    const siteInsights = await SiteApi.GetById(1);
    console.log(siteInsights);
    console.log(siteInsights.insights);
    return siteInsights.insights;
}
/*const http = new HttpService(url);*/
//language
const http = new HttpService(url);
const httpTest = new HttpService(urlTest);
const base = 'en/api/v1/';
const baseObjec = 'api/v1/';
let token = "31be6fdc1014c76db5b16ae3c79bb145";

console.log(token);

const baseGr = 'el/api/v1/';




export class ExhibitNew {
    constructor() { }
    static baseUrl = base + 'ExhibitNew/';


    static async Get() {
        try {
            return await http.Get(this.baseUrl + 'all'/*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.ExhibitNew.Get()] Error:', e.message);
        }
    }

    static async GetById(id) {
        try {

            return await http.Get(this.baseUrl + id/*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.ExhibitNew.GetById()] Error:', e.message);
        }
    }

    static async GetByCategoryId(id) {
        try {
            return await http.Get(this.baseUrl + 'category/' + id/*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.ExhibitNew.GetByCategoryId()] Error:', e.message);
        }
    }

    static async GetByExhibition(id) {
        try {
            return await http.Get(this.baseUrl + 'exhibition/' + id/*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.ExhibitNew.GetByExhibition()] Error:', e.message);
        }
    }

    static async GetByTranslation(id) {
        try {
            return await http.Get(this.baseUrl + 'translation/' + id/*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.ExhibitNew.GetByTranslation()] Error:', e.message);
        }
    }

    static async GetFieldsOfObject(id) {
        try {
            return await http.Get(this.baseUrl + 'field/0/' + id/*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.ExhibitNew.GetFieldsOfObject()] Error:', e.message);
        }
    }
    // static async GetAllObjectsOfField(fieldid) {
    //     try {
    //         return await http.Get(this.baseUrl + 'field/' + fieldid/*, { authorization: auth.getToken().accessToken }*/)
    //     } catch (e) {
    //         console.error('[System.ExhibitNew.GetAllObjectsOfField()] Error:', e.message);
    //     }
    // }
    static async RemoveObjectValues(objectId, presentationId, languageId, status) {
        try {
            return await http.Post(this.baseUrl + 'delete/' + objectId + '/' + presentationId + '/' + languageId + '/' + status /*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.ExhibitNew.RemoveObjectValues()] Error:', e.message);
        }
    }

    static async GetAllValuesOfObject(objectId) {
        try {
            return await http.Get(this.baseUrl + objectId /*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.ExhibitNew.GetAllValuesOfObject()] Error:', e.message);
        }
    }

    static async GetObjectsOfPresentation(id) {
        try {
            return await http.Get(this.baseUrl + 'presentation/' + id + '/0'/*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.ExhibitNew.GetObjectsOfPresantation()] Error:', e.message);
        }
    }
    static async GetObjectsFieldsByPresentation(id, objectId) {
        try {
            return await http.Get(this.baseUrl + 'presentation/' + id + '/' + objectId/*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.ExhibitNew.GetObjectsFieldsByPresantation()] Error:', e.message);
        }
    }
    static async GetAllObjectsOfField(fieldid) {
        try {
            return await http.Get(this.baseUrl + 'field/' + fieldid/*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.ExhibitNew.GetAllObjectsOfField()] Error:', e.message);
        }
    }

    static async Create(object = { Name: '', Description: '', Code: '', Image: 1, Index: 0, CriticalRate: false, translations: [{ LanguageId: 0, Name: '', Description: '' }] }) {
        try {
            return await http.Post(this.baseUrl + 'create', object /*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.ExhibitNew.Create()] Error:', e.message);
        }
    }

    static async Insert(object = { ObjectsId: 0, LanguageId: 1, PressId: 1, FieldID: 1, Status: true, ShortValue: 'Short Text', LongValue: 'Long Text', Mobile: true, url: '' }) {
        try {
            return await http.Post(this.baseUrl + 'insert', object /*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            return e.message;
            console.error('[System.ExhibitNew.Create()] Error:', e.message);
        }
    }


    static async CreateTranslation(translation = { Name: '', Description: '', translations: [{LanguageId: 0, Name: "", Description: ""}] }) {
        try {
            return await http.Post(this.baseUrl + 'translation/create', translation /*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.ExhibitNew.CreateTranslation()] Error:', e.message);
        }

    }


    static async UpdateTranslation(object = { translations: [{LanguageId : 0, Name :"", Description: ""}] }) {
        try {
            return await http.Put(this.baseUrl + 'translation/update', object /*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.ExhibitNew.Update()] Error:', e.message);
        }

    }
    static async Update(object = { ObjectId: 0, Code: '', Image: 0, Index: 0, CriticalRate: false }) {
        try {
            return await http.Put(this.baseUrl + 'update', object /*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.ExhibitNew.Update()] Error:', e.message);
        }

    }

    static async AddToCategory(objectId, categoryId) {
        try {
            return await http.Post(this.baseUrl + 'add/' + objectId + '/to/category/' + categoryId /*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.ExhibitNew.AddToCategory()] Error:', e.message);
        }

    }

    static async AddToExhibition(objectId, exhibitionId) {
        try {
            return await http.Post(this.baseUrl + 'add/' + objectId + '/to/exhibition/' + exhibitionId /*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.ExhibitNew.AddToExhibition()] Error:', e.message);
        }

    }

    static async AddTagToExhibit(objectId, tagId) {
        try {
            return await http.Post(this.baseUrl + 'addTag/' + objectId + '/' + tagId /*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.ExhibitNew.AddToExhibition()] Error:', e.message);
        }

    }

    static async RemoveFromCategory(objectId) {
        try {
            return await http.Post(this.baseUrl + 'remove/from/category/' + objectId /*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.ExhibitNew.RemoveFromCategory()] Error:', e.message);
        }

    }

    static async RemoveFromExhibition(objectId) {
        try {
            return await http.Post(this.baseUrl + 'remove/from/exhibition/' + objectId /*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.ExhibitNew.RemoveFromExhibition()] Error:', e.message);
        }

    }

    static async RemoveTagFromExhibit(objectId, tagId) {
        try {
            return await http.Delete(this.baseUrl + 'removeTag/' + objectId + '/' + tagId  /*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.ExhibitNew.RemoveTagFromExhibit()] Error:', e.message);
        }

    }

    static async AddToTerritory(objectId, territoryId) {
        try {
            return await http.Post(this.baseUrl + 'add/' + objectId + 'to/' + territoryId /*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.ExhibitNew.AddToTerritory()] Error:', e.message);
        }

    }
    static async ObjectDelete(objectId) {
        try {
            return await http.Delete(this.baseUrl + 'delete/' + objectId/*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.ExhibitNew.ObjectDelete()] Error:', e.message);
        }
    }
}

export class ObjectApi {
    constructor() { }
    static baseUrl = base + 'exhibit/';


    static async Get() {
        try {
            return await http.Get(this.baseUrl + 'all'/*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.ObjectApi.Get()] Error:', e.message);
        }
    }

    static async GetById(id) {
        try {
            console.log(id);
            return await http.Get(this.baseUrl + id/*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.ObjectApi.GetById()] Error:', e.message);
        }
    }

    static async GetByCategoryId(id) {
        try {
            return await http.Get(this.baseUrl + 'category/' + id/*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.ObjectApi.GetByCategoryId()] Error:', e.message);
        }
    }

    static async GetByExhibition(id) {
        try {
            return await http.Get(this.baseUrl + 'exhibition/' + id/*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.ObjectApi.GetByExhibition()] Error:', e.message);
        }
    }

    static async GetByTranslation(id) {
        try {
            return await http.Get(this.baseUrl + 'translation/' + id/*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.ObjectApi.GetByTranslation()] Error:', e.message);
        }
    }

    static async GetFieldsOfObject(id) {
        try {
            return await http.Get(this.baseUrl + 'field/0/' + id/*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.ObjectApi.GetFieldsOfObject()] Error:', e.message);
        }
    }
    static async GetAllObjectsOfField(fieldid) {
        try {
            return await http.Get(this.baseUrl + 'field/' + fieldid/*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.ObjectApi.GetAllObjectsOfField()] Error:', e.message);
        }
    }
    static async RemoveObjectValues(objectId, presentationId, languageId, status) {
        try {
            return await http.Post(this.baseUrl + 'delete/' + objectId + '/' + presentationId + '/' + languageId + '/' + status /*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.ObjectApi.RemoveObjectValues()] Error:', e.message);
        }
    }

    static async GetAllValuesOfObject(objectId) {
        try {
            return await http.Get(this.baseUrl + objectId + '/values'  /*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.ObjectApi.GetAllValuesOfObject()] Error:', e.message);
        }
    }

    static async GetObjectsOfPresantation(id) {
        try {
            return await http.Get(this.baseUrl + 'presentation/' + id + '/0'/*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.ObjectApi.GetObjectsOfPresantation()] Error:', e.message);
        }
    }
    static async GetObjectsFieldsByPresantation(id, objectId) {
        try {
            return await http.Get(this.baseUrl + 'presentation/' + id + '/' + objectId/*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.ObjectApi.GetObjectsFieldsByPresantation()] Error:', e.message);
        }
    }
    static async GetAllObjectsOfField(fieldid) {
        try {
            return await http.Get(this.baseUrl + 'field/' + fieldid/*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.ObjectApi.GetAllObjectsOfField()] Error:', e.message);
        }
    }

    static async Create(object = { Name: '', Description: '', Code: '', Image: 1, Index: 0, CriticalRate: false, translations: [{ LanguageId: 0, Name: '', Description: '' }] }) {
        try {
            return await http.Post(this.baseUrl + 'create', object /*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.ObjectApi.Create()] Error:', e.message);
        }
    }

    static async Insert(object = { ObjectsId: 0, LanguageId: 1, PressId: 1, FieldID: 1, Status: true, ShortValue: 'Short Text', LongValue: 'Long Text', Mobile: true, url: '' }) {
        try {
            return await http.Post(this.baseUrl + 'insert', object /*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            return e.message;
            console.error('[System.ObjectApi.Create()] Error:', e.message);
        }
    }


    static async CreateTranslation(translation = { Name: '', Description: '', translations: [{LanguageId:0, Name:'', Description:''}] }) {
        try {
            return await http.Post(this.baseUrl + 'translation/create', translation /*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.ObjectApi.CreateTranslation()] Error:', e.message);
        }

    }


    static async UpdateTranslation(object = { translations: [{LanguageId:0, Name:'', Description:''}] }) {
        try {
            return await http.Put(this.baseUrl + 'translation/update', object /*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.ObjectApi.Update()] Error:', e.message);
        }

    }
    static async Update(object = { ObjectId: 0, Code: '', Image: 0, Index: 0, CriticalRate: false }) {
        try {
            return await http.Put(this.baseUrl + 'update', object /*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.ObjectApi.Update()] Error:', e.message);
        }

    }

    static async AddToCategory(objectId, categoryId) {
        try {
            return await http.Post(this.baseUrl + 'add/' + objectId + '/to/category/' + categoryId /*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.ObjectApi.AddToCategory()] Error:', e.message);
        }

    }

    static async AddToExhibition(objectId, exhibitionId) {
        try {
            return await http.Post(this.baseUrl + 'add/' + objectId + '/to/exhibition/' + exhibitionId /*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.ObjectApi.AddToExhibition()] Error:', e.message);
        }

    }

    static async RemoveFromCategory(objectId) {
        try {
            return await http.Post(this.baseUrl + 'remove/from/category/' + objectId /*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.ObjectApi.RemoveFromCategory()] Error:', e.message);
        }

    }

    static async RemoveFromExhibition(objectId) {
        try {
            return await http.Post(this.baseUrl + 'remove/from/exhibition/' + objectId /*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.ObjectApi.RemoveFromExhibition()] Error:', e.message);
        }

    }

    static async AddToTerritory(objectId, territoryId) {
        try {
            return await http.Post(this.baseUrl + 'add/' + objectId + 'to/' + territoryId /*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.ObjectApi.AddToTerritory()] Error:', e.message);
        }

    }
    static async ObjectDelete(objectId) {
        try {
            return await http.Delete(this.baseUrl + 'delete/' + objectId/*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.ObjectApi.ObjectDelete()] Error:', e.message);
        }
    }
}

export class CategoryApi {
    constructor() { }

    static baseUrl = base + 'category/';


    static async Get() {
        try {
            return await http.Get(this.baseUrl + 'all'/*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.CategoryApi.Get()] Error:', e.message);
        }
    }

    static async GetById(id) {
        try {
            return await http.Get(this.baseUrl + id/*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.CategoryApi.GetById()] Error:', e.message);
        }
    }
    

    static async Create(category = {
        "name": "string",
        "description": "string",
        "index": 0,
        "image_def": 0,
        "parentId": 0,
        "mobile": true,
        translations: [{LanguageId: 0, Name: '', Description: ''}]
    }) {
        try {
            return await http.Post(this.baseUrl + 'create', category /*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.CategoryApi.Create()] Error:', e.message);
        }

    }



    static async CreateTranslation(translation = { Name: '', Description: '', translations: [{LanguageId: 0, Name: '', Description: ''}] }) {
        try {
            return await http.Post(this.baseUrl + 'translation/create', translation /*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.CategoryApi.CreateTranslation()] Error:', e.message);
        }

    }


    static async UpdateTranslation(translation = { translations: [{LanguageId: 0, Name: '', Description: ''}] }) {
        try {

            return await http.Put(this.baseUrl + 'translation/update', translation /*, { authorization: auth.getToken().accessToken }*/)

        } catch (e) {
            console.error('[System.CategoryApi.Update()] Error:', e.message);
        }

    }

    static async Update(category = { CategoryId: 0, ParentId: '', Mobile: 1, Index: 1 }) {
        try {
            return await http.Put(this.baseUrl + 'update', category /*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.CategoryApi.Update()] Error:', e.message);
        }

    }

    static async GetByTranslation(id) {
        try {
            return await http.Get(this.baseUrl + 'translation/' + id/*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.CategoryApi.GetByTranslation()] Error:', e.message);
        }
    }

    static async GetCategoriesOfSite(siteId) {
        try {
            return await http.Get(this.baseUrl + 'site/' + siteId + '/all'/*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.CategoryApi.Get()] Error:', e.message);
        }
    }

    static async GetSubCategoriesOfSite(siteId, categoryId) {
        try {
            return await http.Get(this.baseUrl + 'site/' + siteId + '/sub/' + categoryId/*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.CategoryApi.Get()] Error:', e.message);
        }
    }

    static async GetFieldOfCategory(categoryId) {
        try {
            return await http.Get(this.baseUrl + 'field/' + categoryId/*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.CategoryApi.Get()] Error:', e.message);
        }
    }


    static async AddToSite(categoryId, siteId) {
        try {
            return await http.Post(this.baseUrl + 'add/' + categoryId + '/to/' + siteId /*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.CategoryApi.AddToSite()] Error:', e.message);
        }

    }

    static async CategoryDelete(catId) {
        try {
            return await http.Delete(this.baseUrl + 'delete/' + catId/*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.CategoryApi.Category()] Error:', e.message);
        }
    }
}

export class NotificationApi {
    constructor() { }

    static baseUrl = base + 'notification/';



    static async GetById(id) {
        try {
            return await http.Get(this.baseUrl + id/*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.NotificationApi.GetById()] Error:', e.message);
        }
    }


    static async Create(notification = { Title: '', Message: '', SiteId: '', CreatedAt: '', Status: '' }) {
        try {
            console.log(notification);
            return await http.Post(this.baseUrl + 'create', notification /*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.PostsApi.Create()] Error:', e.message);
        }

    }



    static async Update(notification = { Id: 0, Title: '', Message: '', SiteId: '', CreatedAt: '', Status: '' }) {
        try {
            return await http.Put(this.baseUrl + 'update', notification /*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.NotificationApi.Update()] Error:', e.message);
        }

    }



    static async GetAllNotificationsOfSite(siteId) {
        try {
            return await http.Get(this.baseUrl + 'site/' + siteId + '/all' /*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.NotificationApi.Get()] Error:', e.message);
        }
    }


    static async NotificationDelete(notificationId) {
        try {
            return await http.Delete(this.baseUrl + 'delete/' + notificationId/*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.PostsApi.Category()] Error:', e.message);
        }
    }

}

export class SiteApi {
    constructor() { }

    static baseUrl = base + 'Sites/';

    static async Get() {

        try {
            return await http.Get(this.baseUrl + 'all'/*, { authorization: auth.getToken().accessToken }*/);
        } catch (e) {
            console.error('[System.SiteApi.Get()] Error:', e.message);
        }
    }

    static async GetById(id) {
        try {
            return await http.Get(this.baseUrl + id/*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.SiteApi.GetById()] Error:', e.message);
        }

    }


    static async GetTranslation(id) {
        try {
            return await http.Get(this.baseUrl + 'translation/' + id/*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.SiteApi.GetTranslation()] Error:', e.message);
        }

    }
    static async Create(site = { Name: '', Description: '', translations: [{LanguageId:0, Name:"", Description:""}] }) {
        try {
            return await http.Post(this.baseUrl + 'create', site /*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.SiteApi.Create()] Error:', e.message);
        }

    }
    static async CreateTranslation(translation = { translations: [{translationId:0, id:0, name:"", description:"", languageId:0}] }) {
        try {
            //name, description, left to translation
            return await http.Post(this.baseUrl + 'translation/create', translation /*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.SiteApi.CreateTranslation()] Error:', e.message);
        }

    }
    static async Update(translation = { translations: [{ translationId:0, id:0, name:"", description:"", languageId:0 }] }) {
        try {
            return await http.Put(this.baseUrl + 'update', translation /*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.SiteApi.Update()] Error:', e.message);
        }

    }

}

export class PresentationApi {
    constructor() { }
    static baseUrl = base + 'Presentation/';
    static async Get() {
        try {
            return await http.Get(this.baseUrl + 'all'/*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.Presentation.Get()] Error:', e.message);
        }
    }

    static async GetById(id) {
        try {
            return await http.Get(this.baseUrl + id/*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.PresentationApi.GetById()] Error:', e.message);
        }
    }

    static async GetBySiteId(id) {
        try {
            return await http.Get(this.baseUrl + 'site/' + id/*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.PresentationApi.GetBySiteId()] Error:', e.message);
        }
    }

    static async GetByTranslation(id) {
        try {
            return await http.Get(this.baseUrl + 'translation/' + id/*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.PresentationApi.GetByTranslation()] Error:', e.message);
        }
    }


    static async Create(presentation = { Name: '', Description: '', translations: [{LanguageId:0, Name:"", Description:""}] }) {
        try {
            return await http.Post(this.baseUrl + 'create', presentation /*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.PresentationApi.Create()] Error:', e.message);
        }

    }


    static async CreateTranslation(translation = { Name: '', Description: '', translations: [{LanguageId:0, Name:"", Description:""}] }) {
        try {
            return await http.Post(this.baseUrl + 'translation/create', translation /*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.PresentationApi.CreateTranslation()] Error:', e.message);
        }

    }


    static async Update(translation = { translations: [{LanguageId:0, Name:"", Description:""}] }) {
        try {
            return await http.Put(this.baseUrl + 'translation/update', translation /*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.PresentationApi.Update()] Error:', e.message);
        }

    }

    static async AddToSite(presentationId, siteId) {
        try {
            return await http.Post(this.baseUrl + 'add/' + presentationId + '/to/' + siteId /*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.PresentationApi.AddToSite()] Error:', e.message);
        }

    }
    static async PresentationDelete(presId) {
        try {
            return await http.Delete(this.baseUrl + 'delete/' + presId/*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.PresentationApi.PresentationDelete()] Error:', e.message);
        }
    }
}

export class MediaApi {
    constructor() { }

    static baseUrl = base + 'media/';

    static async Create(media = { Name: '', Description: '', TypeId: 0, LanguageId: 0, Value: '', ObjectValId: 0 }) {
        try {
            return await http.Post(this.baseUrl + 'create', media /*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.Media.Create()] Error:', e.message);
        }

    }

    static async Update(media = {
        "name": "string",
        "description": "string",
        "refpath": "string",
        "typeId": 0,
        "languageId": 0,
        "mediaId": 0 }) {
        try {
            return await http.Post(this.baseUrl + 'update', media /*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.Media.Update()] Error:', e.message);
        }

    }

    static async Get() {
        try {
            return await http.Get(this.baseUrl + 'all'/*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.Media.Get()] Error:', e.message);
        }

    }


    static async GetById(id) {
        try {
            return await http.Get(this.baseUrl + id + '/all/' /* , { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.MediaApi.GetById()] Error:', e.message);
        }
    }

    static async GetByMediaId(id) {
        try {
            return await http.Get(this.baseUrl + 'mediaid/' + id/*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.MediaApi.GetByMediaId()] Error:', e.message);
        }
    }

    static async GetByMediaIdAll(id) {
        try {
            return await http.Get(this.baseUrl + 'mediaid/' + id + '/all' /*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.MediaApi.GetByMediaId()] Error:', e.message);
        }
    }

    static async GetMediaStream(id) {
        try {
            return await http.Get(this.baseUrl + 'stream/video/' + id/*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.MediaApi.GetMediaStream()] Error:', e.message);
        }
    }
    static async GetImageStream(id) {
        try {
            return await http.Get(this.baseUrl + 'stream/image/' + id/*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.MediaApi.GetMediaStream()] Error:', e.message);
        }
    }

    static async AddMediaToObject(objectValId, mediaId) {
        try {
            return await http.Post(this.baseUrl + 'add/' + mediaId + '/to/' + objectValId /*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.MediaApi.AddMediaToObject()] Error:', e.message);
        }

    }

    

    static async RemoveMediaFromObject(objectValId, mediaId) {
        try {
            return await http.Post(this.baseUrl + 'remove/' + mediaId + '/from/' + objectValId /*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.MediaApi.AddMediaToObject()] Error:', e.message);
        }



    }

    static async MediaDelete(mediaId) {
        try {
            return await http.Delete(this.baseUrl + 'delete/' + mediaId/*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.FieldApi.MediaDelete()] Error:', e.message);
        }
    }

    static async ExportAllMedia() {
        try {
            return await http.DownloadAsync('api/v1/media/' + 'export/all/' /*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.MediaApi.ExportAllMedia()] Error:', e.message);
        }
    }

    static async ImportMedia(data) {
        try {
            return await http.PostFile('api/v1/media/' + 'import/', data  /*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.MediaApi.ImportMedia()] Error:', e.message);
        }
    }

}

export class LanguageApi {
    constructor() { }

    static baseUrl = base + 'Language/';

    static async Get() {
        try {
            return await http.Get(this.baseUrl + 'all'/*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.LanguageApi.Get()] Error:', e.message);
        }
    }

    static async GetById(id) {
        try {
            return await http.Get(this.baseUrl + 'id/' + id/*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.LanguageApi.GetById()] Error:', e.message);
        }
    }

    static async Create(language = { Name: '', Description: '', Initials: '' }) {
        try {
            console.log(language);
            return await http.Post(this.baseUrl + 'create', language /*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.LanguageApi.Create()] Error:', e.message);
        }

    }


    static async Update(language = { translations: [{LanguageId:0, Name:"", Description:""}] }) {
        try {
            return await http.Put(this.baseUrl + 'update', language /*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.LanguageApi.Update()] Error:', e.message);
        }

    }

    static async GetSelected() {
        try {
            return await http.Get(this.baseUrl + 'selected'/*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.LanguageApi.GetSelected()] Error:', e.message);
        }
    }

    static async LanguageDelete(id) {
        try {
            return await http.Delete(this.baseUrl + 'delete/' + id/*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.LanguageApi.Delete()] Error:', e.message);
        }
    }
}


export class PostsApi {
    constructor() { }

    static baseUrl = base + 'post/';


    static async Get() {
        try {
            return await http.Get(this.baseUrl + 'all'/*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.PostsApi.Get()] Error:', e.message);
        }
    }

    static async GetById(id) {
        try {
            return await http.Get(this.baseUrl + id/*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.PostsApi.GetById()] Error:', e.message);
        }
    }


    static async Create(post = { Title: '', Content: '', Userid: null, SiteId: '', CreatedAt: '', ExpiresAt: '', PublishedAt: '', Status: '', translations: [] }) {
        try {
            console.log(post);
            return await http.Post(this.baseUrl + 'create', post /*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.PostsApi.Create()] Error:', e.message);
        }

    }


    static async CreateTranslation(translation = { translations: [{Id: 0, LanguageId: 0, Title:'', Content:''}] }) {
        try {
            return await http.Post(this.baseUrl + 'translation/create', translation /*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.PostsApi.CreateTranslation()] Error:', e.message);
        }

    }


    static async UpdateTranslation(translation = { translations: [{Id: 0, LanguageId: 0, Title:'', Content:''}] }) {
        console.log(translation)
        try {

            return await http.Put(this.baseUrl + 'translation/update', translation /*, { authorization: auth.getToken().accessToken }*/)

        } catch (e) {
            console.error('[System.PostsApi.Update()] Error:', e.message);
        }

    }

    static async Update(post = { Id: 0, UserId: '', SiteId: 1, ExpiresAt: '2030-01-01T00:00:00', PublishedAt: '2021-01-01T00:00:00', Status: true }) {
        try {
            return await http.Put(this.baseUrl + 'update', post /*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.PostsApi.Update()] Error:', e.message);
        }

    }

    static async GetAllTranslation(id) {
        try {
            return await http.Get(this.baseUrl + 'translation/' + id/*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.PostsApi.GetByTranslation()] Error:', e.message);
        }
    }

    static async GetAllPostsOfSite(siteId) {
        try {
            return await http.Get(this.baseUrl + 'site/' + siteId + '/all' /*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.PostsApi.Get()] Error:', e.message);
        }
    }

    static async GetActivePostsOfSite(siteId) {
        try {
            return await http.Get(this.baseUrl + 'site/' + siteId + '/active' /*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.PostsApi.Get()] Error:', e.message);
        }
    }
    static async PostsDelete(PostId) {
        try {
            return await http.Delete(this.baseUrl + 'delete/' + PostId/*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.PostsApi.Category()] Error:', e.message);
        }
    }

}

export class ExhibitionApi {
    constructor() { }



    static baseUrl = base + 'Exhibition/';




    static async Get() {
        try {
            return await http.Get(this.baseUrl + 'all'/*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.ExhibitionApi.Get()] Error:', e.message);
        }
    }
    static async GetById(id) {
        try {
            return await http.Get(this.baseUrl + id/*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.ExhibitionApi.GetById()] Error:', e.message);
        }
    }



    static async GetBySiteId(id) {
        try {
            return await http.Get(this.baseUrl + "site/" + id + "/all"/*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.ExhibitionApi.GetBySiteId()] Error:', e.message);
        }
    }




    static async Create(exhibition = { Name: '', Description: '', image_def: 0, translations: [{LanguageId: 0, Name: '', Description:''}] }) {
        try {
            return await http.Post(this.baseUrl + 'create', exhibition /*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.ExhibitionApi.Create()] Error:', e.message);
        }



    }



    static async CreateTranslation(translation = { Name: '', Description: '', translations: [{LanguageId: 0, Name: '', Description:''}] }) {
        try {
            return await http.Post(this.baseUrl + 'translation/create', translation /*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.ExhibitionApi.CreateTranslation()] Error:', e.message);
        }



    }




    static async UpdateTranslation(translation = { translations: [{LanguageId: 0, Name: '', Description:''}] }) {
        try {



            return await http.Put(this.baseUrl + 'translation/update', translation /*, { authorization: auth.getToken().accessToken }*/)



        } catch (e) {
            console.error('[System.ExhibitionApi.Update()] Error:', e.message);
        }



    }



    static async Update(exhibition = { ExhibitionId: 0, Name: '', Description: '', image_def: 0 }) {
        try {
            return await http.Post(this.baseUrl + 'update', exhibition /*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.ExhibitionApi.Update()] Error:', e.message);
        }



    }

    static async AddToSite(exhibitionId, siteId) {
        try {
            return await http.Post(this.baseUrl + 'add/' + exhibitionId + '/to/' + siteId /*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.ExhibitionApi.AddToSite()] Error:', e.message);
        }

    }


    static async GetByTranslation(id) {
        try {
            return await http.Get(this.baseUrl + 'translation/' + id/*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.ExhibitionApi.GetByTranslation()] Error:', e.message);
        }
    }



    static async ExhibitionDelete(exhibitionId) {
        try {
            return await http.Delete(this.baseUrl + 'delete/' + exhibitionId/*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.ExhibitionApi.Category()] Error:', e.message);
        }
    }
}

export class FieldApi {
    constructor() { }

    static baseUrl = base + 'field/';

    static async Get() {
        try {
            return await http.Get(this.baseUrl + 'all'/*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.FieldApi.Get()] Error:', e.message);
        }
    }

    static async GetById(id) {
        try {
            return await http.Get(this.baseUrl + id/*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.FieldApi.GetById()] Error:', e.message);
        }
    }

    static async Create(field = { Name: '', Description: '', translations: [{LanguageId:0, Name:'', Description:''}] }) {
        try {
            return await http.Post(this.baseUrl + 'create', field /*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.FieldApi.Create()] Error:', e.message);
        }

    }



    static async CreateTranslation(translation = { Name: '', Description: '', translations: [{LanguageId:0, Name:'', Description:''}] }) {
        try {
            return await http.Post(this.baseUrl + 'translation/create', translation /*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.FieldApi.CreateTranslation()] Error:', e.message);
        }

    }


    static async Update(field = { translations: [{LanguageId:0, Name:'', Description:''}] }) {
        try {
            return await http.Put(this.baseUrl + 'translation/' + 'update', field/*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.FieldApi.Update()] Error:', e.message);
        }

    }

    static async GetByTranslation(id) {
        try {
            return await http.Get(this.baseUrl + 'translation/' + id/*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.FieldApi.GetByTranslation()] Error:', e.message);
        } 
    }

    static async AddToCategory(categoryId, fieldId) {
        try {
            return await http.Post(this.baseUrl + 'add/' + fieldId + '/to/' + categoryId /*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.FieldApi.AddToCategory()] Error:', e.message);
        }

    }
    static async RemoveFromCategory(categoryId, fieldId) {
        try {
            return await http.Post(this.baseUrl + 'remove/' + fieldId + '/from/' + categoryId /*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.FieldApi.RemoveFromCategory()] Error:', e.message);
        }

    }

    static async AddToMediaToField(mediaId, fieldId) {
        try {
            return await http.Post(this.baseUrl + 'add/Media/' + mediaId + '/to/' + fieldId /*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.FieldApi.AddToMediaToField()] Error:', e.message);
        }

    }

    static async RemoveMediaFromField(fieldId) {
        try {
            return await http.Post(this.baseUrl + 'remove/media' + '/from/' + fieldId /*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.FieldApi.RemoveMediaFromField()] Error:', e.message);
        }

    }

    static async FieldDelete(fieldId) {
        try {
            return await http.Delete(this.baseUrl + 'delete/' + fieldId/*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.FieldApi.FieldDelete()] Error:', e.message);
        }
    }

}

export class MatomoApi {
    
    
    constructor() {  }
    static baseUrl = "https://larosdev.ath.cx/insights/"
    
    static async MonthlyReport() {
        
        try {
            let siteIdMatomo = await getInsightsOfSite();
            console.log(siteIdMatomo);
            return this.baseUrl + `index.php?module=API&action=index&idSite=${siteIdMatomo}&period=day&date=today&method=ScheduledReports.generateReport&idReport=1&outputType=3&language=en&token_auth=${token}`;
        }
        catch (e) {
          console.error(e.message);
        }
    }

    static async GetLiveUsersCount() {
       

        try {
            let siteIdMatomo = await getInsightsOfSite();

            return this.baseUrl + `index.php?date=yesterday&module=API&method=Live.getCounters&format=json&lastMinutes=3&idSite=${siteIdMatomo}&period=day&token_auth=${token}`;
        }
        catch (e) {
            console.error(e.message);
        }

    }

    static async GetTopPageTitles() {
        try {
            let siteIdMatomo = await getInsightsOfSite();

            return this.baseUrl + `?module=API&method=Actions.getPageTitles&idSite=${siteIdMatomo}&period=day&date=last7&format=JSON&token_auth=${token}`;
        }
        catch (e) {
            console.error(e.message);
        }
    }

    static async GetChosenLanguages() {
        try {
            let siteIdMatomo = await getInsightsOfSite();

            return  this.baseUrl + `?module=API&method=CustomVariables.getCustomVariablesValuesFromNameId&idSite=${siteIdMatomo}&period=day&date=last7&format=JSON&token_auth=${token}&idSubtable=4`;
        }
        catch (e) {
            console.error(e.message);
        }
    }

    static async GetChosenAge() {
        try {
            let siteIdMatomo = await getInsightsOfSite();
            return  this.baseUrl + `?module=API&method=CustomVariables.getCustomVariablesValuesFromNameId&idSite=${siteIdMatomo}&period=day&date=last7&format=JSON&token_auth=${token}&idSubtable=2`;
        }
        catch (e) {
            console.error(e.message);
        }
    }

    static async GetChosenGender() {
        try {
            let siteIdMatomo = await getInsightsOfSite();
            return  this.baseUrl + `?module=API&method=CustomVariables.getCustomVariablesValuesFromNameId&idSite=${siteIdMatomo}&period=day&date=last7&format=JSON&token_auth=${token}&idSubtable=3`;
        }
        catch (e) {
            console.error(e.message);
        }
    }


    static async GetDailyInfo() {
        try {
            let siteIdMatomo = await getInsightsOfSite();
            return  this.baseUrl + `?module=API&method=VisitsSummary.get&idSite=${siteIdMatomo}&period=day&date=last7&format=JSON&token_auth=${token}`;
        }
        catch (e) {
            console.error(e.message);
        }
    }
    







  

    //static async LanguageDelete(id) {
    //    try {
    //        return await http.Delete(this.baseUrl + 'delete/' + id/*, { authorization: auth.getToken().accessToken }*/)
    //    } catch (e) {
    //        console.error('[System.LanguageApi.Delete()] Error:', e.message);
    //    }
    //}
}

export class MapsApi {
    constructor() { }

    static baseUrl = base + 'Map/';


    static async Get() {
        try {
            return await http.Get(this.baseUrl + "all"/*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.ExhibitionApi.Get()] Error:', e.message);
        }
    }

    static async GetAllMaps() {
        try {
            return await http.Get(this.baseUrl + "lng/all"/*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.ExhibitionApi.Get()] Error:', e.message);
        }
    }

    static async GetById(id) {
        try {
            return await http.Get(this.baseUrl + id/*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.ExhibitionApi.Get()] Error:', e.message);
        }
    }

    static async Delete(id) {
        try {
            return await http.Delete(this.baseUrl + "delete/" + id/*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.ExhibitionApi.Get()] Error:', e.message);
        }
    }
    static async Update(map = { MapId: 0, Value: '', translations: [{ translationId: 0, Id:0, Name:"", Description:"", LanguageId:0 }] }) {
        
        try {
            console.log(map);
            return await http.Put(this.baseUrl + "update", map/*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.ExhibitionApi.Get()] Error:', e.message);
        }
    }
    static async Create(map = { Name: '', Description: '', Value: null, translations: [{ translationId: 0, Id: 0, Name:"", Description:"", LanguageId:0 }] }) {
        try {
            return await http.Post(this.baseUrl + 'create', map /*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.ExhibitionApi.Create()] Error:', e.message);
        }

    }

    static async UpdateTranslation(map = { Name: '', Description: '', Value: '', translations: [{ translationId : 0, Id:0, Name:"", Description:"", LanguageId:0 }] }) {

        try {
            console.log(map);
            return await http.Put(this.baseUrl + "translation/update", map/*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.ExhibitionApi.Get()] Error:', e.message);
        }
    }


}

export class TagsApi {
    constructor() { }



    static baseUrl = base + 'tags/';




    static async Get() {
        try {
            return await http.Get( this.baseUrl + 'all'/*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.TagsApi.Get()] Error:', e.message);
        }
    }

    static async GetById(tagId) {
        try {
            return await http.Get( this.baseUrl + tagId/*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.TagsApi.GetById()] Error:', e.message);
        }
    }



    static async Create(tag = { name: '' }) {
        try {
            return await http.Post(this.baseUrl ,  tag /*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.TagsApi.Create()] Error:', e.message);
        }



    }

    static async Delete(tagId) {
        try {
            return await http.Delete(this.baseUrl + 'delete/' + tagId /*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.TagsApi.Delete()] Error:', e.message);
        }
    }


    static async Update(tag = { tagName: '' }, tagId) {
        try {
            return await http.Put(this.baseUrl + 'update/' + tagId, tag /*, { authorization: auth.getToken().accessToken }*/)
        } catch (e) {
            console.error('[System.TagsApi.Create()] Error:', e.message);
        }



    }

    

    
}

// const ss = getInsightsOfSite().then((s) => {
    
//     siteIdMatomo = s;
//     console.log(s);
// });



