import "./App.css";
import Login from "./components/Login/Login";
import ControlPanel from "./components/ControlPanel/ControlPanel";
import Sidebar from "./components/Sidebar/Sidebar";
import SidebarConfig from "./components/Configuration/SidebarConfig";
//import './js/tinymce/tinymce.min.js'

import Header from "./components/Header/Header";
import ContentManagement from "./components/Management/ContentManagement";
import AddNewWizard from "./components/Management/AddNewWizard";
import Notifications from "./components/Management/Notifications";
import Exhibitions from "./components/Management/Exhibitions";
import Presentation from "./components/Management/Presentation";
import Category from "./components/Management/Category";
import Events from "./components/Management/Events";
import Field from "./components/Management/Field";
import MediaGallery from "./components/Management/MediaGallery/MediaGallery";
import OutdoorMap from "./components/Maps/OutdoorMap";
import IndoorMap from "./components/Maps/IndoorMap";
import LanguageComponent from "./components/Configuration/LanguageComponent";
import SitesComponent from "./components/Configuration/SitesComponent";
import TagComponent from "./components/Configuration/TagComponent";
import ExportComponent from "./components/Configuration/ExportComponent";
import ImportComponent from "./components/Configuration/ImportComponent";
import ModalB from "./components/UI/Modal";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
//import { createBrowserHistory } from 'history';
import LanguageList from "./components/UI/LanguageList";

import { useState, useEffect } from "react";
import Front from "./Front-End/Pages/Front";
import Statistics from "./components/Management/Statistics";
import { MobileEditor } from "./Front-End/LayoutEditor/MobileEditor";

function App() {
  const changeUrl = (boo) => {
    setUrl(boo);
    console.log(url);
  };
  const [login, setLogin] = useState(false)
  const [url, setUrl] = useState(false);
  useEffect(() =>{
    setUrl(false);
  },[login])
  const handleLogin = () =>{
    setLogin(!login)
  }

  return (
    <div className="AppCenter">
      <Router basename={"/"}>
        {!url ? (
          <>
            {window.location.href.includes("Front") ||
            window.location.href.includes("front") ? (
              console.log("Front-End Here. Disable Back-end navbar")
            ) : (
              sessionStorage.getItem("user") !== null && <Sidebar setLogin={handleLogin} changeUrl={changeUrl}/>
            )}
            {/* <Sidebar.... /> */}
            <Routes>
              <Route path="/" element={<Login setLogin={handleLogin}/>} />
              <Route path="/Management" element={sessionStorage.getItem("user") !== null ? <ContentManagement /> : <Login setLogin={handleLogin}/>} />
              <Route path="/OutdoorMap" element={sessionStorage.getItem("user") !== null ? <OutdoorMap /> : <Login setLogin={handleLogin}/> } />
              <Route path="/IndoorMap" element={sessionStorage.getItem("user") !== null ? <IndoorMap /> : <Login setLogin={handleLogin}/>} />
              <Route path="/MediaGallery" element={sessionStorage.getItem("user") !== null ? <MediaGallery /> : <Login setLogin={handleLogin}/>} />
              <Route path="/Notifications" element={sessionStorage.getItem("user") !== null ? <Notifications /> : <Login setLogin={handleLogin}/>} />
              <Route path="/AddNew" element={sessionStorage.getItem("user") !== null ? <AddNewWizard /> : <Login setLogin={handleLogin}/>} />
              <Route path="/Presentations" element={sessionStorage.getItem("user") !== null ? <Presentation /> : <Login setLogin={handleLogin}/>} />
              <Route path="/Category" element={sessionStorage.getItem("user") !== null ? <Category /> : <Login setLogin={handleLogin}/>} />
              <Route path="/Events" element={sessionStorage.getItem("user") !== null ? <Events /> : <Login setLogin={handleLogin}/>} />
              <Route path="/Field" element={sessionStorage.getItem("user") !== null ? <Field /> : <Login setLogin={handleLogin}/>} />
              <Route path="/Exhibitions" element={sessionStorage.getItem("user") !== null ? <Exhibitions /> : <Login setLogin={handleLogin}/>} />
              <Route path="/ControlPanel" element={sessionStorage.getItem("user") !== null ? <ControlPanel /> : <Login setLogin={handleLogin}/>} />
              <Route path="/LayoutEditor" element={<MobileEditor />} />
              <Route
                path="/Languages"
                element={<LanguageComponent changeUrl={changeUrl} />}
              />
              <Route path="/Site" element={<SitesComponent />} />
              <Route path="/Export" element={<ExportComponent />} />
              <Route path="/Import" element={<ImportComponent />} />

              <Route path="/Front*" element={<Front />} />
              <Route path="/Statistics" element={<Statistics />} />
              <Route path="/ControlPanel" element={<ControlPanel />} />
              

            </Routes>
          </>
        ) : (
          <>
            {sessionStorage.getItem("user") !== null && <SidebarConfig setLogin={handleLogin} changeUrl={changeUrl} />}
            <Routes>
              <Route path="/Languages" element={sessionStorage.getItem("user") !== null ? <LanguageComponent /> : <Login setLogin={handleLogin} />} />
              <Route path="/Site" element={sessionStorage.getItem("user") !== null ? <SitesComponent /> : <Login setLogin={handleLogin}/>} />
              <Route path="/Export" element={sessionStorage.getItem("user") !== null ? <ExportComponent /> : <Login setLogin={handleLogin}/>} />
              <Route path="/Import" element={sessionStorage.getItem("user") !== null ? <ImportComponent /> : <Login setLogin={handleLogin}/>} />
              <Route path="/Management" element={sessionStorage.getItem("user") !== null ? <ContentManagement /> : <Login setLogin={handleLogin}/>} />
              <Route path="/Statistics" element={sessionStorage.getItem("user") !== null ? <Statistics /> : <Login setLogin={handleLogin}/>} />
              <Route path="/ControlPanel" element={sessionStorage.getItem("user") !== null ? <ControlPanel /> : <Login setLogin={handleLogin}/>} />
              <Route path="/TagManagement" element={sessionStorage.getItem("user") !== null ? <TagComponent /> : <Login setLogin={handleLogin}/>} />
              <Route path="/" element={<Login setLogin={handleLogin}/>} />

            </Routes>
          </>
        )}
      </Router>
    </div>
  );
}

export default App;
// export const history = createBrowserHistory({
//   basename: process.env.PUBLIC_URL
// });