// import Greek from '../../../images/Languages/greece-circular.png'
// import English from '../../../images/Languages/great-britain-circular.png'
// import German from '../../../images/Languages/germany-circular.png'
// import Spanish from '../../../images/Languages/spain2-circular.png'

export const LanguageData = [
    

    {
        text: 'Greek',
        image: '../../../images/Languages/greece-circular.png'
    }, 
    {
        text: 'English',
        image: '../../../images/Languages/great-britain-circular.png'
    }, 
    {
        text: 'German',
        image: '../../../images/Languages/germany-circular.png'
    }, 
    {
        text: 'Spanish',
        image: '../../../images/Languages/spain2-circular.png'
    }, 
    

];