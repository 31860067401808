import React from "react";
import { Button } from "react-bootstrap";

function Wall(props) {
  const onAddWall = () => {
    props.canvas.selection = false;
    props.setLineMode("wall");
    props.setIsDrawingLine(true);
  };
  return (
    <div className="mapPropertiesBtn" onClick={onAddWall}>
      Wall
    </div>
  );
}

export default Wall;
