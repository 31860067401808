import React, { useState, useEffect, Fragment } from "react";
import { LanguageApi, SiteApi } from "../../api/api.service";
import CardSkeleton from "../UI/CardSkeleton";
import { language, languageGr } from "../../api/lang";
import manage from "../../images/manage-orange.png";
import { Editor } from '@tinymce/tinymce-react';
import * as FaIcons from "react-icons/fa";
import ModalSite from './ModalSite'
import Swal from "sweetalert2";
import { authService } from "../../api/auth.service";

import $ from 'jquery';


import TableGeneral from "../UI/TableGeneral";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";

const SitesComponent = () => {
  const [languages, setLanguages] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [addSite, setAddSite] = useState(false);
  const [userRole, setUserRole] = useState("");
  const [site, setSite] = useState([]);
  const [idClicked, setIdClicked] = useState(0);
  const [languageChosenSite, setLanguageChosenSite] = useState("");
  const [deleteId, setDeleteId] = useState(0);
  const [tableChanged, setTableChanged] = useState(false);

 // const [translations, setTranslations] = useState([]);

  const [modalShow, setModalShow] = useState(false);

  

  useEffect(() => {
    document.body.className = "";
    // document.querySelector(
    //   ".pageHead"
    // ).innerHTML = `<h4 style='padding-top: 2%;text-align: center;color: #f7aa47;margin-left: -39vw;'><img style="height: 4vh;" class='fa fa-fw fa-file' src='${manage}' />${language.global.titles.site}</h4>`;

    getData();
    changeHead();
  }, [tableChanged]);


  const fetchUser = async () => {
    const user = await authService.getUserAsync();

    setUserRole(user.claims[0].value);
  }

  const getData = async () => {
    // const trans = await SiteApi.GetTranslation(2);
    // console.log(trans);
    const sitesArray = await SiteApi.Get();
    console.log(sitesArray);
    setSite(sitesArray);
    fetchUser();
    setLanguageChosenSite(sessionStorage.getItem("languageChosen"));

    const langs = await LanguageApi.Get();
    console.log(langs);
    setLanguages(langs);
    setLoading(false);
  };

  const editClick = async (e) => {
    setModalShow(true);
    console.log(modalShow);
   
  };


  const changeHead = () => {
    if (sessionStorage.getItem("languageChosen") === "EL") {
      document.querySelector(".pageHead").innerHTML =`<div style="width: 100%; height:100%; display: inline-flex; justify-content: center;"><h4 style='padding-top: 3.5vh;text-align: center;color: #f7aa47;'><img style="height: 4vh;margin-top:-1vh;" class='fa fa-fw fa-file' src='${manage}' />${languageGr.global.titles.site}</h4></div>`;
    } else {
      document.querySelector(".pageHead").innerHTML =`<div style="width: 100%; height:100%; display: inline-flex; justify-content: center;"><h4 style='padding-top: 3.5vh;text-align: center;color: #f7aa47;'><img style="height: 4vh;margin-top:-1vh;" class='fa fa-fw fa-file' src='${manage}' />${language.global.titles.site}</h4></div>`;
    }
  }

  const edit = (cell, row, rowIndex, formatExtraData) => {
    return (
      <FaIcons.FaRegEdit id={row.siteId} className="editIcon" onClick={editClick} Edit />
    );
  };

  const descriptionFormat = (data) => {
    return (
       data.replace(/<[^>]+>/g, '').substring(0, 100) + ' ....'
    );
  }



  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      console.log(e);
      console.log({ row, rowIndex });

      setIdClicked(row.siteId);
      //setCheckedList((prevState) => [...prevState, row.objectS_ID]);
      setDeleteId(row.siteId);
    },
  };


//   function dateFormatter(cell, row) {
//     if (row.createdAt) {
//       return (
//         <span>
//           <Moment format="DD/MM/YY">{cell}</Moment> 
//         </span>
//       );
//     }
//   }

//   function (data) {
//     return data.replace(/<[^>]+>/g, '').substring(0, 80) + ' ....';
// }
    

  //   const editClick = () => {
  //     setModalShow(!modalShow);
  //     console.log(modalShow);
  //   };

  //   const deleteClick = () => {
  //     setModalShow(!modalShow);
  //     console.log(modalShow);
  //   };

  //   const edit = (cell, row, rowIndex, formatExtraData) => {
  //     return <FaIcons.FaRegEdit onClick={editClick} Edit />;
  //   };

  //   const del = (cell, row, rowIndex, formatExtraData) => {
  //     return <FaIcons.FaRegTrashAlt onClick={deleteClick} Delete />;
  //   };

  const siteHeaders = [
    
    {
      dataField: "name",
      text: languageChosenSite === "EL"
      ? languageGr.table.title
      : language.table.title,
      sort: true,
      filter: textFilter(),
    },
    {
        dataField: "description",
        formatter: descriptionFormat,
        text: languageChosenSite === "EL"
        ? languageGr.table.description
        : language.table.description,
        sort: true,
    },
    {
        dataField: "edit",
        text: languageChosenSite === "EL"
        ? languageGr.table.edit
        : language.table.edit,
        formatter: edit,
    },

    // {
    //   dataField: "edit",
    //   text: "Edit",
    //   formatter: edit,
    // },
    // {
    //   dataField: "delete",
    //   text: "Delete",
    //   formatter: del,
    // },
  ];

  const handleHide = () => {
    
    setModalShow(false);
  
}

  const onReturnFromAdd = () => {
    setAddSite(false);
  };

  const onAddLang = () => {
    setAddSite(true);
  };

  

  return (
    <Fragment>
      <div>
          {modalShow && (
            <ModalSite
              onHide={handleHide}
              tableChanged={tableChanged}
              setTableChanged={setTableChanged}
              show={modalShow}
              onSetModalShow={setModalShow}
              targetId={idClicked}
              languages={languages}
              
              rowEvents={rowEvents}
              userRole={userRole}
            ></ModalSite>
          )}
        {isLoading ? (
          <div className="col-7 offset-3">
            <CardSkeleton />
          </div>
        ) : (
          <TableGeneral data={site} columns={siteHeaders} rowEvents={rowEvents}/>
        )}
     
      </div>
     
    </Fragment>
  );
};

export default SitesComponent;
