import { authService } from './auth.service.js';


export default class HttpService {
    /* constructor(baseUrl = "http://185.70.76.114:9001/")*/
    //constructor(baseUrl = "https://larosdev.ath.cx/rhodesdevgw/") {
    //    this.BaseUrl = baseUrl;
    //}

    // this.language = language; for below


    constructor(baseUrl) {
        this.BaseUrl = baseUrl;
        this.SendAnalytics();

    }
    async SendAnalytics() {

        const user = await authService.getUserAsync();
        console.log(user);
        var _paq = window._paq = window._paq || [];
        /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
        // you can set up to 5 custom variables for each visitor
        _paq.push(["setCustomVariable", 1, "UserName", user.username, "visit"]);
        _paq.push(["setCustomVariable", 2, "Company", user.companyId, "visit"]);
        _paq.push(["setCustomVariable", 3, "Applicaton", user.domain.application, "visit"]);
        _paq.push(['setUserId', user.username]);
        _paq.push(['trackPageView']);
        _paq.push(['enableLinkTracking']);
        (function () {
            var u = "https://larosdev.ath.cx/insights/";
            _paq.push(['setTrackerUrl', u + 'matomo.php']);
            _paq.push(['setSiteId', '7']);
            var d = document, g = d.createElement('script'), s = d.getElementsByTagName('script')[0];
            g.async = true; g.src = u + 'matomo.js'; s.parentNode.insertBefore(g, s);
        })();

    }


    async ReAuthenticate() {
        console.log(`[System Security]: Checking access token lifetime...`);
        return true;
    }



    // @descr Handle the error response from server
    async handleResponse(response) {
        if (response.statusCode) {
            if (response.statusCode === 401) {
                const done = await authService.refreshTokenAsync();
                if (!done) {
                    //location.href = "/Login";
                } else {
                    return true;
                }
            }
        }
        return false;
    }
    async Post(url, data, options = {
        authorization: true,
        cors: true,
        headers: {
            'Content-Type': 'application/json',
            'Accepts': 'application/json',
            'Accept-Encoding': 'gzip',
            /*'x-xenagos-lang': language*/
        }
    }) {

        let requestSettings = {
            method: 'POST',
            mode: options.cors ? 'cors' : 'no-cors',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                'Accepts': 'application/json',
                'Accept-Encoding': 'gzip',
                /*'x-xenagos-lang': language*/
            }
        }
        if (options.authorization) {
            const token = await authService.getTokenAsync();

            if (token === null) {
                authService.logOut();
            }

            requestSettings.headers['Authorization'] = 'Bearer ' + token.accessToken
        }
        if (options.cors) {
            requestSettings.headers['Access-Control-Allow-Origin'] = '*';
        }

        const response = await fetch(`${this.BaseUrl}${url}`, requestSettings);

        if (response.status === 401) {
            authService.logOut();
        }
        try {
            return await response.json();
        } catch (e) {
            return response.statusText;
        }
    }


    async PostFile(url, data, options = {
        authorization: true,
        cors: true,
        headers: {
            //'Content-Type': 'multipart/form-data;boundary=---011000010111000001101001',
            'Accepts': 'application/multipart/form-data',
            'Accept-Encoding': 'gzip',
            /*'x-xenagos-lang': language*/
        }
    }) {

        let requestSettings = {
            method: 'POST',
            mode: options.cors ? 'cors' : 'no-cors',
            body: data,
            headers: {
                //'Content-Type': 'multipart/form-data;boundary=---011000010111000001101001',
                'Accepts': 'application/multipart/form-data',
                'Accept-Encoding': 'gzip',
                /*'x-xenagos-lang': language*/
            }
        }
        if (options.authorization) {
            const token = await authService.getTokenAsync();

            if (token === null) {
                authService.logOut();
            }

            requestSettings.headers['Authorization'] = 'Bearer ' + token.accessToken
        }
        if (options.cors) {
            requestSettings.headers['Access-Control-Allow-Origin'] = '*';
        }

        const response = await fetch(`${this.BaseUrl}${url}`, requestSettings);

        if (response.status === 401) {
            authService.logOut();
        }
        try {
            return await response;
        } catch (e) {
            return response.statusText;
        }
    }



    async DownloadAsync(url, data, options = {
        authorization: true,
        cors: true,
        headers: {
            'Content-Type': 'application/json',
            'Accepts': 'application/json',
            'Accept-Encoding': 'gzip',
           /* 'x-xenagos-lang': language*/
        }
    }) {
        let requestSettings = {
            method: 'GET',
            mode: options.cors ? 'cors' : 'no-cors',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                'Accepts': 'application/octet-stream',
                /*'x-xenagos-lang': language*/
            }
        }
        if (options.authorization) {
            const token = await authService.getTokenAsync();
            requestSettings.headers['Authorization'] = 'Bearer ' + token.accessToken
        }
        if (options.cors) {
            requestSettings.headers['Access-Control-Allow-Origin'] = '*';
        }

        const response = await fetch(`${this.BaseUrl}${url}`, requestSettings);
        try {
            if (response.status === 400) {
                //$.notify('Data Not Found', { type: 'danger' });
                return new {
                    message: 'Data Not Found'
                }
            }

            return response;
        } catch (e) {
            return response.statusText;
        }
    }
    async Delete(url, options = {
        authorization: true,
        cors: true,
        headers: {
            'Content-Type': 'application/json',
            'Accepts': 'application/json',
            'Accept-Encoding': 'gzip',
           /* 'x-xenagos-lang': language*/
        }
    }) {
        let requestSettings = {
            method: 'DELETE',
            mode: options.cors ? 'cors' : 'no-cors',
            headers: {
                'Content-Type': 'application/json',
                'Accepts': 'application/json',
                'Accept-Encoding': 'gzip',
                /*'x-xenagos-lang': language*/
            }
        }
        if (options.authorization) {
            const token = await authService.getTokenAsync();

            if (token === null) {
                authService.logOut();
            }

            requestSettings.headers['Authorization'] = 'Bearer ' + token.accessToken
        }
        if (options.cors) {
            requestSettings.headers['Access-Control-Allow-Origin'] = '*';
        }

        const response = await fetch(`${this.BaseUrl}${url}`, requestSettings);

        if (response.status === 401) {
            authService.logOut();
        }

        try {
            return await response.json();
        } catch (e) {
            return response.statusText;
        }
    }
    async Put(url, data, options = {
        authorization: true,
        cors: true,
        headers: {
            'Content-Type': 'application/json',
            'Accepts': 'application/json',
            'Accept-Encoding': 'gzip',
            /*'x-xenagos-lang': language*/
        }
    }) {

        let requestSettings = {
            method: 'PUT',
            mode: options.cors ? 'cors' : 'no-cors',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                'Accepts': 'application/json',
                'Accept-Encoding': 'gzip',
               /* 'x-xenagos-lang': language*/
            }
        }
        if (options.authorization) {
            const token = await authService.getTokenAsync();

            if (token === null) {
                authService.logOut();
            }

            requestSettings.headers['Authorization'] = 'Bearer ' + token.accessToken
        }
        if (options.cors) {
            requestSettings.headers['Access-Control-Allow-Origin'] = '*';
        }

        const response = await fetch(`${this.BaseUrl}${url}`, requestSettings);
        if (response.status === 401) {
            authService.logOut();
        }

        try {
            return await response.json();
        } catch (e) {
            return response.statusText;
        }
    }
    async Get(url, options = {
        authorization: true,
        cors: true,
        headers: {
            'Content-Type': 'application/json',
            'Accepts': 'application/json',
            'Accept-Encoding': 'gzip',
            'x-Custom-Lang': 'EN'
        }
    }) {
        let requestSettings = {
            method: 'GET',
            mode: options.cors ? 'cors' : 'no-cors',
            headers: {
                'Content-Type': 'application/json',
                'Accepts': 'application/json',
                'Accept-Encoding': 'gzip',
                'x-Custom-Lang': sessionStorage.getItem('languageChosen') ? sessionStorage.getItem('languageChosen') : "EN"
            }
        }
        if (options.authorization) {
            const token = await authService.getTokenAsync();

            if (token === null) {
                authService.logOut();
            }
            requestSettings.headers['Authorization'] = 'Bearer ' + token.accessToken
        }
        if (options.cors) {
            requestSettings.headers['Access-Control-Allow-Origin'] = '*';
        }
        const response = await fetch(`${this.BaseUrl}${url}`, requestSettings);

        if (response.status === 401) {
            authService.logOut();
        }
        //console.log(response);
        try {
            return await response.json();
        } catch (e) {
            return response.statusText;
        }
    }
}

/*'x-xenagos-lang': language*/