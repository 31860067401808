import React, { useState, useEffect } from "react";
import Cropper from "react-easy-crop";
import getCroppedImg from "./CropImage";
import classes from "../../UI/Edit.module.css";

import { MediaApi, LanguageApi } from "../../../api/api.service";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import {language, languageGr} from '../../../api/lang'
import Select from "react-select";
import Swal from "sweetalert2";

const aspectRatios = [
  { value: 4 / 3, text: "4/3" },
  { value: 16 / 9, text: "16/9" },
  { value: 1 / 2, text: "1/2" },
  { value: 21 / 9, text: "21/9" },
];

const ImageCropDialog = ({
  id,
  imageUrl,
  cropInit,
  zoomInit,
  aspectInit,
  onCancel,
  setCroppedImageFor,
  setCroppedImagetoCreate,
  languageChosenSite,
  setChangedMediaArray
}) => {
  if (zoomInit == null) {
    zoomInit = 1;
  }

  if (cropInit == null) {
    cropInit = { x: 0, y: 0 };
  }

  if (aspectInit == null) {
    aspectInit = aspectRatios[0];
  }

  // useEffect(() => {
  //   start();
  //   //document.querySelector(".pageHead").innerHTML = `<h4 style='padding-top: 2%;text-align: center;color: #f7aa47;margin-left: -39vw;'><img style="height: 4vh;" class='fa fa-fw fa-file' src='${manage}' />${language.global.titles.media}</h4>`;
  // }, []);

  const [zoom, setZoom] = useState(zoomInit);
  const [crop, setCrop] = useState(cropInit);
  const [aspect, setAspect] = useState(aspectInit);
  const [addTitleChange, setAddTitleChange] = useState("");
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [modalClicked, setModalClicked] = useState(false);
  const [languageSelected, setLanguageSelected] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [sourceCropped, setSourceCropped] = useState("");
  let url;
  const onHide = () => {
    setModalShow(false);
  }


  const start = async () => {
    const languages = await LanguageApi.Get();
    let optionsForLangs = []; 
    for(let l of languages) {
      optionsForLangs.push({
        value: l.languageId,
        label: l.name,
      });
    }
    setLanguages(optionsForLangs);
  }

  

  const onModalClicked = () => {
    setModalClicked(true);
    start();
    
  }

  const onModalClosed = () => {
    setModalClicked(false);

  }

  const onLangChange = (e) => {
    setLanguageSelected(e);
    console.log(e);
  }


  const onCropChange = (crop) => {
    setCrop(crop);
  };

  const onZoomChange = (zoom) => {
    setZoom(zoom);
  };

  const onAspectChange = (e) => {
    const value = e.target.value;
    const ratio = aspectRatios.find((ratio) => ratio.value == value);
    setAspect(ratio);
  };

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const onCrop = async () => {
    const croppedImageUrl = await getCroppedImg(
      `data:image/png;base64, ${imageUrl}`,
      croppedAreaPixels
    );
    setCroppedImageFor(id, crop, zoom, aspect, croppedImageUrl);
  };

 

  async function InitToLanguageId(init) {
    var l = await LanguageApi.Get();

    var langObject = l.find((l) => l.initials === init);

    if (langObject) {
      return langObject.languageId;
    }
  }

  // const startEditing = (e) => {
  //   e.target.style.display = "none";
  //   document.querySelectorAll(".buttonArea").forEach(b => b.style.display = "block");
  // }

//   const blobToBase64 = blob => {
//     const reader = new FileReader();
//     reader.readAsDataURL(blob);
//     return new Promise(resolve => {
//       reader.onloadend = () => {
//         resolve(reader.result);
//       };
//     });
//   };

  const returnSource = (url) => {
    setSourceCropped(url);
    
  }

  const onCreate = async (e) => {
    

    setModalShow(true);
    const croppedImageUrl = await getCroppedImg(
        `data:image/png;base64, ${imageUrl}`,
        croppedAreaPixels
    );

    const urlCropped =  setCroppedImagetoCreate(id, crop, zoom, aspect, croppedImageUrl);
    //  setSourceCropped(urlCropped);
    //  setSourceCropped(urlCropped);
    //console.log(sourceCropped);
    url = urlCropped;


    const urlCroppedBase64 = urlCropped.slice(23);
    returnSource(urlCroppedBase64);
      
    
   
    const targetId = e.target.id;
    e.target.innerHTML = '<span style="text-align:center;" class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>';
    returnSource(urlCroppedBase64);
    
    const targetMedia = await MediaApi.GetByMediaId(parseInt(targetId));
    const languageId = await InitToLanguageId(targetMedia.initials);
    e.target.innerHTML = "Crop";

    

    

    if(addTitleChange === "" || Object.keys(languageSelected).length === 0 ) {
      Swal.fire({
        title: 'Something went wrong',
        text: `Please enter a title and choose a language`,
        icon: 'warning', 
      })
      return;
    }

    const media = {
      name: targetMedia.name,
      description: addTitleChange,
      typeId: 10,
      LanguageId: languageSelected.value,
      value: urlCroppedBase64,
      refPath: targetMedia.ref_Path
      
    };
    
    const newMedia = await MediaApi.Create(media);


    setChangedMediaArray(newMedia.mediA_ID)
   
    if(newMedia) {
      Swal.fire({
        title: 'Success',
        text: `Image Cropped successfully`,
        icon: 'success', 
      })
      document.querySelector(".btn-close").click();
    }
   
    console.log(e.target);

  };

  return (
    
    <div>

{
      <Modal show={modalClicked} onHide={onModalClosed} size="lg" centered >
    <Modal.Header closeButton>
      <Modal.Title>{languageChosenSite === "EL" ? languageGr.global.mediaWords.cropTitle : language.global.mediaWords.cropTitle}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Form>
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label>{languageChosenSite === "EL" ? languageGr.global.transName : language.global.transName}</Form.Label>
          <Form.Control
            type="text"
            placeholder={languageChosenSite === "EL" ? languageGr.global.editWords.namePlaceholder : language.global.editWords.namePlaceholder}
            autoFocus
            onChange={(e) => setAddTitleChange(e.target.value)}
            value={addTitleChange}
          />
        </Form.Group>
        <Form.Group
          className="mb-3"
          controlId="exampleForm.ControlTextarea1"
        >
          {/* <Form.Label>{languageChosenSite === "EN" ? language.global.transDesc : languageGr.global.transDesc}</Form.Label> */}
          {/* <img src={"data:image/png;base64, " + url} /> */}
          {/* <Form.Control placeholder="Enter message.." value={addDescriptionChange}  onChange={(e) => setAddDescriptionChange(e.target.value)} as="textarea" rows={5} /> */}
          <Form.Label>{languageChosenSite === "EL" ? languageGr.global.language.choose : language.global.language.choose}</Form.Label>
          
              <Select
                className={classes.center}
                value={languageSelected}
                options={languages}
                onChange={onLangChange}
                
                isSearchable
              />
        </Form.Group>
      </Form>
    </Modal.Body>
    <Modal.Footer className="modal_footer">
      {/* <Button variant="secondary" onClick={handleClose}>
        Close
      </Button> */}
      <Button variant="success lg"  id={id} style={{borderRadius: 12}} onClick={onCreate}>
      {languageChosenSite === "EL" ? languageGr.global.mediaWords.crop : language.global.mediaWords.crop}
      </Button>
    </Modal.Footer>
    </Modal>
  }

      <div className="backdrop"></div>
      
      <div className="crop-container">
      
            <Cropper
            id={id}
            image={`data:image/png;base64, ${imageUrl}`}
            zoom={zoom}
            crop={crop}
            aspect={aspect.value}
            onCropChange={onCropChange}
            onZoomChange={onZoomChange}
            onCropComplete={onCropComplete}
            />
         
      </div>
      
      <div className="controls">
        <div className="controls-upper-area">
          <input
            type="range"
            min={1}
            max={3}
            step={0.1}
            value={zoom}
            onInput={(e) => {
              onZoomChange(e.target.value);
            }}
            className="slider"
          />

          <select className="form-select-xs" onChange={onAspectChange}>
            {aspectRatios.map((ratio) => (
              <option
                key={ratio.text}
                value={ratio.value}
                selected={ratio.value === aspect.value}
              >
                {ratio.text}
              </option>
            ))}
          </select>
          <span className="aspectRatio">: Aspect Ratio</span>
        </div>
        
        <div className="button-area">
          {/* <button onClick={startEditing}>Edit Image</button> */}
        <button className="buttonArea btn btn-warning" onClick={onCancel}>
           {languageChosenSite === "EL" ? languageGr.content.clearSelectedLabel.back : language.content.clearSelectedLabel.back}
            
          </button>
          <button
            className="buttonArea btn btn-success"
            id={id}
            onClick={onModalClicked}
          >
           {languageChosenSite === "EL" ? languageGr.global.mediaWords.crop : language.global.mediaWords.crop}
          </button>
          {/* <button className="buttonArea btn btn-success" onClick={onCrop}>
            Replace
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default ImageCropDialog;
