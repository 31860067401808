import React, { useState, useEffect } from "react";
import "./Login.css";
import { useNavigate } from "react-router-dom";
import { language, languageGr } from "../../api/lang";
import axios from "axios";

import usernameIcon from "../../images/username.png";
import passwordIcon from "../../images/password.png";
import loginIcon from "../../images/login.png";
import gPlayIcon from "../../images/gPlay.png";
import Swal from "sweetalert2";
import LoginLoading from "./LoginLoading";
import { authService } from '../../api/auth.service'
import manage from "../../images/manage-orange.png";


//import "../css/login.css";


const Login = ({setLogin}) => {
  const [visibility, setVisibility] = useState(false);
  const [loadingAnime, setLoadingAnime] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [languageChosenSite, setLanguageChosenSite] = useState("");

 


  useEffect(() => {

    
    // document.querySelector(".navbar").style.display = "none"
    // document.querySelector(".pageHead").style.display = "none"
    document.body.className = 'bod';
    setLanguageChosenSite(sessionStorage.getItem('languageChosen'));
    
   
    // document.querySelector(".pageHead").style.background = "#1c4325";
    // document.querySelector(".pageHead").innerHTML = `<h4 style='padding-top: 2%;text-align: center;color: #f7aa47;margin-left: -39vw;'><img style="height: 4vh;" class='fa fa-fw fa-file' src='${manage}' />Login Page</h4>`;

  }, []);

  const date = new Date();

  const navigate = useNavigate();

  const logIn = async () => {
    setLoadingAnime(true)
    try {

      const apiCall = "https://api.dei.prismasense.com/auth/" + "connect/token";

      const data = { client_id: '75e08ccfbc94486784e15990d4868fae', client_secret: 'UcnsOEpdt9SSWfDWHTvr', username: username, password: password, grant_type: 'password' };

      let formBody = [];
      for (let item in data){
        let key = encodeURIComponent(item)
        let value = encodeURIComponent(data[item])
        formBody.push(key + "=" + value)
      }
      formBody = formBody.join("&");
      const response = await axios(apiCall, {

        method: "POST",

        data: formBody,

        headers: { "content-type": "application/x-www-form-urlencoded" },

      });

      const token = {
        accessToken: response.data.access_token,
        refreshToken: response.data.refresh_token,
        expiresIn: response.data.expires_in,
        tokenType: response.data.token_type
      }

      const profile = await axios.get("https://api.dei.prismasense.com/auth.api/v1/users/profile", {
        headers: { 
          "content-type": "application/json",
          "authorization": `Bearer ${token.accessToken}`
      }
      })

      token.user = profile.data
      authService.saveToken(token);
      setLogin();

      navigate("/ControlPanel");
      //return response.data.logInResponse

    } catch (error) {

      console.log(error + ": ApiService.js - logIn()");
      Swal.fire({
        title: language.media.invalidAction,
        text: language.login.invalid,
        icon: "warning",
        button: "OK",
        
      })
      setLoadingAnime(false)
      setLogin();

      return;


    }

  }
  

  const visibilityHandler = () => {
    setVisibility(!visibility);
    //console.log(passwordType);
  };

  const loginAnimeStart = () => {
    //e.preventDefault();
    //setLoadingAnime(!loadingAnime);
  };

  const FormHandler = (e) => {
    
    e.preventDefault();
    //loginAnimeStart();
    console.log('username 👉️', username);
    console.log('password 👉️', password);
    setUsername('');
    setPassword('');

    // navigate('/ControlPanel');
    //loginAnimeStart();
    
  }

  // const params = new URLSearchParams();
  // params.append('username', username)
  // params.append('password', password)
  // params.append('client_id', "75e08ccfbc94486784e15990d4868fae")
  // params.append('client_secret', "UcnsOEpdt9SSWfDWHTvr")
  // params.append('grant_type', "password")
  // const call = "https://api.dei.prismasense.com/auth/connect/token";

  // const TokenBringer = () => {
  //   const creds = {
  //     username: username,
  //     password: password,
  //     client_id: "75e08ccfbc94486784e15990d4868fae",
  //     client_secret: "UcnsOEpdt9SSWfDWHTvr",
  //     grant_type: "password"
  //   }
  //   const config = {
  //     headers: {
  //       'Content-Type': 'application/x-www-form-urlencoded'
  //     }
  //   }
  //   // axios.post(call,
  //   //   params, config).then(response => {
  //   //     console.log(response.data);

  //   //     authService.saveToken(response.data.access_token)
  //   //     //window.location.href = data.redirectUrl;
  //   //   })
      


  // }
  // if(visibility) {
  //     setPasswordType("text");
  //     console.log(passwordType);
  // } else {
  //     setPasswordType("password");
  //     console.log(passwordType);

  // }

  return (
    <>
      <div className="masthead">
        <div className="content-inside">
          <div className="outterForm">
            <form className="needs-validation" id="loginForm" onSubmit={FormHandler}>
              <div className="col-9 offset-2">
                <div className="form-group fullWidth inline">
                  <img
                    className="fa fa-file rounded float-left"
                    src={usernameIcon}
                    style={{marginRight: "1rem"}}
                  />
                  <input
                    type="text"
                    className="form-control"
                    id="username"
                    onChange={event => setUsername(event.target.value)}
                    value={username}
                    name="UserName"
                    required=""
                    autocomplete="off"
                    placeholder="Username"
                    data-val="true"
                    data-val-minlength="The field User Name must be a string or array type with a minimum length of '1'."
                    data-val-minlength-min="1"
                    data-val-required="The User Name field is required."
                  />
                </div>
                <div className="form-group fullWidth inline">
                  <img
                    className="fa  fa-file marg rounded float-left"
                    src={passwordIcon}
                    style={{marginRight: "1rem"}}
                  />
                  <input
                    type={visibility ? "text" : "password"}
                    className="form-control"
                    id="password"
                    name="Password"
                    onChange={event => setPassword(event.target.value)}
                    value={password}
                    required=""
                    autocomplete="off"
                    placeholder="Password"
                    data-val="true"
                    data-val-minlength="The field Password must be a string or array type with a minimum length of '1'."
                    data-val-minlength-min="1"
                    data-val-required="The Password field is required."
                  />
                  <i
                    id="notV"
                    onClick={visibilityHandler}
                    style={{ display: visibility ? "block" : "none" }}
                    className="bi bi-eye"
                  ></i>
                  <i
                    id="V"
                    onClick={visibilityHandler}
                    style={{ display: visibility ? "none" : "block" }}
                    className="bi bi-eye-slash"
                  ></i>
                </div>

                <br />
                <br />

                <div className="form-button inline fullWidth">
                  <img
                    class="fa fa-file"
                    src={loginIcon}
                    style={{ marginRight: "1rem" }}
                  />
                  <button
                    type="submit"
                    id="submitBtn"
                    onClick={logIn}
                    className="btn btn-primaryEnLog littleTop"
                  >
                   {languageChosenSite === "EL" ? languageGr.global.login : language.global.login}
                  </button>
                  <br />
                  
                </div>
                
                <LoginLoading show={loadingAnime} />

              </div>
            </form>
          </div>
        </div>
      </div>

      <footer className="footer" id="displayFooter">
       
        <div className="row lightBorder"></div>

        <div className="text-center">
          <small>
            &copy; {date.getYear() + 1900} - Xenagos Ver. 1.0.1.0 Prisma
            Electronics 
          </small>
        </div>
        <div className="download">
          <a href="">
            <img className="gPlay rounded" src={gPlayIcon} />{" "}
          </a>
        </div>
      </footer>
    </>
  );
};

export default Login;
