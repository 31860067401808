import React, {useState, useEffect} from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Edit from './Edit'
import Translations from './Translations'
import position from "../../images/position-green.png";
import multimedia from "../../images/position-green.png";
import fields from "../../images/fields-green.png";
import tranlsation from "../../images/translation-grren.png";
import edit from "../../images/edit-green-s.png";
import FieldsManagement from "./FieldsManagement";
import LocationManagement from "./LocationManagement";
import MediaManagement, { tab4 } from "../Management/MediaGallery/MediaManagement";
import {language, languageGr} from '../../api/lang'
import MediaPageMan from "../Management/MediaPageMan";
import {
  ExhibitNew,
 ObjectApi
} from "../../api/api.service";


const FillModal = (props) => {

  //const [idClicked, setIdClicked] = useState(props.targetId);
  //const [data, setData] = useState(props.data);
  const [languageChosenSite, setLanguageChosenSite] = useState("");
  const [object, setObject] = useState({});
  const [transStatus, setTransStatus] = useState(false);
  const [fieldStatus, setFieldStatus] = useState(false);
  const [mediaStatus, setMediaStatus] = useState(false);
  
  useEffect(() => {
    console.log(props.site);
    setLanguageChosenSite(sessionStorage.getItem('languageChosen'));
    getObject();
  }, [props.targetId]);
  const getObject = async () => {
    const data = await ExhibitNew.GetAllValuesOfObject(props.targetId);
    setObject(data);
  }

  const handleStatusShown = (e) => {
    
    if(e === "Translations") {
      setTransStatus(true);
    } else if(e === "Fields") {
      setFieldStatus(true);
    } else if(e === "Media") {
      setMediaStatus(true);
    } 
   
  }
  


  return (
    <Tabs onSelect={handleStatusShown} defaultActiveKey="Edit" id="fill-tab-example" className="mb-3 container" fill>
      <Tab style={{ "color": "#1c4325" }} eventKey="Edit" title={languageChosenSite === "EL" ? languageGr.content.tabs.tab1 : language.content.tabs.tab1}>
        
        {/* <img className="navPhoto" src={edit} /> */}
        <Edit userRole={props.userRole} languageChosenSite={languageChosenSite}  alterTable={props.alterTable} tableChanged={props.tableChanged} dataObject={object} site={props.site} targetId={props.targetId} rowEvents={props.rowEvents} data={props.data} />
      </Tab>
      <Tab style={{ "color": "#1c4325" }} eventKey="Translations" title={languageChosenSite === "EL" ? languageGr.content.tabs.tab2 : language.content.tabs.tab2}>
      {/* <Edit rowEvents={props.rowEvents} targetId={idClicked} data={data}/> */}
        {transStatus && <Translations userRole={props.userRole} exhibitChanged={props.exhibitChanged} languageChosenSite={languageChosenSite} alterTable={props.alterTable} tableChanged={props.tableChanged} rowEvents={props.rowEvents} targetId={props.targetId} />}
      </Tab>
      <Tab  style={{ "color": "#1c4325" }} eventKey="Fields" title={languageChosenSite === "EL" ? languageGr.content.tabs.tab3 : language.content.tabs.tab3}>
       {fieldStatus && <FieldsManagement userRole={props.userRole} languageChosenSite={languageChosenSite}  site={props.site} rowEvents={props.rowEvents} targetId={props.targetId}/> }
      </Tab>
      <Tab style={{ "color": "#1c4325" }} eventKey="Media" title={languageChosenSite === "EL" ? languageGr.content.tabs.tab4 : language.content.tabs.tab4}>
       {mediaStatus && <MediaPageMan userRole={props.userRole} languageChosenSite={languageChosenSite}  objectValues={object} targetId={props.targetId} rowEvents={props.rowEvents}/>}
        {/* {tab4()} */}
      </Tab>
      {props.site.type == 2 && 
        <Tab style={{ "color": "#1c4325" }} eventKey="Location" title={languageChosenSite === "EL" ? languageGr.content.tabs.tab5 : language.content.tabs.tab5}>
          <LocationManagement object={object} userRole={props.userRole} languageChosenSite={languageChosenSite}  targetId={props.targetId} /> 
        </Tab>
      }
    </Tabs>
  );
};

export default FillModal;
