import React, { useState, useEffect } from 'react'
import HTMLRenderer from 'react-html-renderer';
import { useParams } from "react-router-dom";

//Components
import Map from "../Map/Map.jsx";
import GallerySlider from '../Slider/GallerySlider.jsx';
import { ImageMedia, VideoMedia, AudioMedia } from '../Gallery/Media/Media.jsx';

//API
import { ExhibitNew, MediaApi } from "../../api/api.service"

//Bootstrap
import { Container, Row, Col, Tabs, Tab, Table, Nav } from 'react-bootstrap';

//Language values
import Greek from "../Languages/Greek.json";
import English from "../Languages/English.json";

//CSS
import "./detailsExhibitPage.css"

const sounds = [
    {
        sound: "http://commondatastorage.googleapis.com/codeskulptor-demos/pyman_assets/ateapill.ogg",
        type: "audio/ogg",
        name: "audio_1"
    },
    {
        sound: "http://commondatastorage.googleapis.com/codeskulptor-demos/pyman_assets/intromusic.ogg",
        type: "audio/ogg",
        name: "audio_2"
    },
    {
        sound: "http://commondatastorage.googleapis.com/codeskulptor-demos/riceracer_assets/fx/engine-4.ogg",
        type: "audio/ogg",
        name: "audio_3"
    },
];

function DetailsExhibitPage() {
    const [textLanguage, setTextLanguage] = useState(Greek);
    const [exhibitValues, setExhibitValues] = useState([]);
    const [galleryImages, setGalleryImages] = useState([]);
    const [video, setVideo] = useState([]);
    const [audio, setAudio] = useState([]);
    const _ID = useParams();

    useEffect(() => {
        getExhibitImage();
        setLanguage();
    }, []);

    const getExhibitImage = async () => {
        const exhibitValues = await ExhibitNew.GetAllValuesOfObject(_ID.exhibitId)
        console.log(exhibitValues);
        setExhibitValues(exhibitValues);

        const initialImage = await MediaApi.GetByMediaId(exhibitValues.imagE_DEF)
        setGalleryImages((prevState) => [...prevState, { mediaId: initialImage.mediaId, value: initialImage.value }]);

        exhibitValues.associatedMedia.map(async (value) => {
            for (let i in value.media) {
                if (value.type === "IMAGE") {
                    try {
                        const galleryImages = await MediaApi.GetByMediaId(value.media[i].media_Id)
                        setGalleryImages((prevState) => [...prevState, galleryImages])
                    } catch (exceptionVar) {
                        console.log(exceptionVar)
                    }
                }
                else if (value.type === "VIDEO") {
                    try {
                        const video = await MediaApi.GetByMediaId(value.media[i].media_Id)
                        setVideo((prevState) => [...prevState, video])
                    } catch (exceptionVar) {
                        console.log(exceptionVar)
                    }
                }
                else if (value.type === "AUDIO") {
                    try {
                        const audio = await MediaApi.GetByMediaId(value.media[i].media_Id)
                        console.log(audio)
                        setAudio((prevState) => [...prevState, audio])
                    } catch (exceptionVar) {
                        console.log(exceptionVar)
                    }
                }
            }
        })
    }

    const setLanguage = () => {
        sessionStorage.languageChosen === "EN"
            ? setTextLanguage(English)
            : setTextLanguage(Greek);
    };

    return (
        <>
            <div>
                <div id='exhibit-area'>
                    <Row>
                        <Col lg={6}>
                            <GallerySlider galleryImages={galleryImages} />
                            {console.log(galleryImages)}
                        </Col>
                        <Col lg={6} className='exhibit-details-col'>
                            <h3>{exhibitValues.name}</h3>
                            <div className='pt-5 gy-4 text-start'>
                                <HTMLRenderer html={exhibitValues.dscr} />
                            </div>
                        </Col>
                    </Row>
                </div>
                <div id='exhibit-tabs-area'>
                    <Container className='exhibit-tabs-container'>
                        <Tabs
                            defaultActiveKey="description"
                            id="uncontrolled-tab-example"
                            className="exhibit-tabs mb-3 justify-content-center"
                        >
                            <Tab eventKey="description" title={textLanguage.exhibitPage.fieldsText} className='exhibit-tab p-2'>
                                <Table striped>
                                    <tbody >
                                        <tr>
                                            <td><strong>{textLanguage.exhibitPage.fields.name}</strong></td>
                                            <td>{exhibitValues.name}</td>
                                        </tr>
                                        <tr>
                                            <td><strong>{textLanguage.exhibitPage.fields.category}</strong></td>
                                            <td>{exhibitValues.category}</td>
                                        </tr>
                                        <tr>
                                            <td><strong>{textLanguage.exhibitPage.fields.categoryID}</strong></td>
                                            <td>{exhibitValues.categorY_ID}</td>
                                        </tr>
                                        <tr>
                                            <td><strong>{textLanguage.exhibitPage.fields.latitude}</strong></td>
                                            <td>{exhibitValues.latitude}</td>
                                        </tr>
                                        <tr>
                                            <td><strong>{textLanguage.exhibitPage.fields.longitude}</strong></td>
                                            <td>{exhibitValues.longitude}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Tab>
                            <Tab eventKey="media" title={textLanguage.exhibitPage.media} className='exhibit-tab p-2'>
                                <Tab.Container id="left-tabs-example" defaultActiveKey="images">
                                    <Row>
                                        <Col sm={2}>
                                            <Nav variant="pills" className="flex-column">
                                                <Nav.Item>
                                                    <Nav.Link eventKey="images">{textLanguage.exhibitPage.mediaTabs.images}</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="videos">{textLanguage.exhibitPage.mediaTabs.videos}</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="audios">{textLanguage.exhibitPage.mediaTabs.audios}</Nav.Link>
                                                </Nav.Item>
                                            </Nav>
                                        </Col>
                                        <Col sm={10}>
                                            <Tab.Content>
                                                <Tab.Pane eventKey="images" className='p-2'>
                                                    {galleryImages == 0 ? <p>{textLanguage.exhibitPage.mediaTexts.images}</p> : <ImageMedia galleryImages={galleryImages} />}
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="videos" className='p-2'>
                                                    {video == 0 ? <p>{textLanguage.exhibitPage.mediaTexts.videos}</p> : <VideoMedia video={video} />}
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="audios" className='p-2'>
                                                    {audio == 0 ? <p>{textLanguage.exhibitPage.mediaTexts.audios}</p> : <AudioMedia audio={audio} />}
                                                </Tab.Pane>
                                            </Tab.Content>
                                        </Col>
                                    </Row>
                                </Tab.Container>
                            </Tab>
                            <Tab eventKey="map" title={textLanguage.exhibitPage.map} className='exhibit-tab p-2'>
                                <div>
                                    <Map position={{ lat: exhibitValues.latitude, lng: exhibitValues.longitude }} />
                                </div>
                            </Tab>
                        </Tabs>
                    </Container>
                </div>
            </div >
        </>
    )
}

export default DetailsExhibitPage