import { useState, useEffect } from "react";
import { ObjectApi, LanguageApi, ExhibitNew } from "../../api/api.service";
import { next, prev } from "../UI/F&BButtons";
import * as FaIcons from "react-icons/fa";
import Swal from "sweetalert2";
import { language, languageGr } from "../../api/lang";

const Step2 = ({exhibit, languageChosenSite, userRole}) => {


    const [translationsArr, setTranslationsArr] = useState([]);
    const [languages, setLanguages] = useState([]);
    let translations;
    
    useEffect(() => {
        
        getData();
   

    }, [exhibit]);
    const getData = async () => {
      console.log(exhibit)
        translations = await ExhibitNew.GetByTranslation(exhibit.objectId); //object comes from outside
        console.log(translations); 
        setTranslationsArr(translations);
        
        const langs = await LanguageApi.Get();
        console.log(langs);

        setLanguages(langs);
        console.log(exhibit);
       
        clickLang();
        //document.querySelectorAll(".act")[1].click();
    }


    const clickLang = () => {
      if(document.querySelectorAll(".act")[1]) {

        languageChosenSite === "EL" ? document.querySelectorAll(".act")[1].click() : document.querySelectorAll(".act")[2].click();

      }
    }

    let languagesArr = [];



    const changeLanguageChosen = async (e) => {
        // let languageId;
        // languageId = 0;
        let languageId = e.target.id;
        const language = await LanguageApi.GetById(languageId);
        console.log(languageId);
        //console.log(language);
    
        //document.getElementById("translations").innerHTML = "";
        languagesArr = [];
    
        languagesArr.push(language);
        //console.log(languagesArr);
        transCreate(languagesArr[0]);
    
        //updateTransDropSite(languages);
        await transFill(languagesArr[0]);
    
        //let trans = translations.find(t => t.languageId == languageId);
        
        //document.getElementById("title").innerText = `${trans.name}`
    
      }
    
      const ActiveToggle = (e) => {
        //setToggler(!toggler);
        //e.target.classList.remove("activeUl");
        
        const lis = e.target.parentElement.children;
        const arrayOfElements = [...lis];
        
        arrayOfElements.forEach(l => l.classList.remove("activeUl"));
        e.target.classList.add("activeUl");
      }

      const IdToInitial = (id) => {
        const langObject = languages.find(l => l.languageId == id);
        console.log(languages);
        console.log(langObject);
        if (langObject) {
    
          return langObject.initials;
         
        } 
      }
    
    
        const transCreate = (languagesArr) => {
        
            document.getElementById("translations").innerHTML =
    
                `    
                    <div class="row" id="${languagesArr.languageId}">
                        <div class="col-md-12 justify-content-center">
                
                            <div class="row" style="margin-top:25px;margin-bottom: 2vh;">
                                
                                <div class="col-12 form-group">
                                    <input class="outlineNone" id="objName_${languagesArr.languageId}" style="text-align:center;width: 100%;background:#ffa50036;border-radius:12px;" placeholder="Enter Title.." type="text" />
                                </div>
                            </div>
                            <div class="row form-group" style="margin-top:3px;">
                                
                                <div class="col-12">
                                    <textarea class="form-control form-description txtArea" placeholder="Enter Description.."  id="objDesc_${languagesArr.languageId}" cols="20" rows="8" ></textarea>
                                </div>
                            </div>
                            <div class="row justify-content-end" style="margin:2vh;" id="buttonContainer__${languagesArr.languageId}"></div>
                        </div>
                    </div>
                `;
        }
    
    
    
        const transFill = async (languagesArr) => {
            /* for (var j = 0; j < languagesArr.length; j++) {*/
           
            const trans = translationsArr.find(t => t.initials === IdToInitial(languagesArr.languageId));

            console.log(trans);
            console.log(translationsArr);
           
            if (trans) {
                
                document.getElementById(`buttonContainer__${languagesArr.languageId}`).innerHTML = `  <button style="border-radius:10px;width:30%;" id="editObjTranslation_${languagesArr.languageId}" class="btn btn-success">${language.global.transEdit}</button>`;
                document.getElementById(`objName_${languagesArr.languageId}`).value = trans.name;
                document.getElementById(`objDesc_${languagesArr.languageId}`).value = trans.dscr;
    
                document.getElementById(`editObjTranslation_${languagesArr.languageId}`).addEventListener('click', async function (e) {
                    //var claims = await authService.getUserAsync();
                    //if (claims.claims.role === "Viewer") {
                        //alert(language.global.userRole);
                        // swal({
                        //     title: language.media.invalidAction,
                        //     text: language.global.userRole,
                        //     icon: "warning",
                        //     button: "OK",
    
                        // })
                    //} else {
                        e.preventDefault();
                        let translation = {
                            translations: []
                        };
                        let id = e.target.id;
                        let languageId = id.substring(19);
                        console.log(languageId);
                        console.log(translationsArr);
                        let transl = translationsArr.find(t => t.initials === IdToInitial(parseInt(languageId)));
                        console.log(transl);
                        let name = document.getElementById(`objName_${languageId}`).value;
                        name = name.trim();
                        //const description = document.getElementById(`objDesc_${languageId}`).value
                        let description = document.getElementById(`objDesc_${languageId}`).value;
                        description = description.trim();
    
                        translation.translations.push({ translationId: exhibit.translationId, name: name, description: description });
                        
                        const update = await ExhibitNew.UpdateTranslation(translation);
    
                        document.getElementById(`editObjTranslation_${languagesArr.languageId}`).disabled = true;
                        // document.getElementById(`edit_${pres}`).click();
                        Swal.fire({
                          title: languageChosenSite === "EN" ? language.media.successTitle : languageGr.media.successTitle,
                          text: languageChosenSite === "EN" ? language.media.successDesc : languageGr.media.successDesc,
                          icon: "success",
                          button: 'OK',
              
                        })

                       

                        Step2(exhibit);
                        //alert("Η μετάφραση αποθηκεύτηκε επιτυχώς");
                        //document.getElementById(`editObjTranslation_${languages[j].languageId}`).disabled = true;
                        //document.getElementById(`editObjTranslation_${languages[j].languageId}`).style.color = 'black';
                        //document.getElementById(`editObjTranslation_${languages[j].languageId}`).style.backgroundColor = 'grey';
                        //updateDatatable()
                        //tab2(exhibit);
                    //} //user role check
                });
                   
            }
            else {
               
                    document.getElementById(`buttonContainer__${languagesArr.languageId}`).innerHTML = `  <button style="border-radius:10px;width:30%;" id="addObjTranslation_${languagesArr.languageId}" class="btn btn-success">${language.global.transAdd}</button>`;
                    //var element = document.getElementById(`buttonContainer__${languages[j].languageId}`);
                    ////var button = `  <button id="addSiteTranslation_${languages[i].id}" class="xenagosButton">Add Tranlation</button>`;
                    //element.appendChild(document.createElement('button'));
                    //let button = element.firstChild;
                    //button.id = `addObjTranslation_${languages[j].languageId}`
                    //button.innerHTML = "Προσθήκη Μετάφρασης";
                    //button.className = "xenagosButton";
                    //button.addEventListener('click', async function (e) {
                    document.getElementById(`addObjTranslation_${languagesArr.languageId}`).addEventListener('click', async function (e) {
                    //var claims = await authService.getUserAsync();
                    // if (claims.claims.role === "Viewer") {
                    //     //alert(language.global.userRole);
                    //     swal({
                    //         title: language.media.invalidAction,
                    //         text: language.global.userRole,
                    //         icon: "warning",
                    //         button: "OK",
    
                    //     })
                    //} else {
                        let id = e.target.id;
    
                        let languageId = id.substring(18);
    
                        let translation = {
                            translations: []
                        }
                        let name = document.getElementById(`objName_${languageId}`).value;
                        name = name.trim();
                        let description = document.getElementById(`objDesc_${languageId}`).value;
                        description = description.trim();
                        translation.translations.push({ id: parseInt(exhibit.objectId), languageId: parseInt(languageId), name: name, description: description });
    
                        const update = await ObjectApi.CreateTranslation(translation);
    
    
                        document.getElementById(`addObjTranslation_${languagesArr.languageId}`).disabled = true;
                        // document.getElementById(`edit_${pres}`).click();
                        Swal.fire({
                          title: languageChosenSite === "EN" ? language.media.successTitle : languageGr.media.successTitle,
                          text: languageChosenSite === "EN" ? language.media.successDesc : languageGr.media.successDesc,
                          icon: "success",
                          button: 'OK',
              
                        })

                        //Step2(exhibit);
    
                        //document.getElementById(`tab2`).click();
    
                        //document.getElementById(`addObjTranslation_${languages[j].languageId}`).disabled = true;
                        //document.getElementById(`addObjTranslation_${languages[j].languageId}`).style.color = 'black';
                        //document.getElementById(`addObjTranslation_${languages[j].languageId}`).style.backgroundColor = 'grey';
                        //tab2(exhibit);
                    //}
                });
    
            }
        /* }*/
            //tinymce.remove();
            //textEditor();
            
        }

        const onPrevious = () => {
            console.log("prev");
            //const dots = document.querySelectorAll(".Wizard_dot__btV-v ");
            const dots = document.querySelectorAll(".Wizard_dot__E6e-z");

            const dotsArr = [...dots];
            dotsArr[0].click();
        }
    
        const onForward = () => {
          if(userRole != "Admin") {
            Swal.fire({
              title: language.media.invalidAction,
              text: language.global.userRole,
              icon: "warning",
              button: "OK",
              
            })
            return;
          }
          const dots = document.querySelectorAll(".Wizard_dot__E6e-z");

            const dotsArr = [...dots];
            // console.log(dotsArr);
            // console.log(serialNumber);
            // console.log(optionsForSel);
            // console.log(optionsForSelSub);
            // console.log(exhibitionChosen);
            dotsArr[2].click();

        }
        //document.getElementById(2).click();

        
  return (
    <>
      <div role="tabpanel" className="tab-pane container" id="tab_default_2">
        <div id="translationsBox" style={{ "margin-top": "2vh" }}>
          <div className="row" id="divTranslations">
            <div className="col-2" style={{ "margin-top": "4vh" }}>
              <div id="translationArraySite!" className="">
                <ul
                  className="translationArr"
                  id="transDropdownSite"
                  onClick={changeLanguageChosen}
                  style={{}}
                >
                  {languages.map((lang) => (
                    <li
                      className="act"
                      onClick={ActiveToggle}
                      id={lang.languageId}
                    >
                      {lang.name}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-9 offset-1" id="translations"></div>
          </div>
        </div>
        <div className="modal_footer even" style={{"display": "flex", "flex-direction": "row", "justify-content": "space-evenly"}}>
        
            <button disabled style={{"border": "1px solid black", "border-radius": "10px", "color": "white", "background": "#1c4325"}} onClick={onPrevious} className="backwards btn">
            <FaIcons.FaChevronLeft /> {languageChosenSite === "EN" ? language.global.previous : languageGr.global.previous}
            </button>
        
        
            <button style={{"border": "1px solid black", "border-radius": "10px" , "color": "white", "background": "#1c4325"}} onClick={onForward} className="forward btn">
            {languageChosenSite === "EN" ? language.global.next : languageGr.global.next} <FaIcons.FaChevronRight />
            </button>
       
      </div>
      </div>
    </>
  );
};

export default Step2;
