import {useState} from 'react';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import * as IoIcons from 'react-icons/io';
import content from "../../images/Sidebar/content-green.png";
import add from "../../images/Sidebar/add.png";
import maps from "../../images/Sidebar/maps-green.png";
import multimedia from "../../images/Sidebar/multimedia-green2.png";
import categories from "../../images/Sidebar/categories-green.png";
import events from "../../images/Sidebar/events-green.png";
import fields from "../../images/Sidebar/fields-green.png";
import notification from "../../images/Sidebar/notification.png";
import presentation from "../../images/Sidebar/presentation-green.png";



export const SidebarData = [
    {
        title: 'Content Management',
        path: '/Management',
        icon: <img src={content}/>,
        cName: 'nav-text'
    }, 
    {
        title: 'Add Content',
        path: '/AddNew',
        icon: <img src={add}/>,
        cName: 'nav-text'
    },
    
    {
        title: 'Outdoor Map',
        path: '/OutdoorMap',
        icon: <img src={maps}/>,
        cName: 'nav-text'
    },
    
    // {
    //     title: 'Indoor Map',
    //     path: '/IndoorMap',
    //     icon: <img src={maps}/>,
    //     cName: 'nav-text'
    // },
    {
        title: 'Media Management',
        path: '/MediaGallery',
        icon: <img src={multimedia}/>,
        cName: 'nav-text'
    },
    {
        title: 'Notification Management',
        path: '/Notifications',
        icon: <img src={notification}/>,
        cName: 'nav-text'
    },
    {
        title: 'Event Management',
        path: '/Events',
        icon: <img src={events}/>,
        cName: 'nav-text'
    },
    {
        title: 'Presentation Management',
        path: '/Presentations',
        icon: <img src={presentation}/>,
        cName: 'nav-text'
    },
    {
        title: 'Category Management',
        path: '/Category',
        icon: <img src={categories}/>,
        cName: 'nav-text'
    },
    {
        title: 'Field Management',
        path: '/Field',
        icon: <img src={fields}/>,
        cName: 'nav-text'
    },
    {
        title: 'Exhibition Management',
        path: '/Exhibitions',
        icon: <img src={fields}/>,
        cName: 'nav-text'
    },
    // {
    //     title: 'Export Data',
    //     path: '/Export',
    //     icon: <img src={fields}/>,
    //     cName: 'nav-text'
    // },
    // {
    //     title: 'Control Panel',
    //     path: '/ControlPanel',
    //     icon: <img src={fields}/>,
    //     cName: 'nav-text'
    // },

];


export const SidebarDataIndoor = [
    {
        title: 'Content Management',
        path: '/Management',
        icon: <img src={content}/>,
        cName: 'nav-text'
    }, 
    {
        title: 'Add Content',
        path: '/AddNew',
        icon: <img src={add}/>,
        cName: 'nav-text'
    },
    
    // {
    //     title: 'Outdoor Map',
    //     path: '/OutdoorMap',
    //     icon: <img src={maps}/>,
    //     cName: 'nav-text'
    // },
    
    {
        title: 'Indoor Map',
        path: '/IndoorMap',
        icon: <img src={maps}/>,
        cName: 'nav-text'
    },
    {
        title: 'Media Management',
        path: '/MediaGallery',
        icon: <img src={multimedia}/>,
        cName: 'nav-text'
    },
    {
        title: 'Notification Management',
        path: '/Notifications',
        icon: <img src={notification}/>,
        cName: 'nav-text'
    },
    {
        title: 'Event Management',
        path: '/Events',
        icon: <img src={events}/>,
        cName: 'nav-text'
    },
    {
        title: 'Presentation Management',
        path: '/Presentations',
        icon: <img src={presentation}/>,
        cName: 'nav-text'
    },
    {
        title: 'Category Management',
        path: '/Category',
        icon: <img src={categories}/>,
        cName: 'nav-text'
    },
    {
        title: 'Field Management',
        path: '/Field',
        icon: <img src={fields}/>,
        cName: 'nav-text'
    },
    // {
    //     title: 'Exhibition Management',
    //     path: '/Exhibitions',
    //     icon: <img src={fields}/>,
    //     cName: 'nav-text'
    // },
    // {
    //     title: 'Export Data',
    //     path: '/Export',
    //     icon: <img src={fields}/>,
    //     cName: 'nav-text'
    // },
    // {
    //     title: 'Control Panel',
    //     path: '/ControlPanel',
    //     icon: <img src={fields}/>,
    //     cName: 'nav-text'
    // },

];

export const SidebarDataGr = [
    {
        title: 'Περιεχόμενο',
        path: '/Management',
        icon: <img src={content}/>,
        cName: 'nav-text'
    }, 
    {
        title: 'Προσθήκη Νέου',
        path: '/AddNew',
        icon: <img src={add}/>,
        cName: 'nav-text'
    },
    {
        title: 'Εξωτερικός Χάρτης',
        path: '/OutdoorMap',
        icon: <img src={maps}/>,
        cName: 'nav-text'
    },
    // {
    //     title: 'Εσωτερικός Χάρτης',
    //     path: '/IndoorMap',
    //     icon: <img src={maps}/>,
    //     cName: 'nav-text'
    // },
    {
        title: 'Πολυμέσα',
        path: '/MediaGallery',
        icon: <img src={multimedia}/>,
        cName: 'nav-text'
    },
    {
        title: 'Ειδοποιήσεις',
        path: '/Notifications',
        icon: <img src={notification}/>,
        cName: 'nav-text'
    },
    {
        title: 'Εκδηλώσεις',
        path: '/Events',
        icon: <img src={events}/>,
        cName: 'nav-text'
    },
    {
        title: 'Παρουσιάσεις',
        path: '/Presentations',
        icon: <img src={presentation}/>,
        cName: 'nav-text'
    },
    {
        title: 'Κατηγορίες',
        path: '/Category',
        icon: <img src={categories}/>,
        cName: 'nav-text'
    },
    {
        title: 'Πεδία',
        path: '/Field',
        icon: <img src={fields}/>,
        cName: 'nav-text'
    },
    {
        title: 'Διαδρομές',
        path: '/Exhibitions',
        icon: <img src={fields}/>,
        cName: 'nav-text'
    },
    // {
    //     title: 'Export Data',
    //     path: '/Export',
    //     icon: <img src={fields}/>,
    //     cName: 'nav-text'
    // },
    // {
    //     title: 'Control Panel',
    //     path: '/ControlPanel',
    //     icon: <img src={fields}/>,
    //     cName: 'nav-text'
    // },

];


export const SidebarDataGrIndoor = [
    {
        title: 'Περιεχόμενο',
        path: '/Management',
        icon: <img src={content}/>,
        cName: 'nav-text'
    }, 
    {
        title: 'Προσθήκη Νέου',
        path: '/AddNew',
        icon: <img src={add}/>,
        cName: 'nav-text'
    },
    // {
    //     title: 'Εξωτερικός Χάρτης',
    //     path: '/OutdoorMap',
    //     icon: <img src={maps}/>,
    //     cName: 'nav-text'
    // },
    {
        title: 'Εσωτερικός Χάρτης',
        path: '/IndoorMap',
        icon: <img src={maps}/>,
        cName: 'nav-text'
    },
    {
        title: 'Πολυμέσα',
        path: '/MediaGallery',
        icon: <img src={multimedia}/>,
        cName: 'nav-text'
    },
    {
        title: 'Ειδοποιήσεις',
        path: '/Notifications',
        icon: <img src={notification}/>,
        cName: 'nav-text'
    },
    {
        title: 'Εκδηλώσεις',
        path: '/Events',
        icon: <img src={events}/>,
        cName: 'nav-text'
    },
    {
        title: 'Παρουσιάσεις',
        path: '/Presentations',
        icon: <img src={presentation}/>,
        cName: 'nav-text'
    },
    {
        title: 'Κατηγορίες',
        path: '/Category',
        icon: <img src={categories}/>,
        cName: 'nav-text'
    },
    {
        title: 'Πεδία',
        path: '/Field',
        icon: <img src={fields}/>,
        cName: 'nav-text'
    },
    // {
    //     title: 'Διαδρομές',
    //     path: '/Exhibitions',
    //     icon: <img src={fields}/>,
    //     cName: 'nav-text'
    // },
    // {
    //     title: 'Export Data',
    //     path: '/Export',
    //     icon: <img src={fields}/>,
    //     cName: 'nav-text'
    // },
    // {
    //     title: 'Control Panel',
    //     path: '/ControlPanel',
    //     icon: <img src={fields}/>,
    //     cName: 'nav-text'
    // },

];