import React, { useState, useEffect } from "react";
import { fabric } from "fabric";
import ChangeHistoryIcon from '@mui/icons-material/ChangeHistory';
import { language, languageGr } from "../../../../api/lang";

function Triangle(props) {
  const [selected, setSelected] = useState(false);
  


  useEffect(() => {
    if (selected) {
      props.canvas.getActiveObject().set("stroke", props.iconColor);
      props.canvas.requestRenderAll();

    }
  }, [ props.iconColor]);

  useEffect(() => {
    if (selected) {
      props.canvas.getActiveObject().set("fill", props.fillColor);
      props.canvas.requestRenderAll();

    }
  }, [ props.fillColor]);

  // useEffect(() => {
  //   if (selected) {
  //     props.canvas.getActiveObject().set("strokeWidth", props.strokeWidth);
  //     props.canvas.requestRenderAll();

  //   }
  // }, [ props.strokeWidth]);

 

 

  const workPoint = new fabric.Triangle({
    top: 150,
    left: 200,
    stroke: props.iconColor,
    fill: props.fillColor,
    cornerColor: "black"
  });

  const onAddTriangle = () => {
    props.canvas.add(workPoint);
  };

  workPoint.on("selected", () => {
    props.setSquares(false);

    setSelected(true);

    props.setIconColor(props.canvas.getActiveObject().stroke);
    props.setFillColor(props.canvas.getActiveObject().fill);
    //props.setStrokeWidth(props.canvas.getActiveObject().strokeWidth);
    props.canvas.getActiveObject().cornerColor = "black";

    console.log(props.canvas.getActiveObject());
    // document.getElementById("fontSizeDiv").style.display = "none";
    // document.getElementById("textExhibit").style.display = "none";
    // document.getElementById("fontFamilyDiv").style.display = "none";

  

  });
  
  workPoint.on("deselected", () => {
    setSelected(false);
    // document.getElementById("fontSizeDiv").style.display = "flex";
    // document.getElementById("textExhibit").style.display = "flex";
    // document.getElementById("fontFamilyDiv").style.display = "flex";

  });


  if(props.canvas) {
    props.canvas.on("selection:created", (e) => {
      if(e.selected[0].type === "triangle") {
        props.setSquares(false);

        console.log("circle Selection Created");

        setSelected(true);
        props.setIconColor(props.canvas.getActiveObject().stroke);
        props.setFillColor(props.canvas.getActiveObject().fill);
        
        props.canvas.getActiveObject().cornerColor = "black";

        // document.getElementById("fontSizeDiv").style.display = "none";
        // document.getElementById("textExhibit").style.display = "none";
        // document.getElementById("fontFamilyDiv").style.display = "none";
      } else {
        console.log("NOT circle Selection Created");

        setSelected(false);
        // document.getElementById("fontSizeDiv").style.display = "flex";
        // document.getElementById("textExhibit").style.display = "flex";
        // document.getElementById("fontFamilyDiv").style.display = "flex";
      }
      
     
    });
  }

  if(props.canvas) {
    props.canvas.on("selection:updated", (e) => {
      console.log(e);


      if(e.selected[0].type === "triangle") {
        console.log("circle Selection Updated");
        props.setSquares(false);

        setSelected(true);
        props.setIconColor(props.canvas.getActiveObject().stroke);
        props.setFillColor(props.canvas.getActiveObject().fill);
       
        // document.getElementById("fontSizeDiv").style.display = "none";
        props.canvas.getActiveObject().cornerColor = "black";

        // document.getElementById("textExhibit").style.display = "none";
        
        // document.getElementById("fontFamilyDiv").style.display = "none";
      } else {
        console.log("NOT circle Selection Updated");

        setSelected(false);
        // document.getElementById("fontSizeDiv").style.display = "flex";
        // document.getElementById("textExhibit").style.display = "flex";
        // document.getElementById("fontFamilyDiv").style.display = "flex";
      }
    });
  }

  

  return (
    <button
      className="mapPropertiesItem"
      
      onClick={onAddTriangle}
    >
      <ChangeHistoryIcon />
      {props.languageChosenSite === "EL"
        ? languageGr.indoor.triangle
        : language.indoor.triangle}
    </button>
  );
}

export default Triangle;
