import React, { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { Modal, Form, Tabs, Tab, Toast, ToastContainer } from "react-bootstrap";
import { language, languageGr } from "../../../../api/lang";
import Select from "react-select";
import { ImageURL, ImageFromFile } from "./Image";
import "../../mapConfig.css";
import { MapsApi } from "../../../../api/api.service";
import { addFlagOnMapName } from "../../addFlagOnMapName";

function AddNewMap(props) {
  const [showToast, setShowToast] = useState(false);
  const [key, setKey] = useState("file");
  const [show, setShow] = useState(false);
  const [titleMessage, setTitleMesage] = useState(false);
  const [langMessage, setLangMesage] = useState(false);
  const [t, setT] = useState("");
  const [d, setD] = useState("");
  const [lang, setLang] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleCloseToast = () => setShowToast(false);

  const onSetLanguage = (option) => {
    if (option !== null) {
      setLang(option.value);
      props.setLang(option);
      console.log(option);
    } else setLang("");
  };

  const onAddNew = () => {
    if (t === "" && lang === "") {
      setTitleMesage(true);
      setLangMesage(true);
    } else if (t === "") {
      setTitleMesage(true);
    } else if (lang === "") {
      setLangMesage(true);
    } else {
      setTitleMesage(false);
      setLangMesage(false);

      // if (props.mapId !== null) {
      //   props.canvas.clear();
      //   props.canvas.setBackgroundColor("rgba(236, 236, 236, 0.653)");
      // }

      props.setMapId(null);
      props.setTitle(t);
      props.setDescription(d);
      props.setIsClickable(true);
      setShowToast(true);

      handleClose();
      saveJSONToDb();
      setTimeout(() => {
        setShowToast(false);
      }, 4000);
    }
  };

  const setExistingMapToCreate = async (option) => {
    props.setSelectedMap(option);

    if (option !== null) {
      for (let element of props.allMaps) {
        if (element.mapId === option.value) {
          props.canvas.loadFromJSON(JSON.parse(element.value), function () {
            props.canvas.renderAll();
          });
          console.log(element);
          
        }
      }
    } 
  }

  const saveJSONToDb = async () => {
    console.log("SAVE RUNS");
    const mapToJSON = JSON.stringify(props.canvas.toJSON());
    console.log(mapToJSON);
    const map = {
      Name: t !== null ? t : "Default map",
      Description: d !== null ? d : "Default description",
      Value: mapToJSON,
      translations: [],
    };

    map.translations.push({
      LanguageId: props.lang.value,
      Name: t !== null ? t : "Default map",
      Description: d !== null ? d : "Default description",
    });

    console.log(map);
    var myMap = await MapsApi.Create(map);
    props.setMapId(myMap.mapId);
    props.setSelectedMap({
      value: myMap.mapId,
      label: (
        <div className="d-flex justify-content-between">
          <div>{myMap.name}</div>
          <div>
            <img src={addFlagOnMapName(lang)} height="24px" width="24px" />
          </div>
        </div>
      ),
    });

    props.getMaps();
  };

  const onCancel = () => {
    setTitleMesage(false);
    setLangMesage(false);
    props.cleanCanvas();
    handleClose();
  };

  return (
    <div>
      <ToastContainer className="p-3" position="top-center">
        <Toast show={showToast} onClick={handleCloseToast}>
          <Toast.Header>
            <strong className="me-auto">
              {props.languageChosenSite === "EL"
                ? languageGr.indoor.map
                : language.indoor.map}
            </strong>
          </Toast.Header>
          <Toast.Body>
            {props.languageChosenSite === "EL"
              ? languageGr.indoor.map
              : language.indoor.map}
            {t}
            {props.languageChosenSite === "EL"
              ? languageGr.indoor.created
              : language.indoor.created}
          </Toast.Body>
        </Toast>
      </ToastContainer>

      <button className="add-new-map-btn" onClick={handleShow}>
        <AddIcon />
      </button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {props.languageChosenSite === "EL"
              ? languageGr.indoor.addNewMap
              : language.indoor.addNewMap}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>
              {props.languageChosenSite === "EL"
              ? languageGr.indoor.existingMapCopy
              : language.indoor.existingMapCopy}
              
              </Form.Label>
            <Select
                  className="custom-react-select"
                  options={props.maps}
                  defaultValue={null}
                  onChange={setExistingMapToCreate}
                  placeholder={
                    props.languageChosenSite === "EL"
                      ? languageGr.indoor.selectMap
                      : language.indoor.selectMap
                  }
                  isSearchable
                  // isClearable
                />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>
                {props.languageChosenSite === "EL"
                  ? languageGr.indoor.mapName
                  : language.indoor.mapName}
              </Form.Label>
              <Form.Control
                required
                className="form-control-add-edit-map"
                type="text"
                placeholder={
                  props.languageChosenSite === "EL"
                    ? languageGr.indoor.enterMapName
                    : language.indoor.enterMapName
                }
                onChange={(e) => setT(e.target.value)}
              />
              {titleMessage ? (
                <p className="fw-light text-danger">
                  {props.languageChosenSite === "EL"
                    ? languageGr.indoor.formValidationTitle
                    : language.indoor.formValidationTitle}
                </p>
              ) : null}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>
                {props.languageChosenSite === "EL"
                  ? languageGr.indoor.mapDescription
                  : language.indoor.mapDescription}
              </Form.Label>
              <Form.Control
                className="form-control-add-edit-map"
                type="text"
                placeholder={
                  props.languageChosenSite === "EL"
                    ? languageGr.indoor.enterMapDescription
                    : language.indoor.enterMapDescription
                }
                onChange={(e) => setD(e.target.value)}
              />
            </Form.Group>
            <div className="mb-3">
              <h5>
                {props.languageChosenSite === "EL"
                  ? languageGr.indoor.mapLanguage
                  : language.indoor.mapLanguage}
              </h5>
              <Select
                className="custom-react-select"
                isClearable
                options={props.languages}
                placeholder={
                  props.languageChosenSite === "EL"
                    ? languageGr.indoor.chooseLanguage
                    : language.indoor.chooseLanguage
                }
                isSearchable
                onChange={onSetLanguage}
              />
              {langMessage ? (
                <p className="fw-light text-danger">
                  {props.languageChosenSite === "EL"
                    ? languageGr.indoor.formValidationLang
                    : language.indoor.formValidationLang}
                </p>
              ) : null}
            </div>

            <Form.Group className="mb-3">
              <Form.Label>Image</Form.Label>
              <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="mb-3 image-tabs"
              >
                <Tab eventKey="file" title="File Upload">
                  <ImageFromFile
                    canvas={props.canvas}
                    mapId={props.mapId}
                    languageChosenSite={props.languageChosenSite}
                  />
                </Tab>
                <Tab eventKey="url" title="Image URL">
                  <ImageURL
                    canvas={props.canvas}
                    mapId={props.mapId}
                    languageChosenSite={props.languageChosenSite}
                  />
                </Tab>
              </Tabs>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <button className="add-new-map-btn" onClick={onAddNew}>
            {props.languageChosenSite === "EL"
              ? languageGr.indoor.createNew
              : language.indoor.createNew}
          </button>
          <button className="close-modal-button " onClick={onCancel}>
            {props.languageChosenSite === "EL"
              ? languageGr.indoor.close
              : language.indoor.close}
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default AddNewMap;
