import React, { useState, useEffect } from "react";

// Api
import {
  SiteApi,
  ExhibitionApi,
  LanguageApi,
  CategoryApi,
  PresentationApi,
  FieldApi,
  ObjectApi,
  MediaApi,
} from "../../api/api.service";
import { language, languageGr } from "../../api/lang";

//Authservice
import { authService } from "../../api/auth.service";

// Button Skeleton
import ButtonSkeleton from "./ButtonSkeleton";

// Swal Alert
import Swal from "sweetalert2";

import * as XLSX from "xlsx/xlsx.mjs";
//import * as XLSX from 'https://cdnjs.com/libraries/xlsx';
//import * as XLSX from 'https://cdnjs.cloudflare.com/ajax/libs/xlsx/0.18.2/xlsx.min.js';
//import ProgressBar from "react-animated-progress-bar";

// CSS
import "./Export.css";

// jquery
import $ from "jquery";

// Icons
import manage from "../../images/manage-orange.png";

// Bootstrap
import { Button } from "react-bootstrap";

import Spinner from "react-bootstrap/Spinner";
import { getDefaultNormalizer } from "@testing-library/react";

const ExportComponent = () => {
  const getData = async () => {
    sites = await SiteApi.Get();
    setSite(sites[0]);
    fetchUser();
    const languagesArray = await LanguageApi.Get();
    setLanguageChosenSite(sessionStorage.getItem("languageChosen"));
    setLanguages(languagesArray);
  };

  useEffect(() => {
    document.querySelector(".navbar").style.display = "flex";
    document.querySelector(".pageHead").style.display = "block";
    getData();
    changeHead();
    // document.querySelector(
    //   ".pageHead"
    // ).innerHTML = `<h4 style='padding-top: 2%;text-align: center;color: #f7aa47;margin-left: -39vw;'><img style="height: 4vh;" class='fa fa-fw fa-file' src='${manage}' />${language.global.titles.export}</h4>`;
  }, []);

  const fetchUser = async () => {
    const user = await authService.getUserAsync();

    setUserRole(user.claims[0].value);
  };

  const changeHead = () => {
    if (sessionStorage.getItem("languageChosen") === "EL") {
      document.querySelector(".pageHead").innerHTML =`<div style="width: 100%; height:100%; display: inline-flex; justify-content: center;"><h4 style='padding-top: 3.5vh;text-align: center;color: #f7aa47;'><img style="height: 4vh;margin-top:-1vh;" class='fa fa-fw fa-file' src='${manage}' />${languageGr.global.titles.export}</h4></div>`;
    } else {
      document.querySelector(".pageHead").innerHTML =`<div style="width: 100%; height:100%; display: inline-flex; justify-content: center;"><h4 style='padding-top: 3.5vh;text-align: center;color: #f7aa47;'><img style="height: 4vh;margin-top:-1vh;" class='fa fa-fw fa-file' src='${manage}' />${language.global.titles.export}</h4></div>`;
    }
  };

  const [progress, setProgress] = useState(0);
  const [site, setSite] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [sheetData, setSheetData] = useState(null);
  const [languageChosenSite, setLanguageChosenSite] = useState("");
  const [userRole, setUserRole] = useState("");
  const [loading, setLoading] = useState(true);

  let sites;
  let dataEx = [];
  let dataEx2 = [];
  let dataEx3 = [];
  let dataEx4 = [];
  let dataEx5 = [];

  const unique = [];
  let uniqueFiltered = [];
  let objectData = [];
  let finalArray = [];

  // const addProgress = () => {
  //   setProgress((prevState) => prevState + 10);
  //   if (progress == 100) {
  //     alert("done");
  //   }
  // };

  // const progressLoader = (percentage) => {
  //   setProgress((prevState) => prevState + percentage);
  //   if (progress == 100) {
  //     alert("done");
  //   }
  // };

  function updateStatusDownload(status) {
    document.querySelector(
      "#progressStatus"
    ).innerHTML = `Converting <span class="statusSpan" style="font-weight: bold;">${status}</span>... Please wait`;
  }

  const startProgress = () => {
    document.querySelector("#bar").style.opacity = "0.4";

    document.querySelector("#progressB").style.width = `0%`;
  };

  const downloadConfigFile = async () => {
    if (userRole != "Admin") {
      Swal.fire({
        title: languageChosenSite == "EL" ? languageGr.media.invalidAction : language.media.invalidAction,
        text: languageChosenSite == "EL" ? languageGr.global.userRole : language.global.userRole,
        icon: "warning",
        button: "OK",
      });
      return;
    }

    setLoading(false);
    $("#objectTab").css("pointer-events", "none");
    //document.getElementById("exp").disabled = true;
    $("#configurationTab").css("pointer-events", "none");
    $("#mediaTab").css("pointer-events", "none");
    updateProgressBar(0);
    updateStatusDownload("Museum Files");
    await siteExport();
    updateProgressBar(20);

    updateStatusDownload("Category Files");
    await categoryExport();
    updateProgressBar(40);

    updateStatusDownload("Exhibition Files");
    await exhibitionExport();
    updateProgressBar(60);

    updateStatusDownload("Presentation Files");
    await presentationExport();
    updateProgressBar(80);

    updateProgressBar(92);

    updateStatusDownload("Field Files");
    await fieldExport();
    updateProgressBar(100);

    downloadAsExcel(dataEx, dataEx2, dataEx3, dataEx4, dataEx5);

    Swal.fire({
      title: "Success",
      text:
        languageChosenSite === "EL"
          ? languageGr.mediaWords.successDownload
          : language.export.successDownload,
      icon: "success",
    });
    setLoading(true);
    document.querySelector("#progressStatus").innerText = "";
    //document.getElementById("exp").disabled = false;
    $("#objectTab").css("pointer-events", "auto");
    $("#configurationTab").css("pointer-events", "auto");
    $("#mediaTab").css("pointer-events", "auto");
  };

  function downloadAsExcel(file, file2, file3, file4, file5) {
    console.log(XLSX);
    const worksheet = XLSX.utils.json_to_sheet(file);
    console.log(worksheet);
    const worksheet2 = XLSX.utils.json_to_sheet(file2);
    const worksheet3 = XLSX.utils.json_to_sheet(file3);
    const worksheet4 = XLSX.utils.json_to_sheet(file4);
    const worksheet5 = XLSX.utils.json_to_sheet(file5);
    const workbook = {
      Sheets: {
        Site: worksheet,
        Categories: worksheet2,
        Exhibitions: worksheet3,
        Presentations: worksheet4,
        Fields: worksheet5,
      },
      SheetNames: [
        "Site",
        "Categories",
        "Exhibitions",
        "Presentations",
        "Fields",
      ],
    };

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    saveAsExcel(excelBuffer, "Configuration_File");
  }

  async function languageIdToInit(languageId) {
    var langObject = languages.find((l) => l.languageId === languageId);

    if (langObject) {
      return langObject.initials;
    } else {
      return -1;
    }
  }

  ////////////////////SITE////////////////////
  async function siteExport() {
    let siteTranslations = await SiteApi.GetTranslation(site.siteId);
    //console.log(siteSum);
    for (let trans of siteTranslations) {
      let siteModel = {
        /*Id: parseInt(trans.siteId),*/
        Name: trans.name,
        Description: trans.description.replace(/<p>/gi, ""),
        Languages: await languageIdToInit(trans.languageId),
      };
      dataEx.push(siteModel);
    }
    console.log(dataEx);
  }

  /////////////////CATEGORIES/////////////////////

  async function idToNameParent(parentId, languageId) {
    let parentIds = [];
    let parentNames = [];
    const c = await CategoryApi.GetCategoriesOfSite(parseInt(site.siteId));

    const catObject = c.find((l) => l.categoryId === parentId);
    console.log(catObject);

    if (catObject) {
      parentIds.push(catObject.categoryId);
    }
    console.log(parentIds);

    for (let cat of parentIds) {
      const categoriesSum = await CategoryApi.GetByTranslation(cat);
      console.log(categoriesSum);
      for (let translation of categoriesSum) {
        if (translation.languageId === languageId) {
          parentNames.push(translation.name);
        }
      }
    }
    console.log(parentNames);
    return parentNames.join(", ");
  }

  async function categoryExport() {
    const categories = await CategoryApi.GetCategoriesOfSite(
      parseInt(site.siteId)
    );

    for (let cat of categories) {
      const categoriesSum = await CategoryApi.GetByTranslation(cat.categoryId);
      //console.log(categoriesSum);

      for (let trans of categoriesSum) {
        const categoryModel = {
          Id: parseInt(trans.categoryId),
          Name: trans.name,
          //Image_def: parseInt(trans.image_def),
          Description: trans.description,
          Parent:
            trans.parentId === null
              ? null
              : await idToNameParent(trans.parentId, trans.languageId),
          Languages: await languageIdToInit(trans.languageId),
        };
        dataEx2.push(categoryModel);
      }
      //console.log(dataEx2);
    }
  }

  ///////////////////////EXHIBITIONS//////////////////////////

  async function exhibitionExport() {
    const exhibitions = await ExhibitionApi.GetBySiteId(parseInt(site.siteId));

    for (let exh of exhibitions) {
      const exhibitionsSum = await ExhibitionApi.GetByTranslation(
        exh.exhibitionId
      );

      for (let trans of exhibitionsSum) {
        const exhibitionModel = {
          Id: parseInt(trans.exhibitionId),
          Name: trans.name,
          Description: trans.description,

          Languages: await languageIdToInit(trans.languageId),
        };
        dataEx3.push(exhibitionModel);
      }
      //console.log(dataEx2);
    }
  }

  async function presentationExport() {
    const presentations = await PresentationApi.GetBySiteId(site.siteId);

    for (let pres of presentations) {
      const presentationsSum = await PresentationApi.GetByTranslation(
        pres.presentationId
      );

      for (let trans of presentationsSum) {
        const presentationModel = {
          Id: parseInt(trans.presentationId),
          Name: trans.name,
          Description: trans.description,

          Languages: await languageIdToInit(trans.languageId),
        };
        dataEx4.push(presentationModel);
      }
      //console.log(dataEx2);
    }
  }

  async function findFieldsOfCats(fieldId, languageId) {
    const c = await CategoryApi.GetCategoriesOfSite(parseInt(site.siteId));
    let categoryArray = [];
    let finalArray = [];

    /*const categoriesSum = await CategoryApi.GetByTranslation(cate.categoryId);*/

    for (let ca of c) {
      //const categoriesSum = await CategoryApi.GetByTranslation(ca.categoryId);

      // console.log(categoriesSum);
      const fieldsOfCat = await CategoryApi.GetFieldOfCategory(ca.categoryId);
      //console.log(`Category ${ca.name} contains ${fieldsOfCat.length} fields`);

      for (let f of fieldsOfCat) {
        if (f.fieldId === fieldId) {
          categoryArray.push(ca.categoryId);
        }
      }

      //if (field) {
      //    return ca.name;
      //} else {
      //    return -1;
      //}
    }
    for (let id of categoryArray) {
      const allTranslationsOfCategories = await CategoryApi.GetByTranslation(
        id
      );
      //console.log(allTranslationsOfCategories);
      for (let c of allTranslationsOfCategories) {
        if (c.languageId === languageId) {
          //console.log(c);
          finalArray.push(c.name);
        }
      }
    }

    //return categoryArray.toString();

    return finalArray.join(", ");
  }

  async function fieldExport() {
    const fields = await FieldApi.Get();

    for (let field of fields) {
      const fieldsSum = await FieldApi.GetByTranslation(field.fieldId);

      for (let trans of fieldsSum) {
        const fieldModel = {
          Id: parseInt(trans.fieldId),
          Name: trans.name,
          Description: trans.description,
          Category: await findFieldsOfCats(trans.fieldId, trans.languageId),
          Languages: await languageIdToInit(trans.languageId),
        };

        //await findFieldsOfCats(trans.fieldId) === -1 ? null : await findFieldsOfCats(trans.fieldId)

        //fieldModel.Category.push(await findFieldsOfCats(trans.fieldId));

        dataEx5.push(fieldModel);
      }
      //console.log(dataEx5);
    }
    console.log(dataEx5);
    console.log("done");
  }

  ///////////////////////EXHIBITS//////////////////////////

  async function categoryIdToName(categoryId, languageId) {
    const categories = await CategoryApi.GetCategoriesOfSite(
      parseInt(site.siteId)
    );
    console.log(categories);
    const ascendingCats = categories.sort(function (a, b) {
      return (
        (a.parentId === null) - (b.parentId === null) ||
        a.parentId - b.parentId ||
        b.categoryId - a.categoryId
      );
    });

    console.log(ascendingCats);

    for (let c of ascendingCats) {
      if (c.parentId !== null) {
        const categoriesSum = await CategoryApi.GetByTranslation(c.categoryId);

        const categoryToFind = categoriesSum.find(
          (c) => c.categoryId === categoryId && c.languageId === languageId
        );

        if (categoryToFind) {
          console.log(categoryToFind);
          return categoryToFind.name;
        }
      } else {
        const categoriesSum = await CategoryApi.GetByTranslation(c.categoryId);

        const categoryToFind = categoriesSum.find(
          (c) => c.categoryId === categoryId && c.languageId === languageId
        );

        if (categoryToFind) {
          console.log(categoryToFind);
          return categoryToFind.name;
        }
      }
    }
  }

  async function findExhibitionsOfObjects(objectId, languageId) {
    const exhibitions = await ExhibitionApi.GetBySiteId(parseInt(site.siteId));
    let exhibitionArray = [];
    let exhibitionNames = [];
    for (let exh of exhibitions) {
      const objectByExhib = await ObjectApi.GetByExhibition(exh.exhibitionId);

      for (let t of objectByExhib) {
        if (t.objectId === objectId) {
          exhibitionArray.push(exh.exhibitionId);
        }
      }
    }

    for (let id of exhibitionArray) {
      const allTranslationsOfExhibitions = await ExhibitionApi.GetByTranslation(
        id
      );
      //console.log(allTranslationsOfCategories);
      for (let c of allTranslationsOfExhibitions) {
        if (c.languageId === languageId) {
          //console.log(c);
          exhibitionNames.push(c.name);
        }
      }
    }

    //return categoryArray.toString();
    return exhibitionNames.join(", ");
  }

  const groupBy = (array, key) => {
    return array.reduce((result, currentValue) => {
      (result[currentValue[key]] = result[currentValue[key]] || []).push(
        currentValue
      );

      return result;
    }, {});
  };

  function downloadAsExcelOb(file) {
    const worksheet = XLSX.utils.json_to_sheet(file);

    const workbook = {
      Sheets: {
        Exhibits: worksheet,
      },
      SheetNames: ["Exhibits"],
    };

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    saveAsExcel(excelBuffer, "Objects_File");
  }

  async function objectExport() {
    let objects = await ObjectApi.Get();
    console.log(objects);
    const objectsById = groupBy(objects, "objectS_ID");
    console.log(objectsById);
    console.log(`ta object einai ${objects.length}`);
    console.log(Object.keys(objectsById));
    let count = 0;
    let catRight = [];
    const categories = await CategoryApi.GetCategoriesOfSite(
      parseInt(site.siteId)
    );

    const percentCount = 100 / Object.keys(objectsById).length;

    for (let ch in objectsById) {
      if (objectsById[ch].length > 1) {
        const child = await CategoryApi.GetById(objectsById[ch][1].caT_ID);

        if (child.parentId !== null) {
          catRight.push(objectsById[ch][1]);
        } else {
          catRight.push(objectsById[ch][0]);
        }
      } else {
        catRight.push(objectsById[ch][0]);
      }
    }
    console.log(catRight);
    for (let obj of catRight) {
      console.log(obj);

      const objectsSum = await ObjectApi.GetByTranslation(obj.objectS_ID);

      console.log(objectsSum);

      for (let trans of objectsSum) {
        const objectModel = {
          Name: trans.name,
          Description: trans.description
            .replace(/<p>/gi, "")
            .replace(/<\/p>/gi, ""),
          //"Image": 1,
          ObjectCode: trans.code,
          Languages: await languageIdToInit(trans.languageId),
          /*"Index": parseInt(index)*/
          //"CriticalRate": false,
          Latitude: trans.latitude,
          Longitude: trans.longitude,
          Category: await categoryIdToName(obj.caT_ID, trans.languageId),
          Exhibition: await findExhibitionsOfObjects(
            trans.objectId,
            trans.languageId
          ),
        };
        objectData.push(objectModel);
      }

      if (count < Object.keys(objectsById).length) {
        count++;
      }

      updateProgressBar(`${count * percentCount - 1}`);
      updateStatusDownload(`${count} from ${Object.keys(objectsById).length}`);
    }

    objectData.map((x) =>
      unique.filter(
        (a) =>
          a.ObjectCode == x.ObjectCode &&
          a.Languages == x.Languages &&
          a.Category == x.Category
      ).length > 0
        ? null
        : unique.push(x)
    );
    unique.map((x) =>
      uniqueFiltered.filter(
        (a) =>
          a.ObjectCode == x.ObjectCode &&
          a.Languages == x.Languages &&
          a.Name == x.Name
      ).length > 0
        ? null
        : uniqueFiltered.push(x)
    );
    uniqueFiltered.map((x) =>
      finalArray.filter(
        (a) =>
          a.ObjectCode == x.ObjectCode &&
          a.Languages == x.Languages &&
          a.Name == x.Name
      ).length > 0
        ? null
        : finalArray.push(x)
    );

    //var aa = ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l"];

    //var res = uniqueFiltered.filter(function (v, i) {
    //    // check the index is odd
    //    return i % 2 == 1;
    //});
    function updateArray(obj) {
      //var seen = {};
      //obj = obj.filter(function (entry) {
      //    var previous;
      //    // Have we seen this label before?
      //    if (seen.hasOwnProperty(entry.ObjectCode)) {
      //        // Yes, grab it and add this data to it
      //        previous = seen[entry.ObjectCode];
      //        previous.category.push(entry.Category);
      //        // Don't keep this entry, we've merged it into the previous one
      //        return false;
      //    }
      //    // entry.data probably isn't an array; make it one for consistency
      //    if (!Array.isArray(entry.category)) {
      //        entry.category = [entry.Category];
      //    }
      //    // Remember that we've seen it
      //    seen[entry.ObjectCode] = entry;
      //    // Keep this one, we'll merge any others that match into it
      //    return true;
      //});
    }

    //updateArray(unique);

    //var fArray = groupBy(unique, 'ObjectCode');

    //finalArray = unique.concat(res);
    //for (let cat in fArray) {
    //    for (let cate of cat) {
    //        var cateL = await initTolanguageId(cate[0].Languages);
    //        console.log(cateL);
    //        var cateId = await categoryNameToId(cate[0].Category, cateL);
    //        console.log(cateId);
    //        var hasParent = await checkIfHasParent(cateId);
    //        console.log(hasParent);
    //    }
    //}
    updateProgressBar(100);

    console.log(unique);
    console.log(uniqueFiltered);
    console.log(finalArray);

    //console.log(res);
    //console.log(finalArray);

    //console.log(objectData);
  }

  // /////////filesaver/////////  v// /////////filesaver/////////// /////////filesaver/////////// /////////filesaver/////////// /////////filesaver/////////// /////////filesaver/////////// /////////filesaver/////////

  const EXCEL_TYPE =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const EXCEL_EXTENSION = ".xlsx";

  // let _global =
  //   typeof window === "object" && window.window === window
  //     ? window
  //   //   : typeof self === "object" && self.self === self
  //   //   ? self
  //     : typeof global === "object" && global.global === global
  //     ? global
  //     : this;

  // function bom(blob, opts) {
  //   if (typeof opts === "undefined") opts = { autoBom: false };
  //   else if (typeof opts !== "object") {
  //     console.warn("Deprecated: Expected third argument to be a object");
  //     opts = { autoBom: !opts };
  //   }

  //   // prepend BOM for UTF-8 XML and text/* types (including HTML)
  //   // note: your browser will automatically convert UTF-16 U+FEFF to EF BB BF
  //   if (
  //     opts.autoBom &&
  //     /^\s*(?:text\/\S*|application\/xml|\S*\/\S*\+xml)\s*;.*charset\s*=\s*utf-8/i.test(
  //       blob.type
  //     )
  //   ) {
  //     return new Blob([String.fromCharCode(0xfeff), blob], { type: blob.type });
  //   }
  //   return blob;
  // }

  // function download(url, name, opts) {
  //   let xhr = new XMLHttpRequest();
  //   xhr.open("GET", url);
  //   xhr.responseType = "blob";
  //   xhr.onload = function () {
  //     saveAs(xhr.response, name, opts);
  //   };
  //   xhr.onerror = function () {
  //     console.error("could not download file");
  //   };
  //   xhr.send();
  // }

  // function corsEnabled(url) {
  //   let xhr = new XMLHttpRequest();
  //   // use sync to avoid popup blocker
  //   xhr.open("HEAD", url, false);
  //   try {
  //     xhr.send();
  //   } catch (e) {}
  //   return xhr.status >= 200 && xhr.status <= 299;
  // }

  // // `a.click()` doesn't work for all browsers (#465)
  // function click(node) {
  //   try {
  //     node.dispatchEvent(new MouseEvent("click"));
  //   } catch (e) {
  //     var evt = document.createEvent("MouseEvents");
  //     evt.initMouseEvent(
  //       "click",
  //       true,
  //       true,
  //       window,
  //       0,
  //       0,
  //       0,
  //       80,
  //       20,
  //       false,
  //       false,
  //       false,
  //       false,
  //       0,
  //       null
  //     );
  //     node.dispatchEvent(evt);
  //   }
  // }

  // // Detect WebView inside a native macOS app by ruling out all browsers
  // // We just need to check for 'Safari' because all other browsers (besides Firefox) include that too
  // // https://www.whatismybrowser.com/guides/the-latest-user-agent/macos
  // let isMacOSWebView =
  //   /Macintosh/.test(navigator.userAgent) &&
  //   /AppleWebKit/.test(navigator.userAgent) &&
  //   !/Safari/.test(navigator.userAgent);

  // let saveAs =
  //   _global.saveAs ||
  //   // probably in some web worker
  //   (typeof window !== "object" || window !== _global
  //     ? function saveAs() {
  //         /* noop */
  //       }
  //     : // Use download attribute first if possible (#193 Lumia mobile) unless this is a macOS WebView
  //     "download" in HTMLAnchorElement.prototype && !isMacOSWebView
  //     ? function saveAs(blob, name, opts) {
  //         let URL = _global.URL || _global.webkitURL;
  //         let a = document.createElement("a");
  //         name = name || blob.name || "download";

  //         a.download = name;
  //         a.rel = "noopener"; // tabnabbing

  //         // TODO: detect chrome extensions & packaged apps
  //         // a.target = '_blank'

  //         if (typeof blob === "string") {
  //           // Support regular links
  //           a.href = blob;
  //           if (a.origin !== window.location.origin) {
  //             corsEnabled(a.href)
  //               ? download(blob, name, opts)
  //               : click(a, (a.target = "_blank"));
  //           } else {
  //             click(a);
  //           }
  //         } else {
  //           // Support blobs
  //           a.href = URL.createObjectURL(blob);
  //           setTimeout(function () {
  //             URL.revokeObjectURL(a.href);
  //           }, 4e4); // 40s
  //           setTimeout(function () {
  //             click(a);
  //           }, 0);
  //         }
  //       }
  //     : // Use msSaveOrOpenBlob as a second approach
  //     "msSaveOrOpenBlob" in navigator
  //     ? function saveAs(blob, name, opts) {
  //         name = name || blob.name || "download";

  //         if (typeof blob === "string") {
  //           if (corsEnabled(blob)) {
  //             download(blob, name, opts);
  //           } else {
  //             let a = document.createElement("a");
  //             a.href = blob;
  //             a.target = "_blank";
  //             setTimeout(function () {
  //               click(a);
  //             });
  //           }
  //         } else {
  //           navigator.msSaveOrOpenBlob(bom(blob, opts), name);
  //         }
  //       }
  //     : // Fallback to using FileReader and a popup
  //       function saveAs(blob, name, opts, popup) {
  //         // Open a popup immediately do go around popup blocker
  //         // Mostly only available on user interaction and the fileReader is async so...
  //         popup = popup || window.open("", "_blank");
  //         if (popup) {
  //           popup.document.title = popup.document.body.innerText =
  //             "downloading...";
  //         }

  //         if (typeof blob === "string") return download(blob, name, opts);

  //         let force = blob.type === "application/octet-stream";
  //         const isSafari =
  //           /constructor/i.test(_global.HTMLElement) || _global.safari;
  //         const isChromeIOS = /CriOS\/[\d]+/.test(navigator.userAgent);

  //         if (
  //           (isChromeIOS || (force && isSafari) || isMacOSWebView) &&
  //           typeof FileReader !== "undefined"
  //         ) {
  //           // Safari doesn't allow downloading of blob URLs
  //           let reader = new FileReader();
  //           reader.onloadend = function () {
  //             let url = reader.result;
  //             url = isChromeIOS
  //               ? url
  //               : url.replace(/^data:[^;]*;/, "data:attachment/file;");
  //             if (popup) popup.location.href = url;
  //             else window.location = url;
  //             popup = null; // reverse-tabnabbing #460
  //           };
  //           reader.readAsDataURL(blob);
  //         } else {
  //           let URL = _global.URL || _global.webkitURL;
  //           let url = URL.createObjectURL(blob);
  //           if (popup) popup.location = url;
  //           else window.location.href = url;
  //           popup = null; // reverse-tabnabbing #460
  //           setTimeout(function () {
  //             URL.revokeObjectURL(url);
  //           }, 4e4); // 40s
  //         }
  //       });

  // _global.saveAs = saveAs.saveAs = saveAs;

  // if (typeof module !== "undefined") {
  //   module.exports = saveAs;
  // }

  function saveAsExcel(buffer, filename) {
    const data = new Blob([buffer], { type: EXCEL_TYPE });
    console.log(data);
    let a = document.createElement("a");

    a.href = window.URL.createObjectURL(data);
    const d = new Date();
    a.download = `Files_Export_${
      d.getDate() + "_" + d.getMonth() + "_" + d.getFullYear()
    }`;
    a.click();
    //saveAs(data, filename + "_Export_" + EXCEL_EXTENSION);
  }

  const downloadExhibFile = async () => {
    // if (claims.claims.role === "Viewer") {
    //     //alert(language.global.userRole);
    //     swal({
    //         title: language.media.invalidAction,
    //         text: language.global.userRole,
    //         icon: "warning",
    //         button: "OK",

    //     })
    // } else {
    if (userRole != "Admin") {
      Swal.fire({
        title: languageChosenSite == "EL" ? languageGr.media.invalidAction : language.media.invalidAction,
        text: languageChosenSite == "EL" ? languageGr.global.userRole : language.global.userRole,
        icon: "warning",
        button: "OK",
      });
      return;
    }
    setLoading(false);
    $("#configurationTab").css("pointer-events", "none");
    $("#objectTab").css("pointer-events", "none");
    //document.getElementById("expOb").disabled = true;
    $("#mediaTab").css("pointer-events", "none");
    updateProgressBar(0);
    updateStatusDownload("Object Files");
    await objectExport();

    //updateProgressBar(70);

    downloadAsExcelOb(unique); //used to be unique

    Swal.fire({
      title: "Success",
      text:
        languageChosenSite === "EL"
          ? languageGr.export.successDownload
          : language.export.successDownload,
      icon: "success",
    });
    setLoading(true);
    document.querySelector("#progressStatus").innerText = "";

    //document.querySelector("#progressStatus").innerText = "";
    //document.getElementById("expOb").disabled = false;
    $("#configurationTab").css("pointer-events", "auto");
    $("#objectTab").css("pointer-events", "auto");

    $("#mediaTab").css("pointer-events", "auto");
    //}
  };

  const downloadMediaFile = async () => {
    // if (claims.claims.role === "Viewer") {
    //   //alert(language.global.userRole);
    //   swal({
    //       title: language.media.invalidAction,
    //       text: language.global.userRole,
    //       icon: "warning",
    //       button: "OK",

    //     })
    // } else {
    if (userRole != "Admin") {
      Swal.fire({
        title: languageChosenSite == "EL" ? languageGr.media.invalidAction : language.media.invalidAction,
        text: languageChosenSite == "EL" ? languageGr.global.userRole : language.global.userRole,
        icon: "warning",
        button: "OK",
      });
      return;
    }
    setLoading(false);
    updateProgressBar(0);

    $("#configurationTab").css("pointer-events", "none");
    $("#objectTab").css("pointer-events", "none");
    //document.getElementById("expMedia").disabled = true;
    $("#mediaTab").css("pointer-events", "none");
    updateStatusDownload("Media Files");
    //me interval
    //updateProgressBar(95);
    let count = 0;
    let timer = setInterval(() => {
      updateProgressBar(count);
      if (count < 80) {
        count++;
      }
    }, 1000);

    //

    const mediaExport = await MediaApi.ExportAllMedia();

    //console.log(mediaExport.getResponseHeader());
    const invoke = await mediaExport.blob();

    let a = document.createElement("a");
    const url = new Blob([invoke], { type: "application/zip" });
    a.href = window.URL.createObjectURL(url);
    const d = new Date();
    a.download = `Media_Export_${
      d.getDate() + "_" + d.getMonth() + "_" + d.getFullYear()
    }`;
    a.click();
    setLoading(true);
    updateProgressBar(100);
    clearInterval(timer);
    Swal.fire({
      title: "Success",
      text:
        languageChosenSite === "EL"
          ? languageGr.export.successDownload
          : language.export.successDownload,
      icon: "success",
    });
    $("#configurationTab").css("pointer-events", "auto");
    $("#objectTab").css("pointer-events", "auto");
    document.querySelector("#progressStatus").innerText = "";
    //document.getElementById("expMedia").disabled = false;

    $("#mediaTab").css("pointer-events", "auto");

    //}
  };

  function updateProgressBar(value) {
    // if(value < 50) {
    //   document.querySelector("#progressB").style.background = "orange!important!";

    // } else {
    //   document.querySelector("#progressB").style.background = "green";

    // }
    document.querySelector("#bar").style.opacity = "1";
    document.querySelector("#progressB").style.width = `${value}%`;
  }

  // const downloadButtons = document.querySelector(".download");
  // const downloadButtonsArray = [...downloadButtons]

  // for (let b of downloadButtonsArray) {
  //   b.addEventListener("click", function (e) {
  //   /*$("#preloader").fadeIn();*/
  //       $(this).html('<span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>').attr('disabled', true);

  //       $(this).html('save').attr('disabled', false)
  //       this.style.display = "none";
  //      /* $("#preloader").fadeOut();*/
  //   });
  // }

  return (
    <div
      className="row align-items-center boxes"
      style={{
        width: "78%",
        "min-height": "52vh",
        /* box-shadow: 1px 2px 11px 0px #d2d2d2 !important; */
        "margin-top": "7vh",
        "margin-left": "10.5vw",
        border: "1px solid #d2d2d2",
        "box-shadow": "3px 2px 20px 0px",
        "border-radius": "20px",
      }}
    >
      <div className="col-1"></div>
      <div className="col-10">
        <div id="basicwizard">
          <ul
            className="nav nav-tabs nav-justified mb-5"
            style={{ background: "#dee2e6", "border-radius": "13px" }}
          >
            <li className="nav-item" data-target-form="#contactDetailForm">
              <a
                href="#contactDetail"
                data-bs-toggle="tab"
                data-toggle="tab"
                style={{ color: "black", "border-radius": "13px" }}
                id="configurationTab"
                className="nav-link icon-btn active"
                onClick={startProgress}
              >
                <i className="bx bxs-contact me-1"></i>
                <span className="d-none d-sm-inline">
                  {languageChosenSite === "EL"
                    ? languageGr.export.exportData
                    : language.export.exportData}
                </span>
              </a>
            </li>

            <li className="nav-item" data-target-form="#jobDetailForm">
              <a
                href="#jobDetail"
                data-bs-toggle="tab"
                data-toggle="tab"
                id="objectTab"
                style={{ color: "black", "border-radius": "13px" }}
                className="nav-link icon-btn"
                onClick={startProgress}
              >
                <i className="bx bxs-building me-1"></i>
                <span className="d-none d-sm-inline">
                  {languageChosenSite === "EL"
                    ? languageGr.export.exportExhibits
                    : language.export.exportExhibits}
                </span>
              </a>
            </li>

            <li className="nav-item">
              <a
                href="#finish"
                style={{
                  width: "100%",
                  color: "black",
                  "border-radius": "13px",
                }}
                data-bs-toggle="tab"
                data-toggle="tab"
                id="mediaTab"
                className="nav-link icon-btn"
                onClick={startProgress}
              >
                <i className="bx bxs-check-circle me-1"></i>
                <span className="d-none d-sm-inline">
                  {languageChosenSite === "EL"
                    ? languageGr.export.exportMedia
                    : language.export.exportMedia}
                </span>
              </a>
            </li>
          </ul>

          <div className="tab-content mb-5 pt-0 text-center">
            {/* loading bar from react */}

            {/* <ProgressBar
              width="100%"
              height="12px"
              rect
              id="pB"
              fontColor="gray"
              percentage={progress}
              rectPadding="1px"
              rectBorderRadius="20px"
              trackPathColor="transparent"
              bgColor="#333333"
              defColor={{
                fair: "orangered",
                good: "yellow",
                excellent: "green",
                poor: "red",
              }}
              trackBorderColor="gray"
            />

            <button onClick={addProgress} className="btn btn-info">
              Add 10%
            </button> */}

            {/* loading bar from react */}

            <div
              id="bar"
              className="progress mb-3"
              style={{
                "max-height": "3vh",
                "margin-left": "7.5%",
                opacity: "0.4",
              }}
            >
              <div
                id="progressB"
                style={{ "border-radius": "13px" }}
                className="bar progress-bar progress-bar-striped progress-bar-animated bg-success"
              ></div>
            </div>
            <span
              style={{ "margin-left": "2.4vw", color: "orange" }}
              id="progressStatus"
            ></span>

            <div
              className="tab-pane show active"
              id="contactDetail"
              style={{ "margin-top": "6vh", "margin-left": "3vw" }}
            >
              <h5 className="mb-5 mt-0">
                {languageChosenSite === "EL"
                  ? languageGr.export.downloadxlsConfig
                  : language.export.downloadxlsConfig}
              </h5>
              <div className="row">
                <div className="col-md-12">
                  <div
                    className="mb-5"
                    style={{
                      display: "flex",
                      "flex-direction": "column",
                      "align-items": "center",
                    }}
                  >
                    {!loading ? (
                      <ButtonSkeleton />
                    ) : (
                      <Button
                        className="addNewButton"
                        id="exp"
                        onClick={downloadConfigFile}
                      >
                        {languageChosenSite === "EL"
                          ? languageGr.export.buttonConfig
                          : language.export.buttonConfig}
                      </Button>
                    )}
                    {/* <a href="http://localhost:49999/Manual/CMSManual.pdf" download="abc.xls">Download Demo</a> */}
                  </div>
                </div>
              </div>
            </div>

            <div
              className="tab-pane"
              id="jobDetail"
              style={{ "margin-top": "6vh", "margin-left": "3vw" }}
            >
              <h5 className="mb-5 mt-0">
                {" "}
                {languageChosenSite === "EL"
                  ? languageGr.export.downloadxlsExhib
                  : language.export.downloadxlsExhib}
              </h5>
              <div className="row">
                <div className="col-md-12">
                  <div
                    className="mb-5"
                    style={{
                      display: "flex",
                      "flex-direction": "column",
                      "align-items": "center",
                    }}
                  >
                    {!loading ? (
                      <ButtonSkeleton />
                    ) : (
                      <Button
                        className="addNewButton"
                        id="expOb"
                        onClick={downloadExhibFile}
                      >
                        {languageChosenSite === "EL"
                          ? languageGr.export.buttonExhib
                          : language.export.buttonExhib}
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div
              className="tab-pane"
              id="finish"
              style={{ "margin-top": "6vh", "margin-left": "3vw" }}
            >
              <h5 className="mb-5 mt-0">
                {languageChosenSite === "EL"
                  ? languageGr.export.downloadxlsMedia
                  : language.export.downloadxlsMedia}
              </h5>
              <div className="row">
                <div className="col-md-12">
                  <div
                    className="mb-5"
                    style={{
                      display: "flex",
                      "flex-direction": "column",
                      "align-items": "center",
                    }}
                  >
                    {!loading ? (
                      <ButtonSkeleton />
                    ) : (
                      <Button
                        className="addNewButton"
                        id="expMedia"
                        onClick={downloadMediaFile}
                      >
                        {languageChosenSite === "EL"
                          ? languageGr.export.buttonMedia
                          : language.export.buttonMedia}
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-3"></div>
    </div>
  );
};

export default ExportComponent;
