import React from 'react'

import { Header } from "../Header/Header";
import { Footer } from "../Footer/Footer";

function Front() {
    return (
        <div>
            <Header />
            <Footer />
        </div>
    )
}

export default Front