import React from 'react';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import * as IoIcons from 'react-icons/io';
import content from "../../images/Sidebar/content-green.png";
import add from "../../images/Sidebar/add.png";
import maps from "../../images/Sidebar/maps-green.png";
import multimedia from "../../images/Sidebar/multimedia-green2.png";
import categories from "../../images/Sidebar/categories-green.png";
import events from "../../images/Sidebar/events-green.png";
import fields from "../../images/Sidebar/fields-green.png";
import notification from "../../images/Sidebar/notification.png";
import presentation from "../../images/Sidebar/presentation-green.png";
import languages from '../../images/language-green.png'
import museumgreen from '../../images/museum-green.png'
import setting from '../../images/settings.png'
import statistics from '../../images/Statistics.png'
import exit from '../../images/Sidebar/exit-chaki.png'

export const SidebarDataConfigGr = [
    {
        title: 'Διαχείριση Γλωσσών',
        path: '/Languages',
        icon: <img src={languages}/>,
        cName: 'nav-text'
    }, 
    
    {
        title: 'Διαχείριση Μουσείου',
        path: '/Site',
        icon: <img src={museumgreen}/>,
        cName: 'nav-text'
    },
    {
        title: 'Εξαγωγή Δεδομένων',
        path: '/Export',
        icon: <img src={setting}/>,
        cName: 'nav-text'
    },
    {
        title: 'Εισαγωγή Δεδομένων',
        path: '/Import',
        icon: <img src={setting}/>,
        cName: 'nav-text'
    },
    {
        title: 'Στατιστικά Δεδομένα',
        path: '/Statistics',
        icon: <img src={statistics}/>,
        cName: 'nav-text'
    },
    {
        title: 'Εισαγωγή Ετικετών',
        path: '/TagManagement',
        icon: <img src={setting}/>,
        cName: 'nav-text'
    },
    {
        title: 'Κεντρικό Μενού',
        path: '/Management',
        icon: <img src={exit}/>,
        cName: 'nav-text'
    },

];

export const SidebarDataConfig = [
    {
        title: 'Languages',
        path: '/Languages',
        icon: <img src={languages}/>,
        cName: 'nav-text'
    }, 
    
    {
        title: 'Sites Management',
        path: '/Site',
        icon: <img src={museumgreen}/>,
        cName: 'nav-text'
    },
    {
        title: 'Export Data',
        path: '/Export',
        icon: <img src={setting}/>,
        cName: 'nav-text'
    },
    {
        title: 'Import Data',
        path: '/Import',
        icon: <img src={setting}/>,
        cName: 'nav-text'
    },
    {
        title: 'Statistics',
        path: '/Statistics',
        icon: <img src={statistics}/>,
        cName: 'nav-text'
    },
    {
        title: 'Tag Management',
        path: '/TagManagement',
        icon: <img src={setting}/>,
        cName: 'nav-text'
    },
    {
        title: 'Return To Main Menu',
        path: '/Management',
        icon: <img src={exit}/>,
        cName: 'nav-text'
    },

];