import React, { useState, useEffect, Fragment } from "react";

// Api
import { LanguageApi } from "../../api/api.service";
import { language, languageGr } from "../../api/lang";
//Authservice
import { authService } from "../../api/auth.service";

// Components
import CardSkeleton from "../UI/CardSkeleton";
import TableGeneral from "../UI/TableGeneral";

// Icons
import manage from "../../images/manage-orange.png";

// Swal alert
import Swal from "sweetalert2";

// jquery
import $ from "jquery";

// Bootstrap table filter
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";

// Bootstrap
import { Modal, Form, Button, Row, Col } from "react-bootstrap";

const LanguageComponent = (props) => {
  const [languages, setLanguages] = useState([]);
  const [tableChanged, setTableChanged] = useState(false);
  const [languageChosenSite, setLanguageChosenSite] = useState("");
  const [isLoading, setLoading] = useState(true);
  const [userRole, setUserRole] = useState("");
  const [addLang, setAddLang] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    document.body.className = "";

    getData();
  }, [tableChanged]);

  const getData = async () => {

    const langs = await LanguageApi.Get();
    console.log(langs);
    setLanguages(langs);
    changeHead();

    fetchUser();
    setLanguageChosenSite(sessionStorage.getItem("languageChosen"));
    setLoading(false);

    props.changeUrl(true);

  };

  const fetchUser = async () => {
    const user = await authService.getUserAsync();
    setUserRole(user.claims[0].value);
  };

  

  const changeHead = () => {
    if (sessionStorage.getItem("languageChosen") === "EL") {
      document.querySelector(".pageHead").innerHTML =`<div style="width: 100%; height:100%; display: inline-flex; justify-content: center;"><h4 style='padding-top: 3.5vh;text-align: center;color: #f7aa47;'><img style="height: 4vh;margin-top:-1vh;" class='fa fa-fw fa-file' src='${manage}' />${languageGr.global.titles.language}</h4></div>`;
    } else {
      document.querySelector(".pageHead").innerHTML =`<div style="width: 100%; height:100%; display: inline-flex; justify-content: center;"><h4 style='padding-top: 3.5vh;text-align: center;color: #f7aa47;'><img style="height: 4vh;margin-top:-1vh;" class='fa fa-fw fa-file' src='${manage}' />${language.global.titles.language}</h4></div>`;
    }
  };

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  const languagesHeaders = [
    {
      dataField: "languageId",
      text: "Id",
      sort: true,
    },
    {
      dataField: "name",
      text:
        languageChosenSite === "EL"
          ? languageGr.table.title
          : language.table.title,
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: "initials",
      text:
        languageChosenSite === "EL"
          ? languageGr.table.initials
          : language.table.initials,
      sort: true,
    },
  ];

  const addLanguage = async () => {
    setShowModal(false);

    if (userRole != "Admin") {
      Swal.fire({
        title: languageChosenSite == "EL" ? languageGr.media.invalidAction : language.media.invalidAction,
        text: languageChosenSite == "EL" ? languageGr.global.userRole : language.global.userRole,
        icon: "warning",
        button: "OK",
      });
      return;
    }

    const languageName = $("#languageNameDropDown")
      .children("option:selected")
      .text();
    const languageDescription = $("#languageNameDropDown")
      .children("option:selected")
      .text();
    const languageInitials = $("#languageNameDropDown")
      .children("option:selected")
      .val();
    const languageA = {
      name: languageName,
      description: languageDescription,
      initials: languageInitials,
    };

    await LanguageApi.Create(languageA);
    setTableChanged(!tableChanged);

    document.getElementById("backButton").click();
  };

  return (
    <Fragment>
      <div>
        {isLoading ? (
          <div className="col-7 offset-3">
            <CardSkeleton />
          </div>
        ) : (
          <TableGeneral data={languages} columns={languagesHeaders} />
        )}

        <Button onClick={handleShowModal} className="addNewButton">
          {languageChosenSite === "EL"
            ? languageGr.global.word.addNew
            : language.global.word.addNew}
        </Button>
        <Modal show={showModal} onHide={handleCloseModal} size="lg" centered>
          <Modal.Header>
            <Modal.Title>
              {languageChosenSite === "EL"
                ? languageGr.global.language.add
                : language.global.language.add}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group as={Row} className="mb-3">
                <Col sm={3} className="d-flex justify-content-center">
                  <Form.Label>
                    {languageChosenSite === "EL"
                      ? languageGr.global.name
                      : language.global.name}
                    :
                  </Form.Label>
                </Col>
                <Col sm={6} className="d-flex justify-content-center">
                  <Form.Select id="languageNameDropDown">
                    <option value="AF">
                      {languageChosenSite === "EL"
                        ? languageGr.languages.Africans
                        : language.languages.Africans}
                    </option>

                    <option value="AR">
                      {languageChosenSite === "EL"
                        ? languageGr.languages.Arabic
                        : language.languages.Arabic}
                    </option>

                    <option value="ZH">
                      {languageChosenSite === "EL"
                        ? languageGr.languages.Chinese
                        : language.languages.Chinese}
                    </option>

                    <option value="EN">
                      {languageChosenSite === "EL"
                        ? languageGr.languages.English
                        : language.languages.English}
                    </option>

                    <option value="FR">
                      {languageChosenSite === "EL"
                        ? languageGr.languages.French
                        : language.languages.French}
                    </option>

                    <option value="DE">
                      {languageChosenSite === "EL"
                        ? languageGr.languages.German
                        : language.languages.German}
                    </option>
                    <option value="EL">
                      {languageChosenSite === "EL"
                        ? languageGr.languages.Greek
                        : language.languages.Greek}
                    </option>

                    <option value="HE">
                      {languageChosenSite === "EL"
                        ? languageGr.languages.Hebrew
                        : language.languages.Hebrew}
                    </option>
                    <option value="HI">
                      {languageChosenSite === "EL"
                        ? languageGr.languages.Hindi
                        : language.languages.Hindi}
                    </option>

                    <option value="IT">
                      {languageChosenSite === "EL"
                        ? languageGr.languages.Italian
                        : language.languages.Italian}
                    </option>
                    <option value="JA">
                      {languageChosenSite === "EL"
                        ? languageGr.languages.Japanese
                        : language.languages.Japanese}
                    </option>

                    <option value="KO">
                      {languageChosenSite === "EL"
                        ? languageGr.languages.Korean
                        : language.languages.Korean}
                    </option>

                    <option value="PT">
                      {languageChosenSite === "EL"
                        ? languageGr.languages.Portugese
                        : language.languages.Portugese}
                    </option>

                    <option value="RU">
                      {languageChosenSite === "EL"
                        ? languageGr.languages.Russian
                        : language.languages.Russian}
                    </option>

                    <option value="ES">
                      {languageChosenSite === "EL"
                        ? languageGr.languages.Spanish
                        : language.languages.Spanish}
                    </option>

                    <option value="TR">
                      {languageChosenSite === "EL"
                        ? languageGr.languages.Turkish
                        : language.languages.Turkish}
                    </option>
                  </Form.Select>
                </Col>
                <Col sm={3} className="d-flex justify-content-center">
                  <Button
                    id="addButton"
                    className="addNewButton"
                    onClick={addLanguage}
                  >
                    {languageChosenSite === "EL"
                      ? languageGr.global.word.addNew
                      : language.global.word.addNew}
                  </Button>
                </Col>
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="closeButton"
              variant="closeButton"
              onClick={handleCloseModal}
            >
              {languageChosenSite === "EL"
                ? languageGr.global.close
                : language.global.close}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </Fragment>
  );
};

export default LanguageComponent;
