import React, { useState } from "react";
import PreviewIcon from "@mui/icons-material/Preview";
import { Modal, Button, Row, Col } from "react-bootstrap";
import { language, languageGr } from "../../../api/lang";
import StayPrimaryLandscapeIcon from "@mui/icons-material/StayPrimaryLandscape";
import LaptopMacIcon from "@mui/icons-material/LaptopMac";
function Preview(props) {
  const [bgImageContainer, setBgImageContainer] = useState(
    "bg-image-container-desktop"
  );
  const [previewImageStyle, setPreviewImageStyle] = useState(
    "preview-image-desktop"
  );
  const [imagePreview, setImagePreview] = useState("");
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  const handleShow = () => {
    setShow(true);
    const img = props.canvas.toDataURL();
    setImagePreview(img);
  };

  const onDesktopView = () => {
    setPreviewImageStyle("preview-image-desktop");
    setBgImageContainer("bg-image-container-desktop");
  };

  const onMobileView = () => {
    setPreviewImageStyle("preview-image-mobile");
    setBgImageContainer("bg-image-container-mobile");
  };

  return (
    <>
      <button className="responsive-btn" onClick={handleShow}>
        <PreviewIcon />
      </button>

      <Modal
        size="xl"
        // fullscreen={true}
        show={show}
        onHide={handleClose}
        dialogClassName="modal-90w"
        className="preview-modal"
      >
        <Modal.Body>
          <div className="preview-button-container">
            <Row>
              <Col sm={12} className="d-flex justify-content-center">
                <button className="responsive-btn" onClick={onMobileView}>
                  <StayPrimaryLandscapeIcon />
                </button>
                <button className="responsive-btn" onClick={onDesktopView}>
                  <LaptopMacIcon />
                </button>
              </Col>
            </Row>
          </div>
          <div className="preview-container">
            <div className={bgImageContainer}>
              <img className={previewImageStyle} id="img" src={imagePreview} />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            {props.languageChosenSite === "EL"
              ? languageGr.indoor.close
              : language.indoor.close}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Preview;
