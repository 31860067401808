import React, { useState, useEffect } from "react";

// DatePicker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// TinyMCE
import BundledEditor from "../../BundledEditor";

// Swal
import Swal from "sweetalert2";

// API
import { PostsApi } from "../../api/api.service";

//dynamic language
import { language, languageGr } from "../../api/lang";

// Bootstrap
import { Modal, Button, Row, Col, Tab, Form, Nav } from "react-bootstrap";

// CSS
import "./Modal.css";

// FaIcons
import * as FaIcons from "react-icons/fa";

const ModalParent = (props) => {
  const [posts, setPosts] = useState([]);
  const [allPosts, setAllPosts] = useState([]);
  const [targetId, setTargetId] = useState(props.targetId);
  const [eventPublishDate, setEventPublishDate] = useState(new Date());
  const [eventExpiresDate, setEventExpiresDate] = useState(new Date());
  

  const [postStatus, setPostStatus] = useState();
  const [siteId, setSiteID] = useState();
  const [userId, setUserId] = useState();
  const [languageChosenSite, setLanguageChosenSite] = useState("");
  const languages = props.languages;

  useEffect(() => {
    getData(targetId);
    console.log("Use Effect Runs");
  }, [targetId]);

  const getData = async (id) => {
    setLanguageChosenSite(sessionStorage.getItem("languageChosen"));

    console.log("--------------------------------------");
    console.log("Current id: ", id);
    const posts = await PostsApi.Get();
    console.log("allPosts");
    console.log(posts);

    let arr = [];
    posts.map((item) => {
      arr.push(item.id);
    });

    setAllPosts(arr);

    const currentPost = await PostsApi.GetById(id);
    console.log("currentPost");
    console.log(currentPost);

    if (currentPost !== undefined || null) {
      setEventPublishDate(new Date(currentPost.publishedAt));
      setEventExpiresDate(new Date(currentPost.expiresAt));
      setPostStatus(currentPost.status);
      setSiteID(currentPost.siteId);
      setUserId(currentPost.userId);
      console.log("publishedAt: ", currentPost.publishedAt);
      console.log("expiresAt: ", currentPost.expiresAt);
    }

    let newPost = [];
    const translationsOfCurrentPost = await PostsApi.GetAllTranslation(id);
    console.log("translationsOfCurrentPost");
    console.log("id: ", id);
    console.log(translationsOfCurrentPost);

    translationsOfCurrentPost.map((post) => {
      languages.map((lang) => {
        if (post.initials === lang.initials) {
          newPost.push({ ...post, lang: lang.name });
        }
      });
    });

    setPosts(newPost);
  };

  const next = () => {
    let index = allPosts.indexOf(targetId);
    index === allPosts.length - 1
      ? setTargetId(allPosts[0])
      : setTargetId(allPosts[index + 1]);
    getData(targetId);
  };

  const prev = () => {
    let index = allPosts.indexOf(targetId);
    index === 0
      ? setTargetId(allPosts[allPosts.length - 1])
      : setTargetId(allPosts[index - 1]);
    getData(targetId);
  };

  const updateDates = async () => {
    console.log("publishedAt: ", eventPublishDate.toISOString());
    console.log("expiresAt: ", eventExpiresDate.toISOString());
    console.log("Update dates");

    const dates = {
      ExpiresAt: eventPublishDate.toISOString(),
      Id: parseInt(targetId),
      PublishedAt: eventExpiresDate.toISOString(),
      SiteId: siteId,
      Status: postStatus,
      UserId: userId,
    };

    const update = await PostsApi.Update(dates);
    console.log(update);
    console.log(dates);
    //setModalShow(!modalShow);

    props.setTableChanged(!props.tableChanged);
    
  };

  return (
    <div>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="fw-light">
            {languageChosenSite === "EL"
              ? languageGr.global.eventsWords.editEvent
              : language.global.eventsWords.editEvent}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            id="event-date-picker"
            className="d-flex justify-content-evenly shadow-sm bg-body rounded p-3 mb-2"
          >
            <h5 className="fw-normal">
              {languageChosenSite === "EL"
                ? languageGr.global.eventsWords.publish
                : language.global.eventsWords.publish}
            </h5>
            <div style={{ zIndex: 999 }}>
              <DatePicker
                selected={eventPublishDate}
                
                onChange={(date) => setEventPublishDate(date)}
                selectsStart
                className="fw-light textCenter"
                onCalendarClose={() => updateDates()}
              />
            </div>
            <h5 className="fw-normal">
              {languageChosenSite === "EL"
                ? languageGr.global.eventsWords.expires
                : language.global.eventsWords.expires}
            </h5>
            <div style={{ zIndex: 999 }}>
              <DatePicker
                selected={eventExpiresDate}
                onChange={(date) => setEventExpiresDate(date)}
                selectsEnd
                // startDate={eventPublishDate}
                // endDate={eventExpiresDate}
                // minDate={eventPublishDate}
                className="fw-light textCenter"
                onCalendarClose={() => updateDates()}
              />
            </div>
          </div>
          <Tab.Container id="lang-left-tabs" defaultActiveKey={`#Greek`}>
            <Row>
              <Col sm={3}>
                <Nav variant="pills" className="flex-column">
                  {languages.map((value) => (
                    <Nav.Link href={`#${value.name}`}>{value.name}</Nav.Link>
                  ))}
                </Nav>
              </Col>
              <Col sm={9}>
                <Tab.Content>
                  {languages.map((lang) => (
                    <Tab.Pane eventKey={`#${lang.name}`}>
                      <Data
                      setTableChanged={props.setTableChanged}
                      setIdClicked={props.setIdClicked}
                      tableChanged={props.tableChanged}
                        postId={targetId}
                        languageId={lang.languageId}
                        status={posts
                          .map((post) =>
                            post.lang === lang.name ? post.status : null
                          )
                          .join("")}
                        translationId={posts
                          .map((post) =>
                            post.lang === lang.name ? post.translationId : null
                          )
                          .join("")}
                        initialDesc={posts
                          .map((post) =>
                            post.lang === lang.name ? post.content : null
                          )
                          .join("")}
                        initialTitle={posts
                          .map((post) =>
                            post.lang === lang.name ? post.title : null
                          )
                          .join("")}
                      />
                    </Tab.Pane>
                  ))}
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
          <div className="text-center pt-3">
            <Button onClick={prev} className="backwards mx-1">
              <FaIcons.FaChevronLeft />
            </Button>

            <Button onClick={next} className="forward mx-1">
              <FaIcons.FaChevronRight />
            </Button>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="closeButton"
            variant="closeButton"
            onClick={props.onHide}
          >
            {languageChosenSite === "EL"
              ? languageGr.global.close
              : language.global.close}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ModalParent;

function Data({
  initialTitle,
  initialDesc,
  languageId,
  translationId,
  postId,
  userRole,
  setTableChanged,
  tableChanged,
  setIdClicked
}) {
  const [desc, setDesc] = useState(initialDesc ?? "");
  const [title, setTitle] = useState(initialTitle ?? "");
  const [languageChosenSite, setLanguageChosenSite] = useState("");
  


  useEffect(() => {
    update();
  }, [initialTitle, initialDesc]);

  const update = () => {
    setDesc(initialDesc ?? "");
    setTitle(initialTitle ?? "");
    setLanguageChosenSite(sessionStorage.getItem("languageChosen"));
    

  };

  const updateData = async () => {
    if (userRole == "Viewer") {
      Swal.fire({
        title: languageChosenSite == "EL" ? languageGr.media.invalidAction : language.media.invalidAction,
        text: languageChosenSite == "EL" ? languageGr.global.userRole : language.global.userRole,
        icon: "warning",
        button: "OK",
      });
      return;
    }
    let translation = {
      translations: [],
    };

    console.log(`New title: ${title}`);
    console.log(`New description: ${desc}`);
    console.log(`Language ID: ${languageId}`);
    console.log(`Translation ID: ${translationId}`);

    if (initialTitle || initialDesc !== "") {
      if (initialTitle !== title || initialDesc !== desc) {
        //UpdateTranslation
        console.log("Update data");
        translation.translations.push({
          translationId: translationId,
          languageId: languageId,
          title: title,
          content: desc,
        });
        const update = await PostsApi.UpdateTranslation(translation);

        if (update) {
          Swal.fire({
            title: "Success",
            text: `Action was successfully done`,
            icon: "success",
          });
        }
      } else {
        console.log("The data are same. Update not needed");
      }
    } else {
      //CreateTranslation
      console.log("Create data");
      translation.translations.push({
        id: postId,
        languageId: languageId,
        title: title,
        content: desc,
      });
      const create = await PostsApi.CreateTranslation(translation);

      if (create) {
        Swal.fire({
          title: "Success",
          text: `Action was successfully done`,
          icon: "success",
        });
      }
    }

    console.log(translation);
    setTableChanged(!tableChanged);

  };

  return (
    <>
      <div className="pb-2">
        <Form.Control
          defaultValue={initialTitle}
          value={title}
          onChange={(newT) => setTitle(newT.target.value)}
        />
      </div>
      <div className="pb-2">
        <BundledEditor
          init={{
            height: 400,
            selector: "textarea",
            plugins:
              "advlist anchor autolink help image link lists searchreplace table wordcount emoticons insertdatetime code",
            toolbar:
              "undo redo blocks image link media numlist bullist insertdatetime|",
            content_style:
              "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
          }}
          initialValue={initialDesc}
          value={desc}
          onEditorChange={(newD) => setDesc(newD)}
        />
      </div>
      <div>
        <Button
          className="addNewButton"
          variant="addNewButton"
          onClick={() => updateData()}
        >
          {languageChosenSite === "EN"
            ? language.global.saveChanges
            : languageGr.global.saveChanges}
        </Button>
      </div>
    </>
  );
}
