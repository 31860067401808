import React, { useState, useEffect } from "react";

// Components
import BundledEditor from "../../BundledEditor";

// API
import { TagsApi } from "../../api/api.service";
import { language, languageGr } from "../../api/lang";

// Bootstrap
import { Modal, Button, Row, Col, Tab, Form, Nav } from "react-bootstrap";

// CSS
import "../UI/Modal.css";

// FaIcons
import * as FaIcons from "react-icons/fa";

// Swal alert
import Swal from "sweetalert2";

const TagModal = (props) => {
  const [targetId, setTargetId] = useState(props.targetId);
  const [tag, setTag] = useState([]);
  const [allTags, setAllTags] = useState([]);
  const [languageChosenSite, setLanguageChosenSite] = useState("");

  useEffect(() => {
    getData(targetId);
  }, [targetId]);

  const getData = async (id) => {
    setLanguageChosenSite(sessionStorage.getItem("languageChosen"));

    const tag = await TagsApi.GetById(id);
    console.log("tag: ", tag);
    setTag(tag);
    
    const allTags = props.data;
    console.log("All tags: ", allTags);

    let arr = [];
    allTags.map((item) => {
      arr.push(item.tagId);
    });

    console.log("Array of tags IDs: ", arr);
    setAllTags(arr);

    // let newTags = [];
    // exhib.map((exhibition) => {
    //   props.languages.map((lang) => {
    //     if (exhibition.languageId === lang.languageId) {
    //       newTrans.push({ ...exhibition, lang: lang.name });
    //     }
    //   });
    // });

    // console.log("New trans: ", newTrans);
    // setExhibitions(newTrans);
  };

  const next = () => {
    let index = allTags.indexOf(targetId);
    index === allTags.length - 1
      ? setTargetId(allTags[0])
      : setTargetId(allTags[index + 1]);
    getData(targetId);
  };

  const prev = () => {
    let index = allTags.indexOf(targetId);
    index === 0
      ? setTargetId(allTags[allTags.length - 1])
      : setTargetId(allTags[index - 1]);
    getData(targetId);
  };

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" className="fw-light">
            {languageChosenSite === "EL"
              ? languageGr.global.tagWords.edit
              : language.global.tagWords.edit}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Tab.Container id="lang-left-tabs" defaultActiveKey={`#Greek`}>
              <Row>
                {/* <Col sm={3}>
                  <Nav variant="pills" className="flex-column">
                    {props.languages.map((value) => (
                      <Nav.Link href={`#${value.name}`}>{value.name}</Nav.Link>
                    ))}
                  </Nav>
                </Col> */}
                <Col sm={12}>
                  <Tab.Content>
                    {props.languages.map((lang) => (
                      <Tab.Pane eventKey={`#${lang.name}`}>
                        <Data
                          targetId={targetId}
                          languageId={lang.languageId}
                         
                          setTableChanged={props.setTableChanged}
                          tableChanged={props.tableChanged}
                          initialTitle={tag.tagName}
                        />
                      </Tab.Pane>
                    ))}
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
            <div className="text-center pt-3">
              <Button onClick={prev} className="backwards mx-1">
                <FaIcons.FaChevronLeft />
              </Button>
              <Button onClick={next} className="forward mx-1">
                <FaIcons.FaChevronRight />
              </Button>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="closeButton"
            variant="closeButton"
            onClick={props.onHide}
          >
            {languageChosenSite === "EL"
              ? languageGr.global.close
              : language.global.close}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TagModal;

function Data({
  initialTitle,
 
  languageId,

  targetId,
  userRole,
  setTableChanged,
  tableChanged
}) {
  
  const [title, setTitle] = useState(initialTitle ?? "");
  const [languageChosenSite, setLanguageChosenSite] = useState("");

  useEffect(() => {
    update();
  }, [initialTitle]);

  const update = () => {
   
    setTitle(initialTitle ?? "");
    setLanguageChosenSite(sessionStorage.getItem("languageChosen"));
  };

  const reloadTable = () => {
    setTableChanged(!tableChanged);
  };

  const updateData = async () => {
    if (userRole == "Viewer") {
      Swal.fire({
        title: languageChosenSite == "EL" ? languageGr.media.invalidAction : language.media.invalidAction,
        text: languageChosenSite == "EL" ? languageGr.global.userRole : language.global.userRole,
        icon: "warning",
        button: "OK",
      });
      return;
    }
   

    console.log(`New title: ${title}`);
    // console.log(`New description: ${desc}`);
    console.log(`Language ID: ${languageId}`);
    // console.log(`Translation ID: ${translationId}`);
    console.log(`Target ID: ${targetId}`);

    if (initialTitle !== "") {
      if (initialTitle !== title ) {
        //UpdateTranslation
        console.log("Update data");
        // translation.translations.push({
        //   translationId: parseInt(translationId),
        //   name: title,
        //   description: desc,
        // });

        const tag = {
            tagName: title
        }

        const update = await TagsApi.Update(tag, targetId);

        if (update) {
          Swal.fire({
            title: "Success",
            text: `Action was successfully done`,
            icon: "success",
          });
          reloadTable();

        }
      } else {
        console.log("The data are same. Update not needed");
      }
    //} else {
      //CreateTranslation
      console.log("Create data");

    //   translation.translations.push({
    //     id: parseInt(targetId),
    //     languageId: parseInt(languageId),
    //     name: title,
    //     description: desc,
    //   });

      //const create = await ExhibitionApi.CreateTranslation(translation);

    //   if (create) {
    //     Swal.fire({
    //       title: "Success",
    //       text: `Action was successfully done`,
    //       icon: "success",
    //     });
    //     reloadTable();
    //   }
    //}
    // console.log(translation);
    };
  }
  return (
    <>
      <div className="pb-2">
        <Form.Control
          defaultValue={initialTitle}
          value={title}
          onChange={(newT) => setTitle(newT.target.value)}
        />
      </div>
      {/* <div className="pb-2">
        <BundledEditor
          init={{
            height: 250,
            selector: "textarea",
            plugins:
              "advlist anchor autolink help image link lists searchreplace table wordcount emoticons insertdatetime code",
            toolbar:
              "undo redo blocks image link media numlist bullist insertdatetime|",
            content_style:
              "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
          }}
          //initialValue={initialDesc}
          value={desc}
          onEditorChange={(newD) => setDesc(newD)}
        />
      </div> */}
      <div>
        <Button
          className="addNewButton"
          variant="addNewButton"
          onClick={() => updateData()}
        >
          {languageChosenSite === "EN"
            ? language.global.saveChanges
            : languageGr.global.saveChanges}
        </Button>
      </div>
    </>
  );
}
