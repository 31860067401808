import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const ButtonSkeleton = () => {

   

  return (
    <div>
      
      <Skeleton className="" style={{ "height": "3vh", "width": "20vw", "border-radius": "12px"}} count={1}>
        Download
        </Skeleton>
        
    
    </div>
  );
};

export default ButtonSkeleton;
