import React, { useState, useEffect } from 'react'

//Bootstrap
import { Container, Row, Col } from 'react-bootstrap'

//Font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faLinkedin, faYoutube, faGithub, faTwitter } from "@fortawesome/free-brands-svg-icons";

//Language values
import Greek from "../Languages/Greek.json";
import English from "../Languages/English.json";

//CSS
import "./footer.css";

export function Footer() {
    const [textLanguage, setTextLanguage] = useState(Greek);

    useEffect(() => {
        setLanguage();
    }, []);

    const setLanguage = () => {
        sessionStorage.languageChosen === "EN"
            ? setTextLanguage(English)
            : setTextLanguage(Greek);
    };

    return (
        <div id="footer">
            <Container className='mb-4'>
                <Row>
                    <Col className='single-footer-widget'>
                        <div className='mb-3'>
                            <img src="https://e-xenagos.gr/images/yootheme/demo/default/logo.png" alt="xenagos-logo" />
                        </div>
                        <div className='mb-4'>
                            <a href='https://www.prismaelectronics.eu/' target="_blank" id="prisma-link">
                                <p className="fw-light text-muted mb-3 fs-5">
                                    {textLanguage.footer.copyright}
                                </p>
                            </a>
                        </div>
                        <div className='social-icons d-flex justify-content-between fs-2'>
                            <FontAwesomeIcon icon={faFacebook} />
                            <FontAwesomeIcon icon={faTwitter} />
                            <FontAwesomeIcon icon={faLinkedin} />
                            <FontAwesomeIcon icon={faYoutube} />
                            <FontAwesomeIcon icon={faGithub} />
                        </div>
                    </Col>

                    <Col className='single-footer-widget' sm={12} md={4}>
                        <h4 className='fw-light mb-3'>{textLanguage.footer.aboutTitle}</h4>
                        <p >{textLanguage.footer.aboutText}</p>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

// export default Footer
