import { useState, useEffect } from "react";
import {
  ExhibitNew,
  CategoryApi,
  SiteApi,
  MediaApi,
  ExhibitionApi,
  LanguageApi,
  ObjectApi,
  TagsApi
} from "../../api/api.service";
import { language, languageGr } from "../../api/lang";

import classes from "./Edit.module.css";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import MediaSkeleton from "./MediaSkeleton";
import InputSkeleton from "./InputSkeleton";
import { next, prev } from "./F&BButtons";
import * as FaIcons from "react-icons/fa";
import Swal from "sweetalert2";
import { Button } from "react-bootstrap";

//Resize Images
import Resizer from "react-image-file-resizer";

const animatedComponents = makeAnimated();

const Edit = ({
  targetId,
  data,
  rowEvents,
  site,
  alterTable,
  dataObject,
  languageChosenSite,
  userRole,
  tableChanged
  
}) => {
  const [idClicked, setIdClicked] = useState(targetId);
  //const [siteId, setSiteId] = useState(site.siteId);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [optionsForSel, setOptionsForSel] = useState({});
  const [categoryArray, setCategoryArray] = useState([]);
  const [optionsForSelSub, setOptionsForSelSub] = useState([]);
  const [SubcategoryArray, setSubcategoryArray] = useState([]);
  const [profileImage, setProfileImage] = useState("");
  const [updatedImage, setUpdatedImage] = useState("");
  const [serialNumber, setSerialNumber] = useState("");
  const [exhibitionChosen, setExhibitionChosen] = useState([]);
  const [exhibitionArray, setExhibitionArray] = useState([]);
  const [sumOfObjects, setSumOfObjects] = useState(data);
  const [sumOfObjectsIds, setSumOfObjectsIds] = useState([]);
  const [languageId, setLanguageId] = useState(0);
  const [tagChosen, setTagChosen] = useState([]);
  const [tagsArray, setTagsArray] = useState([]);
  //const [dataObject, setDataObject] = useState([]);
  //const [languageChosenSite, setLanguageChosenSite] = useState("");

  //const [editW, setEditW] = useState({});

  useEffect(() => {
    // if (typeof targetId === "number") {
    //   setIdClicked(targetId);
    // }

    getData();
    //updateSub();
  }, []);

  const optionsForSelectCategory = [];
  const optionsForSelectSubcategory = [];
  const optionsForExhibtions = [];
  const chosenExhibitions = [];
  const optionsForTags = [];
  const chosenTags = [];



  //console.log(sumOfObjects);

  const endLoading = () => {
    setImageLoaded(true);
  };

  let profIm;
  let profImage;
  let exhibitImage;

  const getData = async () => {
    // const site.siteId = await SiteApi.Get();
    // const siteId = site.siteId[0].siteId;
    // setSiteId(siteId);
    //console.log({ targetId, data, rowEvents, site.siteId });
    //console.log(parseInt(targetId));
    //setIdClicked(targetId);
    //setIdClicked(targetId);
    const languages = await LanguageApi.Get();
    const english = languages.find((l) => l.initials === "EN");
    setLanguageId(english.languageId);
    //setLanguageChosenSite(sessionStorage.getItem('languageChosen'));

    const data = await ExhibitNew.GetAllValuesOfObject(targetId);
    const objArr = await ExhibitNew.Get();
    const targetedObject = objArr.find(id => id.objectS_ID == targetId);
    console.log(targetedObject);

    //setDataObject(data);
    //console.log(data);
    const exhibitObjectCode = data.objectS_CODE;
    setSerialNumber(exhibitObjectCode);
    console.log(data);
    //console.log(site.siteId);
    const categories = await CategoryApi.GetCategoriesOfSite(site.siteId);
    //console.log(categories);

    const categoryByExhibit = await CategoryApi.GetById(data.categorY_ID);
    //console.log(categories);
    for (const cat of categories) {
      //console.log(cat);
      const sub = await CategoryApi.GetSubCategoriesOfSite(
        site.siteId,
        cat.categoryId
      );
      // console.log(sub);
      // if (sub.length > 0) {
      //   for (const s of sub) {
      //     optionsForSelectSubcategory.push({
      //       value: s.categoryId,
      //       label: s.name,
      //     });
      //   }
      // }

      if (typeof cat.parentId === "number") {
        // if(typeof categoryByExhibit.parentId === "number") {
        //optionsForSelectSubcategory.push({ value: cat.categoryId, label: cat.name });
        if (cat.categoryId === cat.parentId) {
        }
        //}
      } else {
        optionsForSelectCategory.push({
          value: cat.categoryId,
          label: cat.name,
        });
      }
    }
    //console.log(optionsForSelectCategory);
    setCategoryArray(optionsForSelectCategory);
    setSubcategoryArray(optionsForSelectSubcategory);
    //updateSub();
    // const exhibitName = data.name;
    

    // document.getElementById("exhibitName").textContent =
    //   `${exhibitName}`;

    //console.log(categoryByExhibit);
    const categoryObject = {
      value: categoryByExhibit.categoryId,
      label: categoryByExhibit.name,
    };

    if (categoryByExhibit.parentId != null) {
      //let subCat = await CategoryApi.GetSubCategoriesOfSite(siteId, categoryByExhibit.parentId);
      const parentCategory = await CategoryApi.GetById(
        categoryByExhibit.parentId
      );

      setOptionsForSel({
        value: parentCategory.categoryId,
        label: parentCategory.name,
      });
      setOptionsForSelSub([categoryObject]);
    } else {
      setOptionsForSelSub([]);
      setSubcategoryArray([]);
      setOptionsForSel(categoryObject);
    }

    const tags = await TagsApi.Get();
    //console.log(exhibitions);
    for (let tag of tags) {
      

        optionsForTags.push({
          value: tag.tagId,
          label: tag.tagName,
        });
    
    }
    if(targetedObject.tags.length > 0 ) {
      for(let tag of targetedObject.tags) {
        
       
        chosenTags.push({
          value: tag.tagId,
          label: tag.tagName,
        });
      }
    }
    

    setTagsArray(optionsForTags);
    //setExhibitionChosen(chosenExhibitions);
    setTagChosen(chosenTags);




    const exhibitions = await ExhibitionApi.Get();
    //console.log(exhibitions);
    for (let exhib of exhibitions) {
      let exhibitionCh = await ObjectApi.GetByExhibition(
        parseInt(exhib.exhibitionId)
      );
      let found = exhibitionCh.find((l) => l.objectId === targetId);
      if (found) {
        chosenExhibitions.push({
          value: exhib.exhibitionId,
          label: exhib.name,
        });
      } else {
        console.log(found);
        optionsForExhibtions.push({
          value: exhib.exhibitionId,
          label: exhib.name,
        });
      }
    }

    setExhibitionArray(optionsForExhibtions);
    setExhibitionChosen(chosenExhibitions);
    endLoading();

    // console.log(exhibitionChosen);
    // console.log(exhibitionArray);
    // console.log(optionsForExhibtions);
    // console.log(chosenExhibitions);
    //const SubcategoryObject = {value: data.categorY_ID, label: data.category}

    //setOptionsForSelSub([SubcategoryObject]);
    const profileImageId = data.imagE_DEF;
    // console.log(profileImageId);

    exhibitImage = await MediaApi.GetByMediaId(profileImageId);
    // console.log(exhibitImage);
    setProfileImage(exhibitImage.value);
    //setTimeout(endLoading, 100);
    const arrids = sumOfObjects.map((ob) => ob.objectS_ID);
    setSumOfObjectsIds(arrids);
  };
  //console.log(optionsForSel);

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        600,
        600,
        "JPEG",
        50,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
  });

  const fileInputEdit = async (e) => {
    //console.log(e.target.files);
    for (let i = 0; i < e.target.files.length; i++) {
      e.preventDefault();
      e.stopPropagation();
      const file = e.target.files[i];
      const img = document.getElementById("updateImage");

      // const toBase64 = (files) =>
      //   new Promise(async (resolve, reject) => {
      //     const reader = new FileReader();
      //     reader.readAsDataURL(files);
      //     //console.log(reader.result);
      //     img.src = reader.result;
      //     reader.onload = () => resolve(reader.result);
      //     reader.onloadend = function () {
      //       img.src = reader.result;
      //     };

      //     reader.onerror = (error) => reject(error);
      //   });

      //const uri = await toBase64(file);
      const uri = await resizeFile(file);


      const b64 = await uri.replace(/^data:image.+;base64,/, "");

      document.getElementById("imageUpdateString").innerText = b64;
      setUpdatedImage(b64);

      //console.log(b64);
    }
  };

  const triggerClick = () => {
    console.log(userRole);
    if (userRole == "Viewer") {
      Swal.fire({
        title: languageChosenSite == "EL" ? languageGr.media.invalidAction : language.media.invalidAction,
        text: languageChosenSite == "EL" ? languageGr.global.userRole : language.global.userRole,
        icon: "warning",
        button: "OK",
      });
    } else {
      document.getElementById("file-input-edit").click();
    }
  };

  // const uploadUpdatePhoto = () => {
  //   const image = document.getElementById("updateImage");
  //   const canvas = document.createElement("canvas"),
  //     ctx = canvas.getContext("2d");
  //   canvas.height = image.naturalHeight;
  //   canvas.width = image.naturalWidth;
  //   ctx.drawImage(image, 0, 0);
  //   const uri = canvas.toDataURL("image/png"),
  //     b64 = uri.replace(/^data:image.+;base64,/, "");
  //   document.getElementById("imageUpdateString").innerText = b64;

  // };

  const onCatChange = async (e) => {
    //console.log(e);
    //console.log(optionsForSel);
    //setSubcategoryArray([]);
    setOptionsForSel(e);
    const subb = await CategoryApi.GetSubCategoriesOfSite(site.siteId, e.value);

    console.log(subb);
    let subArr = [];
    for (let a of subb) {
      subArr.push({
        label: a.name,
        value: a.categoryId,
      });
    }
    setSubcategoryArray(subArr);
    setOptionsForSelSub(null);
  };

  const updateObject = async () => {
    //console.log("update");
    //console.log(dataObject.objectS_CODE);
    if (userRole == "Viewer") {
      Swal.fire({
        title: languageChosenSite == "EL" ? languageGr.media.invalidAction : language.media.invalidAction,
        text: languageChosenSite == "EL" ? languageGr.global.userRole : language.global.userRole,
        icon: "warning",
        button: "OK",
      });
    } else {
      if (serialNumber === "") {
        Swal.fire(
          language.media.invalidAction,
          language.new.exhibitCodeLength,
          "warning"
        );
        return;
      } else if (
        serialNumber.length == 0 ||
        parseInt(serialNumber) < 100 ||
        parseInt(serialNumber) > 100000
      ) {
        Swal.fire(
          language.media.invalidAction,
          language.new.exhibitCodeLength,
          "warning"
        );
        return;
      }

      let greekExhibits = [];
      let checkedCode = [];
      let exh = await ObjectApi.Get();
      for (let e of exh) {
        //if (e.languageId = greek.languageId) {
        //    greekExhibits.push(e);
        //}
        greekExhibits.push(e.objectS_CODE);
      }

      console.log(greekExhibits);
      for (let e of greekExhibits) {
        if (e === serialNumber && e != dataObject.objectS_CODE) {
          checkedCode.push(e);
        }
      }
      if (checkedCode.length > 0) {
        Swal.fire(
          language.media.invalidAction,
          language.new.exhibitCodeAlert,
          "warning"
        );
        return;
      }
      //setSerialNumber(`${document.getElementById('objectUpdateSerialNumber').value}`);
      // console.log(serialNumber);
      // console.log(optionsForSel);
      // console.log(exhibitImage);
      //console.log(document.getElementById("imageUpdateString").innerText);
      //console.log(optionsForSelSub);
      //console.log(exhibitionChosen);
      //uploadUpdatePhoto();
      // const toBase64 = files => new Promise(async (resolve, reject) => {
      //   const reader = new FileReader();
      //   reader.readAsDataURL(files);
      //   console.log(reader.result);
      //   img.src=reader.result;
      //   reader.onload = () => resolve(reader.result);
      //   reader.onloadend = function () {
      //     img.src = reader.result;

      //   };

      //   reader.onerror = error => reject(error);
      // });

      // const image = document.getElementById("updateImage");
      // const canvas = document.createElement("canvas"),
      //   ctx = canvas.getContext("2d");
      // canvas.height = image.naturalHeight;
      // canvas.width = image.naturalWidth;
      // ctx.drawImage(image, 0, 0);
      // const uri = canvas.toDataURL("image/png"),
      //   b64 = uri.replace(/^data:image.+;base64,/, "");
      // document.getElementById("imageUpdateString").innerText = b64;

      // const uri = await toBase64(file);

      // const b64 = await uri.replace(/^data:image.+;base64,/, '');

      // document.getElementById("imageUpdateString").innerText = b64;

      //console.log(document.getElementById("imageUpdateString").innerText);
      //console.log(profileImage);
      const image1 = document.getElementById("imageUpdateString").innerText;
      const imageAttached = await MediaApi.GetByMediaId(dataObject.imagE_DEF);

      //const image = document.getElementById("updateImage").innerText;
      //console.log(image);
      // console.log(image1);
      // console.log(profileImage);
      //   console.log(imageAttached);
      if (image1 === "data:," || image1 === "") {
        //image update cancelled

        const object = {
          ExhibitId: dataObject.objectS_ID,
          Code: serialNumber,
          Image: imageAttached.mediaId,
          Index: 0,
          CriticalRate: false,
          Latitude: dataObject.latitude,
          Longitude: dataObject.longitude,
        };
        // console.log(optionsForSel);
        // console.log(dataObject.categorY_ID);
        // console.log(optionsForSelSub);
        // console.log(dataObject.objectS_ID);
        if (optionsForSel.value != parseInt(dataObject.categorY_ID)) {
          await ObjectApi.RemoveFromCategory(dataObject.objectS_ID);
          if (optionsForSelSub != null) {
            await ObjectApi.AddToCategory(
              dataObject.objectS_ID,
              parseInt(optionsForSelSub.value)
            );
            console.log("subcategory!!!!!");
          } else {
            console.log("category!!!!!");
            await ObjectApi.AddToCategory(
              dataObject.objectS_ID,
              parseInt(optionsForSel.value)
            );
          }

          //await ObjectApi.AddToCategory(dataObject.objectS_ID, optionsForSel.value);
        }

        // console.log(exhibitionChosen);
        if (exhibitionChosen.length > 0) {
          await ObjectApi.RemoveFromExhibition(dataObject.objectS_ID);
          for (let exhib of exhibitionChosen) {
            await ObjectApi.AddToExhibition(
              dataObject.objectS_ID,
              parseInt(exhib.value)
            );
          }
        }

        if (tagChosen.length > 0) {
          for(let tag of tagsArray) {
            await ExhibitNew.RemoveTagFromExhibit(dataObject.objectS_ID, tag.value);
          } 
          for (let tagCh of tagChosen) {
            await ExhibitNew.AddTagToExhibit(
              dataObject.objectS_ID,
              parseInt(tagCh.value)
            );
          }
        }

        const updated = await ObjectApi.Update(object);
        // console.log(updated);

        // console.log(object);
      } else {
        if (imageAttached.value != image1) {
          //setProfileImage(image1);
          await MediaApi.MediaDelete(dataObject.imagE_DEF); //delete the profile image
          profIm = {
            Name: dataObject.name,
            Description: "",
            TypeId: 10,
            LanguageId: parseInt(languageId),
            Value: image1,
            ObjectValId: 0,
            RefPath: dataObject.name, //kai gia ola
            //"ObjectValId": addedObj.objectId
          };

          if (profIm.Value) {
            profImage = await MediaApi.Create(profIm);

            setUpdatedImage(profIm.Value);
          } else {
            profIm = imageAttached;
          }
        } else {
          //
          profIm = imageAttached;
        }

        // console.log(profImage);
        // console.log(serialNumber);
        const object = {
          ExhibitId: dataObject.objectS_ID,
          Code: serialNumber,
          Image:
            typeof profImage !== "undefined"
              ? profImage.mediA_ID
              : profileImage,
          Index: 0,
          CriticalRate: false,
          Latitude: dataObject.latitude,
          Longitude: dataObject.longitude,
        };
        // console.log(optionsForSel);
        // console.log(dataObject.categorY_ID);
        // console.log(optionsForSelSub);
        // console.log(dataObject.objectS_ID);
        if (optionsForSel.value != parseInt(dataObject.categorY_ID)) {
          await ObjectApi.RemoveFromCategory(dataObject.objectS_ID);
          if (optionsForSelSub != null) {
            console.log("subcategory!!!!!");

            await ObjectApi.AddToCategory(
              dataObject.objectS_ID,
              parseInt(optionsForSelSub.value)
            );
          } else {
            console.log("category!!!!!");

            await ObjectApi.AddToCategory(
              dataObject.objectS_ID,
              parseInt(optionsForSel.value)
            );
          }

          //await ObjectApi.AddToCategory(dataObject.objectS_ID, optionsForSel.value);
        }

        console.log(exhibitionChosen);
        if (exhibitionChosen.length > 0) {
          await ObjectApi.RemoveFromExhibition(dataObject.objectS_ID);
          for (let exhib of exhibitionChosen) {
            await ObjectApi.AddToExhibition(
              dataObject.objectS_ID,
              parseInt(exhib.value)
            );
          }
        }


        if (tagChosen.length > 0) {
          for(let tag of tagsArray) {
            await ExhibitNew.RemoveTagFromExhibit(dataObject.objectS_ID, tag.value);
          } 
          for (let tagCh of tagChosen) {
            await ExhibitNew.AddTagToExhibit(
              dataObject.objectS_ID,
              parseInt(tagCh.value)
            );
          }
        }

        const updated = await ObjectApi.Update(object);
        // console.log(updated);

        // console.log(object);
        //document.querySelector(".btn-close").click();
      }
      //setProfileImage(image);
      Swal.fire({
        title: "Success",
        text: `Action was successfully done`,
        icon: "success",
      });

      alterTable(!tableChanged);
    }
  };

  // const updateSub = async () => {
  //   let categoryValue = optionsForSel[0].value;
  //   let subCat = await CategoryApi.GetSubCategoriesOfSite(
  //     site.siteId,
  //     categoryValue
  //   );
  //   console.log(subCat);
  // };

  const onForward = async () => {
    setImageLoaded(false);

    let nextObject = next(parseInt(targetId), sumOfObjectsIds);
    console.log(nextObject);
    console.log(targetId);
    setIdClicked(nextObject);

    let dataNext = await ExhibitNew.GetAllValuesOfObject(nextObject);
    //setDataObject(dataNext);
    console.log(dataNext);
    let exhibitObjectCodeNext = dataNext.objectS_CODE;
    setSerialNumber(exhibitObjectCodeNext);
    let categoriesNext = await CategoryApi.GetCategoriesOfSite(site.siteId);

    let categoryByExhibitNext = await CategoryApi.GetById(dataNext.categorY_ID);

    setCategoryArray(optionsForSelectCategory);
    //setSubcategoryArray(optionsForSelectSubcategory);
    //updateSub();
    let exhibitNameNext = dataNext.name;

    document.getElementById("exhibitName").textContent = `${exhibitNameNext}`;

    console.log(categoryByExhibitNext);
    let categoryObjectNext = {
      value: categoryByExhibitNext.categoryId,
      label: categoryByExhibitNext.name,
    };

    if (categoryByExhibitNext.parentId != null) {
      //let subCat = await CategoryApi.GetSubCategoriesOfSite(siteId, categoryByExhibit.parentId);
      const parentCategory = await CategoryApi.GetById(
        categoryByExhibitNext.parentId
      );

      setOptionsForSel([
        { value: parentCategory.categoryId, label: parentCategory.name },
      ]);
      setOptionsForSelSub(categoryObjectNext);
    } else {
      setOptionsForSelSub([]);
      setSubcategoryArray([]);
      setOptionsForSel(categoryObjectNext);
    }

    let profileImageIdNext = dataNext.imagE_DEF;
    console.log(profileImageIdNext);

    let exhibitImageNext = await MediaApi.GetByMediaId(profileImageIdNext);
    setProfileImage(exhibitImageNext.value);
    //setTimeout(endLoading, 100);
    endLoading();

    //setIdClicked(nextObject);
  };

  const onSubOptionChange = (e) => {
    console.log(e);
    setOptionsForSelSub(e);
  };

  const onPrevious = async () => {
    setImageLoaded(false);

    let prevObject = prev(parseInt(targetId), sumOfObjectsIds);

    console.log(sumOfObjectsIds);
    console.log(prevObject);

    setIdClicked(prevObject);

    let dataNext = await ExhibitNew.GetAllValuesOfObject(prevObject);
    //setDataObject(dataNext);
    console.log(dataNext);
    let exhibitObjectCodePrev = dataNext.objectS_CODE;
    setSerialNumber(exhibitObjectCodePrev);
    let categoriesPrev = await CategoryApi.GetCategoriesOfSite(site.siteId);

    let categoryByExhibitPrev = await CategoryApi.GetById(dataNext.categorY_ID);

    setCategoryArray(optionsForSelectCategory);
    //setSubcategoryArray(optionsForSelectSubcategory);
    //updateSub();
    let exhibitNamePrev = dataNext.name;

    document.getElementById("exhibitName").textContent = `${exhibitNamePrev}`;

    console.log(categoryByExhibitPrev);
    let categoryObjectPrev = {
      value: categoryByExhibitPrev.categoryId,
      label: categoryByExhibitPrev.name,
    };

    if (categoryByExhibitPrev.parentId != null) {
      //let subCat = await CategoryApi.GetSubCategoriesOfSite(siteId, categoryByExhibit.parentId);
      const parentCategory = await CategoryApi.GetById(
        categoryByExhibitPrev.parentId
      );

      setOptionsForSel([
        { value: parentCategory.categoryId, label: parentCategory.name },
      ]);
      setOptionsForSelSub([categoryObjectPrev]);
    } else {
      setOptionsForSelSub([]);
      setSubcategoryArray([]);
      setOptionsForSel([categoryObjectPrev]);
    }

    let profileImageIdPrev = dataNext.imagE_DEF;
    //console.log(profileImageIdPrev);

    let exhibitImagePrev = await MediaApi.GetByMediaId(profileImageIdPrev);
    setProfileImage(exhibitImagePrev.value);
    //setTimeout(endLoading, 100);
    endLoading();
  };
  // const updateCat = () => {
  //   setOptionsForSel();
  //   updateSub();
  // }

  return (
    <div
      role="tabpanel"
      className="tab-pane active container"
      style={{ "margin-top": "3vh" }}
      id="tab_default_1"
    >
      <div className="row" id="freeze">
        <div className="col-md-5" style={{}}>
          <div id="photoPlaceHolder">
            {!imageLoaded ? (
              <MediaSkeleton />
            ) : (
              <img
                style={{borderRadius: 20}}
                id="updateImage"
                src={
                  updatedImage
                    ? "data:image/png;base64, " + updatedImage
                    : "data:image/png;base64, " + profileImage
                }
                loading="lazy"
                alt=""
              />
            )}
            <Button
              id="updatePhoto"
              style={{ "margin-top": "0.5vh", "margin-bottom": "1.5vh" }}
              className="closeButton me-2"
              variant="closeButton"
              onClick={triggerClick}
            >
              {languageChosenSite === "EL"
                ? languageGr.global.categoryWords.uploadImage
                : language.global.categoryWords.uploadImage}
            </Button>
            <input
              id="file-input-edit"
              type="file"
              name="name"
              onChange={fileInputEdit}
              style={{ display: "none" }}
            />
          </div>

          {/* <button
            className="btn btn-success addMedia"
            id="addMediaDefault"
            //style={{}}
          >
            Add Default Image
          </button> */}
        </div>
        <div className="col-md-6 offset-1" style={{ "margin-left": "4.5%", "margin-top": "-2%" }}>
          <div className="" style={{ marginTop: 15 }}>
            <label className={classes.label}>
              {languageChosenSite === "EL"
                ? languageGr.global.editWords.serialNumber
                : language.global.editWords.serialNumber}
            </label>
            {!imageLoaded ? (
              <InputSkeleton />
            ) : (
              <input
                id="objectUpdateSerialNumber"
                className="form-control"
                onChange={(e) => setSerialNumber(e.target.value)}
                value={serialNumber}
                type="number"
                min="100"
                max="100000"
              />
            )}
          </div>
          
          <br />
          <div className="sameLine">
            <label className={classes.label}>
              {languageChosenSite === "EL"
                ? languageGr.global.editWords.tags
                : language.global.editWords.tags}
            </label>
            <br />
            {!imageLoaded ? (
              <InputSkeleton />
            ) : (
              <Select
                className={classes.center}
                options={tagsArray}
                isSearchable
                onChange={setTagChosen}
                defaultValue={tagChosen}
                components={animatedComponents}
                isMulti
              />
            )}
          </div>




          <br />

          {/* <div className="sameLine">
            <label className={classes.label}>Tags:</label>
            <br />
            <div id="tagDiv">
              <select id="tagUpdate">
                <option value="all">All</option>
              </select>
            </div>
          </div>
          <br /> */}

          <div className="sameLine">
            <label className={classes.label}>
              {" "}
              {languageChosenSite === "EL"
                ? languageGr.global.categoryWords.category
                : language.global.categoryWords.category}
            </label>
            <br />
            {/* <div id="catDest">
              <select id="modalUpdateCategories">
                <option value="all">All</option>
              </select> value={optionsForSelSub}
            </div> */}
            {!imageLoaded ? (
              <InputSkeleton />
            ) : (
              <Select
                className={classes.center}
                value={optionsForSel}
                options={categoryArray}
                onChange={onCatChange}
                isSearchable
              />
            )}
          </div>
          <br />
          <div className="sameLine">
            <label id="subLabel" className={classes.label}>
              {languageChosenSite === "EL"
                ? languageGr.global.categoryWords.subCategory
                : language.global.categoryWords.subCategory}
            </label>
            <br />
            {!imageLoaded ? (
              <InputSkeleton />
            ) : (
              <Select
                className={classes.center}
                value={optionsForSelSub}
                onChange={onSubOptionChange}
                options={SubcategoryArray}
                isSearchable
              />
            )}
          </div>
          <br />
          {site.type == 2 && <div className="sameLine">
            <label className={classes.label}>
              {languageChosenSite === "EL"
                ? languageGr.global.editWords.exhibitions
                : language.global.editWords.exhibitions}
            </label>
            <br />
            {!imageLoaded ? (
              <InputSkeleton />
            ) : (
              <Select
                className={classes.center}
                options={exhibitionArray}
                isSearchable
                onChange={setExhibitionChosen}
                defaultValue={exhibitionChosen}
                components={animatedComponents}
                isMulti
              />
            )}
          </div>}

          <label style={{ visibility: "hidden" }}>Index:</label>
          <input
            style={{ visibility: "hidden" }}
            id="objectUpdateIndex"
            type="number"
            value="0"
          />
          <br />

          <input
            id="imageUpdateString"
            type="text"
            name="name"
            value=""
            style={{ display: "none" }}
          />
        </div>
      </div>
      <div className="d-flex justify-content-center modal-footer">
        <Button onClick={onPrevious} className="backwards">
          <FaIcons.FaChevronLeft />
        </Button>
        <Button
          id="updateObject"
          onClick={updateObject}
          className="tab-save-button me-2"
          variant="tab-save-button"
        >
          {languageChosenSite === "EL"
            ? languageGr.global.editWords.update
            : language.global.editWords.update}
        </Button>
        <Button onClick={onForward} className="forward">
          <FaIcons.FaChevronRight />
        </Button>
      </div>
    </div>
  );
};

export default Edit;
