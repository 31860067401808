import React, { useState, useEffect } from 'react'
import HTMLRenderer from 'react-html-renderer';
import { useParams } from "react-router-dom";
import { Link } from 'react-router-dom';

//Language values
import Greek from "../Languages/Greek.json";
import English from "../Languages/English.json";

//Bootstrap
import { Container, Row, Col, Card, Button } from 'react-bootstrap';

function Exhibitions() {
    const [textLanguage, setTextLanguage] = useState(Greek);
    const fakeArray = ['1', '2', '3', '4', '5']

    useEffect(() => {
        setLanguage();
    }, []);

    const setLanguage = () => {
        sessionStorage.languageChosen === "EN"
            ? setTextLanguage(English)
            : setTextLanguage(Greek);
    };

    return (
        <>
            <Container fluid>
                <h2 className='text-center p-4'>{textLanguage.homePage.exhibitions}</h2>
                <Row className='px-5 py-3'>
                    <Col lg={6} className='px-5 py-1'>
                        <h2>Lorem Ipsum</h2>
                        <p>
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                            Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                            It has survived not only five centuries, but also the leap into electronic typesetting,
                            remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets
                            containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                        </p>
                        <p>
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                            Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                            It has survived not only five centuries, but also the leap into electronic typesetting,
                            remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets
                            containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                        </p>
                    </Col>
                    <Col lg={6} >
                        <Row xs={1} sm={2} md={3} className="g-4">
                            {fakeArray.map((index, value) => (
                                <Col className="d-flex align-items-stretch" key={index}>
                                    <Card className="p-0 m-0">
                                        <Card.Body>
                                            <Card.Img
                                                variant='top'
                                                src="https://images.adsttc.com/media/images/627e/dec9/3e4b/318f/a100/0024/large_jpg/courtesy_of_studio_malka_architecture.jpg?1652481733"
                                            />
                                            <Card.Title>{index}</Card.Title>
                                            <Card.Subtitle className="mt-2 text-muted">
                                                {value}
                                            </Card.Subtitle>
                                            <Link to={`/front/exhibit/${value.objectS_ID}`}>
                                                <Button variant="primary mt-3">{textLanguage.buttons.seeMore}</Button>
                                            </Link>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                    </Col>
                </Row>
            </Container >
        </>

    )
}

export default Exhibitions