// Flag icons
import greekFlag from "../../images/Languages/greece-circular.png";
import englishFlag from "../../images/Languages/great-britain-circular.png";
import gernamFlag from "../../images/Languages/germany-circular.png";
import spanishFlag from "../../images/Languages/spain2-circular.png";
import italianFlag from "../../images/Languages/italy.png";
import franceFlag from "../../images/Languages/france.png";
import turkishFlag from "../../images/Languages/turkey.png";
import arabicFlag from "../../images/Languages/united-arab-emirates.png";
import chineseFlag from "../../images/Languages/china.png";

export const addFlagOnMapName = (langId) => {
  if (langId === 1) return greekFlag;
  else if (langId === 2) return englishFlag;
  else if (langId === 3) return gernamFlag;
  else if (langId === 11) return spanishFlag;
  else if (langId === 16) return franceFlag;
  else if (langId === 17) return arabicFlag;
  else if (langId === 18) return chineseFlag;
  else if (langId === 21) return turkishFlag;
  else if (langId === 23) return italianFlag;
  else return null;
};
