import { useState, useEffect } from "react";
import StepWizard from "react-step-wizard";
import Step1 from './Step1'
import Step2 from './Step2'
import Step3 from './Step3'
import Step4 from './Step4'
import Step5 from './Step5'
import WizardNav from "./WizardNav";
import { language, languageGr } from "../../api/lang";
import './addNew.css';
import manage from "../../images/manage-orange.png";
import { SiteApi } from "../../api/api.service";

import { authService } from "../../api/auth.service";



const AddNewWizard = () => {

    const [languageChosenSite, setLanguageChosenSite] = useState("");
    const [exhibitData, setExhibitData] = useState({});
    const [userRole, setUserRole] = useState("");
    const [siteIdentity, setSiteIdentity] = useState({});


    useEffect(() => {
        setLanguageChosenSite(sessionStorage.getItem('languageChosen'));
        
        fetchUser();

        document.body.className = "";
        document.body.style.overflowX = "unset";
        changeHead();
        fetchSite();

        // document.querySelector(".pageHead").innerHTML = `<h4 style='padding-top: 2%;text-align: center;color: #f7aa47;margin-left: -39vw;'><img style="height: 4vh;" class='fa fa-fw fa-file' src='${manage}' />${language.global.titles.new}</h4>`;
    }, []);

    
    const fetchUser = async () => {
        const user = await authService.getUserAsync();

        setUserRole(user.claims[0].value);
    }

    const fetchSite = async () => {

        const sites = await SiteApi.Get();

        setSiteIdentity(sites[0].type);
       
    }
 
    //const [step2, setStep2] = useState(false);


    const changeHead = () => {
        if(sessionStorage.getItem('languageChosen') === "EL") {
         
      document.querySelector(".pageHead").innerHTML =`<div style="width: 100%; height:100%; display: inline-flex; justify-content: center;"><h4 style='padding-top: 3.5vh;text-align: center;color: #f7aa47;'><img style="height: 4vh;margin-top:-1vh;" class='fa fa-fw fa-file' src='${manage}' />${languageGr.global.titles.new}</h4></div>`;
            
        } else {
        
      document.querySelector(".pageHead").innerHTML =`<div style="width: 100%; height:100%; display: inline-flex; justify-content: center;"><h4 style='padding-top: 3.5vh;text-align: center;color: #f7aa47;'><img style="height: 4vh;margin-top:-1vh;" class='fa fa-fw fa-file' src='${manage}' />${language.global.titles.new}</h4></div>`;
            
        }
    }
    

    const onStepChange = () => {
        console.log("step");
    }

    const setTheExhibit = (val) => {
        console.log("The value is ", val)
        setExhibitData(val);

    }

  return (
    <div className="container" style={{"max-width": "1200px"}}>
        <div className="jumbotron" style={{  "margin-top": "3rem", 
    "background-color": "#1c432526",
    "border-radius": "12px", "box-shadow": "1px 1px 17px 1px #0000004d" }}>
            
                <div className="col-12" style={{ "min-height": "400px" }}>
                    <div className="container">
                        <StepWizard
                            onStepChange={onStepChange}
                            isHashEnabled
                            nav={<WizardNav />}
                        >
                        <Step1 hashKey={'FirstStep'} userRole={userRole} setExhibitData={setTheExhibit} siteIdentity={siteIdentity} languageChosenSite={languageChosenSite} />
                        {exhibitData != null && <Step2 userRole={userRole} languageChosenSite={languageChosenSite} exhibit={exhibitData}/>}
                        <Step3 exhibit={exhibitData} userRole={userRole} languageChosenSite={languageChosenSite} />
                        <Step4 exhibit={exhibitData} userRole={userRole} languageChosenSite={languageChosenSite} siteIdentity={siteIdentity} />
                        <Step5 exhibit={exhibitData} userRole={userRole} languageChosenSite={languageChosenSite} siteIdentity={siteIdentity} />         
                        </StepWizard>
                    </div>
                </div>
            
        </div>  
    </div>  
  );
};

export default AddNewWizard;
