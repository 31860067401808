import HttpService from './http.service.js';
const authUrl = "api/v1/auth/user/";
const usersUrl = "api/v1/users/";
//const authUrl = "http://localhost:6005/api/v1/auth/user";
const url = 'http://localhost:49998/';
/*const http = new HttpService(*//*'http://localhost:6005/'*//*);*/



let authService = (function () {
    const apiCookieName = 'PrismaSense.Api.Identity';
    let refreshPending = false;
    let refreshing;

    async function refreshTokenAsync(token) {
        refreshPending = true;
        const data = {
            refreshToken: token.refreshToken,
            accessToken: token.accessToken,
            tokenType: token.tokenType
        }
        console.log(token.refreshToken)
        
        const refreshRequest = {
            client_id: '75e08ccfbc94486784e15990d4868fae', 
            client_secret: 'UcnsOEpdt9SSWfDWHTvr',
            refresh_token: token.refreshToken,
            grant_type: 'refresh_token'
        }
        let formBody = [];
      for (let item in refreshRequest){
        let key = encodeURIComponent(item)
        let value = encodeURIComponent(refreshRequest[item])
        formBody.push(key + "=" + value)
      }
      formBody = formBody.join("&");
        const response = await fetch("https://api.dei.prismasense.com/auth/connect/token", {
            method: "POST",
            body: formBody,
            headers:  { "content-type": "application/x-www-form-urlencoded" },
        });

        if (!response.ok) {
            logOut();
        }

        const newToken = await response.json();
        token = JSON.parse(newToken);
        saveToken(token);
        refreshPending = false;
        return token;
    }

    // Save the tokens to session storage
    function saveToken(authResult) {
        try {
            const profile = authResult.user;
            delete authResult.profile;
            if (profile) {
                saveUser(profile);
            }
            
            const date = new Date();
            // Set the expiration date to half of token life. The getTokenAsync() function handles refresh flow easier.
            // The policy of authentication is: the user will be logged out if he stays inactive for 1 hour else the maximum age of the token is 8 hours.
            date.setTime(date.getTime() + (1000 * (authResult.expiresIn)));

            authResult.expiresInSeconds = authResult.expiresIn;
            authResult.expiresIn = date.getTime();
            // if (!navigator.cookieEnabled) {
            //     saveTokenCookie(authResult);
            // } else {
                sessionStorage.setItem('tokens', JSON.stringify(authResult));
            // }
        } catch (e) {
            return {
                message: e.message
            }
        }
    }

    function saveTokenCookie(token) {
        let tokenStr;
        if (typeof (token) !== 'string') {
            tokenStr = JSON.stringify(token);
        }

        setCookie(apiCookieName, tokenStr, token.expiresInSeconds / 60);
    }

    function getTokenCookie() {
        const token = getCookie(apiCookieName);
        //console.log('getCookie', token)
        if (token) {
            return JSON.parse(token);
        }

        return null;
    }

    function deleteTokenCookie() {
        deleteCookie(apiCookieName);
    }

    // Get the tokens from session storage
    async function getTokenAsync() {
        try {
            let cookieToken = getTokenCookie();

            if (cookieToken === null) {
                cookieToken = JSON.parse(sessionStorage.getItem("tokens"));
            }
            const invalidTime = isTokenExpired();
            if (invalidTime) {
                console.log("[Refreshing Session]")
                if (!refreshPending) {
                    refreshing = refreshTokenAsync(cookieToken);
                    await refreshing;
                }
                
                const res = await refreshTokenAsync(cookieToken);
                console.log('res', res);
                return res;
            } else {
                return cookieToken;
            }
        } catch (e) {
            console.log('[System Security] Error:', e.message);
            logOut();
            return null;
        }
    }

    function isTokenExpired() {
        try {
            const validUntil = parseInt(localStorage.getItem("valid_until"));
            return Date.now() >= validUntil;
        } catch(e) {
            return true;
        }
    }

    function setTokenExpired(expiration) {
        localStorage.setItem('valid_until', expiration);
    }

    function logOut() {
        // alert("");
        // sessionStorage.setItem('vessel',null);
         clearToken();
        // window.location.href = "http://localhost:3000/";
    }

    // Save the user to session storage
    function saveUser(user) {
        try {
            user.domain.application = user.domain.application === "deh" ? "energy" : user.domain.application
            sessionStorage.setItem('user', JSON.stringify(user));
        } catch (e) {
            return {
                error: true,
                message: e.message
            }
        }
    }

    // Create cookie
    function setCookie(name, value, expiresInMinutes = 60) {
        const date = new Date();
        const expiration = date.getTime() + (1000 * 60 * (expiresInMinutes / 2));
        localStorage.setItem("valid_until", expiration);
        date.setTime(expiration);
        value = btoa(value);
        const expires = `expires=${date.toUTCString()}`;
        document.cookie = `${name}=${value};${expires};path=/`;
    }

    function deleteCookie(name) {
        const date = new Date();
        date.setTime(date.getTime() - (1000 * 60));
        const expires = `expires=${date.toUTCString()}`;
        document.cookie = `${name}=;${expires};path=/`;
    }

    //Read cookie
    function getCookie(name) {
        const cookie = `${name}=`;
        const decodedCookie = decodeURIComponent(document.cookie);
        const storedCookies = decodedCookie.split(';');

        for (let i = 0; i < storedCookies.length; i++) {
            let character = storedCookies[i];
            while (character.charAt(0) === ' ') {
                character = character.substring(1);
            }

            if (character.indexOf(cookie) == 0) {
                const value = character.substring(cookie.length, character.length);
                return atob(value);
            }
        }

        return null;
    }

    // Get the user from session storage
    async function getUserAsync() {
        try {
            
            let user = sessionStorage.getItem("user");
            if (user === null) {
                console.log("Fetching user")
                const token = await getTokenAsync();
                const data = {
                    accessToken: token.accessToken,
                    tokenType: token.tokenType
                }

                const response = await fetch("https://api.dei.prismasense.com/api/v1/users/profile", {
                    method: "GET",
                    headers: {
                        "Authorization": `${token.tokenType} ${token.accessToken}`,
                        "Content-Type": "application/json"
                    }
                });

                if (!response.ok) {
                    logOut();
                }

                const json = await response.json();
                sessionStorage.setItem("user", JSON.stringify(json))
            }
            user = sessionStorage.getItem("user");
            return JSON.parse(user);
        } catch (e) {
            return null;
        }
    }

    function clearToken() {

        sessionStorage.removeItem('user');
        sessionStorage.removeItem('tokens');
        localStorage.clear();
        deleteTokenCookie();
    }

    return {
        /*        signIn: signIn,*/
        refreshTokenAsync: refreshTokenAsync,
        /*        firstSignInAsync: firstSignIn,*/
        saveToken: saveToken,
        getTokenAsync: getTokenAsync,
        clearToken: clearToken,
        saveUser: saveUser,
        getUserAsync: getUserAsync,
        logOut: logOut
    }
})();

export { authService };