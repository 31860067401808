import React from 'react'
import HTMLRenderer from 'react-html-renderer';

//Bootstrap
import { Modal, Button } from 'react-bootstrap/';

import "./events.css"

function Events(props) {
    return (
        <div>
            <Modal
                size="lg"
                show={props.showModal}
                onHide={props.handleModal}
                backdrop="static"
                centered
                className='modal-dialog-scrollable'
            >
                <Modal.Header>
                    <Modal.Title>{props.modalData.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <HTMLRenderer html={props.modalData.content} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={props.handleModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default Events