import React from "react";
import england from "../../images/Sidebar/great-britain-circular.png";
import greece from "../../images/Sidebar/greece-circular.png";
import HttpService from "../../api/http.service"
//import {Get} from "../../api/http.service"
 
const LanguageList = () => {

  const onSetClickEngland = () => {
    console.log("clicked en");
    document.getElementById("imageSelected").src = england;
    //console.log(HttpService);
    sessionStorage.setItem("languageChosen", "EN");
    window.location.reload(false);
    //window.location = window.location
    //document.getElementById("imageSelected").click();
    //Get.requestSettings.headers.x-Custom-Lang
  }

  const onSetClickGreece = () => {
    console.log("clicked gr");
    document.getElementById("imageSelected").src = greece;
    sessionStorage.setItem("languageChosen", "EL");
    window.location.reload(false);
    //window.location = window.location



  }

  return (
    <>
      <ul className="dropdown-lang">
        <li role="presentation" id="enPath" onClick={onSetClickEngland}>
          <p role="menuitem">
            <img src={england} style={{ marginRight: 12 }}/>
            English
          </p>
        </li>
        <li role="presentation" id="elPath" onClick={onSetClickGreece}>
          <p role="menuitem">
            <img src={greece} style={{ marginRight: 12 }} />
            Greek
          </p>
        </li>
      </ul>
    </>
  );
};

export default LanguageList;
