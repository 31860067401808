import React, { useState, useEffect } from "react";

// API
import { SiteApi } from "../../api/api.service";

// TinyMCE
import BundledEditor from "../../BundledEditor";

import { language, languageGr } from "../../api/lang";

// Swal alert
import Swal from "sweetalert2";

// Bootstrap
import { Button, Modal, Row, Col, Tab, Nav, Form } from "react-bootstrap";

// FaIcons
import * as FaIcons from "react-icons/fa";

const ModalSite = (props) => {
  const [siteManagementTrans, setSiteManagementTrans] = useState([]);
  const [allTrans, setAllTrans] = useState([]);
  const [targetId, setTargetId] = useState(props.targetId);
  const [languages, setLanguages] = useState(props.languages);
  const [languageChosenSite, setLanguageChosenSite] = useState("");
  //const [tableChanged, setTableChanged] = useState(false);



  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setLanguageChosenSite(sessionStorage.getItem("languageChosen"));

    const allTrans = await SiteApi.Get();

    let arr = [];
    allTrans.map((item) => {
      arr.push(item.siteId);
    });

    setAllTrans(arr);
    let newTrans = [];
    const siteManageTranslation = await SiteApi.GetTranslation(props.targetId);
    console.log("siteManageTranslation");
    console.log(siteManageTranslation);

    siteManageTranslation.map((site) => {
      languages.map((lang) => {
        if (site.languageId === lang.languageId) {
          newTrans.push({ ...site, lang: lang.name });
        }
      });
    });

    console.log("newTrans");
    console.log(newTrans);
    setSiteManagementTrans(newTrans);
  };

  const next = () => {
    let index = allTrans.indexOf(targetId);
    index === allTrans.length - 1
      ? setTargetId(allTrans[0])
      : setTargetId(allTrans[index + 1]);
    getData(targetId);
  };

  const prev = () => {
    let index = allTrans.indexOf(targetId);
    index === 0
      ? setTargetId(allTrans[allTrans.length - 1])
      : setTargetId(allTrans[index - 1]);
    getData(targetId);
  };

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="fw-light">Edit</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Tab.Container id="lang-left-tabs" defaultActiveKey={`#Greek`}>
            <Row>
              <Col sm={3}>
                <Nav variant="pills" className="flex-column">
                  {languages.map((value) => (
                    <Nav.Link href={`#${value.name}`}>{value.name}</Nav.Link>
                  ))}
                </Nav>
              </Col>
              <Col sm={9}>
                <Tab.Content>
                  {languages.map((lang) => (
                    <Tab.Pane eventKey={`#${lang.name}`}>
                      <Data
                        setTableChanged={props.setTableChanged}
                        tableChanged={props.tableChanged}
                        languageId={lang.languageId}
                        translationId={siteManagementTrans
                          .map((siteManage) =>
                            siteManage.lang === lang.name
                              ? siteManage.translationId
                              : null
                          )
                          .join("")}
                        initialDesc={siteManagementTrans
                          .map((siteManage) =>
                            siteManage.lang === lang.name
                              ? siteManage.description
                              : null
                          )
                          .join("")}
                        initialTitle={siteManagementTrans
                          .map((siteManage) =>
                            siteManage.lang === lang.name
                              ? siteManage.name
                              : null
                          )
                          .join("")}
                      />
                    </Tab.Pane>
                  ))}
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
          <div className="text-center pt-3">
            <Button onClick={prev} className="backwards mx-1">
              <FaIcons.FaChevronLeft />
            </Button>

            <Button onClick={next} className="forward mx-1">
              <FaIcons.FaChevronRight />
            </Button>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="closeButton"
            variant="closeButton"
            onClick={props.onHide}
          >
            {languageChosenSite === "EN"
              ? language.global.close
              : languageGr.global.close}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalSite;

function Data({
  initialTitle,
  initialDesc,
  languageId,
  translationId,
  userRole,
  tableChanged,
  setTableChanged
}) {
  const [desc, setDesc] = useState(initialDesc ?? "");
  const [title, setTitle] = useState(initialTitle ?? "");
  const [languageChosenSite, setLanguageChosenSite] = useState("");


  useEffect(() => {
    update();
  }, [initialTitle, initialDesc]);

  const update = () => {
    setDesc(initialDesc ?? "");
    setTitle(initialTitle ?? "");
    setLanguageChosenSite(sessionStorage.getItem("languageChosen"));
  };

  const updateData = async () => {
    if (userRole == "Viewer") {
      Swal.fire({
        title: languageChosenSite == "EL" ? languageGr.media.invalidAction : language.media.invalidAction,
        text: languageChosenSite == "EL" ? languageGr.global.userRole : language.global.userRole,
        icon: "warning",
        button: "OK",
      });
      return;
    }
    let translation = {
      translations: [],
    };

    console.log(`New title: ${title}`);
    console.log(`New description: ${desc}`);
    console.log(`Language ID: ${languageId}`);
    console.log(`Translation ID: ${translationId}`);
    console.log("...");

    if (initialTitle || initialDesc !== "") {
      if (initialTitle !== title || initialDesc !== desc) {
        //UpdateTranslation
        console.log("Update data");
        translation.translations.push({
          translationId: translationId,
          name: title,
          description: desc,
        });

        const update = await SiteApi.Update(translation);
        console.log(update);
        if (update) {
          Swal.fire({
            title: "Success",
            text: `Action was successfully done`,
            icon: "success",
          });
          setTableChanged(!tableChanged)

        }
      } else {
        console.log("The data are same. Update not needed");
      }
    } else {
      //CreateTranslation
      console.log("Create data");
      translation.translations.push({
        languageId: languageId,
        name: title,
        description: desc,
      });

      const create = await SiteApi.CreateTranslation(translation);
      console.log(create);
      if (create) {
        Swal.fire({
          title: "Success",
          text: `Action was successfully done`,
          icon: "success",
        });
        setTableChanged(!tableChanged)

      }
    }
  };

  return (
    <>
      <div className="pb-2">
        <Form.Control
          defaultValue={initialTitle}
          value={title}
          onChange={(newT) => setTitle(newT.target.value)}
        />
      </div>
      <div className="pb-2">
        <BundledEditor
          init={{
            height: 400,
            selector: "textarea",
            plugins:
              "advlist anchor autolink help image link lists searchreplace table wordcount emoticons insertdatetime code image preview media",
            toolbar:
              "undo redo blocks image link media numlist bullist insertdatetime|",
            content_style:
              "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
          }}
          initialValue={initialDesc}
          value={desc}
          onEditorChange={(newD) => setDesc(newD)}
        />
      </div>
      <div>
        <Button
          className="addNewButton"
          variant="addNewButton"
          onClick={() => updateData()}
        >
          {languageChosenSite === "EN"
            ? language.global.saveChanges
            : languageGr.global.saveChanges}
        </Button>
      </div>
    </>
  );
}
