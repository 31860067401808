import { useState, useEffect, Fragment } from "react";

// API
import {
  FieldApi,
  LanguageApi,
  SiteApi,
  CategoryApi,
  MediaApi
} from "../../api/api.service";
import { language, languageGr } from "../../api/lang";

// Bootstrap
import { Modal, Button, Form } from "react-bootstrap";
import { textFilter } from "react-bootstrap-table2-filter";

// React Select
import Select from "react-select";

//Authservice
import { authService } from "../../api/auth.service";

// Components
import CardSkeleton from "../UI/CardSkeleton";
import TableGeneral from "../UI/TableGeneral";
import BundledEditor from "../../BundledEditor";
import FieldModal from "../UI/FieldModal";

// Icons
import * as FaIcons from "react-icons/fa";
import manage from "../../images/manage-orange.png";
import makeAnimated from "react-select/animated";

// Swal Alert
import Swal from "sweetalert2";

// CSS
import "./Presentation.css";

const Field = () => {
  const [data, setData] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [checkedList, setCheckedList] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [translations, setTranslations] = useState([]);
  const [categoryState, setCategoryState] = useState([]);
  const [categoriesChosen, setCategoriesChosen] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [idClicked, setIdClicked] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const animatedComponents = makeAnimated();
  const [sites, setSites] = useState([]);
  const [trans, setTrans] = useState([]);
  const [addBox, setAddBox] = useState(false);
  const [categories, setCategories] = useState([]);
  const [languageChosenSite, setLanguageChosenSite] = useState("");
  const [openAddNewModal, setOpenAddNewModal] = useState(false);
  const [fieldDesc, setFieldDesc] = useState("");
  const [userRole, setUserRole] = useState("");
  const [tableChanged, setTableChanged] = useState(false);

  const optionsForCategories = [];

  useEffect(() => {
    document.body.className = "";
    getData();
  }, [tableChanged]);

  const getData = async () => {
    const FieldArray = await FieldApi.Get();
    console.log(FieldArray);
    const languagesArray = await LanguageApi.Get();
    const categoriesOfCms = await CategoryApi.Get();
    setLanguages(languagesArray);
    setData(FieldArray);
    const sitesOfCms = await SiteApi.Get();
    setSites(sitesOfCms);
    setCategories(categoriesOfCms);
    setLanguageChosenSite(sessionStorage.getItem("languageChosen"));

    changeHead();
    fetchUser();
    console.log(categoriesOfCms);
    for (let cat of categoriesOfCms) {
      optionsForCategories.push({
        value: cat.categoryId,
        label: cat.name,
      });
    }
    console.log(optionsForCategories);
    setCategoryState(optionsForCategories);
    setLoading(false);
    console.log(categoryState);
  };

  const fetchUser = async () => {
    const user = await authService.getUserAsync();

    setUserRole(user.claims[0].value);
  };

  const changeHead = () => {
    if (sessionStorage.getItem("languageChosen") === "EL") {
      document.querySelector(".pageHead").innerHTML =`<div style="width: 100%; height:100%; display: inline-flex; justify-content: center;"><h4 style='padding-top: 3.5vh;text-align: center;color: #f7aa47;'><img style="height: 4vh;margin-top:-1vh;" class='fa fa-fw fa-file' src='${manage}' />${languageGr.global.titles.field}</h4></div>`;
    } else {
      document.querySelector(".pageHead").innerHTML =`<div style="width: 100%; height:100%; display: inline-flex; justify-content: center;"><h4 style='padding-top: 3.5vh;text-align: center;color: #f7aa47;'><img style="height: 4vh;margin-top:-1vh;" class='fa fa-fw fa-file' src='${manage}' />${language.global.titles.field}</h4></div>`;
    }
  };

  const editClick = async (row) => {
    setModalShow(true);
    setIdClicked(row.id);

    console.log(modalShow);
    console.log(translations);
  };

  const edit = (cell, row, rowIndex, formatExtraData) => {
    return (
      <FaIcons.FaRegEdit
        id={row.presentationId}
        onClick={() => editClick(row)}
        className="editIcon"
        Edit
      />
    );
  };

  function onRowSelect(row, isSelected, e) {
    if (!isSelected) {
      console.log(e);
      console.log(data[e]);
      setCheckedList((prevState) => [...prevState, data[e].objectS_ID]);
    }
    let rowStr = "";
    for (const prop in row) {
      rowStr += prop + ': "' + row[prop] + '"';
    }
    console.log(checkedList);
  }

  async function addField() {
    if (userRole == "Viewer") {
      Swal.fire({
        title: languageChosenSite == "EL" ? languageGr.media.invalidAction : language.media.invalidAction,
        text: languageChosenSite == "EL" ? languageGr.global.userRole : language.global.userRole,
        icon: "warning",
        button: "OK",
      });
      return;
    }

    console.log("add");
    const form = document.getElementById("addFieldForm");
    console.log(form);
    if (form.checkValidity() === false) {
      form.classList.add("was-validated");
    } else {
      const english = languages.find((l) => l.initials === "EN");
      if (!english) {
        alert("Add English Language First");
      } else {
        let name = document.getElementById("fieldName").value;
        name = name.trim();
        if (name === "") {
          Swal.fire({
            title: "Something went wrong",
            text: `Please enter a title`,
            icon: "warning",
          });
          return;
        }
        console.log(name);
        let description = fieldDesc;
        description = description.trim();
        console.log(description);
        const languageId = english.languageId;

        //photo upload code

        uploadPhoto();
        let image = document.getElementById("imageUpdateStringAdd").innerText;

        const media = {
          Name: name,
          Description: "",
          TypeId: 10,
          LanguageId: parseInt(languageId),
          Value: image,
          ObjectValId: 0,
          RefPath: name,
        };
        
        const mediaCreated = await MediaApi.Create(media);
        console.log(mediaCreated);
        // if (mediaCreated) {
        //   console.log("done");

        //   fieldCreated = {
        //     name: name,
        //     description: description,
        //     // index: 1,
        //     // image_def: mediaCreated.mediA_ID ? mediaCreated.mediA_ID : 1,
        //     // parentId: isSubCategory,
        //     // mobile: true,
        //     translations: [],
        //   };
        // } else {
        //   fieldCreated = {
        //     name: name,
        //     description: description,
        //     // index: 1,
        //     // image_def: 1,
        //     // parentId: isSubCategory,
        //     // mobile: true,
        //     translations: [],
        //   };
        // }

        let field = {
          Name: name,
          Description: description,
          translations: [],
        };

        
                
                


        field.translations.push({
          LanguageId: parseInt(languageId),
          Name: name,
          Description: description,
        });
        console.log(field);
        const insertedField = await FieldApi.Create(field);
        
        if (mediaCreated) {
          await FieldApi.AddToMediaToField(mediaCreated.mediA_ID, insertedField.fieldId)

        } else {
            await FieldApi.AddToMediaToField(1, insertedField.fieldId)

        }
        console.log(insertedField);
        console.log(insertedField);

        for (var i = 0; i < categoriesChosen.length; i++) {
          if (categoriesChosen.length > 0) {
            console.log(categoriesChosen);
            await FieldApi.AddToCategory(
              categoriesChosen[i].value,
              insertedField.fieldId
            );
          }
        }
        form.reset();
        setTableChanged(!tableChanged);
        setOpenAddNewModal(false);
        //getData();
      }
    }

    console.log(categoriesChosen);
  }

  const deleteClick =  (row) => {
    if (userRole == "Viewer") {
      Swal.fire({
        title: languageChosenSite == "EL" ? languageGr.media.invalidAction : language.media.invalidAction,
        text: languageChosenSite == "EL" ? languageGr.global.userRole : language.global.userRole,
        icon: "warning",
        button: "OK",
      });
      return;
    }

    let tar = row.fieldId;
    setIdClicked(tar);
    setDeleteId(tar);
    //let tarObj = await FieldApi.GetById(tar);
    let tarName = row.name;

    Swal.fire({
      title: "Are you sure?",
      html: `You are about to delete the <strong>${tarName}</strong> field`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const deleted = await FieldApi.FieldDelete(parseInt(tar));
        setTableChanged(!tableChanged);
      }
    });
  };

  function uploadPhoto() {
    let image = document.getElementById("usingImage");
    let canvas = document.createElement("canvas"),
      ctx = canvas.getContext("2d");
    canvas.height = image.naturalHeight;
    canvas.width = image.naturalWidth;
    ctx.drawImage(image, 0, 0);
    let uri = canvas.toDataURL("image/png"),
      b64 = uri.replace(/^data:image.+;base64,/, "");
    document.getElementById("imageUpdateStringAdd").innerText = b64;
  }

  const onUploadClick = (e) => {
    console.log("cl;ickcick");
    if (userRole == "Viewer") {
      Swal.fire({
        title: language.media.invalidAction,
        text: language.global.userRole,
        icon: "warning",
        button: "OK",
      });
      return;
    }
    e.preventDefault();

    document.getElementById("file-input").click();
  };

  const del = (cell, row, rowIndex, formatExtraData) => {
    return (
      <FaIcons.FaRegTrashAlt
        id={row.presentationId}
        onClick={() => deleteClick(row)}
        className="deleteIcon"
        Delete
      />
    );
  };

  const selectRowProp = {
    mode: "checkbox",
    hideSelectAll: true,
    onSelect: onRowSelect,
    onSelectAll: onSelectAll,
  };

  const onFileInputChange = (e) => {
    console.log("blabla");

    for (let i = 0; i < e.target.files.length; i++) {
      let file = e.target.files[i];

      if (file.size > 1000000) {
        alert(
          `Media with name ${file.name} is not loaded. Media size exceeds the size limit. Please load images with maximum size of 1Mb`
        );
        continue;
      } else {
        let img = document.getElementById("usingImage");
        let reader = new FileReader();
        console.log(reader);
        reader.onloadend = function () {
          img.src = reader.result;
        };
        reader.readAsDataURL(file);
      }
    }
  };

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      console.log(e);
      console.log({ row, rowIndex });
      setIdClicked(row.fieldId);
      setDeleteId(row.fieldId);
    },
  };

  const toggleModal = () => {
    setModalShow(false);
  };

  function onSelectAll(isSelected, data, e) {
    console.log(e);
  }

  const descriptionFormat = (data) => {
    return data.replace(/<[^>]+>/g, "").substring(0, 50) + " ....";
  };

  const fieldHeaders = [
    // {
    //   dataField: "fieldId",
    //   text: "Id",
    //   sort: true,
    // },
    {
      dataField: "name",
      text: languageChosenSite === "EL"
      ? languageGr.table.title
      : language.table.title,
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: "description",
      text: languageChosenSite === "EL"
      ? languageGr.table.description
      : language.table.description,
      sort: true,
      formatter: descriptionFormat,
    },

    {
      dataField: "edit",
      text: languageChosenSite === "EL"
      ? languageGr.table.edit
      : language.table.edit,
      formatter: edit,
    },
    {
      dataField: "delete",
      text: languageChosenSite === "EL"
      ? languageGr.table.delete
      : language.table.delete,
      formatter: del,
    },
  ];

  return (
    <Fragment>
      {isLoading ? (
        <div className="col-10 offset-1">
          <CardSkeleton />
        </div>
      ) : (
        <>
          {modalShow && (
            <FieldModal
              data={data}
              onHide={toggleModal}
              show={modalShow}
              targetId={idClicked}
              languages={languages}
              translations={translations}
              rowEvents={rowEvents}
              categories={categories}
              categoryState={categoryState}
              userRole={userRole}
              tableChanged={tableChanged}
              setTableChanged={setTableChanged}
              languageChosenSite={languageChosenSite}
            ></FieldModal>
          )}
          <TableGeneral
            data={data}
            columns={fieldHeaders}
            rowEvents={rowEvents}
            //selectRow={selectRowProp}
          />
          <Button
            className="addNewButton"
            variant="addNewButton"
            onClick={() => setOpenAddNewModal(true)}
          >
            {languageChosenSite === "EL"
              ? languageGr.global.word.addNew
              : language.global.word.addNew}
          </Button>
          <Modal
            show={openAddNewModal}
            onHide={() => setOpenAddNewModal(false)}
            size="lg"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>
                {languageChosenSite === "EL"
                  ? languageGr.global.fieldWords.addPres
                  : language.global.fieldWords.addPres}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form id="addFieldForm">
                <div className="text-center">
                  <img
                    id="usingImage"
                    //onHover={hoverButtons}
                    loading="lazy"
                    style={{ "max-height": "200px" }}
                    className="my-1 rounded"
                  />
                  <br />
                  <Button
                    variant="success"
                    id="selectPhoto"
                    className="addNewButton"
                    onClick={onUploadClick}
                  >
                    {languageChosenSite === "EL"
                      ? languageGr.global.categoryWords.uploadImage
                      : language.global.categoryWords.uploadImage}
                  </Button>
                  <input
                    id="file-input"
                    type="file"
                    onChange={onFileInputChange}
                    name="name"
                    style={{ display: "none" }}
                  />
                </div>
                <Form.Group className="mb-3" controlId="">
                  <Form.Label>
                    {languageChosenSite === "EL"
                      ? languageGr.global.transName
                      : language.global.transName}
                  </Form.Label>
                  <Form.Control
                    id="fieldName"
                    type="text"
                    placeholder={
                      languageChosenSite === "EL"
                        ? languageGr.global.enterTitle
                        : language.global.enterTitle
                    }
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="">
                  <Form.Label>
                    {languageChosenSite === "EL"
                      ? languageGr.global.transDesc
                      : language.global.transDesc}
                  </Form.Label>
                  {/* <Form.Control
                    id="fieldDesc"
                    as="textarea"
                    rows={3}
                    placeholder="Enter description..."
                  /> */}
                  <BundledEditor
                    init={{
                      height: 400,
                      selector: "textarea",
                      plugins:
                        "advlist anchor autolink help image link lists searchreplace table wordcount emoticons insertdatetime code",
                      toolbar:
                        "undo redo blocks image link media numlist bullist insertdatetime|",
                      content_style:
                        "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                    }}
                    onEditorChange={(newD) => setFieldDesc(newD)}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="">
                  <Form.Label>
                    {languageChosenSite === "EL"
                      ? languageGr.global.categoryWords.category
                      : language.global.categoryWords.category}
                  </Form.Label>
                  <Select
                    options={categoryState}
                    isSearchable
                    onChange={setCategoriesChosen}
                    defaultValue={categoriesChosen}
                    components={animatedComponents}
                    autosize={true}
                    isMulti
                  />
                </Form.Group>
              </Form>
              <input
                  id="imageUpdateStringAdd"
                  type="text"
                  name="name"
                  style={{ display: "none" }}
                />
            </Modal.Body>
            <Modal.Footer>
              <Button
                className="addNewButton"
                variant="addNewButton"
                id="addFieldButton"
                onClick={addField}
              >
                {languageChosenSite === "EL"
                  ? languageGr.global.word.addNew
                  : language.global.word.addNew}
              </Button>
              <Button
                className="closeButton"
                variant="closeButton"
                onClick={() => setOpenAddNewModal(false)}
              >
                {languageChosenSite === "EL"
                  ? languageGr.global.close
                  : language.global.close}
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      )}
    </Fragment>
  );
};

export default Field;
