import React, { useState, useEffect, Suspense } from "react";

// Components
import Banner from "../Banner/Banner";
import SelectExhibits from "../Select/Select";
import CategoryPage from "../Category/Category";
import {
  ExhibitsCard,
  EventsCard,
  CategoriesCard,
  ExhibitionsCard,
} from "../Cards/Cards";

//Api
import {
  ExhibitNew,
  MediaApi,
  CategoryApi,
  ExhibitionApi,
  PostsApi,
  SiteApi,
} from "../../api/api.service";

//Language values
import Greek from "../Languages/Greek.json";
import English from "../Languages/English.json";

//FontAwesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMuseum } from "@fortawesome/free-solid-svg-icons";

//Bootstrap
import { Container, Row, Col } from "react-bootstrap";

//CSS
import "./Home.css";

export function Home() {
  const [exhibits, setΕxhibits] = useState([]);
  const [filteredExhibits, setFilteredExhibits] = useState([]);
  const [events, setEvents] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [exhibitions, setExhibitions] = useState([]);
  const [categoryImageValue, setCategoryImageValue] = useState([]);
  const [textLanguage, setTextLanguage] = useState(Greek);
  const [selectedOption, setSelectedOption] = useState([]);
  const uniqueKey = [];
  const uniqueExhibits = [];

  useEffect(() => {
    getData();
    setLanguage();
  }, []);
  
  const getData = async () => {
    const exhibitsData = await ExhibitNew.Get();
    const exhibitions = await ExhibitionApi.Get();
    const categories = await CategoryApi.Get();
    const events = await PostsApi.Get();
    const siteApi = await SiteApi.Get();

    setCategories(categories);
    setExhibitions(exhibitions);
    setEvents(events);

    for (let i in exhibitsData) {
      for (let j in uniqueExhibits) {
        if (uniqueExhibits[j].objectS_ID === exhibitsData[i].objectS_ID) {
          uniqueExhibits[j].category += " - " + exhibitsData[i].category;
        }
      }

      if (!uniqueKey.includes(exhibitsData[i].objectS_ID)) {
        uniqueExhibits.push(exhibitsData[i]);
        uniqueKey.push(exhibitsData[i].objectS_ID);
      }
    }
    setFilteredExhibits(uniqueExhibits);

    for (let element of categories) {
      const subCategories = await CategoryApi.GetSubCategoriesOfSite(
        siteApi[0].siteId,
        element.categoryId
      );
      if (!subCategories.length == 0) {
        for (let i in subCategories) {
          if (Array.isArray(subCategories[i])) {
            for (let element of subCategories[i]) {
              setCategories((prevState) => [...prevState, element]);
            }
          } else setCategories((prevState) => [...prevState, subCategories[i]]);
        }
      }
    }

    for (let i in uniqueExhibits) {
      const media = await MediaApi.GetByMediaId(uniqueExhibits[i].imagE_DEF);
      setΕxhibits((prevState) => [
        ...prevState,
        (uniqueExhibits[i]["imageValue"] = media.value),
      ]);
    }

    for (let i in categories) {
      const categoryMediaID = await MediaApi.GetByMediaId(
        categories[i].image_def
      );
      const categoryImageValue = categoryMediaID.value;
      setCategoryImageValue((prevState) => [...prevState, categoryImageValue]);
    }
  };

  const getExhibitsBySelectedOptions = async (results) => {
    console.log(categories);
    console.log(subCategories);
    if (results.length !== 0) {
      const categories = [];
      const exhibitByCategory = [];

      for (let id of results) categories.push(id.value);

      for (let i in filteredExhibits)
        if (categories.includes(parseInt(filteredExhibits[i].categorY_ID)))
          exhibitByCategory.push(filteredExhibits[i]);
      setSelectedOption(exhibitByCategory);
    } else {
      setSelectedOption([]);
    }
  };

  const setLanguage = () => {
    sessionStorage.languageChosen === "EN"
      ? setTextLanguage(English)
      : setTextLanguage(Greek);
  };

  return (
    <>
      
      <Banner />

      {/* Events Area*/}
      {events.length !== 0 ? (
        <div id="events" className="events-area align-items-center">
          <div className="container">
            <div className="row text-center">
              <h2 className="fw-light">{textLanguage.homePage.events}</h2>
              <EventsCard textLanguage={textLanguage} events={events} />
            </div>
          </div>
        </div>
      ) : null}

      {/* Categories area */}
      {categories.length !== 0 ? (
        <div id="categories" className="categories-area-front">
          <div className="container-front">
            <div className="row d-flex justify-content-center pt-5">
              <div className="text-center">
                <h2 className="fw-light">{textLanguage.homePage.categories}</h2>
              </div>
            </div>
            <div className="row"></div>
          </div>
          <CategoriesCard
            categories={categories}
            categoryImageValue={categoryImageValue}
            textLanguage={textLanguage}
            data={filteredExhibits}
          />
        </div>
      ) : null}

      {/* Exhibitions Area*/}
      {exhibitions.length !== 0 ? (
        <div id="exhibitions" className="events-area align-items-center">
          <div className="container">
            <div className="row text-center pt-5">
              <h2 className="fw-light">{textLanguage.homePage.exhibitions}</h2>
              <ExhibitionsCard
                textLanguage={textLanguage}
                exhibitions={exhibitions}
              />
            </div>
          </div>
        </div>
      ) : null}

      {/* About area */}
      <div className="service-area-2 align-items-center">
        <div className="container">
          <div className="row text-center">
            <div className="col-lg-6">
              <h2 className="fw-light">
                <span className="icon">
                  <FontAwesomeIcon icon={faMuseum} />
                </span>
                <span>{textLanguage.homePage.xenagos}</span>
              </h2>
            </div>
            <div className="col-lg-6">
              <p>{textLanguage.homePage.info}.</p>
            </div>
          </div>
        </div>
      </div>

      {/* Exhibits area */}
      <div className="exhibits-area-front">
        <Container id="exhibits">
          <Row className="pt-4">
            <Col xs={12} className="text-center">
              <h2 className="fw-light">{textLanguage.homePage.discoverExhibits}</h2>
            </Col>
          </Row>
          <Row className="d-flex justify-content-center bd-highlight py-4">
            <Col xs={5}>
              <SelectExhibits
                exhibits={filteredExhibits}
                categories={categories}
                fetchResults={(results) => {
                  getExhibitsBySelectedOptions(results);
                }}
              />
            </Col>
          </Row>
          <ExhibitsCard
            data={
              selectedOption.length === 0 ? filteredExhibits : selectedOption
            }
            textLanguage={textLanguage}
          />
        </Container>
      </div>
    </>
  );
}

export default Home;
