const next = (current, arr) => {
    let idx = arr.indexOf(current);
   
    if (idx === arr.length - 1) {
        return arr[0];
    }
    return arr[idx + 1];
}

const prev = (current, arr) => {
    let idx = arr.indexOf(current);
    
    if (idx === 0) {
        return arr[arr.length - 1];
    }
    return arr[idx - 1];
}

export { next, prev };