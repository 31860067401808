import React from "react";
import { fabric } from "fabric";
import { Button } from "react-bootstrap";
import { language, languageGr } from "../../../../api/lang";


import { useState, useEffect } from "react";
import HorizontalRuleSharpIcon from '@mui/icons-material/HorizontalRuleSharp';

function Line(props) {

  const [selected, setSelected] = useState(false);

  useEffect(() => {
    if (selected) {
      props.canvas.getActiveObject().set("stroke", props.iconColor);
      props.canvas.requestRenderAll();
      console.log(props.canvas.getActiveObject());

    }
  }, [ props.iconColor]);

  // useEffect(() => {
  //   if (selected) {
  //     props.canvas.getActiveObject().set("fill", props.fillColor);
  //     props.canvas.requestRenderAll();

  //   }
  // }, [ props.fillColor]);

  // useEffect(() => {
  //   if (selected) {
  //   props.setIconColor(props.canvas.getActiveObject().stroke);
      
  //     console.log(props.canvas.getActiveObject());

  //   }
  // }, [ selected]);

  const workPoint = new fabric.Line([50, 100, 200, 200], {
    left: 170,
    top: 150,
    stroke: props.iconColor,
    cornerColor: "black"
  });

  const onAddLine = () => {
    props.canvas.add(workPoint);
  };

  workPoint.on("selected", () => {
    props.setSquares(false);

    setSelected(true);
    props.setIconColor(props.canvas.getActiveObject().stroke);
    props.canvas.getActiveObject().cornerColor = "black";
    
    // document.getElementById("fontSizeDiv").style.display = "none";
    // document.getElementById("fillColorDiv").style.display = "none";
    // document.getElementById("textExhibit").style.display = "none";
    // document.getElementById("fontFamilyDiv").style.display = "none";
      


  });
  workPoint.on("deselected", () => {
    setSelected(false);
    // document.getElementById("fontSizeDiv").style.display = "flex";
    // document.getElementById("fillColorDiv").style.display = "flex";
    // document.getElementById("textExhibit").style.display = "flex";
    // document.getElementById("fontFamilyDiv").style.display = "flex";
  
    

  });



  if(props.canvas) {
    props.canvas.on("selection:created", (e) => {
      if(e.selected[0].type === "line") {
        props.setSquares(false);

        setSelected(true);
        props.setIconColor(props.canvas.getActiveObject().stroke);
        props.canvas.getActiveObject().cornerColor = "black";
        
        // document.getElementById("fontSizeDiv").style.display = "none";
        // document.getElementById("fillColorDiv").style.display = "none";
        // document.getElementById("textExhibit").style.display = "none";
        // document.getElementById("fontFamilyDiv").style.display = "none";
          
      } else {
      

        setSelected(false);
        // document.getElementById("fontSizeDiv").style.display = "flex";
        // document.getElementById("fillColorDiv").style.display = "flex";
        // document.getElementById("textExhibit").style.display = "flex";
        // document.getElementById("fontFamilyDiv").style.display = "flex";
      }
      
      //document.getElementById("strokeWidth").style.display = "block";
    });
  }

  if(props.canvas) {
    props.canvas.on("selection:updated", (e) => {
      console.log(e);

      if(e.selected[0].type === "line") {
        props.setSquares(false);

        setSelected(true);
    props.setIconColor(props.canvas.getActiveObject().stroke);
    props.canvas.getActiveObject().cornerColor = "black";
    
    // document.getElementById("fontSizeDiv").style.display = "none";
    // document.getElementById("fillColorDiv").style.display = "none";
    // document.getElementById("textExhibit").style.display = "none";
    // document.getElementById("fontFamilyDiv").style.display = "none";
      
      } else {
        setSelected(false);
    // document.getElementById("fontSizeDiv").style.display = "flex";
    // document.getElementById("fillColorDiv").style.display = "flex";
    // document.getElementById("textExhibit").style.display = "flex";
    // document.getElementById("fontFamilyDiv").style.display = "flex";
      }
    });
  }

  return (
    <button
    className="mapPropertiesItem"
   
    onClick={onAddLine}
  >
    <HorizontalRuleSharpIcon />
    {props.languageChosenSite === "EL"
                    ? languageGr.indoor.line
                    : language.indoor.line}
  </button>
    // <div>
    //   <Button onClick={onAddLine}>Line</Button>
    // </div>
  );
}

export default Line;
