import React from "react";
import { Button } from "react-bootstrap";
import DeleteIcon from "@mui/icons-material/Delete";
import { language, languageGr } from "../../../../api/lang";


function Delete(props) {
  const onDeleteSelection = () => {
    const activeSelection = props.canvas.getActiveObject();
    console.log(activeSelection);
    if (activeSelection && activeSelection._objects) {
      activeSelection._objects.map((object) => props.canvas.remove(object));
      //console.log('inside map');
      props.canvas.remove(activeSelection);

    } else {
      props.canvas.remove(activeSelection);
    }
  };
  return (
    <div>
      <button className="clean-canvas-btn" onClick={onDeleteSelection}>
        <DeleteIcon />
        {props.languageChosenSite === "EL"
                    ? languageGr.indoor.delete
                    : language.indoor.delete}
      </button>
    </div>
  );
}

export default Delete;
