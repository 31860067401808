import { useState, useEffect, useCallback, useMemo } from "react";

import * as FaIcons from "react-icons/fa";
import { ObjectApi, ExhibitNew } from "../../api/api.service";
// import GoogleMapReact from "google-map-react";
import { GoogleMap, useJsApiLoader, Marker, InfoWindow } from "@react-google-maps/api";
import Swal from "sweetalert2";
import { language, languageGr } from "../../api/lang";
import { ImContrast } from "react-icons/im";

const Step5 = ({exhibit, languageChosenSite}) => {
  useEffect(() => {
    getData();
  }, [exhibit]);
  let object;
  const getData = async () => {
    
    object = await ExhibitNew.GetAllValuesOfObject(exhibit.objectId);
    setMarkerAdd({lat: object.latitude, lng: object.longitude});
    setObj(object);
    console.log(object);
    console.log(object);
    console.log(obj);
    
  };



  const [map, setMap] = useState(null);
  const [obj, setObj] = useState({});
  const [longitude, setLongitude] = useState(0);
  const [latitude, setLatitude] = useState(0);
  const [markerAdd, setMarkerAdd] = useState({});
  const [centered, setCentered] = useState({lat:41, lng:25});

  const onLoad = useCallback(function callback(map) {
    //const bounds = new window.google.maps.LatLngBounds(centered);
    //map.fitBounds(bounds);
    map.setZoom(5);

    setMap(map);
  }, []);

  const onUnmount = useCallback(function callback(map) {
    setMap(null);
  }, []);

  const onPrevious = () => {
    console.log("prev");
    const dots = document.querySelectorAll(".Wizard_dot__btV-v ");
    const dotsArr = [...dots];
    dotsArr[3].click();
}

const onForward = async () => {
    // const dots = document.querySelectorAll(".Wizard_dot__btV-v ");
    // const dotsArr = [...dots];
    // // console.log(dotsArr);
    // // console.log(serialNumber);
    // // console.log(optionsForSel);
    // // console.log(optionsForSelSub);
    // // console.log(exhibitionChosen);
    // dotsArr[3].click();

    
      // const dots = document.querySelectorAll(".Wizard_dot__btV-v ");
      // const dotsArr = [...dots];
      // console.log(dotsArr);
      // console.log(serialNumber);
      // console.log(optionsForSel);
      // console.log(optionsForSelSub);
      // console.log(exhibitionChosen);
      await saveCoords();
      window.location.replace("./Management");

    

      Swal.fire({
        title: languageChosenSite === "EL" ? languageGr.media.successTitle : language.media.successTitle,
        text:  languageChosenSite === "EL" ? languageGr.media.successDesc : language.media.successDesc,
        icon: "success",
        button: 'OK',

    })
}

  

  

    const { isLoaded } = useJsApiLoader({
      id: "google-map-script",
      googleMapsApiKey: "AIzaSyDE3AVYlMuR5mpBqDl_lZLenbmyFhbkwaQ",
    });

    
    const longitudeChange = (e) => {
      console.log(e.target.value);
      setLongitude(parseFloat(e.target.value));
      //setMarkerAdd({...markerAdd, lng: parseFloat(e.target.value)})
    }

    const latitudeChange = (e) => {
      console.log(e.target.value);

      setLatitude(parseFloat(e.target.value));
      //setMarkerAdd({...markerAdd, lat: parseFloat(e.target.value)})

    }
    // const position = {
    //   lat: parseInt(object.latitude),
    //   lng: parseInt(object.longitude)
    // }

    const saveCoords = async () => { //na mpei sto finish meta ta coords
      // object = { ObjectId: 0, Code: '', Image: 0, Index: 0, CriticalRate: false }
      console.log(latitude);
      console.log(longitude);
      const object13 = {
        ExhibitId: obj.objectS_ID,
        Code: obj.objectS_CODE,
        Image: parseInt(obj.imagE_DEF),
        Index: parseInt(obj.lisT_INDEX),
        CriticalRate: false,
        Latitude: parseFloat(latitude),
        Longitude: parseFloat(longitude)
      }
      const objectUpdated = await ObjectApi.Update(object13);
      //console.log(object13);
      Swal.fire({
        title: languageChosenSite === "EL" ? languageGr.media.successTitle : language.media.successTitle,
        text: languageChosenSite === "EL" ? languageGr.media.successDesc : language.media.successDesc,
        icon: "success",
        button: 'OK',

      })
    }

    //console.log(object.latitude, object.longitude);

    const containerStyle = {
      width: 'auto',
      height: '400px'
    };
    
    const markerLabel = obj.name

    // let center = useMemo(() => ({
    //   lat: 41,
    //   lng: 25
    // }), []);

    

    const onShowOnMap = () => {
      //position = {lat: latitude, lng: longitude}
      console.log(latitude, longitude);
      setMarkerAdd({lat: parseFloat(latitude), lng: parseFloat(longitude)});
      setCentered({lat: parseFloat(latitude), lng: parseFloat(longitude)});
    }

    const mapClick = (e) => {
      console.log(e.latLng.lat());
      console.log(e.latLng.lng());
      setMarkerAdd({lat: e.latLng.lat(), lng: e.latLng.lng()});
      setLatitude(e.latLng.lat());
      setLongitude(e.latLng.lng());
      
      // return (<Marker position={markerAdd} />)
    }

  return (
    <>
      <div role="tabpanel" className="tab-pane container" id="tab_default_5">
        <div
          className="row"
          style={{
            width: "100%",
            "margin-left": "0px",
            "margin-right": "0px",
            height: "2vh",
          }}
        ></div>
        <div className="jumbotron" id="googleMaps">
          <div id="container"></div>
          {isLoaded ? (
            <GoogleMap
            mapContainerStyle={containerStyle}
            center={centered}
            defaultZoom={10}
            onLoad={onLoad}
            onUnmount={onUnmount}
            onClick={mapClick}
          >
            {/* Child components, such as markers, info windows, etc. */}
            <Marker position={markerAdd}  animation={2} 
              
            ><InfoWindow
            //onClick={onMarkerClick}    
            position={markerAdd}
            visible={true}
            >
        <div style={{"text-align": "center"}} id="content">
            <div id="siteNotice">
            </div>
            <h5 id="firstHeading" className="firstHeading">{obj.name}</h5>
        
        
            <div id="bodyContent">
              {/* <p><b>Exhibition : </b><div style={{"color": "green"}}>{m.exhibitionName}</div></p> */}

              <p><b>{languageChosenSite === "EL" ? languageGr.global.editWords.serialNumber : language.global.editWords.serialNumber}: </b><div style={{"color": "green"}}> {obj.objectS_CODE}</div> </p>
              
          
          
              <p><b>{languageChosenSite === "EL" ? languageGr.content.tab5.long : language.content.tab5.long} :</b><div style={{"color": "green"}}>{longitude == 0 ? obj.longitude: longitude }</div></p>
          
              <p><b>{languageChosenSite === "EL" ? languageGr.content.tab5.lat : language.content.tab5.lat} :</b><div style={{"color": "green"}}>{latitude  == 0 ? obj.latitude: latitude}</div></p>
    
            </div>
        </div>
        </InfoWindow></Marker> 
            
          </GoogleMap>
          ) : (
            <></>
          )}

          <div className="coordinates" style={{'margin-top': '3vh', 'margin-bottom': '3vh'}}>
              <div>
                <div className="coordinatesInputs" style={{'display': 'flex', 'flex-direction': 'row', 'justify-content': 'space-evenly'}}>
                    <div class="form-outline">
                      <label className="" for="markerLng" style={{'display': 'inline-block'}}>{languageChosenSite === "EL" ? languageGr.content.tab5.long : language.content.tab5.long}</label>
                        <input type='number' className="form-control" id='markerLng' placeholder="Enter a value.." defaultValue={markerAdd.lng} onChange={longitudeChange} style={{'width':'11vw', 'padding': '3px', 'padding-left': '0.5vw', 'margin-left': '1vw'}} />
                      
                    </div>
                    <div class="form-outline">
                      <label className="" for="markerLat" style={{'display': 'inline-block'}}>{languageChosenSite === "EL" ? languageGr.content.tab5.lat : language.content.tab5.lat}</label>
                        <input type='number' className="form-control xs" id='markerLat'  placeholder="Enter a value.." defaultValue={markerAdd.lat} onChange={latitudeChange} style={{'width':'11vw', 'padding': '3px', 'padding-left': '0.5vw', 'margin-left': '1vw'}} />
                      
                    </div>
                    
                    
                    <div style={{ "margin-top": "3%" }}>
                      <button className="btn btn-success" onClick={onShowOnMap} id="butt" style={{'border-radius':'16px'}}>{languageChosenSite === "EL" ? languageGr.content.tab5.showOnMap : language.content.tab5.showOnMap}</button>
                    </div>
                  </div>
                    {/* <span id="saved" style="margin-left: 3vh; color: green;">${language.content.tab5.successSave}</span> */}
                
              </div>
          </div>
          {/* <div style={{ height: "100vh", width: "100%" }}>
            <GoogleMapReact
              bootstrapURLKeys={{ key: "AIzaSyDE3AVYlMuR5mpBqDl_lZLenbmyFhbkwaQ" }}
              defaultCenter={defaultProps.center}
              defaultZoom={defaultProps.zoom}
              yesIWantToUseGoogleMapApiInternals
              onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
            >
              <ExhibitComp
                lat={59.955413}
                lng={30.337844}
                text="My Marker"
              />
            </GoogleMapReact>
          </div> */}
          <div
            className="modal_footer even"
            style={{
              display: "flex",
              "flex-direction": "row",
              "justify-content": "space-evenly",
            }}
          >
            
              <button
              disabled
                style={{ border: "1px solid black", "border-radius": "10px" , "color": "white", "background": "#1c4325" }}
                onClick={onPrevious}
                className="backwards btn"
                
              >
                <FaIcons.FaChevronLeft /> {languageChosenSite === "EL" ? languageGr.global.previous : language.global.previous}
              </button>
            
            
              <button
                style={{ border: "1px solid black", "border-radius": "10px", "color": "white", "background": "#1c4325" }}
                onClick={onForward}
                className="forward btn"
              >
                {languageChosenSite === "EL" ? languageGr.content.finish : language.content.finish} <FaIcons.FaChevronRight />
              </button>
            
          </div>
        </div>
      </div>
    </>
  );
};

export default Step5;
