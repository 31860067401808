import React, { useState, useEffect } from "react";

// Api
import {
  SiteApi,
  ExhibitionApi,
  LanguageApi,
  CategoryApi,
  PresentationApi,
  FieldApi,
  ObjectApi,
  MediaApi,
} from "../../api/api.service";
import { language, languageGr } from "../../api/lang";
import { authService } from "../../api/auth.service";

// Button skeleton
import ButtonSkeleton from "./ButtonSkeleton";

// XLSX
import * as XLSX from "xlsx/xlsx.mjs";
import ManualConfig from "../../Manual/Configuration_File_Starter.xlsx";
import ManualExhibit from "../../Manual/Exhibits_File_Starter.xlsx";

// Swal alert
import Swal from "sweetalert2";

// CSS
import "./Export.css";

// jquery
import $ from "jquery";

// Icons
import manage from "../../images/manage-orange.png";

// Bootstrap
import { Button } from "react-bootstrap";

import { FaSwatchbook } from "react-icons/fa";
//import * as XLSX from 'https://cdnjs.com/libraries/xlsx';
//import * as XLSX from 'https://cdnjs.cloudflare.com/ajax/libs/xlsx/0.18.2/xlsx.min.js';
//import ProgressBar from "react-animated-progress-bar";

// import { containerCSS } from "react-select/dist/declarations/src/components/containers";
// import { CONSTANTS, contains } from "@firebase/util";

const ImportComponent = () => {
  const getData = async () => {
    sites = await SiteApi.Get();
    setSite(sites[0]);
    fetchUser();
    const languagesArray = await LanguageApi.Get();
    setLanguageChosenSite(sessionStorage.getItem("languageChosen"));

    setLanguages(languagesArray);
  };

  const fetchUser = async () => {
    const user = await authService.getUserAsync();

    setUserRole(user.claims[0].value);
  };

  const [progress, setProgress] = useState(0);
  const [site, setSite] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [sheetData, setSheetData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFile2, setSelectedFile2] = useState(null);
  const [userRole, setUserRole] = useState("");
  const [languageChosenSite, setLanguageChosenSite] = useState("");
  const [selectedFile3, setSelectedFile3] = useState(null);

  let sites;
  let arrayErrors = [];

  useEffect(() => {
    document.querySelector(".navbar").style.display = "flex";
    document.querySelector(".pageHead").style.display = "block";
    getData();
    changeHead();
    // document.querySelector(
    //   ".pageHead"
    // ).innerHTML = `<h4 style='padding-top: 2%;text-align: center;color: #f7aa47;margin-left: -39vw;'><img style="height: 4vh;" class='fa fa-fw fa-file' src='${manage}' />${language.global.titles.import}</h4>`;
  }, []);

  /////////////////////////////////SITE//////////////////////////////////////

  const changeHead = () => {
    if (sessionStorage.getItem("languageChosen") === "EL") {
      document.querySelector(".pageHead").innerHTML =`<div style="width: 100%; height:100%; display: inline-flex; justify-content: center;"><h4 style='padding-top: 3.5vh;text-align: center;color: #f7aa47;'><img style="height: 4vh;margin-top:-1vh;" class='fa fa-fw fa-file' src='${manage}' />${languageGr.global.titles.import}</h4></div>`;
    } else {
      document.querySelector(".pageHead").innerHTML =`<div style="width: 100%; height:100%; display: inline-flex; justify-content: center;"><h4 style='padding-top: 3.5vh;text-align: center;color: #f7aa47;'><img style="height: 4vh;margin-top:-1vh;" class='fa fa-fw fa-file' src='${manage}' />${language.global.titles.import}</h4></div>`;
    }
  };

  async function siteCreation(siteName, description, languageId) {
    const siteSum = await SiteApi.GetTranslation(site.siteId);
    //console.log(siteSum);
    if (
      siteSum.find((s) => s.name === siteName && s.languageId === languageId)
    ) {
    } else {
      const siteModel = {
        Translations: [],
      };

      siteModel.Translations.push({
        id: parseInt(site.siteId),
        name: siteName,
        description: description,
        languageId: parseInt(languageId),
      });

      const update = await SiteApi.CreateTranslation(siteModel);

      console.log("Site created: ", siteName);
      //console.log(arrayOfSiteIds);
    }
  }

  //////////////////////CATEGORIES////////////////////////////

  async function categoryCheckExists(categoryName, languageId) {
    const categories = await CategoryApi.GetCategoriesOfSite(
      parseInt(site.siteId)
    );

    for (let cat of categories) {
      const categoriesSum = await CategoryApi.GetByTranslation(cat.categoryId);
      //console.log(categoriesSum);
      const checkExistance = categoriesSum.find(
        (s) => s.name === categoryName && s.languageId === languageId
      );

      if (checkExistance) {
        return cat.categoryId;
      }
    }

    //console.log("category inputs");
    //console.log(categoryName);
    console.log("Category not exists: ", categoryName, languageId);

    arrayErrors.push("error");
    return 0;
  }

  async function ParentCreate(arr) {
    const groupedParIds = groupBy(arr, "Id");
    let catFlag = false;

    for (let e in groupedParIds) {
      const category = {
        Name: groupedParIds[e][0].Name,
        Description: groupedParIds[e][0].Description,
        image_def: 1,
        ParentId: null,
        translations: [],
      };

      for (let t of groupedParIds[e]) {
        //catFlag = false;
        const checkCat = await categoryCheckExists(t.Name, t.LanguageId);

        if (checkCat === 0) {
          catFlag = true;

          category.translations.push({
            LanguageId: parseInt(t.LanguageId),
            Name: t.Name,
            Description: t.Description,
          });
        } else {
          //const fetch = await CategoryApi.GetById(checkCat);

          var translation = {
            translations: [],
          };

          translation.translations.push({
            id: parseInt(checkCat),
            languageId: parseInt(t.LanguageId),
            name: t.Name,
            description: t.Description,
          });
        }
      }
      if (catFlag) {
        const insertedCategory = await CategoryApi.Create(category);

        const catNew = await CategoryApi.AddToSite(
          insertedCategory.categoryId,
          site.siteId
        );
      } else {
        const update = await CategoryApi.CreateTranslation(translation);
      }
    }
  }

  async function ChildrenCreate(arr) {
    const groupedIds = groupBy(arr, "Id");
    let catFlag = false;

    for (let e in groupedIds) {
      var category = {
        Name: groupedIds[e][0].Name,
        Description: groupedIds[e][0].Description,
        image_def: 1,
        ParentId: null,
        translations: [],
      };

      for (let t of groupedIds[e]) {
        //catFlag = false;
        const checkCat = await categoryCheckExists(t.Name, t.LanguageId);
        const checkParent = await categoryCheckExists(t.Parent, t.LanguageId);
        if (checkCat === 0 && checkParent !== 0) {
          category.Name = groupedIds[e][0].Name;
          category.Description = groupedIds[e][0].Description;
          category.ParentId = parseInt(checkParent);

          catFlag = true;

          category.translations.push({
            LanguageId: parseInt(t.LanguageId),
            Name: t.Name,
            Description: t.Description,
          });
        } else {
          //var fetch = await CategoryApi.GetById(checkCat);

          var translation = {
            translations: [],
          };

          translation.translations.push({
            id: parseInt(checkCat),
            languageId: parseInt(t.LanguageId),
            name: t.Name,
            description: t.Description,
          });
        }
      }
      if (catFlag) {
        const insertedCategory = await CategoryApi.Create(category);

        const catNew = await CategoryApi.AddToSite(
          insertedCategory.categoryId,
          site.siteId
        );
      } else {
        const update = await CategoryApi.CreateTranslation(translation);
      }
    }
  }

  /////////////////////////////EXHIBITIONS//////////////////////////////

  async function ExhibitionCheckExists(exhibitionName, languageId) {
    const exhibitions = await ExhibitionApi.GetBySiteId(parseInt(site.siteId));

    for (let exh of exhibitions) {
      const exhibitionsSum = await ExhibitionApi.GetByTranslation(
        exh.exhibitionId
      );

      if (
        exhibitionsSum.find(
          (s) => s.name === exhibitionName && s.languageId === languageId
        )
      ) {
        return exh.exhibitionId;
      }
    }

    //console.log("exhibition inputs");
    //console.log(exhibitionName);

    console.log("exhibition not exists: ", exhibitionName);
    return 0;
  }

  async function ExhibitionCreate(arr) {
    const groupedIds = groupBy(arr, "Id");
    let exhFlag = false;

    for (let e in groupedIds) {
      const exhibition = {
        Name: groupedIds[e][0].Name,
        Description: groupedIds[e][0].Description,
        translations: [],
      };

      for (let t of groupedIds[e]) {
        const checkExh = await ExhibitionCheckExists(t.Name, t.LanguageId);

        if (checkExh === 0) {
          exhibition.Name = groupedIds[e][0].Name;
          exhibition.Description = groupedIds[e][0].Description;

          exhFlag = true;

          exhibition.translations.push({
            LanguageId: parseInt(t.LanguageId),
            Name: t.Name,
            Description: t.Description,
          });
        } else {
          //var fetch = await ExhibitionApi.GetById(checkExh);

          var translation = {
            translations: [],
          };

          translation.translations.push({
            id: parseInt(checkExh),
            languageId: parseInt(t.LanguageId),
            name: t.Name,
            description: t.Description,
          });
        }
      }
      if (exhFlag) {
        const insertedExhibition = await ExhibitionApi.Create(exhibition);

        const exhNew = await ExhibitionApi.AddToSite(
          insertedExhibition.exhibitionId,
          site.siteId
        );
      } else {
        const update = await ExhibitionApi.CreateTranslation(translation);
      }
    }
  }

  ///////////////////////////PRESENTATIONS///////////////////////////////////

  async function PresentationCheckExists(presentationName, languageId) {
    const presentations = await PresentationApi.GetBySiteId(site.siteId);

    for (let pres of presentations) {
      const presentationsSum = await PresentationApi.GetByTranslation(
        pres.presentationId
      );

      if (
        presentationsSum.find(
          (s) => s.name === presentationName && s.languageId === languageId
        )
      ) {
        return pres.presentationId;
      }
    }

    //console.log("pres inputs");
    //console.log(presentationName);
    //console.log(languageId);
    console.log("Presentation not exists: ", presentationName);
    return 0;
  }

  async function PresentationCreate(arr) {
    const groupedIds = groupBy(arr, "Id");
    let presFlag = false;

    for (let e in groupedIds) {
      const presentation = {
        Name: groupedIds[e][0].Name,
        Description: groupedIds[e][0].Description,
        translations: [],
      };

      for (let t of groupedIds[e]) {
        const checkPres = await PresentationCheckExists(t.Name, t.LanguageId);

        if (checkPres === 0) {
          presentation.Name = groupedIds[e][0].Name;
          presentation.Description = groupedIds[e][0].Description;

          presFlag = true;

          presentation.translations.push({
            LanguageId: parseInt(t.LanguageId),
            Name: t.Name,
            Description: t.Description,
          });
        } else {
          //var fetch = await PresentationApi.GetById(checkPres);

          var translation = {
            translations: [],
          };

          translation.translations.push({
            id: parseInt(checkPres),
            languageId: parseInt(t.LanguageId),
            name: t.Name,
            description: t.Description,
          });
        }
      }
      if (presFlag) {
        const insertedPresentation = await PresentationApi.Create(presentation);

        const presNew = await PresentationApi.AddToSite(
          insertedPresentation.presentationId,
          site.siteId
        );
      } else {
        const update = await PresentationApi.CreateTranslation(translation);
      }
    }
  }

  ///////////////////////////FIELDS//////////////////////////////////

  async function FieldCheckExists(fieldName, languageId) {
    const fields = await FieldApi.Get();

    for (let field of fields) {
      const fieldsSum = await FieldApi.GetByTranslation(field.fieldId);

      if (
        fieldsSum.find(
          (s) => s.name === fieldName && s.languageId === languageId
        )
      ) {
        return field.fieldId;
      }
    }

    //console.log("pres inputs");
    //console.log(presentationName);
    //console.log(languageId);
    console.log("Field does not exist: ", fieldName);
    return 0;
  }

  async function FieldCreate(arr) {
    const groupedIds = groupBy(arr, "Id");
    let fieldFlag = false;

    for (let e in groupedIds) {
      let field = {
        Name: groupedIds[e][0].Name,
        Description: groupedIds[e][0].Description,
        translations: [],
      };

      for (let t of groupedIds[e]) {
        const checkField = await FieldCheckExists(t.Name, t.LanguageId);

        if (checkField === 0) {
          field.Name = groupedIds[e][0].Name;
          field.Description = groupedIds[e][0].Description;
          //field.Category = groupedIds[e][0].Category;

          fieldFlag = true;

          field.translations.push({
            LanguageId: parseInt(t.LanguageId),
            Name: t.Name,
            Description: t.Description,
          });
        } else {
          //var fetch = await FieldApi.GetById(checkField);

          var translation = {
            translations: [],
          };

          translation.translations.push({
            id: parseInt(checkField),
            languageId: parseInt(t.LanguageId),
            name: t.Name,
            description: t.Description,
          });
        }
      }

      console.log(field);

      if (fieldFlag) {
        const insertedField = await FieldApi.Create(field);
        console.log(insertedField);
        console.log(groupedIds[e][0].Category);
        for (let cat of groupedIds[e][0].Category) {
          console.log(cat);
          const checkCategory = await categoryCheckExists(
            cat,
            groupedIds[e][0].LanguageId
          );
          console.log(checkCategory);
          if (checkCategory !== 0) {
            const fieldNew = await FieldApi.AddToCategory(
              checkCategory,
              insertedField.fieldId
            );
            console.log(fieldNew);
          }
        }

        //var fieldNew = await FieldApi.AddToCategory(insertedField.fieldId, categoryid);
      } else {
        let update = await FieldApi.CreateTranslation(translation);
      }
    }
  }

  ///////////////////////////////////////OBJECTS//////////////////////////////////////////.toString()
  async function ObjectCodeCheck(objectCode) {
    const objects = await ObjectApi.Get();

    if (
      objectCode.length == 0 ||
      parseInt(objectCode) < 100 ||
      parseInt(objectCode) > 100000
    ) {
      arrayErrors.push("error");
      return -1;
    }

    if (objects.length === 0) {
      return 0;
    } else {
      var f = objects.find((s) => s.objectS_CODE === objectCode.toString());

      if (f) {
        arrayErrors.push("error");
        console.log("code exists");
        return f.objectS_ID;
      } else {
        return 0;
      }
    }
  }

  async function ObjectCreate(arr) {
    const groupedIds = groupBy(arr, "Code");

    console.log(groupedIds);
    let objFlag = false;
    let count = 0;
    let filteredTrans = [];
    const percentCount = 100 / Object.keys(groupedIds).length;
    //let errors = [];

    for (let e in groupedIds) {
      count++;

      const object = {
        Name: groupedIds[e][0].Name,
        Description: groupedIds[e][0].Description,
        Image: 1,
        Code: groupedIds[e][0].ObjectCode,
        /*"Index": parseInt(index)*/
        CriticalRate: false,
        translations: [],
        Latitude: groupedIds[e][0].Latitude,
        Longitude: groupedIds[e][0].Longitude,
      };

      console.log(object);
      for (let t of groupedIds[e]) {
        updateProgressBar(`${count * percentCount}`);
        updateStatusDownload(`${count} of ${Object.keys(groupedIds).length}`);

        const checkObj = await ObjectCodeCheck(t.Code);
        //console.log(checkObj);
        //console.log(groupedIds[e][0].Code);
        //console.log(t.Code);

        if (checkObj === 0) {
          object.Name = t.Name;
          object.Description = t.Description;
          object.Code = t.Code.toString();
          object.Latitude = t.Latitude;
          object.Longitude = t.Longitude;

          objFlag = true;
          if (
            !object.translations.find(
              (c) =>
                c.LanguageId === t.LanguageId &&
                c.Name === t.Name &&
                c.Description === t.Description
            )
          ) {
            object.translations.push({
              LanguageId: parseInt(t.LanguageId),
              Name: t.Name,
              Description: t.Description,
            });
          }
          //object.translations.push({ LanguageId: parseInt(t.LanguageId), Name: t.Name, Description: t.Description });
          console.log(object.translations);
        } else {
          //console.log(groupedIds[e]);
          //console.log(t);

          // var fetch = await ObjectApi.Get();

          objFlag = false;
          const translation = {
            translations: [],
          };

          translation.translations.push({
            id: parseInt(checkObj),
            languageId: parseInt(t.LanguageId),
            name: t.Name,
            description: t.Description,
          });
          //translation.translations.find();
          translation.translations.map((x) =>
            filteredTrans.filter((a) => a.id == x.translationId).length > 0
              ? null
              : filteredTrans.push(x)
          );
          console.log(filteredTrans);
          let update = await ObjectApi.CreateTranslation(translation);
        }
      }
      if (objFlag) {
        console.log(object);
        const insertedObject = await ObjectApi.Create(object);
        console.log(insertedObject);
        const insertObject = {
          ExhibitId: parseInt(insertedObject.objectId),
          LanguageId: groupedIds[e][0].LanguageId,
          PressId: 1,
          FieldId: 0,
          Status: false,
          ShortValue: insertedObject.name,
          LongValue: insertedObject.name,
          Mobile: true,
          Url: null,
        };

        console.log(insertedObject);
        console.log(insertObject);
        const insertObjectVal = await ObjectApi.Insert(insertObject);

        //if (insertObjectVal) {

        //}

        const checkCategory = await categoryCheckExists(
          groupedIds[e][0].Category,
          groupedIds[e][0].LanguageId
        );
        //console.log(checkCategory);
        //var categoryId = await CategoryApi.GetById(checkCategory);

        console.log(groupedIds[e][0].Category);
        console.log(groupedIds[e][0].LanguageId);

        if (checkCategory !== 0) {
          const objNew = await ObjectApi.AddToCategory(
            insertedObject.objectId,
            checkCategory
          );
          console.log(objNew);
        }
        //console.log(groupedIds[e][0].Exhibitions);
        if (groupedIds[e][0].Exhibitions === null) {
          continue;
        } else {
          for (let exh of groupedIds[e][0].Exhibitions) {
            const checkExhibition = await ExhibitionCheckExists(
              exh,
              groupedIds[e][0].LanguageId
            );

            if (checkExhibition !== 0) {
              let addedObjToExhibition = await ObjectApi.AddToExhibition(
                insertedObject.objectId,
                checkExhibition
              );
            }
          }
        }
      }
    }
    console.log(arrayErrors);
    if (arrayErrors.length == 0) {
      Swal.fire({
        title:
          languageChosenSite === "EL"
            ? languageGr.import.success
            : language.import.success,
        text:
          languageChosenSite === "EL"
            ? languageGr.import.successUpload
            : language.import.successUpload,
        icon: "success",
        button: "OK",
      });
    } else {
      Swal.fire({
        title:
          languageChosenSite === "EL"
            ? languageGr.import.issues
            : language.import.issues,
        text:
          languageChosenSite === "EL"
            ? languageGr.import.issuesText
            : language.import.issuesText,
        icon: "warning",
        button: "OK",
      });
    }
    setLoading(false);

    document.querySelector("#progressStatus").innerText = "";
    //document.getElementById("button2").disabled = false;
    $("#objectTab").css("pointer-events", "auto");
    $("#configurationTab").css("pointer-events", "auto");
    $("#mediaTab").css("pointer-events", "auto");
    setLoading(true);
  }

  const groupBy = (array, key) => {
    return array.reduce((result, currentValue) => {
      (result[currentValue[key]] = result[currentValue[key]] || []).push(
        currentValue
      );

      return result;
    }, {});
  };

  const languageList = [
    { name: "Arabic", initials: "AR", description: "arabic language" },
    { name: "Chinese", initials: "ZH", description: "chinese language" },
    { name: "English", initials: "EN", description: "english language" },
    { name: "Greek", initials: "EL", description: "greek language" },
    { name: "Turkish", initials: "TR", description: "turksih language" },
    { name: "Hindi", initials: "HI", description: "Hindi language" },
    { name: "Chinese", initials: "ZH", description: "Chinese language" },
    { name: "German", initials: "DE", description: "German language" },
    //{ name: 'Arabic', initials: 'AR', description: 'arabic language' },
    //{ name: 'Arabic', initials: 'AR', description: 'arabic language' },
    //{ name: 'Arabic', initials: 'AR', description: 'arabic language' },
    //{ name: 'Arabic', initials: 'AR', description: 'arabic language' },
  ];

  function updateProgressBar(value) {
    document.querySelector("#barUpload").style.opacity = "1";
    document.querySelector("#progressBUpload").style.width = `${value}%`;
  }

  function updateStatusDownload(status) {
    document.querySelector(
      "#progressStatus"
    ).innerHTML = `Converting <span class="statusSpan" style="font-weight: bold;">${status}</span>... Please wait`;
  }

  const startProgress = () => {
    document.querySelector("#barUpload").style.opacity = "0.4";

    document.querySelector("#progressBUpload").style.width = `0%`;
  };

  async function languageCreate(initials) {
    let l = await LanguageApi.Get();

    const languageA = languageList.find((l) => l.initials === initials);
    if (!languageA) {
      return -1;
    }

    if (l.find((l) => l.initials === languageA.initials)) {
      const langObject = l.find((l) => l.initials === languageA.initials);
      return langObject.languageId;
    } else {
      const languageB = {
        name: languageA.name,
        description: languageA.description,
        initials: languageA.initials,
      };

      const newLanguage = await LanguageApi.Create(languageA);
      console.log("language created: ", languageA.name);
      return newLanguage;
    }
  }

  const uploadConfig = () => {
    // if (claims.claims.role === "Viewer") {
    //     //alert(language.global.userRole);
    //     swal({
    //         title: language.media.invalidAction,
    //         text: language.global.userRole,
    //         icon: "warning",
    //         button: "OK",

    //     })
    // } else {

    if (userRole != "Admin") {
      Swal.fire({
        title: languageChosenSite == "EL" ? languageGr.media.invalidAction : language.media.invalidAction,
        text: languageChosenSite == "EL" ? languageGr.global.userRole : language.global.userRole,
        icon: "warning",
        button: "OK",
      });
      return;
    }

    if(selectedFile == null) {
      Swal.fire({
        title: languageChosenSite === "EL"
        ? languageGr.import.issues
        : language.import.issues,
        text: languageChosenSite === "EL"
        ? languageGr.import.nofile
        : language.import.nofile,
        icon: "warning",
        button: "OK",
      });
      return;
    }

    XLSX.utils.json_to_sheet(data, "out.xlsx");
    if (selectedFile != null) {
      let fileReader = new FileReader();
      fileReader.readAsBinaryString(selectedFile);
      fileReader.onload = async (event) => {
        let data = event.target.result;
        let categoryArray = [];
        let exhibitionArray = [];
        let presentationArray = [];
        let fieldArray = [];
        let workbook = XLSX.read(data, { type: "binary" });
        console.log(workbook);
        setLoading(false);

        $("#objectTab").css("pointer-events", "none");
        // document.getElementById("button").disabled = true;
        $("#configurationTab").css("pointer-events", "none");
        $("#mediaTab").css("pointer-events", "none");
        updateProgressBar(0);
        updateStatusDownload("Museum Files");
        //workbook.SheetNames.forEach(async sheet => {
        for (let sheet of workbook.SheetNames) {
          /*let rowObject = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheet]);*/

          //for (let row of rowObject) {
          if (sheet === "Site") {
            let rowObject = XLSX.utils.sheet_to_row_object_array(
              workbook.Sheets[sheet]
            );
            for (let row of rowObject) {
              let languageId = await languageCreate(row.Languages);

              await siteCreation(row.Name, row.Description, languageId);
            }

            updateProgressBar(20);
          } else if (sheet === "Categories") {
            let rowObject = XLSX.utils.sheet_to_row_object_array(
              workbook.Sheets[sheet]
            );

            for (let row of rowObject) {
              const languageId = await languageCreate(row.Languages);

              const categoryModel = {
                Id: row.Id,
                Name: row.Name,
                Image_def: 1,
                Description: row.Description,
                LanguageId: languageId,
                Parent: row.Parent,
              };

              categoryArray.push(categoryModel);

              let childrenArray = categoryArray.filter((cat) => cat.Parent);

              let parentArray = categoryArray.filter(
                (cat) => typeof cat.Parent === "undefined"
              );
            }
          } else if (sheet === "Exhibitions") {
            let rowObject = XLSX.utils.sheet_to_row_object_array(
              workbook.Sheets[sheet]
            );
            for (let row of rowObject) {
              const languageId = await languageCreate(row.Languages);

              const exhibitionModel = {
                Id: row.Id,
                Name: row.Name,
                Description: row.Description,
                LanguageId: languageId,
              };

              exhibitionArray.push(exhibitionModel);
            }
          } else if (sheet === "Presentations") {
            let rowObject = XLSX.utils.sheet_to_row_object_array(
              workbook.Sheets[sheet]
            );
            for (let row of rowObject) {
              const languageId = await languageCreate(row.Languages);

              const presentationModel = {
                Id: row.Id,
                Name: row.Name,
                Description: row.Description,
                LanguageId: languageId,
              };

              presentationArray.push(presentationModel);
            }
          } else if (sheet === "Fields") {
            let rowObject = XLSX.utils.sheet_to_row_object_array(
              workbook.Sheets[sheet]
            );

            for (let row of rowObject) {
              const languageId = await languageCreate(row.Languages);

              const fieldModel = {
                Id: row.Id,
                Name: row.Name,
                Description: row.Description,
                LanguageId: languageId,
                Category: null,
              };

              const categoryValue = row.Category;
              console.log(categoryValue);
              if (typeof categoryValue !== "undefined") {
                fieldModel.Category = categoryValue.split(", ");
              }
              // const testArr = categoryValue.split(", ");
              //console.log(testArr);
              //console.log(testArr);
              //fieldModel.Category.push(testArr);
              //fieldModel.Category = testArr;
              fieldArray.push(fieldModel);
            }
            //console.log(fieldArray);
          }
          //} // end of for per row

          if (sheet === "Categories") {
            const parentArray = categoryArray.filter(
              (cat) => typeof cat.Parent === "undefined"
            );
            //console.log(parentArray);
            const childrenArray = categoryArray.filter((cat) => cat.Parent);
            updateStatusDownload("Category Files");
            await ParentCreate(parentArray);

            await ChildrenCreate(childrenArray);

            updateProgressBar(40);
          } else if (sheet === "Exhibitions") {
            updateStatusDownload("Exhibition Files");

            await ExhibitionCreate(exhibitionArray);

            updateProgressBar(60);
          } else if (sheet === "Presentations") {
            updateStatusDownload("Presentation Files");

            await PresentationCreate(presentationArray);

            updateProgressBar(80);
          } else if (sheet === "Fields") {
            updateStatusDownload("Field Files");
            updateProgressBar(90);
            await FieldCreate(fieldArray);

            updateProgressBar(100);

            Swal.fire({
              title:
                languageChosenSite === "EL"
                  ? languageGr.import.success
                  : language.import.success,
              text:
                languageChosenSite === "EL"
                  ? languageGr.import.successUpload
                  : language.import.successUpload,
              icon: "success",
              button: "OK",
            });
            document.querySelector("#progressStatus").innerText = "";
            //document.getElementById("button").disabled = false;
            $("#objectTab").css("pointer-events", "auto");
            $("#configurationTab").css("pointer-events", "auto");
            $("#mediaTab").css("pointer-events", "auto");
            setLoading(true);
          }

          /*document.getElementById("jsondata").innerHTML = JSON.stringify(rowObject, undefined, 4)*/
          //}); //end of foreach
        }
      };
    } else {
      document.getElementById("button").disabled = false;
    }
    //}
  };

  const uploadExhibits = () => {
    // if (claims.claims.role === "Viewer") {
    //   //alert(language.global.userRole);
    //   Swal.fire({
    //     title: language.media.invalidAction,
    //     text: language.global.userRole,
    //     icon: "warning",
    //     button: "OK",
    //   });
    // } else {
    if (userRole != "Admin") {
      Swal.fire({
        title: languageChosenSite == "EL" ? languageGr.media.invalidAction : language.media.invalidAction,
        text: languageChosenSite == "EL" ? languageGr.global.userRole : language.global.userRole,
        icon: "warning",
        button: "OK",
      });
      return;
    }

    if(selectedFile2 == null) {
      Swal.fire({
        title: languageChosenSite === "EL"
        ? languageGr.import.issues
        : language.import.issues,
        text: languageChosenSite === "EL"
        ? languageGr.import.nofile
        : language.import.nofile,
        icon: "warning",
        button: "OK",
      });
      return;
    }
    XLSX.utils.json_to_sheet(data2, "out.xlsx");
    if (selectedFile2 != null) {
      console.log(selectedFile2);

      let fileReader = new FileReader();
      fileReader.readAsBinaryString(selectedFile2);
      fileReader.onload = async (event) => {
        let data = event.target.result;
        let objArray = [];
        let workbook = XLSX.read(data, { type: "binary" });
        console.log(workbook);
        setLoading(false);

        //document.getElementById("button2").disabled = false;
        $("#objectTab").css("pointer-events", "none");
        $("#configurationTab").css("pointer-events", "none");
        $("#mediaTab").css("pointer-events", "none");
        for (let sheet of workbook.SheetNames) {
          let rowObject = XLSX.utils.sheet_to_row_object_array(
            workbook.Sheets[sheet]
          );
          console.log(rowObject);
          /*document.getElementById("jsondata2").innerHTML = JSON.stringify(rowObject, undefined, 4)*/

          for (let row of rowObject) {
            const languageId = await languageCreate(row.Languages);

            const objectModel = {
              Name: row.Name,
              Description: row.Description,
              Image: 1,
              Code: row.ObjectCode,
              LanguageId: languageId,
              /*"Index": parseInt(index)*/
              CriticalRate: false,
              translations: [row.Translations],
              Latitude: row.Latitude,
              Longitude: row.Longitude,
              Category: row.Category,
              Exhibitions: null,
            };

            const ExhibitionValue = row.Exhibition;
            console.log(ExhibitionValue);
            if (typeof ExhibitionValue !== "undefined") {
              const testArr = ExhibitionValue.split(", ");
              console.log(testArr);
              //fieldModel.Category.push(testArr);
              objectModel.Exhibitions = testArr;
            }
            objArray.push(objectModel);
            console.log(objArray);

            //var media = {
            //    "Name": name,
            //    "Description": "",
            //    "TypeId": 1,
            //    "LanguageId": parseInt(languageId),
            //    "Value": image,
            //    "ObjectValId": 0,
            //    "RefPath": "refpath"
            //}
          }

          await ObjectCreate(objArray);
        }
      };
    }
    //}
  };

  const uploadMedia = async () => {
    // if (claims.claims.role === "Viewer") {
    //   //alert(language.global.userRole);
    //   Swal.fire({
    //       title: language.media.invalidAction,
    //       text: language.global.userRole,
    //       icon: "warning",
    //       button: "OK",

    //   })
    // } else {
    //let fileReader = new FileReader();
    //var file = document.getElementById("input3").files[0]
    if (userRole != "Admin") {
      Swal.fire({
        title: languageChosenSite == "EL" ? languageGr.media.invalidAction : language.media.invalidAction,
        text: languageChosenSite == "EL" ? languageGr.global.userRole : language.global.userRole,
        icon: "warning",
        button: "OK",
      });
      return;
    }

    if(selectedFile3 == null) {
      Swal.fire({
        title: languageChosenSite === "EL"
        ? languageGr.import.issues
        : language.import.issues,
        text: languageChosenSite === "EL"
        ? languageGr.import.nofile
        : language.import.nofile,
        icon: "warning",
        button: "OK",
      });
      return;
    }

    if (typeof selectedFile3 === "undefined") {
      Swal.fire({
        title:
          languageChosenSite === "EL"
            ? languageGr.media.invalidAction
            : language.media.invalidAction,
        text:
          languageChosenSite === "EL"
            ? languageGr.import.fileType
            : language.import.fileType,
        icon: "warning",
        button: "OK",
      });
    } else {
      $("#configurationTab").css("pointer-events", "none");
      $("#objectTab").css("pointer-events", "none");
      //document.getElementById("button3").disabled = true;
      $("#mediaTab").css("pointer-events", "none");
      updateStatusDownload("Media files");

      console.log(selectedFile3);

      //fileReader.onload = async (event) => {
      //    let data = event.target.result;
      //};
      let count = 0;
      const timer = setInterval(() => {
        updateProgressBar(count);
        if (count < 80) {
          count++;
        }
      }, 1000);

      // for (let i = 0; i < 80; i++) {

      //     timer;

      // }

      const toBase64 = (files) =>
        new Promise(async (resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(files);

          reader.onload = async () => resolve(reader.result);

          reader.onerror = (error) => reject(error);
        });

      //let reader = new FileReader();
      //reader.addEventListener("loadend", () => { let data = reader.result; });
      //reader.readAsText(file);
      /*var test = await MediaApi.ImportMedia();*/

      const uri = await toBase64(selectedFile3);
      //console.log(uri);

      const form = new FormData();
      console.log(form);
      form.append("zip", selectedFile3);
      const fetch = await MediaApi.ImportMedia(form);
      console.log(fetch);
      updateProgressBar(100);
      clearInterval(timer);
      Swal.fire({
        title:
          languageChosenSite === "EL"
            ? languageGr.import.success
            : language.import.success,
        text:
          languageChosenSite === "EL"
            ? languageGr.import.successUpload
            : language.import.successUpload,
        icon: "success",
        button: "OK",
      });

      $("#configurationTab").css("pointer-events", "auto");
      $("#objectTab").css("pointer-events", "auto");
      document.querySelector("#progressStatus").innerText = "";
      //document.getElementById("button3").disabled = false;

      $("#mediaTab").css("pointer-events", "auto");
    }
    //}
  };

  let data = [
    {
      name: "jayanth",
      data: "scd",
      abc: "sdef",
    },
  ];

  let data2 = [
    {
      name: "jayaanth",
      data: "scd",
    },
  ];

  return (
    <div
      className="row align-items-center boxes"
      style={{
        width: "78%",
        "min-height": "52vh",
        /* box-shadow: 1px 2px 11px 0px #d2d2d2 !important; */
        "margin-top": "7vh",
        "margin-left": "10.5vw",
        border: "1px solid #d2d2d2",
        "box-shadow": "3px 2px 20px 0px",
        "border-radius": "20px",
      }}
    >
      <div className="col-1"></div>
      <div className="col-10">
        <div id="basicwizard">
          <ul
            className="nav nav-tabs nav-justified mb-5"
            style={{ background: "#dee2e6", "border-radius": "13px" }}
          >
            <li className="nav-item" data-target-form="#contactDetailForm">
              <a
                href="#contactDetail"
                data-bs-toggle="tab"
                data-toggle="tab"
                id="configurationTab"
                className="nav-link icon-btn active"
                style={{ color: "black", "border-radius": "13px" }}
                onClick={startProgress}
              >
                <i className="bx bxs-contact me-1"></i>
                <span className="d-none d-sm-inline">
                  {languageChosenSite === "EL"
                    ? languageGr.import.importData
                    : language.import.importData}
                </span>
              </a>
            </li>

            <li className="nav-item" data-target-form="#jobDetailForm">
              <a
                href="#jobDetail"
                data-bs-toggle="tab"
                data-toggle="tab"
                id="objectTab"
                className="nav-link icon-btn"
                style={{ color: "black", "border-radius": "13px" }}
                onClick={startProgress}
              >
                <i className="bx bxs-building me-1"></i>
                <span className="d-none d-sm-inline">
                  {languageChosenSite === "EL"
                    ? languageGr.import.importExhibits
                    : language.import.importExhibits}
                </span>
              </a>
            </li>

            <li className="nav-item">
              <a
                href="#finish"
                data-bs-toggle="tab"
                data-toggle="tab"
                id="mediaTab"
                className="nav-link icon-btn"
                style={{
                  width: "100%",
                  color: "black",
                  "border-radius": "13px",
                }}
                onClick={startProgress}
              >
                <i className="bx bxs-check-circle me-1"></i>
                <span className="d-none d-sm-inline">
                  {languageChosenSite === "EL"
                    ? languageGr.import.importMedia
                    : language.import.importMedia}
                </span>
              </a>
            </li>
          </ul>

          <div className="tab-content mb-0 pt-0 text-center">
            <div
              id="barUpload"
              className="progress mb-3"
              style={{
                "max-height": "3vh",
                "margin-left": "7.5%",
                opacity: "0.4",
              }}
            >
              <div
                id="progressBUpload"
                style={{ "border-radius": "13px" }}
                className="bar progress-bar progress-bar-striped progress-bar-animated bg-success"
              ></div>
            </div>
            <span
              style={{ "margin-left": "2.4vw", color: "orange" }}
              id="progressStatus"
            ></span>

            <div
              className="tab-pane show active"
              id="contactDetail"
              style={{ "margin-top": "6vh", "margin-left": "3vw" }}
            >
              <h5 className="mb-5 mt-0">
                {languageChosenSite === "EL"
                  ? languageGr.import.uploadxlsConfig
                  : language.import.uploadxlsConfig}
              </h5>
              <div className="row">
                <div className="col-md-12">
                  <div
                    className="mb-0"
                    style={{
                      display: "flex",
                      "flex-direction": "column",
                      "align-items": "center",
                    }}
                  >
                    <a target="_blank" href={ManualConfig}>
                      {languageChosenSite === "EL"
                        ? languageGr.import.configTemplate
                        : language.import.configTemplate}
                    </a>
                    <div className="mb-3 mt-3" style={{ width: "50%" }}>
                      <input
                        className="form-control"
                        type="file"
                        id="input"
                        accept=".xls,.xlsx"
                        style={{ width: "30%!important" }}
                        onChange={(e) => setSelectedFile(e.target.files[0])}
                      />
                    </div>
                    {!loading ? (
                      <ButtonSkeleton />
                    ) : (
                      <Button
                        className="addNewButton"
                        id="button"
                        onClick={uploadConfig}
                      >
                        {languageChosenSite === "EL"
                          ? languageGr.import.convert
                          : language.import.convert}
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div
              className="tab-pane"
              id="jobDetail"
              style={{ "margin-top": "6vh", "margin-left": "3vw" }}
            >
              <h5 className="mb-5 mt-0">
                {languageChosenSite === "EL"
                  ? languageGr.import.uploadxlsExhib
                  : language.import.uploadxlsExhib}
              </h5>
              <div className="row">
                <div className="col-md-12">
                  <div
                    className="mb-0"
                    style={{
                      display: "flex",
                      "flex-direction": "column",
                      "align-items": "center",
                    }}
                  >
                    <a target="_blank" href={ManualExhibit}>
                      {languageChosenSite === "EL"
                        ? languageGr.import.exhibitTemplate
                        : language.import.exhibitTemplate}
                    </a>
                    <div className="mb-3 mt-3" style={{ width: "50%" }}>
                      <input
                        className="form-control"
                        type="file"
                        id="input2"
                        accept=".xls,.xlsx"
                        style={{ width: "30%" }}
                        onChange={(e) => setSelectedFile2(e.target.files[0])}
                      />
                    </div>
                    {!loading ? (
                      <ButtonSkeleton />
                    ) : (
                      <Button
                        className="addNewButton"
                        id="button2"
                        onClick={uploadExhibits}
                      >
                        {languageChosenSite === "EL"
                          ? languageGr.import.convert
                          : language.import.convert}
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div
              className="tab-pane"
              id="finish"
              style={{ "margin-top": "6vh", "margin-left": "3vw" }}
            >
              <h5 className="mb-5 mt-0">
                {languageChosenSite === "EL"
                  ? languageGr.import.uploadxlsMedia
                  : language.import.uploadxlsMedia}
              </h5>
              <div className="row">
                <div className="col-md-12">
                  <div
                    className="mb-4"
                    style={{
                      display: "flex",
                      "flex-direction": "column",
                      "align-items": "center",
                    }}
                  >
                    <div className="mb-3 mt-3" style={{ width: "50%" }}>
                      <input
                        className="form-control"
                        type="file"
                        id="input3"
                        accept=".zip,.rar,.7zip"
                        style={{ width: "30%" }}
                        onChange={(e) => setSelectedFile3(e.target.files[0])}
                      />
                    </div>
                    {!loading ? (
                      <ButtonSkeleton />
                    ) : (
                      <Button
                        className="addNewButton"
                        id="button3"
                        onClick={uploadMedia}
                      >
                        {languageChosenSite === "EL"
                          ? languageGr.import.convert
                          : language.import.convert}
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-3"></div>
    </div>
  );
};

export default ImportComponent;
