import React from "react";
import { useState, useEffect } from "react";
import { language, languageGr } from "../../api/lang";
import {
  SiteApi,
  CategoryApi,
  ExhibitNew,
  MediaApi,
} from "../../api/api.service";
import InputSkeleton from "../UI/InputSkeleton";
import MediaSkeleton from "../UI/MediaSkeleton";
import Select from "react-select";
import classes from "../UI/Edit.module.css";
import manage from "../../images/manage-orange.png";
import { fabric } from "fabric";
import CloseIcon from "@mui/icons-material/Close";
import Text from "./Canvas/Objects/Text";

function Tooltip(props) {
  const currentMarker = props.currentMarker;

  return (
    <div
      style={{
        marginTop: "20vh",
        position: `absolute`,
        top: `${currentMarker.top}px`,
        padding: "0.5%",
        width: "15vw",
        backgroundColor: `rgba(0, 0, 0, 0.7)`,
        color: `white`,
        borderRadius: `5px`,
        left: `${currentMarker.left}px`,
        marginLeft: "10vw",
      }}
    >
      {currentMarker && currentMarker.exhibitName}
    </div>
  );
}

function ExhibitItem(props) {
  const [image, setImage] = useState();

  const item = props.item;

  async function fetchImage() {
    const exhibitImage = await MediaApi.GetByMediaId(item.imagE_DEF);
    setImage(exhibitImage.value);
    setImage(exhibitImage.value);
  }

  useEffect(() => {
    fetchImage();
  }, [image, item]);

  if (image) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "start",
          cursor: "pointer",
          backgroundColor: "#ffffff",
          padding: "1%",
          paddingLeft: "4%",
          borderRadius: "2px",
          boxShadow: "rgb(0 0 0 / 50%) 2px 0px 6px 2px",
          marginTop: "6%",
        }}
      >
        <img
          src={`data:image/png;base64,${image}`}
          style={{
            width: "3vw",
            height: "3vw",
            borderRadius: "200px",
            marginRight: "1%",
          }}
        />
        {item.name}
      </div>
    );
  } else return null;
}

export default function ExhibitMarker(props) {
  const iconPath = props.iconPath;
  const iconColor = props.iconColor;
  const fontSize = props.fontSize;
  const fillColor = props.fillColor;
  const setFontSize = props.setFontSize;
  const [inputLoaded, setInputLoaded] = useState(false);
  const [optionsForSel, setOptionsForSel] = useState([]);
  const [languageChosenSite, setLanguageChosenSite] = useState("");
  const [categoryArray, setCategoryArray] = useState([]);
  const [exhibits, setExhibits] = useState([]);
  let optionsForSelectCategory = [];

  useEffect(() => {
    setLanguageChosenSite(sessionStorage.getItem("languageChosen"));
    getData();
  }, []);

  const changeHead = () => {
    if (sessionStorage.getItem("languageChosen") === "EL") {
      document.querySelector(
        ".pageHead"
      ).innerHTML = `<div style="width: 100%; height:100%; display: inline-flex; justify-content: center;"><h4 style='padding-top: 3.5vh;text-align: center;color: #f7aa47;'><img style="height: 4vh;margin-top:-1vh;" class='fa fa-fw fa-file' src='${manage}' />${languageGr.global.titles.indoor}</h4></div>`;
    } else {
      document.querySelector(
        ".pageHead"
      ).innerHTML = `<div style="width: 100%; height:100%; display: inline-flex; justify-content: center;"><h4 style='padding-top: 3.5vh;text-align: center;color: #f7aa47;'><img style="height: 4vh;margin-top:-1vh;" class='fa fa-fw fa-file' src='${manage}' />${language.global.titles.indoor}</h4></div>`;
    }
  };

  const getData = async () => {
    const sites = await SiteApi.Get();
    const site = sites[0];
    const categories = await CategoryApi.GetCategoriesOfSite(site.siteId);
    changeHead();

    for (const cat of categories) {
      console.log(cat);
      if (typeof cat.parentId === "number") {
        if (cat.categoryId === cat.parentId) {
        }
      } else {
        optionsForSelectCategory.push({
          value: cat.categoryId,
          label: cat.name,
        });
      }
    }
    setCategoryArray(optionsForSelectCategory);

    setInputLoaded(true);
  };

  const onCatChange = async (e) => {
    console.log(e);
    setOptionsForSel(e);
    let objectSum = await ExhibitNew.Get();
    let obj = [];
    obj = objectSum.filter((x) => x.categorY_ID == e.value);
    setExhibits(obj);
  };

  const workpoint = new fabric.Path(iconPath);
  const exhibitLabel = new fabric.Text("000000000000000000000000000000000", {
    top: 0,
    right: 0,
    stroke: iconColor,
    fill: fillColor,
    fontSize: fontSize,
  });

  workpoint.set({
    top: 10,
    left: -20,
    originX: "center",
    originY: "center",
    stroke: iconColor,
    fill: fillColor,
    cornerColor: "black",
  });

  const originalToObject = fabric.Object.prototype.toObject;

  const myAdditional = ["exhibitId", "exhibitName", "iconPath", "iconName"];

  fabric.Object.prototype.toObject = function (additionalProperties) {
    return originalToObject.call(
      this,
      myAdditional.concat(additionalProperties)
    );
  };

  // const [myColor, setMyColor] = useState("#111111");
  // useEffect(() => {
  //   setMyColor(iconColor);
  // }, [iconColor]);

  const [selected, setSelected] = useState(false);

  if (props.canvas) {
    props.canvas.on("selection:created", (e) => {
      if (e.selected[0]._objects) {
        console.log(e);
       props.setSquares(true);
       props.setShowUndo(true);


        setSelected(true);
        // document.getElementById("fontSizeDiv").style.display = "flex";
        // document.getElementById("textExhibit").style.display = "flex";
        // document.getElementById("fillColorDiv").style.display = "flex";
        // document.getElementById("fontFamilyDiv").style.display = "flex";
        props.canvas.getActiveObject().cornerColor = "black";

        props.setIconColor(props.canvas.getActiveObject()._objects[0].stroke);
        props.setIconColor(props.canvas.getActiveObject()._objects[1].stroke);
        props.setFontSize(props.canvas.getActiveObject()._objects[0].fontSize);
        props.setFontSize(props.canvas.getActiveObject()._objects[1].fontSize);
        props.setFillColor(props.canvas.getActiveObject()._objects[0].fill);
        props.setFillColor(props.canvas.getActiveObject()._objects[1].fill);
        props.setExhibitText(props.canvas.getActiveObject()._objects[1].text);
        props.setStrokeWidth(
          props.canvas.getActiveObject()._objects[0].strokeWidth
        );
        props.setStrokeWidth(
          props.canvas.getActiveObject()._objects[1].strokeWidth
        );
        props.setFontFamily(
          props.canvas.getActiveObject()._objects[1].fontFamily
        );
      } else {
        setSelected(false);
        // document.getElementById("fontSizeDiv").style.display = "none";
        // document.getElementById("textExhibit").style.display = "none";
        // document.getElementById("fillColorDiv").style.display = "none";
        // document.getElementById("fontFamilyDiv").style.display = "none";
      }

      //document.getElementById("strokeWidth").style.display = "block";
    });
  }

  if (props.canvas) {
    props.canvas.on("selection:updated", (e) => {
      if (e.selected[0]._objects) {
        props.setSquares(true);

        setSelected(true);

        // document.getElementById("fontSizeDiv").style.display = "flex";
        // document.getElementById("textExhibit").style.display = "flex";
        // document.getElementById("fillColorDiv").style.display = "flex";
        // document.getElementById("fontFamilyDiv").style.display = "flex";
        props.canvas.getActiveObject().cornerColor = "black";

        props.setIconColor(props.canvas.getActiveObject()._objects[0].stroke);
        props.setIconColor(props.canvas.getActiveObject()._objects[1].stroke);
        props.setFontSize(props.canvas.getActiveObject()._objects[0].fontSize);
        props.setFontSize(props.canvas.getActiveObject()._objects[1].fontSize);
        props.setFillColor(props.canvas.getActiveObject()._objects[0].fill);
        props.setFillColor(props.canvas.getActiveObject()._objects[1].fill);
        props.setExhibitText(props.canvas.getActiveObject()._objects[1].text);
        props.setStrokeWidth(
          props.canvas.getActiveObject()._objects[0].strokeWidth
        );
        props.setStrokeWidth(
          props.canvas.getActiveObject()._objects[1].strokeWidth
        );
        props.setFontFamily(
          props.canvas.getActiveObject()._objects[1].fontFamily
        );
        console.log(props.canvas.getActiveObject()._objects[0]);
        console.log(props.canvas.getActiveObject()._objects[1]);
      } else {
        setSelected(false);
        // document.getElementById("fontSizeDiv").style.display = "none";
        // document.getElementById("textExhibit").style.display = "none";
        // document.getElementById("fillColorDiv").style.display = "none";
        // document.getElementById("fontFamilyDiv").style.display = "none";
      }
    });
  }

  useEffect(() => {
    if (selected) {
      props.canvas.getActiveObject()._objects[0].set("stroke", iconColor);
      props.canvas.getActiveObject()._objects[1].set("stroke", iconColor);

      props.canvas.requestRenderAll();

      // let x = props.editorMemo.getActiveObject()
      // console.log("X = ",x._objects[0])
    }
  }, [iconColor]);

  useEffect(() => {
    if (selected) {
      props.canvas
        .getActiveObject()
        ._objects[0].set("path", iconPath.toString());
      console.log(props.canvas.getActiveObject()._objects[0]);

      //props.canvas.requestRenderAll();

      // let x = props.editorMemo.getActiveObject()
      // console.log("X = ",x._objects[0])
    }
  }, [iconPath]);

  useEffect(() => {
    if (selected) {
      props.canvas.getActiveObject()._objects[0].set("fontSize", fontSize);
      props.canvas.getActiveObject()._objects[1].set("fontSize", fontSize);
      props.canvas.requestRenderAll();

      // let x = props.editorMemo.getActiveObject()
      // console.log("X = ",x._objects[0])
    }
  }, [fontSize]);

  useEffect(() => {
    if (selected) {
      props.canvas
        .getActiveObject()
        ._objects[0].set("strokeWidth", props.strokeWidth);
      props.canvas
        .getActiveObject()
        ._objects[1].set("strokeWidth", props.strokeWidth);
      props.canvas.requestRenderAll();
    }
  }, [props.strokeWidth]);

  useEffect(() => {
    if (selected) {
      props.canvas
        .getActiveObject()
        ._objects[1].set("fontFamily", props.fontFamily);
      props.canvas.requestRenderAll();
    }
  }, [props.fontFamily]);

  useEffect(() => {
    if (selected) {
      props.canvas.getActiveObject()._objects[0].set("fill", fillColor);
      props.canvas.getActiveObject()._objects[1].set("fill", fillColor);
      props.canvas.requestRenderAll();

      // let x = props.editorMemo.getActiveObject()
      // console.log("X = ",x._objects[0])
    }
  }, [fillColor]);

  useEffect(() => {
    if (selected) {
      props.canvas.getActiveObject()._objects[1].set("text", props.exhibitText);
      props.canvas.requestRenderAll();

      // let x = props.editorMemo.getActiveObject()
      // console.log("X = ",x._objects[0])
    }
  }, [props.exhibitText]);

  const [currentMarker, setCurrentMarker] = useState();
  var group = new fabric.Group([workpoint, exhibitLabel]);

  // group.on("mouseover", (e) => {
  //   setCurrentMarker(e.target);
  // });
  // group.on("mouseout", () => {
  //   setCurrentMarker(undefined);
  // });

  group.on("selected", () => {
            // setSquares={setSquares}
    props.setSquares(true);
    setSelected(true);

    props.setIconColor(props.canvas.getActiveObject()._objects[0].stroke);
    props.setIconColor(props.canvas.getActiveObject()._objects[1].stroke);
    props.setFontSize(props.canvas.getActiveObject()._objects[0].fontSize);
    props.setFontSize(props.canvas.getActiveObject()._objects[1].fontSize);
    props.setFillColor(props.canvas.getActiveObject()._objects[0].fill);
    props.setFillColor(props.canvas.getActiveObject()._objects[1].fill);
    props.setExhibitText(props.canvas.getActiveObject()._objects[1].text);
    props.setStrokeWidth(
      props.canvas.getActiveObject()._objects[0].strokeWidth
    );
    props.setStrokeWidth(
      props.canvas.getActiveObject()._objects[1].strokeWidth
    );
    props.setFontFamily(props.canvas.getActiveObject()._objects[1].fontFamily);
    props.canvas.getActiveObject().cornerColor = "black";

    console.log(props.canvas.getActiveObject()._objects[0]);
    console.log(props.canvas.getActiveObject()._objects[1]);
  });
  group.on("deselected", () => {
    setSelected(false);
  });
  // exhibitLabel.on("added",()=>{
  //   props.editorMemo.getActiveObject().set("text", "MY TEXT");
  // })

  const onAddPath = (exhibitId, exhibitName, iconPath, iconName) => {
    group.exhibitId = exhibitId;
    group.exhibitName = exhibitName;
    group.iconPath = iconPath;
    group.iconName = iconName;
    group.top = 100;
    group.left = 100;

    group._objects[1].set("text", exhibitName);
    // group._objects[1].left =  "-500";

    props.canvas.add(group);
    //props.editorMemo.add(exhibitLabel);
  };

  return (
    <div>
      <div className="mapEditContainer">
        <div className="mapPropertiesTitle">
          {" "}
          {languageChosenSite == "EL"
            ? languageGr.indoor.catHeading
            : language.indoor.catHeading}
        </div>
        <div className="editor-items-divs-cats">
          <div className="sameLine">
            {/* <a
              className=""
              data-bs-toggle="collapse"
              href="#categoriesDropDown"
              aria-expanded="false"
              aria-controls="categoriesDropDown"
            >
              {languageChosenSite === "EL"
                ? languageGr.global.categoryWords.category
                : language.global.categoryWords.category}
            </a> */}

            {!inputLoaded ? (
              <InputSkeleton />
            ) : (
              <Select
                className={classes.center}
                value={optionsForSel}
                options={
                  props.categoriesByLang.length !== 0
                    ? props.categoriesByLang
                    : categoryArray
                }
                onChange={onCatChange}
                isSearchable
                id="categoriesDropDown"
                defaultValue={
                  languageChosenSite === "EL"
                    ? languageGr.global.categoryWords.catPlaceholder
                    : language.global.categoryWords.catPlaceholder
                }
              />
            )}
          </div>
        </div>
        <div className="editor-items-divs-exhibits">
          <div className="col-md-12" style={{ marginTop: "10%" }}>
            <a
              className="mapPropertiesTab"
              data-bs-toggle="collapse"
              href="#container"
              aria-expanded="false"
              aria-controls="container"
            >
              {languageChosenSite == "EL"
                ? languageGr.indoor.exhibitHeading
                : language.indoor.exhibitHeading}
            </a>
            {exhibits.length > 0 &&
              exhibits.map((item, index) => (
                <div
                  key={index}
                  className="categoryBox"
                  id="container"
                  onClick={() => {
                    onAddPath(item.objectS_ID, item.name, iconPath, "Exhibit");
                    // showName(item.name)
                  }}
                >
                  <ExhibitItem item={item} />
                </div>
              ))}
          </div>
        </div>
      </div>
      {currentMarker && <Tooltip currentMarker={currentMarker} />}
    </div>
  );
}
