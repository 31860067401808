import React, { useEffect, useState } from 'react'
import { useParams } from "react-router-dom";

import { ExhibitsCard } from '../Cards/Cards';

//Language values
import Greek from "../Languages/Greek.json";
import English from "../Languages/English.json";

import {
    ExhibitNew,
    MediaApi,
} from "../../api/api.service";

function CategoryPage(props) {
    const [data, setData] = useState([]);
    const [imageValue, setImageValue] = useState([]);
    const [categoryName, setCategoryName] = useState([]);
    const [textLanguage, setTextLanguage] = useState(Greek);
    const _ID = useParams();

    useEffect(() => {
        getData();
        setLanguage();
    }, [])

    const getData = async () => {
        const exhibits = await ExhibitNew.GetByCategoryId(_ID.categoryId)
        setCategoryName(exhibits[0].category)
        setData(exhibits)

        for (let i in exhibits) {
            const media = await MediaApi.GetByMediaId(exhibits[i].imagE_DEF);
            setImageValue((prevState) => [
                ...prevState,
                (exhibits[i]["imageValue"] = media.value),
            ]);
        }
    }

    const setLanguage = () => {
        sessionStorage.languageChosen === "EN"
            ? setTextLanguage(English)
            : setTextLanguage(Greek);
    };

    return (
        <div>
            <h1 className='text-center p-4'>{categoryName}</h1>
            <ExhibitsCard data={data} imageValue={imageValue} textLanguage={textLanguage} />
        </div>
    )
}

export default CategoryPage