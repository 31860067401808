import { useState, useEffect } from "react";
import {
  ExhibitNew,
  CategoryApi,
  SiteApi,
  MediaApi,
  ExhibitionApi,
  LanguageApi,
  ObjectApi,
  TagsApi
} from "../../api/api.service";

//Resize Images
import Resizer from "react-image-file-resizer";


import classes from "../UI/Edit.module.css";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import MediaSkeleton from "../UI/MediaSkeleton";
import InputSkeleton from "../UI/InputSkeleton";
// import { authService } from "../../api/auth.service";
import * as FaIcons from "react-icons/fa";
import Swal from "sweetalert2";
import { language, languageGr } from "../../api/lang";

import $ from "jquery";

const Step1 = ({ setExhibitData, languageChosenSite, userRole, siteIdentity }) => {
  console.log(languageChosenSite);
  //const [idClicked, setIdClicked] = useState(targetId);
  const [siteId, setSiteId] = useState(1);
  //const [siteIdentity, setSiteIdentity] = useState(1);
  const [step2, setStep2] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [optionsForSel, setOptionsForSel] = useState([]);
  const [nameInputField, setNameInputField] = useState("");
  const [categoryArray, setCategoryArray] = useState([]);
  const [optionsForSelSub, setOptionsForSelSub] = useState([]);
  const [SubcategoryArray, setSubcategoryArray] = useState([]);
  const [profileImage, setProfileImage] = useState("");
  const [updatedImage, setUpdatedImage] = useState(null);
  const [serialNumber, setSerialNumber] = useState("");
  const [exhibitionChosen, setExhibitionChosen] = useState([]);
  const [exhibitionArray, setExhibitionArray] = useState([]);

  const [sumOfObjectsIds, setSumOfObjectsIds] = useState([]);
  const [languageId, setLanguageId] = useState(0);
  const [dataObject, setDataObject] = useState({});
  const [tagChosen, setTagChosen] = useState([]);
  const [tagsArray, setTagsArray] = useState([]);
  // const [userRole, setUserRole] = useState("");

  useEffect(() => {
    // if (typeof targetId === "number") {
    //   setIdClicked(targetId);
    // }

    getData();
    //updateSub();
  }, []);

  const animatedComponents = makeAnimated();

  const optionsForSelectCategory = [];
  const optionsForSelectSubcategory = [];
  const optionsForSelectSubcategoryFinal = [];
  const optionsForExhibtions = [];
  const chosenExhibitions = [];
  const optionsForTags = [];
  const chosenTags = [];

  const endLoading = () => {
    setImageLoaded(true);
  };

  const uploadUpdatePhoto = () => {
    const image = document.getElementById("updateImage");
    const canvas = document.createElement("canvas"),
      ctx = canvas.getContext("2d");
    canvas.height = image.naturalHeight;
    canvas.width = image.naturalWidth;
    ctx.drawImage(image, 0, 0);
    const uri = canvas.toDataURL("image/png"),
      b64 = uri.replace(/^data:image.+;base64,/, "");
    document.getElementById("imageUpdateString").innerText = b64;
  };

  $("#modalUpdateCategories").on("change", async function (e) {
    console.log(e.target);
    document.getElementById("modalUpdateSubCategories").innerHTML = "";
    let parentCat = document.getElementById("modalUpdateCategories").value;

    let sub = await CategoryApi.GetSubCategoriesOfSite(siteId, parentCat);

    if (sub.length > 0) {
      for (let i = 0; i < sub.length; i++) {
        document.getElementById(
          "modalUpdateSubCategories"
        ).innerHTML += `<option value="${sub[i].categoryId}">${sub[i].name}</option>`;
      }
    }
    if (sub.length === 0) {
      document.getElementById("modalUpdateSubCategories").innerHTML = "";
    }
  });

  let profIm;

  const getData = async () => {
    const site = await SiteApi.Get();

    // const siteIdentit = site[0].insights;
    // setSiteIdentity(siteIdentit);
    const sitesId = site[0].siteId;
    setSiteId(sitesId);

    //console.log(parseInt(targetId));
    //setIdClicked(targetId);
    //setIdClicked(targetId);
    const languages = await LanguageApi.Get();
    // const english = languages.find(l => l.initials === "EN");
    // const greek = languages.find(l => l.initials === "EL");
    languageChosenSite === "EL" ? setLanguageId(1) : setLanguageId(2);
    //setLanguageId(english.languageId);

    //const data = await ExhibitNew.GetAllValuesOfObject(idClicked);
    //setDataObject(data);
    //console.log(data);
    //const exhibitObjectCode = data.objectS_CODE;
    //setSerialNumber(exhibitObjectCode);
    const categories = await CategoryApi.GetCategoriesOfSite(siteId);
    console.log(categories);

    //const categoryByExhibit = await CategoryApi.GetById(data.categorY_ID);
    //console.log(categories);

    for (const cat of categories) {
      const sub = await CategoryApi.GetSubCategoriesOfSite(
        siteId,
        cat.categoryId
      );
      console.log(sub);
      //   if (sub.length > 0) {
      //     for (const s of sub) {
      //       optionsForSelectSubcategory.push({
      //         value: s.categoryId,
      //         label: s.name,
      //       });
      //     }
      //   }

      if (typeof cat.parentId === "number") {
        // if(typeof categoryByExhibit.parentId === "number") {
        //optionsForSelectSubcategory.push({ value: cat.categoryId, label: cat.name });
        if (cat.categoryId === cat.parentId) {
        }
        //}
      } else {
        optionsForSelectCategory.push({
          value: cat.categoryId,
          label: cat.name,
        });
      }
    }

    setCategoryArray(optionsForSelectCategory);

    setSubcategoryArray(optionsForSelectSubcategory);
    //updateSub();
    //const exhibitName = data.name;

    // document.getElementById("exhibitName").textContent =
    //   `${exhibitName}`;

    //console.log(categoryByExhibit);
    // const categoryObject = {
    //   value: categoryByExhibit.categoryId,
    //   label: categoryByExhibit.name,
    // };

    // if (categoryByExhibit.parentId != null) {
    //   //let subCat = await CategoryApi.GetSubCategoriesOfSite(siteId, categoryByExhibit.parentId);
    //   const parentCategory = await CategoryApi.GetById(
    //     categoryByExhibit.parentId
    //   );

    //   setOptionsForSel([
    //     { value: parentCategory.categoryId, label: parentCategory.name },
    //   ]);
    //   setOptionsForSelSub([categoryObject]);
    // } else {
    //   setOptionsForSelSub([]);
    //   setSubcategoryArray([]);
    //   setOptionsForSel([categoryObject]);
    // }


    const tags = await TagsApi.Get();
    //console.log(exhibitions);
    for (let tag of tags) {
      

        optionsForTags.push({
          value: tag.tagId,
          label: tag.tagName,
        });
    
    }
    // if(data.tags!=null) {
    //   for(let tag of data.tags) {
    //     chosenTags.push(tag);
    //   }
    // }
    

    setTagsArray(optionsForTags);
    //setExhibitionChosen(chosenExhibitions);
    //setTagChosen(chosenTags);




    const exhibitImage = await MediaApi.GetByMediaId(1);
    setProfileImage(exhibitImage.value);

    const exhibitions = await ExhibitionApi.Get();
    console.log(exhibitions);
    for (let exhib of exhibitions) {
      optionsForExhibtions.push({
        value: exhib.exhibitionId,
        label: exhib.name,
      });
    }
    setExhibitionArray(optionsForExhibtions);

    //const SubcategoryObject = {value: data.categorY_ID, label: data.category}

    //setOptionsForSelSub([SubcategoryObject]);

    endLoading();
  };

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        600,
        600,
        "JPEG",
        50,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
  });

  const onCatChange = async (e) => {
    console.log(e);
    //console.log(optionsForSel);
    //setSubcategoryArray([]);
    setOptionsForSel(e);
    const subb = await CategoryApi.GetSubCategoriesOfSite(siteId, e.value);

    console.log(subb);
    let subArr = [];
    for (let a of subb) {
      subArr.push({
        label: a.name,
        value: a.categoryId,
      });
    }
    setSubcategoryArray(subArr);
    setOptionsForSelSub(null);
  };

  const onSubOptionChange = (e) => {
    console.log(e);
    setOptionsForSelSub(e);
  };

  async function uploadPhoto() {}

  const triggerClick = () => {
    document.getElementById("file-input-edit").click();
  };

  const fileInputEdit = async (e) => {
    console.log(e.target.files);
    for (let i = 0; i < e.target.files.length; i++) {
      e.preventDefault();
      e.stopPropagation();
      const file = e.target.files[i];
      const img = document.getElementById("updateImage");

      // const toBase64 = (files) =>
      //   new Promise(async (resolve, reject) => {
      //     const reader = new FileReader();
      //     reader.readAsDataURL(files);
      //     //console.log(reader.result);
      //     img.src = reader.result;
      //     reader.onload = () => resolve(reader.result);
      //     reader.onloadend = function () {
      //       img.src = reader.result;
      //     };

      //     reader.onerror = (error) => reject(error);
      //   });

      //const uri = await toBase64(file);
      const uri = await resizeFile(file);


      const b64 = await uri.replace(/^data:image.+;base64,/, "");

      document.getElementById("imageUpdateString").innerText = b64;

      setProfileImage(b64);
      
    }
  };

  // const fileInputEdit = async (e) => {
  //   console.log("blabla");

  //   for (let i = 0; i < e.target.files.length; i++) {
  //     let file = e.target.files[i];

  //     if (file.size > 1000000) {
  //       alert(
  //         `Media with name ${file.name} is not loaded. Media size exceeds the size limit. Please load images with maximum size of 1Mb`
  //       );
  //       // swal({
  //       //     title: language.media.invalidAction,
  //       //     text: `Media with name ${file.name} is not loaded.${language.content.fileSize.image}`,
  //       //     icon: "warning",
  //       //     button: "OK",
  //       // })
  //       continue;
  //     } else {
  //       let test2;
  //       let img = document.getElementById("updateImage");
  //       // let reader = new FileReader();
  //       // console.log(reader);
  //       // reader.onload = function () {
  //       //   img.src = reader.result;
  //       //   test2 = reader.result;
  //       // };

  //       const toBase64 = files => new Promise(async (resolve, reject) => {
  //         const reader = new FileReader();
  //         reader.readAsDataURL(files);
  //         console.log(reader.result);
  //         img.src=reader.result;
  //         reader.onload = () => resolve(reader.result);
  //         reader.onloadend = function () {
  //           img.src = reader.result;

  //         };

  //         reader.onerror = error => reject(error);
  //       });

  //       const uri = await toBase64(file);

  //       const b64 = await uri.replace(/^data:image.+;base64,/, '');

  //       // reader.readAsDataURL(file);
  //       // const canvas = document.createElement("canvas"),
  //       //   ctx = canvas.getContext("2d");
  //       // canvas.height = img.naturalHeight;

  //       // canvas.width = img.naturalWidth;
  //       // ctx.drawImage(img, 0, 0);

  //       //const uri = canvas.toDataURL("image/png"),
  //       //b64 = reader.result.replace(/^data:image.+;base64,/, "");
  //       console.log(test2);

  //       console.log(b64);
  //       document.getElementById("imageUpdateString").innerText = b64;

  //       //const english = languages.find((l) => l.initials === "EN");
  //       const image = b64;

  //       console.log(image);
  //       const image1 = await MediaApi.GetByMediaId(1);
  //       //console.log(image1);
  //      // image1.mediA_ID = catobje.image_def;
  //       let media;
  //       if (image === "data:,") {
  //         //alert("Image update cancelled. Please choose a default image");
  //       } else {
  //         if (image1.value != image) {
  //           //await MediaApi.MediaDelete(catobje.image_def);
  //           media = {
  //             Name: catobje.name,
  //             Description: "",
  //             TypeId: 10,
  //             LanguageId: parseInt(languageId),
  //             Value: image,
  //             ObjectValId: 0,
  //             RefPath: catobje.name, //kai gia ola
  //             //"ObjectValId": addedObj.objectId
  //           };

  //           if (media.Value) {
  //             media = await MediaApi.Create(media);
  //             console.log("media created");
  //             console.log(media);
  //           } else {
  //             media = image1;
  //           }
  //         } else {
  //           media = image1;
  //           console.log("here");
  //         }
  //         console.log(media);
  //         //catobje.image_def = media.mediA_ID;
  //         console.log(media.mediA_ID);

  //         const category = {
  //           CategoryId: catobje.categoryId,
  //           image_def: media.mediA_ID,
  //           ParentId: catobje.parentId,
  //           Mobile: true,
  //           Index: 1,
  //         };
  //         console.log(catobje);
  //         await CategoryApi.Update(category);
  //       }
  //       //image saving
  //       props.getData();
  //       // Swal.fire({
  //       //   title: "Success",
  //       //   text: `Action was successfully done`,
  //       //   icon: "success",
  //       // });
  //       //updatePhoto(targetId);
  //       //setChangedImage(false);

  //       //$("#file-input").after(img);
  //     }
  //   }
  // };

  const onPrevious = () => {
    console.log("prev");
  };

  const onForward = async () => {
    console.log(userRole);
    if (userRole == "Viewer") {
      Swal.fire({
        title: language.media.invalidAction,
        text: language.global.userRole,
        icon: "warning",
        button: "OK",
      });
      return;
    }

    const dots = document.querySelectorAll(".Wizard_dot__E6e-z");
    const dotsArr = [...dots];
    console.log(dotsArr);
    console.log(serialNumber);
    console.log(optionsForSel);
    console.log(optionsForSelSub);
    console.log(exhibitionChosen);
    let greekExhibits = [];
    let checkedCode = [];
    let exh = await ExhibitNew.Get();
    console.log(exh);
    for (let e of exh) {
      //if (e.languageId = greek.languageId) {
      //    greekExhibits.push(e);
      //}
      greekExhibits.push(e.objectS_CODE);
    }

    console.log(greekExhibits);

    for (let e of greekExhibits) {
      if (e == serialNumber) {
        checkedCode.push(e);
      }
    }

    if (serialNumber === "") {
      Swal.fire({
        title:
          languageChosenSite === "EL"
            ? languageGr.media.invalidAction
            : language.media.invalidAction,
        text:
          languageChosenSite === "EL"
            ? languageGr.new.exhibitCodeLength
            : language.new.exhibitCodeLength,
        icon: "warning",
        button: "OK",
      });

      //Swal.fire(languageChosenSite === "EN" ? (, ) : (languageGr.media.invalidAction, languageGr.new.exhibitCodeLength), "warning");
      return;
    } else if (
      serialNumber.length == 0 ||
      parseInt(serialNumber) < 100 ||
      parseInt(serialNumber) > 100000
    ) {
      // Swal.fire(languageChosenSite === "EN" ? (language.media.invalidAction, language.new.exhibitCodeLength) : (languageGr.media.invalidAction, languageGr.new.exhibitCodeLength), "warning");
      Swal.fire({
        title:
          languageChosenSite === "EL"
            ? languageGr.media.invalidAction
            : language.media.invalidAction,
        text:
          languageChosenSite === "EL"
            ? languageGr.new.exhibitCodeLength
            : language.new.exhibitCodeLength,
        icon: "warning",
        button: "OK",
      });
      return;
    } else if (checkedCode.length > 0) {
      Swal.fire({
        title:
          languageChosenSite === "EL"
            ? languageGr.media.invalidAction
            : language.media.invalidAction,
        text:
          languageChosenSite === "EL"
            ? languageGr.new.exhibitCodeAlert
            : language.new.exhibitCodeAlert,
        icon: "warning",
        button: "OK",
      });
      return;
    } else if (nameInputField === "") {
      //Swal.fire(languageChosenSite === "EN" ? (language.media.invalidAction, language.new.exhibName) : (languageGr.media.invalidAction, languageGr.new.exhibName), "warning");
      Swal.fire({
        title:
          languageChosenSite === "EL"
            ? languageGr.media.invalidAction
            : language.media.invalidAction,
        text:
          languageChosenSite === "EL"
            ? languageGr.new.exhibName
            : language.new.exhibName,
        icon: "warning",
        button: "OK",
      });
      return;
    } else if (optionsForSel.length === 0) {
      //Swal.fire(languageChosenSite === "EN" ? (language.media.invalidAction, language.new.categoryAlert) : (languageGr.media.invalidAction, languageGr.new.categoryAlert), "warning");
      Swal.fire({
        title:
          languageChosenSite === "EL"
            ? languageGr.media.invalidAction
            : language.media.invalidAction,
        text:
          languageChosenSite === "EL"
            ? languageGr.new.categoryAlert
            : language.new.categoryAlert,
        icon: "warning",
        button: "OK",
      });
    }  else {
      await saveObject();
      dotsArr[1].click();
    }
  };

  async function saveObject() {
    //var claims = await authService.getUserAsync();
    // if (claims.claims.role === "Viewer") {
    //     //alert(language.global.userRole);
    //     swal({
    //         title: language.media.invalidAction,
    //         text: language.global.userRole,
    //         icon: "warning",
    //         button: "OK",

    //     })
    // } else {

    setImageLoaded(false);

    // if (!Number.isInteger(+code)) {
    //     swal({
    //         title: language.media.invalidAction,
    //         text: 'Παρακαλώ επιλέξτε ακέραιο αριθμό για κωδικό εκθέματος',
    //         icon: "warning",
    //         button: "OK",

    //     });
    //} else {

    console.log(serialNumber);

    //const lang = await LanguageApi.Get();
    //var greek = lang.find(l => l.initials === language.global.langInit);

    //for (let exhibit of greekExhibits) {
    //    if (exhibit.code === code) {
    //        //alert(language.new.exhibitCodeAlert);
    //        console.log("same code found!!");
    //        swal({
    //            title: "Invalid action",
    //            text: language.new.exhibitCodeAlert,
    //            icon: "warning",
    //            button: "OK",

    //        })
    //        //location.reload();
    //        break;

    //    }
    //}

    //nameInputField = nameInputField.trim();
    console.log(nameInputField);

    if (nameInputField === "") {
      // alert(language.new.exhibName);
      Swal.fire({
        title: language.media.invalidAction,
        text: language.new.exhibName,
        icon: "warning",
        button: "OK",
      });
    } else {
      if (
        serialNumber.length == 0 ||
        parseInt(serialNumber) < 100 ||
        parseInt(serialNumber) > 100000
      ) {
        //alert(language.new.exhibitCodeLength);

        Swal.fire(
          language.media.invalidAction,
          language.new.exhibitCodeLength,
          "warning"
        );
      } else {
        if (optionsForSel.label === "") {
          //alert(language.new.categoryAlert);
          // Swal.fire({
          //     title: language.media.invalidAction,
          //     text: language.new.categoryAlert,
          //     icon: "warning",
          //     button: "OK",
          // })
          Swal.fire(
            language.media.invalidAction,
            language.new.categoryAlert,
            "warning"
          );
        } else {
          // document.getElementById("saveObject").disabled = true;

          // document.getElementById("saveObject").style.backgroundColor = 'grey';
          //document.getElementById("objectName").readOnly = true;
          //document.getElementById("objectSerialNumber").readOnly = true;
          //document.getElementById("modalCategories").disabled = true;
          //var form = document.getElementById(`contentForm`);

          //console.log(form);
          //if (form.checkValidity() === false) {
          //    event.preventDefault();
          //    event.stopPropagation();
          //    form.classList.add('was-validated');
          //}
          //else {
          //document.getElementById("editContent").style.display = "block";
          // var languages = await LanguageApi.Get();
          // var english = languages.find(l => l.initials === language.global.langInit)
          //if (!english) {
          //alert("Add English Language First");
          //} else {
          // var name = document.getElementById("objectName").value;
          // name = name.trim();
          // var serialNu = document.getElementById("objectSerialNumber").value;

          // var languageId = english.languageId;

          const index = document.getElementById("objectUpdateIndex").value;

          const object = {
            Name: nameInputField,
            Description: "",
            Image: 1,
            Code: serialNumber,
            Index: parseInt(index),
            CriticalRate: false,
            translations: [],
            Latitude: 10,
            Longitude: 10,
          };
          object.translations.push({
            LanguageId: parseInt(languageId),
            Name: nameInputField,
            Description: "",
          });
          const addedObj = await ObjectApi.Create(object);

          console.log(addedObj);
          if (addedObj.objectId) {
            const categoryId = optionsForSel.value;

            console.log(categoryId);
            console.log(optionsForSelSub);
            if (optionsForSelSub != null) {
              const subcategoryId = optionsForSelSub.value;
              await ObjectApi.AddToCategory(
                addedObj.objectId,
                parseInt(subcategoryId)
              );
            } else {
              await ObjectApi.AddToCategory(
                addedObj.objectId,
                parseInt(categoryId)
              );
            }
            //var addedObjCat = await ObjectApi.AddToCategory(addedObj.objectId, parseInt(categoryId));
            //console.log(addedObjCat);
            //var options = document.getElementById('routesMultiple').selectedOptions;
            //var values = Array.from(options).map(({ value }) => value);
            const values = exhibitionChosen;
            console.log(values);
            for (let selectedId of values) {
              let addedObjToExhibition = await ObjectApi.AddToExhibition(
                addedObj.objectId,
                parseInt(selectedId.value)
              );
            }

            //////////////////// Tags Update ///////////////////
            const tagValues = tagChosen;
            console.log(tagValues);
            for (let selectedId of tagValues) {
              let addedTagsToObj = await ExhibitNew.AddTagToExhibit(
                addedObj.objectId,
                parseInt(selectedId.value)
              );
            }

            //var tags = document.getElementById('tagUpdate').selectedOptions;
            //var valuesOfTags = Array.from(tags).map(({ value }) => value);
            //for (let selectedTag of valuesOfTags) {
            //    console.log(addedObj)
            //    console.log(selectedTag);

            //    await ObjectApiLoc.addToTag(addedObj.objectId, parseInt(selectedTag));

            //}

            // select.disable();
            /*document.getElementById('routesMultiple').disabled = "true";*/
          }

          //image
          //uploadPhoto();
          const image = document.getElementById("imageUpdateString").innerText;
          console.log(image);
          if (image === "") {
          } else {
            const media = {
              Name: nameInputField,
              Description: "",
              TypeId: 10,
              LanguageId: parseInt(languageId),
              Value: image,
              ObjectValId: 0,
              RefPath: nameInputField,
            };
            const mediaCreated = await MediaApi.Create(media);
            console.log(mediaCreated);

            if (mediaCreated !== null) {
              console.log("done");
              //const object = { ExhibitId: dataObject.objectS_ID, Code: serialNumber, Image: typeof profImage !== "undefined" ? profImage.mediA_ID :imageAttached.value , Index: 0, CriticalRate: false, Latitude: dataObject.latitude, Longitude: dataObject.longitude }

              const object2 = {
                ExhibitId: addedObj.objectId,
                Image: parseInt(mediaCreated.mediA_ID),
                Code: serialNumber,
                Index: parseInt(index),
                CriticalRate: false,
                latitude: 10,
                longitude: 10,
              };
              const updateImage = await ObjectApi.Update(object2);
              console.log(object2);
              console.log(updateImage);
            }
          }

          ///////////////////////////////////////////////////EXHIBITIONS/////////////////////////////////////////

          const insertObject = {
            ExhibitId: parseInt(addedObj.objectId),
            LanguageId: languageId,
            PressId: 1,
            FieldId: 0,
            Status: false,
            ShortValue: nameInputField,
            LongValue: nameInputField,
            Mobile: true,
            Url: null,
          };

          console.log(insertObject);
          const insertObjectVal = await ObjectApi.Insert(insertObject);

          console.log(insertObjectVal);
          console.log(addedObj);

          //=================================================================================================================================================
          //$('#tab2').tab('show');
          //const btn = document.getElementsByClassName('btnDisable')
          //console.log(btn)
          //for (var i = 0; i < btn.length; i++) {
          //    btn[i].setAttribute("disabled", "");
          //}

          //========================================================================================================================================================================

          //document.getElementById("idTab2").innerHTML += ` <a class="nav-link" id="tab2" href="#tab_default_2" role="tab" data-toggle="tab"><img class="navPhoto" src="../../../images/xenagos/translation-grren.png" />${language.content.tabs.tab2}</a>`;

          //document.getElementById("tab3").remove();
          //document.getElementById("idTab3").innerHTML += ` <a class="nav-link" id="tab3" href="#tab_default_3" role="tab" data-toggle="tab">${language.content.tabs.tab3}</a>`;

          //document.getElementById("tab4").remove();
          //document.getElementById("idTab4").innerHTML += ` <a class="nav-link" id="tab4" href="#tab_default_4" role="tab" data-toggle="tab">${language.content.tabs.tab4}</a>`;

          //document.getElementById("tab5").remove();
          //document.getElementById("idTab5").innerHTML += ` <a class="nav-link" id="tab5" href="#tab_default_5" role="tab" data-toggle="tab">${language.content.tabs.tab5}</a>`;

          //var sites = await SiteApi.Get();
          //var site = sites[0];
          /*var categories = await CategoryApi.GetCategoriesOfSite(site.siteId);*/

          //// updateModalCategoryDrop(categories);
          //var contents = [];
          //for (var i = 0; i < categories.length; i++) {
          //    var catObj = await ObjectApi.GetByCategoryId(categories[i].categoryId);
          //    for (var j = 0; j < catObj.length; j++) {
          //        contents.push(catObj[j]);
          //    }
          //}

          const exhibit = await ObjectApi.GetByTranslation(addedObj.objectId);
          console.log(exhibit);
          const [exhibitChildCat] = exhibit.slice(-1);
          console.log(exhibitChildCat);
          setExhibitData(exhibitChildCat);
          //await tab2(insertObjectVal);
          setImageLoaded(true);

          //setStep2(true);

          //}
        } //category check
      } //serial number check
    } //name check
    //code check
    //} // integer check
    //}//user role check
  } // end of saveObject

  //   const setInstance = SW => updateState({
  //     ...state,
  //     SW,
  //   });

  return (
    <div
      role="tabpanel"
      className="tab-pane active container"
      style={{ "margin-top": "3vh" }}
      id="tab_default_1"
    >
      <div className="row" id="freeze">
        <div className="col-md-5" style={{}}>
          <div id="photoPlaceHolder">
            {!imageLoaded ? (
              <MediaSkeleton />
            ) : (
              <img
                style={{borderRadius: 18}}
                id="updateImage"
                src={profileImage && "data:image/png;base64, " + profileImage}
                loading="lazy"
                alt=""
              />
            )}
            <button
              id="updatePhoto"
              style={{ "margin-top": "0.5vh", "margin-bottom": "1.5vh" }}
              className="btn btn-success xenagosButton addNewButton"
              onClick={triggerClick}
            >
              {languageChosenSite === "EL"
                ? languageGr.global.categoryWords.uploadImage
                : language.global.categoryWords.uploadImage}
            </button>
            <input
              id="file-input-edit"
              type="file"
              name="name"
              onChange={fileInputEdit}
              style={{ display: "none" }}
            />
          </div>

          {/* <button
            className="btn btn-success addMedia"
            id="addMediaDefault"
            //style={{}}
          >
            Add Default Image
          </button> */}
        </div>
        <div className="col-md-6 offset-1" style={{ "margin-left": "4.5%", "margin-top": "-2%" }}>
          <div className="" style={{ marginTop: 15 }}>
            <label className={classes.label}>
              {languageChosenSite === "EL"
                ? languageGr.global.editWords.serialNumber
                : language.global.editWords.serialNumber}
              :
            </label>
            {!imageLoaded ? (
              <InputSkeleton />
            ) : (
              <input
                id="objectUpdateSerialNumber"
                className=""
                placeholder={
                  languageChosenSite === "EL"
                    ? languageGr.global.editWords.serialPlaceholder
                    : language.global.editWords.serialPlaceholder
                }
                style={{ "text-align": "center" }}
                onChange={(e) => setSerialNumber(e.target.value)}
                //defaultValue={serialNumber}
                type="number"
                min="100"
                max="100000"
              />
            )}
          </div>
          <br />

          <div
            className="sameLine"
            style={{ "margin-bottom": "1.5vh", "margin-top": "-1vh" }}
          >
            <label className={classes.label}>
              {languageChosenSite === "EL"
                ? languageGr.global.editWords.name
                : language.global.editWords.name}
              :
            </label>
            {!imageLoaded ? (
              <InputSkeleton />
            ) : (
              <input
                id="objectUpdateSerialNumber"
                className=""
                placeholder={
                  languageChosenSite === "EL"
                    ? languageGr.global.editWords.namePlaceholder
                    : language.global.editWords.namePlaceholder
                }
                style={{ "text-align": "center" }}
                onChange={(e) => setNameInputField(e.target.value)}
                value={nameInputField}
                //defaultValue={serialNumber}
                type="text"
              />
            )}
          </div>


          {/* <div className="sameLine">
            <label className={classes.label}>Tags:</label>
            <br />
            <div id="tagDiv">
              <select id="tagUpdate">
                <option value="all">All</option>
              </select>
            </div>
          </div>
          <br /> */}
       
          <div className="sameLine">
            <label className={classes.label}>
              {languageChosenSite === "EL"
                ? languageGr.global.editWords.tags
                : language.global.editWords.tags}
            </label>
            <br />
            {!imageLoaded ? (
              <InputSkeleton />
            ) : (
              <Select
                className={classes.center}
                options={tagsArray}
                isSearchable
                onChange={setTagChosen}
                //defaultValue={tagChosen}
                components={animatedComponents}
                isMulti
              />
            )}
          </div>
              <br/>
          <div className="sameLine">
            <label className={classes.label}>
              {languageChosenSite === "EL"
                ? languageGr.global.categoryWords.category
                : language.global.categoryWords.category}
              :
            </label>
            <br />
            {/* <div id="catDest">
              <select id="modalUpdateCategories">
                <option value="all">All</option>
              </select> value={optionsForSelSub}
            </div> */}
            {!imageLoaded ? (
              <InputSkeleton />
            ) : (
              <Select
                className={classes.center}
                value={optionsForSel}
                options={categoryArray}
                onChange={onCatChange}
                isSearchable
                id="modalUpdateCategories"
                defaultValue={
                  languageChosenSite === "EL"
                    ? languageGr.global.categoryWords.catPlaceholder
                    : language.global.categoryWords.catPlaceholder
                }
              />
            )}
          </div>
          <br />
          <div className="sameLine">
            <label id="subLabel" className={classes.label}>
              {languageChosenSite === "EL"
                ? languageGr.global.categoryWords.subCategory
                : language.global.categoryWords.subCategory}
              :
            </label>
            <br />
            {!imageLoaded ? (
              <InputSkeleton />
            ) : (
              <Select
                className={classes.center}
                value={optionsForSelSub}
                options={SubcategoryArray}
                onChange={onSubOptionChange}
                isSearchable
                id="modalUpdateSubCategories"
              />
            )}
          </div>
          <br />
          {siteIdentity == 2 && <div className="sameLine">
            <label className={classes.label}>
              {languageChosenSite === "EL"
                ? languageGr.global.editWords.exhibitions
                : language.global.editWords.exhibitions}
              :
            </label>
            <br />
            {!imageLoaded ? (
              <InputSkeleton />
            ) : (
              <Select
                className={classes.center}
                options={exhibitionArray}
                isSearchable
                onChange={setExhibitionChosen}
                //defaultValue={exhibitionChosen}
                components={animatedComponents}
                isMulti
              />
            )}
          </div>}

          <label style={{ visibility: "hidden" }}>Index:</label>
          <input
            style={{ visibility: "hidden" }}
            id="objectUpdateIndex"
            type="number"
            value="0"
          />
          <br />

          <input
            id="imageUpdateString"
            type="text"
            name="name"
            value=""
            style={{ display: "none" }}
          />
        </div>
      </div>
      <div
        className="modal_footer even"
        style={{
          display: "flex",
          "flex-direction": "row",
          "justify-content": "space-evenly",
        }}
      >
        {/* <div style={{ "background": "#1c4325", "border-radius": "10px" }}> */}
        <button
          style={{
            "border-radius": "10px",
            color: "white",
            background: "#1c4325",
          }}
          onClick={onPrevious}
          disabled
          className="backwards btn"
        >
          <FaIcons.FaChevronLeft />{" "}
          {languageChosenSite === "EL"
            ? languageGr.global.previous
            : language.global.previous}
        </button>
        {/* </div> */}
        {/* <div style={{ "background": "#1c4325", "border-radius": "10px" }}> */}
        <button
          style={{
            "border-radius": "10px",
            color: "white",
            background: "#1c4325",
          }}
          onClick={onForward}
          className="forward btn"
        >
          {languageChosenSite === "EL"
            ? languageGr.global.next
            : language.global.next}{" "}
          <FaIcons.FaChevronRight />
        </button>
        {/* </div> */}
      </div>
    </div>
  );
};

export default Step1;
