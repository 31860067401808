import React, { useState, useEffect } from "react";

// API
import { CategoryApi, MediaApi } from "../../api/api.service";

//Resize Images
import Resizer from "react-image-file-resizer";

// Components
import InputSkeleton from "./InputSkeleton";
import BundledEditor from "../../BundledEditor";

//dynamic language
import { language, languageGr } from "../../api/lang";

// Swal alert
import Swal from "sweetalert2";


import $ from "jquery";
import { Spinner } from "react-bootstrap";
import { authService } from "../../api/auth.service.js";
import xenagos from "../../images/default.jpg";
import england from "../../images/Languages/great-britain-circular.png";
import greece from "../../images/Languages/greece-circular.png";
import spain from "../../images/Languages/spain2-circular.png";
import germany from "../../images/Languages/germany-circular.png";


// Bootstrap
import {
  Button,
  Modal,
  Row,
  Col,
  Tab,
  Form,
  Nav,
  Image,
  ButtonGroup,
} from "react-bootstrap";

// CSS
import "./Modal.css";
// FaIcons
import * as FaIcons from "react-icons/fa";

const ModalParent = (props) => {
  const [languages, setLanguages] = useState(props.languages);
  const [targetId, setTargetId] = useState(props.targetId);
  const [categories, setCategories] = useState([]);
  const [parentCategory, setParentCategory] = useState(null);
  const [currentCategory, setCurrentCategory] = useState([]);
  const [allCategoires, setAllCategories] = useState([]);
  const [imageCategory, setImageCategory] = useState([]);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [defaultImage, setDefaultImage] = useState("");
  const [allParentCategories, setAllParentCategories] = useState([]);
  const [languageChosenSite, setLanguageChosenSite] = useState("");

  useEffect(() => {
    getData(targetId);
  }, [targetId, parentCategory]);

  const getData = async (id) => {
    setLanguageChosenSite(sessionStorage.getItem("languageChosen"));

    

    const allCat = props.data;
    updateLangFilter(props.languages);

    let arr = [];
    allCat.map((item) => {
      arr.push(item.categoryId);
    });
    setAllCategories(arr);

    const getCategories = await CategoryApi.GetByTranslation(id);
    console.log(getCategories);
    setCurrentCategory(getCategories);

    if (getCategories !== undefined || null) {
      const image = await MediaApi.GetByMediaId(getCategories[0].image_def);
      console.log(image);
      setImageCategory(image.value);
      setImageLoaded(true);
    }

    const dImage = await MediaApi.GetByMediaId(1);
    setDefaultImage(dImage.value);

    if (getCategories[0].parentId !== null || undefined || "") {
      setParentCategory(getCategories[0].parentId);
    } else setParentCategory(null);

    let newTrans = [];
    getCategories.map((category) => {
      languages.map((lang) => {
        if (category.languageId === lang.languageId) {
          newTrans.push({ ...category, lang: lang.name });
        }
      });
    });
    setCategories(newTrans);

    let allParentCat = [];
    props.data.map((parent) => {
      allParentCat.push({ id: parent.categoryId, name: parent.name });
    });

    setAllParentCategories(allParentCat);
  };

  const next = () => {
    let index = allCategoires.indexOf(targetId);
    index === allCategoires.length - 1
      ? setTargetId(allCategoires[0])
      : setTargetId(allCategoires[index + 1]);
    getData(targetId);
  };


  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        500,
        500,
        "JPEG",
        50,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
  });

  const prev = () => {
    let index = allCategoires.indexOf(targetId);
    index === 0
      ? setTargetId(allCategoires[allCategoires.length - 1])
      : setTargetId(allCategoires[index - 1]);
    getData(targetId);
  };

  const handleParentCategory = async (e) => {
    const currentCat = Object.assign({}, ...currentCategory);
    currentCat.parentId = e.target.value;
    const update = await CategoryApi.Update(currentCat);
    if (update) {
      setParentCategory("");
      props.setTableChanged(!props.tableChanged);

    }
  };

  const handleImageFromGallery = () => {
    if (props.userRole == "Viewer") {
      Swal.fire({
        title: language.media.invalidAction,
        text: language.global.userRole,
        icon: "warning",
        button: "OK",
      });
      return;
    }
    if (props.onSetGalleryChooseImage) {
      props.onSetGalleryChooseImage();
    }
  };

  const handleUploadImage = (e) => {
    if (props.ColuserRole == "Viewer") {
      Swal.fire({
        title: language.media.invalidAction,
        text: language.global.userRole,
        icon: "warning",
        button: "OK",
      });
      return;
    }
    e.preventDefault();

    document.getElementById("file-input-edit").click();
  };

  const onImageUploadChange = async (e) => {
    const file = e.target.files[0];

    
      let media;
      let img = document.getElementById("category-image");

      // const toBase64 = (files) =>
      //   new Promise(async (resolve, reject) => {
      //     const reader = new FileReader();
      //     reader.readAsDataURL(files);
      //     reader.onload = () => resolve(reader.result);
      //     reader.onloadend = () => (img.src = reader.result);
      //     reader.onerror = (error) => reject(error);
      //   });
        const uri = await resizeFile(file);

      //const uri = await toBase64(file);
      const newImage = await uri.replace(/^data:image.+;base64,/, "");
      const currentCat = Object.assign({}, ...currentCategory);
      const oldImage = await MediaApi.GetByMediaId(currentCat.image_def);

      if (newImage !== oldImage.value) {
        await MediaApi.MediaDelete(currentCat.image_def);
        media = {
          Name: currentCat.name,
          Description: "",
          TypeId: 10,
          LanguageId: languages[0].languageId,
          Value: newImage,
          ObjectValId: 0,
          RefPath: currentCat.name,
        };

        if (media.Value) {
          media = await MediaApi.Create(media);
          console.log("media created");
          console.log(media);
        }
      } else console.log("Images are same!");

      console.log(media);
      console.log(media.mediA_ID);

      const category = {
        CategoryId: currentCat.categoryId,
        image_def: media.mediA_ID,
        ParentId: currentCat.parentId,
        Mobile: true,
        Index: 1,
      };

      await CategoryApi.Update(category);
    
    document.querySelector(".py-1").src = "data:image/png;base64, " + newImage;
    props.getData();
  };


  
  ///////////////////////////////////////////////gallery/////////////////////////////////////////////

  const OnAddMediaGallery = async () => {
    //document.querySelector(".spinnerGallery").style.display = "block";

    let tokenObject = await authService.getTokenAsync();
    let token = tokenObject.accessToken;
    document.getElementById("myModalMedia").style.display = "block";
    galleryItemsMedia(token);

    flagForGallery = true;
  };

  async function updateLangFilter(languages) {
    document.querySelector("#filterLang").innerHTML = "";
    //document.querySelector("#filterLang").innerHTML = `<option value="${languages.filter(l=>l.initals==="EN").initials}" id="2">English</option>`;
    for (let lang of languages) {
      // if (document.querySelector("#filterLang").innerHTML != "") {
      // console.log(document.querySelector("#filterLang"));
      //} else {

      document.querySelector(
        "#filterLang"
      ).innerHTML += `<option value="${lang.initials}"  id="${lang.languageId}">${lang.name}</option>`;
      //}
    }
  }


  $("#transferMedia").off("click");
  $("#transferMedia").on("click", async function (e) {
    e.stopPropagation();
    e.preventDefault();
    
    if (props.userRole == "Viewer") {
      Swal.fire({
        title: language.media.invalidAction,
        text: language.global.userRole,
        icon: "warning",
        button: "OK",
      });
      return;
    }
    
    let filtered = [];
    let arrayToDelete = [];
    let checkboxesChecked = document.querySelectorAll(
      "input[type=checkbox]:checked"
    );
    console.log(checkboxesChecked);

    //check if the checkbox belongs to image, video or audio//
    for (let i = 0; i < checkboxesChecked.length; i++) {
      if (checkboxesChecked[i].parentElement.querySelector("img")) {
        let idsToDel = checkboxesChecked[i].parentElement
          .querySelector("img")
          .getAttribute("data-id");
        console.log(idsToDel);

        arrayToDelete.push(idsToDel);
        filtered = arrayToDelete.filter((el) => {
          return el !== null;
        });
        console.log(filtered);
      }
      
    }
    console.log(targetId);
    console.log(currentCategory);

    if (filtered.length == 0) {
      //alert("No media chosen. Please tick the media you want to add");
      Swal.fire({
        title: language.media.noItemsTitle,
        text: language.media.noItemsChosen,
        icon: "warning",
        button: "OK",
      });
    }  //else if (filtered.includes('23')) {
    //    alert("23 is found");
    //}
    else {
      //console.log(selectedNames);
      //var result = confirm(`Are you sure you want to add the ${arrayToDelete.length} selected media?`);
      //if (result) {

      //    arrayToDelete.forEach(async function (selectedId) {

      //        await MediaApi.AddMediaToObject(objectInitial.objectValId, selectedId);

      //    });

      //    span.click();
      //    //await showMedia();

      //    //await tab4(exhibit);
      //    await showMedia();
      //}
       
        
        //await MediaApi.MediaDelete(currentField.icoN_ID);
      filtered = arrayToDelete.filter((el) => {
        return el !== null;
      });

      filtered = filtered.filter((el) => {
        return el !== "";
      });
      let imageF;
      // Swal.fire({
      //   title: language.media.confirmMessage,
      //   text: language.media.chooseConfirm,
      //   icon: "warning",
      //   buttons: true,
      //   dangerMode: true,
      // }).then(async (willDelete) => {
       // if (willDelete) {
      

          const upload = filtered.forEach(async function (selectedId) {
              imageF = await MediaApi.GetByMediaId(selectedId);
            console.log(imageF);
            // setImageField(parseInt(imageF.value));
            console.log(selectedId);
            console.log(targetId);
            const category = {
              CategoryId: targetId,
              image_def: imageF.mediaId,
              ParentId: currentCategory.parentId,
              Mobile: true,
              Index: 1,
            };
            await CategoryApi.Update(category)
            document.querySelector(".py-1").src = "data:image/png;base64, " + imageF.value;

          });
          //return upload;
         

          //setTimeout(upload, 800);
          console.log(filtered[0]);

          //document.getElementById("category-image").src = "data:image/png;base64, " + imageF.value
          document.getElementsByClassName("closeModal")[0].click();
          //$("#spinnerContentTable").fadeIn();
          //$("#preloader").fadeIn();
          
        

          //show(upload);

          //span.click();
          //await showMedia();

          //await tab4(exhibit);
       // }
      //});

      //location.reload();
    }

    //await showMedia();
  });


  async function galleryItemsMedia(token) {
    // let tokenObject = await authService.getTokenAsync();
    // let token = tokenObject.accessToken;
    
    if (flagForGallery == false) {
        

      //let galleryItems = document.querySelector(".gallery").children;
      ///////////// //Used to be backup //////////
      //setVisibilityLoader(true);
      //setVisibilityLoader(bb => (true));

      //$(span).css("pointer-events", "none");

      const searchBar = document.getElementById("searchBar");

      //$("#spinnerContent").fadeIn();
      //$("#preloader").fadeIn();
      let allMedia = await MediaApi.Get();
      allMedia.shift();

      //console.log(allMedia);

      //var imgEl = document.getElementsByClassName("image");

      //console.log(imgEl);
      //async function lazyLoad() {
      //    for (var i = 0; i < imgEl.size; i++) {
      //        if (imgEl[i].getAttribute('src')) {
      //            imgEl[i].setAttribute('data-src', imgEl[i].getAttribute('src'));
      //            imgEl[i].removeAttribute('src');
      //        }
      //    }

      //}async-src="data:image/png;base64, ${photo.value}"

      //let valueOfSel = document.querySelector("#filterLang").value;
      //console.log(`"${valueOfSel}"`);

      for (let media of allMedia) {
        //if (media.initials === "EN") {
         
        if (media.type == "10") {
          //var photo = await MediaApi.GetByMediaId(parseInt(media.mediaId));

          document.querySelector(".gallery").innerHTML += `
          
                 <div  class="image hide" data-name="image"><span style="border-radius: 15px"><img data-id="" id="${
                   media.mediaId + "ids"
                 }"  src="${xenagos}" alt="" init="${
            media.initials
          }" class="targ ${
            media.mediaId + "ids"
          } loading"  ></span> <input class="form-check-input checkboo" type="checkbox"><span class="descript"></span></div>
          
              `;
        }

       
        //}
      }
      //setVisibilityLoader(bb => (false));
      let checkboxes = document.querySelectorAll("input[type=checkbox]");

      checkboxes.forEach((c) =>
        c.addEventListener("click", function (e) {
          //onChange();

          e.stopPropagation();
          
        })
      );

      const loader = async (init) => {
        for (let media of allMedia) {
          if (media.initials === init) {
            if (media.type == "10") {
              const photo = await MediaApi.GetByMediaId(
                parseInt(media.mediaId)
              );

              let photos = document.getElementsByClassName(
                `${media.mediaId + "ids"}`
              );

              console.log(photos);

              //if (photos[0].src !== 0) {
              photos[0].src = `data:image/png;base64,${photo.value}`;
              photos[0].classList.remove("loading");
              photos[0].setAttribute(
                "alt",
                `${media.description ? media.description : media.name}`
              );
              photos[0].setAttribute("data-id", `${media.mediaId}`);

              photos[0].parentElement.nextElementSibling.nextElementSibling.innerHTML = `${changeFlag(
                media.initials
              )}${media.description ? media.description : media.name}`;
              //    $('photos').attr("src", $('photo').attr("value"));
              //}
              //photos.find(p => p.getAttribute("data-id") == media.mediaId);
              //console.log(photos);
              //photos.src = "data:image/png;base64, ${photo.value}";
            }
            
          }
        }
      };

      //loader();
      //$("#spinnerContent").fadeOut();
      //$("#preloader").fadeOut();
      //$(".closeModal[0]").css("pointer-events", "auto");

      let testButton = document.querySelector("#testButton");
      testButton.addEventListener("click", () => {
        document.querySelector("#filterLang").value = "EN";
        triggerChange(document.querySelector("#filterLang"));
      });

      function triggerChange(element) {
        let changeEvent = new Event("change");
        element.dispatchEvent(changeEvent);
      }

      //filtering languages ${lang.name}
      //async function updateLangFilter(languages) {

      //    document.querySelector("#filterLang").innerHTML = `<option value="All" id="0">All</option>`;
      //    for (let lang of languages) {

      //        document.querySelector("#filterLang").innerHTML += `<option value="${lang.initials}" id="${lang.languageId}">${lang.name}</option>`;
      //    }

      //}
      console.log(document.querySelector("#filterLang"));
      document
        .querySelector("#filterLang")
        .addEventListener("change", async function () {
          //var checkboxes = document.querySelectorAll('input[type=checkbox]');
          //for (let ch of checkboxes) {
          //    ch.checked = false;
          //}

          let filterItem = document.querySelector(".items");
          let allItems = filterItem.querySelector("span");
          filterItem.querySelector(".active").classList.remove("active");
          allItems.classList.add("active");
          searchBar.value = "";
          let filterImg = document.querySelectorAll(".gallery .image");
          let filterAud = document.querySelectorAll(".gallery .audio");
          let filterVid = document.querySelectorAll(".gallery .video");
          let valueOfSel = document.querySelector("#filterLang").value;
          let val = this.value;

          //   console.log(filterItem);
          //   console.log(filterImg);
          //   console.log(filterVid);
          document.querySelector(".spinnerGallery").style.display = "none";

          loader(valueOfSel);

          searchBar.addEventListener("keyup", function (e) {
            filterItem.querySelector(".active").classList.remove("active");
            allItems.classList.add("active");
            let target = e.target.value.toLowerCase();

            filterImg.forEach((med) => {
              med.classList.remove("show");
              med.classList.add("hide");
            });

            filterVid.forEach((med) => {
              med.classList.remove("show");
              med.classList.add("hide");
            });

            filterAud.forEach((med) => {
              med.classList.remove("show");
              med.classList.add("hide");
            });

            /*let allFilteredMedia = filterImg.concat(filterVid, filterAud);*/

            let filteredMedia = [];
            filterImg.forEach((media) => {
              let langInitials = media.children[0]
                .querySelector("img")
                .getAttribute("init");
              if (val === langInitials || val === "All") {
                if (
                  media.children[0]
                    .querySelector("img")
                    .getAttribute("alt")
                    .toLowerCase()
                    .includes(target)
                ) {
                  filteredMedia.push(media);
                }
              }
            });

            let filteredMediaV = [];
            filterVid.forEach((media) => {
              let langInitials = media.children[0]
                .querySelector("video")
                .getAttribute("init");
              if (val === langInitials || val === "All") {
                if (
                  media.children[0]
                    .querySelector("video")
                    .getAttribute("alt")
                    .toLowerCase()
                    .includes(target)
                ) {
                  filteredMediaV.push(media);
                }
              }
            });

            let filteredMediaA = [];
            filterAud.forEach((media) => {
              let langInitials = media.children[0]
                .querySelector("audio")
                .getAttribute("init");
              if (val === langInitials || val === "All") {
                if (
                  media.children[0]
                    .querySelector("audio")
                    .getAttribute("alt")
                    .toLowerCase()
                    .includes(target)
                ) {
                  filteredMediaA.push(media);
                }
              }
            });

            filteredMedia.forEach((med) => {
              med.classList.remove("hide");
              med.classList.add("show");
            });

            filteredMediaV.forEach((med) => {
              med.classList.remove("hide");
              med.classList.add("show");
            });

            filteredMediaA.forEach((med) => {
              med.classList.remove("hide");
              med.classList.add("show");
            });

            $(filterItem).off("click");

            $(filterItem).on("click", function (e) {
              if (e.target.classList.contains("item")) {
                //if user selected item has .item class
                filterItem.querySelector(".active").classList.remove("active"); //remove the active class which is in first item
                e.target.classList.add("active"); //add that active class on user selected item
                let filterName = e.target.getAttribute("data-name"); //getting data-name value of user selected item and store in a filtername variable

                filteredMedia.forEach((image) => {
                  let filterImges = image.getAttribute("data-name"); //getting image data-name value

                  let langInitials = image
                    .querySelector("span")
                    .querySelector("img")
                    .getAttribute("init");

                  //if user selected item data-name value is equal to images data-name value
                  //or user selected item data-name value is equal to "all"
                  if (
                    (filterImges == filterName || filterName == "all") &&
                    (langInitials === valueOfSel || valueOfSel === "All")
                  ) {
                    image.classList.remove("hide"); //first remove the hide class from the image
                    image.classList.add("show"); //add show class in image
                  } else {
                    image.classList.add("hide"); //add hide class in image
                    image.classList.remove("show"); //remove show class from the image
                  }
                });

                filteredMediaV.forEach((video) => {
                  let filterVideos = video.getAttribute("data-name"); //getting image data-name value

                  let langInitials = video
                    .querySelector("span")
                    .querySelector("video")
                    .getAttribute("init");

                  //if user selected item data-name value is equal to images data-name value
                  //or user selected item data-name value is equal to "all"
                  if (
                    (filterVideos == filterName || filterName == "all") &&
                    (langInitials === valueOfSel || valueOfSel === "All")
                  ) {
                    video.classList.remove("hide"); //first remove the hide class from the image
                    video.classList.add("show"); //add show class in image
                  } else {
                    video.classList.add("hide"); //add hide class in image
                    video.classList.remove("show"); //remove show class from the image
                  }
                });

                filteredMediaA.forEach((audio) => {
                  let filterAudios = audio.getAttribute("data-name"); //getting image data-name value

                  let langInitials = audio
                    .querySelector("span")
                    .querySelector("audio")
                    .getAttribute("init");

                  //if user selected item data-name value is equal to images data-name value
                  //or user selected item data-name value is equal to "all"
                  if (
                    (filterAudios == filterName || filterName == "all") &&
                    (langInitials === valueOfSel || valueOfSel === "All")
                  ) {
                    audio.classList.remove("hide"); //first remove the hide class from the image
                    audio.classList.add("show"); //add show class in image
                  } else {
                    audio.classList.add("hide"); //add hide class in image
                    audio.classList.remove("show"); //remove show class from the image
                  }
                });
              }
            });
          });

          $(filterItem).off("click");

          $(filterItem).on("click", function (e) {
            if (e.target.classList.contains("item")) {
              //if user selected item has .item class
              filterItem.querySelector(".active").classList.remove("active"); //remove the active class which is in first item
              e.target.classList.add("active"); //add that active class on user selected item
              let filterName = e.target.getAttribute("data-name"); //getting data-name value of user selected item and store in a filtername variable

              filterImg.forEach((image) => {
                let filterImges = image.getAttribute("data-name"); //getting image data-name value

                let langInitials = image
                  .querySelector("span")
                  .querySelector("img")
                  .getAttribute("init");

                //if user selected item data-name value is equal to images data-name value
                //or user selected item data-name value is equal to "all"
                if (
                  (filterImges == filterName || filterName == "all") &&
                  (langInitials === valueOfSel || valueOfSel === "All")
                ) {
                  image.classList.remove("hide"); //first remove the hide class from the image
                  image.classList.add("show"); //add show class in image
                } else {
                  image.classList.add("hide"); //add hide class in image
                  image.classList.remove("show"); //remove show class from the image
                }
              });

              filterVid.forEach((video) => {
                let filterVideos = video.getAttribute("data-name"); //getting image data-name value

                let langInitials = video
                  .querySelector("span")
                  .querySelector("video")
                  .getAttribute("init");

                //if user selected item data-name value is equal to images data-name value
                //or user selected item data-name value is equal to "all"
                if (
                  (filterVideos == filterName || filterName == "all") &&
                  (langInitials === valueOfSel || valueOfSel === "All")
                ) {
                  video.classList.remove("hide"); //first remove the hide class from the image
                  video.classList.add("show"); //add show class in image
                } else {
                  video.classList.add("hide"); //add hide class in image
                  video.classList.remove("show"); //remove show class from the image
                }
              });

              filterAud.forEach((audio) => {
                let filterAudios = audio.getAttribute("data-name"); //getting image data-name value

                let langInitials = audio
                  .querySelector("span")
                  .querySelector("audio")
                  .getAttribute("init");

                //if user selected item data-name value is equal to images data-name value
                //or user selected item data-name value is equal to "all"
                if (
                  (filterAudios == filterName || filterName == "all") &&
                  (langInitials === valueOfSel || valueOfSel === "All")
                ) {
                  audio.classList.remove("hide"); //first remove the hide class from the image
                  audio.classList.add("show"); //add show class in image
                } else {
                  audio.classList.add("hide"); //add hide class in image
                  audio.classList.remove("show"); //remove show class from the image
                }
              });
            }
          });

          filterImg.forEach((div) => {
            let langInitials = div.children[0].children[0].getAttribute("init"); // find the initials for each div that is assigned in the init attribute.

            if (val === langInitials || val === "All") {
              div.classList.remove("hide"); //first remove the hide class from the image
              div.classList.add("show"); //add show class in image
            } else {
              div.classList.add("hide"); //add hide class in image
              div.classList.remove("show"); //remove show class from the image
            }
          });

          filterVid.forEach((div) => {
            let langInitials = div.children[0].children[0].getAttribute("init"); // find the initials for each div that is assigned in the init attribute.

            if (val === langInitials || val === "All") {
              div.classList.remove("hide"); //first remove the hide class from the image
              div.classList.add("show"); //add show class in image
            } else {
              div.classList.add("hide"); //add hide class in image
              div.classList.remove("show"); //remove show class from the image
            }
          });

          filterAud.forEach((div) => {
            let langInitials = div.children[0].children[0].getAttribute("init"); // find the initials for each div that is assigned in the init attribute.

            if (val === langInitials || val === "All") {
              div.classList.remove("hide"); //first remove the hide class from the image
              div.classList.add("show"); //add show class in image
            } else {
              div.classList.add("hide"); //add hide class in image
              div.classList.remove("show"); //remove show class from the image
            }
          });
        });

      testButton.click();

      //let filterItem = document.querySelector(".items");
      // let filterImg = document.querySelectorAll(".gallery .image");

      // let filterVid = document.querySelectorAll(".gallery .video");

      // let filterAud = document.querySelectorAll(".gallery .audio");

      ///////////// //Used to be backup //////////
    } //end of flag
    return false;
  } //end of galleryFunction


  function check(input) {

    var checkboxes = document.querySelectorAll("input");

    for (var i = 0; i < checkboxes.length; i++) {
        //uncheck all
        if (checkboxes[i].checked == true) {
            checkboxes[i].checked = false;
        }
    }

    //set checked of clicked object
    if (input.checked == true) {
        input.checked = false;
    }
    else {
        input.checked = true;
    }
}

  $(".gallery").off("click");
  $(".gallery").on("click", function (e) {
    e.stopPropagation();
    

    let targetArray = [];
    //var headerWrapper = document.getElementById("headerWrapper");
    //if (headerWrapper) {
    //    $(headerWrapper).fadeOut(10);
    //    $(transferMedia).fadeIn(1000);
    //}

    let target = e.target.parentElement.parentElement.querySelector("input");
    console.log(target)
    check(target);
    //var dataID = target.getAttribute('data-id');
    //target.checked = true;
    //onlyOne(target);


    //if (target.checked) {
    //    target.checked = false;


    //} else {
    //    target.checked = true;

    //}


  });

  const clodeFModal = () => {
    document.getElementById("myModalMedia").style.display = "none";
    flagForGallery = false;
    document.querySelector(".gallery").innerHTML = "";
  }

  const modal = document.getElementById("myModalMedia");
  console.log(modal);
  let flagForGallery = false;
  const span = document.getElementsByClassName("closeModal")[0];

  const changeFlag = (initials) => {
    if (initials === "EL") {
      const grIcon = `<img class="languageIcons" src="${greece}" />`;
      return grIcon;
    } else if (initials === "EN") {
      const enIcon = `<img class="languageIcons" src="${england}" />`;
      return enIcon;
    } else if (initials === "DE") {
      const gerIcon = `<img class="languageIcons" src="${germany}" />`;
      return gerIcon;
    }

    // else if (initials === "FR") {
    //     const frIcon = '<img class="languageIcons" src="../images/xenagos/france-circular.png" />';
    //     return frIcon;
    // }

    // else if (initials === "AF") {
    //   const africancon = '<img class="languageIcons" src="../images/xenagos/south-africa-circular.png"/>';
    //     return africancon;
    // }

    // else if (initials === "AR") {
    //   const arabicIcon = '<img class="languageIcons" src="../images/xenagos/saudi-arabia-circular.png"/>';
    //     return arabicIcon;
    // }

    // else if (initials === "ZH") {
    //   const chinese = '<img class="languageIcons" src="../images/xenagos/china-circular.png"/>';
    //     return chinese;
    // }

    // else if (initials === "HE") {
    //   const hebrew = '<img class="languageIcons" src="../images/xenagos/israel-circular.png"/>';
    //     return hebrew;
    // }

    // else if (initials === "HI") {
    //   const hindi = '<img class="languageIcons" src="../images/xenagos/india-circular.png"/>';
    //     return hindi;
    // }

    // else if (initials === "IT") {
    //   const italy = '<img class="languageIcons" src="../images/xenagos/italy-circular.png"/>';
    //     return italy;
    // }

    // else if (initials === "JA") {
    //   const japan = '<img class="languageIcons" src="../images/xenagos/japan-circular.png"/>';
    //     return japan;
    // }

    // else if (initials === "KO") {
    //   const korean = '<img class="languageIcons" src="../images/xenagos/south-korea-circular.png"/>';
    //     return korean;
    // }

    // else if (initials === "PT") {
    //   const portugese = '<img class="languageIcons" src="../images/xenagos/portugal-circular.png"/>';
    //     return portugese;
    // }

    // else if (initials === "RU") {
    //   const russia = '<img class="languageIcons" src="../images/xenagos/russian-federation-circular.png"/>';
    //     return russia;
    // }
    else if (initials === "ES") {
      const spainIcon = `<img class="languageIcons" src="${spain}"/>`;
      return spainIcon;
    }

    // else if (initials === "TR") {
    //   const turkey = '<img class="languageIcons" src="../images/xenagos/turkey-circular.png"/>';
    //     return turkey;
    // }
  };

  /////////////////////////////////////////////////////gallery///////////////////////////////////////////////////////////////




  return (
    <>
    {/* gallery */}
    <div id="myModalMedia" style={{"z-index": "99999"}} className="modalMedia">
        <div className="modalMedia-content">
          {/* <!-- Modal content --> */}

          <div className="modalMedia-body">
            <Spinner
              animation="border"
              className="spinnerGallery"
              size="lg"

              // style={{ display: "block" }}
            />
            <div style={{ "text-align": "center" }}>
              <h4 style={{ background: "#1c4325" }}>Multimedia</h4>
              <span onClick={clodeFModal} className="closeModal">&times;</span>
            </div>

            <div className="wrapper">
              <button id="testButton" style={{ display: "none" }}></button>

              <button
                className="xenagosButton btn btn-success"
                id="transferMediaGal"
              >
                Add Media
              </button>

              {/* <i style=" margin-left: 72.3%; margin-top: 1.1%; color: #1c4325;" className="fas fa-globe"></i> */}
              <select
                className="form-select"
                style={{
                  "border-radius": "20px",
                  "display": "none",
                  width: "21%",
                  "font-size": "18px",
                  "text-align": "center",
                }}
                name="filterLang"
                id="filterLang"
                value="EN"
              ></select>
              <div
                id="searchWrapper"
                style={{
                  "margin-bottom": "1rem",
                  display: "flex",
                  "justify-content": "center",
                }}
              >
                {/* <i className="fas fa-search searchIcon"></i> */}
                <input
                  type="text"
                  style={{
                    "margin-bottom": "1rem",
                    "padding-left": "0.5rem",
                    "margin-top": "-1rem",
                    "border-radius": "12px!important"
                  }}
                  name="searchBar"
                  id="searchBar"
                  placeholder="Search.."
                />
              </div>
              {/* <!-- filter Items --> */}
              <nav>
                <div className="items">
                  <span className="item active" data-name="all">
                    All
                  </span>
                  <span className="item" data-name="image">
                    Image
                  </span>
                  <span className="item" data-name="video">
                    Video
                  </span>
                  <span className="item" data-name="audio">
                    Mp3
                  </span>
                </div>
              </nav>

              {/* <!-- filter Images --> */}
              <div className="gallery" id="gallery">
                {/* <Triangle
                        height="80"
                        width="80"
                        color="#4fa94d"
                        ariaLabel="triangle-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={visibilityLoader}
                    /> */}
                    
              </div>
            </div>
          </div>
        </div>
      </div>
                                                                                              {/* gallery */}
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" className="fw-light">
            {languageChosenSite === "EL"
              ? languageGr.global.categoryWords.editCat
              : language.global.categoryWords.editCat}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="text-center shadow-sm pb-2 mb-2 bg-body rounded">
            <Col sm={12}>
              {!imageLoaded ? (
                <div className="d-flex justify-content-center">
                  <Col sm={4}>
                    <InputSkeleton />
                  </Col>
                </div>
              ) : (
                <div>
                  <Image
                    loading="lazy"
                    id="category-image"
                    style={{ "max-height": "20vh", width: "35%", "border-radius": "10px" }}
                    className="py-1"
                    src={imageCategory ? "data:image/png;base64, " + imageCategory : "data:image/png;base64, " + defaultImage}
                  />
                </div>
              )}
            </Col>
            <Col sm={12}>
              <ButtonGroup size="sm">
                <Button
                  id="updatePhoto"
                  className="closeButton"
                  variant="closeButton"
                  onClick={handleUploadImage}
                >
                  {languageChosenSite === "EL"
                    ? languageGr.global.categoryWords.uploadImage
                    : language.global.categoryWords.uploadImage}
                </Button>
                <Button
                  id="addMedia"
                  className="closeButton"
                  variant="closeButton"
                  onClick={OnAddMediaGallery}

                >
                  {languageChosenSite === "EL"
                    ? languageGr.global.categoryWords.galleryAttach
                    : language.global.categoryWords.galleryAttach}
                </Button>
              </ButtonGroup>
              <input
                id="file-input-edit"
                type="file"
                name="name"
                onChange={onImageUploadChange}
                style={{ display: "none" }}
              />
            </Col>
          </Row>

          {parentCategory === null ? null : (
            <Row className="mb-2">
              <Col
                sm={3}
                className="d-flex justify-content-start align-self-center fw-light"
              >
                <span className="attachment-categories">
                  {languageChosenSite === "EL"
                    ? languageGr.global.categoryWords.parentCat
                    : language.global.categoryWords.parentCat}
                </span>
              </Col>
              <Col sm={9}>
                <div>
                  <Form.Select
                    aria-label="Default select example"
                    onChange={handleParentCategory}
                    value={parentCategory === "" ? null : parentCategory}
                    style={{ borderRadius: "12px", fontSize: "20px" }}
                  >
                    {allParentCategories.map((cat) => (
                      <option value={cat.id}>{cat.name}</option>
                    ))}
                  </Form.Select>
                </div>
              </Col>
            </Row>
          )}

          <Tab.Container
            id="lang-left-tabs"
            className="mb-4"
            defaultActiveKey={`#Greek`}
          >
            <Row>
              <Col sm={3}>
                <Nav variant="pills" className="flex-column">
                  {languages.map((value) => (
                    <Nav.Link href={`#${value.name}`}>{value.name}</Nav.Link>
                  ))}
                </Nav>
              </Col>
              <Col sm={9}>
                <Tab.Content>
                  {languages.map((lang) => (
                    <Tab.Pane eventKey={`#${lang.name}`}>
                      <Data
                        targetId={props.targetId}
                        tableChanged={props.tableChanged}
                        setTableChanged={props.setTableChanged}
                        languageId={lang.languageId}
                        translationId={categories
                          .map((category) =>
                            category.lang === lang.name
                              ? category.translationId
                              : null
                          )
                          .join("")}
                        initialTitle={categories
                          .map((category) =>
                            category.lang === lang.name ? category.name : null
                          )
                          .join("")}
                        initialDesc={categories
                          .map((category) =>
                            category.lang === lang.name
                              ? category.description
                              : null
                          )
                          .join("")}
                          languageChosenSite={props.languageChosenSite}
                      />
                    </Tab.Pane>
                  ))}
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>

          <div className="text-center pt-3">
            <Button onClick={prev} className="backwards mx-1">
              <FaIcons.FaChevronLeft />
            </Button>

            <Button onClick={next} className="forward mx-1">
              <FaIcons.FaChevronRight />
            </Button>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="closeButton"
            variant="closeButton"
            onClick={props.onHide}
          >
            {languageChosenSite === "EL"
              ? languageGr.global.close
              : language.global.close}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalParent;

function Data({
  initialTitle,
  initialDesc,
  languageId,
  translationId,
  targetId,
  userRole,
  languageChosen,
  tableChanged,
  setTableChanged
}) {
  const [desc, setDesc] = useState(initialDesc ?? "");
  const [title, setTitle] = useState(initialTitle ?? "");
  const [languageChosenSite, setLanguageChosenSite] = useState("");

  useEffect(() => {
    update();
  }, [initialTitle, initialDesc]);

  const update = () => {
    setDesc(initialDesc ?? "");
    setTitle(initialTitle ?? "");
    setLanguageChosenSite(sessionStorage.getItem("languageChosen"));
  };

  const updateData = async () => {
    if (userRole == "Viewer") {
      Swal.fire({
        title: language.media.invalidAction,
        text: language.global.userRole,
        icon: "warning",
        button: "OK",
      });
      return;
    }
    let translation = {
      translations: [],
    };

    console.log(`New title: ${title}`);
    console.log(`New description: ${desc}`);
    console.log(`Language ID: ${languageId}`);
    console.log(`Translation ID: ${translationId}`);
    console.log(`Target ID: ${targetId}`);

    if (initialTitle || initialDesc !== "") {
      if (initialTitle !== title || initialDesc !== desc) {
        //UpdateTranslation
        console.log("Update data");
        translation.translations.push({
          translationId: parseInt(translationId),
          name: title,
          description: desc,
        });

        const update = await CategoryApi.UpdateTranslation(translation);

        if (update) {
          Swal.fire({
            title: "Success",
            text: `Action was successfully done`,
            icon: "success",
          });
          setTableChanged(!tableChanged);

        }
      } else {
        console.log("The data are same. Update not needed");
      }
    } else {
      //CreateTranslation
      console.log("Create data");
      translation.translations.push({
        id: targetId,
        languageId: languageId,
        name: title,
        description: desc,
      });

      const create = await CategoryApi.CreateTranslation(translation);

      if (create) {
        Swal.fire({
          title: "Success",
          text: `Action was successfully done`,
          icon: "success",
        });
        setTableChanged(!tableChanged);

      }
    }
    console.log(translation);
  };

  return (
    <>
      <div className="pb-2">
        <Form.Control
          defaultValue={initialTitle}
          value={title}
          onChange={(newT) => setTitle(newT.target.value)}
        />
      </div>
      <div className="pb-2">
        <BundledEditor
          init={{
            height: 250,
            selector: "textarea",
            plugins:
              "advlist anchor autolink help image link lists searchreplace table wordcount emoticons insertdatetime code",
            toolbar:
              "undo redo blocks image link media numlist bullist insertdatetime|",
            content_style:
              "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
          }}
          initialValue={initialDesc}
          value={desc}
          onEditorChange={(newD) => setDesc(newD)}
        />
      </div>
      <div>
        <Button
          className="addNewButton"
          variant="addNewButton"
          onClick={() => updateData()}
        >
          {languageChosenSite === "EL"
            ? languageGr.global.saveChanges
            : language.global.saveChanges}
        </Button>
      </div>
    </>
  );
}
