import React, { useState } from 'react'

// Import Swiper 
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Navigation, Thumbs } from "swiper";

// Import Swiper styles
import "./gallerySlider.css";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

function GallerySlider(props) {
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    console.log(props);
    return (
        <div>
            <Swiper
                style={{
                    "--swiper-navigation-color": "#fff",
                    "--swiper-pagination-color": "#fff",
                }}
                loop={false}
                spaceBetween={10}
                navigation={true}
                thumbs={{ swiper: thumbsSwiper }}
                modules={[FreeMode, Navigation, Thumbs]}
                className="imageSwiper"
            >
                {props.galleryImages.map((v) => (
                    <SwiperSlide>
                        <img
                            src={v.value && "data:image/png;base64, " + v.value}
                            alt={v.description}
                        />
                    </SwiperSlide>
                ))}
            </Swiper>
            <Swiper
                onSwiper={setThumbsSwiper}
                loop={false}
                spaceBetween={10}
                slidesPerView={4}
                freeMode={true}
                watchSlidesProgress={true}
                modules={[FreeMode, Navigation, Thumbs]}
                className="thumbnailSwiper"
            >
                {props.galleryImages.map((v) => (
                    <SwiperSlide>
                        <img
                            src={v.value && "data:image/png;base64, " + v.value}
                            alt={v.description}
                        />
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    )
}


export default GallerySlider