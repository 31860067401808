import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import HTMLRenderer from 'react-html-renderer';

//Api
import { ExhibitionApi, ExhibitNew } from "../../api/api.service";

import CategoryPage from "../Category/Category";
import Events from "../Events/Events";
import EventImage from "../Icons/calendar.png"

import "./cards.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Bootstrap
import { Card, Container, Row, Col, Button } from "react-bootstrap";

export function ExhibitsCard(props) {
    const [visibleExhibits, setVisibleExhibits] = useState(6);
    const showMoreExhibits = () => {
        if (props.data.length > visibleExhibits)
            setVisibleExhibits((prevState) => prevState + 6);
    }

    return (
        <>
            <Container className='pb-5'>
                <Row xs={1} sm={2} md={3} className="g-4">
                    {props.data.slice(0, visibleExhibits).map((value, index) => (
                        <Col className="d-flex align-items-stretch" key={index} >
                            <Card className="exhibitCard">
                                <Card.Img
                                    variant="top"
                                    src={value.imageValue && "data:image/png;base64, " + value.imageValue}
                                />
                                <Card.Body>
                                    <Card.Title>{value.name}</Card.Title>
                                    <Card.Subtitle className="mt-2 text-muted">
                                        {value.category}
                                    </Card.Subtitle>
                                    <Link to={`/front/exhibit/${value.objectS_ID}`}>
                                        <Button variant="primary mt-3">{props.textLanguage.buttons.seeMore}</Button>
                                    </Link>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>
                <div className="d-flex justify-content-center pt-5">
                    {props.data.length > visibleExhibits ?
                        <Button onClick={showMoreExhibits} variant="primary">
                            {props.textLanguage.buttons.seeMoreExhibits}
                        </Button>
                        :
                        <div></div>
                    }
                </div>
            </Container>
        </>
    )
}

export function EventsCard(props) {
    const [showModal, setShowModal] = useState(false);
    const [modalData, setModalData] = useState(null);
    const [smallScreen, setSmallScreen] = useState(3);

    useEffect(() => {
        isSmallScreen();
    }, []);

    const isSmallScreen = () => {
        window.addEventListener("resize", () => {
            if (window.innerWidth <= 990 && window.innerWidth >= 650)
                setSmallScreen(2)

            else if (window.innerWidth < 650)
                setSmallScreen(1)
            else
                setSmallScreen(3)

            console.log(window.innerWidth);
        });
    }

    const handleModal = () => setShowModal(!showModal);

    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: smallScreen,
        slidesToScroll: 1,

    };

    return (
        <>
            <Slider {...sliderSettings}>
                {props.events.map((value, index) => (
                    <Col className="px-3 " key={index}>
                        <Card className="event-card">
                            <Card.Img
                                src={EventImage}
                            />
                            <Card.Body className="event-card-body">
                                <Card.Title>{value.title}</Card.Title>
                                <Button
                                    onClick={() => {
                                        handleModal();
                                        setModalData(value);
                                    }}
                                    variant="primary">
                                    {props.textLanguage.buttons.readMore}
                                </Button>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Slider>

            {modalData !== null ? <Events
                handleModal={handleModal}
                showModal={showModal}
                modalData={modalData}
            /> : null}
        </>
    )
}

export function CategoriesCard(props) {
    const [visibleExhibits, setVisibleExhibits] = useState(6);

    const showMoreExhibits = () => {
        setVisibleExhibits((prevState) => prevState + 6);
    }

    return (
        <>
            <Container>
                <Row>
                    {props.categories.map((value, index) => (
                        <Col lg={3} sm={6} key={index} className="p-2">
                            <Card className="category-card text-center">
                                <Link to={`category/${value.categoryId}`}>
                                    <Card.Img
                                        variant="top"
                                        src={props.categoryImageValue[index] && "data:image/png;base64, " + props.categoryImageValue[index]}
                                    />
                                    <div className="card-img-overlay d-flex align-items-end">
                                        <Card.Body className="category-card-body">
                                            <div className="d-flex justify-content-start">
                                                <Card.Title className="category-card-title">{value.name}</Card.Title>
                                            </div>
                                        </Card.Body>
                                    </div>

                                </Link>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Container>
        </>
    )
}

export function ExhibitionsCard(props) {
    const [showModal, setShowModal] = useState(false);
    const [modalData, setModalData] = useState(null);
    const [smallScreen, setSmallScreen] = useState(3);

    useEffect(() => {
        isSmallScreen();
    }, []);

    const isSmallScreen = () => {
        window.addEventListener("resize", () => {
            if (window.innerWidth <= 990 && window.innerWidth >= 650)
                setSmallScreen(2)

            else if (window.innerWidth < 650)
                setSmallScreen(1)
            else
                setSmallScreen(3)

            console.log(window.innerWidth);
        });
    }

    const handleModal = () => setShowModal(!showModal);

    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: smallScreen,
        slidesToScroll: 1,
    };

    return (
        <>
            <Slider {...sliderSettings}>
                {props.exhibitions.map((value, index) => (
                    <Col className="p-2" key={index}>
                        <Card className="event-card">
                            <Card.Img
                                variant="top"
                                src="https://cdn-icons-png.flaticon.com/512/2558/2558944.png"
                            />
                            <Card.Body>
                                <Card.Title>{value.name}</Card.Title>
                                <Link to={`/front/exhibitions/${value.exhibitionId}`}>
                                    <Button variant="primary mt-3">{props.textLanguage.buttons.seeMore}</Button>
                                </Link>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Slider>
        </>
    )
}