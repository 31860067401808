import React, { useState, useEffect } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import Swal from "sweetalert2";
import InputSkeleton from "./InputSkeleton";

import { FieldApi, CategoryApi } from "../../api/api.service";
// Bootstrap
import { Button } from "react-bootstrap";

// CSS
import "../../css/components/style.css";

//dynamic language
import { language, languageGr } from "../../api/lang";

const SelectMulti = (props) => {
  useEffect(() => {
    // if (typeof targetId === "number") {
    //   setIdClicked(targetId);
    // }
    // fetchBody();
    //updateTransDropSite();
    //setTranslations(props.translations);

    //setTargetId(props.targetId);
    // attachTranslations();

    start();
  }, [props]);

  const animatedComponents = makeAnimated();
  let test1 = [];
  //const [categoriesChosen, setCategoriesChosen] = useState(props.categoriesChosen);
  const [categoriesChosen, setCategoriesChosen] = useState([]);
  const [loading, setLoading] = useState(false);
  const [languageChosenSite, setLanguageChosenSite] = useState("");

  const start = () => {
    categoryFields(props.targetId);
    setLanguageChosenSite(sessionStorage.getItem("languageChosen"));

    //addFieldtoCat();
  };

  const categoryFields = async (id) => {
    //console.log(props.categories);
    //let checked = "";
    //document.getElementById("updateFieldCategoriesDrop").innerHTML = "";
    //console.log(props.categories)
    for (let i = 0; i < props.categories.length; i++) {
      const fields = await CategoryApi.GetFieldOfCategory(
        props.categories[i].categoryId
      );
      console.log(fields);

      for (var j = 0; j < fields.length; j++) {
        if (fields[j].fieldId === id) {
          console.log(props.categories[i]);
          test1.push({
            value: props.categories[i].categoryId,
            label: props.categories[i].name,
            //style: {width: 'min-content'}
          });
          //checked = "checked"
          // console.log(checked)
          //setChecked(true);
          //setCategoriesField([...categoriesField, targetId]);
        }
      }

      console.log(props);

      // document.getElementById("updateFieldCategoriesDrop").innerHTML += `<div class="form-check">
      // <input ${checked}  type="checkbox" id="${props.categories[i].name}" name="${props.categories[i].name}" value="${props.categories[i].categoryId}" class="form-check-input">
      // <label class="form-check-label"> ${props.categories[i].name}</label>`
      //       checked = ""
    }
    setCategoriesChosen(test1);
    setLoading(true);
    console.log(categoriesChosen);
    console.log(test1);
    // const blabla = document.querySelectorAll(".css-1rhbuit-multiValue");
    // const blaArr = [...blabla]
    //   console.log(blaArr);
    // blaArr.forEach(b => b.parentElement.style.width = "min-content")
  };

  const addFieldtoCat = async () => {
    // if (claims.claims.role === "Viewer") {
    //     //alert(language.global.userRole);
    //     swal({
    //         title: language.media.invalidAction,
    //         text: language.global.userRole,
    //         icon: "warning",
    //         button: "OK",

    //     })
    // } else {
    setLoading(false);

    console.log(categoriesChosen);

    for (var i = 0; i < props.categories.length; i++) {
      console.log(props.categories[i].name);
      console.log(props.categories[i].categoryId);
      if (
        categoriesChosen.find(
          (cat) => cat.value == props.categories[i].categoryId
        )
      ) {
        await FieldApi.AddToCategory(
          props.categories[i].categoryId,
          props.targetId
        );
      } else {
        FieldApi.RemoveFromCategory(
          props.categories[i].categoryId,
          props.targetId
        );
      }
      // if (props.categories[i].name) {
      //     var categoryChecked = document.getElementById(props.categories[i].name).checked
      // }
      //console.log(categoryChecked);
      // if (categoryChecked) {
      //     await FieldApi.AddToCategory(props.categories[i].categoryId, targetId)
      // }
      // else {
      //     await FieldApi.RemoveFromCategory(props.categories[i].categoryId, targetId)
      // }
      //await FieldApi.AddToCategory(categoriesChosen[i].value, targetId)
    }
    console.log("end");
    Swal.fire("Success", "Field was added successfully", "success");
    setLoading(true);

    categoryFields(props.targetId);
    //$("#successSave").show().fadeOut(2000);
    //}
  };

  const OnSaveClick = async () => {
    await addFieldtoCat();
    //setChangeMade(false);
  };

  const testing = (e) => {
    setCategoriesChosen(e);
  };

  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      width: state.selectProps.width,
      blockSize: state.selectProps.blockSize,
    }),
    control: (_, { selectProps: { width, blockSize } }) => ({
      width: "fit-content",
      blockSize: "fit-content",
    }),
  };

  // const colourStyles = {
  //   control: styles => ({ ...styles, backgroundColor: 'white' }),
  //   container: base => ({

  //     flex: 1
  //   }),
  //   option: (styles, { base, data, isDisabled, isFocused, isSelected }) => {

  //     return {
  //       ...styles,
  //      ...base,
  //       color: '#FFF',
  //       cursor: isDisabled ? 'not-allowed' : 'default',
  //       width: "min-content"
  //     };
  //   },
  // };

  return (
    <>
      {!loading ? (
        <InputSkeleton />
      ) : (
        <Select
          // className={classes.center}
          options={props.categoryState}
          isSearchable
          onChange={testing}
          value={categoriesChosen}
          components={animatedComponents}
          autosize={true}
          //styles={customStyles}
          isMulti
        />
      )}
      <Button
        className="addNewButton"
        variant="addNewButton"
        onClick={OnSaveClick}
        style={{
          "margin-top": "1vh",
          "margin-bottom": "1vh",
        }}
      >
        {languageChosenSite === "EN"
          ? language.global.saveCat
          : languageGr.global.saveCat}
      </Button>
    </>
  );
};

export default SelectMulti;
