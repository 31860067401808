import { useEffect, useState } from "react";
import classes from "./LanguageFilter.module.css";
import { LanguageApi } from "../../../api/api.service";
import * as FaIcons from "react-icons/fa";
import * as BIcons from "react-icons/bs";
import { LanguageData } from "./LanguageData";
import { language, languageGr } from "../../../api/lang";
import { Row, Col } from "react-bootstrap";

const Filters = ({
  onSetLanguageState,
  onSetMediaTypeState,
  onSetSearchFilter,
  languageChosenSite
}) => {
  //const [languageChosenSite, setLanguageChosenSite] = useState("");

  useEffect(() => {
    updateLangFilter();
    //setLanguageChosenSite(sessionStorage.getItem("languageChosen"));
  }, []);

  

  async function updateLangFilter() {
    const languages = await LanguageApi.Get();
    console.log(languages);
    console.log(LanguageData);

    for (let lang of languages) {
      document.querySelector(
        "#filterLang"
      ).innerHTML += `<option value="${lang.initials}" id="${lang.languageId}">${lang.name}</option>`;
    }
  }

  const onLanguageSwitch = (e) => {
    //const prev = document.querySelector(".ArrowButton__Button-sc-1ikb0hj-1.bBfHpH");
    //const divOfPrev = document.querySelector(".ArrowButton__ButtonWrapper-sc-1ikb0hj-0.cSNndH")
    onSetLanguageState(e.target.value);
    // console.log(prev.parentElement.nextSibling.children[0].children[0]);
    
    // let allDivs = document.querySelectorAll('.Carousel__Rail-sc-hyhecw-2');
    // console.log(allDivs[0].children[0]);
    // if(prev.parentElement.nextSibling.children[0].children[0] !== allDivs[0].children[0]) {
    //   console.log("no first");
    // } else {
    //   console.log("first");
    
    // for(let i=0; i<10; i++) {
    //   if(divOfPrev.style.display !== "none") {
    //     prev.click();
    //   } else {
    //     return;
    //   }
    // }
    
  };

  const onMediaTypeSwitch = (e) => {
    e.target.parentElement.children[0].classList.remove(classes.active);
    e.target.parentElement.children[1].classList.remove(classes.active);
    e.target.parentElement.children[2].classList.remove(classes.active);
    e.target.parentElement.children[3].classList.remove(classes.active);
    onSetMediaTypeState(e.target.id);
    e.target.classList.add(classes.active);
  };

  const setOnSearchIput = (e) => {
    let searchWord = e.target.value.toLowerCase();
    console.log(searchWord);

    onSetSearchFilter(searchWord);
  };

  return (
    <>
      {/* <Fragment>
        <div id="searchWrapper" className={classes.searchWrapper}>
          <BIcons.BsSearch
            style={{ "margin-right": "1vh", "margin-top": "0.8vh" }}
          />

          <input
            type="text"
            name="searchBar"
            id="searchBar"
            onChange={setOnSearchIput}
            className={classes.searchbar}
            placeholder="Search.."
          />
        </div>

        <div className={classes.wrapper}>
          <div className={classes.items}>
            <span
              className={`${classes.item}, ${classes.active}`}
              id="10"
              onClick={onMediaTypeSwitch}
            >
              {languageChosenSite === "EN"
                ? language.global.mediaWords.images
                : languageGr.global.mediaWords.images}
            </span>
            <span className={classes.item} id="20" onClick={onMediaTypeSwitch}>
              {languageChosenSite === "EN"
                ? language.global.mediaWords.video
                : languageGr.global.mediaWords.video}
            </span>
            <span className={classes.item} id="30" onClick={onMediaTypeSwitch}>
              {languageChosenSite === "EN"
                ? language.global.mediaWords.audio
                : languageGr.global.mediaWords.audio}
            </span>
            <span className={classes.item} id="40" onClick={onMediaTypeSwitch}>
              {languageChosenSite === "EN"
                ? language.global.mediaWords.ts
                : languageGr.global.mediaWords.ts}
              °
            </span>
          </div>
        </div>

        <div className={classes.filterLangDiv}>
          <div className="icon">
            <FaIcons.FaGlobeAmericas />
          </div>
          <div className="selectDiv">
            <select
              style={{
                "border-radius": "20px",
                "background-color": "rgb(28, 67, 37)",
                color: "white",
                cursor: "pointer",
                padding: "5px 10px",
                "font-size": "17px",
                "font-weight": "500",
                border: "2px solid green",
                transition: "all 0.25s ease",
              }}
              className="form-select-sm"
              name="filterLang"
              id="filterLang"
              defaultValue="EN"
              onChange={onLanguageSwitch}
            ></select>
          </div>
        </div>
      </Fragment> */}
      <div className="mx-5 my-3">
        <Row>
          <Col
            md={12}
            lg={4}
            className="d-flex justify-content-lg-start justify-content-md-center justify-content-sm-center justify-content-xs-center mb-sm-3 mb-xs-3"
          >
            <BIcons.BsSearch
              className="me-1"
              style={{ "margin-top": "1.3vh" }}
            />
            <input
              type="text"
              name="searchBar"
              id="searchBar"
              onChange={setOnSearchIput}
              className={classes.searchbar}
              placeholder={languageChosenSite === "EL"
              ? languageGr.global.mediaWords.search
              : language.global.mediaWords.search}
            />
          </Col>
          <Col
            md={12}
            lg={4}
            className="d-flex justify-content-lg-center justify-content-md-center justify-content-sm-center justify-content-xs-center mb-sm-3 mb-xs-3"
          >
            <div className={classes.items}>
              <span
                className={`${classes.item}, ${classes.active}`}
                id="10"
                onClick={onMediaTypeSwitch}
              >
                {languageChosenSite === "EL"
                  ? languageGr.global.mediaWords.images
                  : language.global.mediaWords.images}
              </span>
              <span
                className={classes.item}
                id="20"
                onClick={onMediaTypeSwitch}
              >
                {languageChosenSite === "EL"
                  ? languageGr.global.mediaWords.video
                  : language.global.mediaWords.video}
              </span>
              <span
                className={classes.item}
                id="30"
                onClick={onMediaTypeSwitch}
              >
                {languageChosenSite === "EL"
                  ? languageGr.global.mediaWords.audio
                  : language.global.mediaWords.audio}
              </span>
              <span
                className={classes.item}
                id="40"
                onClick={onMediaTypeSwitch}
              >
                {languageChosenSite === "EL"
                  ? languageGr.global.mediaWords.ts
                  : language.global.mediaWords.ts}
                °
              </span>
            </div>
          </Col>
          <Col
            md={12}
            lg={4}
            className="d-flex justify-content-lg-end justify-content-md-center justify-content-sm-center justify-content-xs-center justify-content-xs-center mb-sm-3 mb-xs-3"
          >
            <FaIcons.FaGlobeAmericas className="icon" style={{ "color": "#1c4325" }} />
            <select
              style={{
                "border-radius": "20px",
                color: "black",
                cursor: "pointer",
                padding: "7px 12px",
                "font-size": "18px",
                "font-weight": "400",
                border: "1px solid #1c4325",
                transition: "all 0.25s ease",
              }}
              className="form-select-sm"
              name="filterLang"
              id="filterLang"
              defaultValue="EN"
              onChange={onLanguageSwitch}
            ></select>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Filters;
