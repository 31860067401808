import React, { useState, useEffect } from "react";
import Select from "react-select";

//Language values
import Greek from "../Languages/Greek.json";
import English from "../Languages/English.json";

export default function SelectExhibits(props) {
    const [exhibitsSelectOptions, setExhibitsSelectOptions] = useState([]);
    const [textLanguage, setTextLanguage] = useState(Greek);
    const selectOptions = []
    const categoryName = [];

    useEffect(() => {
        setLanguage();
    }, []);
    
    
    const getCategoriesOfExhibits = () => {
        for (let i in props.categories) {
            if (Array.isArray(props.categories[i])) {
                for (let element of props.categories[i]) {
                    selectOptions.push({ value: element.categoryId, label: element.name })
                }
            } else {
                selectOptions.push({ value: props.categories[i].categoryId, label: props.categories[i].name })
            }
        }
        setExhibitsSelectOptions(selectOptions);
    }

    const handleSelectOption = (options) => {
        props.fetchResults(options)
    }

    const setLanguage = () => {
        sessionStorage.languageChosen === "EN"
            ? setTextLanguage(English)
            : setTextLanguage(Greek);
    };

    return (
        <>
            <Select
                options={exhibitsSelectOptions}
                placeholder={textLanguage.homePage.filteredExhibits}
                isSearchable
                isMulti
                onMenuOpen={getCategoriesOfExhibits}
                onChange={handleSelectOption}
            />
        </>
    )
}
