import { useState, useEffect, Fragment } from "react";
import "./MediaGallery.css";
import { authService } from "../../../api/auth.service";
import Media from "./Media";
import { Button, Spinner, Row, Col } from "react-bootstrap";

import Cropper from "react-easy-crop";
import ImageCropDialog from "./ImageCropDialog";
import { LanguageApi, MediaApi } from "../../../api/api.service";
import ImageGallery from "react-image-gallery";
import Carousel from "better-react-carousel";
import "react-image-gallery/styles/css/image-gallery.css";
import defaultImage from "../../../images/default.jpg";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import MediaSkeleton from "../../UI/MediaSkeleton";
import { CarouselItem } from "react-bootstrap";

import Filters from "./Filter";

import Upload from "./Upload";
import removeFadeOut from "../../UI/RemoveItemDom";

import * as BsIcons from "react-icons/bs";

import { language, languageGr } from "../../../api/lang";
import * as FaIcons from "react-icons/fa";
import manage from "../../../images/manage-orange.png";
import Swal from "sweetalert2";

import $ from "jquery";
import { IoIosConstruct } from "react-icons/io";
import { FaWindowClose } from "react-icons/fa";
import { faPersonWalkingDashedLineArrowRight } from "@fortawesome/free-solid-svg-icons";

const MediaGallery = (props) => {
  const imagesArray = [
    {
      original: "",
      description: "",
      croppedImageUrl: null,
    },
  ];
  const [clickedPhoto, setClickedPhoto] = useState(false);
  const [images, setImages] = useState(imagesArray);
  const [media10, setMedia10] = useState([]);
  const [mediaValue, setMediaValue] = useState([]);
  const [mediaValueVid, setMediaValueVid] = useState([]);
  const [mediaValueVid360, setMediaValueVid360] = useState([]);
  const [mediaValueAud, setMediaValueAud] = useState([]);
  const [uploadState, setUploadState] = useState(false);
  // const [spinnerShow, setSpinnerShow] = useState(false);
  //const [clickedDesc, setClickedDesc] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [languageState, setLanguageState] = useState("EL");
  const [mediaTypeState, setMediaTypeState] = useState("10");
  const [mediaShow, setMediaShow] = useState(false);
  const [languageChosenSite, setLanguageChosenSite] = useState("");
  const [removedElement, setRemovedElement] = useState(false);
  const [queryTyped, setQueryTyped] = useState("");
  //const [uploadState, setUploadState] = useState(props.uploadState);
  const [modalShow, setModalShow] = useState(false);
  //const [mulitpleMediaDeleteArray, setMulitpleMediaDeleteArray] = useState([]);
  
  const [changedMediaArray, setChangedMediaArray] = useState(0);
  const [userRole, setUserRole] = useState("");
  const [removedElementArrayMutli, setRemovedMediaElementArrayMulti] =
    useState(false);
  const [multiDel, setMultiDel] = useState(false);
  const [deletedMediaArray, setDeletedMediaArray] = useState(0);
  const [isClicked, setIsClicked] = useState(false);
  const [multipleDeleteArray, setMultipleDeleteArray] = useState([]);
  

  useEffect(() => {
    document.body.className = "";
    setLanguageChosenSite(sessionStorage.getItem("languageChosen"));

    getData(languageState);
    changeHead();
    //document.querySelector(".pageHead").innerHTML = `<h4 style='padding-top: 2%;text-align: center;color: #f7aa47;margin-left: -39vw;'><img style="height: 4vh;" class='fa fa-fw fa-file' src='${manage}' />${language.global.titles.media}</h4>`;
  }, []);

  useEffect(() => {
    getNewData();
    console.log("mphka");
    //document.querySelector(".pageHead").innerHTML = `<h4 style='padding-top: 2%;text-align: center;color: #f7aa47;margin-left: -39vw;'><img style="height: 4vh;" class='fa fa-fw fa-file' src='${manage}' />${language.global.titles.media}</h4>`;
  }, [changedMediaArray]);

  useEffect(() => {
    removeMediaItem();
    console.log("svhsthka");
  }, [deletedMediaArray, removedElement]);


  useEffect(() => {
    removeMediaItemArrayMulti();
    console.log("svhsthkan multi");
  }, [multiDel, removedElement]);

  // const removeMediaItemArrayMulti = () => {
  //   let arr = mediaValue;

  //   arr = arr.filter( ( el ) => !multipleDeleteArray.includes( el ) );
  //   console.log(arr);

  // }

  const removeMediaItem = async () => {
    console.log(deletedMediaArray);
    const removedMedia = await MediaApi.GetByMediaId(
      parseInt(deletedMediaArray)
    );
    //document.querySelector(".spinnerDel").style.display = "block";
    //const carousel = document.querySelector(".overlayed");

    await MediaApi.MediaDelete(parseInt(deletedMediaArray));
    //document.querySelector(".spinnerDel").style.display = "none";
    //unBlurEffect(carousel);

    //blurEffect(carousel);

    if (removedMedia.media_type === "10") {
      let arr = mediaValue;
      const index = arr.indexOf(
        arr.find((file) => file.mediaId === parseInt(deletedMediaArray))
      );
      console.log(index);
      arr.splice(index, 1);
      setMediaValue((prevState) => (prevState = arr));
      setRemovedElement(!removedElement);

      // document.getElementById("20").click();
      // document.getElementById("10").click();
    } else if (removedMedia.media_type === "20") {
      let arrVid = mediaValueVid;
      const index = arrVid.indexOf(
        arrVid.find((file) => file.mediaId === parseInt(deletedMediaArray))
      );
      console.log(index);
      arrVid.splice(index, 1);
      setMediaValueVid((prevState) => (prevState = arrVid));
      setRemovedElement(!removedElement);

      console.log(arrVid);
      // document.getElementById("10").click();
      // document.getElementById("20").click();

      //console.log(arrVid);
    } else if (removedMedia.media_type === "30") {
      let arrAud = mediaValueAud;
      const index = arrAud.indexOf(
        arrAud.find((file) => file.mediaId === parseInt(deletedMediaArray))
      );
      console.log(index);
      arrAud.splice(index, 1);
      setMediaValueAud((prevState) => (prevState = arrAud));
      setRemovedElement(!removedElement);

      console.log(arrAud);

      // document.getElementById("10").click();
      // document.getElementById("30").click();
    } else if (removedMedia.media_type === "40") {
      let arrVid360 = mediaValueVid360;
      const index = arrVid360.indexOf(
        arrVid360.find((file) => file.mediaId === parseInt(deletedMediaArray))
      );
      console.log(index);
      arrVid360.splice(index, 1);

      setMediaValueVid360((prevState) => (prevState = arrVid360));
      console.log(arrVid360);
      setRemovedElement(!removedElement);

      // document.getElementById("10").click();
      // document.getElementById("40").click();
    }
  };

  const removeMediaItemArrayMulti = async () => {
    console.log(multipleDeleteArray);
    for(let item of multipleDeleteArray) {

      const removedMedia = await MediaApi.GetByMediaId(
        parseInt(item)
      );
      //document.querySelector(".spinnerDel").style.display = "block";
      //const carousel = document.querySelector(".overlayed");

      await MediaApi.MediaDelete(parseInt(item));
      //document.querySelector(".spinnerDel").style.display = "none";
      //unBlurEffect(carousel);

      //blurEffect(carousel);

      if (removedMedia.media_type === "10") {
        let arr = mediaValue;
        const index = arr.indexOf(
          arr.find((file) => file.mediaId === parseInt(item))
        );
        console.log(index);
        arr.splice(index, 1);
        setMediaValue((prevState) => (prevState = arr));
        setRemovedElement(!removedElement);

        // document.getElementById("20").click();
        // document.getElementById("10").click();
      } else if (removedMedia.media_type === "20") {
        let arrVid = mediaValueVid;
        const index = arrVid.indexOf(
          arrVid.find((file) => file.mediaId === parseInt(item))
        );
        console.log(index);
        arrVid.splice(index, 1);
        setMediaValueVid((prevState) => (prevState = arrVid));
        setRemovedElement(!removedElement);

        console.log(arrVid);
        // document.getElementById("10").click();
        // document.getElementById("20").click();

        //console.log(arrVid);
      } else if (removedMedia.media_type === "30") {
        let arrAud = mediaValueAud;
        const index = arrAud.indexOf(
          arrAud.find((file) => file.mediaId === parseInt(item))
        );
        console.log(index);
        arrAud.splice(index, 1);
        setMediaValueAud((prevState) => (prevState = arrAud));
        setRemovedElement(!removedElement);

        console.log(arrAud);

        // document.getElementById("10").click();
        // document.getElementById("30").click();
      } else if (removedMedia.media_type === "40") {
        let arrVid360 = mediaValueVid360;
        const index = arrVid360.indexOf(
          arrVid360.find((file) => file.mediaId === parseInt(item))
        );
        console.log(index);
        arrVid360.splice(index, 1);

        setMediaValueVid360((prevState) => (prevState = arrVid360));
        console.log(arrVid360);
        setRemovedElement(!removedElement);

        // document.getElementById("10").click();
        // document.getElementById("40").click();
      }
      
    }

    setMultipleDeleteArray([]);

  };

  const changeDelState = () => {
    console.log(multipleDeleteArray);
    if (multipleDeleteArray.length == 0) {
      Swal.fire({
        title:
          languageChosenSite === "EL"
            ? languageGr.media.noItemsTitle
            : language.media.noItemsTitle,
        text:
          languageChosenSite === "EL"
            ? languageGr.media.noItemsChosen
            : language.media.noItemsChosen,
        icon: "warning",
        button: "OK",
      });
    } else {
      Swal.fire({
        title:
          languageChosenSite === "EL"
            ? languageGr.media.confirmMessage
            : language.media.confirmMessage,
        html: `${
          languageChosenSite === "EL"
            ? languageGr.deleteSelected.multiDelete
            : language.deleteSelected.multiDelete
        }   <b>${multipleDeleteArray.length}</b>`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText:
          languageChosenSite === "EL"
            ? languageGr.content.del
            : language.content.del,
      }).then(async (result) => {
        if (result.isConfirmed) {

          setMultiDel(!multiDel);
          //const deleted = await ExhibitNew.ObjectDelete(parseInt(target));
          //multipleDeleteArray.forEach(async function (selectedId) {
            
         //const set = setDeletedMediaArray(selectedId);
                
            //setTimeout(set, 200);
            //await MediaApi.MediaDelete(parseInt(selectedId));
            //setTableChanged(!tableChanged);
          //});

          // const reload = () => {
          //   window.location.reload();
          // };

          // setTimeout(reload, 1200);
        }
      });
    }
  };

 

  const blurEffect = (element) => {
    element.style.opacity = "0.7";
  };

  const unBlurEffect = (element) => {
    element.style.opacity = "1";
  };

  const getNewData = async () => {
    const addedMedia = await MediaApi.GetByMediaId(changedMediaArray);
    console.log(addedMedia);
    const tokenObject = await authService.getTokenAsync();
    const token = tokenObject.accessToken;
    fetchUser();
    if (addedMedia.media_type === "10") {
      setMediaValue((prevState) => [...prevState, addedMedia]);
    } else if (addedMedia.media_type === "20") {
      const uri = `https://api.dei.prismasense.com/Xenagos/api/v1/media/uri/video/${addedMedia.mediaId}/${token}`;
      setMediaValueVid((prevState) => [
        ...prevState,
        { ...addedMedia, value: uri },
      ]);
    } else if (addedMedia.media_type === "30") {
      setMediaValueAud((prevState) => [...prevState, addedMedia]);
    } else if (addedMedia.media_type === "40") {
      const uri = `https://api.dei.prismasense.com/Xenagos/api/v1/media/uri/video/${addedMedia.mediaId}/${token}`;
      setMediaValueVid360((prevState) => [
        ...prevState,
        { ...addedMedia, value: uri },
      ]);
    }
  };

  const fetchUser = async () => {
    const user = await authService.getUserAsync();

    setUserRole(user.claims[0].value);
  };

  // const [isLoading, setIsLoading] = useState(true);
  const media10Array = [];
  const updateImages = (src, alt) => {
    const imgs = images;
    imgs[0].original = src;
    imgs[0].description = alt;

    setImages(imgs);
  };

  const changeHead = () => {
    if (sessionStorage.getItem("languageChosen") === "EL") {
      document.querySelector(
        ".pageHead"
      ).innerHTML = `<div style="width: 100%; height:100%; display: inline-flex; justify-content: center;"><h4 style='padding-top: 3.5vh;text-align: center;color: #f7aa47;'><img style="height: 4vh;margin-top:-1vh;" class='fa fa-fw fa-file' src='${manage}' />${languageGr.global.titles.media}</h4></div>`;
    } else {
      document.querySelector(
        ".pageHead"
      ).innerHTML = `<div style="width: 100%; height:100%; display: inline-flex; justify-content: center;"><h4 style='padding-top: 3.5vh;text-align: center;color: #f7aa47;'><img style="height: 4vh;margin-top:-1vh;" class='fa fa-fw fa-file' src='${manage}' />${language.global.titles.media}</h4></div>`;
    }
  };
  // const onUploadPress = (e) => {

  //   setUploadState(!uploadState);
  //   //setModalShow(true)
  //   //AddMedia();
  // }

  // const onUploadBack = () => {
  //   setUploadState(!uploadState);
  //   //setModalShow(false);

  // }

  // const AddMedia = () => {
  //   console.log("addMedia");

  //   document.getElementById("submit").disabled = true;

  //           //Check File API support

  //           // if (window.File && window.FileList && window.FileReader) {

  //           // }
  //           // else {
  //           //     //console.log("Your browser does not support File API");
  //           // }

  // }

  // const clickPhoto = (e) => {
  //   setClickedPhoto(!clickedPhoto);

  //   if (e.target.src) {

  //     updateImages(e.target.src, e.target.alt);
  //   }
  // };

  const ChangeDesc = (e) => {
    //console.log(e.target.parentElement.nextElementSibling.children[1]);
    const buttonOfDescription = e.target.nextElementSibling;
    const buttonOfDeletion = e.target.nextElementSibling.nextElementSibling;
    // const buttonOfCheckbox = e.target.nextElementSibling.nextElementSibling.nextElementSibling;
    //setClickedDesc(true);
    buttonOfDescription.style.display = "block";
    buttonOfDeletion.style.display = "block";
    // buttonOfCheckbox.style.display = "block";

    // e.target.nextElementSibling.style.display =  "block"
  };

  // const HoverCarouselOut = (e) => {
  //   console.log(e.target);
  //   const buttonOfDescription = e.target.parentElement.parentElement.nextElementSibling.children[1];
  //   const buttonOfDeletion = e.target.parentElement.parentElement.nextElementSibling.children[2];
  //   //setClickedDesc(true);
  //   buttonOfDescription.style.display ="none";
  //   buttonOfDeletion.style.display ="none";
  // }

  // const HoverCarouselIn = (e) => {
  //   console.log(e.target);
  //   const buttonOfDescription = e.target.parentElement.parentElement.nextElementSibling.children[1];
  //   const buttonOfDeletion = e.target.parentElement.parentElement.nextElementSibling.children[2];
  //   //setClickedDesc(true);
  //   buttonOfDescription.style.display ="block";
  //   buttonOfDeletion.style.display ="block";
  // }

  const HoverImage = (e) => {
    
    // const buttonOfDescription =
    //   e.target.parentElement.nextElementSibling.children[1];
    // const buttonOfDeletion =
    //   e.target.parentElement.nextElementSibling.children[2];
    // //setClickedDesc(true);
    // // const buttonCheckbox =
    // //   e.target.parentElement.nextElementSibling.children[3];
    // // buttonOfDescription.style.display = "block";
    // buttonOfDeletion.style.display = "block";
    // buttonCheckbox.style.display = "block";
  };

  const HoverVideo = (e) => {
    //console.log(e.target.parentElement.children[3].children[0].children[2]);
    // const buttonOfDescription =
    // e.target.parentElement.nextElementSibling.children[1];
    const buttonOfDeletion =
    e.target.parentElement.children[3].children[0].children[2]
    //setClickedDesc(true);
    
      // e.target.parentElement.nextElementSibling.children[3];
      // buttonOfDescription.style.display = "none";
      buttonOfDeletion.style.display = "block";
    // buttonCheckbox.style.display = "none";
  };

  const HoverAudio = (e) => {
    //console.log(e.target.parentElement.children[3].children[0].children[2]);
    // const buttonOfDescription =
    // e.target.parentElement.nextElementSibling.children[1];
    console.log(e.target);
    const buttonOfDeletion =
    e.target.children[2].children[2]
        //setClickedDesc(true);
    
      // e.target.parentElement.nextElementSibling.children[3];
      // buttonOfDescription.style.display = "none";
      buttonOfDeletion.style.display = "block";
    // buttonCheckbox.style.display = "none";
  };


  const HoverVideoOut = (e) => {
    //console.log(e.target.parentElement.children[3].children[0].children[2]);
    // const buttonOfDescription =
    // e.target.parentElement.nextElementSibling.children[1];
    const buttonOfDeletion =
    e.target.parentElement.children[3].children[0].children[2]
    //setClickedDesc(true);
    
      // e.target.parentElement.nextElementSibling.children[3];
      // buttonOfDescription.style.display = "none";
      buttonOfDeletion.style.display = "none";
    // buttonCheckbox.style.display = "none";
  };

  const HoverAudioOut = (e) => {
    //console.log(e.target.parentElement.children[3].children[0].children[2]);
    // const buttonOfDescription =
    // e.target.parentElement.nextElementSibling.children[1];
    const buttonOfDeletion =
    e.target.children[2].children[2]
    //setClickedDesc(true);
    
      // e.target.parentElement.nextElementSibling.children[3];
      // buttonOfDescription.style.display = "none";
      buttonOfDeletion.style.display = "none";
    // buttonCheckbox.style.display = "none";
  };



  const HoverImageOut = (e) => {
    
    const buttonOfDescription =
    e.target.parentElement.nextElementSibling.children[1];
    const buttonOfDeletion = e.target.parentElement.nextElementSibling.children[2];
    //setClickedDesc(true);
   
      // e.target.parentElement.nextElementSibling.children[3];
      buttonOfDescription.style.display = "none";
      buttonOfDeletion.style.display = "none";
    // buttonCheckbox.style.display = "none";
  };

  const HoverTitleOut = (e) => {
    let buttonOfDescription = e.target.children[1];
    let buttonOfDeletion = buttonOfDescription.nextElementSibling;
    // const buttonCheckbox =
    // buttonOfDeletion.nextElementSibling;
    //setClickedDesc(true);
    buttonOfDescription.style.display = "none";
    buttonOfDeletion.style.display = "none";
    // buttonCheckbox.style.display = "none";

    // e.target.nextElementSibling.style.display =  "block"
  };

  const HoverTitleOutVid = (e) => {
    console.log(e.target);
    const buttonOfDescription = e.target.nextElementSibling;
    const buttonOfDeletion = buttonOfDescription.nextElementSibling;
    // const buttonCheckbox =
    // buttonOfDeletion.nextElementSibling;
    //setClickedDesc(true);
    buttonOfDescription.style.display = "none";
    buttonOfDeletion.style.display = "none";
    // buttonCheckbox.style.display = "none";

    // e.target.nextElementSibling.style.display =  "block"
  };

  const HoverTitle = (e) => {
    if(true) {
      return;
    }
    //console.log(e.target);
    if(true) {
      return;
    }
    const buttonOfDescription = e.target.nextElementSibling;
    const buttonOfDeletion = buttonOfDescription.nextElementSibling;
    // const buttonCheckbox =
    // buttonOfDeletion.nextElementSibling;
    //setClickedDesc(true);
    // buttonOfDescription.style.display = "block";
    buttonOfDeletion.style.display = "block";
    // buttonCheckbox.style.display = "block";

    // e.target.nextElementSibling.style.display =  "block"
  };

  async function InitToLanguageId(init) {
    var l = await LanguageApi.Get();

    var langObject = l.find((l) => l.initials === init);

    if (langObject) {
      return langObject.languageId;
    }
  }

  const onCancel = () => {
    setSelectedImage(null);
  };

  const setCroppedImageFor = (id, crop, zoom, aspect, croppedImageUrl) => {
    const newMedia = [...mediaValue];

    const imageIndex = mediaValue.findIndex((x) => x.mediaId === id);

    const med = mediaValue[imageIndex];

    const newMed = { ...med, croppedImageUrl, crop, zoom, aspect };

    newMedia[imageIndex] = newMed;
    setMediaValue(newMedia);
    setSelectedImage(null);
  };

  const setCroppedImagetoCreate = (id, crop, zoom, aspect, croppedImageUrl) => {
    const newMedia = [...mediaValue];

    const imageIndex = mediaValue.findIndex((x) => x.mediaId === id);

    const med = mediaValue[imageIndex];

    const newMed = { ...med, croppedImageUrl, crop, zoom, aspect };

    return newMed.croppedImageUrl;
  };

  const UpdateMediaDescription = async (e) => {
    if (userRole == "Viewer") {
      Swal.fire({
        title:
          languageChosenSite == "EL"
            ? languageGr.media.invalidAction
            : language.media.invalidAction,
        text:
          languageChosenSite == "EL"
            ? languageGr.global.userRole
            : language.global.userRole,
        icon: "warning",
        button: "OK",
      });
      return;
    }
    e.target.innerHTML =
      '<span style="text-align:center;" class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>';
    //setClickedDesc(!clickedDesc);
    console.log(
      e.currentTarget.parentElement.parentElement.children[1].children[0]
        
    );
    const targetedId =
    e.currentTarget.parentElement.parentElement.children[1].children[0].id;
    // const targetedSrc =
    //   e.target.parentElement.parentElement.children[1].children[0].src;

    const inputTarget = e.currentTarget.previousElementSibling;
    console.log(inputTarget);

    const mediaTarget = await MediaApi.GetByMediaId(targetedId);

    const languageId = await InitToLanguageId(mediaTarget.initials);
    const mediaUpd = {
      name: mediaTarget.name,
      description: inputTarget.value,
      refpath: mediaTarget.description
        ? mediaTarget.description
        : mediaTarget.name,
      typeId: mediaTarget.media_type,
      languageId: languageId,
      mediaId: mediaTarget.mediaId,
    };

    console.log(mediaUpd);

    const updatedMedia = await MediaApi.Update(mediaUpd);
    //setClickedDesc(false);
    e.target.innerHTML = "Save";
    // e.target.style.display = "none";
  };

  const UpdateMediaDescription360 = async (e) => {
    if (userRole == "Viewer") {
      Swal.fire({
        title:
          languageChosenSite == "EL"
            ? languageGr.media.invalidAction
            : language.media.invalidAction,
        text:
          languageChosenSite == "EL"
            ? languageGr.global.userRole
            : language.global.userRole,
        icon: "warning",
        button: "OK",
      });
      return;
    }
    e.target.innerHTML =
      '<span style="text-align:center;" class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>';
    //setClickedDesc(!clickedDesc);
    console.log(e.target.parentElement.parentElement.parentElement);
    let targetedId =
      e.target.parentElement.parentElement.parentElement.children[1].children[0]
        .id;
    // const targetedSrc =
    //   e.target.parentElement.parentElement.parentElement.children[0].children[0]
    //     .src;

    //let targetElement = e.target.parentElement.previousElementSibling.children[0];
    //let targetedId = targetElement.parentElement.parentElement.children[1].children[0].id;

    const inputTarget = e.target.previousElementSibling;
    console.log(inputTarget);

    const mediaTarget = await MediaApi.GetByMediaId(targetedId);

    const languageId = await InitToLanguageId(mediaTarget.initials);
    const mediaUpd = {
      name: mediaTarget.name,
      description: inputTarget.value,
      refpath: mediaTarget.description
        ? mediaTarget.description
        : mediaTarget.name,
      typeId: mediaTarget.media_type,
      languageId: languageId,
      mediaId: mediaTarget.mediaId,
    };

    const updatedMedia = await MediaApi.Update(mediaUpd);
    //setClickedDesc(false);
    e.target.innerHTML = "Save";
    e.target.style.display = "none";
  };

  const onChangeLanguage = (e) => {
    setLanguageState(e);
  };

  const DeleteImage = async (e) => {
    
    if (userRole == "Viewer") {
      Swal.fire({
        title:
          languageChosenSite == "EL"
            ? languageGr.media.invalidAction
            : language.media.invalidAction,
        text:
          languageChosenSite == "EL"
            ? languageGr.global.userRole
            : language.global.userRole,
        icon: "warning",
        button: "OK",
      });
      return;
    }
    let targetElement =
      e.currentTarget.parentElement.previousElementSibling.children[0];
    let targetId = targetElement.parentElement.parentElement.children[1].children[0].id;
    console.log(targetElement.parentElement.parentElement);
    Swal.fire({
      title: "Are you sure?",
      text: `You are about to delete the media with id ${targetId}`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
    }).then(async (result) => {
      if (result.isConfirmed) {
        //const deleted = await MediaApi.MediaDelete(parseInt(targetId));
        setDeletedMediaArray(targetId);

        //removeFadeOut(targetElement.parentElement.parentElement, 600);
      }
    });
  };



  const DeleteVideo = async (e) => {
    
    if (userRole == "Viewer") {
      Swal.fire({
        title:
          languageChosenSite == "EL"
            ? languageGr.media.invalidAction
            : language.media.invalidAction,
        text:
          languageChosenSite == "EL"
            ? languageGr.global.userRole
            : language.global.userRole,
        icon: "warning",
        button: "OK",
      });
      return;
    }
    let targetElement =
      e.currentTarget.parentElement.previousElementSibling.children[0];
    let targetId = targetElement.parentElement.parentElement.children[1].children[0].id;
    console.log(targetElement.parentElement.parentElement);
    Swal.fire({
      title: "Are you sure?",
      text: `You are about to delete the media with id ${targetId}`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
    }).then(async (result) => {
      if (result.isConfirmed) {
        //const deleted = await MediaApi.MediaDelete(parseInt(targetId));
        setDeletedMediaArray(targetId);

        //removeFadeOut(targetElement.parentElement.parentElement, 600);
      }
    });
  };

  const fetchIdForMultipleDelete = (e) => {
    let targetElement =
      e.target.parentElement.previousElementSibling.children[0];
    let targetId = e.target.parentElement.previousElementSibling.children[0].id;
    console.log(targetElement.parentElement.parentElement);
    setMultipleDeleteArray((prevState) => ([...prevState], targetId));
  };

  

  const DeleteVideoWith360 = async (e) => {
    console.log(e.currentTarget.parentElement.parentElement.parentElement);
    if (userRole == "Viewer") {
      Swal.fire({
        title:
          languageChosenSite == "EL"
            ? languageGr.media.invalidAction
            : language.media.invalidAction,
        text:
          languageChosenSite == "EL"
            ? languageGr.global.userRole
            : language.global.userRole,
        icon: "warning",
        button: "OK",
      });
      return;
    }
    //console.log(e.target);
    let targetElement = e.currentTarget.parentElement.parentElement.parentElement.children[1].children[0];

    
    let targetId = targetElement.id
    console.log(targetId);

    Swal.fire({
      title: "Are you sure?",
      text: `You are about to delete the media with id ${targetId}`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setDeletedMediaArray(targetId);

        // Swal.fire(
        //   'Deleted!',
        //   'Your file has been sent to Hell.',
        //   'success'
        // )
        //removeFadeOut(targetElement.parentElement, 600);
      }
    });
  };

  // const showMediaPerLang = (languageState, initials) => {
  //   if(languageState === initials) {
  //     setMediaShow(true);
  //   } else {
  //     setMediaShow(false);
  //   }
  // }

  const getData = async (languageState) => {
    //const l = await LanguageApi.Get();
    //updateLangFilter(l);
    const mediaArray = await MediaApi.Get();
    const allMedia = mediaArray.reverse();
    //allMedia.shift();
    let tokenObject = await authService.getTokenAsync();
    let token = tokenObject.accessToken;
    for (let media of allMedia) {
      if (media.type === "10") {
        //const test = await MediaApi.GetByMediaIdAll("20")
        //console.log(test);

        const photo = await MediaApi.GetByMediaId(parseInt(media.mediaId));
        //media10Array.push(photo);
        setMediaValue((prevState) => [...prevState, photo]);

        //, {value:`data:image/png;base64, ${photo.value}`}
        // media10Array.forEach(med =>

        //   med.value = `data:image/png;base64, ${photo.value}`
        // );
      } else if (media.type === "20") {
        const video = await MediaApi.GetByMediaId(parseInt(media.mediaId));

        //media10Array.push(photo);
        const uri = `https://api.dei.prismasense.com/Xenagos/api/v1/media/uri/video/${media.mediaId}/${token}`;
        setMediaValueVid((prevState) => [
          ...prevState,
          { ...video, value: uri },
        ]);

        //, {value:`data:image/png;base64, ${photo.value}`}
        // media10Array.forEach(med =>

        //   med.value = `data:image/png;base64, ${photo.value}`
        // );
      } else if (media.type === "40") {
        const video = await MediaApi.GetByMediaId(parseInt(media.mediaId));
        //media10Array.push(photo);
        // const sources = [ // Declare an array of video sources
        //     { url: '/videos/video.webm', type: 'video/webm' },
        //     { url: '/videos/video.mp4', type: 'video/mp4' }
        // ];
        const uri = `https://api.dei.prismasense.com/Xenagos/api/v1/media/uri/video/${media.mediaId}/${token}`;
        //sources.push({url: uri, type: 'video/webm'});
        setMediaValueVid360((prevState) => [
          ...prevState,
          { ...video, value: uri },
        ]);

        //console.log(sources);
        //console.log(mediaValueVid360);
        //, {value:`data:image/png;base64, ${photo.value}`}
        // media10Array.forEach(med =>

        //   med.value = `data:image/png;base64, ${photo.value}`
        // );
      } else if (media.type === "30") {
        const audio = await MediaApi.GetByMediaId(parseInt(media.mediaId));
        //media10Array.push(photo);
        //const uri = `https://api.dei.prismasense.com/Xenagos/api/v1/media/uri/video/${media.mediaId}/${token}`;
        setMediaValueAud((prevState) => [...prevState, audio]);

        //, {value:`data:image/png;base64, ${photo.value}`}
        // media10Array.forEach(med =>

        //   med.value = `data:image/png;base64, ${photo.value}`
        // );
      }
    }
    //setMedia10(allMedia);
    //setMediaValue(media10Array);
    //console.log(media10Array);

    //setImages(previewImages);
    //setIsLoading(false);
    //asyncLoad();
    //console.log(mediaValue);
  };

  const handleChangeVideo = (event) => {
    console.log(event.target.parentElement);
    if (event.target.checked) {
      setIsClicked(true);
      //fetchIdForMultipleDelete();
      let arr = multipleDeleteArray;
      //let targetId = event.target.parentElement.previousElementSibling.children[0].id;
      let targetId =
        event.target.parentElement.children[1].children[0].id;
      arr.push(targetId);

      console.log(targetId);
      setMultipleDeleteArray((prevState) => arr);
    } else {
      setIsClicked(false);
      //setMultipleDeleteArray(prevState => ([...prevState], targetId));

      let arr = multipleDeleteArray;
      
      let targetId = event.target.parentElement.children[1].children[0].id;
      console.log(arr);
      const index = arr.indexOf(arr.find((file) => file == targetId));
      console.log(targetId);
      console.log(index);
      arr.splice(index, 1);
      setMultipleDeleteArray((prevState) => (prevState = arr));
    }
  };

  const handleChange = (event) => {
    console.log(event.target.nextElementSibling);
    if (event.target.checked) {
      setIsClicked(true);
      //fetchIdForMultipleDelete();
      let arr = multipleDeleteArray;
      let targetId = event.target.nextElementSibling.children[0].id;
      arr.push(targetId);

      console.log(targetId);
      setMultipleDeleteArray((prevState) => arr);
    } else {
      setIsClicked(false);
      //setMultipleDeleteArray(prevState => ([...prevState], targetId));

      let arr = multipleDeleteArray;
      // let targetId = event.target.parentElement.previousElementSibling.children[0].id;
      let targetId = event.target.nextElementSibling.children[0].id;

      console.log(arr);
      const index = arr.indexOf(arr.find((file) => file == targetId));
      console.log(targetId);
      console.log(index);
      arr.splice(index, 1);
      setMultipleDeleteArray((prevState) => (prevState = arr));
    }
    //setIsClicked(current => !current);
  };

  const Toggler360 = async (e) => {
    e.preventDefault();
    
    if (userRole == "Viewer") {
      Swal.fire({
        title:
          languageChosenSite == "EL"
            ? languageGr.media.invalidAction
            : language.media.invalidAction,
        text:
          languageChosenSite == "EL"
            ? languageGr.global.userRole
            : language.global.userRole,
        icon: "warning",
        button: "OK",
      });
      return;
    }
    //setSpinnerShow(true);
    //console.log(e.target.parentElement.parentElement.parentElement.parentElement.children[0].children[0].getAttribute("id"));
    const video360Id =
      e.target.parentElement.parentElement.parentElement.parentElement.children[1].children[0].getAttribute(
        "id"
      );
    const loadingDiv =
      e.target.parentElement.parentElement.parentElement.parentElement
        .children[0];
    const loadingIcon =
      e.target.parentElement.parentElement.parentElement.parentElement
        .children[1].nextElementSibling;
    loadingDiv.style.opacity = "0.5";
    loadingIcon.style.display = "block";
    const ob = await MediaApi.GetByMediaId(video360Id);
    console.log(ob);
    Swal.fire({
      title: "Are you sure?",
      text: `Change the 360 view on the ${ob.name} video `,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Proceed",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const media = {
          name: ob.name,
          description: ob.description,
          refpath: ob.name,
          typeId: ob.media_type === "40" ? "20" : "40",
          languageId: await InitToLanguageId(ob.initials),
          mediaId: ob.mediaId,
        };

        //console.log(media);
        const updated = await MediaApi.Update(media);
        //console.log(updated);
        loadingDiv.style.opacity = "1";
        //setSpinnerShow(false);
        loadingIcon.style.display = "none";

        e.target.checked = updated.mediA_TYPE === "40" ? true : false;
        // Swal.fire(
        //   'Deleted!',
        //   'Your file has been sent to Hell.',
        //   'success'
        // )
      } else {
        loadingDiv.style.opacity = "1";
        //setSpinnerShow(false);
        loadingIcon.style.display = "none";
        e.target.checked = e.target.checked;
      }
    });
  };

  const ClickUpload = () => {
    console.log(userRole);
    if (userRole == "Viewer") {
      Swal.fire({
        title:
          languageChosenSite == "EL"
            ? languageGr.media.invalidAction
            : language.media.invalidAction,
        text:
          languageChosenSite == "EL"
            ? languageGr.global.userRole
            : language.global.userRole,
        icon: "warning",
        button: "OK",
      });
      return;
    }
    setModalShow(true);
    setUploadState(true);
  };

  const ExitUpload = () => {
    setModalShow(false);
    setUploadState(false);
  };

  return (
    <div className="overlayed container">
      {/* <Spinner
        animation="border"
        className="spinnerDel"
        size="lg"
        style={{ display: "none" }}
      /> */}
      <Row>
        <Col sm={12}>
          <Button
            variant="addNewButton"
            id="uploadButton"
            className="addNewButton"
            onClick={ClickUpload}
          >
            {languageChosenSite === "EL"
              ? languageGr.global.mediaWords.upload
              : language.global.mediaWords.upload}{" "}
            <BsIcons.BsArrowBarUp />
          </Button>
          <button
            className="btn btn-danger"
            style={{ "border-radius": "18px" }}
            onClick={changeDelState}
          >
            {languageChosenSite === "EL"
              ? languageGr.global.multipleDel
              : language.global.multipleDel}
          </button>
        </Col>
      </Row>

      <Row>
        <Col sm={12}>
          <Media
            onChangeMediaArray={setMediaValue}
            getData={getData}
            setMediaValue={setMediaValue}
            show={modalShow}
            onHide={ExitUpload}
            setChangedMediaArray={setChangedMediaArray}
            languageChosenSite={languageChosenSite}
          />
        </Col>
      </Row>

      <div>
        <Filters
          onSetLanguageState={onChangeLanguage}
          onSetMediaTypeState={setMediaTypeState}
          onSetSearchFilter={setQueryTyped}
          languageChosenSite={languageChosenSite}
        />
      </div>

      {!clickedPhoto && (
        <Carousel cols={4} rows={2} gap={5} tabIndex={2}>
          {mediaValue
            .filter((medDefault) => {
              if (queryTyped === "") {
                return medDefault;
              } else if (
                medDefault.description !== ""
                  ? medDefault.description
                      .toLowerCase()
                      .includes(queryTyped.toLowerCase())
                  : medDefault.name
                      .toLowerCase()
                      .includes(queryTyped.toLowerCase())
              ) {
                return medDefault;
              }
            })
            .map(
              (medDefault) =>
                medDefault.initials === languageState &&
                mediaTypeState === "10" && (
                  <Carousel.Item>
                    <div className="imageHolder" onMouseLeave={HoverImageOut} >
                    <input
                      //onClick={fetchIdForMultipleDelete}
                      type="checkbox"
                      value={isClicked}
                      onChange={handleChange}
                      style={{ "margin-left": "1vw" }}
                      className="checkboxForCarousel"
                    />
                    <LazyLoadImage
                      width="87%"
                      height="83%"
                      alt={
                        medDefault.description !== ""
                          ? medDefault.description
                          : medDefault.name
                      }
                      id={medDefault.mediaId}
                      key={medDefault.mediaId}
                      
                      effect="blur"
                      className="roundBox"
                      
                      src={
                        medDefault.croppedImageUrl
                          ? medDefault.croppedImageUrl
                          : `data:image/png;base64, ${medDefault.value}`
                      }
                      onClick={() => setSelectedImage(medDefault)}
                      onMouseEnter={HoverImage}
                    />
                    <div
                      className="title"
                      onMouseEnter={HoverTitle}
                      onMouseLeave={HoverTitleOut}
                    >
                      <input
                        className="description_Media"
                        onClick={ChangeDesc}
                        defaultValue={
                          medDefault.description !== ""
                            ? medDefault.description
                            : medDefault.name
                        }
                      />
                      <button
                        onClick={UpdateMediaDescription}
                        className="btn btn-sm btn-success save_Description"
                      >
                        Save
                        {/* <BsIcons.BsCheckLg /> */}
                      </button>
                      <button
                        onClick={DeleteImage}
                        className="btn btn-sm btn-danger save_Description"
                      >
                        
                        <FaIcons.FaRegTrashAlt
                          style={{ "font-size": "20px", cursor: "pointer","color": "white" }}
                          //id={row.objectS_ID}
                          className="deleteIcon"
                         
                        />
                      </button>
                    </div>
                    </div>
                  </Carousel.Item>
                )
            )}

          {mediaValueVid
            .filter((video) => {
              if (queryTyped === "") {
                return video;
              } else if (
                video.description !== ""
                  ? video.description
                      .toLowerCase()
                      .includes(queryTyped.toLowerCase())
                  : video.name.toLowerCase().includes(queryTyped.toLowerCase())
              ) {
                return video;
              }
            })
            .map(
              (video) =>
                video.initials === languageState &&
                mediaTypeState === video.media_type && (
                  <Carousel.Item>
                    
                    <div className="video_container"  >
                    <input
                            //onClick={fetchIdForMultipleDelete}
                            className="checkboxForCarouselVid"
                            type="checkbox"
                            value={isClicked}
                            onChange={handleChangeVideo}
                            style={{ "margin-left": "1vw" }}
                          />
                      <video
                        className="embed-responsive-item roundBox"
                        controls
                        
                      >
                        <source
                          id={video.mediaId}
                          src={video.value}
                          type="video/mp4"
                        ></source>
                      </video>
                      <Spinner
                        animation="border"
                        className="spinner"
                        size="lg"
                        style={{ display: "none" }}
                      />
                      <div className="titleVid360" >
                        <div className="saveDel"  onMouseLeave={HoverTitleOut}>
                          <input
                            className="description_Media"
                            onClick={ChangeDesc}
                            defaultValue={
                              video.description !== ""
                                ? video.description
                                : video.name
                            }
                          />
                          <button
                            onClick={UpdateMediaDescription360}
                            className="btn btn-sm btn-success save_Description"
                          >
                            Save
                          </button>
                          <button
                            onClick={DeleteVideoWith360}
                            className="btn btn-sm btn-danger save_Description"
                          >
                             <FaIcons.FaRegTrashAlt
                          style={{ "font-size": "20px", cursor: "pointer","color": "white" }}
                          //id={row.objectS_ID}
                          className="deleteIcon"
                         
                        />
                          </button>
                          
                           
                        </div>
                        <br />
                        <div className="toggler360">
                          <label>360°</label>
                          <div
                            className="form-check form-switch"
                            style={{ "margin-left": "5%" }}
                          >
                            <input
                              className="form-check-input"
                              type="checkbox"
                              onChange={Toggler360}
                            />
                           
                          </div>
                        </div>
                      </div>
                    </div>
                  </Carousel.Item>
                )
            )}

          {mediaValueAud
            .filter((audio) => {
              if (queryTyped === "") {
                return audio;
              } else if (
                audio.description !== ""
                  ? audio.description
                      .toLowerCase()
                      .includes(queryTyped.toLowerCase())
                  : audio.name.toLowerCase().includes(queryTyped.toLowerCase())
              ) {
                return audio;
              }
            })
            .map(
              (audio) =>
                audio.initials === languageState &&
                mediaTypeState === "30" && (
                  <Carousel.Item>
                    <div className="divAudio"  onMouseLeave={HoverAudioOut} onMouseEnter={HoverAudio}>
                    <input
                        //onClick={fetchIdForMultipleDelete}
                        className="audioCheckbox"
                        type="checkbox"
                        value={isClicked}
                        onChange={handleChange}
                        style={{ "margin-left": "1vw" }}
                      />
                       
                    <audio className="embed-responsive-item" controls>
                      <source
                        id={audio.mediaId}
                        src={`data:audio/mp3;base64, ${audio.value}`}
                        type="audio/mp3"
                      ></source>
                    </audio>
                    <div className="titleAud"  onMouseLeave={HoverTitleOut}>
                      <input
                        className="description_Media"
                        onClick={ChangeDesc}
                        defaultValue= {
                          audio.description !== ""
                            ? audio.description
                            : audio.name
                        }
                      />
                      <button
                        onClick={UpdateMediaDescription}
                        className="btn btn-sm btn-success save_Description"
                      >
                        Save
                      </button>
                      <button
                        onClick={DeleteVideo}
                        className="btn btn-sm btn-danger save_Description"
                      >
                         <FaIcons.FaRegTrashAlt
                          style={{ "font-size": "20px", cursor: "pointer","color": "white" }}
                          //id={row.objectS_ID}
                          className="deleteIcon"
                         
                        />
                      </button>
                      
                    </div>
                    </div>
                  </Carousel.Item>
                )
            )}

          {mediaValueVid360
            .filter((video) => {
              if (queryTyped === "") {
                return video;
              } else if (
                video.description !== ""
                  ? video.description
                      .toLowerCase()
                      .includes(queryTyped.toLowerCase())
                  : video.name.toLowerCase().includes(queryTyped.toLowerCase())
              ) {
                return video;
              }
            })
            .map(
              (video) =>
                video.initials === languageState &&
                mediaTypeState === video.media_type && (
                  <Carousel.Item>
                    <div className="video_container"  onMouseLeave={HoverVideoOut} onMouseEnter={HoverVideo}>
                    <input
                            //onClick={fetchIdForMultipleDelete}
                            className="checkboxForCarouselVid"
                            type="checkbox"
                            value={isClicked}
                            onChange={handleChangeVideo}
                            style={{ "margin-left": "1vw" }}
                          />
                      <video
                        className="embed-responsive-item roundBox"
                        controls
                      >
                        <source
                          id={video.mediaId}
                          src={video.value}
                          type="video/mp4"
                        ></source>
                      </video>
                      <Spinner
                        animation="border"
                        className="spinner"
                        size="lg"
                        style={{ display: "none" }}
                      />
                      {/* <VrPlayer
                      sources={  sources }
                      brand="Some Brand Name"
                      title="Some Video Title"
                      
                    /> */}
                      <div className="titleVid360"  >
                        <div className="saveDel" onMouseLeave={HoverTitleOutVid}>
                          <input
                            className="description_Media"
                            onClick={ChangeDesc}
                            defaultValue={
                              video.description !== ""
                                ? video.description
                                : video.name
                            }
                          />
                          <button
                            onClick={UpdateMediaDescription360}
                            className="btn btn-sm btn-success save_Description"
                          >
                            Save
                          </button>
                          <button
                            onClick={DeleteVideoWith360}
                            className="btn btn-sm btn-danger save_Description"
                          >
                             <FaIcons.FaRegTrashAlt
                          style={{ "font-size": "20px", cursor: "pointer","color": "white" }}
                          //id={row.objectS_ID}
                          className="deleteIcon"
                         
                        />
                          </button>
                          
                        </div>
                        <br />
                        <div className="toggler360">
                          <label>360°</label>
                          <div
                            className="form-check form-switch"
                            style={{ "margin-left": "5%" }}
                          >
                            <input
                              className="form-check-input"
                              type="checkbox"
                              onChange={Toggler360}
                              checked
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Carousel.Item>
                )
            )}
        </Carousel>
      )}

      {selectedImage && (
        <Fragment>
          <ImageCropDialog
            id={selectedImage.mediaId}
            imageUrl={selectedImage.value}
            cropInit={selectedImage.crop}
            zoomInit={selectedImage.zoom}
            aspectInit={selectedImage.aspect}
            onCancel={onCancel}
            setCroppedImageFor={setCroppedImageFor}
            setCroppedImagetoCreate={setCroppedImagetoCreate}
            languageChosenSite={languageChosenSite}
            setChangedMediaArray={setChangedMediaArray}
          />
          {/* <ImageGallery items={images} /> */}
          {/* <input value={images[0].description}/> */}
          {/* <button
              style={{ marginTop: "2vh" }}
              className="btn btn-warning"
              onClick={clickPhoto}
            >
              Back
            </button> */}
        </Fragment>
      )}
    </div>
  );
};

export default MediaGallery;

{
  /* <Cropper
            image="https://img.huffingtonpost.com/asset/5ab4d4ac2000007d06eb2c56.jpeg?cache=sih0jwle4e&ops=1910_1000"
            crop={crop}
            zoom={zoom}
            aspect={4 / 3}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
          /> */
}
