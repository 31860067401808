import React, { useState, useEffect, Fragment } from "react";

// API
import { NotificationApi, SiteApi } from "../../api/api.service";
import { language, languageGr } from "../../api/lang";

// Components
import CardSkeleton from "../UI/CardSkeleton";
import NotificationModal from "../UI/NotificationModal";
import TableGeneral from "../UI/TableGeneral";
import BundledEditor from "../../BundledEditor";

// Icons
import * as FaIcons from "react-icons/fa";
import manage from "../../images/manage-orange.png";

//Authservice
import { authService } from "../../api/auth.service";

// React Moment
import Moment from "react-moment";

// Axios
import axios from "axios";

// Swal Alert
import Swal from "sweetalert2";

// Bootstrap
import { Button, Form, Modal } from "react-bootstrap";
import { textFilter } from "react-bootstrap-table2-filter";

const Notifications = () => {

  const sendNotifFromTable = async (row) => {

    console.log(siteTopic);
    
   

    if (userRole == "Viewer") {
      Swal.fire({
        title: language.media.invalidAction,
        text: language.global.userRole,
        icon: "warning",
        button: "OK",
      });
      return;
    }
    
    let targ = row.id;
    console.log(targ);

    setIdClicked(targ);

    const notificationObject = await NotificationApi.GetById(
      parseInt(targ)
    );
    console.log(notificationObject);
    
  

    
    const apiCall = "https://fcm.googleapis.com/fcm/send";

    // if (addTitleChange === "") {
    //   Swal.fire({
    //     title: "Something went wrong",
    //     text: `Please enter a title`,
    //     icon: "warning",
    //   });
    //   return;
    // }
    
    const response = await axios(apiCall, {
      method: "POST",
      data: {
        notification: {
          body: notificationObject.message,
          title: notificationObject.title,
        },
        to: `/topics/${siteTopic}`,
        //to: "1:339342829232:web:88e8fc6fff9c2a29a9bd75",
      },

      headers: {
        "content-type": "application/json",
        Authorization:
          "key=AAAATwJoprA:APA91bH52nXTeuO9bmyoEBDTKluSbF5TRdh9lM1ISf1QpqsncRH1M7ZeKnjHUswPGZDIiUfVEyfLzzpaGpTiNQ23pYsY5roTLPwkBRat6pmIyk6GY8eOUYuStrQzYABoxumvDnBc0Sxl",
      },
    });

    console.log(response);
    
    Swal.fire("Success!", "Your message has been sent.", "success");

  } 

  const sendNotif = async () => {
    if (userRole == "Viewer") {
      Swal.fire({
        title: language.media.invalidAction,
        text: language.global.userRole,
        icon: "warning",
        button: "OK",
      });
      return;
    }
    
    console.log(addMessageChange);
    console.log(addTitleChange);

    
    const apiCall = "https://fcm.googleapis.com/fcm/send";

    // if (addTitleChange === "") {
    //   Swal.fire({
    //     title: "Something went wrong",
    //     text: `Please enter a title`,
    //     icon: "warning",
    //   });
    //   return;
    // }

    const response = await axios(apiCall, {
      method: "POST",
      data: {
        notification: {
          body: addMessageChange,
          title: addTitleChange,
        },
        to: `/topics/${siteTopic}`
        //to: "1:339342829232:web:88e8fc6fff9c2a29a9bd75",
      },

      headers: {
        "content-type": "application/json",
        Authorization:
          "key=AAAATwJoprA:APA91bH52nXTeuO9bmyoEBDTKluSbF5TRdh9lM1ISf1QpqsncRH1M7ZeKnjHUswPGZDIiUfVEyfLzzpaGpTiNQ23pYsY5roTLPwkBRat6pmIyk6GY8eOUYuStrQzYABoxumvDnBc0Sxl",
      },
    });

    console.log(response);
    
    Swal.fire("Success!", "Your message has been sent.", "success");
  };

  const sendNotificationAndAdd = () => {
    if (userRole == "Viewer") {
      Swal.fire({
        title: language.media.invalidAction,
        text: language.global.userRole,
        icon: "warning",
        button: "OK",
      });
      return;
    }
    sendNotif();
    addNotificationToSite();
  };

  const [notifications, setNotifications] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [modalShow, setModalShow] = useState(false);
  const [idClicked, setIdClicked] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  //const [show, setShow] = useState(false);
  const [addNotification, setAddNotification] = useState(false);
  const [addTitleChange, setAddTitleChange] = useState("");
  const [addMessageChange, setAddMessageChange] = useState("");
  const [tableChanged, setTableChanged] = useState(false);
  const [userRole, setUserRole] = useState("");
  const [languageChosenSite, setLanguageChosenSite] = useState("");
  const [siteTopic, setSiteTopic] = useState("");
  //testReact97

  useEffect(() => {
    document.body.className = "";
    console.log("useef");
    getData();
  }, [tableChanged]);

  const handleHide = () => {
    setModalShow(false);
  };

  const fetchUser = async () => {
    const user = await authService.getUserAsync();

    setUserRole(user.claims[0].value);
  };

  const getData = async () => {
    const site = await SiteApi.Get();
    console.log(site[0]);
    setSiteTopic(site[0].notificationTopic);
    const notificationArray = await NotificationApi.GetAllNotificationsOfSite(
      site[0].siteId
    );
    setNotifications(notificationArray);
    setLanguageChosenSite(sessionStorage.getItem("languageChosen"));
    fetchUser();
    setLoading(false);
    console.log(notificationArray);
    //document.querySelector(".pageHead").innerHTML = `<h4 style='padding-top: 2%;text-align: center;color: #f7aa47;margin-left: -39vw;'><img style="height: 4vh;" class='fa fa-fw fa-file' src='${manage}' />${language.global.titles.notifications}</h4>`;
    changeHead();
  };

  const changeHead = () => {
    if (sessionStorage.getItem("languageChosen") === "EL") {
      document.querySelector(".pageHead").innerHTML =`<div style="width: 100%; height:100%; display: inline-flex; justify-content: center;"><h4 style='padding-top: 3.5vh;text-align: center;color: #f7aa47;'><img style="height: 4vh;margin-top:-1vh;" class='fa fa-fw fa-file' src='${manage}' />${languageGr.global.titles.notifications}</h4></div>`;
    } else {
      document.querySelector(".pageHead").innerHTML =`<div style="width: 100%; height:100%; display: inline-flex; justify-content: center;"><h4 style='padding-top: 3.5vh;text-align: center;color: #f7aa47;'><img style="height: 4vh;margin-top:-1vh;" class='fa fa-fw fa-file' src='${manage}' />${language.global.titles.notifications}</h4></div>`;
    }
  };

  function dateFormatter(cell, row) {
    if (row.createdAt) {
      return (
        <span>
          <Moment format="DD/MM/YY">{cell}</Moment>
        </span>
      );
    }
  }

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      console.log(e);
      console.log({ row, rowIndex });
      setAddTitleChange(row.title);

      setAddMessageChange(row.message);
      setIdClicked(row.id);
      console.log(idClicked);
      console.log(addTitleChange);
      console.log(addMessageChange);
      //setCheckedList((prevState) => [...prevState, row.objectS_ID]);
      //setDeleteId(row.id);
    },
  };

  const edit = (cell, row, rowIndex, formatExtraData) => {
    return <FaIcons.FaRegEdit onClick={() => editClick(row)} className="editIcon" Edit />;
  };

  const StatusChange = (row, e) => {
    if (userRole == "Viewer") {
      Swal.fire({
        title: languageChosenSite == "EL" ? languageGr.media.invalidAction : language.media.invalidAction,
        text: languageChosenSite == "EL" ? languageGr.global.userRole : language.global.userRole,
        icon: "warning",
        button: "OK",
      });
      return;
    }
    e.preventDefault();
    console.log(row);
    const pubTar =
      row.id;
    const pubTarName = row.title;
        
    //console.log(!e.target.checked);
    //console.log(e.currentTarget.parentElement.parentElement.parentElement.children[0].innerText);
    Swal.fire({
      title: "Are you sure?",
      html: `You are about to change the status of the <strong>${pubTarName}</strong> Notification`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const notificationObject = await NotificationApi.GetById(
          parseInt(pubTar)
        );
        console.log(notificationObject);
        const notification = {
          Id: notificationObject.id,
          Title: notificationObject.title,
          Message: notificationObject.message,
          SiteId: 1,
          CreatedAt: notificationObject.createdAt,
          Status: !notificationObject.status,
        };

        const updated = await NotificationApi.Update(notification);
        console.log(updated);
        e.target.checked = updated.status;
      } else {
        e.target.checked = e.target.checked;
      }
    });
  };

  const editClick = (row) => {
    // if (userRole == "Viewer") {
    //   Swal.fire({
    //     title: languageChosenSite == "EL" ? languageGr.media.invalidAction : language.media.invalidAction,
    //     text: languageChosenSite == "EL" ? languageGr.global.userRole : language.global.userRole,
    //     icon: "warning",
    //     button: "OK",
    //   });
    //   return;
    // }

    // let targ =
    //   e.currentTarget.parentElement.parentElement.children[0].innerText;
    let targ = row.id;
    console.log(targ);
    setModalShow(true);
    setIdClicked(targ);
    console.log(modalShow);
  };

  const del = (cell, row, rowIndex, formatExtraData) => {
    
    return (
      <FaIcons.FaRegTrashAlt

        onClick={() => deleteClick(row)}
        className="deleteIcon"
        Delete
      />
    );
  };

  const deleteClick = async (row) => {
    if (userRole == "Viewer") {
      Swal.fire({
        title: languageChosenSite == "EL" ? languageGr.media.invalidAction : language.media.invalidAction,
        text: languageChosenSite == "EL" ? languageGr.global.userRole : language.global.userRole,
        icon: "warning",
        button: "OK",
      });
      return;
    }
    //e.stopPropagation();
    //console.log(e);
    // target = e.target.parentElement.parentElement.children[0].innerText;
    // let targetElement = e.target.parentElement.parentElement;
    // console.log(targetElement);
    //setDeleteId(target);
    console.log(row);

    //console.log(e.currentTarget);  e.currentTarget.parentElement.parentElement.children[0].innerText;

    
    
    //console.log(idClicked);
    setIdClicked(row.id);
    setDeleteId(row.id);
    let tar = row.id;
    //let tarObj = await NotificationApi.GetById(row.id);
   
    let tarName = row.title;
    //console.log(e.currentTarget.parentElement.parentElement.children[1].innerText);

    Swal.fire({
      title: "Are you sure?",
      html: `You are about to delete the <strong>${tarName}</strong> Notification`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const deleted = await NotificationApi.NotificationDelete(parseInt(tar));

        setTableChanged(!tableChanged);
        setTableChanged(!tableChanged);
      }
    });
  };

  function statusFormatter(cell, row) {
    return (
      <div class="form-check form-switch" style={{ "margin-left": "35%" }}>
        <input
          class="form-check-input"
          type="checkbox"
          onChange={(e) => StatusChange(row, e)}
          checked={row.status}
        />
      </div>
    );
  }

  const AddNotification = () => {
    setAddNotification(true);
  };

  const onReturnFromAdd = () => {
    setAddNotification(false);
  };

  const handleClose = () => {
    setAddNotification(false);
  };

  const addNotificationToSite = async () => {
    if (userRole == "Viewer") {
      Swal.fire({
        title: languageChosenSite == "EL" ? languageGr.media.invalidAction : language.media.invalidAction,
        text: languageChosenSite == "EL" ? languageGr.global.userRole : language.global.userRole,
        icon: "warning",
        button: "OK",
      });
      return;
    }
    if (addTitleChange === "") {
      Swal.fire({
        title: "Something went wrong",
        text: `Please enter a title`,
        icon: "warning",
      });
    } else {
      const sites = await SiteApi.Get();
      let name = addTitleChange;
      name = name.trim();
      let description = addMessageChange;
      description = description.trim();

      const createdAt = new Date().toISOString();
      //createdAt.format("DD/MM/YYYY");

      const notification = {
        Title: name,
        Message: description,

        SiteId: sites[0].siteId,
        CreatedAt: createdAt,

        Status: true,
      };
      console.log(notification);

      const insertedNotification = await NotificationApi.Create(notification);
      setAddTitleChange("");
      setAddMessageChange("");
      //setAddNotification(false);
      setTableChanged(!tableChanged);
      setTableChanged(!tableChanged);

      handleClose();
    }
  };

  const send = (cell, row, rowIndex, formatExtraData) => {
    return <FaIcons.FaMailBulk onClick={() => sendNotifFromTable(row)} className="bg-warning text-white" style={{ "border-radius": "3px", "cursor": "pointer", "font-size": "18px" }}  Send />;
  };

  const descriptionFormat = (d) => {
    if (d != null) {
      return d.replace(/<[^>]+>/g, "").substring(0, 50) + " ....";
    }
  };

  const notificationHeaders = [
    // {
    //   dataField: "id",
    //   text: "Id",
    //   sort: true,
    //   hidden: true
    // },
    {
      dataField: "title",
      text: languageChosenSite === "EL"
      ? languageGr.table.title
      : language.table.title,
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: "message",
      text: languageChosenSite === "EL"
      ? languageGr.table.message
      : language.table.message,
      sort: true,
      formatter: descriptionFormat,
    },
    {
      dataField: "createdAt",
      text: languageChosenSite === "EL"
      ? languageGr.table.created
      : language.table.created,
      sort: true,
      formatter: dateFormatter,
    },
    {
      dataField: "status",
      text: languageChosenSite === "EL"
      ? languageGr.table.active
      : language.table.active,
      sort: true,
      formatter: statusFormatter,
    },
    {
      dataField: "edit",
      text: languageChosenSite === "EL"
      ? languageGr.table.edit
      : language.table.edit,
      formatter: edit,
    },
    {
      dataField: "delete",
      text: languageChosenSite === "EL"
      ? languageGr.table.delete
      : language.table.delete,
      formatter: del,
    },

    {
      dataField: "Send",
      text: languageChosenSite === "EL"
      ? languageGr.table.send
      : language.table.send,
      formatter: send,
    },
  ];

  return (
    <Fragment>
      <div>
        {modalShow && (
          <NotificationModal
            onHide={handleHide}
            show={modalShow}
            targetId={idClicked}
            rowEvents={rowEvents}
            data={notifications}
            tableChanged={tableChanged}
            setTableChanged={setTableChanged}
            getData={getData}
            userRole={userRole}
          ></NotificationModal>
        )}
        {isLoading ? (
          <div className="col-7 offset-3">
            <CardSkeleton />
          </div>
        ) : (
          <TableGeneral
            responsive
            data={notifications}
            rowEvents={rowEvents}
            columns={notificationHeaders}
          />
        )}
        <Button
          onClick={AddNotification}
          className="addNewButton"
          variant="addNewButton"
        >
          {languageChosenSite === "EL"
          ? languageGr.global.word.addNew
          : language.global.word.addNew}
        </Button>
      </div>
      <Modal show={addNotification} onHide={handleClose} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>
            {languageChosenSite === "EL"
              ? languageGr.global.notificationWords.addNotification
              : language.global.notificationWords.addNotification}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>
                {languageChosenSite === "EL"
                  ? languageGr.global.transName
                  : language.global.transName}
              </Form.Label>
              <Form.Control
                type="text"
                placeholder={
                  languageChosenSite === "EL"
                    ? languageGr.global.enterTitle
                    : language.global.enterTitle
                }
                autoFocus
                onChange={(e) => setAddTitleChange(e.target.value)}
                value={addTitleChange}
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>
                {languageChosenSite === "EL"
                  ? languageGr.global.transDesc
                  : language.global.transDesc}
              </Form.Label>

              <BundledEditor
                init={{
                  height: 400,
                  selector: "textarea",
                  plugins:
                    "advlist anchor autolink help image link lists searchreplace table wordcount emoticons insertdatetime code",
                  toolbar:
                    "undo redo blocks image link media numlist bullist insertdatetime|",
                  content_style:
                    "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                }}
                onEditorChange={(newD) => setAddMessageChange(newD)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer className="modal_footer">
          <Button
            variant="info lg"
            style={{ color: "white", fontWeight: "500" }}
            onClick={sendNotificationAndAdd}
          >
            {languageChosenSite === "EL"
              ? languageGr.global.notificationWords.send
              : language.global.notificationWords.send}
          </Button>
          <Button
            className="addNewButton"
            variant="addNewButton"
            onClick={addNotificationToSite}
          >
            {languageChosenSite === "EL"
              ? languageGr.content.clearSelectedLabel.value
              : language.content.clearSelectedLabel.value}
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default Notifications;
