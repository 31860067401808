import React, { useState, useEffect } from "react";

// API
import { PresentationApi } from "../../api/api.service";

// Components
import BundledEditor from "../../BundledEditor";

// Bootstrap
import { Button, Modal, Form, Col, Row, Tab, Nav } from "react-bootstrap";

// CSS
import "./Modal.css";

//dynamic language
import { language, languageGr } from "../../api/lang";

// Swal Alert
import Swal from "sweetalert2";

// FaIcons
import * as FaIcons from "react-icons/fa";

const ModalParent = (props) => {
  const [languages, setLanguages] = useState(props.languages);
  const [targetId, setTargetId] = useState(props.targetId);
  const [presentations, setPresentations] = useState([]);
  const [allPresentations, setAllPresentations] = useState([]);
  const [languageChosenSite, setLanguageChosenSite] = useState("");

  useEffect(() => {
    getData(targetId);
    console.log("Use Effect Runs");
  }, [targetId]);

  const getData = async (id) => {
    setLanguageChosenSite(sessionStorage.getItem("languageChosen"));

    const getPresentations = await PresentationApi.GetByTranslation(id);
    const allPres = await PresentationApi.Get();
    console.log(allPres);

    let arr = [];
    allPres.map((item) => {
      arr.push(item.presentationId);
    });
    console.log(arr);
    setAllPresentations(arr);

    let newTrans = [];
    getPresentations.map((presentation) => {
      languages.map((lang) => {
        if (presentation.languageId === lang.languageId) {
          newTrans.push({ ...presentation, lang: lang.name });
        }
      });
    });
    setPresentations(newTrans);
  };

  const next = () => {
    let index = allPresentations.indexOf(targetId);
    index === allPresentations.length - 1
      ? setTargetId(allPresentations[0])
      : setTargetId(allPresentations[index + 1]);
    getData(targetId);
  };

  const prev = () => {
    let index = allPresentations.indexOf(targetId);
    index === 0
      ? setTargetId(allPresentations[allPresentations.length - 1])
      : setTargetId(allPresentations[index - 1]);
    getData(targetId);
  };

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" className="fw-light">
            {languageChosenSite === "EL"
              ? languageGr.global.presentationWords.editPresentation
              : language.global.presentationWords.editPresentation}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Tab.Container id="lang-left-tabs" defaultActiveKey={`#Greek`}>
            <Row>
              <Col sm={3}>
                <Nav variant="pills" className="flex-column">
                  {languages.map((value) => (
                    <Nav.Link href={`#${value.name}`}>{value.name}</Nav.Link>
                  ))}
                </Nav>
              </Col>
              <Col sm={9}>
                <Tab.Content>
                  {languages.map((lang) => (
                    <Tab.Pane eventKey={`#${lang.name}`}>
                      <Data
                        targetId={props.targetId}
                        languageId={lang.languageId}
                        changeTable={props.changeTable}
                        translationId={presentations
                          .map((presentation) =>
                            presentation.lang === lang.name
                              ? presentation.translationId
                              : null
                          )
                          .join("")}
                        initialTitle={presentations
                          .map((presentation) =>
                            presentation.lang === lang.name
                              ? presentation.name
                              : null
                          )
                          .join("")}
                        initialDesc={presentations
                          .map((presentation) =>
                            presentation.lang === lang.name
                              ? presentation.description
                              : null
                          )
                          .join("")}
                      />
                    </Tab.Pane>
                  ))}
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>

          <div className="text-center pt-3">
            <Button onClick={prev} className="backwards mx-1">
              <FaIcons.FaChevronLeft />
            </Button>

            <Button onClick={next} className="forward mx-1">
              <FaIcons.FaChevronRight />
            </Button>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="closeButton"
            variant="closeButton"
            onClick={props.onHide}
          >
            {languageChosenSite === "EL"
              ? languageGr.global.close
              : language.global.close}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalParent;

function Data({
  initialTitle,
  initialDesc,
  languageId,
  translationId,
  targetId,
  userRole,
  changeTable
}) {
  const [desc, setDesc] = useState(initialDesc ?? "");
  const [title, setTitle] = useState(initialTitle ?? "");
  const [languageChosenSite, setLanguageChosenSite] = useState("");

  useEffect(() => {
    update();
  }, [initialTitle, initialDesc]);

  const update = () => {
    setDesc(initialDesc ?? "");
    setTitle(initialTitle ?? "");
    setLanguageChosenSite(sessionStorage.getItem("languageChosen"));
  };

  const updateData = async () => {
    if (userRole == "Viewer") {
      Swal.fire({
        title: languageChosenSite == "EL" ? languageGr.media.invalidAction : language.media.invalidAction,
        text: languageChosenSite == "EL" ? languageGr.global.userRole : language.global.userRole,
        icon: "warning",
        button: "OK",
      });
      return;
    }
    let translation = {
      translations: [],
    };

    console.log("initialTitle :" + initialTitle);
    console.log("initialDesc :" + initialDesc);
    console.log(`New title: ${title}`);
    console.log(`New description: ${desc}`);
    console.log(`Language ID: ${languageId}`);
    console.log(`Translation ID: ${translationId}`);
    console.log(`Target ID: ${targetId}`);

    if (initialTitle || initialDesc !== "") {
      if (initialTitle !== title || initialDesc !== desc) {
        //UpdateTranslation
        console.log("Update data");
        translation.translations.push({
          translationId: translationId,
          name: title,
          description: desc,
        });

        const update = await PresentationApi.Update(translation);

        if (update) {
          Swal.fire({
            title: "Success",
            text: `Action was successfully done`,
            icon: "success",
          });
          changeTable();
        }
      } else {
        console.log("The data are same. Update not needed");
      }
    } else {
      //CreateTranslation
      console.log("Create data");
      translation.translations.push({
        id: targetId,
        languageId: languageId,
        name: title,
        description: desc,
      });

      const create = await PresentationApi.CreateTranslation(translation);

      if (create) {
        Swal.fire({
          title: "Success",
          text: `Action was successfully done`,
          icon: "success",
        });
        changeTable();
      }
    }

    console.log(translation);
  };

  return (
    <>
      <div className="pb-2">
        <Form.Control
          defaultValue={initialTitle}
          value={title}
          onChange={(newT) => setTitle(newT.target.value)}
        />
      </div>
      <div className="pb-2">
        <BundledEditor
          init={{
            height: 400,
            selector: "textarea",
            plugins:
              "advlist anchor autolink help image link lists searchreplace table wordcount emoticons insertdatetime code",
            toolbar:
              "undo redo blocks image link media numlist bullist insertdatetime|",
            content_style:
              "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
          }}
          initialValue={initialDesc}
          value={desc}
          onEditorChange={(newD) => setDesc(newD)}
        />
      </div>
      <div>
        <Button
          className="addNewButton"
          variant="addNewButton"
          onClick={() => updateData()}
        >
          {languageChosenSite === "EL"
            ? languageGr.global.saveChanges
            : language.global.saveChanges}
        </Button>
      </div>
    </>
  );
}
