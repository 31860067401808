const language = {
  outdoor: {
    filter: "Filter Per Exhibition",
    all: "All",
    exhibition: "Exhibition",
    serial: "Serial Number",
    long: "Longitude",
    lat: 'Latitude'
  },
  indoor: {
    map: "Map ",
    addImage: "Add Floor plan",
    addImageAsObject: "Add image",
    addNewMap: "Create new map",
    updateMap: "Update map",
    mapName: "Map name",
    enterMapName: "Enter map name",
    mapDescription: "Map description",
    enterMapDescription: "Enter map description",
    mapLanguage: "Map language",
    chooseLanguage: "Choose language",
    selectMap: "Select map",
    createNew: "Create",
    edit: "Edit",
    edited: " edited",
    created: " created!",
    saveChanges: "Changes saved!",
    update: "Update",
    delete: "Delete",
    deleteMessage: "Do you want to delete map",
    deleteMessageConfirmed: "deleted",
    cancel: "Cancel",
    save: "Save",
    clean: "Clean",
    exhibitAdd: "Add Exhibits",
    addshapes: "Add Geometric Shapes",
    text: "Add Text",
    miscellaneous: "Add Miscellaneous",
    // configuration: "Configuration",
    markers: "Style",
    fill: "Fill Color",
    stroke: "Stroke Color",
    catHeading: "Category",
    exhibitHeading: "Exhibits",
    circle: "Circle",
    triangle: "Triangle",
    square: "Square",
    rectangle: "Rectangle",
    line: "Line",
    polyline: "Polyline",
    misc: "Marker",
    stairs: "Stairs",
    fireExtinguisher: "Fire Extinguisher",
    restroom: "Restroom",
    shop: "Shop",
    fromUrl: "From Url",
    topicUpload: "Upload from your device",
    close: "Close",
    options: "Options",
    canvas: "Floor Map",
    configuration: "Configuration",
    markerColor: "Marker Color",
    add: "Add",
    formValidationTitle: "You must add a name for the map",
    formValidationLang: "You must select language for the map",
    fontSize: "Font Size",
    exhibitText: "Text",
    strokeWidth: "Stroke Width",
    transparentFill: "Transparent",
    fontsHeading: "Fonts",
    fontFamily: "Font-Family",
    drawMode: "Drawing Mode",
    existingMapCopy: "Copy an existing map"
  },
  table: {
    edit: "Edit",
    delete: "Delete",
    code: "Code",
    title: "Title",
    category: "Category",
    active: "Active",
    created: "Created At",
    message: "Message",
    send: "Send",
    published: "Published At",
    expires: "Expires At",
    approved: "Approved",
    description: "Description",
    parent: "Parent",
    initials: "Initials",
  },
  login: {
    invalid: "Invalid Username or Password",
  },
  global: {
    multipleDel: "Multiple Delete",
    hi: "Hi",
    next: "Next",
    previous: "Previous",
    transName: "Name",
    transInvalid: "Please fill out this field",
    transDesc: "Description",
    transEdit: "Edit Translation",
    transAdd: "Add Translation",
    userRole:
      "Your user role does not have the requested permissions to perform this action",
    categoryDrop: "All",
    choose: "Choose",
    close: "Close",
    titleFill: "Please insert a value for the title",
    save: "Saved Successfully",
    saveChanges: "Save changes",
    saveCat: "Save categories",
    name: "Name",
    description: "Description",
    enterTitle: "Enter title...",
    fillThis: "Please fill out this field",
    titles: {
      content: "Content Management",
      new: "Add Content",
      media: "Media Management",
      map: "Route Map",
      routes: "Exhibitions",
      indoor: "Indoor Map",
      notifications: "Notification Management",
      events: "Event Management",
      exhibition: "Exhibition Management",
      presentation: "Presentation Management",
      category: "Category Management",
      field: "Field Management",
      language: "Language Management",
      site: "Site Management",
      import: "Import Data",
      export: "Export Data",
      settings: "Settings",
      statistics: "Statistics",
      userManagement: "User Management",
      tagManagement: "Tag Management",
      controlPanel: "Control Panel",
      controlPanelContent: "Content",
      tags: "Tag Management"
    },

    word: {
      addNew: "Add New",
    },
    language: {
      choose: "Choose Language :",
      add: "Add Language",
    },

    categoryWords: {
      addCategory: "Add Category",
      uploadImage: "Upload Image",
      galleryAttach: "Gallery Attach",
      isSub: "is SubCategory",
      category: "Category",
      subCategory: "Subcategory",
      catPlaceholder: "Select..",
      parentCat: "Parent Category",
      attachmentCat: "Attachment Categories",
      editCat: "Edit Category",
    },
    eventsWords: {
      addEvent: "Add Event",
      editEvent: "Edit Event",
      publish: "Publish at:",
      expires: "Expires at:",
    },

    presentationWords: {
      addPresentation: "Add Presentation",
      editPresentation: "Edit Presentation",
    },

    notificationWords: {
      addNotification: "Add Notification",
      send: "Send",
    },

    exhibitionWords: {
      edit: "Edit Exhibition",
      add: "Add Exhibition",
      exhibitionPlaceholder: "Select..",
    },

    tagWords: {
      edit: "Edit Tag",
      add: "Add Tag"
    },

    editWords: {
      update: "Update",
      serialNumber: "Serial Number",
      serialPlaceholder: "Please Enter Number..",
      exhibitions: "Exhibitions",
      edit: "Edit Notification",
      qrDownload: "Donwload QR Code",
      qrCode: "QR Code",
      editExhibit: "Edit Exhibit",
      name: "Name",
      namePlaceholder: "Enter Name..",
      tags: "Tags"

    },

    mediaWords: {
      all: "All",
      images: "Images",
      video: "Videos",
      audio: "Audios",
      ts: "360",
      search: "Search..",
      upload: "Upload",
      addMedia: "Add Media",
      computer: "Computer",
      mediagallery: "Media Gallery",
      mulitple: "Upload Files",
      crop: "Crop",
      cropTitle: "Cropping Image",
      uploadSuccess: "Media Uploaded Successfully",
      selectFiles: "Choose files",
      uploadTitles: "Upload Files",
      uploadLength: "Please choose media files",
      uploadLangInvalid: "Some files do not have a language selection",
    },
    fieldWords: {
      choosePres: "Choose Presentation",
      addPres: "Add field",
      cat: "Category",
      select: "Select",
      edit: "Edit field",
    },
    login: "Sign In",
  },
  denyRoleEdit: {
    siteAlert: "Your user role does not have permission to edit sites",
    catAlert: "Your user role does not have permission to edit categories",
    fieldAlert: "Your user role has no permission to edit fields",
    presentationAlert: "Your user role has no permission to edit presentations",
    eventsAlert: "Your user role has no permission to edit events",
    exhibitionsAlert: "Your user role has no permission to edit exhibitions",
    global: "Your user role has no permission to edit",
  },
  denyRoleDelete: {
    catAlert:
      "Your user user role does not have permission to delete categories",
    fieldAlert: "Your user role has no permission to delete fields",
    presentationAlert:
      "Your user role has no permission to delete presentations",
    eventsAlert: "Your user role has no permission to delete events",
    exhibitionsAlert: "Your user role has no permission to delete exhibitions",
    global: "Your user role has no permission to delete",
  },
  denyRoleAdd: {
    langAlert: "Your user role does not have permission to add languages",
    siteAlert: "Your user role does not have permission to add sites",
    catAlert: "Your user role does not have permission to add categories",
    fieldAlert: "Your user role has no permission to add fields",
    presentationAlert: "Your user role has no permission to add presentations",
    eventsAlert: "Your user role has no permission to add events",
    exhibitionsAlert: "Your user role has no permission to add exhibitions",
    global: "Your user role has no permission to add",
  },
  deleteSelected: {
    multiDelete:
      "You are about to delete the following number of media file(s) ",
    catAlert: "Are you sure you want to delete the category with id  : ",
    fieldAlert: "Are you sure you want to delete the field with id  : ",
    presentationAlert:
      "Are you sure you want to delete the presentation with id  : ",
    eventsAlert: "Are you sure you want to delete the event with id  : ",
    exhibitionsAlert:
      "Are you sure you want to delete the exhibition with id  : ",
    tagsAlert: "Are you sure you want to delete the tag with id  : ",
    exhibitAlert: "You are about to delete the exhibit : ",
  },
  content: {
    categoryDrop: "All",
    categoryChoose: "Choose",
    categoryUpdate: "None",
    subCategoryUpdate: "None",
    button: "Save",
    finish: "Finish",
    del: "Delete",
    size: "*Size:",
    mulitpleDelConfirm: "Are you sure you want to delete the",
    deleteConfirm: "Are you sure you want to delete",
    tabs: {
      tab1: "Edit",
      tab2: "Translations",
      tab3: "Fields",
      tab4: "Media",
      tab5: "Location",
    },
    routes: {
      searchPlaceholder: "Search..",
      placeholder: "Add",
    },
    fileSize: {
      video:
        "Media size exceeds the limit. Please load videos with maximum size of 15Mb",
      image:
        "Media size exceeds the limit. Please load images with maximum size of 1Mb",
      audio:
        "Media size exceeds the limit. Please load audio files with maximum size of 5Mb",
    },
    langSeleectForFiles: "Choose Language :",
    langCountAlert: "No languages are chosen",
    clearSelectedLabel: {
      choose: "Choose mulitple files:",
      clear: "Clear",
      value: "Add",
      back: "Back",
    },
    tab5: {
      long: "Longitude :",
      lat: "Latitude :",
      showOnMap: "Show on map",
      successSave: "Saved successfully",
      alert: "Please insert values for longitude and latitude",
    },
    update: {
      exhibitCodeAlert: "Serial numbers already exists. Please choose another",
      exhibitCodeLength:
        "Please choose a number between 100 and 100000 as a Serial number",
      categoryAlert: "Please choose a category",
    },
    /* updateImageSize: `Media with name ${file.name} is not loaded. Media size is too big. Please load images with maximum size of 1Mb`*/
  },
  new: {
    saveObjRole: "Your user role does not have permission to create Objects",
    exhibitCodeAlert: "Serial numbers already exists. Please choose another",
    exhibName: "Please add a valid Object name",
    exhibitCodeLength:
      "Please choose a number between 100 and 100000 as a Serial number",
    categoryAlert: "Please choose a category",
  },
  map: {
    long: "Longitude:",
    lat: "Latitude:",
    route: "Route: ",
    serial: "Serial number: ",
  },
  notifications: {
    firstNameAlert: "You can use up to 100 characters",
    lastNameAlert: "You can use up to 1200 characters",
    userAlert: "Your role has no permission to send notifications",
    statusAlert: "Notification status needs to be approved",
  },
  media: {
    invalidAction: "Invalid action",
    confirmMessage: "Are you sure?",
    successTitle: "Success",
    successDesc: "Action was successfully done",
    deleteTitle: "Deleted",
    errorTitle: "The following files are not loaded",
    successUpload: "Files uploaded successfully",
    limitErrorTitle: "Too many files chosen",
    limitErrorText: "Please load up to 10 files simultaneously",
    limitErrorMultiDel: "Please choose a maximum size of 30 media file",
    successSave: "Image saved successfully",
    successDelete: "Deleted successfully",
    deleteConfirm: "Are you sure you want to delete this file?",
    chooseConfirm: "Are you sure you want to choose the selected image?",
    profileError: "Profile images cannot be deleted",
    succcessChange: "Changed successfully",
    noItemsTitle: "No items chosen",
    noItemsChosen: "Please tick the media you want to delete",
    noProceed: 'Cannot proceed to upload. Check language or media length'
  },

  export: {
    exportData: "Export Data",
    exportExhibits: "Export Exhibits",
    exportMedia: "Export Media",
    downloadxlsConfig: "Click to Export All Configuration data in xls format",
    downloadxlsExhib: "Click to Export All Exhibits data in xls format",
    downloadxlsMedia: "Click to Export All Media data in zip file format",
    buttonConfig: "Download Configuration File",
    buttonExhib: "Download Exhibits File",
    buttonMedia: "Download Media File",
    success: "Success",
    successDownload: "File downloaded successfully",
  },
  import: {
    importData: "Import Data",
    importExhibits: "Import Exhibits",
    importMedia: "Import Media",
    uploadxlsConfig: "Click to Import Configuration excel file",
    uploadxlsExhib: "Click to Import Exhibits excel file",
    uploadxlsMedia: "Click to Import Media files in zip file format",
    configTemplate: "Configuration Template",
    exhibitTemplate: "Exhibits Template",
    convert: "Convert & Upload file",
    success: "Success",
    successUpload: "File uploaded successfully",
    issues: "Issues Found",
    nofile: "No file was chosen",
    issuesText:
      "One or more issues found while uploading your file. Some exhibits may have not been uploaded correctly",
    fileType: "Please load a correct file type",
  },
  analytics: {
    pagesLabel: "Top Visited Pages",
    languagesLabel: "Languages Found",
    agesLabel: "Ages Found",
    genderLabel: "Genders Found",
    visitorsLabel: "Visitors Per Day",
    timeOnSiteLabel: "Time on Site Per Day",
    bounceRateLabel: "Bounce Rate Per Day (Percentage)",
  },
  user: {
    passwordError: "Passwords do not match",
  },
  languages: {
    Arabic: "Arabic",
    Africans: "Africans",
    Chinese: "Chinese",
    English: "English",
    French: "French",
    German: "German",
    Greek: "Greek",
    Hebrew: "Hebrew",
    Hindi: "Hindi",
    Italian: "Italian",
    Japanese: "Japanese",
    Korean: "Korean",
    Portugese: "Portugese",
    Russian: "Russian",
    Spanish: "Spanish",
    Turkish: "Turkish",
  },
};

const languageGr = {
  outdoor: {
    filter: "Φιλτράρισμα Ανά Διαδρομή",
    all: "Όλες",
    exhibition: "Διαδρομή",
    serial: "Σειριακός Αριθμός",
    long: "Γεωγραφικό Μήκος",
    lat: 'Γεωγραφικό Πλάτος'
  },
  indoor: {
    map: "Χάρτης ",
    addImage: "Προσθήκη κάτοψης",
    addImageAsObject: "Προσθήκη εικόνας",
    addNewMap: "Δημιουργία νέου χάρτη",
    updateMap: "Ενημέρωση χάρτη",
    mapName: "Όνομα χάρτη",
    enterMapName: "Εισαγωγή ονόματος χάρτη",
    mapDescription: "Περιγραφή χάρτη",
    enterMapDescription: "Εισαγωγή περιγραφής χάρτη",
    mapLanguage: "Γλώσσα χάρτη",
    chooseLanguage: "Επιλογή γλώσσας",
    selectMap: "Επιλογή χάρτη",
    deleteMessage: "Θέλετε να διαγράψετε οριστικά το χάρτη",
    deleteMessageConfirmed: "διαγράφηκε",
    cancel: "Ακύρωση",
    createNew: "Δημιουργία",
    edit: "Επεξεργασία",
    edited: " ενημερώθηκε",
    created: " δημιουργήθηκε!",
    saveChanges: "Οι αλλαγές αποθηκεύτηκαν!",
    update: "Ενημέρωση",
    delete: "Διαγραφή",
    save: "Αποθήκευση",
    clean: "Καθαρισμός",
    exhibitAdd: "Προσθήκη Εκθεμάτων",
    addshapes: "Προσθήκη Σχημάτων",
    text: "Προσθήκη Φράσης",
    miscellaneous: "Διάφορα",
    // configuration: "Ρυθμίσεις",
    markers: "Χρωματισμός",
    catHeading: "Κατηγορία",
    exhibitHeading: "Εκθέματα",
    circle: "Κύκλος",
    triangle: "Τρίγωνο",
    square: "Τετράγωνο",
    rectangle: "Ορθογώνιο",
    line: "Γραμμή",
    polyline: "Πολυγραμμικό",
    misc: "Σημείο τοποθέτησης",
    stairs: "Σκάλες",
    fireExtinguisher: "Πυροσβεστικό κρουνό",
    restroom: "Τουαλέτα",
    shop: "Σημείο Πώλησης",
    fromUrl: "Από Url",
    topicUpload: "Μεταφόρτωση από συσκευή",
    close: "Κλείσιμο",
    options: "Επιλογές",
    canvas: "Κάτοψη",
    configuration: "Διαμόρφωση",
    markerColor: "Χρωματισμός",
    fill: "Εσωτερικός Χρωματισμός",
    stroke: "Εξωτερικός Χρωματισμός",
    add: "Προσθήκη",
    formValidationTitle: "Πρέπει να προσθέσετε ένα όνομα για το χάρτη",
    formValidationLang: "Πρέπει να επιλέξετε γλώσσα για το χάρτη",
    fontSize: "Μέγεθος Γραμματοσειράς",
    exhibitText: "Κείμενο",
    strokeWidth: "Μέγεθος Εξωτερικής Γραμμής",
    transparentFill: "Διάφανο",
    fontsHeading: "Γραμματοσειρά",
    fontFamily: "Όνομα Γραμματοσειράς",
    drawMode: "Λειτουργία Σχεδίασης",
    existingMapCopy: "Αντιγράψτε έναν υπάρχων χάρτη"


  },

  table: {
    edit: "Επεξεργασία",
    delete: "Διαγραφή",
    code: "Κωδικός",
    title: "Τίτλος",
    category: "Κατηγορία",
    active: "Ενεργό",
    created: "Ημ/νία Δημιουργίας",
    message: "Μήνυμα",
    send: "Αποστολή",
    published: "Ημ/νία Έκδοσης",
    expires: "Ημ/νία Λήξης",
    approved: "Εγκρίθηκε",
    description: "Περιγραφή",
    parent: "Γονέας",
    initials: "Αρχικά",
  },
  login: {
    invalid: "Λάθος όνομα ή κωδικός",
  },
  global: {
    hi: "Γειά",
    multipleDel: "Πολλαπλή Διαγραφή",
    next: "Επόμενο",
    previous: "Προηγούμενο",
    langInit: "EL",
    transName: "Όνομα",
    transInvalid: "Παρακαλώ συμπληρώστε το πεδίο",
    transDesc: "Περιγραφή",
    transEdit: "Επεξεργασία Μετάφρασης",
    transAdd: "Προσθήκη Μετάφρασης",
    userRole:
      "Ο ρόλος χρήστη σας δεν πληροί τα δικαιώματα για αυτή την ενέργεια",
    categoryDrop: "Όλες",
    choose: "Επιλογή",
    close: "Κλείσιμο",
    titleFill: "Παρακαλώ εισάγετε τον τίτλο",
    save: "Επιτυχής Αποθήκευση",
    saveChanges: "Αποθήκευση αλλαγών",
    saveCat: "Αποθήκευση κατηγοριών",
    name: "Όνομα",
    description: "Περιγραφή",
    enterTitle: "Προσθήκη τίτλου...",
    fillThis: "Παρακαλώ συμπληρώστε αυτό το πεδίο.",
    titles: {
      content: "Διαχείριση Περιεχομένου",
      new: "Προσθήκη Περιεχομένου",
      media: "Διαχείριση Πολυμέσων",
      map: "Χάρτης",
      routes: "Διαδρομές",
      indoor: "Εσωτερικός Χάρτης",
      notifications: "Διαχείριση Ειδοποιήσεων",
      events: "Διαχείριση Εκδηλώσεων",
      exhibition: "Διαχείριση Διαδρομών",
      presentation: "Διαχείριση Παρουσιάσεων",
      category: "Διαχείριση Κατηγοριών",
      field: "Διαχείριση Πεδίων",
      language: "Διαχείριση Γλωσσών",
      site: "Διαχείριση Μουσείου",
      import: "Εισαγωγή Δεδομένων",
      export: "Εξαγωγή Δεδομένων",
      settings: "Ρυθμίσεις",
      statistics: "Στατιστικά",
      userManagement: "Διαχείριση Χρηστών",
      tagManagement: "Διαχείριση Ετικετών",
      controlPanel: "Πίνακας Ελέγχου",
      controlPanelContent: "Περιεχόμενο",
      tags: "Διαχείριση Ετικετών"

    },
    word: {
      addNew: "Προσθήκη",
    },
    language: {
      choose: "Επιλογή Γλώσσας :",
      add: "Προσθήκη Γλώσσας",
    },
    categoryWords: {
      addCategory: "Προσθήκη Κατηγορίας",
      uploadImage: "Μεταφόρτωση εικόνας",
      galleryAttach: "Επιλογή από πολυμέσα",
      isSub: "Υποκατηγορία",
      category: "Κατηγορία",
      subCategory: "Υποκατηγορία",
      parentCat: "Κύρια κατηγορία",
      attachmentCat: "Κατηγορίες πεδίων",
      editCat: "Επεξεργασία Κατηγορίας",
    },
    notificationWords: {
      addNotification: "Προσθήκη Ειδοποιήσεων",
      send: "Αποστολή",
    },
    eventsWords: {
      addEvent: "Προσθήκη εκδήλωσης",
      editEvent: "Επεξεργασία εκδήλωσης",
      expires: "Ημερομηνία λήξης: ",
      publish: "Ημερομηνία έκδοσης: ",
    },
    presentationWords: {
      addPresentation: "Προσθήκη παρουσίασης",
      editPresentation: "Επεξεργασία παρουσίασης",
    },
    notificationWords: {
      addNotification: "Προσθήκη Ειδοποιήσεων",
      send: "Αποστολή",
    },

    exhibitionWords: {
      edit: "Διαχείριση Διαδρομής",
      add: "Προσθήκη Διαδρομής",
      exhibitionPlaceholder: "Εισαγωγή Διαδρομής..",
    },

    tagWords: {
      edit: "Διαχείριση ΅Ετικέτας",
      add: "Προσθήκη Ετικέτας"
    },

    editWords: {
      update: "Ανανέωση",
      serialNumber: "Κωδικός Εκθέματος",
      serialPlaceholder: "Εισαγωγή Κωδικού..",
      exhibitions: "Διαδρομές",
      edit: "Διαχείριση Ειδοποιήσεων",
      qrDownload: "Λήψη QR κωδικού",
      qrCode: "QR κωδικός",
      editExhibit: "Διαχείριση Εκθέματος",
      name: "Όνομα",
      namePlaceholder: "Εισαγωγή Ονόματος..",
      tags: "Ετικέτες"
    },

    mediaWords: {
      all: "Όλα",
      images: "Εικόνες",
      video: "Βίντεο",
      audio: "Ήχοι",
      ts: "360",
      search: "Αναζήτηση..",
      upload: "Μεταφόρτωση",
      addMedia: "Προσθήκη Μέσων",
      computer: "Υπολογιστής",
      mediagallery: "Πολυμέσα",
      mulitple: "Μεταφόρτωση Αρχείων",
      crop: "Περικοπή",
      cropTitle: "Περικοπή φωτογραφίας",
      uploadSuccess: "Επιτυχής Μεταφόρτωση Αρχείων",
      selectFiles: "Επιλογή αρχείων",
      uploadTitle: "Μεταφόρτωση Αρχείων",
      uploadLength: "Παρακαλώ επιλέξτε αρχεία",
    },
    fieldWords: {
      choosePres: "Επιλογή Παρουσίασης",
      addPres: "Προσθήκη Πεδίου",
      cat: "Κατηγορία",
      select: "Επιλογή",
      edit: "Επεξεργασία πεδίου",
    },
    login: "Είσοδος",
  },
  denyRoleEdit: {
    siteAlert: "Ο ρόλος χρήστη σας δεν έχει δικαίωμα επεξεργασίας μουσείων",
    catAlert: "Ο ρόλος χρήστη σας δεν έχει δικαίωμα επεξεργασίας κατηγοριών",
    fieldAlert: "Ο ρόλος χρήστη σας δεν έχει δικαίωμα επεξεργασίας πεδίων",
    presentationAlert:
      "Ο ρόλος χρήστη σας δεν έχει δικαίωμα επεξεργασίας παρουσιάσεων",
    eventsAlert: "Ο ρόλος χρήστη σας δεν έχει δικαίωμα επεξεργασίας εκδηλώσεων",
    exhibitionsAlert:
      "Ο ρόλος χρήστη σας δεν έχει δικαίωμα επεξεργασίας διαδρομών",
    global: "Ο ρόλος χρήστη σας δεν έχει δικαίωμα επεξεργασίας",
  },
  denyRoleDelete: {
    catAlert: "Ο ρόλος χρήστη σας δεν έχει δικαίωμα διαγραφής κατηγοριών",
    fieldAlert: "Ο ρόλος χρήστη σας δεν έχει δικαίωμα διαγραφής πεδίων",
    presentationAlert:
      "Ο ρόλος χρήστη σας δεν έχει δικαίωμα διαγραφής παρουσιάσεων",
    eventsAlert: "Ο ρόλος χρήστη σας δεν έχει δικαίωμα διαγραφής εκδηλώσεων",
    exhibitionsAlert:
      "Ο ρόλος χρήστη σας δεν έχει δικαίωμα διαγραφής διαδρομών",
    global: "Ο ρόλος χρήστη σας δεν έχει δικαίωμα διαγραφής",
  },
  denyRoleAdd: {
    langAlert: "Ο ρόλος χρήστη σας δεν έχει δικαίωμα προσθήκης γλωσσών",
    siteAlert: "Ο ρόλος χρήστη σας δεν έχει δικαίωμα προσθήκης μουσείων",
    catAlert: "Ο ρόλος χρήστη σας δεν έχει δικαίωμα προσθήκης κατηγοριών",
    fieldAlert: "Ο ρόλος χρήστη σας δεν έχει δικαίωμα προσθήκης πεδίων",
    presentationAlert:
      "Ο ρόλος χρήστη σας δεν έχει δικαίωμα προσθήκης παρουσιάσεων",
    eventsAlert: "Ο ρόλος χρήστη σας δεν έχει δικαίωμα προσθήκης εκδηλώσεων",
    exhibitionsAlert:
      "Ο ρόλος χρήστη σας δεν έχει δικαίωμα προσθήκης διαδρομών",
    global: "Ο ρόλος χρήστη σας δεν έχει δικαίωμα προσθήκης",
  },
  deleteSelected: {
    multiDelete: "Πρόκειται να διαγραφεί ο ακόλουθος αριθμός εκθεμάτων ",

    catAlert: "Σίγουρα θέλετε να διαγράψετε την κατηγορία με id  : ",
    fieldAlert: "Σίγουρα θέλετε να διαγράψετε το πεδίο με id  : ",
    presentationAlert: "Σίγουρα θέλετε να διαγράψετε την παρουσίαση με id  : ",
    eventsAlert: "Σίγουρα θέλετε να διαγράψετε την εκδήλωση με id  : ",
    exhibitionsAlert: "Σίγουρα θέλετε να διαγράψετε την διαδρομή με id  : ",
    exhibitAlert: "Σίγουρα θέλετε να διαγράψετε το έκθεμα ",
  },
  content: {
    categoryDrop: "Όλες",
    categoryChoose: "Επιλέξτε",
    categoryUpdate: "Καμία",
    subCategoryUpdate: "Καμία",
    finish: "Finish",
    button: "Αποθήκευση",
    del: "Διαγραφή",
    size: "*Μέγεθος:",
    mulitpleDelConfirm: "Είστε σίγουροι ότι θέλετε να διαγράψετε τα ",
    deleteConfirm: "Είστε βέβαιοι πως θέλετε να διαγράψετε το αντικείμενο ",
    tabs: {
      tab1: "Επεξεργασία",
      tab2: "Μεταφράσεις",
      tab3: "Πεδία",
      tab4: "Πολυμέσα",
      tab5: "Τοποθεσία",
    },
    routes: {
      searchPlaceholder: "Αναζήτηση..",
      placeholder: "Προσθήκη",
    },
    fileSize: {
      video:
        "Το αρχείο δεν φορτώθηκε λόγω μεγέθους. Παρακαλώ μεταφορτώστε βίντεο με μέγιστο μέγεθος 15Mb",
      image:
        "Το αρχείο δεν φορτώθηκε λόγω μεγέθους. Παρακαλώ μεταφορτώστε εικόνες με μέγιστο μέγεθος 1Mb",
      audio:
        "Το αρχείο δεν φορτώθηκε λόγω μεγέθους. Παρακαλώ μεταφορτώστε αρχεία ήχου με μέγιστο μέγεθος 5Mb",
    },
    langSeleectForFiles: "Επιλογή Γλώσσας :",
    langCountAlert: "Παρακαλώ επιλέξετε τουλαχιστον μία γλώσσα",
    clearSelectedLabel: {
      choose: "Επιλέξτε πολλαπλά αρχεία:",
      clear: "Καθαρισμός",
      value: "Εισαγωγή",
      back: "Πίσω",
    },
    tab5: {
      long: "Γεωγραφικό Μήκος:",
      lat: "Γεωγραφικό Πλάτος:",
      showOnMap: "Προβολή",
      successSave: "Επιτυχής Αποθήκευση",
      alert: "Παρακαλώ εισάγετε τιμές για μήκος και πλάτος",
    },
    update: {
      exhibitCodeAlert: "Ο κωδικός εκθέματος υπάρχει ήδη",
      exhibitCodeLength:
        "Παρακαλώ συμπληρώστε σωστό κωδικό εκθέματος. Ορίστε τιμές μεταξύ 100 και 100000",
      categoryAlert:
        "Παρακαλώ εισάγετε κατηγορία για την ανανέωση του αντικειμένου",
    },
    //updateImageSize: `Το αρχείο με όνομα ${file.name} δεν φορτώθηκε λόγω μεγέθους. Παρακαλώ μεταφορτώστε εικόνες με μέγιστο μέγεθος 1Mb`
  },
  new: {
    saveObjRole: "Ο ρόλος χρήστη σας δεν επιτρέπει την εισαγωγή αντικειμένων",
    exhibitCodeAlert: "Ο κωδικός εκθέματος υπάρχει ήδη",
    exhibName: "Παρακαλώ εισάγετε όνομα εκθέματος",
    exhibitCodeLength:
      "Παρακαλώ συμπληρώστε σωστό κωδικό εκθέματος. Ορίστε τιμές μεταξύ 100 και 100000",
    categoryAlert: "Παρακαλώ εισάγετε κατηγορία",
  },
  map: {
    long: "Γεωγραφικό Μήκος :",
    lat: "Γεωγραφικό Πλάτος :",
    route: "Διαδρομή : ",
    serial: "Σειριακός αριθμός : ",
  },

  notifications: {
    firstNameAlert: "Μπορείτε να χρησιμοποιήσετε μέχρι 100 χαρακτήρες",
    lastNameAlert: "Μπορείτε να χρησιμοποιήσετε μέχρι 1200 χαρακτήρες",
    userAlert: "Ο ρόλος χρήστη σας δεν έχει δικαιώματα αποστολής ειδοποιήσεων",
    statusAlert: "Η κατάσταση της ειδοποίησης πρέπει να εγκριθεί",
  },
  media: {
    invalidAction: "Μη έγκυρη ενέργεια",
    confirmMessage: "Είστε σίγουροι?",
    successTitle: "Επιτυχία",
    successDesc: "Επιτυχής Ενέργεια",

    deleteTitle: "Διαγράφηκε",
    errorTitle: "Τα ακόλουθα αρχεία δεν φορτώθηκαν",
    successUpload: "Τα αρχεία μεταφορτώθηκαν επιτυχώς",
    limitErrorTitle: "Πολλά αρχεία επιλέχθησαν",
    limitErrorText: "Παρακαλώ μεταφορτώστε μέχρι 10 αρχεία ταυτόχρονα",
    limitErrorMultiDel: "Παρακαλώ επιλέξτε μέχρι 30 αρχεία ταυτόχρονα",
    successSave: "Επιτυχής αποθήκευση εικόνας",
    successDelete: "Επιτυχής Διαγραφή",
    deleteConfirm: "Είστε σίγουροι ότι θέλετε να διαγράψετε αυτό το αρχείο?",
    chooseConfirm: "Είστε σίγουροι ότι θέλετε την επιλεγμένη εικόνα?",
    profileError: "Οι εικόνες προφίλ δεν μπορούν να διαγραφούν",
    succcessChange: "Επιτυχής αλλαγή",
    noItemsTitle: "Δεν επιλέχθηκαν αντικείμενα",
    noItemsChosen: "Παρακαλώ τικάρετε τα αντικείμενα που επιθυμείτε",
    noProceed: 'Κάποια αρχεία δεν είχαν επιλεγμένη γλώσσα'

  },
  export: {
    exportData: "Εξαγωγή Δεδομένων",
    exportExhibits: "Εξαγωγή Εκθεμάτων",
    exportMedia: "Εξαγωγή Πολυμέσων",
    downloadxlsConfig: "Εξαγωγή Ρυθμίσεων σε μορφή xls",
    downloadxlsExhib: "Εξαγωγή Εκθεμάτων σε μορφή xls",
    downloadxlsMedia: "Εξαγωγή Πολυμέσων σε μορφή zip",
    buttonConfig: "Εξαγωγή Αρχείου Ρυθμίσεων",
    buttonExhib: "Εξαγωγή Αρχείου Εκθεμάτων",
    buttonMedia: "Εξαγωγή Αρχείου Πολυμέσων",
    success: "Επιτυχία",
    successDownload: "Επιτυχής λήψη αρχείου",
  },
  import: {
    importData: "Εισαγωγή Ρυθμίσεων",
    importExhibits: "Εισαγωγή Εκθεμάτων",
    importMedia: "Εισαγωγή Πολυμέσων",
    uploadxlsConfig: "Εισάγετε το αρχείο Ρυθμίσεων σε μορφή xls",
    uploadxlsExhib: "Εισάγετε το αρχείο Εκθεμάτων σε μορφή xls",
    uploadxlsMedia: "Εισάγετε το αρχείο Πολυμέσων σε μορφή zip",
    configTemplate: "Πρότυπο Αρχείου Ρυθμίσεων",
    exhibitTemplate: "Πρότυπο Αρχείου Εκθεμάτων",
    convert: "Μετατροπή & Μεταφόρτωση",
    success: "Επιτυχία",
    successUpload: "Επιτυχής μεταφόρτωση αρχείου",
    issues: "Εντοπίστηκαν ζητήματα",
    nofile: "Δεν επιλέχθηκε αρχείο",

    issuesText:
      "Εντοπίστηκαν ένα ή περισσότερα ζητήματα κατά τη μεταφόρτωση του αρχείου σας. Ορισμένα εκθέματα μπορεί να μην έχουν μεταφορτωθεί σωστά",
    fileType: "Παρακαλώ χρησιμοποιήστε τον σωστό τύπο αρχείου",
  },
  analytics: {
    pagesLabel: "Πιο Δημοφιλείς Σελίδες ",
    languagesLabel: "Γλώσσες",
    agesLabel: "Ηλικιακές Κατηγορίες",
    genderLabel: "Φύλα",
    visitorsLabel: "Επισκέπτες Ανά Ημέρα",
    timeOnSiteLabel: "Χρόνος Επισκεψιμότητας Ανά Ημέρα",
    bounceRateLabel: "Ποσοστό Αναπήδισης",
  },
  user: {
    passwordError: "Τα πεδία συνθηματικών δεν ταιριάζουν",
  },
  languages: {
    Arabic: "Αραβικά",
    Africans: "Αφρικανικά",
    Chinese: "Κινέζικα",
    English: "Αγγλικά",
    French: "Γαλλικά",
    German: "Γερμανικά",
    Greek: "Ελληνικά",
    Hebrew: "Εβραικά",
    Hindi: "Ινδικά",
    Italian: "Ιταλικπά",
    Japanese: "Ιαπωνικά",
    Korean: "Κορεάτικα",
    Portugese: "Πορτογαλικά",
    Russian: "Ρωσσικά",
    Spanish: "Ισπανικά",
    Turkish: "Τουρκικά",
  },
};

export { language, languageGr };
