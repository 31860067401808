import { ObjectApi, FieldApi } from '../../api/api.service';





const nameFill = (exhibit) => {
    if (document.getElementById("nameOfObject") != null) {
        document.getElementById("nameOfObject").innerHTML = `<img style="" class="navPhoto" src="../images/xenagos/edit-orange-s.png"/><h5 style="color: #1c4325;">${exhibit.name}</h5>`;

    }
    //document.getElementById("nameOfObject").innerHTML = ""; 
}

const fieldCreate = async (fields, languageId, objects, presentationId) => {
    document.getElementById("fields").innerHTML = '';
    let countSaveFields = 1000;
    let fieldValue;
    
    
    for (let i = 0; i < fields.length; i++) {
        //console.log(objects)
        const field = await FieldApi.GetByTranslation(parseInt(fields[i].fieldId));

        //console.log(field);

        
        const extracted = field.find(l => l.languageId == languageId);

        //console.log(extracted);
        
        if (extracted) {
            
            const objectField = objects.find(t => t.fieldId == extracted.fieldId && t.languageId == extracted.languageId && t.presentationId == presentationId);
            //const objectField = objects.presentations[0]
            //console.log(objectField);


            
           
            if (objectField === undefined) {
                fieldValue = '';

            }
            else {

                fieldValue = objectField.shortValue;
                //console.log(fieldValue);
                
            }
           
           

            document.getElementById("fields").innerHTML += `<div class="row" id="${extracted.fieldId}">
                                        <div class="col-md-12">
                                            <div class="row" style="margin-top:30px;">
                                                
                                                <div class="col-md-4 offset-1" style="overflow: hidden;white-space: initial;text-overflow: ellipsis;text-align: center;
                                                height:4vh;
                                                background: #ffa50036;
                                                border-radius: 12px;">
                                                    
                                                    <label style="font-size: 20px;" id="languageId_${extracted}" for=${extracted} >${extracted.name}</label>
                                                </div>
                                                <div class="col-md-7">
                                                    <textarea id="fieldName_${countSaveFields}" type="text" class="form-control form-description txtArea" style="padding-left: 1vw;border-radius:20px;" cols="10" rows="1" >${fieldValue}</textarea>
                                                </div>
                                           
                                            </div>
                                            <div id="buttonContainer__${extracted.fieldId}"></div>
                                        </div>
                                    </div>`;
            countSaveFields++;
            
            //var textarea = document.getElementById(`fieldName_${countSaveFields}`); margin-left:3vw; inside label
            //console.log(textarea);
            //var limit = 200; //height limit

            //textarea.oninput = function () {
            //    textarea.style.height = "";
            //    textarea.style.height = Math.min(textarea.scrollHeight, limit) + "px";
            //};
        }

        
      

    }

}



const saveTheFields = async (fields, languageId, presentationId, exhibit) => {
    //console.log(exhibit)
    await ObjectApi.RemoveObjectValues(parseInt(exhibit[0].objectId), parseInt(presentationId), parseInt(languageId) , true);
   
    let countSaveFields = 1000;
    
    
    for (let i = 0; i < fields.length; i++) {
        const field = await FieldApi.GetByTranslation(parseInt(fields[i].fieldId));
        //console.log(field);
        const extracted = field.find(l => l.languageId == languageId);
        //console.log(extracted);
        
        if (extracted) { 
           
            let name = document.getElementById(`fieldName_${countSaveFields}`).value;
            name = name.trim();
         
            if (name) { 

                const insertObject = {

                    exhibitId: exhibit[0].objectId,
                    LanguageId: parseInt(languageId),
                    PressId: parseInt(presentationId),
                    FieldID: fields[i].fieldId,
                    Status: true,
                    ShortValue: name,
                    LongValue: name,
                    Mobile: true,
                    Url: null

                }

               
                const insertObjectValues = await ObjectApi.Insert(insertObject);
                //Insert(object = { ObjectsId: 0, LanguageId: 1, PressId: 1, FieldID: 1, Status: true, ShortValue: 'Short Text', LongValue: 'Long Text', Mobile: true, url: '' })
                
            }

            countSaveFields++;

        }
    }

    
}

export { fieldCreate, saveTheFields };