import { useState, useEffect, Fragment } from "react";

// Bootstrap
import { Modal, Form, Button, Row, Col } from "react-bootstrap";
import { textFilter } from "react-bootstrap-table2-filter";

// API
import {
  ExhibitNew,
  PresentationApi,
  LanguageApi,
  SiteApi,
} from "../../api/api.service";
import { language, languageGr } from "../../api/lang";

//Authservice
import { authService } from "../../api/auth.service";

// Components
import CardSkeleton from "../UI/CardSkeleton";
import TableGeneral from "../UI/TableGeneral";
import ModalParent from "../UI/ModalParent";
import BundledEditor from "../../BundledEditor";

// Icons
import * as FaIcons from "react-icons/fa";
import Swal from "sweetalert2";
import manage from "../../images/manage-orange.png";

// CSS
import "./Presentation.css";
import "../../css/components/style.css";

const Presentation = () => {
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [modalShow, setModalShow] = useState(false);
  const [idClicked, setIdClicked] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const [checkedList, setCheckedList] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [tableChanged, setTableChanged] = useState(false);
  const [sites, setSites] = useState([]);
  const [trans, setTrans] = useState([]);
  const [addPres, setAddPres] = useState(false);
  const [openAddNewModal, setOpenAddNewModal] = useState(false);
  const [userRole, setUserRole] = useState("");
  const [languageChosenSite, setLanguageChosenSite] = useState("");
  const [presDesc, setPresDesc] = useState("");
  const [presName, setPresName] = useState("");

  useEffect(() => {
    document.body.className = "";
    getData();
  }, [tableChanged]);

  const getData = async () => {
    const presentationArray = await PresentationApi.Get();
    const languagesArray = await LanguageApi.Get();
    setLanguages(languagesArray);
    setData(presentationArray);
    const sitesOfCms = await SiteApi.Get();
    setSites(sitesOfCms);
    fetchUser();
    setLanguageChosenSite(sessionStorage.getItem("languageChosen"));

    setLoading(false);
    console.log(languages);
    changeHead();
  };

  const fetchUser = async () => {
    const user = await authService.getUserAsync();

    setUserRole(user.claims[0].value);
  };

  const changeHead = () => {
    if (sessionStorage.getItem("languageChosen") === "EL") {
      document.querySelector(".pageHead").innerHTML =`<div style="width: 100%; height:100%; display: inline-flex; justify-content: center;"><h4 style='padding-top: 3.5vh;text-align: center;color: #f7aa47;'><img style="height: 4vh;margin-top:-1vh;" class='fa fa-fw fa-file' src='${manage}' />${languageGr.global.titles.presentation}</h4></div>`;
    } else {
      document.querySelector(".pageHead").innerHTML =`<div style="width: 100%; height:100%; display: inline-flex; justify-content: center;"><h4 style='padding-top: 3.5vh;text-align: center;color: #f7aa47;'><img style="height: 4vh;margin-top:-1vh;" class='fa fa-fw fa-file' src='${manage}' />${language.global.titles.presentation}</h4></div>`;
    }
  };

  const editClick =  (row) => {
    setModalShow(true);
    setIdClicked(row.deleteId);
    console.log(modalShow);
    //console.log(translations);
  };

  async function addPresentation() {
    if (userRole == "Viewer") {
      Swal.fire({
        title: languageChosenSite == "EL" ? languageGr.media.invalidAction : language.media.invalidAction,
        text: languageChosenSite == "EL" ? languageGr.global.userRole : language.global.userRole,
        icon: "warning",
        button: "OK",
      });
      return;
    }
    const form = document.getElementById("addPresForm");
    console.log(form);
    if (form.checkValidity() === false) {
      form.classList.add("was-validated");
    } else {
      const english = languages.find((l) => l.initials === "EN");
      console.log(english);
      if (!english) {
        alert("Add English Language First");
      } else {
        let name = presName;
        name = name.trim();

        console.log(name);
        let description = presDesc;
        description = description.trim();
        console.log(description);

        const languageId = english.languageId;
        let presentation = {
          Name: name,
          Description: description,
          translations: [],
        };
        presentation.translations.push({
          LanguageId: parseInt(languageId),
          Name: name,
          Description: description,
        });
        const presentationCreated = await PresentationApi.Create(presentation);
        console.log(presentationCreated);
        const addPres = await PresentationApi.AddToSite(
          presentationCreated.presentationId,
          sites[0].siteId
        );
        console.log(addPres);
        form.reset();
        setTableChanged(!tableChanged);
        setOpenAddNewModal(false);
      }
    }
  }

  const handleHide = () => {
    setModalShow(false);
  };

  const edit = (cell, row, rowIndex, formatExtraData) => {
    return (
      <FaIcons.FaRegEdit
        id={row.presentationId}
        onClick={() => editClick(row)}
        className="editIcon"
        Edit
      />
    );
  };

  function onRowSelect(row, isSelected, e) {
    if (!isSelected) {
      console.log(e);

      console.log(data[e]);
      setCheckedList((prevState) => [...prevState, data[e].objectS_ID]);
    }
    let rowStr = "";
    for (const prop in row) {
      rowStr += prop + ': "' + row[prop] + '"';
    }

    console.log(checkedList);
  }

  const deleteClick = async (row) => {
    if (userRole == "Viewer") {
      Swal.fire({
        title: languageChosenSite == "EL" ? languageGr.media.invalidAction : language.media.invalidAction,
        text: languageChosenSite == "EL" ? languageGr.global.userRole : language.global.userRole,
        icon: "warning",
        button: "OK",
      });
      return;
    }
    let tar = row.presentationId;
    setIdClicked(tar);
    setDeleteId(tar);
    //let tarObj = await PresentationApi.GetById(tar);
    let tarName = row.name;

    Swal.fire({
      title: "Are you sure?",
      html: `You are about to delete the <strong>${tarName}</strong> presentation`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const deleted = await PresentationApi.PresentationDelete(parseInt(tar));
        setTableChanged(!tableChanged);
      }
    });
  };

  const del = (cell, row, rowIndex, formatExtraData) => {
    return (
      <FaIcons.FaRegTrashAlt
        id={row.presentationId}
        onClick={() => deleteClick(row)}
        className="deleteIcon"
        Delete
      />
    );
  };

  const descriptionFormat = (data) => {
    return data.replace(/<[^>]+>/g, "").substring(0, 50) + " ....";
  };

  const selectRowProp = {
    mode: "checkbox",
    hideSelectAll: true,
    onSelect: onRowSelect,
    onSelectAll: onSelectAll,
  };

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      console.log(e);
      console.log({ row, rowIndex });
      setIdClicked(row.presentationId);
      setDeleteId(row.presentationId);
    },
  };

  function onSelectAll(isSelected, data, e) {
    console.log(e);
  }

  const changeTable = () => {
    setTableChanged(!tableChanged);
  }

  const presentationHeaders = [
    // {
    //   dataField: "presentationId",
    //   text: "Id",
    //   sort: true,
    // },
    {
      dataField: "name",
      text: languageChosenSite === "EL"
      ? languageGr.table.title
      : language.table.title,
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: "description",
      text:languageChosenSite === "EL"
      ? languageGr.table.description
      : language.table.description,
      sort: true,
      formatter: descriptionFormat,
    },

    {
      dataField: "edit",
      text: languageChosenSite === "EL"
      ? languageGr.table.edit
      : language.table.edit,
      formatter: edit,
    },
    {
      dataField: "delete",
      text: languageChosenSite === "EL"
      ? languageGr.table.delete
      : language.table.delete,
      formatter: del,
    },
  ];

  return (
    <Fragment>
      {modalShow && (
        <ModalParent
          changeTable={changeTable}
          onHide={handleHide}
          data={data}
          show={modalShow}
          onSetModalShow={setModalShow}
          targetId={idClicked}
          languages={languages}
          //translations={translations}
          rowEvents={rowEvents}
          userRole={userRole}
        ></ModalParent>
      )}
      {isLoading ? (
        <div className="col-10 offset-1">
          <CardSkeleton />
        </div>
      ) : (
        <TableGeneral
          data={data}
          columns={presentationHeaders}
          rowEvents={rowEvents}
          //selectRow={selectRowProp}
        />
      )}
      <Button
        className="addNewButton"
        variant="addNewButton"
        onClick={() => setOpenAddNewModal(true)}
      >
        {languageChosenSite === "EL"
          ? languageGr.global.word.addNew
          : language.global.word.addNew}
      </Button>

      <Modal
        show={openAddNewModal}
        onHide={() => setOpenAddNewModal(false)}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {languageChosenSite === "EL"
              ? languageGr.global.word.addNew
              : language.global.word.addNew}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form id="addPresForm">
            <Form.Group className="mb-2">
              <Form.Label>
                <Form.Label>
                  {languageChosenSite === "EL"
                    ? languageGr.global.transName
                    : language.global.transName}
                </Form.Label>
              </Form.Label>
              <Form.Control
                type="text"
                maxlength="30"
                required
                placeholder={
                  languageChosenSite === "EL"
                    ? languageGr.global.enterTitle
                    : language.global.enterTitle
                }
                onChange={(newN) => setPresName(newN.target.value)}
              />
              <div className="valid-feedback">Valid.</div>
              <div className="invalid-feedback">
                {languageChosenSite === "EL"
                  ? languageGr.global.fillThis
                  : language.global.fillThis}
              </div>
            </Form.Group>

            <Form.Group className="mb-2" controlId="">
              <Form.Label>
                {languageChosenSite === "EL"
                  ? languageGr.global.transDesc
                  : language.global.transDesc}
              </Form.Label>
              <BundledEditor
                init={{
                  height: 400,
                  selector: "textarea",
                  plugins:
                    "advlist anchor autolink help image link lists searchreplace table wordcount emoticons insertdatetime code",
                  toolbar:
                    "undo redo blocks image link media numlist bullist insertdatetime|",
                  content_style:
                    "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                }}
                onEditorChange={(newD) => setPresDesc(newD)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="addNewButton"
            variant="addNewButton"
            onClick={addPresentation}
          >
            {languageChosenSite === "EL"
              ? languageGr.global.word.addNew
              : language.global.word.addNew}
          </Button>
          <Button
            className="closeButton"
            variant="closeButton"
            onClick={() => setOpenAddNewModal(false)}
          >
            {languageChosenSite === "EL"
              ? languageGr.global.close
              : language.global.close}
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default Presentation;
