// @ts-nocheck
import React from "react";
import { useState } from "react";
import Draggable from "react-draggable";
import { useDrag, useDrop } from "react-dnd";
import { LayoutEditor } from "../layoutEditor";

const layoutEditor = new LayoutEditor();

export default function EditorItem(props) {
  //const [isDragging, setIsDragging] = useState(false);
  const ref = React.createRef();
  const item = props.item;
  const itemId = props.itemId;
  const selected = props.selected;
  const setSelected = props.setSelected;

  const [{ isDragging }, drag] = useDrag(() => ({
    type: "tile",
    item: { id: item.id },
    collect: (monitor) => ({ isDragging: !!monitor.isDragging() }), //Optional
  }));

  const handlePress = () => {
    setSelected(item);
  };

  if (item.deleted) {
    return null;
  } else {
    return (
      <div
        ref={drag}
        className={layoutEditor.getSize(item.size)}
        onClick={handlePress}
        style={{
          backgroundImage: item.background ? `url(${item.background})` : null,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          border: selected === item ? "4px solid #F98304" : "1px solid #EDEDE8",
          backgroundColor: item.color,
        }}
      >
        {item.icon && (
          <svg
            className="editorIcon"
            viewbox="0 0 50 50"
            height="48"
            width="48"
          >
            <path fill="#edede8" d={item.icon} />
          </svg>
        )}
        {item.title}
      </div>
    );
  }
}
