export const markerIcon = {
  name: "Marker",
  path: "M24 23.5q1.45 0 2.475-1.025Q27.5 21.45 27.5 20q0-1.45-1.025-2.475Q25.45 16.5 24 16.5q-1.45 0-2.475 1.025Q20.5 18.55 20.5 20q0 1.45 1.025 2.475Q22.55 23.5 24 23.5Zm0 16.55q6.65-6.05 9.825-10.975Q37 24.15 37 20.4q0-5.9-3.775-9.65T24 7q-5.45 0-9.225 3.75Q11 14.5 11 20.4q0 3.75 3.25 8.675Q17.5 34 24 40.05ZM24 44q-8.05-6.85-12.025-12.725Q8 25.4 8 20.4q0-7.5 4.825-11.95Q17.65 4 24 4q6.35 0 11.175 4.45Q40 12.9 40 20.4q0 5-3.975 10.875T24 44Zm0-23.6Z",
};

export const stairIcon = {
  name: "Stairs",
  path: "M12.5 35.5h7.85v-6.65h5.15V22.2h5.15v-6.7h4.85v-3h-7.85v6.65H22.5v6.65h-5.15v6.7H12.5ZM9 42q-1.2 0-2.1-.9Q6 40.2 6 39V9q0-1.2.9-2.1Q7.8 6 9 6h30q1.2 0 2.1.9.9.9.9 2.1v30q0 1.2-.9 2.1-.9.9-2.1.9Zm0-3h30V9H9v30ZM9 9v30V9Z",
};

export const wcIcon = {
  name: "Restroom",
  path: "M10.6 44V29H8.1V17.6q0-1.45 1.075-2.525T11.7 14h5.2q1.45 0 2.525 1.075T20.5 17.6V29H18v15Zm3.7-32.7q-1.5 0-2.575-1.075Q10.65 9.15 10.65 7.65q0-1.5 1.075-2.575Q12.8 4 14.3 4q1.5 0 2.575 1.075Q17.95 6.15 17.95 7.65q0 1.5-1.075 2.575Q15.8 11.3 14.3 11.3ZM31.5 44V32h-5.2l4.3-15.55q.35-1.2 1.325-1.825T34.2 14q1.3 0 2.275.625.975.625 1.325 1.825L42.1 32h-5.2v12Zm2.7-32.7q-1.5 0-2.575-1.075Q30.55 9.15 30.55 7.65q0-1.5 1.075-2.575Q32.7 4 34.2 4q1.5 0 2.575 1.075Q37.85 6.15 37.85 7.65q0 1.5-1.075 2.575Q35.7 11.3 34.2 11.3Z",
};

export const storeIcon = {
  name: "Shop",
  path: "M42 22.05V39q0 1.2-.9 2.1-.9.9-2.1.9H8.95q-1.2 0-2.1-.9-.9-.9-.9-2.1V22.05q-1.4-1.2-1.85-2.95-.45-1.75.1-3.5l2.15-6.75q.4-1.35 1.4-2.1 1-.75 2.3-.75H37.7q1.4 0 2.45.775 1.05.775 1.45 2.075l2.2 6.75q.55 1.75.075 3.5Q43.4 20.85 42 22.05ZM28.5 20.5q1.45 0 2.45-.95 1-.95.8-2.3L30.5 9h-5v8.25q0 1.3.85 2.275.85.975 2.15.975Zm-9.35 0q1.4 0 2.375-.95.975-.95.975-2.3V9h-5l-1.25 8.25q-.2 1.3.7 2.275.9.975 2.2.975Zm-9.1 0q1.2 0 2.075-.825.875-.825 1.025-2.025L14.45 9h-5l-2.3 7.3q-.5 1.55.4 2.875t2.5 1.325Zm27.85 0q1.6 0 2.525-1.3.925-1.3.425-2.9L38.55 9h-5l1.3 8.65q.15 1.2 1.025 2.025.875.825 2.025.825ZM8.95 39H39V23.45q.05.05-.325.05H37.9q-1.25 0-2.375-.525T33.3 21.35q-.8 1-2 1.575t-2.65.575q-1.5 0-2.575-.425Q25 22.65 24 21.65q-.75.9-1.9 1.375t-2.6.475q-1.55 0-2.75-.55t-2.05-1.6q-1.2 1.05-2.35 1.6-1.15.55-2.3.55h-.675q-.325 0-.425-.05V39ZM39 39H8.95 39Z",
};

export const fireIcon = {
  name: "Fire Extinguisher",
  path: "M18 44q-1.65 0-2.825-1.175Q14 41.65 14 40v-2h20v2q0 1.65-1.175 2.825Q31.65 44 30 44Zm-4-7.5v-11h20v11Zm.2-12.5q.5-2.3 2.1-4.3t4.05-3q-.5-.4-.9-.875t-.7-1.025L10 12.75v-1.5l8.75-2.05q.75-1.45 2.125-2.325Q22.25 6 24 6q1.05 0 2 .35t1.7.95L34 6v12l-6.3-1.3h-.05q2.35.95 3.975 2.875T33.8 24Zm9.8-9q1.3 0 2.15-.875Q27 13.25 27 12q0-1.3-.875-2.15Q25.25 9 24 9t-2.125.85Q21 10.7 21 12q0 1.25.875 2.125T24 15Z",
};

export const circleIcon = {
  name: "Circle",
  path: "M24 44q-4.1 0-7.75-1.575-3.65-1.575-6.375-4.3-2.725-2.725-4.3-6.375Q4 28.1 4 24q0-4.15 1.575-7.8 1.575-3.65 4.3-6.35 2.725-2.7 6.375-4.275Q19.9 4 24 4q4.15 0 7.8 1.575 3.65 1.575 6.35 4.275 2.7 2.7 4.275 6.35Q44 19.85 44 24q0 4.1-1.575 7.75-1.575 3.65-4.275 6.375t-6.35 4.3Q28.15 44 24 44Zm0-3q7.1 0 12.05-4.975Q41 31.05 41 24q0-7.1-4.95-12.05Q31.1 7 24 7q-7.05 0-12.025 4.95Q7 16.9 7 24q0 7.05 4.975 12.025Q16.95 41 24 41Zm0-17Z",
};
