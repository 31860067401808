import React, { useState, useEffect } from "react";

//API
import { MatomoApi } from "../../api/api.service";

//Bootstrap
import { Container, Row, Col, Button, Table } from "react-bootstrap";

//languages
import { language, languageGr } from "../../api/lang";

//image
import manage from "../../images/manage-orange.png";

//Chart
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  LineElement,
} from "chart.js";
import { Doughnut, Bar, Line } from "react-chartjs-2";
// import { faL } from "@fortawesome/free-solid-svg-icons";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  ArcElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const colors = [
  "rgba(255, 0, 0, 0.5)",
  "rgba(255, 64, 0, 05)",
  "rgba(255, 128, 0, 0.5)",
  "rgba(255, 191, 0, 0.5)",
  "rgba(255, 255, 0, 0.5)",
  "rgba(191, 255, 0, 0.5)",
  "rgba(128, 255, 0, 0.5)",
  "rgba(64, 255, 0, 0.5)",
  "rgba(0, 255, 255, 0.5)",
  "rgba(0, 191, 255, 0.5)",
];

function Statistics() {
  const [monthlyReport, setMonthlyReport] = useState();
  const [liveUsersCount, setLiveUsersCount] = useState();
  const [topPageTitles, setTopPageTitles] = useState();
  const [chosenLanguages, setChosenLanguages] = useState({
    labels: [],
    datasets: [],
  });
  const [chosenAge, setChosenAge] = useState({
    labels: [],
    datasets: [],
  });
  const [chosenGender, setChosenGender] = useState({
    labels: [],
    datasets: [],
  });
  const [dailyInfo, setDailyInfo] = useState({ labels: [], datasets: [] });

  useEffect(() => {
    changeHead();
    document.body.className = "";

    getMatomoApiData();
  }, []);

  const changeHead = () => {
    if (sessionStorage.getItem("languageChosen") === "EL") {
      document.querySelector(".pageHead").innerHTML =`<div style="width: 100%; height:100%; display: inline-flex; justify-content: center;"><h4 style='padding-top: 3.5vh;text-align: center;color: #f7aa47;'><img style="height: 4vh;margin-top:-1vh;" class='fa fa-fw fa-file' src='${manage}' />${languageGr.global.titles.statistics}</h4></div>`;
    } else {
      document.querySelector(".pageHead").innerHTML =`<div style="width: 100%; height:100%; display: inline-flex; justify-content: center;"><h4 style='padding-top: 3.5vh;text-align: center;color: #f7aa47;'><img style="height: 4vh;margin-top:-1vh;" class='fa fa-fw fa-file' src='${manage}' />${language.global.titles.statistics}</h4></div>`;
    }
  };

  const getMatomoApiData = async () => {
    const monthlyReport = await MatomoApi.MonthlyReport();
    setMonthlyReport(monthlyReport);

    const topPageTitles = await MatomoApi.GetTopPageTitles();

    fetch(topPageTitles)
      .then((response) => response.json())
      .then((data) => {
        let labels = [];
        console.log(data);
        for (let i in data) {
          for (let j of data[i]) {
            labels.push({ label: j.label, visits: j.nb_visits });
          }
        }

        const groupedMap = Array.from(
          labels
            .reduce(
              (entryMap, e) =>
                entryMap.set(e.label, [...(entryMap.get(e.label) || []), e]),
              new Map()
            )
            .values()
        );

        const uniqueLabels = [];
        for (let arr of groupedMap) {
          const sum = arr
            .filter((element) => element.label === arr[0].label)
            .map((element) => element.visits)
            .reduce((previousSum, currentValue) => previousSum + currentValue);

          uniqueLabels.push({ label: arr[0].label, visitors: sum });
        }

        setTopPageTitles(uniqueLabels);
      });

    const userCount = await MatomoApi.GetLiveUsersCount();
    fetch(userCount)
      .then((response) => response.json())
      .then((data) => {
        setLiveUsersCount(data[0].visitors);
      });

    const chosenLang = await MatomoApi.GetChosenLanguages();
    fetch(chosenLang)
      .then((response) => response.json())
      .then((data) => {
        let label = [];
        let greek = [];
        let english = [];

        for (let i in data) {
          label.push(i);
          if (data[i].length === 0) {
            greek.push(0);
            english.push(0);
          } else {
            for (let j of data[i]) {
              if (j.label === "Greek") greek.push(j.nb_visits);
              if (j.label === "English") english.push(j.nb_visits);
            }
          }
        }

        setChosenLanguages({
          labels: label,
          datasets: [
            {
              label: "Greek",
              data: greek,
              backgroundColor: "rgba(8, 168, 239, 0.5)",
            },
            {
              label: "English",
              data: english,
              backgroundColor: "rgba(223, 75, 164, 0.5)",
            },
          ],
        });
      });

    const chosenAge = await MatomoApi.GetChosenAge();
    fetch(chosenAge)
      .then((response) => response.json())
      .then((data) => {
        let ages = [0, 0, 0, 0, 0, 0];
        for (let i in data) {
          if (!data[i].length === 0) {
            for (let j of data[i]) {
              if (parseInt(j.label) <= 17) ages[0] += 1;
              if (parseInt(j.label) >= 18 && parseInt(j.label) <= 25)
                ages[1] += 1;
              if (parseInt(j.label) >= 26 && parseInt(j.label) <= 40)
                ages[2] += 1;
              if (parseInt(j.label) >= 41 && parseInt(j.label) <= 59)
                ages[3] += 1;
              if (parseInt(j.label) >= 60) ages[4] += 1;
              if (j.label === undefined) ages[5] += 1;
            }
          }
        }

        setChosenAge({
          labels: ["0-17", "18-25", "26-40", "41-59", "60+", "Undefined Age"],
          datasets: [
            {
              label: "Age",
              data: ages,
              backgroundColor: "rgba(128, 255, 0, 0.5)",
            },
          ],
        });
      });

    const chosenGender = await MatomoApi.GetChosenGender();
    fetch(chosenGender)
      .then((response) => response.json())
      .then((data) => {
        let label = [];
        let male = [];
        let female = [];

        for (let i in data) {
          label.push(i);
          if (data[i].length === 0) {
            female.push(0);
            male.push(0);
          } else {
            for (let j of data[i]) {
              if (j.label === "Female") female.push(j.nb_visits);
              if (j.label === "Male") male.push(j.nb_visits);
            }
          }
        }

        setChosenGender({
          labels: label,
          datasets: [
            {
              label: "Female",
              data: female,
              backgroundColor: "rgba(223, 75, 164, 0.5)",
            },
            {
              label: "Male",
              data: male,
              backgroundColor: "rgba(8, 168, 239, 0.5)",
            },
          ],
        });
      });

    const dInfo = await MatomoApi.GetDailyInfo();
    fetch(dInfo)
      .then((response) => response.json())
      .then((data) => {
        let label = [];
        let d = [];
        for (let i in data) {
          label.push(i);
          d.push(data[i].nb_visits);
        }

        setDailyInfo({
          labels: label,
          datasets: [
            {
              label: "Visitors per Day",
              data: d,
              backgroundColor: colors,
            },
          ],
        });
      });
  };

  return (
    <Container>
      <Row className="py-4">
        <Col s={12} lg={12} className="d-flex justify-content-end">
          <div className="p-1 rounded addNewButton addNewButtontext-white">
            <h3 className="fw-light">Live visitors: {liveUsersCount}</h3>
          </div>
        </Col>
      </Row>
      <Row className="d-flex justify-content-between">
        <Col s={12} lg={6} className="shadow-sm p-1 mb-5 bg-body rounded">
          <h2 className="fw-light">Number of Visitors</h2>
          <Stats apiData={dailyInfo} />
        </Col>
        <Col s={12} lg={6} className="shadow-sm p-1 mb-5 bg-body rounded">
          <h2 className="fw-light">Genders</h2>
          <Stats apiData={chosenGender} />
        </Col>
      </Row>
      <Row className="d-flex justify-content-between">
        <Col s={12} lg={6} className="shadow-sm p-1 mb-5 bg-body rounded">
          <h2 className="fw-light">Languages</h2>
          <Stats apiData={chosenLanguages} />
        </Col>
        <Col s={12} lg={6} className="shadow-sm p-1 mb-5 bg-body rounded">
          <h2 className="fw-light">Age Groups</h2>
          <Stats apiData={chosenAge} />
        </Col>
      </Row>
      <Row className="d-flex justify-content-center">
        <Col s={12} lg={8} className="shadow-sm bg-body rounded">
          <h2 className="fw-light">Top 10 pages</h2>
          <TopPages data={topPageTitles} />
        </Col>
        <Col xs={12} lg={12}>
          <a href={monthlyReport} target="_blank" rel="noreferrer">
            <Button className="my-5 addNewButton">
              Generate Monthly Report
            </Button>
          </a>
        </Col>
      </Row>
    </Container>
  );
}

export default Statistics;

function Stats(props) {
  const [statisticType, setstatisticType] = useState("bar");

  const handleVisitorsPerDay = (type) => {
    return setstatisticType(type);
  };

  return (
    <div>
      {statisticType === "line" ? <Line data={props.apiData} /> : null}
      {statisticType === "bar" ? <Bar data={props.apiData} /> : null}
      {statisticType === "pie" ? <Doughnut data={props.apiData} /> : null}
      <Button
        className="mx-1 my-3 addNewButton"
        onClick={() => handleVisitorsPerDay("line")}
      >
        Line
      </Button>
      <Button
        className="mx-1 my-3 addNewButton"
        onClick={() => handleVisitorsPerDay("bar")}
      >
        Bar
      </Button>
      <Button
        className="mx-1 my-3 addNewButton"
        onClick={() => handleVisitorsPerDay("pie")}
      >
        Pie
      </Button>
    </div>
  );
}

function TopPages(props) {
  return (
    <Table striped bordered>
      <thead>
        <tr>
          <th>Page</th>
          <th>Views</th>
        </tr>
      </thead>
      <tbody>
        {props.data !== undefined ? (
          props.data.slice(0, 10).map((value) => {
            return (
              <tr>
                <td>{value.label}</td>
                <td>{value.visitors}</td>
              </tr>
            );
          })
        ) : (
          <div></div>
        )}
      </tbody>
    </Table>
  );
}
