import { useState, useEffect, useCallback, useMemo } from "react";

import { next, prev } from "../UI/F&BButtons";
import * as FaIcons from "react-icons/fa";
import { authService } from '../../api/auth.service.js';
import { Spinner } from "react-bootstrap";

import { SiteApi, ExhibitNew, CategoryApi, ObjectApi, MediaApi, LanguageApi, FieldApi, PresentationApi } from '../../api/api.service';
import { language, languageGr } from '../../api/lang'
import $ from 'jquery';
import Swal from "sweetalert2";
import england from "../../images/Languages/great-britain-circular.png";
import greece from "../../images/Languages/greece-circular.png";
import spain from "../../images/Languages/spain2-circular.png";
import germany from "../../images/Languages/germany-circular.png";
import xenagos from "../../images/default.jpg";


//Resize Images
import Resizer from "react-image-file-resizer";


import { ImContrast } from "react-icons/im";
import {tab4} from './MediaGallery/MediaManagement'
import './MediaMan.css';
import reactSelect from "react-select";


const Step4 = ({exhibit, languageChosenSite, siteIdentity}) => {

    const [languages, setLanguages] = useState([]);
    const [token, setToken] = useState();
    const [flagForLocal, setFlagForLocal] = useState(false);
    const [objectValues, setObjectValues] = useState([]);
    const [medias, setMedias] = useState([]);
    
    console.log(exhibit);
    const changeFlag = (initials) => {
        if (initials === "EL") {

            const grIcon = `<img class="languageIcons" src="${greece}" />`;
            return grIcon;
        }

        else if (initials === "EN") {

            const enIcon = `<img class="languageIcons" src="${england}" />`;
            return enIcon;
        }

        else if (initials === "DE") {

            const gerIcon = `<img class="languageIcons" src="${germany}" />`;
            return gerIcon;
        }

        // else if (initials === "FR") {
        //     const frIcon = '<img class="languageIcons" src="../images/xenagos/france-circular.png" />';
        //     return frIcon;
        // }

        // else if (initials === "AF") {
        //   const africancon = '<img class="languageIcons" src="../images/xenagos/south-africa-circular.png"/>';
        //     return africancon;
        // }

        // else if (initials === "AR") {
        //   const arabicIcon = '<img class="languageIcons" src="../images/xenagos/saudi-arabia-circular.png"/>';
        //     return arabicIcon;
        // }

        // else if (initials === "ZH") {
        //   const chinese = '<img class="languageIcons" src="../images/xenagos/china-circular.png"/>';
        //     return chinese;
        // }

        // else if (initials === "HE") {
        //   const hebrew = '<img class="languageIcons" src="../images/xenagos/israel-circular.png"/>';
        //     return hebrew;
        // }

        // else if (initials === "HI") {
        //   const hindi = '<img class="languageIcons" src="../images/xenagos/india-circular.png"/>';
        //     return hindi;
        // }

        // else if (initials === "IT") {
        //   const italy = '<img class="languageIcons" src="../images/xenagos/italy-circular.png"/>';
        //     return italy;
        // }

        // else if (initials === "JA") {
        //   const japan = '<img class="languageIcons" src="../images/xenagos/japan-circular.png"/>';
        //     return japan;
        // }

        // else if (initials === "KO") {
        //   const korean = '<img class="languageIcons" src="../images/xenagos/south-korea-circular.png"/>';
        //     return korean;
        // }

        // else if (initials === "PT") {
        //   const portugese = '<img class="languageIcons" src="../images/xenagos/portugal-circular.png"/>';
        //     return portugese;
        // }

        // else if (initials === "RU") {
        //   const russia = '<img class="languageIcons" src="../images/xenagos/russian-federation-circular.png"/>';
        //     return russia;
        // }

        else if (initials === "ES") {
          const spainIcon = `<img class="languageIcons" src="${spain}"/>`;
            return spainIcon;
        }

        // else if (initials === "TR") {
        //   const turkey = '<img class="languageIcons" src="../images/xenagos/turkey-circular.png"/>';
        //     return turkey;
        // }
    }
    
    
    async function showMedia(exhibit, token) { 
        console.log();
        let media = await MediaApi.GetById(parseInt(exhibit));
        
        //console.log(media);
        document.getElementById("imagesGallery").innerHTML = "";
        for (let i = 0; i < media.length; i++) {
            //media.size (mb)
      
            
            if (media[i].type === '10') {
            
              /*  if (user.role === "Admin" || user.role === "Editor") {*/
                    const photo = await MediaApi.GetByMediaId(parseInt(media[i].mediaId));
              

                   
                    //document.getElementById("imagesGallery").innerHTML += `<tr><td style="vertical-align: middle;color: white;text-align: center; line-height: 150%;font-size: 26px;"><label  >${media[i].description}</label></td><td style="width:20%"><img width="200px;" height="150px;" id=${media[i].mediaId}  class='thumbnail' src=""/></td><td style="float: right;margin-top:10px;"><span style="font-size: x-small;color:white">*Size: ${media[i].size.toFixed(2)} MB</span><button style="height: 1px;" type="button" data-id=${media[i].mediaId} class='thumbnail xenagosButton deleteBtn'>Delete</button></td></tr>`;


                //code for new tab4 filtering <div class="title">${media[i].description}</div> <button class="btn btn-success addMedia" >Add Media</button>
               // <button class="btn btn-info editMedia" style="margin-top:2vh;">Edit</button>
            /*<div class="mediaButtons" style="display: flex;flex-direction: column;width: fit-content;margin-top: 2vh;"></div>*/

                    document.getElementById("imagesGallery").innerHTML += `<tr>
                        <td style="vertical-align: middle;color: white;text-align: center; line-height: 150%;font-size: 26px;">
                            <div style="display: inline-flex;" class="title"> <span class="languageImages">${changeFlag(media[i].initials)}</span><input id="input${media[i].mediaId}" class="inputDesc" style="width:100%;font-size:smaller;border:none;text-align:center;padding-left:2%;padding-right:2%;margin-right:3%" value="${media[i].description ? media[i].description : media[i].name}"/><button style="display:none;border-radius:20px;" class="buttonDesc btn btn-sm btn-success" id="save${media[i].description}">Save</button></div>
                            
                            <input type="radio" name="select" id=${media[i].mediaId}>
                                
                                <div class="item-hugger">

                                    <img class="thumb-image" src="data:image/png;base64, ${photo.value}" />
                                    <label for=${media[i].mediaId}></label>
                                    
                                </div>
                                <div class="content" style="top:38%!important;right: 24%!important;">
                               
                                
                                
                                        
                                    <div class="item-wrapper" style="display: flex;flex-direction: column;align-items:center">
                                        
                                        <img class="previewPhoto" src="data:image/png;base64, ${photo.value}" >
                                             <button data-id=${media[i].mediaId} style="margin-left: 6vw;margin-top:1%;" class="btn xenagosButton btn-danger deleteBtn">Delete</button>
                                        
                                    </div>
                           
                                </div>
                  </td>  
                </tr>`;




           





                   // document.getElementById("imagesGallery").innerHTML += `<tr><td style="vertical-align: middle;color: white;text-align: center; line-height: 150%;font-size: 26px;"><label  >${media[i].description}</label></td><td style="width:20%"><img width="200px;" height="150px;" id=${media[i].mediaId}  class='thumbnail' src=""/></td><td style="float: right;margin-top:10px;"><span style="font-size: x-small;color:white">*Size: ${media[i].size.toFixed(2)} MB</span><button style="height: 1px;" type="button" data-id=${media[i].mediaId} class='thumbnail xenagosButton deleteBtn'>Delete</button></td></tr>`;



                    //var imageMedia = document.getElementById(media[i].mediaId);
                
               
                    /*imageMedia.src = "data:image/png;base64, " + photo[0].value;*/
                //} else {

                //    var photo = await MediaApi.GetByMediaId(parseInt(media[i].mediaId));

                //    console.log(photo);
                //    document.getElementById("imagesGallery").innerHTML += `<tr><td style="vertical-align: middle;color: white;text-align: center; line-height: 150%;font-size: 26px;"><label  >${media[i].description}</label></td><td style="width:20%"><img width="200px;" height="150px;" id=${media[i].mediaId}  class='thumbnail' src=""/></td><td style="float: right;margin-top:10px;"><span style="font-size: x-small;color:white">*Size: ${media[i].size.toFixed(2)} MB</span></td></tr>`;



                //    var imageMedia = document.getElementById(media[i].mediaId);
                //    imageMedia.src = "data:image/png;base64, " + photo.value;
                //}

            }
            else if (media[i].type === '20') {/////line for video
                //var claims = await authService.getUserAsync();
                //if (claims.claims.role === "Viewer") {
                //    var video = await MediaApi.GetByMediaId(parseInt(media[i].mediaId));

                //    //console.log(video);

                //    document.getElementById("imagesGallery").innerHTML += `<tr><td style="vertical-align: middle;color: white;text-align: center; line-height: 150%;font-size: 26px;"><label  >${media[i].description}</label></td><td style="width:20%"><video id=${media[i].mediaId}  class='thumbnail' controls><source src="" type="video/mp4"></video></td><td style="float: right;margin-top:10px;"><span style="font-size: x-small;color:white">*Size: ${media[i].size.toFixed(2)} MB</span></td></tr>`;



                //    var videoMedia = document.getElementById(media[i].mediaId);
                //    videoMedia.src = "data:video/mp4;base64, " + video.value;
                /*} else {*/
                    //var video = await MediaApi.GetByMediaId(parseInt(media[i].mediaId));
                    const mediaid = media[i].mediaId;
                    const uri = `https://api.dei.prismasense.com/Xenagos/api/v1/media/uri/video/${mediaid}/${token}`

                    //console.log(video[0]);
                //<div class="title"><p class="titleMed">${media[i].description ? media[i].description : media[i].name}</p></div>

                    //document.getElementById("imagesGallery").innerHTML += `<tr><td style="vertical-align: middle;color: white;text-align: center; line-height: 150%;font-size: 26px;"><label  >${media[i].description}</label></td><td style="width:20%"><video id=${media[i].mediaId}  class='thumbnail' controls><source src="" type="video/mp4"></video></td><td style="float: right;margin-top:10px;"><span style="font-size: x-small;color:white">*Size: ${media[i].size.toFixed(2)} MB</span><button style="height: 1px;" type="button" data-id=${media[i].mediaId} class='thumbnail xenagosButton deleteBtn'>Delete</button></td></tr>`;


                    document.getElementById("imagesGallery").innerHTML += `<tr>
                        <td style="vertical-align: middle;color: white;text-align: center; line-height: 150%;font-size: 26px;">
                            <div style="display: inline-flex;" class="title"> <span class="languageImages">${changeFlag(media[i].initials)}</span><input id="input${media[i].mediaId}" class="inputDesc " style="width:100%;font-size:smaller;border:none;text-align:center;padding-left:2%;padding-right:2%;margin-right:3%" value="${media[i].description ? media[i].description : media[i].name}"/><button style="display:none;border-radius:20px;" class="buttonDesc btn btn-sm btn-success" id="save${media[i].description}">Save</button></div>
                                     
                            <input type="radio" name="select" id=${media[i].mediaId}>
                               
                                <div class="item-hugger">
                                    
                                    <video class="thumb-image" ><source src=${uri}></video>
                                        <label for=${media[i].mediaId}></label>
                    </div>
                                    <div class="content" style="top:31%!important;right:29%!important;">
                                        <div class="item-wrapper" style="margin-left: 1%;display: flex;flex-direction: column;align-items:center" >
                                            
                                            <video class="previewVideo"     style="border-radius: 20px;" controls height=""><source src=${uri}></video>
                                           
                               
                              
                                    <button data-id=${media[i].mediaId} style="margin-left: 8vw;margin-top:1%;" class="btn vi xenagosButton deleteBtn">Delete</button>
                            
                      </div>
                                        </div>
                  </td>  
                </tr>`;











                    //var videoMedia = document.getElementById(media[i].mediaId);
                    //videoMedia.src = "data:video/mp4;base64, " + video[0].value;
                

            }

            else if (media[i].type === '40') {/////line for video
                //var claims = await authService.getUserAsync();
                //if (claims.claims.role === "Viewer") {
                //    var video = await MediaApi.GetByMediaId(parseInt(media[i].mediaId));

                //    //console.log(video);

                //    document.getElementById("imagesGallery").innerHTML += `<tr><td style="vertical-align: middle;color: white;text-align: center; line-height: 150%;font-size: 26px;"><label  >${media[i].description}</label></td><td style="width:20%"><video id=${media[i].mediaId}  class='thumbnail' controls><source src="" type="video/mp4"></video></td><td style="float: right;margin-top:10px;"><span style="font-size: x-small;color:white">*Size: ${media[i].size.toFixed(2)} MB</span></td></tr>`;



                //    var videoMedia = document.getElementById(media[i].mediaId);
                //    videoMedia.src = "data:video/mp4;base64, " + video.value;
                /*} else {*/
                //var video = await MediaApi.GetByMediaId(parseInt(media[i].mediaId));
                const mediaid = media[i].mediaId;
                //console.log(video[0]);
                const uri = `https://api.dei.prismasense.com/Xenagos/api/v1/media/uri/video/${mediaid}/${token}`

                //document.getElementById("imagesGallery").innerHTML += `<tr><td style="vertical-align: middle;color: white;text-align: center; line-height: 150%;font-size: 26px;"><label  >${media[i].description}</label></td><td style="width:20%"><video id=${media[i].mediaId}  class='thumbnail' controls><source src="" type="video/mp4"></video></td><td style="float: right;margin-top:10px;"><span style="font-size: x-small;color:white">*Size: ${media[i].size.toFixed(2)} MB</span><button style="height: 1px;" type="button" data-id=${media[i].mediaId} class='thumbnail xenagosButton deleteBtn'>Delete</button></td></tr>`;
                //next to span element <div class="title"><p class="titleMed">${media[i].description ? media[i].description : media[i].name} ........      </p></div>

                document.getElementById("imagesGallery").innerHTML += `<tr>
                        <td style="vertical-align: middle;color: white;text-align: center; line-height: 150%;font-size: 26px;">
<div style="display: inline-flex;" class="title"> <span class="languageImages">${changeFlag(media[i].initials)}</span><input id="input${media[i].mediaId}" class="inputDesc " style="width:100%;font-size:smaller;border:none;text-align:center;padding-left:2%;padding-right:2%;margin-right:3%" value="${media[i].description ? media[i].description : media[i].name}"/><button style="display:none;border-radius:20px;" class="buttonDesc btn btn-sm btn-success" id="save${media[i].description}">Save</button></div>
                                 
                            <input type="radio" name="select" id=${media[i].mediaId}>

                                <div class="item-hugger">
                                    <span style="color:#74747494;font-size:medium;">360°</span>
                                    <video class="thumb-image" style="padding:8px 0;!important;" ><source src=${uri}></video>
                                        <label for=${media[i].mediaId}></label>
                    </div>
                                    <div class="content" style="top:31%!important;left:29%!important;">
                                        <div class="item-wrapper" style="margin-left: 1%;display: flex;flex-direction: column;align-items:center">
                                            
                                            <video  style="border-radius: 20px;" class="previewVideo" controls height=""><source src=${uri}></video>
                                           
                               
                              
                                    <button data-id=${media[i].mediaId} style="margin-left: 8vw;margin-top:1%;" class="btn vi xenagosButton deleteBtn">Delete</button>
                            
                      </div>
                                        </div>
                  </td>  
                </tr>`;











                //var videoMedia = document.getElementById(media[i].mediaId);
                //videoMedia.src = "data:video/mp4;base64, " + video[0].value;


            }
            //////audio //////////
            else if (media[i].type === '30' ) {/////line for audio
                //var claims = await authService.getUserAsync();
                //if (claims.claims.role === "Viewer") {
                //    var audio = await MediaApi.GetByMediaId(parseInt(media[i].mediaId));

                //    //console.log(audio[0]);

                //    document.getElementById("imagesGallery").innerHTML += `<tr><td style="vertical-align: middle;color: white;text-align: center; line-height: 150%;font-size: 26px;"><label  >${media[i].description}</label></td><td style="width:20%"><audio style="width:240px;" width="200px;" height="150px;" id=${media[i].mediaId}  class='thumbnail' controls><source src="" type="audio/mp3"></audio></td><td style="float: right;margin-top:10px;"><span style="font-size: x-small;color:white">*Size: ${media[i].size.toFixed(2)} MB</span></td></tr>`;

                


                //    var audioMedia = document.getElementById(media[i].mediaId);          
                //    audioMedia.src = "data:video/mp4;base64, " + audio.value;
                //} else {
                    const audio = await MediaApi.GetByMediaId(parseInt(media[i].mediaId));

                    //console.log(audio[0]);  

                    //document.getElementById("imagesGallery").innerHTML += `<tr><td style="vertical-align: middle;color: white;text-align: center; line-height: 150%;font-size: 26px;"><label  >${media[i].description}</label></td><td style="width:20%"><audio style="width:240px;" width="200px;" height="150px;" id=${media[i].mediaId}  class='thumbnail' controls><source src="" type="audio/mp3"></audio></td><td style="float: right;margin-top:10px;"><span style="font-size: x-small;color:white">*Size: ${media[i].size.toFixed(2)} MB</span><button style="height: 1px;" type="button" data-id=${media[i].mediaId} class='thumbnail xenagosButton deleteBtn'>Delete</button></td></tr>`;

                                                                                //testing the new styles for mp3


                    //<div class="title"><p class="titleMed">${media[i].description ? media[i].description : media[i].name}</p></div> above the i tag fas fa-file-audio

                    //document.getElementById("imagesGallery").innerHTML += `<tr><td style="vertical-align: middle;color: white;text-align: center; line-height: 150%;font-size: 26px;"><label  >${media[i].description}</label></td><td style="width:20%"><audio style="width:240px;" width="200px;" height="150px;" id=${media[i].mediaId}  class='thumbnail' controls><source src="" type="audio/mp3"></audio></td><td style="float: right;margin-top:10px;"><span style="font-size: x-small;color:white">*Size: ${media[i].size.toFixed(2)} MB</span><button style="height: 1px;" type="button" data-id=${media[i].mediaId} class='thumbnail xenagosButton deleteBtn'>Delete</button></td></tr>`;



                    document.getElementById("imagesGallery").innerHTML += `<tr>
                        <td style="vertical-align: middle;color: white;text-align: center; line-height: 150%;font-size: 26px;">
<div style="display: inline-flex;" class="title"> <span class="languageImages">${changeFlag(media[i].initials)}</span><input id="input${media[i].mediaId}" class="inputDesc " style="width:100%;font-size:smaller;border:none;text-align:center;padding-left:2%;padding-right:2%;margin-right:3%" value="${media[i].description ? media[i].description : media[i].name}"/><button style="display:none;border-radius:20px;" class="buttonDesc btn btn-sm btn-success" id="save${media[i].description}">Save</button></div>
                      
                            <input type="radio" name="select" id=${media[i].mediaId}>
                           
                                <div class="item-hugger" style="margin-top:18%;">
                                    
                                   
                                    
                                <i class="fas fa-file-audio thumb-image" style="font-size: 5vh;color: #1c4325;"></i>
                                    <label for=${media[i].mediaId}></label>
                                </div>
                                <div class="content" style="right:22vw!important;;top:40%;">
                                    <div class="item-wrapper" style="margin-left: 1%;display: flex;flex-direction: column;align-items:center" >
                                       
                                        <audio class="previewAudio" controls><source src="data:video/mp4;base64, ${audio.value}" ></audio>
                                       
                                
                            
                                    <button data-id=${media[i].mediaId} style="margin-left:1vw;" class="btn au xenagosButton deleteBtn">Delete</button>
                           
                      </div>
                                    </div>
                  </td>  
                </tr>`;


















                    let audioMedia = document.getElementById(media[i].mediaId);
                    audioMedia.src = "data:video/mp4;base64, " + audio.value;
                //}

            }
        }

      

        const inputDesc = document.getElementsByClassName("inputDesc");

        for (let i of inputDesc) {
            i.addEventListener("click", function (e) {
                //console.log(e.target);
                e.target.previousElementSibling.style.display = "none"
                e.target.nextElementSibling.style.display = "block";
            });
        }

        const buttonDesc = document.getElementsByClassName("buttonDesc");

        for (let b of buttonDesc) {
            b.addEventListener("click", async function (e) {
            /*$("#preloader").fadeIn();*/
                $(this).html('<span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>').attr('disabled', true);
                const targetId = e.target.parentElement.nextElementSibling.getAttribute("id");
                const inputTarget = e.target.previousElementSibling;
                const mediaTarget = await MediaApi.GetByMediaId(targetId);
                console.log(mediaTarget);
                const mediaUpd = {
                    "name": mediaTarget.name,
                    "description": inputTarget.value,
                    "refpath": mediaTarget.name,
                    "typeId": mediaTarget.media_type,
                    "languageId": await InitToLanguageId(mediaTarget.initials),
                    "mediaId": mediaTarget.mediaId
                }
                //console.log(media);
                const upda = await MediaApi.Update(mediaUpd);
                console.log(upda); 
                $(this).html('save').attr('disabled', false)
                this.style.display = "none";
                this.previousElementSibling.previousElementSibling.style.display = "block"
               /* $("#preloader").fadeOut();*/
            });
        }

        //const allTitles = document.getElementsByClassName("title");
        //for (const title of allTitles) {
        //    title.addEventListener("click", (evt) => {

        //        let targetEl = evt.target; // clicked element      
        //        do {
        //            if (targetEl == title) {
        //                // This is a click inside, does nothing, just return.
        //                console.log("clicked inside");
        //                return;
        //            }
        //            // Go up the DOM
        //            targetEl != targetEl;
        //        } while (targetEl);
        //        // This is a click outside.   
        //        console.log("clicked outside");
        //        title.children[1].style.display = "none";
        //    });
        //}


        //function to remove all children of an element after pressing delete
        const removeChilds = (parent) => {
          while (parent.lastChild) {
              parent.removeChild(parent.lastChild);
          }
        };
        


        // Get the <span> element that closes the modal
        

      
        
        // When the user clicks on <span> (x), close the modal

        document.getElementsByClassName("closeModal")[0].onclick = function () {
         
          document.getElementById("myModalMedia").style.display = "none";
            flagForGallery = false;
            document.querySelector(".gallery").innerHTML = "";
            
        }

      
      // select target target 
     
      
      // remove all child nodes
     

        let deleteBtn = document.getElementsByClassName("deleteBtn");
        
        for (let d of deleteBtn) {
            d.addEventListener("click", async function (e) {
                //var claims = await authService.getUserAsync();
                //if (claims.claims.role === "Viewer") {
                    //alert(language.global.userRole);
                    // Swal({
                    //     title: language.media.invalidAction,
                    //     text: language.global.userRole,
                    //     icon: "warning",
                    //     button: "OK",

                    // })
                //} else {
                  e.stopPropagation();
                    e.preventDefault();
                    const elementToDelete = e.target.parentElement.parentElement.parentElement
                    console.log(elementToDelete);
                   

                  Swal.fire({
                    title: "Are you sure?",
                    html: `You are about to delete the current media`,
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Delete",
                  }).then(async (result) => {
                    if (result.isConfirmed) {
                      const objectVals = await ObjectApi.GetAllValuesOfObject(parseInt(exhibit));
                      const objectInitial = objectVals.find(o => o.status === false);
                      const id = e.target.getAttribute('data-id');
                    
                    //let res = confirm(`Are you sure you want to delete this file?`);
                    //if (res) {

                        await MediaApi.RemoveMediaFromObject(objectInitial.objectValId, parseInt(id));
                        removeChilds(elementToDelete);

                        Swal.fire("Deleted!", "Your file has been deleted.", "success");
                        //await showMedia();
                      //removeFadeOut(targetElement, 600);
                        
                     
                    }
                  });
                    
                   
                    //    //await tab4(exhibit);
                    //}

                    // Swal({
                    //     title: language.media.confirmMessage,
                    //     text: language.media.deleteConfirm,
                    //     icon: "warning",
                    //     buttons: true,
                    //     dangerMode: true,
                    // })
                    //     .then(async (willDelete) => {
                    //         if (willDelete) {
                    //             await MediaApi.RemoveMediaFromObject(objectInitial.objectValId, parseInt(id));
                    //             await showMedia();
                    //         }
                    //     });

                    /* await MediaApi.MediaDelete(parseInt(id));*/
                    //clearSelected();

                    //document.getElementById('tab4').click();
                //}
            });
        }

        
        //var numberOfMedia = document.querySelector(".numberOfMedia");
        //var checkboxes = document.querySelectorAll('input[type=checkbox]');
        //console.log(checkboxes);

        $(".gallery").off("click");
        $(".gallery").on("click", async function (e) {
            e.stopPropagation();
            console.log(e.target);
            
            let targetArray = [];
            //var headerWrapper = document.getElementById("headerWrapper");
            //if (headerWrapper) {
            //    $(headerWrapper).fadeOut(10);
            //    $(transferMedia).fadeIn(1000);
            //}

            const target = e.target.parentElement.parentElement.querySelector("input");
            
            //var dataID = target.getAttribute('data-id');
            //target.checked = true;

            

            if (target.checked) {
                target.checked = false;
               
               
            } else {
                target.checked = true;
                
            }
                

        });
        //τρανσφερμεδια
       

        //τρανσφερμεδια




        //checkboxes.forEach(c => c.addEventListener("click", function (e) {
        //    //onChange();
            
        //    e.stopPropagation();
        //    console.log(e);

        //}));







    }
     
    let buttonDesc2 = document.getElementsByClassName("buttonDesc");
    if(document.querySelector(".white-box")) {
      document.querySelector(".white-box").addEventListener("click", function () {
        for (let b of buttonDesc2) {
            b.style.display = "none";
        }
      });
    }
   

    const onAddLocally = () => {
        setFlagForLocal(false);
        document.getElementById("myModalMediaLocal").style.display = "block";
        AddMediaLocally();
        setFlagForLocal(true);
    }

    const OnAddMediaGallery = async () => {
        let tokenObject = await authService.getTokenAsync();
        let token = tokenObject.accessToken;
      document.getElementById("myModalMedia").style.display = "block";
      galleryItemsMedia(token);
      flagForGallery = true;
    }


    // const transferMedia = document.getElementById("transferMedia");


    $("#transferMedia").off("click");
    $("#transferMedia").on("click", async function (e) {
        e.stopPropagation();
        e.preventDefault();
        const objectVals = await ObjectApi.GetAllValuesOfObject(parseInt(exhibit.objectId));

        const objectInitial = objectVals.find(o => o.status === false);
        let filtered = [];
        let arrayToDelete = [];
        let checkboxesChecked = document.querySelectorAll('input[type=checkbox]:checked');
        console.log(checkboxesChecked);

        //check if the checkbox belongs to image, video or audio//
        for (let i = 0; i < checkboxesChecked.length; i++) {
            if (checkboxesChecked[i].parentElement.querySelector("img")) {
                let idsToDel = checkboxesChecked[i].parentElement.querySelector("img").getAttribute('data-id');
                console.log(idsToDel)
                
                arrayToDelete.push(idsToDel);
                filtered = arrayToDelete.filter((el) => {
                    return el !== null;
                });
                console.log(filtered)
            }
            if (checkboxesChecked[i].parentElement.querySelector("video")) {
                let idsToDel = checkboxesChecked[i].parentElement.querySelector("video").getAttribute('data-id');
                console.log(idsToDel)
                arrayToDelete.push(idsToDel);
                filtered = arrayToDelete.filter((el) => {
                    return el !== null;
                });
                console.log(filtered)
            }
            if (checkboxesChecked[i].parentElement.querySelector("audio")) {
                let idsToDel = checkboxesChecked[i].parentElement.querySelector("audio").getAttribute('data-id');
                console.log(idsToDel)
                arrayToDelete.push(idsToDel);
                filtered = arrayToDelete.filter((el) => {
                    return el !== null;
                });
                console.log(filtered)
            }

        }


        

        if (filtered.length == 0) {
            //alert("No media chosen. Please tick the media you want to add");
            Swal.fire({
                title: language.media.noItemsTitle,
                text: language.media.noItemsChosen,
                icon: "warning",
                button: "OK",

            })

        }
        else if (filtered.length > 10) {
            Swal.fire({
                title: language.media.limitErrorTitle,
                text: language.media.limitErrorText,
                icon: "warning",
                button: "OK",

            })
        } //else if (filtered.includes('23')) {
        //    alert("23 is found");
        //}
        else {

            //console.log(selectedNames);
            //var result = confirm(`Are you sure you want to add the ${arrayToDelete.length} selected media?`);
            //if (result) {

            //    arrayToDelete.forEach(async function (selectedId) {

            //        await MediaApi.AddMediaToObject(objectInitial.objectValId, selectedId);

            //    });

            //    span.click();
            //    //await showMedia();

            //    //await tab4(exhibit);
            //    await showMedia();
            //}
            filtered = arrayToDelete.filter((el) => {
                return el !== null;
            });

            filtered = filtered.filter((el) => {
                return el !== '';
            });
            
            Swal.fire({
                title: language.media.confirmMessage,
                text: `Are you sure you want to add the ${filtered.length} selected media?`,
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then(async (willDelete) => {
                    let tokenObject = await authService.getTokenAsync();
                        let token = tokenObject.accessToken;
                    if (willDelete) {
                        
                        const upload = filtered.forEach(async function (selectedId) {

                            await MediaApi.AddMediaToObject(objectInitial.objectValId, selectedId);
                            
                        });
                        //return upload;
                       
                        document.getElementsByClassName("closeModal")[0].click();
                        //$("#spinnerContentTable").fadeIn();
                        //$("#preloader").fadeIn();
                            const show = async () => {   
                                
                                await showMedia(exhibit.objectId, token);

                                //$("#spinnerContentTable").fadeOut();
                                //$("#preloader").fadeOut();
                            }
                           

                        
                            setTimeout(show, 2000);
                        
                        //show(upload);
                       
                        //span.click();
                        //await showMedia();

                        //await tab4(exhibit);

                    }
                });


            //location.reload();
        }

        //await showMedia();

    });

    const onEnd = async () => {
      // const dots = document.querySelectorAll(".Wizard_dot__btV-v ");
      // const dotsArr = [...dots];
      // // console.log(dotsArr);
      // // console.log(serialNumber);
      // // console.log(optionsForSel);
      // // console.log(optionsForSelSub);
      // // console.log(exhibitionChosen);
      // dotsArr[3].click();
  
      
        // const dots = document.querySelectorAll(".Wizard_dot__btV-v ");
        // const dotsArr = [...dots];
        // console.log(dotsArr);
        // console.log(serialNumber);
        // console.log(optionsForSel);
        // console.log(optionsForSelSub);
        // console.log(exhibitionChosen);
        
        window.location.replace("./Management");
  
      
  
        Swal.fire({
          title: languageChosenSite === "EL" ? languageGr.media.successTitle : language.media.successTitle,
          text:  languageChosenSite === "EL" ? languageGr.media.successDesc : language.media.successDesc,
          icon: "success",
          button: 'OK',
  
      })
  }
  
  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        500,
        500,
        "JPEG",
        50,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
  });


    async function galleryItemsMedia(token) { 
       
      if (flagForGallery == false) { 
          let galleryItems = document.querySelector(".gallery").children;
              ///////////// //Used to be backup //////////

          

          
          



          //$(span).css("pointer-events", "none");

          

          const searchBar = document.getElementById("searchBar");


          //$("#spinnerContent").fadeIn();
          //$("#preloader").fadeIn();
          let allMedia = await MediaApi.Get();
          allMedia.shift();

          console.log(allMedia);
          

         

          //var imgEl = document.getElementsByClassName("image");
          
          //console.log(imgEl);
          //async function lazyLoad() { 
          //    for (var i = 0; i < imgEl.size; i++) {
          //        if (imgEl[i].getAttribute('src')) {
          //            imgEl[i].setAttribute('data-src', imgEl[i].getAttribute('src'));
          //            imgEl[i].removeAttribute('src');
          //        }
          //    }

          //}async-src="data:image/png;base64, ${photo.value}"

          //let valueOfSel = document.querySelector("#filterLang").value;
          //console.log(`"${valueOfSel}"`);

          for (let media of allMedia) {

              //if (media.initials === "EN") { 
              if (media.type == "10") {

                  //var photo = await MediaApi.GetByMediaId(parseInt(media.mediaId));

                  document.querySelector(".gallery").innerHTML += `
          
                 <div  class="image hide" data-name="image"><span style="border-radius: 15px"><img data-id="" id="${media.mediaId + "ids"}"  src="${xenagos}" alt="" init="${media.initials}" class="targ ${media.mediaId + "ids"} loading"  ></span> <input class="form-check-input checkboo" type="checkbox"><span class="descript"></span></div>
          
              `

              }

              if (media.type == "20") {
                  //var video = await MediaApi.GetByMediaId(parseInt(media.mediaId));

                  document.querySelector(".gallery").innerHTML += `
                  <div class="video hide" style="width:40%;" data-name="video"><span style="border-radius: 15px"><video class="targ ${media.mediaId + "ids"}" alt="" init="${media.initials}" data-id="" width="100%" height="30%" controls><source src=""  type="video/mp4"></video></span><input class="checkboo" type="checkbox"><span class="descriptVid"></span></div>    
              `
              }

              if (media.type == "40") {
                  //var video = await MediaApi.GetByMediaId(parseInt(media.mediaId));

                  document.querySelector(".gallery").innerHTML += `
                  <div class="video hide" style="width:40%;" data-name="video"><span style="border-radius: 15px"><video class="targ ${media.mediaId + "ids"}" alt="" init="${media.initials}" data-id="" width="100%" height="30%" controls><source src=""  type="video/mp4"></video></span><input class="checkboo" type="checkbox"><span class="descriptVid"></span></div>    
              `
              }

              if (media.type == "30") {
                  var audio = await MediaApi.GetByMediaId(parseInt(media.mediaId));

                  document.querySelector(".gallery").innerHTML += `
                  <div class="audio hide" data-name="audio"><span style="border-radius: 15px"><audio class="targ ${media.mediaId + "ids"} loading" alt="" init="${media.initials}" data-id="" width="100%" height="30%" controls><source src="../images/imagesTest/default.jpg"  type="audio/mp3"></audio></span><input class="checkboo" type="checkbox"><span class="descriptAud"></span></div>        
              `
              }
              //}


          }

          let checkboxes = document.querySelectorAll('input[type=checkbox]');
         

          checkboxes.forEach(c => c.addEventListener("click", function (e) {
              //onChange();

              e.stopPropagation();
              console.log(e);

          }));

          const loader = async (init) => {
              for (let media of allMedia) {
                  if (media.initials === init) {
                      if (media.type == "10") {
                        const photo = await MediaApi.GetByMediaId(parseInt(media.mediaId));
                         
                        let photos = document.getElementsByClassName(`${media.mediaId + "ids"}`);

                          //if (photos[0].src !== 0) { 
                          photos[0].src = `data:image/png;base64,${photo.value}`;
                          photos[0].classList.remove('loading');
                          photos[0].setAttribute("alt", `${media.description ? media.description : media.name}`);
                          photos[0].setAttribute("data-id", `${media.mediaId}`);

                          photos[0].parentElement.nextElementSibling.nextElementSibling.innerHTML = `${changeFlag(media.initials)}${media.description ? media.description : media.name}`;
                          //    $('photos').attr("src", $('photo').attr("value"));
                          //}
                          //photos.find(p => p.getAttribute("data-id") == media.mediaId);
                          //console.log(photos);
                          //photos.src = "data:image/png;base64, ${photo.value}";
                      }

                      if (media.type == "20") {
                          //var video = await MediaApi.GetByMediaId(parseInt(media.mediaId));
                          const mediaid = media.mediaId;
                          const uri = `https://api.dei.prismasense.com/Xenagos/api/v1/media/uri/video/${mediaid}/${token}`

                          let photos = document.getElementsByClassName(`${media.mediaId + "ids"}`);

                          //if (photos[0].src !== 0) { 
                          photos[0].src = uri;
                          photos[0].setAttribute("alt", `${media.description ? media.description : media.name}`);
                          photos[0].setAttribute("data-id", `${media.mediaId}`);

                          photos[0].parentElement.nextElementSibling.nextElementSibling.innerHTML = `${changeFlag(media.initials)}${media.description ? media.description : media.name}`;

                          //    $('photos').attr("src", $('photo').attr("value"));
                          //}
                          //photos.find(p => p.getAttribute("data-id") == media.mediaId);
                          //console.log(photos);
                          //photos.src = "data:image/png;base64, ${photo.value}";
                      }

                      if (media.type == "40") {
                          //var video = await MediaApi.GetByMediaId(parseInt(media.mediaId));
                          const mediaid = media.mediaId;
                          const uri = `https://api.dei.prismasense.com/Xenagos/api/v1/media/uri/video/${mediaid}/${token}`

                          let photos = document.getElementsByClassName(`${media.mediaId + "ids"}`);

                          //if (photos[0].src !== 0) { 
                          photos[0].src = uri;
                          photos[0].setAttribute("alt", `${media.description ? media.description : media.name}`);
                          photos[0].setAttribute("data-id", `${media.mediaId}`);

                          photos[0].parentElement.nextElementSibling.nextElementSibling.innerHTML = `${changeFlag(media.initials)}${media.description ? media.description : media.name}  <pre>360</pre>`;

                          //    $('photos').attr("src", $('photo').attr("value"));
                          //}
                          //photos.find(p => p.getAttribute("data-id") == media.mediaId);
                          //console.log(photos);
                          //photos.src = "data:image/png;base64, ${photo.value}";
                      }

                      if (media.type == "30") {
                          var audio = await MediaApi.GetByMediaId(parseInt(media.mediaId));
                          //const mediaid = media.mediaId;
                          //const uri = `https://api.dei.prismasense.com/Xenagos/api/v1/media/uri/video/${mediaid}/${token}`


                          let photos = document.getElementsByClassName(`${media.mediaId + "ids"}`);

                          //if (photos[0].src !== 0) { 
                          photos[0].src = `data:audio/mp3;base64,${audio.value}`;
                          //photos[0].src = uri;
                          photos[0].classList.remove('loading');

                     
                          photos[0].setAttribute("alt", `${media.description ? media.description : media.name}`);
                          photos[0].setAttribute("data-id", `${media.mediaId}`);

                          photos[0].parentElement.nextElementSibling.nextElementSibling.innerHTML = `${changeFlag(media.initials)}${media.description ? media.description : media.name}`;

                          //    $('photos').attr("src", $('photo').attr("value"));
                          //}
                          //photos.find(p => p.getAttribute("data-id") == media.mediaId);
                          //console.log(photos);
                          //photos.src = "data:image/png;base64, ${photo.value}";
                      }
                  }
              }

          }


          //loader();
          //$("#spinnerContent").fadeOut();
          //$("#preloader").fadeOut();
          //$(".closeModal[0]").css("pointer-events", "auto");

          let testButton = document.querySelector("#testButton");
          testButton.addEventListener('click', () => {
              document.querySelector("#filterLang").value = "EN";
              triggerChange(document.querySelector("#filterLang"));
          });


          function triggerChange(element) {
              let changeEvent = new Event('change');
              element.dispatchEvent(changeEvent);
          }


          //filtering languages ${lang.name}
          //async function updateLangFilter(languages) {

          //    document.querySelector("#filterLang").innerHTML = `<option value="All" id="0">All</option>`;
          //    for (let lang of languages) {


          //        document.querySelector("#filterLang").innerHTML += `<option value="${lang.initials}" id="${lang.languageId}">${lang.name}</option>`;
          //    }

          //}
          console.log(document.querySelector("#filterLang"));
          document.querySelector("#filterLang").addEventListener("change", async function () {
              //var checkboxes = document.querySelectorAll('input[type=checkbox]');
              //for (let ch of checkboxes) {
              //    ch.checked = false;
              //}

              let filterItem = document.querySelector(".items");
              let allItems = filterItem.querySelector("span");
              filterItem.querySelector(".active").classList.remove("active");
              allItems.classList.add("active");
              searchBar.value = "";
              let filterImg = document.querySelectorAll(".gallery .image");
              let filterAud = document.querySelectorAll(".gallery .audio");
              let filterVid = document.querySelectorAll(".gallery .video");
              let valueOfSel = document.querySelector("#filterLang").value;
              let val = this.value;

            //   console.log(filterItem);
            //   console.log(filterImg);
            //   console.log(filterVid);
      document.querySelector(".spinnerGallery").style.display = "none";

              loader(valueOfSel);

              searchBar.addEventListener("keyup", function (e) {

                  filterItem.querySelector(".active").classList.remove("active");
                  allItems.classList.add("active");
                  let target = e.target.value.toLowerCase();


                  filterImg.forEach(med => {




                      med.classList.remove("show");
                      med.classList.add("hide");

                  });

                  filterVid.forEach(med => {

                      med.classList.remove("show");
                      med.classList.add("hide");
                  });

                  filterAud.forEach(med => {

                      med.classList.remove("show");
                      med.classList.add("hide");
                  });


                  /*let allFilteredMedia = filterImg.concat(filterVid, filterAud);*/

                  let filteredMedia = [];
                  filterImg.forEach(media => {
                      let langInitials = media.children[0].querySelector("img").getAttribute("init");
                      if ((val === langInitials) || val === "All") {
                          if (media.children[0].querySelector("img").getAttribute("alt").toLowerCase().includes(target)) {
                              filteredMedia.push(media);
                          }
                      }
                  });

                  let filteredMediaV = [];
                  filterVid.forEach(media => {
                      let langInitials = media.children[0].querySelector("video").getAttribute("init");
                      if ((val === langInitials) || val === "All") {
                          if (media.children[0].querySelector("video").getAttribute("alt").toLowerCase().includes(target)) {
                              filteredMediaV.push(media);
                          }
                      }
                  });

                  let filteredMediaA = [];
                  filterAud.forEach(media => {
                      let langInitials = media.children[0].querySelector("audio").getAttribute("init");
                      if ((val === langInitials) || val === "All") {
                          if (media.children[0].querySelector("audio").getAttribute("alt").toLowerCase().includes(target)) {
                              filteredMediaA.push(media);
                          }
                      }
                  });

                  filteredMedia.forEach(med => {

                      med.classList.remove("hide");
                      med.classList.add("show");
                  });

                  filteredMediaV.forEach(med => {

                      med.classList.remove("hide");
                      med.classList.add("show");
                  });

                  filteredMediaA.forEach(med => {

                      med.classList.remove("hide");
                      med.classList.add("show");
                  });



                  $(filterItem).off("click");

                  $(filterItem).on("click", function (e) {





                      if (e.target.classList.contains("item")) { //if user selected item has .item class
                          filterItem.querySelector(".active").classList.remove("active"); //remove the active class which is in first item
                          e.target.classList.add("active"); //add that active class on user selected item
                          let filterName = e.target.getAttribute("data-name"); //getting data-name value of user selected item and store in a filtername variable

                          filteredMedia.forEach((image) => {
                              let filterImges = image.getAttribute("data-name"); //getting image data-name value

                              let langInitials = image.querySelector("span").querySelector("img").getAttribute("init");

                              //if user selected item data-name value is equal to images data-name value
                              //or user selected item data-name value is equal to "all"
                              if (((filterImges == filterName) || (filterName == "all")) && (langInitials === valueOfSel || valueOfSel === "All")) {
                                  image.classList.remove("hide"); //first remove the hide class from the image
                                  image.classList.add("show"); //add show class in image
                              } else {
                                  image.classList.add("hide"); //add hide class in image
                                  image.classList.remove("show"); //remove show class from the image
                              }
                          });

                          filteredMediaV.forEach((video) => {
                              let filterVideos = video.getAttribute("data-name"); //getting image data-name value

                              let langInitials = video.querySelector("span").querySelector("video").getAttribute("init");

                              //if user selected item data-name value is equal to images data-name value
                              //or user selected item data-name value is equal to "all"
                              if (((filterVideos == filterName) || (filterName == "all")) && (langInitials === valueOfSel || valueOfSel === "All")) {
                                  video.classList.remove("hide"); //first remove the hide class from the image
                                  video.classList.add("show"); //add show class in image
                              } else {
                                  video.classList.add("hide"); //add hide class in image
                                  video.classList.remove("show"); //remove show class from the image
                              }
                          });

                          filteredMediaA.forEach((audio) => {
                              let filterAudios = audio.getAttribute("data-name"); //getting image data-name value

                              let langInitials = audio.querySelector("span").querySelector("audio").getAttribute("init");

                              //if user selected item data-name value is equal to images data-name value
                              //or user selected item data-name value is equal to "all"
                              if (((filterAudios == filterName) || (filterName == "all")) && (langInitials === valueOfSel || valueOfSel === "All")) {
                                  audio.classList.remove("hide"); //first remove the hide class from the image
                                  audio.classList.add("show"); //add show class in image
                              } else {
                                  audio.classList.add("hide"); //add hide class in image
                                  audio.classList.remove("show"); //remove show class from the image
                              }
                          });

                      }

                  });
















              });





















              $(filterItem).off("click");

              $(filterItem).on("click", function (e) {





                  if (e.target.classList.contains("item")) { //if user selected item has .item class
                      filterItem.querySelector(".active").classList.remove("active"); //remove the active class which is in first item
                      e.target.classList.add("active"); //add that active class on user selected item
                      let filterName = e.target.getAttribute("data-name"); //getting data-name value of user selected item and store in a filtername variable

                      filterImg.forEach((image) => {
                          let filterImges = image.getAttribute("data-name"); //getting image data-name value

                          let langInitials = image.querySelector("span").querySelector("img").getAttribute("init");

                          //if user selected item data-name value is equal to images data-name value
                          //or user selected item data-name value is equal to "all"
                          if (((filterImges == filterName) || (filterName == "all")) && (langInitials === valueOfSel || valueOfSel === "All")) {
                              image.classList.remove("hide"); //first remove the hide class from the image
                              image.classList.add("show"); //add show class in image
                          } else {
                              image.classList.add("hide"); //add hide class in image
                              image.classList.remove("show"); //remove show class from the image
                          }
                      });

                      filterVid.forEach((video) => {
                          let filterVideos = video.getAttribute("data-name"); //getting image data-name value

                          let langInitials = video.querySelector("span").querySelector("video").getAttribute("init");

                          //if user selected item data-name value is equal to images data-name value
                          //or user selected item data-name value is equal to "all"
                          if (((filterVideos == filterName) || (filterName == "all")) && (langInitials === valueOfSel || valueOfSel === "All")) {
                              video.classList.remove("hide"); //first remove the hide class from the image
                              video.classList.add("show"); //add show class in image
                          } else {
                              video.classList.add("hide"); //add hide class in image
                              video.classList.remove("show"); //remove show class from the image
                          }
                      });

                      filterAud.forEach((audio) => {
                          let filterAudios = audio.getAttribute("data-name"); //getting image data-name value

                          let langInitials = audio.querySelector("span").querySelector("audio").getAttribute("init");

                          //if user selected item data-name value is equal to images data-name value
                          //or user selected item data-name value is equal to "all"
                          if (((filterAudios == filterName) || (filterName == "all")) && (langInitials === valueOfSel || valueOfSel === "All")) {
                              audio.classList.remove("hide"); //first remove the hide class from the image
                              audio.classList.add("show"); //add show class in image
                          } else {
                              audio.classList.add("hide"); //add hide class in image
                              audio.classList.remove("show"); //remove show class from the image
                          }
                      });

                  }

              });




              filterImg.forEach((div) => {


                  let langInitials = div.children[0].children[0].getAttribute("init"); // find the initials for each div that is assigned in the init attribute.


                  if ((val === langInitials) || val === "All") {





                      div.classList.remove("hide"); //first remove the hide class from the image
                      div.classList.add("show"); //add show class in image

                  } else {
                      div.classList.add("hide"); //add hide class in image
                      div.classList.remove("show"); //remove show class from the image
                  }
              });

              filterVid.forEach((div) => {


                  let langInitials = div.children[0].children[0].getAttribute("init"); // find the initials for each div that is assigned in the init attribute.









                  if ((val === langInitials) || val === "All") {

                      div.classList.remove("hide"); //first remove the hide class from the image
                      div.classList.add("show"); //add show class in image

                  } else {
                      div.classList.add("hide"); //add hide class in image
                      div.classList.remove("show"); //remove show class from the image
                  }
              });

              filterAud.forEach((div) => {


                  let langInitials = div.children[0].children[0].getAttribute("init"); // find the initials for each div that is assigned in the init attribute.


                  if ((val === langInitials) || val === "All") {

                      div.classList.remove("hide"); //first remove the hide class from the image
                      div.classList.add("show"); //add show class in image

                  } else {
                      div.classList.add("hide"); //add hide class in image
                      div.classList.remove("show"); //remove show class from the image
                  }
              });



















          });

          testButton.click();

          //let filterItem = document.querySelector(".items");
          // let filterImg = document.querySelectorAll(".gallery .image");

          // let filterVid = document.querySelectorAll(".gallery .video");

          // let filterAud = document.querySelectorAll(".gallery .audio");
          

          
             ///////////// //Used to be backup //////////
      }//end of flag
      return false;
   }//end of galleryFunction


   

  const UploadSelectedMedia = async (e) => {
   
    
    //var user = await UserApi.GetUser(username);
    //if (user.role === "Viewer") {
    //    alert(language.global.userRole);
    //} else {


    //var videosArray = [];
    //var imagesArray = [];

    //e.preventDefault();
    const uncheckedFiles = [];





    let countUpload = 100000;

    let iCount = document.getElementById('files').files.length;

    let files = document.getElementById('files').files;
    let arrayFiles = [...files].sort((a, b) => a.size - b.size);
    console.log(arrayFiles);

    //$("#spinner").fadeIn();
    //$("#preloader").fadeIn();
    //console.log(files);
    //var videoFiles = files.filter(c => {
    //    c.type.includes("video");
    //});

    //var picFiles = files.filter(c => {
    //    c.type.includes("image");
    //});

    //var audioFiles = files.filter(c => {
    //    c.type.includes("audio");
    //});


    //console.log(videoFiles);
    //console.log(picFiles);
    //console.log(audioFiles);



    document.getElementById('submit').disabled = true; ///disabled click while uploading multiple


    for (var i = 0; i < arrayFiles.length; i++) {
        //setLoading(true);
        let file = arrayFiles[i];
        const languages = await LanguageApi.Get();
        console.log(file);
        //let checked = document.querySelectorAll('input[type=checkbox]:checked');
        const checked = document.getElementsByClassName(`${countUpload}`);
        //console.log(checked);
        let count = 0;

        for (let checks of checked) {
            if (checks.checked === false) {
                count += 1;
            }
        }

        if (checked.length === count) {
            //alert(`File ${file.name} did not load. ${language.content.langCountAlert}`);

            uncheckedFiles.push(file);

            //document.getElementById("message").innerHTML += `<p style="color:#ad863f;">File ${file.name} did not load. ${language.content.langCountAlert} </p>`;



            //document.getElementById("logErrors").children.forEach(fi => { text += fi.innerHTML + "<br>" }) 
            document.getElementById("logErrors").innerHTML += `<p style="color:#ad863f;">File ${file.name} did not load. ${language.content.langCountAlert} </p>`;




        }


        if (file.type.indexOf("image/jpeg") !== -1 || file.type.indexOf("image/jpg") !== -1 || file.type.indexOf("image/png") !== -1) {




            if (file.size > 1000000) {
                //alert("Media size too big. Please choose an image up to 1 Mb");
            } else {

                // const toBase64 = files => new Promise(async (resolve, reject) => {
                //     const reader = new FileReader();
                //     reader.readAsDataURL(files);

                //     reader.onload = async () => resolve(reader.result);

                //     reader.onerror = error => reject(error);
                // });



                const uri = await resizeFile(file);
                //const uri = await toBase64(file);


                const b64 = await uri.replace(/^data:image.+;base64,/, '');


                document.getElementById("imageString").innerText = await b64;

                let image = document.getElementById("imageString").innerText;


                

                


                const imageChecked = document.getElementsByClassName(`${countUpload}`);
                //[${langName.name}] 


                for (let check of imageChecked) {
                    //let dscr = await document.getElementById('textDcr_lang_' + countUpload).value;
                    //let langName = languages.find(l => l.languageId === parseInt(check.id.match(/(\d+)/)));
                  if(check.checked) {
                    console.log(check);

                    //for(let lang of languages) {
                      //let dscr = $(`input[id*='textDcr_lang_${countUpload}_']`).val();
                      //let dscr = $(`input[id*='textDcr_lang_${countUpload}_']`).val();

                      //$(`input[id*='${e.target.id}_']`)
                      //$(`input[id*='textDcr_lang_${countUpload}_']`).val() != ""

                      console.log($(`input[id*='textDcr_lang_${countUpload}_']`).val());
                      if(check.parentElement.nextElementSibling.value != "") {
                        //let dscr = $(`input[id*='textDcr_lang_${countUpload}_']`).val();
                        let dscr = check.parentElement.nextElementSibling.value;
                        dscr = dscr.trim();
                        console.log(dscr);

                        if (check.checked == true) {
                          let test = check.getAttribute("class");


                          const media = {
                              "Name": file.name,
                              "Description": `${dscr}`,
                              "TypeId": 10,
                              "LanguageId": parseInt(check.id.match(/(\d+)/)),
                              "Value": image,

                              "RefPath": file.name //filename
                          }

                          document.getElementById('progressBar_' + countUpload).style.visibility = 'visible';

                          document.getElementById('progressBar_' + countUpload).setAttribute('value', 20);

                          const mediaCreated = await MediaApi.Create(media);
                 
                        //   if(mediaCreated) {
                        //     props.setMediaValue((prevState) => [...prevState, media]);
                        //   }
                           const objectVals = await ObjectApi.GetAllValuesOfObject(parseInt(exhibit.objectId));
                           console.log(objectVals);

                            //console.log(objectVals);
                            console.log(objectVals);
                            const objectInitial = objectVals.find(o => o.status === false);




                            await MediaApi.AddMediaToObject(objectInitial.objectValId, mediaCreated.mediA_ID);
                          
                          //var mediaInserted = await MediaApi.AddMediaToObject(objectInitial.objectValId, media);

                          //console.log(media);



                        }


                      } else {
                        let dscr = file.name;

                        if (check.checked == true) {
                          let test = check.getAttribute("class");


                          const media = {
                              "Name": file.name,
                              "Description": `${dscr}`,
                              "TypeId": 10,
                              "LanguageId": parseInt(check.id.match(/(\d+)/)),
                              "Value": image,

                              "RefPath": file.name //filename
                          }

                          document.getElementById('progressBar_' + countUpload).style.visibility = 'visible';

                          document.getElementById('progressBar_' + countUpload).setAttribute('value', 20);

                          const mediaCreated = await MediaApi.Create(media);
                 
                        //   if(mediaCreated) {
                        //     props.setMediaValue((prevState) => [...prevState, media]);
                        //   }
                           const objectVals = await ObjectApi.GetAllValuesOfObject(parseInt(exhibit.objectId));
                           console.log(objectVals);

                            //console.log(objectVals);
                            console.log(objectVals);
                            const objectInitial = objectVals.find(o => o.status === false);




                            await MediaApi.AddMediaToObject(objectInitial.objectValId, mediaCreated.mediA_ID);
                          
                          //var mediaInserted = await MediaApi.AddMediaToObject(objectInitial.objectValId, media);

                          //console.log(media);



                        }
                      }
                   // }
                    
                    

                  }

                }

                document.getElementById('progressBar_' + countUpload).setAttribute('value', 100);
                countUpload++;







            }


        } else if (file.type.indexOf("video/mp4") !== -1 || file.type.indexOf("video/ogg") !== -1 || file.type.indexOf("video/mkv") !== -1 || file.type.indexOf("video/avi") !== -1 || file.type.indexOf("video/m4v") !== -1) {

            if (file.size > 15000000) {

            } else {


                const toBase64 = files => new Promise(async (resolve, reject) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(files);

                    reader.onload = async () => resolve(reader.result);

                    reader.onerror = error => reject(error);
                });




                const uri = await toBase64(file);


                const b64 = await uri.replace(/^data:video.+;base64,/, '');


                document.getElementById("imageString").innerText = await b64;

                let vid = document.getElementById("imageString").innerText;

                const imageChecked = document.getElementsByClassName(`${countUpload}`);
                //code from image


                for (let check of imageChecked) {
                  //let dscr = await document.getElementById('textDcr_lang_' + countUpload).value;
                  //let langName = languages.find(l => l.languageId === parseInt(check.id.match(/(\d+)/)));
                  if(check.checked) {
                    console.log(check);

                    //for(let lang of languages) {
                      //let dscr = $(`input[id*='textDcr_lang_${countUpload}_']`).val();
                      //let dscr = $(`input[id*='textDcr_lang_${countUpload}_']`).val();

                      //$(`input[id*='${e.target.id}_']`)
                      //$(`input[id*='textDcr_lang_${countUpload}_']`).val() != ""

                      console.log($(`input[id*='textDcr_lang_${countUpload}_']`).val());
                      if(check.parentElement.nextElementSibling.value != "") {
                        //let dscr = $(`input[id*='textDcr_lang_${countUpload}_']`).val();
                        let dscr = check.parentElement.nextElementSibling.value;
                        dscr = dscr.trim();
                        console.log(dscr);

                        const VR = await document.getElementById('VR' + countUpload).checked;


                        if (file.name.includes("360") || dscr.includes("360")) {
                            VR = true;
                        }

                        if (check.checked == true) {
                          let test = check.getAttribute("class");


                          const media = {
                            "Name": file.name,
                            "Description": `${dscr}`,
                            "TypeId": VR ? 40 : 20,
                            "LanguageId": parseInt(check.id.match(/(\d+)/)),
                            "Value": vid,
                            "RefPath": file.name
                          }

                          // document.getElementById('progressBar_' + countUpload).style.visibility = 'visible';

                          // document.getElementById('progressBar_' + countUpload).setAttribute('value', 20);

                          const mediaCreatedVid = await MediaApi.Create(media);

                          const objectVals = await ObjectApi.GetAllValuesOfObject(parseInt(exhibit.objectId));
                            console.log(objectVals);

                            console.log(objectVals);
                           

                            const objectInitial = objectVals.find(o => o.status === false);




                            await MediaApi.AddMediaToObject(objectInitial.objectValId, mediaCreatedVid.mediA_ID);
                          //var mediaInserted = await MediaApi.AddMediaToObject(objectInitial.objectValId, media);

                          //console.log(media);



                        }


                      } else {
                        let dscr = file.name;

                        const VR = await document.getElementById('VR' + countUpload).checked;


                        if (file.name.includes("360") || dscr.includes("360")) {
                            VR = true;
                        }

                        if (check.checked == true) {
                          let test = check.getAttribute("class");


                          const media = {
                            "Name": file.name,
                            "Description": `${dscr}`,
                            "TypeId": VR ? 40 : 20,
                            "LanguageId": parseInt(check.id.match(/(\d+)/)),
                            "Value": vid,
                            "RefPath": file.name
                          }

                          // document.getElementById('progressBar_' + countUpload).style.visibility = 'visible';

                          // document.getElementById('progressBar_' + countUpload).setAttribute('value', 20);

                          const mediaCreatedVid = await MediaApi.Create(media);

                          const objectVals = await ObjectApi.GetAllValuesOfObject(parseInt(exhibit.objectId));
                            console.log(objectVals);

                            console.log(objectVals);
                           

                            const objectInitial = objectVals.find(o => o.status === false);




                            await MediaApi.AddMediaToObject(objectInitial.objectValId, mediaCreatedVid.mediA_ID);
                          //var mediaInserted = await MediaApi.AddMediaToObject(objectInitial.objectValId, media);

                          //console.log(media);



                        }
                      }
                  // }
                    
                    

                  }

              }




                //code from image





                // let dscr = await document.getElementById('textDcr_' + countUpload).value;
                // dscr = dscr.trim();
                // const VR = await document.getElementById('VR' + countUpload).checked;

                // //Language checks

                // if (file.name.includes("360") || dscr.includes("360")) {
                //     VR = true;
                // }

                 


                // for (let check of imageChecked) {

                //     if (check.checked == true) {

                //         let langName = languages.find(l => l.languageId === parseInt(check.id.match(/(\d+)/)));



                //         const media = {
                //             "Name": file.name,
                //             "Description": `${dscr}`,
                //             "TypeId": VR ? 40 : 20,
                //             "LanguageId": parseInt(check.id.match(/(\d+)/)),
                //             "Value": vid,
                //             "RefPath": file.name
                //         }


                //         //document.getElementById('progressBar_' + countUpload).style.visibility = 'visible';

                //         //document.getElementById('progressBar_' + countUpload).setAttribute('value', 20);

                //         const mediaCreated = await MediaApi.Create(media);

                //     }
                // }
               // document.getElementById('progressBar_' + countUpload).setAttribute('value', 100);


                countUpload++;


            }
        }
        ///////audio ////////////////
          
        else if (file.type.indexOf("audio/mp3") !== -1 || file.type.indexOf("audio/mpeg") !== -1) {

            if (file.size > 5000000) {

            } else {

                const toBase64 = files => new Promise(async (resolve, reject) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(files);

                    reader.onload = () => resolve(reader.result);

                    reader.onerror = error => reject(error);
                });




                const uri = await toBase64(file);


                const b64 = await uri.replace(/^data:audio.+;base64,/, '');


                document.getElementById("imageString").innerText = await b64;

                let aud =  document.getElementById("imageString").innerText;

                

                const imageChecked = document.getElementsByClassName(`${countUpload}`);
                //[${langName.name}] 


                for (let check of imageChecked) {
                    //let dscr = await document.getElementById('textDcr_lang_' + countUpload).value;
                    //let langName = languages.find(l => l.languageId === parseInt(check.id.match(/(\d+)/)));
                  if(check.checked) {
                    console.log(check);

                    //for(let lang of languages) {
                      //let dscr = $(`input[id*='textDcr_lang_${countUpload}_']`).val();
                      //let dscr = $(`input[id*='textDcr_lang_${countUpload}_']`).val();

                      //$(`input[id*='${e.target.id}_']`)
                      //$(`input[id*='textDcr_lang_${countUpload}_']`).val() != ""

                      console.log($(`input[id*='textDcr_lang_${countUpload}_']`).val());
                      if(check.parentElement.nextElementSibling.value != "") {
                        //let dscr = $(`input[id*='textDcr_lang_${countUpload}_']`).val();
                        let dscr = check.parentElement.nextElementSibling.value;
                        dscr = dscr.trim();
                        console.log(dscr);

                        if (check.checked == true) {
                          let test = check.getAttribute("class");


                          const media = {
                              "Name": file.name,
                              "Description": `${dscr}`,
                              "TypeId": 30,
                              "LanguageId": parseInt(check.id.match(/(\d+)/)),
                              "Value": aud,

                              "RefPath": file.name //filename
                          }

                          // document.getElementById('progressBar_' + countUpload).style.visibility = 'visible';

                          // document.getElementById('progressBar_' + countUpload).setAttribute('value', 20);

                          const mediaCreatedAud = await MediaApi.Create(media);

                          console.log(objectValues);


                          //const objectInitial = objectValues.find(o => o.status === false);
                          const objectVals = await ObjectApi.GetAllValuesOfObject(parseInt(exhibit.objectId));
                          console.log(objectVals);

                          console.log(objectVals);
                         

                          const objectInitial = objectVals.find(o => o.status === false);




                            await MediaApi.AddMediaToObject(objectInitial.objectValId, mediaCreatedAud.mediA_ID);

                          //var mediaInserted = await MediaApi.AddMediaToObject(objectInitial.objectValId, media);

                          //console.log(media);



                        }


                      } else {
                        let dscr = file.name;

                        if (check.checked == true) {
                          let test = check.getAttribute("class");


                          const media = {
                              "Name": file.name,
                              "Description": `${dscr}`,
                              "TypeId": 30,
                              "LanguageId": parseInt(check.id.match(/(\d+)/)),
                              "Value": aud,

                              "RefPath": file.name //filename
                          }

                          // document.getElementById('progressBar_' + countUpload).style.visibility = 'visible';

                          // document.getElementById('progressBar_' + countUpload).setAttribute('value', 20);

                          const mediaCreatedAud = await MediaApi.Create(media);

                          console.log(objectValues);


                          //const objectInitial = objectValues.find(o => o.status === false);
                          const objectVals = await ObjectApi.GetAllValuesOfObject(parseInt(exhibit.objectId));
                          console.log(objectVals);

                          console.log(objectVals);
                         

                          const objectInitial = objectVals.find(o => o.status === false);




                            await MediaApi.AddMediaToObject(objectInitial.objectValId, mediaCreatedAud.mediA_ID);

                          //var mediaInserted = await MediaApi.AddMediaToObject(objectInitial.objectValId, media);

                          //console.log(media);



                        }

                      }
                   // }
                    
                    

                  }

                }
                //document.getElementById('progressBar_' + countUpload).setAttribute('value', 100);

                countUpload++;

            }

        } else {

          Swal.fire({
            title: 'Invalid Action',
            text: `Can't procceed to upload. Only mp3 files are allowed`,
            icon: 'warning',
            
          })
        }

        //document.getElementById("divResults").style.opacity = "1";

    } //end of for loop
    //$(divResults).append(`<div class="tenor-gif-embed" data-postid="14857605" data-share-method="host" data-aspect-ratio="1.77778" data-width="100%"><a href="https://tenor.com/view/memes-loading-preview-loading-waiting-loading-preview-gif-14857605">Memes Loading GIF</a>from <a href="https://tenor.com/search/memes-gifs">Memes GIFs</a></div> <script type="text/javascript" async src="https://tenor.com/embed.js"></script>`);
    //$("#spinner").hide();
    //$("#preloader").fadeOut();
    //alert("Media uploaded successfully");
    //swal({ 
    //    title: "Success",
    //    text: "Media uploaded successfully",
    //    icon: "success",

    //});
    if (uncheckedFiles.length == 0 && iCount > 0) {
      //setLoading(false);
      let tokenObject = await authService.getTokenAsync();
      let token = tokenObject.accessToken;
      Swal.fire({
        title: languageChosenSite === "EL" ? languageGr.media.successTitle : language.media.successTitle,
        text: languageChosenSite === "EL" ? languageGr.global.mediaWords.uploadSuccess : language.global.mediaWords.uploadSuccess,
        icon: 'success',
        
        
      })

      
      
        // swal({
        //     title: language.media.successTitle,
        //     text: language.media.successUpload,
        //     icon: "success",
        //     button: 'OK',
        //     dangerMode: true,
        // })
        //     .then(async (willDelete) => {
        //         if (willDelete) {
        //             flagForGallery = true;

        //             //location.reload();
        //             closeGallery.click();

        //             $(".wrapper").css({ "opacity": "0.6" });
        //             //$("#spinner").fadeIn();
        //             $("#preloader").fadeIn();
        //             flagForGallery = false;
        //             await MediaGallery();

        //             //location.reload();
        //             $(".wrapper").css({ "opacity": "1" });
        //             //$("#spinner").fadeOut();
        //             $("#preloader").fadeOut();

        //         }
        //     });


    } else {

      Swal.fire({
        title: 'Invalid Action',
        text: `Can't procceed to upload. Check language or media length`,
        icon: 'warning',
        
      })

      document.getElementById('submit').disabled = false; 
      //setLoading(false);


        // swal({
        //     title: language.media.errorTitle,
        //     text: document.getElementById("logErrors").innerText,
        //     icon: "warning",
        //     button: 'OK',
        //     dangerMode: true,
        // })
            // .then(async (willDelete) => {
            //     if (willDelete) {

            //         //location.reload();
            //         $(".wrapper").css({ "opacity": "0.6" });
            //         //$("#spinner").fadeIn();
            //         $("#preloader").fadeIn();
            //         closeGallery.click();
            //         await MediaGallery();
            //         $(".wrapper").css({ "opacity": "1" });
            //         //$("#spinner").fadeOut();
            //         $("#preloader").fadeOut();

            //     }
            // });

            
    }
    //document.getElementById('clear').click();
    //closeGallery.click();
    //clearSelected();
    
    
    //var objectId = exhibit.objectId;

    //document.getElementById(`edit_${objectId}`).click();
    /* document.getElementById('tab4').click();*/
    //document.getElementById('submit').disabled = false; // enable click after uploading

    let tokenObject = await authService.getTokenAsync();
    let tokenn = tokenObject.accessToken;








    //} //user role check
    //} //else statement for media length

   showMedia(exhibit.objectId, tokenn);
    //await showMedia(exhibit.objectId, token);
  }


  const UploadMediaFiller = async (event) => {
    
    //var user = await UserApi.GetUser(username);   <button type="button" id="clear">Clear</button><br /><br />
    //if (user.role === "Viewer") {
    //    alert(language.global.userRole);
    //} else {
    let countImages = 100000;
    let countLanguages = 100000;
    const output = document.getElementById("result");
   
    
    
    let files = event.target.files; //FileList object
    let iCount = files.length;
    let arrayFiles = [...event.target.files].sort((a, b) => a.size - b.size);
    console.log(arrayFiles);
    if (arrayFiles.length > 10) {
        // swal({
        //     title: language.media.limitErrorTitle,
        //     text: language.media.limitErrorText,
        //     icon: "warning",
        //     button: "OK",

        // })
        Swal.fire({

          title: languageChosenSite === "EL" ? languageGr.media.limitErrorTitle : language.media.limitErrorTitle,
          text: languageChosenSite === "EL" ? languageGr.media.limitErrorText : language.media.limitErrorText,
          icon: 'warning',
          showCancelButton: true,
          
        })
    } else {

        //console.log(arrayFiles);


        const languages = await LanguageApi.Get();
        document.getElementById("message").innerHTML = "";
        document.getElementById("logErrors").innerHTML = "";


        // var checkboxArray = [];
        if (window.FileList.length !== "0") {
            document.getElementById("submit").disabled = false;
        }
        
        //$("#divResults").css("opacity", "0.6");
        //document.getElementById("divResults").style.opacity = "0.6";

        //$("#spinner").fadeIn();


        const languagePicker = document.getElementById("languagesPicker");

        for (let lang of languages) {

            //languagePicker.innerHTML += `<input  class='${countLanguages} languagesTogglers' id='lang_${lang.languageId}' name='lang_${lang.languageId}' value="${lang.name}" type='checkbox'  />`;
            languagePicker.innerHTML += `<label class='labelUpload' for="lang_${lang.languageId}">${lang.name}<input style="display:none;" class=' languagesTogglers' id='lang_${lang.languageId}' name-'lang_${lang.languageId}' type='checkbox'  /></label>`;




        }
        
        const languagesTogglers = document.querySelectorAll(".languagesTogglers");
        

        languagesTogglers.forEach(toggler => {

          $(toggler).off("click");
          $(toggler).on("click", function (e) {
              e.stopPropagation();

              if ($(toggler).is(":checked")) {
                  $(`input[id*='${e.target.id}_']`).attr("checked", true);
                  
                  //console.log($(`input[id*='${e.target.id}_']`));
                  for(let text of $(`input[id*='${e.target.id}_']`)) {
                    text.parentElement.nextElementSibling.disabled = false;
                  }
                  //$(`input[id*='${e.target.id}_']`).parentElement.nextElementSibling.disabled = false;
                  $(toggler).parent().css("background", "#1c4325");
                  $(toggler).parent().css("color", "white");

              } else {
                  $(`input[id*='${e.target.id}_']`).attr("checked", false);
                  
                  for(let text of $(`input[id*='${e.target.id}_']`)) {
                    text.parentElement.nextElementSibling.disabled = true;
                  }

                  $(toggler).parent().css("background", "floralwhite");
                  $(toggler).parent().css("color", "black");

              }
          })
        });



        


      //languagesPicker.style.display = "flex";

        console.log(languagesTogglers);


        //languagePicker.style.display = "flex";



        for (let file of arrayFiles) {



            //var file = files[i];

            /*****************Videos ************************/

            if (file.type.match('.mp4')) {
                if (file.size > 15000000) {
                    document.getElementById("message").innerHTML += ` <p style="color:orange;">Media file ${file.name} is not loaded. ${language.content.fileSize.video}.  <a target="_blank" href="../Manual/CMSManual.pdf">Please read more..</a> </p>`;
                    //alert(`Media with name ${file.name} is not loaded. ${language.content.fileSize.video}`);
                    //document.getElementById("logErrors").innerHTML += `<p style="color:orange;">Media file ${file.name}</p>`;

                } else {


                    //videosArray.push(file);
                    let videoReader = new FileReader();







                    async function readFileAsDataURL() {

                        videoReader.addEventListener("load", function (event) {

                            const picFile = event.target;

                            const div = document.createElement("div");
                            div.style.display = 'flex';
                            div.style.flexDirection = 'column-reverse';
                            div.style.marginLeft = "3%"

                            //div.style.width = "20%"; //prin to teleftaion div apo katw <input  placeholder='Description'' id='textDcr_" + countImages + "' type='text' value='' />

                            div.innerHTML = "<div style='display:flex;flex-direction:column;margin-bottom:6.2rem;'> <div class='enabler' style='text-align: center;'><span style='margin-right:12%;color:#035918;'>Enable 360 :</span><label style='margin-top:2%;margin-bottom:0%;' class='switch'><input type='checkbox' id='VR" + countImages +"' ><span style='background:#bdbdbd;' class='slider round'></span></label ></div><video id='" + countImages + "' title='" + file['name'] + "'  class='thumbnailPreviewVid' controls ><source src='" + picFile.result + "'  type='video/mp4'><video/><progress id='progressBar_" + countImages + "' value='0' max='100' style='height:20px; visibility:hidden;' class='thumbnail' ></progress></div><div style='display:flex;   align-items:start; margin-top: 15px;' ></div>";



                            for (let lang of languages) {


                                // div.innerHTML += `<div><input class='${countImages}' id='lang_${lang.languageId}_vid'  type='checkbox' name='${lang.name}' /><label style="margin-left: 1vw;"
                                //  for='lang_${lang.languageId}'>${lang.name}</label></div>`;
                                // let checked = document.querySelectorAll('input[type=checkbox]:checked');
                                div.innerHTML += `<div style="display:flex;flex-direction: row;justify-content: space-between;"><div style="display:flex;flex-direction: row;"><input class='${countImages} checker form-check-input' id='lang_${lang.languageId}_vid'  type='checkbox' name='${lang.name}' /><label style="margin-left: 0vw;" for='lang_${lang.languageId}'>${lang.name}</label></div><input class="descriptionLang" placeholder= Description..  id='textDcr_lang_${countImages}_lang_${lang.languageId}_vid' type='text' value='' disabled/></div>`;

                                //let checked = document.querySelectorAll('input[type=checkbox]:checked');
                                // let checking - document.querySelector



                            }


                            div.innerHTML += `<div class='languageSelection'>Language Selection :</div><hr>`;


                            output.insertBefore(div, null);
                            countImages++;

                        });

                        videoReader.readAsDataURL(file);


                    }
                    readFileAsDataURL();
                    output.style.display = "flex";




                }


            }


            //Only pics
            else if (file.type.match('image.*')) { //if statement originally
                if (file.size > 1000000) {
                    document.getElementById("message").innerHTML += `<p style="color:orange;">Media file ${file.name} is not loaded. ${language.content.fileSize.image}.  <a target="_blank" href="../Manual/CMSManual.pdf">Please read more..</a> </p>`;
                    //window.FileList.pop(file);
                    //document.getElementById("logErrors").innerHTML += `<p style="color:orange;">Media file ${file.name}</p>`;

                    //alert(`Media with name ${file.name} is not loaded. ${language.content.fileSize.image}`);
                } else {
                    //imagesArray.push(file);

                    let picReader = new FileReader();


                    picReader.addEventListener("load", function (event) {
                        var picFile = event.target;

                        var div = document.createElement("div");
                        div.style.display = 'flex';
                        div.style.flexDirection = 'column-reverse'
                         //div.style.width = "20%";
                         div.style.marginLeft = "3%"

                        //<input placeholder='Description'' id='textDcr_" + countImages + "' type='text' value='' />
                        div.innerHTML = "<div style='display:flex;flex-direction:column;'> <img id='" + countImages + "'  class='thumbnailPreview img-responsive img-rounded' src='" + picFile.result + "' title='" + file['name'] + "'/><progress id='progressBar_" + countImages + "' value='0' max='100' style='height:20px; visibility:hidden;' class='thumbnail' ></progress></div><div style='display:flex;   align-items:start; margin-top: 15px;margin-left: 32px;' ></div>";


                        for (let lang of languages) {

                            div.innerHTML += `<div style="display:flex;flex-direction: row;justify-content: space-between;"><div style="display:flex;flex-direction: row;"><input class='${countImages} checker form-check-input' id='lang_${lang.languageId}_img'  type='checkbox' name='${lang.name}' /><label style="margin-left: 0vw;" for='lang_${lang.languageId}'>${lang.name}</label></div><input class="descriptionLang" placeholder= Description..  id='textDcr_lang_${countImages}_lang_${lang.languageId}_img' type='text' value='' disabled/></div>`;

                           


                        }

                        div.innerHTML += `<div class='languageSelection'>Language Selection :</div><hr>`;

                        countImages++;

                        output.insertBefore(div, null);
                        //document.getElementsByClassName();





                    });

                    
                    
                    //Read the image
                    output.style.display = "flex";
                    picReader.readAsDataURL(file);
                    // let checkers = document.querySelectorAll(".checker");
                    // console.log(checkers);
                    // let arrayOFCheckers = [...checkers]
                    // arrayOFCheckers.forEach(checker => {
                    //   //$(checker).off("click");
                    //   $(checker).on("change", function (e) {
                    //       e.stopPropagation();
                    //       console.log(e.target);
                    //       if ($(checker).is(":checked")) {
                    //           $(`input[id*='${e.target.id}_']`).attr("checked", true);
                    //           //$(`input[id*='lang_${}_img']`)
                              
                    //         e.target.parentElement.nextElementSibling.disabled = false;

                    //       } else {
                    //           //$(`input[id*='${e.target.id}_']`).attr("checked", false);
                            
                    //         e.target.parentElement.nextElementSibling.disabled = true;

                    //           //console.log();  

                    //       }
                    //   })
                    // });


                  
      
                    
                }
                //else {
                //    alert("Μπορείτε να ανεβάσετε μόνο αρχείο εικόνας.");
                //    $(this).val("");
                //}
            }
            else { //if statement originally
                if (file.size > 5000000) {
                    document.getElementById("message").innerHTML += `<p style="color:orange;">Media file ${file.name} is not loaded. ${language.content.fileSize.audio}. <a target="_blank" href="../Manual/CMSManual.pdf">Please read more..</a> </p>`;
                    //alert(`Media with name ${file.name} is not loaded. Media size is too big. ${language.content.fileSize.audio}`);
                    //document.getElementById("logErrors").innerHTML += `<p style="color:orange;">Media file ${file.name}</p>`;
                } else {
                    //audioArray.push(file);

                    let picReader = new FileReader();


                    picReader.addEventListener("load", function (event) {
                        const picFile = event.target;

                        const div = document.createElement("div");
                        div.style.display = 'flex';
                        div.style.flexDirection = 'column-reverse'; //prin to teleftaio div apo katw <input placeholder='Description'' id='textDcr_" + countImages + "' type='text' value='' />
                        div.style.marginLeft = "3%"




                        div.innerHTML = "<div style='display:flex;flex-direction:column;margin-bottom: 10.7rem;'><div  class='audioFiller'><audio id='" + countImages + "'  controls ><source src='" + picFile.result + "' title='" + file['name'] + "' type='audio/ogg'><audio/></div><progress id='progressBar_" + countImages + "' value='0' max='100' style='height:20px; visibility:hidden;' class='thumbnail' ></progress></div><div style='display:flex;   align-items:start; margin-top: 15px;' ></div>";


                        for (let lang of languages) {

                            // div.innerHTML += `<div><input class='${countImages}' id='lang_${lang.languageId}_audio'  type='checkbox' name='${lang.name}' />
                            // <label style="margin-left: 1vw;" for='lang_${lang.languageId}'>${lang.name}</label></div>`;

                            // div.innerHTML += `<div style="display:flex;flex-direction: column;"><div><input class='${countImages} checker' id='lang_${lang.languageId}_audio' 
                            // type='checkbox' name='${lang.name}' /><label style="margin-left: 1vw;" for='lang_${lang.languageId}'>${lang.name}</label></div><input class="descriptionLang"
                            //  placeholder='${lang.name} Description..'' id='textDcr_lang_${lang.languageId}' type='text' value='' disabled/></div>`;


                             div.innerHTML += `<div style="display:flex;flex-direction: row;justify-content: space-between;"><div style="display:flex;flex-direction: row;"><input class='${countImages} checker form-check-input' id='lang_${lang.languageId}_audio'  type='checkbox' name='${lang.name}' /><label style="margin-left: 0vw;" for='lang_${lang.languageId}'>${lang.name}</label></div><input class="descriptionLang" placeholder= Description..  id='textDcr_lang_${countImages}_lang_${lang.languageId}_vid' type='text' value='' disabled/></div>`;


                        }
                        div.innerHTML += `<div class='languageSelection'>Language selection :</div><hr>`;
                        countImages++;

                        output.insertBefore(div, null);

                    });

                    //Read media
                    output.style.display = "flex";
                    picReader.readAsDataURL(file);


                }

            }

            /*console.log(checkboxArray);*/



        }


        //} //user role check
        // let checked = document.querySelectorAll('input[type=checkbox]:checked');
       
        //$("#spinner").fadeOut();
        // $("#preloader").fadeOut();
        // $("#divResults").css({ "opacity": "1" });
        //document.getElementById("divResults").style.opacity = "1";

        document.getElementById("files").disabled = true;
        document.querySelector(".labelUpload").style.background = "gray";

    }

    
    
      

  }



   const AddMediaLocally = async () => {

        if (flagForLocal == false) {

            
        

           
            document.getElementById("submit").disabled = true;
            
           
            




            $(document).on("change", ".checker", function(e) {
        
    
                if ($(this).is(":checked")) {
                    $(`input[id*='${e.target.id}_']`).attr("checked", true);
                    //$(`input[id*='lang_${}_img']`)
                    
                e.target.parentElement.nextElementSibling.disabled = false;
            
                } else {
                    //$(`input[id*='${e.target.id}_']`).attr("checked", false);
                
                e.target.parentElement.nextElementSibling.disabled = true;
            
                    //console.log();  
            
                }
            
            
                
            })

            $("#uploadMultiple").on("submit", async function() {
                UploadSelectedMedia();
            })

            
        } // flag
    }

   async function updateLangFilter(languages) {

    document.querySelector("#filterLang").innerHTML = '';
    //document.querySelector("#filterLang").innerHTML = `<option value="${languages.filter(l=>l.initals==="EN").initials}" id="2">English</option>`;
    for (let lang of languages) {
        // if (document.querySelector("#filterLang").innerHTML != "") {
        // console.log(document.querySelector("#filterLang"));
        //} else { 

        document.querySelector("#filterLang").innerHTML += `<option value="${lang.initials}"  id="${lang.languageId}">${lang.name}</option>`;
        //}

    }


  }


  const closeLocalModal = () => {
    document.getElementById("myModalMediaLocal").style.display = "none";
    document.getElementById("files").remove();
    document.querySelector("#divGallery").innerHTML = "";
    
    setFlagForLocal(false);
  }




    async function InitToLanguageId(init) {
      
        console.log(languages);
      const langObject = languages.find(l => l.initials === init);
      console.log(langObject);

      if (langObject) {

          return langObject.languageId;
      }
    }

    const modal = document.getElementById("myModalMedia");
    console.log(modal);
    let flagForGallery = false;
    const span = document.getElementsByClassName("closeModal")[0];


    useEffect(() => {
        getData();
      }, []);
      console.log(exhibit);
      const getData = async () => {
       //await showMedia(exhibit.objectId);
    //    await tab4(props.targetId);
        const langs = await LanguageApi.Get();
        setLanguages(langs);
        // let tokenObject = await authService.getTokenAsync();
        // let tokenn = tokenObject.accessToken;
        //setToken(tokenn);
        await updateLangFilter(langs);
        const objectVals = await ObjectApi.GetAllValuesOfObject(parseInt(exhibit.objectId));
        setObjectValues(objectVals);
      };

   const onPrevious = () => {
    console.log("prev");
    const dots = document.querySelectorAll(".Wizard_dot__E6e-z");

    const dotsArr = [...dots];
    dotsArr[2].click();
  }

const onForward = () => {
    const dots = document.querySelectorAll(".Wizard_dot__E6e-z");

    const dotsArr = [...dots];
    // console.log(dotsArr);
    // console.log(serialNumber);
    // console.log(optionsForSel);
    // console.log(optionsForSelSub);
    // console.log(exhibitionChosen);
    dotsArr[4].click();

}

  return (
    <div role="tabpanel" className="tab-pane " id="tab_default_4">
      <div
        className="row"
        style={{
          'width': "100%",
          "margin-left": "0px",
          "margin-right": "0px",
          "height": "2vh",
        }}
      ></div>

      <table
        id="media"
        className="table table-responsive table-dark thumbnails"
      >
        <tbody id="imagesGallery"></tbody>
      </table>

      <div className="white-box">
        <div className="buttonsForMed">
          <button className="btn-sm btn-success addMedia" style={{ "border-radius": "12px" }} onClick={OnAddMediaGallery} id="addMedia">
            Media Gallery
          </button>
          <button className="btn-sm btn-info addMedia" style={{ "border-radius": "12px" }} onClick={onAddLocally} id="addMediaLocally">
            Local Upload
          </button>
        </div>
      </div>

      <div id="myModalMediaLocal" className="modalMedia">
        <div className="modalMedia-content">
          {/* <!-- Modal content --> */}

          <div className="modalMedia-body">
            <div style={{"text-align":"center"}}>
              <h4 style={{ "background": "#1c4325", "color": "white" }}>Upload Files</h4>
              <span onClick={closeLocalModal} className="closeModalLocal">&times;</span>
            </div>

            <div className="wrapper">
              <article id="divGallery">
              <form id="uploadMultiple" onSubmit={(event) => {event.preventDefault()}}>
              <div id="divFiles">
                  <label className="labelUpload" for="files">
                      <i className="fas fa-upload"></i> Select multiple files
                      <input style={{ "display": "none" }} id="files" name="files[]" onChange={UploadMediaFiller} type="file" multiple />
                  </label>
              </div><br />  
              <div id="divSubmits">
                  <input className="xenagosButton" type="Submit" value="Import" id="submit" onClick={UploadSelectedMedia}/>
                  
              </div>
  
          </form>
          <div id="logErrors" style={{ "display": "none" }}></div>
          <div style={{}} id="message"></div>
          <input id="imageString" type="text" name="name" value="" style={{ "display": "none"}} />
          <div id="divResults" style={{  }}>
            
       

            <div id="languagesPicker" style={{}}></div>
              
              <output id="result" style={{ display: "none" }} />
          </div>
              </article>
            </div>
          </div>
        </div>
      </div>

      <div id="myModalMedia" className="modalMedia">
        <div className="modalMedia-content">
          {/* <!-- Modal content --> */}

          <div className="modalMedia-body">
          <Spinner
                animation="border"
                className="spinnerGallery"
                size="lg"
                
                // style={{ display: "block" }}
            />
            <div style={{ "text-align": "center" }}>
              <h4 style={{ "background": "#1c4325" }}>Multimedia</h4>
              <span className="closeModal">&times;</span>
            </div>

            <div className="wrapper">
              <button id="testButton" style={{ "display": "none" }}></button>

              <button className="xenagosButton btn btn-success" id="transferMedia">
                Add Media
              </button>

              {/* <i style=" margin-left: 72.3%; margin-top: 1.1%; color: #1c4325;" className="fas fa-globe"></i> */}
              <select
                className="form-select"
                style={{
                  "border-radius": "20px",
                  "width": "21%",
                  "font-size": "18px",
                  "text-align": "center",
                }}
                name="filterLang"
                id="filterLang"
              ></select>
              <div
                id="searchWrapper"
                style={{"margin-bottom": "1rem" }}
              >
                {/* <i className="fas fa-search searchIcon"></i> */}
                <input
                  type="text"
                  style={{
                    "margin-bottom": "1rem",
                    "padding-left": "0.5rem",
                    "margin-top": "-1rem",
                  }}
                  name="searchBar"
                  id="searchBar"
                  placeholder="Search.."
                />
              </div>
              {/* <!-- filter Items --> */}
              <nav>
                <div className="items">
                  <span className="item active" data-name="all">
                    All
                  </span>
                  <span className="item" data-name="image">
                    Image
                  </span>
                  <span className="item" data-name="video">
                    Video
                  </span>
                  <span className="item" data-name="audio">
                    Mp3
                  </span>
                </div>
              </nav>

              {/* <!-- filter Images --> */}
              <div className="gallery" id="gallery"></div>
            </div>
          </div>
        </div>
      </div>

      <div
            className="modal_footer even"
            style={{
              display: "flex",
              "flex-direction": "row",
              "justify-content": "space-evenly",
            }}
          >
            
              <button
                style={{ border: "1px solid black", "border-radius": "10px" , "color": "white", "background": "#1c4325" }}
                onClick={onPrevious}
                className="backwards btn"
                disabled
              >
                <FaIcons.FaChevronLeft /> {languageChosenSite === "EL" ? languageGr.global.previous : language.global.previous}
              </button>
            
            
              {siteIdentity == 2 && <button
                style={{ border: "1px solid black", "border-radius": "10px", "color": "white", "background": "#1c4325" }}
                onClick={onForward}
                className="forward btn"
              >
                {languageChosenSite === "EL" ? languageGr.global.next : language.global.next} <FaIcons.FaChevronRight />
              </button>}

              {siteIdentity == 1 && <button
                style={{ border: "1px solid black", "border-radius": "10px", "color": "white", "background": "#1c4325" }}
                onClick={onEnd}
                className="forward btn"
              >
                {languageChosenSite === "EL" ? languageGr.content.finish : language.content.finish} <FaIcons.FaChevronRight />
              </button>}
            
          </div>
    </div>
  );
};

export default Step4;
