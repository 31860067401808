import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const InputSkeleton = () => {

   

  return (
    <div>
      
      <Skeleton style={{ "height": "4vh",  }} count={1}>
       
        </Skeleton>
        
    
    </div>
  );
};

export default InputSkeleton;
