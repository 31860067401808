import { useState, useEffect } from "react";
import * as FaIcons from "react-icons/fa";
import {
  CategoryApi,
  PresentationApi,
  LanguageApi,
  ObjectApi,
  ExhibitNew,
  FieldApi,
  SiteApi,
} from "../../api/api.service";
import { fieldCreate, saveTheFields } from "./FieldsFunctions";
import { language, languageGr } from "../../api/lang";
import $ from "jquery";
import Swal from "sweetalert2";
import { Button, Spinner, Row, Col } from "react-bootstrap";

import smallInputSkeleton from "./smallInputSkeleton";

const FieldsManagement = (props) => {
  const [presentations, setPresentations] = useState([]);
  const [bringTheValues, setBringTheValues] = useState(false);
  const [languages, setLanguages] = useState([]);
  const [langIdChosen, setLangIdChosen] = useState(1);
  const [objectToTarget, setObjectToTarget] = useState({});
  const [categoryId, setCategoryId] = useState(null);
  const [fields, setFields] = useState([]);
  const [start, setStart] = useState([]);
  const [fieldLoaded, setFieldLoaded] = useState(false);
  

  useEffect(() => {
    getData();
  }, [start, bringTheValues]);
  let presentationArray = [];
  let languageId;
  let sites = [];
  let objectTargeted = {};
  let langs = [];
  let cats = [];
  let languagesArr = [];
  let fieldsArr = [];
  let obj = [];
  let presentationId;
  let content = [];
  let presentationName;
  //let lis;

  //let lis = document.querySelectorAll(".acti");

  //async function tab3(exhibit) {
  //tinymce.remove();
  const getData = async () => {
    // sites = await SiteApi.Get();
    // console.log(sites);

    // cats = await CategoryApi.GetCategoriesOfSite(sites[0].siteId);
    cats = await CategoryApi.GetCategoriesOfSite(1);

    for (let i = 0; i < cats.length; i++) {
      let catObj = await ObjectApi.GetByCategoryId(cats[i].categoryId);

      for (let j = 0; j < catObj.length; j++) {
        obj.push(catObj[j]);
      }
    }

    console.log(obj);
    content = obj.find((c) => c.objectId === parseInt(props.targetId));
    //console.log(content);
    presentationArray = await PresentationApi.Get();
    setPresentations(presentationArray);
    //objectTargeted = {};
    langs = await LanguageApi.Get();
    setLanguages(langs);
    //console.log(targetId);
    objectTargeted = await ObjectApi.GetAllValuesOfObject(content.objectId);
    //console.log(objectTargeted);
    setObjectToTarget(objectTargeted);
    setCategoryId(content.categoryId);
    // document.getElementById("presentationDropdown").remove();
    // document.getElementById("fields").remove();
    // document.getElementById("saveFields").remove();

    // document.getElementById("divFields").innerHTML += `<div class="col-md-9 col-lg-7 col-sm-9" id="fields"></div>`;
    // document.getElementById("divSaveFields").innerHTML += `<button style="margin-left: 4vw; margin-top: 5vh;" id="saveFields" class="xenagosButton">${language.content.button}</button>`;
    // document.getElementById("divPresentationsDropdown").innerHTML += `<select style="border-radius:20px;padding: 2px;" id="presentationDropdown"></select>`;

    fieldsArr = await CategoryApi.GetFieldOfCategory(
      parseInt(content.categoryId)
    );

    setFields(fieldsArr);

    //document.getElementById("fieldLanguages").innerHTML = "";

    // const tab = document.getElementsByClassName('tabDisable')

    // const btn = document.getElementsByClassName('btnEnable');
    // for (let i = 0; i < btn.length; i++) {
    //     btn[i].setAttribute("disabled", "");
    // }
    // console.log(presentationArray);
    updatePresentationsDrop(presentationArray);
    //const objects = await ObjectApi.GetAllValuesOfObject(parseInt(targetId));

    presentationId = $("#presentationDropdown")
      .children("option:selected")
      .val();
    //console.log(presentationId);
    presentationName = $("#presentationDropdown")
      .children("option:selected")
      .text();
    //console.log(presentationName);
    document.getElementById("saveFields").innerHTML =
      props.languageChosenSite === "EL"
        ? languageGr.content.button
        : language.content.button;

    //{props.languageChosenSite === "EN" ? language.content.button : languageGr.content.button}

    //await fieldCreate(fieldsArr, langs[0].languageId, objectTargeted, presentationId);

    // for (let lang of languages) {
    //     document.getElementById("fieldLanguages").innerHTML += `<li class="acti" id="${lang.languageId}">${lang.name}</li>`
    // }

    const starter = document.getElementsByClassName("acti")[1];
    setStart(starter);
    console.log(start);
    console.log(starter);
    console.log(langs);
    //start.click();
    start.click();
    //setFieldLoaded(true);
    document.querySelector(".spinnerField").style.display = "none";
    document.querySelector(".toggleDisplay").style.opacity = "1";
    //for active class and starting at first li active

    // lis.forEach(li => {
    //     li.addEventListener("click", function () {
    //         lis.forEach(l => l.classList.remove("active"));
    //         this.classList.add("active");
    //     });
    // })
  };

  async function updatePresentationsDrop(presentations) {
    //document.getElementById("modalPresentations").innerHTML = "<option>Select</option>";
    document.getElementById("presentationDropdown").innerHTML = "";
    for (let i = 0; i < presentations.length; i++) {
      document.getElementById(
        "presentationDropdown"
      ).innerHTML += `<option value="${presentations[i].presentationId}">${presentations[i].name}</option>`;
    }
  }

  //nameFill(exhibit);

  //var start = document.getElementsByClassName("acti")[0];

  //start.click();
  //event
  // $("#saveFields").off("click");
  // $('#saveFields').on('click', async function () {
  //     //var claims = await authService.getUserAsync();
  //     // if (claims.claims.role === "Viewer") {
  //     //     //alert(language.global.userRole);
  //     //     swal({
  //     //         title: language.media.invalidAction,
  //     //         text: language.global.userRole,
  //     //         icon: "warning",
  //     //         button: "OK",

  //     //     })
  //     // //} else {

  //             presentationId = $("#presentationDropdown").children("option:selected").val();
  //             console.log(presentationId);
  //             await saveTheFields(fieldsArr, languages[0].languageId, presentationId, objectTargeted);

  //             // $("#success").animate({
  //             //     "opacity": 1
  //             // }).delay(1500).animate({
  //             //     "opacity": 0
  //             // });

  //         //$('#saveFields').prop('disabled', true);
  //         //document.getElementById("saveFields").style.color = 'black';
  //         //document.getElementById("saveFields").style.backgroundColor = 'grey';
  //         //await tab3(exhibit);
  //    // } //user role check
  //     //location.reload();

  // });

  const onSaveField = async () => {
    console.log(props.userRole);
    if (props.userRole != "Admin") {
      Swal.fire({
        title: props.languageChosenSite == "EL" ? languageGr.media.invalidAction : language.media.invalidAction,
        text: props.languageChosenSite == "EL" ? languageGr.global.userRole : language.global.userRole,
        icon: "warning",
        button: "OK",
      });
    } else {
      presentationId = $("#presentationDropdown")
        .children("option:selected")
        .val();
      //console.log(presentationId);

      await saveTheFields(fields, langIdChosen, presentationId, objectToTarget);

      Swal.fire({
        title:
          props.languageChosenSite === "EL"
            ? languageGr.media.successTitle
            : language.media.successTitle,
        text:
          props.languageChosenSite === "EL"
            ? languageGr.media.successDesc
            : language.media.successDesc,
        icon: "success",
        button: "OK",
      });
      setBringTheValues(!bringTheValues)
    }
  };

  const onDropdownChange = async (e) => {
    let presentationName = $(e.target).children("option:selected").text();

    await fieldCreate(fields, langIdChosen, objectToTarget, e.target.value);
  };

  // $("#presentationDropdown").on("change", async function (e) {

  //     console.log(e.target);
  //     //console.log($("#presentationDropdown").children("option:selected").text());

  //     let presentationName = $("#presentationDropdown").children("option:selected").text();
  //     let presentation;
  //     //document.getElementById("saveFields").innerHTML = "test";
  //     document.getElementById("saveFields").innerHTML = language.content.button + " " + presentationName;

  //     presentation= e.target.value;
  //     console.log(presentation);
  //     //let lis = document.querySelectorAll(".acti");

  //    // await fieldCreated(fields, languages[0].languageId, objects, presentationId);
  //     // lis.forEach(li => {

  //     //         lis.forEach(l => l.classList.remove("active"));

  //     // })
  //     //console.log(langIdChosen);
  //     //document.getElementById(`1`).click();
  //     document.getElementById("fields").innerHTML = '';

  //     //await fieldCreate(fields, langIdChosen, objectToTarget, presentation);

  //     //const start = document.getElementsByClassName("acti")[0];
  //     //start = [...start];

  //     // start.length = 1;
  //     // console.log(start);
  //     //const elem = e.target.parentElement.parentElement.parentElement.children[1].children[0].children[0].children[0];
  //     // console.log(e.target.parentElement.parentElement.parentElement.children[1].children[0].children[0]);
  //     //elem.click();
  //     // start.classList.add("active");
  //     // start.click();
  // });

  // const onPresDropDownChange = () => {
  //     let presentationName = $("#presentationDropdown").children("option:selected").text();

  //     //document.getElementById("saveFields").innerHTML = "test";
  //     document.getElementById("saveFields").innerHTML = language.content.button + " " + presentationName;

  //     presentationId = $("#presentationDropdown").children("option:selected").val();

  //     //await fieldCreate(fields, languages[0].languageId, objects, presentationId);
  //     lis.forEach(li => {

  //             lis.forEach(l => l.classList.remove("active"));

  //     })
  // }

  // $("#fieldLanguages").off("click");
  // $("#fieldLanguages").on("click", async function (e) {

  //     console.log(e.target);
  //     languageId = e.target.id;

  //     presentationId = $("#presentationDropdown").children("option:selected").val();
  //     console.log(presentationId);
  //     languagesArr = [];

  //     const objects1 = await ObjectApi.GetAllValuesOfObject(parseInt(targetId));
  //     console.log(objects1);

  //     let language = await LanguageApi.GetById(languageId);
  //     languagesArr.push(language);

  //     await fieldCreated(fieldsArr, languagesArr[0].languageId, objects1, presentationId);

  //     //transFill(languagesArr[0]);

  // });

  const onLiClick = async (e) => {
    languageId = e.target.id;
    setLangIdChosen(languageId);
    presentationId = $("#presentationDropdown")
      .children("option:selected")
      .val();

    //languagesArr = [];
    //objectTargeted = await ObjectApi.GetAllValuesOfObject(parseInt(content.objectId));

    // const objects1 = await ObjectApi.GetAllValuesOfObject(parseInt(content.objectId));
    // console.log(objects1);

    //let language = await LanguageApi.GetById(languageId);
    //languagesArr.push(language);

    await fieldCreate(
      fields,
      parseInt(languageId),
      objectToTarget,
      presentationId
    );
  };

  const ActiveToggle = async (e) => {
    //setToggler(!toggler);
    //e.target.classList.remove("activeUl");
    //presentationId = $("#presentationDropdown").children("option:selected").val();

    const list = e.target.parentElement.children;
    const arrayOfElements = [...list];
    //const objects1 = await ObjectApi.GetAllValuesOfObject(parseInt(content.objectId));

    arrayOfElements.forEach((l) => l.classList.remove("activeUl"));
    e.target.classList.add("activeUl");
    //fieldCreate(fieldsArr, e.target.id, objectTargeted, presentationId);
  };

  //}

  const onPrevious = () => {
    console.log("previous");
  };

  const onForward = () => {
    console.log("forward");
  };

  return (
    <>
      <div role="tabpanel" className="tab-pane" id="tab_default_3">
        <div className="row" style={{ width: "100%", height: "2vh" }}></div>
        <div id="fieldsBox" className="container">
          <Spinner animation="border" className="spinnerField" size="lg" />
          <div className="toggleDisplay">
            <div className="row">
              <div
                className="col-5 offset-4"
                style={{ "text-align": "center" }}
                id="divPresentationsDropdown"
              >
                <label style={{ "font-size": "18px" }}>
                  {props.languageChosenSite === "EL"
                    ? languageGr.global.fieldWords.choosePres
                    : language.global.fieldWords.choosePres}
                  :
                </label>
                <select
                  style={{
                    "border-radius": "20px",
                    "text-align": "center",
                    "margin-bottom": "2vh",
                  }}
                  className="form-select"
                  id="presentationDropdown"
                  onChange={onDropdownChange}
                ></select>
              </div>
            </div>
            <div className="row" id="divFields">
              <div className="col-3 px-2">
                <ul
                  // style={{
                  //   "margin-top": "3vh",
                  //   "font-size": "18px",
                  //   cursor: "pointer",
                  //   "list-style": "none",
                  //   color: "black",
                  // }}
                  onClick={onLiClick}
                  className="tabs-ul-language "
                  id="fieldLanguages"
                >
                  {languages.map((lang) => (
                    <li
                      className="acti"
                      onClick={ActiveToggle}
                      id={lang.languageId}
                    >
                      {lang.name}
                    </li>
                  ))}
                </ul>
              </div>
              <div className="col-9 fields-content" id="fields"></div>
            </div>
            <div
              className="col-md-12"
              style={{ display: "flex", "justify-content": "end" }}
              id="divSaveFields"
            >
              <Button
                id="saveFields"
                className="addNewButton"
                onClick={onSaveField}
              ></Button>
            </div>
            <div className="modal_footer">
              <Button style={{}} onClick={onPrevious} className="backwards btn">
                <FaIcons.FaChevronLeft />
              </Button>

              <Button style={{}} onClick={onForward} className="forward btn">
                <FaIcons.FaChevronRight />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FieldsManagement;
