export const screenList = [
  {
    name: "About",
    path: "AboutScreen",
  },
  {
    name: "AR",
    path: "ARScreen",
  },
  {
    name: "Categories",
    path: "CategoriesScreen",
  },
  {
    name: "Events",
    path: "EventsScreen",
  },
  {
    name: "Exhibitions",
    path: "ExhibitionScreen",
  },
  {
    name: "Exhibits",
    path: "ExhibitListScreen",
  },
  {
    name: "QR Scanner",
    path: "QRCodeScreen",
  },
  {
    name: "Outdoor Map",
    path: "OutdoorMapScreen",
  },
  {
    name: "Indoor Map",
    path: "IndoorMapScreen",
  },
];

export const iconList = [
  {
    name: "Image",
    path: "M9 42q-1.2 0-2.1-.9Q6 40.2 6 39V9q0-1.2.9-2.1Q7.8 6 9 6h30q1.2 0 2.1.9.9.9.9 2.1v30q0 1.2-.9 2.1-.9.9-2.1.9Zm2.8-7.85h24.45l-7.35-9.8-6.6 8.55-4.65-6.35Z",
  },
  {
    name: "Category",
    path: "m13.05 21.7 11-17.7 11 17.7ZM35.3 44q-3.65 0-6.175-2.525T26.6 35.3q0-3.6 2.525-6.15Q31.65 26.6 35.3 26.6q3.6 0 6.15 2.55Q44 31.7 44 35.3q0 3.65-2.55 6.175T35.3 44ZM6 42.75v-15.2h15.2v15.2Z",
  },
  {
    name: "Navigation",
    path: "M16.05 33.4 24 29.8l7.95 3.6.25-.25L24 13.3l-8.2 19.85ZM24 44q-4.1 0-7.75-1.575-3.65-1.575-6.375-4.3-2.725-2.725-4.3-6.375Q4 28.1 4 24q0-4.15 1.575-7.8 1.575-3.65 4.3-6.35 2.725-2.7 6.375-4.275Q19.9 4 24 4q4.15 0 7.8 1.575 3.65 1.575 6.35 4.275 2.7 2.7 4.275 6.35Q44 19.85 44 24q0 4.1-1.575 7.75-1.575 3.65-4.275 6.375t-6.35 4.3Q28.15 44 24 44Z",
  },
  {
    name: "Email",
    path: "M7 40q-1.2 0-2.1-.9Q4 38.2 4 37V11q0-1.2.9-2.1Q5.8 8 7 8h34q1.2 0 2.1.9.9.9.9 2.1v26q0 1.2-.9 2.1-.9.9-2.1.9Zm17-15.1 17-11.15V11L24 21.9 7 11v2.75Z",
  },
  {
    name: "Search",
    path: "M39.8 41.95 26.65 28.8q-1.5 1.3-3.5 2.025-2 .725-4.25.725-5.4 0-9.15-3.75T6 18.75q0-5.3 3.75-9.05 3.75-3.75 9.1-3.75 5.3 0 9.025 3.75 3.725 3.75 3.725 9.05 0 2.15-.7 4.15-.7 2-2.1 3.75L42 39.75Zm-20.95-13.4q4.05 0 6.9-2.875Q28.6 22.8 28.6 18.75t-2.85-6.925Q22.9 8.95 18.85 8.95q-4.1 0-6.975 2.875T9 18.75q0 4.05 2.875 6.925t6.975 2.875Z",
  },
  {
    name: "Settings",
    path: "m19.4 44-1-6.3q-.95-.35-2-.95t-1.85-1.25l-5.9 2.7L4 30l5.4-3.95q-.1-.45-.125-1.025Q9.25 24.45 9.25 24q0-.45.025-1.025T9.4 21.95L4 18l4.65-8.2 5.9 2.7q.8-.65 1.85-1.25t2-.9l1-6.35h9.2l1 6.3q.95.35 2.025.925Q32.7 11.8 33.45 12.5l5.9-2.7L44 18l-5.4 3.85q.1.5.125 1.075.025.575.025 1.075t-.025 1.05q-.025.55-.125 1.05L44 30l-4.65 8.2-5.9-2.7q-.8.65-1.825 1.275-1.025.625-2.025.925l-1 6.3ZM24 30.5q2.7 0 4.6-1.9 1.9-1.9 1.9-4.6 0-2.7-1.9-4.6-1.9-1.9-4.6-1.9-2.7 0-4.6 1.9-1.9 1.9-1.9 4.6 0 2.7 1.9 4.6 1.9 1.9 4.6 1.9Z",
  },
  {
    name: "Check Circle",
    path: "M21.05 33.1 35.2 18.95l-2.3-2.25-11.85 11.85-6-6-2.25 2.25ZM24 44q-4.1 0-7.75-1.575-3.65-1.575-6.375-4.3-2.725-2.725-4.3-6.375Q4 28.1 4 24q0-4.15 1.575-7.8 1.575-3.65 4.3-6.35 2.725-2.7 6.375-4.275Q19.9 4 24 4q4.15 0 7.8 1.575 3.65 1.575 6.35 4.275 2.7 2.7 4.275 6.35Q44 19.85 44 24q0 4.1-1.575 7.75-1.575 3.65-4.275 6.375t-6.35 4.3Q28.15 44 24 44Z",
  },
  {
    name: "Star",
    path: "m11.65 44 3.25-14.05L4 20.5l14.4-1.25L24 6l5.6 13.25L44 20.5l-10.9 9.45L36.35 44 24 36.55Z",
  },
  {
    name: "Favorite",
    path: "m24 41.95-2.05-1.85q-5.3-4.85-8.75-8.375-3.45-3.525-5.5-6.3T4.825 20.4Q4 18.15 4 15.85q0-4.5 3.025-7.525Q10.05 5.3 14.5 5.3q2.85 0 5.275 1.35Q22.2 8 24 10.55q2.1-2.7 4.45-3.975T33.5 5.3q4.45 0 7.475 3.025Q44 11.35 44 15.85q0 2.3-.825 4.55T40.3 25.425q-2.05 2.775-5.5 6.3T26.05 40.1Z",
  },
  {
    name: "Wallpaper",
    path: "M9 42q-1.2 0-2.1-.9Q6 40.2 6 39V25.5h3V39h13.5v3Zm16.5 0v-3H39V25.5h3V39q0 1.2-.9 2.1-.9.9-2.1.9Zm-13.65-7.4 5.9-7.6 4.65 6.35L29 24.8l7.35 9.8ZM6 22.5V9q0-1.2.9-2.1Q7.8 6 9 6h13.5v3H9v13.5Zm33 0V9H25.5V6H39q1.2 0 2.1.9.9.9.9 2.1v13.5Zm-7.8-3q-1.15 0-1.925-.775-.775-.775-.775-1.925t.775-1.925q.775-.775 1.925-.775t1.925.775q.775.775.775 1.925t-.775 1.925q-.775.775-1.925.775Z",
  },
  {
    name: "Accessibility",
    path: "M24 11.3q-1.5 0-2.575-1.075Q20.35 9.15 20.35 7.65q0-1.5 1.075-2.575Q22.5 4 24 4q1.5 0 2.575 1.075Q27.65 6.15 27.65 7.65q0 1.5-1.075 2.575Q25.5 11.3 24 11.3ZM18.6 44V17.35H6v-3h36v3H29.4V44h-3V31h-4.8v13Z",
  },
];

export const tileSizes = [
  {
    title: "XLarge",
    value: "xlarge",
  },
  {
    title: "Large",
    value: "large",
  },
  {
    title: "Medium",
    value: "medium",
  },
  {
    title: "Small",
    value: "small",
  },
];

export const widgetTypes = [
  {
    title: "Screen",
    value: "screen",
  },
  {
    title: "Category",
    value: "category",
  },
  {
    title: "Exhibit",
    value: "exhibit",
  },
];
