// @ts-nocheck
import { MediaApi } from "../../api/api.service";

// @ts-nocheck
const Colors = {
  //Primary Colors
  primaryGreen: "#035918",
  primaryYellow: "#F7AA47",
  primaryGray: "#EDEDE8",
  primaryLightGreen: "#D7DBC7",

  //Secondary Colors
  secondaryDarkGreen: "#1C4325",
  secondaryOrange: "#F98304",
  secondaryLime: "#CCD98F",
  secondaryGreen: "#A8B378",
  secondaryRed: "#FF003E",
  secondaryDarkGray: "#E5E5E5",

  //Background Colors
  background: "#FFFFFF",
  backgroundDark: "#FAFAFA",
  backgroundBlack: "#111111",
};

// @ts-nocheck
export class LayoutEditor {
  constructor() {}

  findObject(id, tiles) {
    let found;
    tiles.map((item, index) => {
      if (item.id === id) {
        found = item;
      }
    });
    return found;
  }

  addTileToScreen = (id, setTiles, tiles) => {
    if (id) {
      //Add item
      let obj = this.findObject(id, tiles);
      if (obj) {
        let updatedValues = [...tiles];
        let targetIndex = tiles.indexOf(obj);
        obj.screen = true;
        updatedValues[targetIndex] = obj;
        setTiles(updatedValues);
      }
    }
  };

  returnTileToEditor = (id, setTiles, tiles) => {
    if (id) {
      //Add item
      let obj = this.findObject(id, tiles);
      if (obj) {
        let updatedValues = [...tiles];
        let targetIndex = tiles.indexOf(obj);
        obj.screen = false;
        updatedValues[targetIndex] = obj;
        setTiles(updatedValues);
      }
    }
  };

  createElement(tiles, setTiles) {
    let length = tiles.length;
    let itemId = 1;
    if (length > 0) {
      itemId = length + 1;
    }
    const element = {
      id: itemId,
      position: itemId,
      title: "Enter name...",
      background: null,
      color: Colors.primaryYellow,
      icon: "",
      size: "medium",
      attribute: "screen",
      route: "",
      objectId: 0,
      screen: false,
      deleted: false,
    };
    setTiles((prevState) => [...prevState, element]);
  }
  removeElement(item, tiles, setTiles) {
    if (item) {
      let updatedValues = [...tiles];
      let targetIndex = tiles.indexOf(item);
      item.deleted = true;
      updatedValues[targetIndex] = item;
      setTiles(updatedValues);
    }
  }
  changeName(item, text, tiles, setTiles) {
    if (text && item) {
      let updatedValues = [...tiles];
      let targetIndex = tiles.indexOf(item);
      item.title = text;
      updatedValues[targetIndex] = item;
      setTiles(updatedValues);
    }
  }
  changeSize(size, item, tiles, setTiles) {
    if (size && item) {
      let updatedValues = [...tiles];
      let targetIndex = tiles.indexOf(item);
      item.size = size;
      updatedValues[targetIndex] = item;
      setTiles(updatedValues);
    }
  }
  changeIcon(icon, item, tiles, setTiles) {
    if (icon && item) {
      let updatedValues = [...tiles];
      let targetIndex = tiles.indexOf(item);
      item.icon = icon;
      updatedValues[targetIndex] = item;
      setTiles(updatedValues);
    }
  }
  changeBackground(img, item, tiles, setTiles) {
    if (img && item) {
      let updatedValues = [...tiles];
      let targetIndex = tiles.indexOf(item);
      item.background = img;
      updatedValues[targetIndex] = item;
      setTiles(updatedValues);
    }
  }
  changeColor() {}

  addAttribute(attr, item, tiles, setTiles) {
    if (attr && item) {
      let updatedValues = [...tiles];
      let targetIndex = tiles.indexOf(item);
      item.attribute = attr;
      updatedValues[targetIndex] = item;
      setTiles(updatedValues);
    }
  }

  async fetchImage(mediaId, item, tiles, setTiles) {
    let resp = await MediaApi.GetByMediaId(parseInt(mediaId));
    if (resp) {
      let base64 = resp.value;
      let type = resp.type;
      let img = `data:${type};base64,${base64}`;
      let updatedValues = [...tiles];
      let targetIndex = tiles.indexOf(item);
      item.background = img;
      updatedValues[targetIndex] = item;
      setTiles(updatedValues);
    }
  }

  addExhibit(exhibits, exhibitId, item, tiles, setTiles) {
    const length = exhibits.length;
    let found;
    if (length > 0) {
      for (let i = 0; i < length; i++) {
        if (parseInt(exhibitId) === exhibits[i].objectS_ID) {
          found = exhibits[i];
        }
      }
      if (found) {
        let updatedValues = [...tiles];
        let targetIndex = tiles.indexOf(item);
        item.objectId = exhibitId;
        item.title = found.name;
        item.route = "";
        item.attribute = "exhibit";
        updatedValues[targetIndex] = item;
        setTiles(updatedValues);
        this.fetchImage(found.imagE_DEF, item, tiles, setTiles);
      }
    }
  }

  addCategory(categories, categoryId, item, tiles, setTiles) {
    let length = categories.length;
    let found;
    if (length > 0) {
      for (let i = 0; i < length; i++) {
        if (parseInt(categoryId) === categories[i].categoryId) {
          found = categories[i];
        }
      }
      if (found) {
        let updatedValues = [...tiles];
        let targetIndex = tiles.indexOf(item);
        item.objectId = categoryId;
        item.title = found.name;
        item.route = "";
        item.attribute = "category";
        updatedValues[targetIndex] = item;
        setTiles(updatedValues);
        this.fetchImage(found.image_def, item, tiles, setTiles);
      }
    }
  }

  changeRoute(route, item, tiles, setTiles) {
    if (route && item) {
      let updatedValues = [...tiles];
      let targetIndex = tiles.indexOf(item);
      item.route = route;
      updatedValues[targetIndex] = item;
      setTiles(updatedValues);
    }
  }

  export(tiles) {
    let addedItems = [];
    tiles.map((item) => {
      if (item.screen && !item.deleted) {
        addedItems.push(item);
      }
    });
    const data = {
      id: 0,
      title: "Main UI",
      tiles: addedItems,
    };

    const jsonString = JSON.stringify(data);
    const downloadLink = document.createElement("a");
    downloadLink.href =
      "data:text/json;charset=utf-8," + encodeURIComponent(jsonString);
    downloadLink.download = "data.json";
    downloadLink.click();
  }

  upload() {}

  getSize(size) {
    let className = "editorItemM";
    if (size === "small") {
      className = "editorItemS";
    }
    if (size === "medium") {
      className = "editorItemM";
    }
    if (size === "large") {
      className = "editorItemL";
    }
    if (size === "xlarge") {
      className = "editorItemXL";
    }
    return className;
  }
}
