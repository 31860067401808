import React from "react";
import { useState, useEffect } from "react";
import "./mapConfig.css";
import { HexColorPicker } from "react-colorful";
import Delete from "./Canvas/Objects/Delete";
// import Edit from "./Canvas/Objects/Edit";
import StairsIcon from "@mui/icons-material/Stairs";
import WeekendIcon from "@mui/icons-material/Weekend";

import { stairIcon, wcIcon } from "./IconsSVG";
import { language, languageGr } from "../../api/lang";

function MarkerConfig(props) {
  const option = props.option;
  const markerColor = props.markerColor;
  const setMarkerColor = props.setMarkerColor;
  const setFontSize = props.setFontSize;
  const fontSize = props.fontSize;
  const fillColor = props.fillColor;
  const setFillColor = props.setFillColor;
  const squares = props.squares;

  // console.log(props);


  const toggleChecked = (e) => {
    console.log(e.target.checked);
    if(e.target.checked) {
      setFillColor("transparent");

    } else {
      setFillColor(fillColor);

    }
  }

  const fillColorChange = (e) => {
    setFillColor(e.target.value)
    document.getElementById("checkTrans").checked = false;
  }
  // useEffect(() => {
  //   alert("color changed, size changed");
  // }, [props.markerColor, props.fontSize]);

  if (option === "marker" || option === "text" || option === "map" || option === "paint") {
    return (
      <div className="flexStart">
        <div className="mapConfigTitle" >
          {props.languageChosenSite === "EL"
            ? languageGr.indoor.markers
            : language.indoor.markers}
        </div>
        {/* <div className="mapConfigText">Name</div>
        <input
          className="mapConfigInput"
          type="text"
          placeholder="Marker name..."
        />
        <div className="mapConfigText">Type:</div> */}

        <div className="mapPropertiesText">
          {props.languageChosenSite === "EL"
            ? languageGr.indoor.stroke
            : language.indoor.stroke}
        </div>
        {/* <HexColorPicker color={markerColor} onChange={setMarkerColor} /> */}
       <input type="color" className="mapItemConfigInputs" value={markerColor} onChange={e=>setMarkerColor(e.target.value)} />
        
        <div id="fillColorDiv" className="flexStart">
          <div className="mapPropertiesText">
            {props.languageChosenSite === "EL"
              ? languageGr.indoor.fill
              : language.indoor.fill}
          </div>
          {/* <HexColorPicker color={fillColor} onChange={setFillColor} /> */}
        <input type="color" className="mapItemConfigInputs" value={fillColor} onChange={fillColorChange} />
        <div>{props.languageChosenSite === "EL"
            ? languageGr.indoor.transparentFill
            : language.indoor.transparentFill}</div>
        <input type="checkbox" id="checkTrans" onClick={toggleChecked}/>
        </div>
        {/* <p id="fillColorDiv">
       
          <code>{props.languageChosenSite === "EL"
                    ? languageGr.indoor.fontSize
                    : language.indoor.fontSize}</code><br/>
          <input type="color" id="fillColor" value={props.fillColor}  onChange={(e) => props.setFillColor(e.target.value)} />
       
      </p> */}
      <div className="fontsAndTexts" style={{"display": props.squares ? "flex" : "none"}}>
        
        <p id="fontSizeDiv" className="flexStart">
        <div className="mapConfigTitle">
          {props.languageChosenSite === "EL"
            ? languageGr.indoor.fontsHeading
            : language.indoor.fontsHeading}
        </div>
          <code className="coded">
            {props.languageChosenSite === "EL"
              ? languageGr.indoor.fontSize
              : language.indoor.fontSize}
          </code>
          <br />
          <input
            type="range"
            id="fontSize"
            value={fontSize}
            min="15"
            max="40"
            style={{"width": "100%"}}
            onChange={(e) => setFontSize(e.target.value)}
          />
        </p>

        <div className="fontFamily flexStart" id="fontFamilyDiv" style={{"width": "99%"}}>
          <p className="flexStart">
            <code className="coded">{props.languageChosenSite === "EL"
              ? languageGr.indoor.fontFamily
              : language.indoor.fontFamily}</code> 
            <select className="form-control" style={{"font-size": "17px"}} id="font-family" value={props.fontFamily} onChange={(e) => props.setFontFamily(e.target.value)} >
              <option value="Times New Roman">Times New Roman</option>
              <option value="Fantasy">Fantasy</option>
              <option value="Helvetica">Helvetica</option>
              <option value="Calibri">Calibri</option>
              <option value="Segoe UI">Segoe UI</option>
            </select>
          </p>
        </div>

        
        {/* <p id="strokeWidth">
          <code>
            {props.languageChosenSite === "EL"
              ? languageGr.indoor.strokeWidth
              : language.indoor.strokeWidth}
          </code>
          <br />
          <input
            type="range"
            id="strokeWidthNumber"
            value={props.strokeWidth}
            min="0"
            max="5"
            onChange={(e) => props.setStrokeWidth(e.target.value)}
          />
        </p> */}

        {/* <div>
          <button
            className="mapPropertiesItem"
            onClick={() => {
              console.log(stairIcon.path);
              props.setMarkerChosen(stairIcon.path);
            }}
          >
            <StairsIcon />{" "}
            {props.languageChosenSite === "EL"
              ? languageGr.indoor.stairs
              : language.indoor.stairs}
          </button>
          <br></br>
          <button
        className="mapPropertiesItem"
        onClick={() => {
          props.setMarkerChosen(wcIcon.path);
        }}
      >
        <WeekendIcon />{" "}
        {props.languageChosenSite === "EL"
          ? languageGr.indoor.restroom
          : language.indoor.restroom}
      </button>
        </div> */}

          <div className="textExhibit flexStart" id="textExhibit">
            <div className="mapConfigTitle">
              {props.languageChosenSite === "EL"
                ? languageGr.indoor.exhibitText
                : language.indoor.exhibitText}{" "}
              
            </div>
            <input
              type="text"
              id="textInput"
              value={props.exhibitText}
              onChange={(e) => props.setExhibitText(e.target.value)}
            />
          </div>
      </div>
        <br />
      </div>
    );
  } else return null;
}

function MapConfig(props) {
  const option = props.option;

  if (option === "map") {
    return (
      <div className="mapConfigEdit">
        <div className="mapConfigTitle">Map</div>
        <div className="mapConfigText">Name</div>
        <input
          className="mapConfigInput"
          type="text"
          placeholder="Map name..."
        />
      </div>
    );
  } else return null;
}

function ExhibitConfig(props) {
  const option = props.option;
  const [color, setColor] = useState("#FFFFFF");
  const [picker, setPicker] = useState(false);

  const markerColor = {
    width: "2.5vw",
    height: "2.5vw",
    backgroundColor: color ? color : "#FFFFFF",
    borderRadius: 10,
    marginTop: "1vh",
    marginBottom: "1vh",
    boxShadow: "5px 5px 5px rgba(0, 0, 0, 0.5)",
    border: "2px solid gray",
  };

  if (option === "exhibit") {
    return (
      <div className="mapConfigEdit">
        <div className="mapConfigTitle">Exhibit</div>
        <div className="mapConfigText">Display Name</div>
        <input
          className="mapConfigInput"
          type="text"
          placeholder="Exhibit name..."
        />
        <div className="mapConfigText">
          {props.languageChosenSite === "EL"
            ? languageGr.indoor.markerColor
            : language.indoor.markerColor}
        </div>
        <div style={markerColor} onClick={() => setPicker(!picker)}></div>
        {picker && (
          <HexColorPicker
            color={props.markerColor}
            onChange={props.setMarkerColor}
          />
        )}
      </div>
    );
  } else return null;
}

export default function MapItemConfig(props) {
  const option = props.option;
  const markerColor = props.markerColor;
  const setMarkerColor = props.setMarkerColor;
  const canvas = props.canvas;
  const setObjectTargeted = props.setObjectTargeted;
  const objectTargeted = props.objectTargeted
  const selected=props.selected;
  const setSelected = props.setSelected;
  const squares = props.squares;
  // function observeNumeric(property) {
  //   document.getElementById(property).oninput = function() {
  //     canvas.item(0)[property] = parseFloat(this.value);
  //     if (property === 'padding') {
  //       canvas.item(0).setCoords();
  //     }
  //     canvas.requestRenderAll();
  //   };
  // }

  // function observeValue(property) {
  //   document.getElementById(property).oninput = function() {
  //     canvas.item(0)[property] = this.value;
  //     canvas.requestRenderAll();
  //   };
  // }

  // observeNumeric('padding');
  // observeNumeric('cornerSize');
  // observeValue('borderColor');

  return (
    <div className="mapConfigContainer" style={{"alignItems": "flex-start"}}>
      {/* <label>{props.languageChosenSite === "EL"
                    ? languageGr.indoor.configuration
                    : language.indoor.configuration}</label> */}
      <MarkerConfig
        option={option}
        markerColor={markerColor}
        setMarkerColor={setMarkerColor}
        languageChosenSite={props.languageChosenSite}
        cavnas={props.canvas}
        fontSize={props.fontSize}
        setFontSize={props.setFontSize}
        fillColor={props.fillColor}
        setFillColor={props.setFillColor}
        setMarkerChosen={props.setMarkerChosen}
        exhibitText={props.exhibitText}
        setExhibitText={props.setExhibitText}
        strokeWidth={props.strokeWidth}
        setStrokeWidth={props.setStrokeWidth}
        fontFamily={props.fontFamily}
        setFontFamily={props.setFontFamily}
        squares={props.squares}
      />

      {/* <p>
        <label>
          <code>padding</code>
          <input type="range" id="padding" value="0" min="0" max="50" />
        </label>
      </p> */}

      <div className="py-2">
        <Delete canvas={canvas} languageChosenSite={props.languageChosenSite} />
      </div>
      
    </div>
  );
}
