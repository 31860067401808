import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const MediaSkeleton = (count) => {

   

  return (
    <div>
      
      <Skeleton style={{ "height": "37vh", }} count={1}>
       
        </Skeleton>
        
    
    </div>
  );
};

export default MediaSkeleton;
