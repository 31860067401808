import React, { useState, useEffect, Fragment } from "react";

// API
import { TagsApi, SiteApi, LanguageApi } from "../../api/api.service";
import { language, languageGr } from "../../api/lang";

// Components
import CardSkeleton from "../UI/CardSkeleton";
import TableGeneral from "../UI/TableGeneral";
import TagModal from "./TagModal";
import BundledEditor from "../../BundledEditor";

//Authservice
import { authService } from "../../api/auth.service";

// Icons
import * as FaIcons from "react-icons/fa";
import manage from "../../images/manage-orange.png";

// Swal Alert
import Swal from "sweetalert2";

// Bootstrap
import { Button, Form, Modal } from "react-bootstrap";
import { textFilter } from "react-bootstrap-table2-filter";

const TagComponent = () => {
  const [tags, setTags] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [modalShow, setModalShow] = useState(false);
  const [idClicked, setIdClicked] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const [addTag, setAddTag] = useState(false);
  const [addTitleChange, setAddTitleChange] = useState("");
//   const [addDescriptionChange, setAddDescriptionChange] = useState("");
  const [tableChanged, setTableChanged] = useState(false);
  const [languages, setLanguages] = useState([]);
  const [languageChosenSite, setLanguageChosenSite] = useState("");
  const [userRole, setUserRole] = useState("");
  const [site, setSite] = useState([]);

  useEffect(() => {
    document.body.className = "";
    console.log("useef");
    getData();
    changeHead();
  }, [tableChanged]);

  const handleHide = () => {
    setModalShow(false);
  };

  const fetchUser = async () => {
    const user = await authService.getUserAsync();

    setUserRole(user.claims[0].value);
  };

  const getData = async () => {
    const siteArray = await SiteApi.Get();
    setSite(siteArray);
    console.log(siteArray);
    const tagsArray = await TagsApi.Get();
    
    fetchUser();
    const languageArray = await LanguageApi.Get();
    setLanguages(languageArray);
    setLanguageChosenSite(sessionStorage.getItem("languageChosen"));

    setTags(tagsArray);
    setLoading(false);
    console.log(tagsArray);
    //document.querySelector(".pageHead").innerHTML = `<h4 style='padding-top: 2%;text-align: center;color: #f7aa47;margin-left: -39vw;'><img style="height: 4vh;" class='fa fa-fw fa-file' src='${manage}' />${language.global.titles.exhibition}</h4>`;
  };

  const changeHead = () => {
    if (sessionStorage.getItem("languageChosen") === "EL") {
      document.querySelector(".pageHead").innerHTML =`<div style="width: 100%; height:100%; display: inline-flex; justify-content: center;"><h4 style='padding-top: 3.5vh;text-align: center;color: #f7aa47;'><img style="height: 4vh;margin-top:-1vh;" class='fa fa-fw fa-file' src='${manage}' />${languageGr.global.titles.tags}</h4></div>`;
    } else {
      document.querySelector(".pageHead").innerHTML =`<div style="width: 100%; height:100%; display: inline-flex; justify-content: center;"><h4 style='padding-top: 3.5vh;text-align: center;color: #f7aa47;'><img style="height: 4vh;margin-top:-1vh;" class='fa fa-fw fa-file' src='${manage}' />${language.global.titles.tags}</h4></div>`;
    }
  };

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      console.log(e);
      console.log({ row, rowIndex });
    },
  };

  const edit = (cell, row, rowIndex, formatExtraData) => {
    return <FaIcons.FaRegEdit onClick={() => editClick(row)} className="editIcon" Edit />;
  };

  const editClick = (row, e) => {
    let targ =
      row.tagId;
    console.log(targ);
    setModalShow(true);
    setIdClicked(targ);
    console.log(modalShow);
  };

  const del = (cell, row, rowIndex, formatExtraData) => {
    return (
      <FaIcons.FaRegTrashAlt
        onClick={() => deleteClick(row)}
        className="deleteIcon"
        Delete
      />
    );
  };

  const deleteClick = async (row) => {
    if (userRole == "Viewer") {
      Swal.fire({
        title: languageChosenSite == "EL" ? languageGr.media.invalidAction : language.media.invalidAction,
        text: languageChosenSite == "EL" ? languageGr.global.userRole : language.global.userRole,
        icon: "warning",
        button: "OK",
      });
      return;
    }   
    //console.log(e);

    let tar = row.tagId;
    console.log(tar);

    setIdClicked(tar);
    setDeleteId(tar);
    // let tarObj = row.name
    // console.log(tarObj);
    let tarName = row.tagName;

    Swal.fire({
      title: "Are you sure?",
      html: `You are about to delete the <strong>${tarName}</strong> Tag`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const deleted = await TagsApi.Delete(parseInt(tar));
        setTableChanged(!tableChanged);
        setTableChanged(!tableChanged);
      }
    });
  };

  const AddTags = () => {
    setAddTag(true);
  };

  const onReturnFromAdd = () => {
    setAddTag(false);
  };

  const handleClose = () => {
    setAddTag(false);
  };

  const addTagToSite = async () => {
    if (userRole == "Viewer") {
      Swal.fire({
        title: languageChosenSite == "EL" ? languageGr.media.invalidAction : language.media.invalidAction,
        text: languageChosenSite == "EL" ? languageGr.global.userRole : language.global.userRole,
        icon: "warning",
        button: "OK",
      });
      return;
    }

    if (addTitleChange === "") {
      Swal.fire({
        title: "Something went wrong",
        text: `Please enter a title`,
        icon: "warning",
      });
    } else {
    //   const sites = await SiteApi.Get();
      let name = addTitleChange;
      name = name.trim();
    //   let description = addDescriptionChange;
    //   description = description.trim();
      const tag = {
        name: name
      };
      const english = languages.find((l) => l.initials === "EN");

     

      const insertedTag = await TagsApi.Create(tag);
      
      setAddTitleChange("");
    //   setAddDescriptionChange("");
      setTableChanged(!tableChanged);
      setTableChanged(!tableChanged);

      handleClose();
    }
  };

//   const descriptionFormat = (d) => {
//     if (d != null) {
//       return d.replace(/<[^>]+>/g, "").substring(0, 50) + " ....";
//     }
//   };

  const exhibitionHeaders = [
    // {
    //   dataField: "tagId",
    //   text: "Id",
    //   sort: true,
    // },
    {
      dataField: "tagName",
      text: languageChosenSite === "EL"
      ? languageGr.table.title
      : language.table.title,
      sort: true,
      filter: textFilter(),
    },
    // {
    //   dataField: "description",
    //   text: languageChosenSite === "EL"
    //   ? languageGr.table.description
    //   : language.table.description,
    //   sort: true,
    //   formatter: descriptionFormat,
    // },
    {
      dataField: "edit",
      text: languageChosenSite === "EL"
      ? languageGr.table.edit
      : language.table.edit,
      formatter: edit,
    },
    {
      dataField: "delete",
      text: languageChosenSite === "EL"
      ? languageGr.table.delete
      : language.table.delete,
      formatter: del,
    },
  ];

  const reloadTable = () => {
    setTableChanged(!tableChanged);
  };

  return (
    <Fragment>
      <div>
        {modalShow && (
          <TagModal
            onHide={handleHide}
            show={modalShow}
            targetId={idClicked}
            languages={languages}
            rowEvents={rowEvents}
            data={tags}
            getData={getData}
            setTableChanged={reloadTable}
            userRole={userRole}
            //setTableChangedtest={setTableChanged}
          ></TagModal>
        )}
        {isLoading ? (
          <div className="col-7 offset-3">
            <CardSkeleton />
          </div>
        ) : (
          <TableGeneral
            responsive
            data={tags}
            rowEvents={rowEvents}
            columns={exhibitionHeaders}
          />
        )}
        <Button
          className="addNewButton"
          variant="addNewButton"
          onClick={AddTags}
        >
          {languageChosenSite === "EL"
            ? languageGr.global.word.addNew
            : language.global.word.addNew}
        </Button>
      </div>

      <Modal show={addTag} onHide={handleClose} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>
            {languageChosenSite === "EL"
              ? languageGr.global.tagWords.add
              : language.global.tagWords.add}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>
                {languageChosenSite === "EL"
                  ? languageGr.global.transName
                  : language.global.transName}
              </Form.Label>
              <Form.Control
                type="text"
                placeholder={
                  languageChosenSite === "EL"
                    ? languageGr.global.enterTitle
                    : language.global.enterTitle
                }
                autoFocus
                onChange={(e) => setAddTitleChange(e.target.value)}
                value={addTitleChange}
              />
            </Form.Group>
            
          </Form>
        </Modal.Body>
        <Modal.Footer className="modal_footer">
          <Button
            className="addNewButton"
            variant="addNewButton"
            onClick={addTagToSite}
          >
            {languageChosenSite === "EL"
              ? languageGr.content.clearSelectedLabel.value
              : language.content.clearSelectedLabel.value}
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default TagComponent;
