import { useState, useEffect } from "react";

// API
import { ObjectApi, LanguageApi, ExhibitNew } from "../../api/api.service";

// TinyMCE
import BundledEditor from "../../BundledEditor";

// Swal
import Swal from "sweetalert2";

// Bootstrap
import { Button, Row, Col, Tab, Form, Nav, Spinner } from "react-bootstrap";

// FaIcons
import * as FaIcons from "react-icons/fa";

//languagesObject
import { language, languageGr } from "../../api/lang";


const Translations = (props) => {
  const [contentArr, setContentArr] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [translationId, setTranslationId] = useState(0);
  const [targetId, setTargetId] = useState(props.targetId);
  const [allTrans, setAllTrans] = useState([]);
  const [alter, setAlter] = useState();

  useEffect(() => {
    getData(targetId);
    console.log(props);
  }, [translationId, targetId, props]);

  useEffect(() => {
    const handler = (e) => {
      if (e.target.closest(".tox-tinymce-aux, .moxman-window, .tam-assetmanager-root") !== null) {
        e.stopImmediatePropagation();
      }
    };
    document.addEventListener("focusin", handler);
    return () => document.removeEventListener("focusin", handler);
  }, []);
  

  const getData = async (id) => {
    const allTrans = await ExhibitNew.Get();

    let arr = [];
    allTrans.map((item) => {
      arr.push(item.objectS_ID);
    });

    setAllTrans(arr);

    const lang = await LanguageApi.Get();
    setLanguages(lang);

    const objectValues = await ObjectApi.GetAllValuesOfObject(id);
    setTranslationId(objectValues[0].translationId);

    const contentObj = await ExhibitNew.GetByTranslation(id);

    let newTrans = [];
    contentObj.map((content) => {
      languages.map((lang) => {
        if (content.initials === lang.initials) {
          newTrans.push({ ...content, lang: lang.name });
        }
      });
    });
    document.querySelector(".spinnerTrans").style.display = "none";
    //document.querySelector(".row").style.opacity = "1";
    setContentArr(newTrans);
  };

  const next = () => {
    let index = allTrans.indexOf(targetId);
    index === allTrans.length - 1
      ? setTargetId(allTrans[0])
      : setTargetId(allTrans[index + 1]);
    getData(targetId);
    
  };

  const prev = () => {
    let index = allTrans.indexOf(targetId);
    index === 0
      ? setTargetId(allTrans[allTrans.length - 1])
      : setTargetId(allTrans[index - 1]);
    getData(targetId);
   

  };

  return (
    <div>
      <Spinner
        animation="border"
        className="spinnerTrans"
        size="lg"

        // style={{ display: "block" }}
      />
      <Tab.Container id="lang-left-tabs" defaultActiveKey={`#Greek`}>
        <Row>
          <Col sm={3}>
            <Nav variant="pills" className="flex-column">
              {languages.map((value) => (
                <Nav.Link href={`#${value.name}`}>{value.name}</Nav.Link>
              ))}
            </Nav>
          </Col>
          <Col sm={9}>
            <Tab.Content>
              {languages.map((lang) => (
                <Tab.Pane eventKey={`#${lang.name}`}>
                  <Data
                    targetId={targetId}
                    languageId={lang.languageId}
                    exhibitChanged={props.exhibitChanged}
                    alterTable={props.alterTable}
                    tableChanged={props.tableChanged}
                    userRole={props.userRole}
                    translationId={contentArr
                      .map((content) =>
                        content.lang === lang.name ? content.objecT_TR_ID : null
                      )
                      .join("")}
                    initialDesc={contentArr
                      .map((content) =>
                        content.lang === lang.name ? content.dscr : null
                      )
                      .join("")}
                    initialTitle={contentArr
                      .map((content) =>
                        content.lang === lang.name ? content.name : null
                      )
                      .join("")}
                  />
                </Tab.Pane>
              ))}
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
      <div className="text-center pt-3">
        <Button onClick={prev} className="backwards mx-1">
          <FaIcons.FaChevronLeft />
        </Button>

        <Button onClick={next} className="forward mx-1">
          <FaIcons.FaChevronRight />
        </Button>
      </div>
    </div>
  );
};

export default Translations;

function Data({
  initialTitle,
  initialDesc,
  languageId,
  translationId,
  targetId,
  alterTable,
  tableChanged,
  languageChosenSite,
  userRole,
  exhibitChanged
}) {
  const [desc, setDesc] = useState(initialDesc ?? "");
  const [title, setTitle] = useState(initialTitle ?? "");

  useEffect(() => {
    update();
    
  }, [initialTitle, initialDesc]);

  const update = () => {
    setDesc(initialDesc ?? "");
    setTitle(initialTitle ?? "");
  };

  const updateData = async () => {
    if (userRole == "Viewer") {
      console.log(userRole);
      Swal.fire({
        title: languageChosenSite == "EL" ? languageGr.media.invalidAction : language.media.invalidAction,
        text: languageChosenSite == "EL" ? languageGr.global.userRole : language.global.userRole,
        icon: "warning",
        button: "OK",
      });
    } else {
      let translation = {
        translations: [],
      };
      
      console.log(`New title: ${title}`);
      console.log(`New description: ${desc}`);
      console.log(`Language ID: ${languageId}`);
      console.log(`Translation ID: ${translationId}`);
      console.log(`Target ID: ${targetId}`);

      if (initialTitle || initialDesc !== "") {
        if (initialTitle !== title || initialDesc !== desc) {
          //UpdateTranslation
          console.log("Update data");
          translation.translations.push({
            translationId: translationId,
            languageId: languageId,
            name: title,
            description: desc,
          });

          const update = await ExhibitNew.UpdateTranslation(translation);
          if (update) {
            Swal.fire({
              title:
                languageChosenSite === "EL"
                  ? languageGr.media.successTitle
                  : language.media.successTitle,
              text:
                languageChosenSite === "EL"
                  ? languageGr.media.successDesc
                  : language.media.successDesc,
              icon: "success",
              button: "OK",
            });
            //exhibitChanged()
            alterTable(!tableChanged);

         

          }
        } else {
          console.log("The data are same. Update not needed");
        }
      } else {
        //CreateTranslation
        console.log("Create data");
        translation.translations.push({
          id: targetId,
          languageId: languageId,
          name: title,
          description: desc,
        });

        const create = await ObjectApi.CreateTranslation(translation);

        if (create) {
          Swal.fire({
            title:
              languageChosenSite === "EL"
                ? languageGr.media.successTitle
                : language.media.successTitle,
            text:
              languageChosenSite === "EL"
                ? languageGr.media.successDesc
                : language.media.successDesc,
            icon: "success",
            button: "OK",
          });
          //exhibitChanged()
          alterTable(!tableChanged);

          

        }
      }
    }

    //console.log(translation);
  };

  return (
    <>
      <div className="pb-2">
        <Form.Control
          defaultValue={initialTitle}
          value={title}
          onChange={(newT) => setTitle(newT.target.value)}
        />
      </div>
      <div className="pb-2">
        <BundledEditor
          init={{
            height: 500,
            selector: "textarea",
            plugins:
              "advlist anchor autolink help image link lists searchreplace table wordcount emoticons insertdatetime code",
            toolbar:
              "undo redo blocks image imagetools link media numlist bullist insertdatetime|",
            content_style:
              "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
          }}
          initialValue={initialDesc}
          //defautValue={desc}
          onEditorChange={(newD) => setDesc(newD)}
        />
      </div>
      <div>
        <Button
          className="addNewButton"
          variant="addNewButton"
          onClick={() => updateData()}
        >
          {languageChosenSite === "EN"
            ? language.global.saveChanges
            : languageGr.global.saveChanges}
        </Button>
      </div>
    </>
  );
}
