import React, { useState, Fragment, useEffect, useRef } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { NotificationApi } from "../../api/api.service";
import Moment from "react-moment";
//import {Editor} from '@tinymce/tinymce-react';
//import * as tinymce from 'https://cdn.tiny.cloud/1/no-api-key/tinymce/6/tinymce.min.js'
//import * as tinymce from '../../js/tinymce/tinymce.min.js'

//import * as tinymce from '../../js/tinymce/tinymce.min.js'

import "./Modal.css";
import { next, prev } from "./F&BButtons";
//import TransFiller from './TransFiller'
import * as FaIcons from "react-icons/fa";
import TranslationInfo from "../Management/TranslationInfo";
import InputSkeleton from "./InputSkeleton";
import { SiteApi, LanguageApi } from "../../api/api.service";
//import * as tinymce  from '@tinymce/tinymce-react'
import editOrange from "../../images/edit-orange-s.png";

import $ from "jquery";
import { language, languageGr } from "../../api/lang";

import Swal from "sweetalert2";

import { getDefaultNormalizer } from "@testing-library/react";

const ModalParent = (props) => {
  const [targetId, setTargetId] = useState(props.targetId);
  const [sumOfNotifications, setSumOfNotifications] = useState([]);
  const [notificationObject, setNotificationObject] = useState({});
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [languageChosenSite, setLanguageChosenSite] = useState("");
  const [loading, setLoading] = useState(false);
  const [allNotifications, setAllNotification] = useState([]);
  let languagesArr = [];
  let translationsItem;
  let eventObject;

  useEffect(() => {
    // start();
    getData(targetId);
  }, [targetId]);

  const getData = async (id) => {
    setLanguageChosenSite(sessionStorage.getItem("languageChosen"));

    const allNoti = props.data;
    console.log("All notifications: ", allNoti);

    let arr = [];
    allNoti.map((item) => {
      console.log(item.id);
      arr.push(item.id);
    });

    console.log("allNoti");
    console.log(arr);
    setAllNotification(arr);

    const eventObject = await NotificationApi.GetById(id);
    console.log("Current notification: ", eventObject);
    setTitle(eventObject.title);
    setMessage(eventObject.message);
    setNotificationObject(eventObject);

    const eventsIds = props.data.map((ob) => ob.id);
    setSumOfNotifications(eventsIds);
  }

  const next = () => {
    let index = allNotifications.indexOf(targetId);
    index === allNotifications.length - 1
      ? setTargetId(allNotifications[0])
      : setTargetId(allNotifications[index + 1]);
    getData(targetId);
  };

  const prev = () => {
    let index = allNotifications.indexOf(targetId);
    index === 0
      ? setTargetId(allNotifications[allNotifications.length - 1])
      : setTargetId(allNotifications[index - 1]);
    getData(targetId);
  };

  const onEditNotification = async () => {
    if (props.userRole == "Viewer") {
      Swal.fire({
        title: languageChosenSite == "EL" ? languageGr.media.invalidAction : language.media.invalidAction,
        text: languageChosenSite == "EL" ? languageGr.global.userRole : language.global.userRole,
        icon: "warning",
        button: "OK",
      });
      return;
    }
    let name = title;
    console.log(name);
    name = name.trim();
    if (name === "") {
      Swal.fire({
        title: "Something went wrong",
        text: `Please enter a title`,
        icon: "warning",
      });
      return;
    }
    let description = message;
    description = description.trim();
    console.log(description);

    const notification = {
      Id: notificationObject.id,
      Title: title,
      Message: message,
      SiteId: notificationObject.siteId,
      CreatedAt: notificationObject.createdAt,
      Status: notificationObject.status,
    };
    const update = await NotificationApi.Update(notification);
    Swal.fire({
      title: "Success",
      text: `Notification updated`,
      icon: "success",
    });
    props.setTableChanged(!props.tableChanged);
  };

  // const start = async () => {
  //   await bringNotifObj(props.targetId);
  //   setLanguageChosenSite(sessionStorage.getItem("languageChosen"));

  //   const eventsIds = props.data.map((ob) => ob.id);
  //   setSumOfNotifications(eventsIds);
  // };

  // const bringNotifObj = async (targetId) => {
  //   eventObject = await NotificationApi.GetById(targetId);
  //   console.log(eventObject);

  //   setTitle(eventObject.title);
  //   setMessage(eventObject.message);
  //   setNotificationObject(eventObject);
  //   console.log("notificationObject: ", notificationObject);
  // };

  // const UpdateEvent = async () => {
  //   const notification = {
  //     Id: notification.id,
  //     UserId: notification.userId,
  //     SiteId: notification.siteId,
  //     createdAt: notification.createdAt,

  //     Status: notification.status,
  //   };

  //   console.log(notification);
  //   const updated = await NotificationApi.Update(notification);
  //   if (updated) {
  //     Swal.fire({
  //       title: "Success",
  //       text: `Saved successfully`,
  //       icon: "success",
  //     });
  //   }
  // };

  // const dateToString = (date) => {
  //   const [year, month, day] = date.split("-");
  //   return new Date(`${year}-${month}-${day.slice(0, 2)}`);
  // };

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <img src={editOrange} style={{ height: "4vh" }} />
            <span id="title">
              {languageChosenSite === "EL"
                ? languageGr.global.editWords.edit
                : language.global.editWords.edit}
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            className="xenagosCard boxes container"
            id="translationsBox"
            style={{ "background-color": "white" }}
          >
            <div className="row">
              <div className="col-md-12" id="translations">
                <div className="col-md-12 justify-content-center">

                  <div className="form-group" style={{ "margin-top": "2vh" }}>
                    <label>
                      {languageChosenSite === "EL"
                        ? languageGr.global.transName
                        : language.global.transName}
                    </label>
                    <input
                      className="form-control"
                      onChange={(e) => setTitle(e.target.value)}
                      id="articleName"
                      type="text"
                      value={title}
                      required
                    />
                    <div className="valid-feedback"></div>
                    <div className="invalid-feedback">
                      {language.global.transInvalid}.
                    </div>
                  </div>
                  <div
                    className="form-group mt-5"
                    style={{ "margin-top": "2vh" }}
                  >
                    <label>
                      {languageChosenSite === "EL"
                        ? languageGr.global.transDesc
                        : language.global.transDesc}
                    </label>

                    <textarea
                      className="form-control"
                      id="articleDesc"
                      cols="20"
                      rows="6"
                      onChange={(e) => setMessage(e.target.value)}
                      value={message}
                    ></textarea>
                  </div>
                  <div>
                    <Button
                      className="addNewButton"
                      variant="addNewButton"
                      style={{ "margin-top": "1vh", "margin-bottom": "1vh" }}
                      onClick={onEditNotification}
                    >
                      {languageChosenSite === "EL"
                        ? languageGr.global.saveChanges
                        : language.global.saveChanges}
                    </Button>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center">
            <Button onClick={prev} className="backwards">
              <FaIcons.FaChevronLeft />
            </Button>

            <Button onClick={next} className="forward">
              <FaIcons.FaChevronRight />
            </Button>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="closeButton"
            variant="closeButton"
            onClick={props.onHide}
          >
            {languageChosenSite === "EL"
              ? languageGr.global.close
              : language.global.close}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalParent;
