import React from 'react'
import { Route, Routes } from "react-router-dom";

import NavigationBar from "../Navbar/NavigationBar";
import DetailsExhibitPage from '../DetailsExhibitPage/DetailsExhibitPage';
import Category from '../Category/Category';
import Events from '../Events/Events';
import Exhibitions from '../Exhibitions/Exhibitions';

//Pages
import { Home } from "../Pages/Home"
import { About } from "../Pages/About";

//CSS
import "./header.css"

export function Header() {
    return (
        <div>
            <NavigationBar />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="exhibit/:exhibitId" element={<DetailsExhibitPage />} />
                <Route path="exhibitions/:exhibitionsId" element={<Exhibitions />} />
                <Route path="category/:categoryId" element={<Category />} />
                <Route path="event/:eventId" element={<Events />} />
            </Routes>
        </div>
    )
}

// export default Header