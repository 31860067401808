import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Swal from "sweetalert2";
import {language, languageGr} from '../../../api/lang'
import { LanguageApi, MediaApi } from "../../../api/api.service";
import * as BsIcons from "react-icons/bs";
import { Spinner } from "react-bootstrap";
import RingLoader from "react-spinners/RingLoader";
import Manual from '../../../Manual/CMSManual.pdf';

//Resize Images
import Resizer from "react-image-file-resizer";

import $ from 'jquery';

import {useState} from 'react'


const Media = (props) => {
  const [modalShow, setModalShow] = useState(false);
  const [uploadState, setUploadState] = useState(false);
  const [loading, setLoading] = useState(false);

  const toBase64 = files => new Promise(async (resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(files);

    reader.onload = async () => resolve(reader.result);

    reader.onerror = error => reject(error);
  });

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        500,
        500,
        "JPEG",
        50,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
  });
  
  const UploadMediaFiller = async (event) => {
    
    //var user = await UserApi.GetUser(username);   <button type="button" id="clear">Clear</button><br /><br />
    //if (user.role === "Viewer") {
    //    alert(language.global.userRole);
    //} else {
    let countImages = 100000;
    let countLanguages = 100000;
    const output = document.getElementById("result");
   
    
    
    let files = event.target.files; //FileList object
    let iCount = files.length;
    let arrayFiles = [...event.target.files].sort((a, b) => a.size - b.size);
    console.log(arrayFiles);

    

    if (arrayFiles.length > 10) {
        // swal({
        //     title: language.media.limitErrorTitle,
        //     text: language.media.limitErrorText,
        //     icon: "warning",
        //     button: "OK",

        // })
        Swal.fire({
          title: 'Invalid Action',
          text: `Up to 10 files per upload`,
          icon: 'warning',
          showCancelButton: true,
          
        })
    } else {

        //console.log(arrayFiles);


        const languages = await LanguageApi.Get();
        document.getElementById("message").innerHTML = "";
        document.getElementById("logErrors").innerHTML = "";


        // var checkboxArray = [];
        if (window.FileList.length !== "0") {
            document.getElementById("submit").disabled = false;
        }
        
        //$("#divResults").css("opacity", "0.6");
        //document.getElementById("divResults").style.opacity = "0.6";

        //$("#spinner").fadeIn();


        const languagePicker = document.getElementById("languagesPicker");

        for (let lang of languages) {

            //languagePicker.innerHTML += `<input  class='${countLanguages} languagesTogglers' id='lang_${lang.languageId}' name='lang_${lang.languageId}' value="${lang.name}" type='checkbox'  />`;
            languagePicker.innerHTML += `<label class='labelUpload' for="lang_${lang.languageId}">${lang.name}<input style="display:none;" class=' languagesTogglers' id='lang_${lang.languageId}' name-'lang_${lang.languageId}' type='checkbox'  /></label>`;




        }
        
        const languagesTogglers = document.querySelectorAll(".languagesTogglers");
        

        languagesTogglers.forEach(toggler => {

          $(toggler).off("click");
          $(toggler).on("click", function (e) {
              //e.stopPropagation();

              if ($(toggler).is(":checked")) {
                  $(`input[id*='${e.target.id}_']`).attr("checked", true);
                  
                  //console.log($(`input[id*='${e.target.id}_']`));
                  for(let text of $(`input[id*='${e.target.id}_']`)) {
                    text.parentElement.nextElementSibling.disabled = false;
                  }
                  //$(`input[id*='${e.target.id}_']`).parentElement.nextElementSibling.disabled = false;
                  $(toggler).parent().css("background", "#1c4325");
                  $(toggler).parent().css("color", "white");

              } else {
                  $(`input[id*='${e.target.id}_']`).attr("checked", false);
                  
                  for(let text of $(`input[id*='${e.target.id}_']`)) {
                    text.parentElement.nextElementSibling.disabled = true;
                  }

                  $(toggler).parent().css("background", "floralwhite");
                  $(toggler).parent().css("color", "black");

              }
          })
        });



        


      //languagesPicker.style.display = "flex";

        console.log(languagesTogglers);


        //languagePicker.style.display = "flex";



        for (let file of arrayFiles) {



            //var file = files[i];

            /*****************Videos ************************/

            if (file.type.match('.mp4')) {
                if (file.size > 15000000) {
                    document.getElementById("message").innerHTML += ` <p style="color:orange;">Media file ${file.name} is not loaded. ${language.content.fileSize.video}.  <a target="_blank" href=${Manual}>Please read more..</a> </p>`;
                    //alert(`Media with name ${file.name} is not loaded. ${language.content.fileSize.video}`);
                    //document.getElementById("logErrors").innerHTML += `<p style="color:orange;">Media file ${file.name}</p>`;

                } else {


                    //videosArray.push(file);
                    let videoReader = new FileReader();







                    async function readFileAsDataURL() {

                        videoReader.addEventListener("load", function (event) {

                            const picFile = event.target;

                            const div = document.createElement("div");
                            div.style.display = 'flex';
                            div.style.flexDirection = 'column-reverse';
                            div.style.marginLeft = "3%"

                            //div.style.width = "20%"; //prin to teleftaion div apo katw <input  placeholder='Description'' id='textDcr_" + countImages + "' type='text' value='' />

                            div.innerHTML = "<div style='display:flex;flex-direction:column;margin-bottom:2.8rem;'> <div class='enabler' style='text-align: center;'><span style='margin-right:12%;color:#035918;'>Enable 360 :</span><label style='margin-top:2%;margin-bottom:0%;' class='switch'><input type='checkbox' id='VR" + countImages +"' ><span style='background:#bdbdbd;' class='slider round'></span></label ></div><video id='" + countImages + "' title='" + file['name'] + "'  class='thumbnailPreviewVid' controls ><source src='" + picFile.result + "'  type='video/mp4'><video/></div><progress id='progressBar_" + countImages + "' value='0' max='100' style='height:20px; visibility:hidden;' class='thumbnail' ></progress><div style='display:flex;   align-items:start; margin-top: 15px;' ></div>";
                            //div.innerHTML = "<div style='display:flex;flex-direction:column;'> <img id='" + countImages + "'  class='thumbnailPreview img-responsive img-rounded' src='" + picFile.result + "' title='" + file['name'] + "'/><progress id='progressBar_" + countImages + "' value='0' max='100' style='height:20px; visibility:hidden;' class='thumbnail' ></progress></div><div style='display:flex;   align-items:start; margin-top: 15px;margin-left: 32px;' ></div>";



                            for (let lang of languages) {


                                // div.innerHTML += `<div><input class='${countImages}' id='lang_${lang.languageId}_vid'  type='checkbox' name='${lang.name}' /><label style="margin-left: 1vw;"
                                //  for='lang_${lang.languageId}'>${lang.name}</label></div>`;
                                // let checked = document.querySelectorAll('input[type=checkbox]:checked');
                                div.innerHTML += `<div style="display:flex;flex-direction: row;justify-content: space-between;"><div style="display:flex;flex-direction: row;"><input class='${countImages} checker form-check-input' id='lang_${lang.languageId}_vid'  type='checkbox' name='${lang.name}' /><label style="margin-left: 0vw;" for='lang_${lang.languageId}'>${lang.name}</label></div><input class="descriptionLang" placeholder= Description..  id='textDcr_lang_${countImages}_lang_${lang.languageId}_vid' type='text' value='' disabled/></div>`;

                                //let checked = document.querySelectorAll('input[type=checkbox]:checked');
                                // let checking - document.querySelector

                            }


                            div.innerHTML += `<div class='languageSelection'>Language Selection :</div><hr>`;


                            output.insertBefore(div, null);
                            countImages++;

                        });

                        videoReader.readAsDataURL(file);


                    }
                    readFileAsDataURL();
                    output.style.display = "flex";




                }


            }


            //Only pics
            else if (file.type.match('image.*')) { //if statement originally
                if (file.size > 1000000) {
                    document.getElementById("message").innerHTML += `<p style="color:orange;">Media file ${file.name} is not loaded. ${language.content.fileSize.image}.  <a target="_blank" href="/Manual/CMSManual.pdf">Please read more..</a> </p>`;
                    //window.FileList.pop(file);
                    //document.getElementById("logErrors").innerHTML += `<p style="color:orange;">Media file ${file.name}</p>`;

                    //alert(`Media with name ${file.name} is not loaded. ${language.content.fileSize.image}`);
                } else {
                    //imagesArray.push(file);

                    let picReader = new FileReader();


                    picReader.addEventListener("load", function (event) {
                        var picFile = event.target;

                        var div = document.createElement("div");
                        div.style.display = 'flex';
                        div.style.flexDirection = 'column-reverse'
                         //div.style.width = "20%";
                         div.style.marginLeft = "3%"

                        //<input placeholder='Description'' id='textDcr_" + countImages + "' type='text' value='' />
                        div.innerHTML = "<div style='display:flex;flex-direction:column;'> <img id='" + countImages + "'  class='thumbnailPreview img-responsive img-rounded' src='" + picFile.result + "' title='" + file['name'] + "'/><progress id='progressBar_" + countImages + "' value='0' max='100' style='height:20px; visibility:hidden;' class='thumbnail' ></progress></div><div style='display:flex;   align-items:start; margin-top: 15px;margin-left: 32px;' ></div>";


                        for (let lang of languages) {

                            div.innerHTML += `<div style="display:flex;flex-direction: row;justify-content: space-between;"><div style="display:flex;flex-direction: row;"><input class='${countImages} checker form-check-input' id='lang_${lang.languageId}_img'  type='checkbox' name='${lang.name}' /><label style="margin-left: 0vw;" for='lang_${lang.languageId}'>${lang.name}</label></div><input class="descriptionLang" placeholder= Description..  id='textDcr_lang_${countImages}_lang_${lang.languageId}_img' type='text' value='' disabled/></div>`;

                           


                        }

                        div.innerHTML += `<div class='languageSelection'>Language Selection :</div><hr>`;

                        countImages++;

                        output.insertBefore(div, null);
                        //document.getElementsByClassName();





                    });

                    
                    
                    //Read the image
                    output.style.display = "flex";
                    picReader.readAsDataURL(file);
                    // let checkers = document.querySelectorAll(".checker");
                    // console.log(checkers);
                    // let arrayOFCheckers = [...checkers]
                    // arrayOFCheckers.forEach(checker => {
                    //   //$(checker).off("click");
                    //   $(checker).on("change", function (e) {
                    //       e.stopPropagation();
                    //       console.log(e.target);
                    //       if ($(checker).is(":checked")) {
                    //           $(`input[id*='${e.target.id}_']`).attr("checked", true);
                    //           //$(`input[id*='lang_${}_img']`)
                              
                    //         e.target.parentElement.nextElementSibling.disabled = false;

                    //       } else {
                    //           //$(`input[id*='${e.target.id}_']`).attr("checked", false);
                            
                    //         e.target.parentElement.nextElementSibling.disabled = true;

                    //           //console.log();  

                    //       }
                    //   })
                    // });


                  
      
                    
                }
                //else {
                //    alert("Μπορείτε να ανεβάσετε μόνο αρχείο εικόνας.");
                //    $(this).val("");
                //}
            }
            else { //if statement originally
                if (file.size > 5000000) {
                    document.getElementById("message").innerHTML += `<p style="color:orange;">Media file ${file.name} is not loaded. ${language.content.fileSize.audio}. <a target="_blank" href="/Manual/CMSManual.pdf">Please read more..</a> </p>`;
                    //alert(`Media with name ${file.name} is not loaded. Media size is too big. ${language.content.fileSize.audio}`);
                    //document.getElementById("logErrors").innerHTML += `<p style="color:orange;">Media file ${file.name}</p>`;
                } else {
                    //audioArray.push(file);

                    let picReader = new FileReader();


                    picReader.addEventListener("load", function (event) {
                        const picFile = event.target;

                        const div = document.createElement("div");
                        div.style.display = 'flex';
                        div.style.flexDirection = 'column-reverse'; //prin to teleftaio div apo katw <input placeholder='Description'' id='textDcr_" + countImages + "' type='text' value='' />
                        div.style.marginLeft = "3%"




                        div.innerHTML = "<div style='display:flex;flex-direction:column;margin-bottom: 10.7rem;'><div  class='audioFiller'><audio id='" + countImages + "'  controls ><source src='" + picFile.result + "' title='" + file['name'] + "' type='audio/ogg'><audio/></div><progress id='progressBar_" + countImages + "' value='0' max='100' style='height:20px; visibility:hidden;' class='thumbnail' ></progress></div><div style='display:flex;   align-items:start; margin-top: 15px;' ></div>";


                        for (let lang of languages) {

                            // div.innerHTML += `<div><input class='${countImages}' id='lang_${lang.languageId}_audio'  type='checkbox' name='${lang.name}' />
                            // <label style="margin-left: 1vw;" for='lang_${lang.languageId}'>${lang.name}</label></div>`;

                            // div.innerHTML += `<div style="display:flex;flex-direction: column;"><div><input class='${countImages} checker' id='lang_${lang.languageId}_audio' 
                            // type='checkbox' name='${lang.name}' /><label style="margin-left: 1vw;" for='lang_${lang.languageId}'>${lang.name}</label></div><input class="descriptionLang"
                            //  placeholder='${lang.name} Description..'' id='textDcr_lang_${lang.languageId}' type='text' value='' disabled/></div>`;


                             div.innerHTML += `<div style="display:flex;flex-direction: row;justify-content: space-between;"><div style="display:flex;flex-direction: row;"><input class='${countImages} checker form-check-input' id='lang_${lang.languageId}_audio'  type='checkbox' name='${lang.name}' /><label style="margin-left: 0vw;" for='lang_${lang.languageId}'>${lang.name}</label></div><input class="descriptionLang" placeholder= Description..  id='textDcr_lang_${countImages}_lang_${lang.languageId}_vid' type='text' value='' disabled/></div>`;


                        }
                        div.innerHTML += `<div class='languageSelection'>Language selection :</div><hr>`;
                        countImages++;

                        output.insertBefore(div, null);

                    });

                    //Read media
                    output.style.display = "flex";
                    picReader.readAsDataURL(file);


                }

            }

            /*console.log(checkboxArray);*/



        }


        //} //user role check
        // let checked = document.querySelectorAll('input[type=checkbox]:checked');
       
        //$("#spinner").fadeOut();
        // $("#preloader").fadeOut();
        // $("#divResults").css({ "opacity": "1" });
        //document.getElementById("divResults").style.opacity = "1";

        document.getElementById("files").disabled = true;
        document.querySelector(".labelUpload").style.background = "gray";

    }

    
    
      

  }


  const UploadSelectedMedia = async (e) => {
   
    
    //var user = await UserApi.GetUser(username);
    //if (user.role === "Viewer") {
    //    alert(language.global.userRole);
    //} else {


    //var videosArray = [];
    //var imagesArray = [];

    //e.preventDefault();
    const uncheckedFiles = [];

    const divRes = document.querySelector("#divResults");
    



    let countUpload = 100000;

    let iCount = document.getElementById('files').files.length;

    let files = document.getElementById('files').files;
    let arrayFiles = [...files].sort((a, b) => a.size - b.size);
    console.log(arrayFiles);
    if(files.length == "0") {
      Swal.fire({
        title: props.languageChosenSite === "EL" ? languageGr.media.invalidAction : language.media.invalidAction,
        text: props.languageChosenSite === "EL" ? languageGr.global.mediaWords.uploadLength : language.global.mediaWords.uploadLength,
        icon: 'warning',
      })
      return;  
    }

    document.querySelector(".spinnerUpload").style.display = "block";
    blurEffect(divRes);

    //$("#spinner").fadeIn();
    //$("#preloader").fadeIn();
    //console.log(files);
    //var videoFiles = files.filter(c => {
    //    c.type.includes("video");
    //});

    //var picFiles = files.filter(c => {
    //    c.type.includes("image");
    //});

    //var audioFiles = files.filter(c => {
    //    c.type.includes("audio");
    //});


    //console.log(videoFiles);
    //console.log(picFiles);
    //console.log(audioFiles);



    document.getElementById('submit').disabled = true; ///disabled click while uploading multiple


    for (const file of arrayFiles) {
        //setLoading(true);
        //let file = arrayFiles[i];
        //const languages = await LanguageApi.Get();
        await new Promise(resolve => setTimeout(resolve, 1500));
        console.log(file);
        //let checked = document.querySelectorAll('input[type=checkbox]:checked');
        const checked = document.getElementsByClassName(`${countUpload}`);
        //console.log(checked);
        let count = 0;

        for (let checks of checked) {
            if (checks.checked === false) {
                count += 1;
            }
        }

        if (checked.length === count) {
            //alert(`File ${file.name} did not load. ${language.content.langCountAlert}`);

            uncheckedFiles.push(file);

            //document.getElementById("message").innerHTML += `<p style="color:#ad863f;">File ${file.name} did not load. ${language.content.langCountAlert} </p>`;

            //document.getElementById("logErrors").children.forEach(fi => { text += fi.innerHTML + "<br>" }) 
            document.getElementById("logErrors").innerHTML += `<p style="color:#ad863f;">File ${file.name} did not load. ${language.content.langCountAlert} </p>`;

        }


        if (file.type.indexOf("image/jpeg") !== -1 || file.type.indexOf("image/jpg") !== -1 || file.type.indexOf("image/png") !== -1) {




            if (file.size > 1000000) {
                //alert("Media size too big. Please choose an image up to 1 Mb");
            } else {


                //const uri = await toBase64(file);
                const uri = await resizeFile(file);



                const b64 =  uri.replace(/^data:image.+;base64,/, '');


                document.getElementById("imageString").innerText =  b64;

                let image = document.getElementById("imageString").innerText;


                

                


                const imageChecked = document.getElementsByClassName(`${countUpload}`);
                //[${langName.name}] 


                for (let check of imageChecked) {
                    //let dscr = await document.getElementById('textDcr_lang_' + countUpload).value;
                    //let langName = languages.find(l => l.languageId === parseInt(check.id.match(/(\d+)/)));
                  if(check.checked) {
                    console.log(check);

                    //for(let lang of languages) {
                      //let dscr = $(`input[id*='textDcr_lang_${countUpload}_']`).val();
                      //let dscr = $(`input[id*='textDcr_lang_${countUpload}_']`).val();

                      //$(`input[id*='${e.target.id}_']`)
                      //$(`input[id*='textDcr_lang_${countUpload}_']`).val() != ""

                      console.log($(`input[id*='textDcr_lang_${countUpload}_']`).val());
                      console.log(check.parentElement.nextElementSibling.value);

                      if(check.parentElement.nextElementSibling.value != "") {
                        //let dscr = $(`input[id*='textDcr_lang_${countUpload}_']`).val();
                        let dscr = check.parentElement.nextElementSibling.value;
                        dscr = dscr.trim();
                        console.log(dscr);

                        if (check.checked == true) {
                          let test = check.getAttribute("class");


                          const media = {
                              "Name": file.name,
                              "Description": dscr,
                              "TypeId": 10,
                              "LanguageId": parseInt(check.id.match(/(\d+)/)),
                              "Value": image,

                              "RefPath": file.name //filename
                          }

                          document.getElementById('progressBar_' + countUpload).style.visibility = 'visible';

                          document.getElementById('progressBar_' + countUpload).setAttribute('value', 20);

                          const mediaCreated = await MediaApi.Create(media);
                          // if(mediaCreated) {
                          //   props.setMediaValue((prevState) => [...prevState, media]);
                          // }
                          console.log(mediaCreated);
                          //props.onChangeMediaArray((prevState) => [...prevState, mediaCreated]);
                          props.setChangedMediaArray(mediaCreated.mediA_ID)
                          //var mediaInserted = await MediaApi.AddMediaToObject(objectInitial.objectValId, media);

                          //console.log(media);



                        }


                      }
                   // }

                   else {
                      //let dscr = $(`input[id*='textDcr_lang_${countUpload}_']`).val();
                      let dscr = file.name;
                      dscr = dscr.trim();
                      console.log(dscr);

                      if (check.checked == true) {
                        let test = check.getAttribute("class");


                        const media = {
                            "Name": file.name,
                            "Description": dscr,
                            "TypeId": 10,
                            "LanguageId": parseInt(check.id.match(/(\d+)/)),
                            "Value": image,

                            "RefPath": file.name //filename
                        }

                        document.getElementById('progressBar_' + countUpload).style.visibility = 'visible';

                        document.getElementById('progressBar_' + countUpload).setAttribute('value', 20);

                        const mediaCreated = await MediaApi.Create(media);
                        // if(mediaCreated) {
                        //   props.setMediaValue((prevState) => [...prevState, media]);
                        // }
                        console.log(mediaCreated);
                        //props.onChangeMediaArray((prevState) => [...prevState, mediaCreated]);
                        props.setChangedMediaArray(mediaCreated.mediA_ID)
                        //var mediaInserted = await MediaApi.AddMediaToObject(objectInitial.objectValId, media);

                        //console.log(media);



                      }


                    }
                      
                    

                  }

                }

                document.getElementById('progressBar_' + countUpload).setAttribute('value', 100);
                countUpload++;







            }


        } else if (file.type.indexOf("video/mp4") !== -1 || file.type.indexOf("video/ogg") !== -1 || file.type.indexOf("video/mkv") !== -1 || file.type.indexOf("video/avi") !== -1 || file.type.indexOf("video/m4v") !== -1) {

            if (file.size > 15000000) {

            } else {


                // const toBase64 = files => new Promise(async (resolve, reject) => {
                //     const reader = new FileReader();
                //     reader.readAsDataURL(files);

                //     reader.onload = async () => resolve(reader.result);

                //     reader.onerror = error => reject(error);
                // });




                const uri = await toBase64(file);


                const b64 = await uri.replace(/^data:video.+;base64,/, '');


                document.getElementById("imageString").innerText = await b64;

                let vid = document.getElementById("imageString").innerText;

                const imageChecked = document.getElementsByClassName(`${countUpload}`);
                //code from image


                for (let check of imageChecked) {
                  //let dscr = await document.getElementById('textDcr_lang_' + countUpload).value;
                  //let langName = languages.find(l => l.languageId === parseInt(check.id.match(/(\d+)/)));
                  if(check.checked) {
                    console.log(check);

                    //for(let lang of languages) {
                      //let dscr = $(`input[id*='textDcr_lang_${countUpload}_']`).val();
                      //let dscr = $(`input[id*='textDcr_lang_${countUpload}_']`).val();

                      //$(`input[id*='${e.target.id}_']`)
                      //$(`input[id*='textDcr_lang_${countUpload}_']`).val() != ""

                      console.log($(`input[id*='textDcr_lang_${countUpload}_']`).val());
                      if(check.parentElement.nextElementSibling.value != "") {
                        //let dscr = $(`input[id*='textDcr_lang_${countUpload}_']`).val();
                        let dscr = check.parentElement.nextElementSibling.value;
                        dscr = dscr.trim();
                        console.log(dscr);

                        let VR = await document.getElementById('VR' + countUpload).checked;


                        if (file.name.includes("360") || dscr.includes("360")) {
                            VR = true;
                        }

                        if (check.checked == true) {
                          let test = check.getAttribute("class");


                          const media = {
                            "Name": file.name,
                            "Description": `${dscr}`,
                            "TypeId": VR ? 40 : 20,
                            "LanguageId": parseInt(check.id.match(/(\d+)/)),
                            "Value": vid,
                            "RefPath": file.name
                          }

                         document.getElementById('progressBar_' + countUpload).style.visibility = 'visible';

                         document.getElementById('progressBar_' + countUpload).setAttribute('value', 20);

                          const mediaCreatedVid = await MediaApi.Create(media);


                          //var mediaInserted = await MediaApi.AddMediaToObject(objectInitial.objectValId, media);

                          console.log(mediaCreatedVid);

                          props.setChangedMediaArray(mediaCreatedVid.mediA_ID)


                        }


                      }

                      else {
                        //let dscr = $(`input[id*='textDcr_lang_${countUpload}_']`).val();
                        let dscr = file.name;
                        dscr = dscr.trim();
                        console.log(dscr);

                        let VR = await document.getElementById('VR' + countUpload).checked;


                        if (file.name.includes("360") || dscr.includes("360")) {
                            VR = true;
                        }

                        if (check.checked == true) {
                          let test = check.getAttribute("class");


                          const media = {
                            "Name": file.name,
                            "Description": `${dscr}`,
                            "TypeId": VR ? 40 : 20,
                            "LanguageId": parseInt(check.id.match(/(\d+)/)),
                            "Value": vid,
                            "RefPath": file.name
                          }

                          document.getElementById('progressBar_' + countUpload).style.visibility = 'visible';

                          document.getElementById('progressBar_' + countUpload).setAttribute('value', 20);

                          const mediaCreatedVid = await MediaApi.Create(media);


                          //var mediaInserted = await MediaApi.AddMediaToObject(objectInitial.objectValId, media);

                          console.log(mediaCreatedVid);

                          props.setChangedMediaArray(mediaCreatedVid.mediA_ID)


                        }


                      }
                  // }
                    
                    

                  }

              }




                //code from image





                // let dscr = await document.getElementById('textDcr_' + countUpload).value;
                // dscr = dscr.trim();
                // const VR = await document.getElementById('VR' + countUpload).checked;

                // //Language checks

                // if (file.name.includes("360") || dscr.includes("360")) {
                //     VR = true;
                // }

                 


                // for (let check of imageChecked) {

                //     if (check.checked == true) {

                //         let langName = languages.find(l => l.languageId === parseInt(check.id.match(/(\d+)/)));



                //         const media = {
                //             "Name": file.name,
                //             "Description": `${dscr}`,
                //             "TypeId": VR ? 40 : 20,
                //             "LanguageId": parseInt(check.id.match(/(\d+)/)),
                //             "Value": vid,
                //             "RefPath": file.name
                //         }


                //         //document.getElementById('progressBar_' + countUpload).style.visibility = 'visible';

                //         //document.getElementById('progressBar_' + countUpload).setAttribute('value', 20);

                //         const mediaCreated = await MediaApi.Create(media);

                //     }
                // }
               // document.getElementById('progressBar_' + countUpload).setAttribute('value', 100);

               document.getElementById('progressBar_' + countUpload).setAttribute('value', 100);

                countUpload++;


            }
        }
        ///////audio ////////////////
          
        else if (file.type.indexOf("audio/mp3") !== -1 || file.type.indexOf("audio/mpeg") !== -1) {

            if (file.size > 5000000) {

            } else {

                // const toBase64 = files => new Promise(async (resolve, reject) => {
                //     const reader = new FileReader();
                //     reader.readAsDataURL(files);

                //     reader.onload = () => resolve(reader.result);

                //     reader.onerror = error => reject(error);
                // });




                const uri = await toBase64(file);


                const b64 = await uri.replace(/^data:audio.+;base64,/, '');


                document.getElementById("imageString").innerText = await b64;

                let aud =  document.getElementById("imageString").innerText;

                

                const imageChecked = document.getElementsByClassName(`${countUpload}`);
                //[${langName.name}] 


                for (let check of imageChecked) {
                    //let dscr = await document.getElementById('textDcr_lang_' + countUpload).value;
                    //let langName = languages.find(l => l.languageId === parseInt(check.id.match(/(\d+)/)));
                  if(check.checked) {
                    console.log(check);

                    //for(let lang of languages) {
                      //let dscr = $(`input[id*='textDcr_lang_${countUpload}_']`).val();
                      //let dscr = $(`input[id*='textDcr_lang_${countUpload}_']`).val();

                      //$(`input[id*='${e.target.id}_']`)
                      //$(`input[id*='textDcr_lang_${countUpload}_']`).val() != ""

                      console.log($(`input[id*='textDcr_lang_${countUpload}_']`).val());
                      if(check.parentElement.nextElementSibling.value != "") {
                        //let dscr = $(`input[id*='textDcr_lang_${countUpload}_']`).val();
                        let dscr = check.parentElement.nextElementSibling.value;
                        dscr = dscr.trim();
                        console.log(dscr);

                        if (check.checked == true) {
                          let test = check.getAttribute("class");


                          const media = {
                              "Name": file.name,
                              "Description": `${dscr}`,
                              "TypeId": 30,
                              "LanguageId": parseInt(check.id.match(/(\d+)/)),
                              "Value": aud,

                              "RefPath": file.name //filename
                          }

                          document.getElementById('progressBar_' + countUpload).style.visibility = 'visible';

                          document.getElementById('progressBar_' + countUpload).setAttribute('value', 20);

                          const mediaCreatedAud = await MediaApi.Create(media);

                          //var mediaInserted = await MediaApi.AddMediaToObject(objectInitial.objectValId, media);

                          console.log(mediaCreatedAud);
                          props.setChangedMediaArray(mediaCreatedAud.mediA_ID)



                        }


                      }

                      else {
                        //let dscr = $(`input[id*='textDcr_lang_${countUpload}_']`).val();
                        let dscr = file.name;
                        dscr = dscr.trim();
                        console.log(dscr);

                        if (check.checked == true) {
                          let test = check.getAttribute("class");


                          const media = {
                              "Name": file.name,
                              "Description": `${dscr}`,
                              "TypeId": 30,
                              "LanguageId": parseInt(check.id.match(/(\d+)/)),
                              "Value": aud,

                              "RefPath": file.name //filename
                          }

                          document.getElementById('progressBar_' + countUpload).style.visibility = 'visible';

                          document.getElementById('progressBar_' + countUpload).setAttribute('value', 20);

                          const mediaCreatedAud = await MediaApi.Create(media);

                          //var mediaInserted = await MediaApi.AddMediaToObject(objectInitial.objectValId, media);

                          console.log(mediaCreatedAud);
                          props.setChangedMediaArray(mediaCreatedAud.mediA_ID)



                        }


                      }
                   // }
                    
                    

                  }

                }
                document.getElementById('progressBar_' + countUpload).setAttribute('value', 100);

                countUpload++;

            }

        } else {

          Swal.fire({
            title: 'Invalid Action',
            text: `Can't procceed to upload. Only mp3 files are allowed`,
            icon: 'warning',
            
          })
        }

        //document.getElementById("divResults").style.opacity = "1";

    } //end of for loop
    //$(divResults).append(`<div class="tenor-gif-embed" data-postid="14857605" data-share-method="host" data-aspect-ratio="1.77778" data-width="100%"><a href="https://tenor.com/view/memes-loading-preview-loading-waiting-loading-preview-gif-14857605">Memes Loading GIF</a>from <a href="https://tenor.com/search/memes-gifs">Memes GIFs</a></div> <script type="text/javascript" async src="https://tenor.com/embed.js"></script>`);
    //$("#spinner").hide();
    //$("#preloader").fadeOut();
    //alert("Media uploaded successfully");
    //swal({ 
    //    title: "Success",
    //    text: "Media uploaded successfully",
    //    icon: "success",

    //});
    if (uncheckedFiles.length == 0 && iCount > 0) {
      //setLoading(false);

      Swal.fire({
        title: props.languageChosenSite === "EL" ? languageGr.media.successTitle : language.media.successTitle,
        text: props.languageChosenSite === "EL" ? languageGr.global.mediaWords.uploadSuccess : language.global.mediaWords.uploadSuccess,
        icon: 'success',
        
        
      })
      
      document.querySelector(".spinnerUpload").style.display = "none";
      unBlurEffect(divRes);
      
        // swal({
        //     title: language.media.successTitle,
        //     text: language.media.successUpload,
        //     icon: "success",
        //     button: 'OK',
        //     dangerMode: true,
        // })
        //     .then(async (willDelete) => {
        //         if (willDelete) {
        //             flagForGallery = true;

        //             //location.reload();
        //             closeGallery.click();

        //             $(".wrapper").css({ "opacity": "0.6" });
        //             //$("#spinner").fadeIn();
        //             $("#preloader").fadeIn();
        //             flagForGallery = false;
        //             await MediaGallery();

        //             //location.reload();
        //             $(".wrapper").css({ "opacity": "1" });
        //             //$("#spinner").fadeOut();
        //             $("#preloader").fadeOut();

        //         }
        //     });


    } else {

      Swal.fire({
        title: props.languageChosenSite === "EL" ? languageGr.media.invalidAction : language.media.invalidAction,
        text: props.languageChosenSite === "EL" ? languageGr.global.mediaWords.uploadLangInvalid : language.global.mediaWords.uploadLangInvalid,
        icon: 'warning',
        
      })

      

      document.getElementById('submit').disabled = false; 
      //setLoading(false);


        // swal({
        //     title: language.media.errorTitle,
        //     text: document.getElementById("logErrors").innerText,
        //     icon: "warning",
        //     button: 'OK',
        //     dangerMode: true,
        // })
            // .then(async (willDelete) => {
            //     if (willDelete) {

            //         //location.reload();
            //         $(".wrapper").css({ "opacity": "0.6" });
            //         //$("#spinner").fadeIn();
            //         $("#preloader").fadeIn();
            //         closeGallery.click();
            //         await MediaGallery();
            //         $(".wrapper").css({ "opacity": "1" });
            //         //$("#spinner").fadeOut();
            //         $("#preloader").fadeOut();

            //     }
            // });

            
    }
    //document.getElementById('clear').click();
    //closeGallery.click();
    //clearSelected();

    //var objectId = exhibit.objectId;

    //document.getElementById(`edit_${objectId}`).click();
    /* document.getElementById('tab4').click();*/
    //document.getElementById('submit').disabled = false; // enable click after uploading










    //} //user role check
    //} //else statement for media length


  
  }


  $(document).on("change", ".checker", function(e) {
    
   
    if ($(this).is(":checked")) {
        $(`input[id*='${e.target.id}_']`).attr("checked", true);
        //$(`input[id*='lang_${}_img']`)
        
      e.target.parentElement.nextElementSibling.disabled = false;

    } else {
        //$(`input[id*='${e.target.id}_']`).attr("checked", false);
      
      e.target.parentElement.nextElementSibling.disabled = true;

        //console.log();  

    }


    
  })

  
  // $(checker).on("change", function (e) {
  //   e.stopPropagation();
  //   console.log(e.target);
  //   if ($(checker).is(":checked")) {
  //       $(`input[id*='${e.target.id}_']`).attr("checked", true);
  //       //$(`input[id*='lang_${}_img']`)
        
  //     e.target.parentElement.nextElementSibling.disabled = false;

  //   } else {
  //       //$(`input[id*='${e.target.id}_']`).attr("checked", false);
      
  //     e.target.parentElement.nextElementSibling.disabled = true;

  //       //console.log();  

  //   }
  // })
  


  const blurEffect = (element) => {
    element.style.opacity = "0.7"
  }

  const unBlurEffect = (element) => {
    element.style.opacity = "1"
  }

  
      
 

 



  return (
    <>
      
      <Modal
        {...props}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton style={{ "height": "5vh" }}>
          <Modal.Title id="contained-modal-title-vcenter">
          {props.languageChosenSite === "EL" ? languageGr.global.mediaWords.uploadTitle : language.global.mediaWords.uploadTitle}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <form id="uploadMultiple" onSubmit={(event) => {event.preventDefault()}}>
          
              <div id="divFiles">
                  <label className="labelUpload" for="files">
                      <i className="fas fa-upload"></i> {props.languageChosenSite === "EL" ? languageGr.global.mediaWords.selectFiles : language.global.mediaWords.selectFiles}
                      <input style={{ "display": "none" }} id="files" name="files[]" onChange={UploadMediaFiller} type="file" multiple />
                  </label>
              </div><br />  
              <div id="divSubmits">
                  <input className="xenagosButton" type="Submit" value={props.languageChosenSite === "EL" ? languageGr.import.importMedia : language.import.importMedia} id="submit" onClick={UploadSelectedMedia}/>
                  
              </div>
  
          </form>
          <div id="logErrors" style={{ "display": "none" }}></div>
          <div style={{}} id="message"></div>
          <input id="imageString" type="text" name="name" value="" style={{ "display": "none"}} />
          <Spinner
                  animation="border"
                  className="spinnerUpload"
                  size="lg"
                  
                  // style={{ display: "block" }}
              />
          <div id="divResults" style={{  }}>
            
       

            <div id="languagesPicker" style={{}}></div>
              
              <output id="result" style={{ display: "none" }} />
          </div>
          {/* <RingLoader color={"orange"} loading={loading}  size={100} /> */}
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-primaryNew" onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
      
    </>
  );
}

export default Media;