import React, { useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import { Modal, Form, Tabs, Tab, Toast, ToastContainer } from "react-bootstrap";
import { language, languageGr } from "../../../../api/lang";
import { ImageURL, ImageFromFile } from "./Image";
import Select from "react-select";
import { MapsApi } from "../../../../api/api.service";

function EditMap(props) {
  const [showToast, setShowToast] = useState(false);
  const [show, setShow] = useState(false);
  const [key, setKey] = useState("file");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleCloseToast = () => setShowToast(false);

  const onSetTitle = (e) => {
    props.setTitle(e.target.value);
  };

  const onSetDescription = (e) => {
    props.setDescription(e.target.value);
  };

  const onSetLanguage = (option) => {
    props.setLang(option);
    if (option !== null) {
      // props.setLang(option.value);
      console.log(option);
    }
  };

  const updateMapInformation = async () => {
    console.log("Update map informations.");
    const mapToJSON = JSON.stringify(props.canvas.toJSON());
    const mapInfo = await MapsApi.GetById(props.mapId);
    console.log(mapInfo);

    const map = {
      Name: props.title !== mapInfo.name ? props.title : mapInfo.name,
      Description:
        props.description !== mapInfo.description
          ? props.description
          : mapInfo.description,
      MapId: props.mapId,
      Value: mapToJSON,
      translations: [
        {
          translationId: mapInfo.translationId,
          Id: props.mapId,
          Name: props.title !== mapInfo.name ? props.title : mapInfo.name,
          Description:
            props.description !== mapInfo.description
              ? props.description
              : mapInfo.description,
          LanguageId: props.lang.value,
        },
      ],
    };

    const myMap = await MapsApi.UpdateTranslation(map);
    console.log("myMap");
    console.log(myMap);

    console.log(props.selectedMap);
    props.setSelectedMap({ value: map.MapId, label: map.Name });
    console.log(props.selectedMap);

    if (props.canvas !== mapInfo.value) {
      const myMap2 = await MapsApi.Update(map);
      console.log(myMap2);
    }

    props.getMaps();
  };

  const onEditHandler = () => {
    updateMapInformation();
    setShow(false);
    setShowToast(true);
    console.log(props.title);
    console.log(props.description);
    setTimeout(() => {
      setShowToast(false);
    }, 4000);
  };

  return (
    <div>
      <ToastContainer className="p-3" position="top-center">
        <Toast show={showToast} onClick={handleCloseToast}>
          <Toast.Header>
            <strong className="me-auto">
              {props.languageChosenSite === "EL"
                ? languageGr.indoor.edit
                : language.indoor.edit}
            </strong>
          </Toast.Header>
          <Toast.Body>
            {props.languageChosenSite === "EL"
              ? languageGr.indoor.map
              : language.indoor.map}
            {props.title}
            {props.languageChosenSite === "EL"
              ? languageGr.indoor.edited
              : language.indoor.edited}
            !
          </Toast.Body>
        </Toast>
      </ToastContainer>

      {props.isClickable === true ? (
        <button className="edit-map-btn" onClick={handleShow}>
          <EditIcon />
        </button>
      ) : (
        <button className="edit-map-btn-disabled" disabled onClick={handleShow}>
          <EditIcon />
        </button>
      )}

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {props.languageChosenSite === "EL"
              ? languageGr.indoor.updateMap
              : language.indoor.updateMap}
            : {props.title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>
                {props.languageChosenSite === "EL"
                  ? languageGr.indoor.mapName
                  : language.indoor.mapName}
              </Form.Label>
              <Form.Control
                className="form-control-add-edit-map"
                type="text"
                placeholder={
                  props.languageChosenSite === "EL"
                    ? languageGr.indoor.enterMapName
                    : language.indoor.enterMapName
                }
                defaultValue={props.title}
                onChange={(e) => onSetTitle(e)}
              />
            </Form.Group>
            <Form.Group controlId="formFile" className="mb-3">
              <Form.Label>
                {props.languageChosenSite === "EL"
                  ? languageGr.indoor.mapDescription
                  : language.indoor.mapDescription}
              </Form.Label>
              <Form.Control
                className="form-control-add-edit-map"
                type="text"
                placeholder={
                  props.languageChosenSite === "EL"
                    ? languageGr.indoor.enterMapDescription
                    : language.indoor.enterMapDescription
                }
                defaultValue={props.description}
                onChange={(e) => onSetDescription(e)}
              />
            </Form.Group>
            <Form.Group controlId="formFile" className="mb-3">
              <Form.Label>
                {props.languageChosenSite === "EL"
                  ? languageGr.indoor.mapLanguage
                  : language.indoor.mapLanguage}
              </Form.Label>
              <Select
                className="custom-react-select"
                isClearable
                options={props.languages}
                value={props.lang}
                placeholder={
                  props.languageChosenSite === "EL"
                    ? languageGr.indoor.chooseLanguage
                    : language.indoor.chooseLanguage
                }
                isSearchable
                onChange={onSetLanguage}
              />
            </Form.Group>
            <Form.Group controlId="formFile" className="mb-3">
              <Form.Label>Image</Form.Label>
              <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="mb-3 image-tabs"
              >
                <Tab eventKey="file" title="File Upload">
                  <ImageFromFile
                    canvas={props.canvas}
                    languageChosenSite={props.languageChosenSite}
                  />
                </Tab>
                <Tab eventKey="url" title="Image URL">
                  <ImageURL
                    canvas={props.canvas}
                    languageChosenSite={props.languageChosenSite}
                  />
                </Tab>
              </Tabs>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <button className="edit-map-btn" onClick={onEditHandler}>
            {props.languageChosenSite === "EL"
              ? languageGr.indoor.edit
              : language.indoor.edit}
          </button>
          <button className="close-modal-button " onClick={handleClose}>
            {props.languageChosenSite === "EL"
              ? languageGr.indoor.close
              : language.indoor.close}
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default EditMap;
