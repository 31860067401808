// @ts-nocheck
import "./mobileEditor.css";
// @ts-ignore
import phoneImg from "../../images/layoutEditor/phoneScreen.png";
import { useState, useEffect, useRef, useMemo } from "react";
import Draggable from "react-draggable";
import EditorItem from "./EditorItems/EditorItem";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider, useDrop } from "react-dnd";
import EditWidget from "./EditorItems/EditWidget";
import { LayoutEditor } from "./layoutEditor";

const layout = new LayoutEditor();

const elements = [
  {
    id: 0,
    position: 0,
    title: "Enter name",
    background: "",
    color: "#F7AA47",
    icon: "",
    size: "medium",
    attribute: "screen",
    route: "",
    objectId: 0,
    screen: false,
    deleted: false,
  },
];

export const MobileEditor = () => {
  return (
    <DndProvider backend={HTML5Backend}>
      <EditorView />
    </DndProvider>
  );
};

function EditorView() {
  const [tiles, setTiles] = useState([]);
  const [selected, setSelected] = useState();
  const editorRef = useRef();

  var [{ isOver }, drop] = useDrop(
    () => ({
      accept: "tile",
      drop: (item) =>
        layout.addTileToScreen(item ? item.id : null, setTiles, tiles),
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
      }), //Optional
    }),
    [tiles]
  );

  var [{ isOver2 }, drop2] = useDrop(
    () => ({
      accept: "tile",
      drop: (item) =>
        layout.returnTileToEditor(item ? item.id : null, setTiles, tiles),
      collect: (monitor) => ({ isOver2: !!monitor.isOver() }), //Optional
    }),
    [tiles]
  );

  const sortedTiles = tiles.sort((a, b) => a.id - b.id);

  return (
    <div className="editor">
      <div className="editorSection">
        <div ref={drop2} className="editorItemContainer">
          <div className="editorDeviceScreen">
            {sortedTiles.map((item, index) => {
              if (!item.screen) {
                return (
                  <EditorItem
                    key={index}
                    item={item}
                    selected={selected}
                    setSelected={setSelected}
                  />
                );
              } else {
                return null;
              }
            })}
          </div>
        </div>
        <div className="editorBtn"
          onClick={() => {
            layout.createElement(tiles, setTiles);
          }}
        >
          Create
        </div>
      </div>
      <div className="editorSection">
        <div ref={drop} id="mobile" className="editorDevice">
          <div className="notch"></div>
          <div className="editorDeviceScreen">
            {sortedTiles.map((item, index) => {
              if (item.screen) {
                return (
                  <EditorItem
                    key={index}
                    item={item}
                    selected={selected}
                    setSelected={setSelected}
                  />
                );
              } else {
                return null;
              }
            })}
          </div>
        </div>
      </div>
      <EditWidget selected={selected} tiles={tiles} setTiles={setTiles} />
    </div>
  );
}
