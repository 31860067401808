import { useState, useEffect, useCallback, useMemo, Fragment } from "react";

import * as FaIcons from "react-icons/fa";
import {
  ObjectApi,
  ExhibitNew,
  SiteApi,
  ExhibitionApi,
} from "../../api/api.service";
// import GoogleMapReact from "google-map-react";
import { GoogleMap, useJsApiLoader, Marker, InfoWindow } from "@react-google-maps/api";
import Swal from "sweetalert2";
import $ from 'jquery';
import manage from "../../images/manage-orange.png";

import { language, languageGr } from "../../api/lang";
import { ImContrast } from "react-icons/im";

const OutdoorMap = () => {
  const [map, setMap] = useState(null);
  const [obj, setObj] = useState({});
  const [exhibitions, setExhibitions] = useState([]);
  const [site, setSite] = useState(1);  
    const [arrayMark, setArrayMark] = useState([]);
  const [markerAdd, setMarkerAdd] = useState({});
  const [centered, setCentered] = useState({});
  const [toggleOpen, setToggleOpen] = useState(0);
  const [languageChosenSite, setLanguageChosenSite] = useState("");
  const [selectedCenter, setSelectedCenter] = useState(null);

  useEffect(() => {
    document.body.className = "";
    
    getData();
  }, []);

  const changeHead = () => {
    if(sessionStorage.getItem('languageChosen') === "EL") {
    document.querySelector(".pageHead").innerHTML =`<div style="width: 100%; height:100%; display: inline-flex; justify-content: center;"><h4 style='padding-top: 3.5vh;text-align: center;color: #f7aa47;'><img style="height: 4vh;margin-top:-1vh;" class='fa fa-fw fa-file' src='${manage}' />${languageGr.global.titles.map}</h4></div>`;
      

    } else {
    document.querySelector(".pageHead").innerHTML =`<div style="width: 100%; height:100%; display: inline-flex; justify-content: center;"><h4 style='padding-top: 3.5vh;text-align: center;color: #f7aa47;'><img style="height: 4vh;margin-top:-1vh;" class='fa fa-fw fa-file' src='${manage}' />${language.global.titles.map}</h4></div>`;
      
      

    }
  }

  const getData = async () => {
    const sites = await SiteApi.Get();
    //setSite(sites[0]);
    console.log(sites[0]);
    setLanguageChosenSite(sessionStorage.getItem("languageChosen"));
    changeHead();
    const exhibitionsArray = await ExhibitionApi.GetBySiteId(sites[0].siteId);
    setExhibitions(exhibitionsArray);
    updateRouteDrop(exhibitionsArray);
    // object = await ExhibitNew.GetAllValuesOfObject(props.targetId);
    // setMarkerAdd({ lat: object.latitude, lng: object.longitude });


    // setObj(object);
    setCentered({ lat: sites[0].latitude, lng: sites[0].longitude }); 
    await initMap(exhibitionsArray);
    // console.log(object);
    // console.log(object);
    // console.log(obj);
  };

  

  async function updateRouteDrop(exhibitions) {
    // document.getElementById("routesDropdown").innerHTML =
    //   `<option value='0'>${languageChosenSite == "EL" ? languageGr.outdoor.all : language.outdoor.all}</option>`;

    for (let route of exhibitions) {
      document.getElementById(
        "routesDropdown"
      ).innerHTML += `<option value="${route.exhibitionId}">${route.name}</option>`;
      console.log(route);
    }
  }

    const onLoad = useCallback(function callback(map) {
        ///const bounds = new window.google.maps.();
        //console.log(bounds);
            map.setZoom(13);

        // map.fitBounds(bounds);
        //new.window.
    setMap(map);
    }, []);

  const onUnmount = useCallback(function callback(map) {
    setMap(null);
  }, []);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyDE3AVYlMuR5mpBqDl_lZLenbmyFhbkwaQ",
  });

  const containerStyle = {
    width: "auto",
    height: "600px",
    "border-radius": "10px"
  };

  
    const onSelectChange = async(e) => {
        setToggleOpen(0);
        const exhibitionId = e.target.value;
        console.log(exhibitionId);
        if (exhibitionId == 0) {
           
            console.log(exhibitions);
            await initMap(exhibitions);

        }
        else {
            let exhibitionsEmpt = [];
            const exhibition = await ExhibitionApi.GetById(exhibitionId);
            exhibitionsEmpt.push(exhibition);
            console.log(exhibitionsEmpt);
           await  initMap(exhibitionsEmpt);
        }
    }

   

    function pinSymbol(color) {
        return {
          path: "M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z M -2,-30 a 2,2 0 1,1 4,0 2,2 0 1,1 -4,0",
          fillColor: color,
          fillOpacity: 1,
          strokeColor: "#000",
          strokeWeight: 2,
          scale: 1,
        };
    }

    const CSS_COLOR_NAMES = [
        "red",
        "yellow",
        "green",
        "blue",
        "purple",
        "brown",
        "orange",
        "cyan",
  
        "BlueViolet",
  
        "pink",
        "CadetBlue",
        "Chartreuse",
        "Chocolate",
        "Coral",
        "CornflowerBlue",
        "Cornsilk",
        "Crimson",
        "Cyan",
        "DarkBlue",
        "DarkCyan",
        "DarkGoldenRod",
        "DarkGray",
        "DarkGrey",
        "DarkGreen",
        "DarkKhaki",
        "DarkMagenta",
        "DarkOliveGreen",
        "DarkOrange",
        "DarkOrchid",
        "DarkRed",
        "DarkSalmon",
        "DarkSeaGreen",
        "DarkSlateBlue",
        "DarkSlateGray",
        "DarkSlateGrey",
        "DarkTurquoise",
        "DarkViolet",
        "DeepPink",
        "DeepSkyBlue",
        "DimGray",
        "DimGrey",
        "DodgerBlue",
        "HoneyDew",
        "HotPink",
        "IndianRed",
        "Indigo",
        "Ivory",
        "Khaki",
        "Lavender",
        "LavenderBlush",
        "LawnGreen"
      ];

      const onMarkerClick = (id) => {
        setToggleOpen(id);
    }

  async function initMap(exhibitions) {
    
    let arrayMarker = [];

    console.log(exhibitions);


    for (let exhib of exhibitions) {
      let changedColor = CSS_COLOR_NAMES[exhib.exhibitionId];

      let objPerExh = await ObjectApi.GetByExhibition(exhib.exhibitionId);
      for (let obj of objPerExh) {
        console.log(obj);
       

  

    
        let marker = {id: obj.objectId.toString().concat(exhib.exhibitionId) , lat: obj.latitude, lng: obj.longitude, label: obj.name, exhibitionId: exhib.exhibitionId, code: obj.code, exhibitionName: exhib.name}
        
      
        
        arrayMarker.push(marker);

      }

    
    console.log(arrayMark);
    console.log(arrayMarker);
    }
    setArrayMark(arrayMarker)

    
  } //init Map ending
   

  return (
    <div className="container-fluid" style={{"margin-top": "4vh"}}>
      <div className="row">
        <div className="col-2">
          <label style={{ color: "black", "font-size": "19px" }}>
          {languageChosenSite == "EL" ? languageGr.outdoor.filter : language.outdoor.filter} : 
          </label>
          <select className="form-control" style={{"text-align": "center"}} id="routesDropdown" defaultValue={0} onChange={onSelectChange}>
            <option value="0">{languageChosenSite == "EL" ? languageGr.outdoor.all : language.outdoor.all}</option>
          </select>
        </div>
        <div className="col-10">
          {isLoaded ? (
            <GoogleMap
                id="mapp"
              mapContainerStyle={containerStyle}
              center={centered}
              defaultZoom={2}
              onLoad={onLoad}
              onUnmount={onUnmount}
            //   onClick={mapClick}
            >
                {arrayMark.length > 0 && arrayMark.map(m => (<Marker key={m.id} position={{lat: m.lat, lng: m.lng}} label={m.label} animation={2} icon={pinSymbol(CSS_COLOR_NAMES[m.exhibitionId])} onClick={() => onMarkerClick(m.id)}>
                { toggleOpen === m.id && <InfoWindow
                onClick={onMarkerClick}    
                position={{lat: m.lat, lng: m.lng}}
                visible={toggleOpen}
                >
            <div style={{"text-align": "center"}} id="content">
                <div id="siteNotice">
                </div>
                <h4 id="firstHeading" className="firstHeading">{m.label}</h4>
            
            
                <div id="bodyContent">
                  <p><b>{languageChosenSite == "EL" ? languageGr.outdoor.exhibition : language.outdoor.exhibition} : </b><div style={{"color": "green"}}>{m.exhibitionName}</div></p>
              
                  <p><b>{languageChosenSite == "EL" ? languageGr.outdoor.serial : language.outdoor.serial}: </b><div style={{"color": "green"}}> {m.code}</div> </p>
                  
              
              
                  <p><b>{languageChosenSite == "EL" ? languageGr.outdoor.long : language.outdoor.long} :</b><div style={{"color": "green"}}>{m.lat}</div></p>
              
                  <p><b>{languageChosenSite == "EL" ? languageGr.outdoor.lat : language.outdoor.lat} :</b><div style={{"color": "green"}}>{m.lng}</div></p>
        
                </div>
            </div>
            </InfoWindow>}
        </Marker>))}
              
              {/* Child components, such as markers, info windows, etc. */}
              {/* <Marker position={markerAdd} label={markerLabel} animation={2} /> */}
            </GoogleMap>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default OutdoorMap;
