import React, { useState, useEffect } from "react";
import { fabric } from "fabric";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { language, languageGr } from "../../../../api/lang";

function Circle(props) {
  const workPoint = new fabric.Circle({
    top: 100,
    left: 150,
    radius: props.wpSize,
    stroke: props.iconColor,
    fill: props.fillColor,
    cornerColor: "black"
  });

  const [selected, setSelected] = useState(false);

  if(props.canvas) {
    props.canvas.on("selection:created", (e) => {
      if(e.selected[0].type === "circle") {
        props.setSquares(false);

        console.log("circle Selection Created");

        setSelected(true);
        props.setIconColor(props.canvas.getActiveObject().stroke);
        props.setFillColor(props.canvas.getActiveObject().fill);
        props.setStrokeWidth(props.canvas.getActiveObject().strokeWidth);
        console.log(document.getElementById("fontSizeDiv"));
        props.canvas.getActiveObject().cornerColor = "black";

        // document.getElementById("fontSizeDiv").style.display = "none";
        // console.log(document.getElementById("fontSizeDiv"));

        // document.getElementById("textExhibit").style.display = "none";
        // document.getElementById("fontFamilyDiv").style.display = "none";
      } else {
        console.log("NOT circle Selection Created");

        setSelected(false);
        // document.getElementById("fontSizeDiv").style.display = "block";
        // document.getElementById("textExhibit").style.display = "block";
        // document.getElementById("fontFamilyDiv").style.display = "block";
      }
      
      //document.getElementById("strokeWidth").style.display = "block";
    });
  }

  if(props.canvas) {
    props.canvas.on("selection:updated", (e) => {
      console.log(e);

      if(e.selected[0].type === "circle") {
        console.log("circle Selection Updated");
        props.setSquares(false);

        setSelected(true);
        props.setIconColor(props.canvas.getActiveObject().stroke);
        props.canvas.getActiveObject().cornerColor = "black";

        props.setFillColor(props.canvas.getActiveObject().fill);
        props.setStrokeWidth(props.canvas.getActiveObject().strokeWidth);
        // document.getElementById("fontSizeDiv").style.display = "none";
        // document.getElementById("textExhibit").style.display = "none";
        
        // document.getElementById("fontFamilyDiv").style.display = "none";
      } else {
        console.log("NOT circle Selection Updated");

        setSelected(false);
        // document.getElementById("fontSizeDiv").style.display = "block";
        // document.getElementById("textExhibit").style.display = "block";
        // document.getElementById("fontFamilyDiv").style.display = "block";
      }
    });
  }

  useEffect(() => {
    if (selected) {
      props.canvas.getActiveObject().set("stroke", props.iconColor);
      props.canvas.requestRenderAll();

    }
  }, [props.iconColor]);

  useEffect(() => {
    if (selected) {
      props.canvas.getActiveObject().set("fill", props.fillColor);
      props.canvas.requestRenderAll();

    }
  }, [ props.fillColor]);

  useEffect(() => {
    if (selected) {
      props.canvas.getActiveObject().set("strokeWidth", props.strokeWidth);
      props.canvas.requestRenderAll();

    }
  }, [ props.strokeWidth]);

  const onAddCircle = () => {
    props.canvas.add(workPoint);
  };

 

  workPoint.on("selected", () => {
    props.setSquares(false);

    setSelected(true);
    props.setIconColor(props.canvas.getActiveObject().stroke);
    props.setFillColor(props.canvas.getActiveObject().fill);
    props.canvas.getActiveObject().cornerColor = "black";

    props.setStrokeWidth(props.canvas.getActiveObject().strokeWidth);
    // document.getElementById("fontSizeDiv").style.display = "none";
    // document.getElementById("textExhibit").style.display = "none";
    // document.getElementById("fontFamilyDiv").style.display = "none";

    


  });
  workPoint.on("deselected", () => {
    setSelected(false);
    // document.getElementById("fontSizeDiv").style.display = "flex";
    // document.getElementById("textExhibit").style.display = "flex";
    // document.getElementById("fontFamilyDiv").style.display = "flex";

     
  });

  return (
    <button
      className="mapPropertiesItem"
      
      onClick={onAddCircle}
    >
      <RadioButtonUncheckedIcon />
      {props.languageChosenSite === "EL"
        ? languageGr.indoor.circle
        : language.indoor.circle}
    </button>
  );
}

export default Circle;
