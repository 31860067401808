import { useState, useEffect } from "react";

import { fabric } from "fabric";
import { Button, Form } from "react-bootstrap";
import { language, languageGr } from "../../../../api/lang";


import Draggable from "react-draggable";
import RoomIcon from "@mui/icons-material/Room";
import StairsIcon from "@mui/icons-material/Stairs";
import WcIcon from "@mui/icons-material/Wc";
import StorefrontIcon from "@mui/icons-material/Storefront";
import FireExtinguisherIcon from "@mui/icons-material/FireExtinguisher";
import { faBullseye } from "@fortawesome/free-solid-svg-icons";



function Paint(props) {
  const [drawMode, setDrawMode] = useState(false);
  const [selected, setSelected] = useState(false);

  if(props.canvas) {
    props.canvas.on("selection:created", (e) => {
      
      if(e.selected[0].strokeLineCap === 'round') {
       props.setSquares(false);

        setSelected(true);
        console.log("canvas sel");
        console.log(e);
        console.log("selection created");
        props.setIconColor(props.canvas.getActiveObject().stroke);
        props.setFillColor(props.canvas.getActiveObject().fill);
        props.setFontSize(props.canvas.getActiveObject().fontSize);
        props.setExhibitText(props.canvas.getActiveObject().text);
        props.setFontFamily(props.canvas.getActiveObject().fontFamily);
        props.canvas.getActiveObject().cornerColor = "black";

        // document.getElementById("fontSizeDiv").style.display = "flex";
        // document.getElementById("textExhibit").style.display = "flex";
        // document.getElementById("fillColorDiv").style.display = "flex";
        // document.getElementById("fontFamilyDiv").style.display = "flex";
        
      } else {
        setSelected(false);
        // document.getElementById("fontSizeDiv").style.display = "none";
        // document.getElementById("textExhibit").style.display = "none";
        // document.getElementById("fillColorDiv").style.display = "none";
        // document.getElementById("fontFamilyDiv").style.display = "none";
      }
     
      //document.getElementById("strokeWidth").style.display = "block";
    });
  }

  if(props.canvas) {
    console.log(props.canvas.getActiveObject());
    props.canvas.on("selection:updated", (e) => {
    

      if(e.selected[0].strokeLineCap === 'round') {
        props.setSquares(false);
        console.log(e.selected[0]);
        console.log("selection updated");

        setSelected(true);
        console.log("canvas sel");
        console.log(e);
        props.setIconColor(props.canvas.getActiveObject().stroke);
        //props.setFillColor(props.canvas.getActiveObject().fill);
      
        props.canvas.getActiveObject().cornerColor = "black";

        // document.getElementById("fontSizeDiv").style.display = "flex";
        // document.getElementById("textExhibit").style.display = "flex";
        // document.getElementById("fillColorDiv").style.display = "flex";
        // document.getElementById("fontFamilyDiv").style.display = "flex";
       
      } else {
        setSelected(false);
        // document.getElementById("fontSizeDiv").style.display = "none";
        // document.getElementById("textExhibit").style.display = "none";
        // document.getElementById("fillColorDiv").style.display = "none";
        // document.getElementById("fontFamilyDiv").style.display = "none";
      }
    });
  }

  useEffect(() => {
    if (selected) {
      props.canvas.getActiveObject().set("stroke", props.iconColor);
      // props.canvas.getActiveObject().set("fontSize", props.fontSize);
      props.canvas.requestRenderAll();
      //props.canvas.freeDrawingBrush = props.iconColor;
        
    }
  }, [ props.iconColor]);

  useEffect(() => {
    if (selected) {
      props.canvas.getActiveObject().set("fill", props.fillColor);
      // props.canvas.getActiveObject().set("fontSize", props.fontSize);
      props.canvas.requestRenderAll();

    }
  }, [ props.fillColor]);

 

//   useEffect(() => {
//     if (selected) {
      
      
//       props.canvas.getActiveObject().set("text", props.exhibitText);
//       props.canvas.requestRenderAll();
    
//       // let x = props.editorMemo.getActiveObject()
//       // console.log("X = ",x._objects[0])
//     }
//   }, [ props.exhibitText]);



  const ToggleDrawMode = (e) => {
    if(e.target.checked) {
        console.log("checked");
      setDrawMode(true)
      props.canvas.isDrawingMode = !props.canvas.isDrawingMode;
      console.log(props.canvas.isDrawingMode);


    } else {
      setDrawMode(false)
      props.canvas.isDrawingMode = !props.canvas.isDrawingMode;
      console.log(props.canvas.isDrawingMode);


    }
  }

  

//   const changeModeSelection = (e) => {
//     console.log(e.target.value);
//     console.log(props.canvas);
//     if(e.target.value === "Circle") {
       
//         //props.setCanvas(fabric.CircleBrush);
//         props.setCanvas( new fabric.Canvas[e.target.value + 'Brush']( props.canvas));
        
//     }

    
        
//         // props.setCanvas(canv => ({...canv, freeDrawingBrush: new fabric[e.target.value + 'Brush']( props.canvas)}));
    

//     //   if (props.canvas.freeDrawingBrush) {
//     //     var brush =  props.canvas.freeDrawingBrush;
//     //     // brush.color = drawingColorEl.value;
//     //     if (brush.getPatternSrc) {
//     //       brush.source = brush.getPatternSrc.call(brush);
//     //     }
//     //     // brush.width = parseInt(drawingLineWidthEl.value, 10) || 1;
        
//     //   }

//   }


  if (fabric.PatternBrush) {
    let vLinePatternBrush = new fabric.PatternBrush(props.canvas);
    vLinePatternBrush.getPatternSrc = function() {

      let patternCanvas = fabric.document.createElement('canvas');
      patternCanvas.width = patternCanvas.height = 10;
      let ctx = patternCanvas.getContext('2d');

      ctx.strokeStyle = this.color;
      ctx.lineWidth = 5;
      ctx.beginPath();
      ctx.moveTo(0, 5);
      ctx.lineTo(10, 5);
      ctx.closePath();
      ctx.stroke();

      return patternCanvas;
    };

    let hLinePatternBrush = new fabric.PatternBrush(props.canvas);
    hLinePatternBrush.getPatternSrc = function() {

      let patternCanvas = fabric.document.createElement('canvas');
      patternCanvas.width = patternCanvas.height = 10;
      let ctx = patternCanvas.getContext('2d');

      ctx.strokeStyle = this.color;
      ctx.lineWidth = 5;
      ctx.beginPath();
      ctx.moveTo(5, 0);
      ctx.lineTo(5, 10);
      ctx.closePath();
      ctx.stroke();

      return patternCanvas;
    };

   
  }

//   if (props.canvas.freeDrawingBrush) {
//     props.canvas.freeDrawingBrush.color = props.iconColor;
    
   
//   }

 

  
  // let act = props.canvas.getActiveObject();
  // console.log(act)

  return (
   
    
    <div id="drawing-mode-options" style={{marginTop: "5%", textAlign: "center"}}>
        <div className="drawModeToggler">
            <label className="form-check-label">{props.languageChosenSite === "EL"
              ? languageGr.indoor.drawMode
              : language.indoor.drawMode}</label>
            <div className="form-check form-switch">
                <input className="form-check-input" onClick={(e) => ToggleDrawMode(e)} type="checkbox" id="flexSwitchCheckChecked" />
                
            </div>
            {/* <button className="btn btn-success" style={{display: !drawMode ? "block" : "none"}} onClick={drawingMode}>Draw Mode</button>
            <button className="btn btn-warning" style={{display: drawMode ? "block" : "none"}} onClick={() => setDrawMode(!drawMode)}>Exit Draw Mode</button> */}
        </div>
      {/* <label for="drawing-mode-selector" >Mode:</label>
      <select className="form-control" id="drawing-mode-selector" style={{textAlign: "center"}} onChange={(e) => changeModeSelection(e)}>
        <option value="pencil">Pencil</option>
        <option value="Circle">Circle</option>
        <option value="Spray">Spray</option>
 
      </select> */}
    </div>  
  

  );
}

export default Paint;
