import React from 'react'
import { Container, Carousel, Image } from 'react-bootstrap';
import "./Banner.css"

function Banner() {
    return (
        <div className='banner-container'>
            <Carousel>
                <Carousel.Item>
                    <Image src="https://images.unsplash.com/photo-1496889196885-5ddcec5eef4d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80" />
                </Carousel.Item>
                <Carousel.Item>
                    <Image src="https://images.unsplash.com/photo-1583306346437-f2143c0f11fc?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1171&q=80" />
                </Carousel.Item><Carousel.Item>
                    <Image src="https://images.unsplash.com/photo-1518998053901-5348d3961a04?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80" />
                </Carousel.Item>
            </Carousel>
        </div>
    )
}

export default Banner