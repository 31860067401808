import React, { useState } from 'react'
import MediaModal from '../MediaModal/MediaModal';
import { Row, Col } from "react-bootstrap";

import "./media.css"

export function ImageMedia(props) {
    const [clickedImg, setClickedImg] = useState(null);
    const [currentIndex, setCurrentIndex] = useState(null);

    const handleClick = (item, index) => {
        setCurrentIndex(index);
        setClickedImg(item.value);
    };

    const handelRotationRight = () => {
        const totalLength = props.galleryImages.length;

        if (currentIndex + 1 >= totalLength) {
            setCurrentIndex(0);
            const newUrl = props.galleryImages[0].value;
            setClickedImg(newUrl);
            return;
        }

        const newIndex = currentIndex + 1;
        const newUrl = props.galleryImages.filter((item) => {
            return props.galleryImages.indexOf(item) === newIndex;
        });

        const newItem = newUrl[0].value;
        setClickedImg(newItem);
        setCurrentIndex(newIndex);
    };

    const handelRotationLeft = () => {
        const totalLength = props.galleryImages.length;

        if (currentIndex === 0) {
            setCurrentIndex(totalLength - 1);
            const newUrl = props.galleryImages[totalLength - 1].value;
            setClickedImg(newUrl);
            return;
        }

        const newIndex = currentIndex - 1;
        const newUrl = props.galleryImages.filter((item) => {
            return props.galleryImages.indexOf(item) === newIndex;
        });

        const newItem = newUrl[0].value;
        setClickedImg(newItem);
        setCurrentIndex(newIndex);
    };

    return (
        <div className="media-gallery">
            <Row xs={2} sm={2} md={4} className="g-3">
                {props.galleryImages.map((item, index) => (
                    <Col key={index} >
                        <div key={index} className="media-gallery-items">
                            <img
                                src={item.value && `data:${item.type == "" || item.type == null  ? "image/png" : item.type};base64, ` + item.value}
                                alt={item.description}
                                onClick={() => {
                                    handleClick(item, index)
                                }}
                            />
                            <div onClick={() => {
                                handleClick(item, index)
                            }}>
                                <p className='fs-6 text-muted text-center'>{item.name}</p>
                            </div>
                        </div>
                    </Col>
                ))}
            </Row>
            <div>
                {clickedImg && (
                    <MediaModal
                        clickedImg={clickedImg}
                        handelRotationRight={handelRotationRight}
                        setClickedImg={setClickedImg}
                        handelRotationLeft={handelRotationLeft}
                    />
                )}
            </div>
        </div>
    )
}

export function VideoMedia(props) {
    return (
        <Row xs={1} sm={2} md={2}>
            {props.video.map((item, index) => (
                <Col key={index} >
                    <div key={index}>
                        <video width="1280" height="720" controls>
                            <source
                                src={item.value && `data:${item.type == "" || item.type == null ? "video/mp4" : item.type};base64, ` + item.value}
                                type={item.type}
                            />
                            {console.log(item)}
                        </video>
                        <div>
                            <p className='fs-6 text-muted text-center'>{item.name}</p>
                        </div>
                    </div>
                </Col>
            ))}
        </Row>
    )
}

export function AudioMedia(props) {
    return (
        <Row xs={1} sm={1} md={2} lg={3}>
            {props.audio.map((item, index) => (
                <Col key={index} className="audio-media px-1">
                    <div key={index}>
                        <audio controls>
                            <source src={item.value && `data:${item.type == "" || item.type == null ? "audio/mp3" : item.type};base64, ` + item.value} type={item.type} />
                            {console.log(item)}
                        </audio>
                        <div>
                            <p className='fs-6 text-muted text-center'>{item.name}</p>
                        </div>
                    </div>
                </Col>
            ))}
        </Row>
    )
}