import React, { useState, useEffect, Fragment } from "react";

// API
import { ExhibitionApi, SiteApi, LanguageApi, MediaApi } from "../../api/api.service";
import { language, languageGr } from "../../api/lang";

// Components
import CardSkeleton from "../UI/CardSkeleton";
import TableGeneral from "../UI/TableGeneral";
import ExhibitionModal from "../UI/ExhibitionModal";
import BundledEditor from "../../BundledEditor";

//Authservice
import { authService } from "../../api/auth.service";

// Icons
import * as FaIcons from "react-icons/fa";
import manage from "../../images/manage-orange.png";

// Swal Alert
import Swal from "sweetalert2";

// Bootstrap
import { Button, Form, Modal } from "react-bootstrap";
import { textFilter } from "react-bootstrap-table2-filter";

const Exhibitions = () => {
  const [exhibitions, setExhibitions] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [modalShow, setModalShow] = useState(false);
  const [idClicked, setIdClicked] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const [addExhibition, setAddExhibition] = useState(false);
  const [addTitleChange, setAddTitleChange] = useState("");
  const [addDescriptionChange, setAddDescriptionChange] = useState("");
  const [tableChanged, setTableChanged] = useState(false);
  const [languages, setLanguages] = useState([]);
  const [languageChosenSite, setLanguageChosenSite] = useState("");
  const [userRole, setUserRole] = useState("");
  const [site, setSite] = useState([]);

  useEffect(() => {
    document.body.className = "";
    console.log("useef");
    getData();
    changeHead();
  }, [tableChanged]);

  const handleHide = () => {
    setModalShow(false);
  };

  const fetchUser = async () => {
    const user = await authService.getUserAsync();

    setUserRole(user.claims[0].value);
  };

  function uploadPhoto() {
    let image = document.getElementById("usingImage");
    let canvas = document.createElement("canvas"),
      ctx = canvas.getContext("2d");
    canvas.height = image.naturalHeight;
    canvas.width = image.naturalWidth;
    ctx.drawImage(image, 0, 0);
    let uri = canvas.toDataURL("image/png"),
      b64 = uri.replace(/^data:image.+;base64,/, "");
    document.getElementById("imageUpdateStringAdd").innerText = b64;
  }

  const getData = async () => {
    const siteArray = await SiteApi.Get();
    setSite(siteArray);
    const exhibitionsArray = await ExhibitionApi.GetBySiteId(
      siteArray[0].siteId
    );
    fetchUser();
    const languageArray = await LanguageApi.Get();
    setLanguages(languageArray);
    setLanguageChosenSite(sessionStorage.getItem("languageChosen"));

    setExhibitions(exhibitionsArray);
    setLoading(false);
    console.log(exhibitionsArray);
    //document.querySelector(".pageHead").innerHTML = `<h4 style='padding-top: 2%;text-align: center;color: #f7aa47;margin-left: -39vw;'><img style="height: 4vh;" class='fa fa-fw fa-file' src='${manage}' />${language.global.titles.exhibition}</h4>`;
  };

  const changeHead = () => {
    if (sessionStorage.getItem("languageChosen") === "EL") {
      document.querySelector(".pageHead").innerHTML =`<div style="width: 100%; height:100%; display: inline-flex; justify-content: center;"><h4 style='padding-top: 3.5vh;text-align: center;color: #f7aa47;'><img style="height: 4vh;margin-top:-1vh;" class='fa fa-fw fa-file' src='${manage}' />${languageGr.global.titles.exhibition}</h4></div>`;
    } else {
      document.querySelector(".pageHead").innerHTML =`<div style="width: 100%; height:100%; display: inline-flex; justify-content: center;"><h4 style='padding-top: 3.5vh;text-align: center;color: #f7aa47;'><img style="height: 4vh;margin-top:-1vh;" class='fa fa-fw fa-file' src='${manage}' />${language.global.titles.exhibition}</h4></div>`;
    }
  };

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      console.log(e);
      console.log({ row, rowIndex });
    },
  };

  const edit = (cell, row, rowIndex, formatExtraData) => {
    return <FaIcons.FaRegEdit onClick={() => editClick(row)} className="editIcon" Edit />;
  };

  const editClick = (row, e) => {
    let targ =
      row.exhibitionId;
    console.log(targ);
    setModalShow(true);
    setIdClicked(targ);
    console.log(modalShow);
  };

  const del = (cell, row, rowIndex, formatExtraData) => {
    return (
      <FaIcons.FaRegTrashAlt
        onClick={() => deleteClick(row)}
        className="deleteIcon"
        Delete
      />
    );
  };

  const deleteClick = async (row) => {
    if (userRole == "Viewer") {
      Swal.fire({
        title: languageChosenSite == "EL" ? languageGr.media.invalidAction : language.media.invalidAction,
        text: languageChosenSite == "EL" ? languageGr.global.userRole : language.global.userRole,
        icon: "warning",
        button: "OK",
      });
      return;
    }
    //console.log(e);

    let tar = row.exhibitionId;
    console.log(tar);

    setIdClicked(tar);
    setDeleteId(tar);
    // let tarObj = row.name
    // console.log(tarObj);
    let tarName = row.name;

    Swal.fire({
      title: "Are you sure?",
      html: `You are about to delete the <strong>${tarName}</strong> Exhibition`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const deleted = await ExhibitionApi.ExhibitionDelete(parseInt(tar));
        setTableChanged(!tableChanged);
        setTableChanged(!tableChanged);
      }
    });
  };

  const AddExhibition = () => {
    setAddExhibition(true);
  };

  const onReturnFromAdd = () => {
    setAddExhibition(false);
  };

  const handleClose = () => {
    setAddExhibition(false);
  };

  const onUploadClick = (e) => {
    console.log("cl;ickcick");
    if (userRole == "Viewer") {
      Swal.fire({
        title: language.media.invalidAction,
        text: language.global.userRole,
        icon: "warning",
        button: "OK",
      });
      return;
    }
    e.preventDefault();

    document.getElementById("file-input").click();
  };

  const onFileInputChange = (e) => {
    console.log("blabla");

    for (let i = 0; i < e.target.files.length; i++) {
      let file = e.target.files[i];

      if (file.size > 1000000) {
        alert(
          `Media with name ${file.name} is not loaded. Media size exceeds the size limit. Please load images with maximum size of 1Mb`
        );
        continue;
      } else {
        let img = document.getElementById("usingImage");
        let reader = new FileReader();
        console.log(reader);
        reader.onloadend = function () {
          img.src = reader.result;
        };
        reader.readAsDataURL(file);
      }
    }
  };

  const addExhibitionToSite = async () => {
    if (userRole == "Viewer") {
      Swal.fire({
        title: languageChosenSite == "EL" ? languageGr.media.invalidAction : language.media.invalidAction,
        text: languageChosenSite == "EL" ? languageGr.global.userRole : language.global.userRole,
        icon: "warning",
        button: "OK",
      });
      return;
    }

    if (addTitleChange === "") {
      Swal.fire({
        title: "Something went wrong",
        text: `Please enter a title`,
        icon: "warning",
      });
    } else {
      const sites = await SiteApi.Get();
      let name = addTitleChange;
      name = name.trim();
      let description = addDescriptionChange;
      description = description.trim();
      // const exhibition = {
      //   Name: name,
      //   Description: description,
      //   translations: [],
      // };
      const english = languages.find((l) => l.initials === "EN");

     

      uploadPhoto();
      let image = document.getElementById("imageUpdateStringAdd").innerText;

      const media = {
        Name: name,
        Description: "",
        TypeId: 10,
        LanguageId: parseInt(english.languageId),
        Value: image,
        ObjectValId: 0,
        RefPath: name,
      };
      let exhibitionNew;
      const mediaCreated = await MediaApi.Create(media);
      console.log(mediaCreated);
      if (mediaCreated) {
        console.log("done");

        exhibitionNew = {
          name: name,
          description: description,
     
          image_def: mediaCreated.mediA_ID ? mediaCreated.mediA_ID : 1,
         
         
          translations: [],
        };
      } else {
        exhibitionNew = {
          name: name,
          description: description,
        
          image_def: 1,
      
       
          translations: [],
        };
      }

      exhibitionNew.translations.push({
        LanguageId: parseInt(english.languageId),
        Name: name,
        Description: description,
      });
      console.log(exhibitionNew);

      const insertedExhibition = await ExhibitionApi.Create(exhibitionNew);
      const result = await ExhibitionApi.AddToSite(
        insertedExhibition.exhibitionId,
        sites[0].siteId
      );
      setAddTitleChange("");
      setAddDescriptionChange("");
      setTableChanged(!tableChanged);
      setTableChanged(!tableChanged);
      document.getElementById("usingImage").src = "";

      handleClose();
    }
  };

  const descriptionFormat = (d) => {
    if (d != null) {
      return d.replace(/<[^>]+>/g, "").substring(0, 50) + " ....";
    }
  };

  const exhibitionHeaders = [
    // {
    //   dataField: "exhibitionId",
    //   text: "Id",
    //   sort: true,
    // },
    {
      dataField: "name",
      text: languageChosenSite === "EL"
      ? languageGr.table.title
      : language.table.title,
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: "description",
      text: languageChosenSite === "EL"
      ? languageGr.table.description
      : language.table.description,
      sort: true,
      formatter: descriptionFormat,
    },
    {
      dataField: "edit",
      text: languageChosenSite === "EL"
      ? languageGr.table.edit
      : language.table.edit,
      formatter: edit,
    },
    {
      dataField: "delete",
      text: languageChosenSite === "EL"
      ? languageGr.table.delete
      : language.table.delete,
      formatter: del,
    },
  ];

  const reloadTable = () => {
    setTableChanged(!tableChanged);
  };

  return (
    <Fragment>
      <div>
        {modalShow && (
          <ExhibitionModal
            onHide={handleHide}
            show={modalShow}
            targetId={idClicked}
            languages={languages}
            rowEvents={rowEvents}
            data={exhibitions}
            getData={getData}
            setTableChanged={reloadTable}
            userRole={userRole}
            setTableChangedtest={setTableChanged}
          ></ExhibitionModal>
        )}
        {isLoading ? (
          <div className="col-7 offset-3">
            <CardSkeleton />
          </div>
        ) : (
          <TableGeneral
            responsive
            data={exhibitions}
            rowEvents={rowEvents}
            columns={exhibitionHeaders}
          />
        )}
        <Button
          className="addNewButton"
          variant="addNewButton"
          onClick={AddExhibition}
        >
          {languageChosenSite === "EL"
            ? languageGr.global.word.addNew
            : language.global.word.addNew}
        </Button>
      </div>

      <Modal show={addExhibition} onHide={handleClose} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>
            {languageChosenSite === "EL"
              ? languageGr.global.exhibitionWords.add
              : language.global.exhibitionWords.add}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>

            <div className="text-center">
              <img
                id="usingImage"
                //onHover={hoverButtons}
                loading="lazy"
                style={{ "max-height": "200px" }}
                className="my-1 rounded"
              />
              <br />
              <Button
                variant="success"
                id="selectPhoto"
                className="addNewButton"
                onClick={onUploadClick}
              >
                {languageChosenSite === "EL"
                  ? languageGr.global.categoryWords.uploadImage
                  : language.global.categoryWords.uploadImage}
              </Button>
              <input
                id="file-input"
                type="file"
                onChange={onFileInputChange}
                name="name"
                style={{ display: "none" }}
              />
            </div>


            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>
                {languageChosenSite === "EL"
                  ? languageGr.global.transName
                  : language.global.transName}
              </Form.Label>
              <Form.Control
                type="text"
                placeholder={
                  languageChosenSite === "EN"
                    ? language.global.enterTitle
                    : languageGr.global.enterTitle
                }
                autoFocus
                onChange={(e) => setAddTitleChange(e.target.value)}
                value={addTitleChange}
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>
                {languageChosenSite === "EL"
                  ? languageGr.global.transDesc
                  : language.global.transDesc}
              </Form.Label>
              <BundledEditor
                init={{
                  height: 400,
                  selector: "textarea",
                  plugins:
                    "advlist anchor autolink help image link lists searchreplace table wordcount emoticons insertdatetime code",
                  toolbar:
                    "undo redo blocks image link media numlist bullist insertdatetime|",
                  content_style:
                    "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                }}
                onEditorChange={(newD) => setAddDescriptionChange(newD)}
              />
            </Form.Group>
            <input
                  id="imageUpdateStringAdd"
                  type="text"
                  name="name"
                  style={{ display: "none" }}
                />
          </Form>
        </Modal.Body>
        <Modal.Footer className="modal_footer">
          <Button
            className="addNewButton"
            variant="addNewButton"
            onClick={addExhibitionToSite}
          >
            {languageChosenSite === "EL"
              ? languageGr.content.clearSelectedLabel.value
              : language.content.clearSelectedLabel.value}
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default Exhibitions;
