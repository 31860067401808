import React from "react";

//API
import { MapsApi, SiteApi } from "../../../../api/api.service";
import { language, languageGr } from "../../../../api/lang";

// Icons
import { Save } from "@mui/icons-material";

import Swal from "sweetalert2";

// CSS
import "../../mapConfig.css";

function SaveJSON(props) {
  const update = async () => {
    console.log("props.selectedMapValues");
    console.log(props.selectedMapValues);

    if (props.mapId !== null) {
      const mapToJSON = JSON.stringify(props.canvas.toJSON());

      updateMapJSONValue(props.selectedMapValues, mapToJSON);

      Swal.fire(
        `${
          props.languageChosenSite === "EL"
            ? languageGr.indoor.saveChanges
            : language.indoor.saveChanges
        }`,
        "",
        "success"
      );
    }
  };

  const updateMapJSONValue = async (mapInfo, mapToJSON) => {
    console.log("Update map JSON value.");

    const map = {
      MapId: props.mapId,
      Value: mapToJSON,
      translations: [
        {
          translationId: mapInfo.translationId,
          Id: props.mapId,
          Name: props.title !== mapInfo.name ? props.title : mapInfo.name,
          Description:
            props.description !== mapInfo.description
              ? props.description
              : mapInfo.description,
          LanguageId: props.lang.value,
        },
      ],
    };

    var myMap = await MapsApi.Update(map);
    console.log(myMap);
    props.getMaps();
  };

  return (
    <>
      {props.isClickable === true ? (
        <button className="save-update-button" onClick={update}>
          <Save /><br/>
          {props.saveText}
        </button>
      ) : (
        <button className="save-update-button-disabled" disabled>
          <Save /><br/>
          {props.saveText}
        </button>
      )}
    </>
  );
}

export default SaveJSON;
