import React from "react";
import { MapsApi } from "../../../../api/api.service";
import { language, languageGr } from "../../../../api/lang";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";

function DeleteMap(props) {
  const onDeleteMap = () => {
    Swal.fire({
      icon: "warning",
      title: `${
        props.languageChosenSite === "EL"
          ? languageGr.indoor.deleteMessage
          : language.indoor.deleteMessage
      }: ${props.title}`,
      showDenyButton: true,
      showCancelButton: true,
      showConfirmButton: false,
      cancelButtonText: `${
        props.languageChosenSite === "EL"
          ? languageGr.indoor.cancel
          : language.indoor.cancel
      }`,
      denyButtonText: `${
        props.languageChosenSite === "EL"
          ? languageGr.indoor.delete
          : language.indoor.delete
      }`,
    }).then((result) => {
      if (result.isDenied) {
        deleteMapFromDb();
        Swal.fire(
          `${props.title} ${
            props.languageChosenSite === "EL"
              ? languageGr.indoor.deleteMessageConfirmed
              : language.indoor.deleteMessageConfirmed
          }`,
          "",
          "success"
        );
      }
    });
  };

  const deleteMapFromDb = async () => {
    const deleted = await MapsApi.Delete(props.mapId);
    console.log(deleted);
    props.getMaps();

    props.canvas.clear();
    props.canvas.setBackgroundColor("rgba(236, 236, 236, 0.653)");
    props.setSelectedMap(null);
    props.setMapId(null);
    props.setIsClickable(false);
  };

  return (
    <>
      {props.isClickable === true ? (
        <button className="delete-map-btn" onClick={onDeleteMap}>
          <DeleteIcon />
        </button>
      ) : (
        <button
          className="delete-map-btn-disabled"
          disabled
          onClick={onDeleteMap}
        >
          <DeleteIcon />
        </button>
      )}
    </>
  );
}

export default DeleteMap;
