import { useState, useEffect } from "react";

import { fabric } from "fabric";
import { Button, Form } from "react-bootstrap";
import { language, languageGr } from "../../../../api/lang";


import Draggable from "react-draggable";
import RoomIcon from "@mui/icons-material/Room";
import StairsIcon from "@mui/icons-material/Stairs";
import WcIcon from "@mui/icons-material/Wc";
import StorefrontIcon from "@mui/icons-material/Storefront";
import FireExtinguisherIcon from "@mui/icons-material/FireExtinguisher";

function Text(props) {
  const [text, setText] = useState("");
  const [selected, setSelected] = useState(false);

  if(props.canvas) {
    props.canvas.on("selection:created", (e) => {
      if(e.selected[0].text) {
       props.setSquares(true);

        setSelected(true);
        console.log("canvas sel");
        console.log(e);
        props.setIconColor(props.canvas.getActiveObject().stroke);
        props.setFillColor(props.canvas.getActiveObject().fill);
        props.setFontSize(props.canvas.getActiveObject().fontSize);
        props.setExhibitText(props.canvas.getActiveObject().text);
        props.setFontFamily(props.canvas.getActiveObject().fontFamily);
        props.canvas.getActiveObject().cornerColor = "black";

        // document.getElementById("fontSizeDiv").style.display = "flex";
        // document.getElementById("textExhibit").style.display = "flex";
        // document.getElementById("fillColorDiv").style.display = "flex";
        // document.getElementById("fontFamilyDiv").style.display = "flex";
        
      } else {
        setSelected(false);
        // document.getElementById("fontSizeDiv").style.display = "none";
        // document.getElementById("textExhibit").style.display = "none";
        // document.getElementById("fillColorDiv").style.display = "none";
        // document.getElementById("fontFamilyDiv").style.display = "none";
      }
     
      //document.getElementById("strokeWidth").style.display = "block";
    });
  }

  if(props.canvas) {
    props.canvas.on("selection:updated", (e) => {
      if(e.selected[0].text) {
        props.setSquares(true);

        setSelected(true);
        console.log("canvas sel");
        console.log(e);
        props.setIconColor(props.canvas.getActiveObject().stroke);
        props.setFillColor(props.canvas.getActiveObject().fill);
        props.setFontSize(props.canvas.getActiveObject().fontSize);
        props.setExhibitText(props.canvas.getActiveObject().text);
        props.setFontFamily(props.canvas.getActiveObject().fontFamily);
        props.canvas.getActiveObject().cornerColor = "black";

        // document.getElementById("fontSizeDiv").style.display = "flex";
        // document.getElementById("textExhibit").style.display = "flex";
        // document.getElementById("fillColorDiv").style.display = "flex";
        // document.getElementById("fontFamilyDiv").style.display = "flex";
       
      } else {
        setSelected(false);
        // document.getElementById("fontSizeDiv").style.display = "none";
        // document.getElementById("textExhibit").style.display = "none";
        // document.getElementById("fillColorDiv").style.display = "none";
        // document.getElementById("fontFamilyDiv").style.display = "none";
      }
    });
  }

  useEffect(() => {
    if (selected) {
      props.canvas.getActiveObject().set("stroke", props.iconColor);
      // props.canvas.getActiveObject().set("fontSize", props.fontSize);
      props.canvas.requestRenderAll();

    }
  }, [ props.iconColor]);

  useEffect(() => {
    if (selected) {
      props.canvas.getActiveObject().set("fill", props.fillColor);
      // props.canvas.getActiveObject().set("fontSize", props.fontSize);
      props.canvas.requestRenderAll();

    }
  }, [ props.fillColor]);

  useEffect(() => {
    if (selected) {
  
      props.canvas.getActiveObject().set("fontSize", props.fontSize);
      props.canvas.requestRenderAll();
    

    }
  }, [ props.fontSize]);

  useEffect(() => {
    if (selected) {
  
      props.canvas.getActiveObject().set("fontFamily", props.fontFamily);
      props.canvas.requestRenderAll();
    

    }
  }, [ props.fontFamily]);

  useEffect(() => {
    if (selected) {
      
      
      props.canvas.getActiveObject().set("text", props.exhibitText);
      props.canvas.requestRenderAll();
    
      // let x = props.editorMemo.getActiveObject()
      // console.log("X = ",x._objects[0])
    }
  }, [ props.exhibitText]);


  const workPoint = new fabric.Text(text,  { left: 100, top: 100, stroke: props.iconColor, fill: props.fillColor, cornerColor: "black" } );

  const onAddText = () => {
    if(text === "") {
      return;
    }
    props.canvas.add(workPoint);
    
    setText("");
  };

  

  workPoint.on("selected", () => {
    props.setSquares(true);

    setSelected(true);
    props.setIconColor(props.canvas.getActiveObject().stroke);
    props.setFillColor(props.canvas.getActiveObject().fill);
    props.setFontSize(props.canvas.getActiveObject().fontSize);
    props.setExhibitText(props.canvas.getActiveObject().text);
    props.setFontFamily(props.canvas.getActiveObject().fontFamily);
    //document.getElementById("strokeWidth").style.display = "none";

    console.log(props.canvas.getActiveObject());

  });
  workPoint.on("deselected", () => {
    setSelected(false);
    //document.getElementById("strokeWidth").style.display = "block";

  });

  
  // let act = props.canvas.getActiveObject();
  // console.log(act)

  return (
    <div>
      <Form.Control
        className="px-2 my-1 add-text-form"
        size="lg"
        type="text"
        placeholder="Add text"
        value={text}
        onChange={(e) => setText(e.target.value)}
      />
      <button
        className="mapPropertiesItem"
        style={{ width: "100%", justifyContent: "center" }}
        onClick={onAddText}
      >
        {props.languageChosenSite === "EL"
                    ? languageGr.indoor.add
                    : language.indoor.add}
      </button>
    </div>
  );
}

export default Text;
