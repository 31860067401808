import React, { useState, useEffect } from "react";

//Language values
import Greek from "../Languages/Greek.json";
import English from "../Languages/English.json";

//Icon flags
import GreekFlag from "../Icons/greece-flag.png"
import UnitedStatesFlag from "../Icons/united-states-flag.png"
import XenagosLogo from "../Icons/xenagos-logo.png"

//Bootstrap
import { Container, Nav, Navbar, Image, Button, Row, Col } from 'react-bootstrap';
import { HashLink } from 'react-router-hash-link';

//CSS
import "./navigationbar.css"

function NavigationBar() {
    const [textLanguage, setTextLanguage] = useState(Greek);
    const [small, setSmall] = useState(false);

    useEffect(() => {
        setLanguage();
        scrollingPage();
    }, []);

    const scrollingPage = () => {
        if (typeof window !== "undefined") {
            window.addEventListener("scroll", () =>
                setSmall(window.pageYOffset > 0)
            );
        }
    }

    const changeLangToEN = () => {
        sessionStorage.setItem("languageChosen", "EN");
        window.location.reload();
    };

    const changeLangToEL = () => {
        sessionStorage.setItem("languageChosen", "EL");
        window.location.reload();
    };

    const setLanguage = () => {
        sessionStorage.languageChosen === "EN"
            ? setTextLanguage(English)
            : setTextLanguage(Greek);
    };

    return (
        <Navbar expand="lg" className="navigation-bar" style={small ? {
            position: 'fixed',
            top: '0',
        } : { position: 'relative' }}>
            <Container >
                <Navbar.Brand href="/front">
                    <Image
                        src={XenagosLogo}
                        alt="xenagos-logo"
                    />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
                    <Nav className='align-self-center text-center '>
                        <Nav.Link>
                            <HashLink to="./">{textLanguage.navigation.home}</HashLink>
                        </Nav.Link>
                        <Nav.Link>
                            <HashLink to="./#events">{textLanguage.navigation.events}</HashLink>
                        </Nav.Link>
                        <Nav.Link>
                            <HashLink to="./#categories">{textLanguage.navigation.categories}</HashLink>
                        </Nav.Link>
                        <Nav.Link>
                            <HashLink to="./#exhibits">{textLanguage.navigation.exhibits}</HashLink>
                        </Nav.Link>
                        <Nav.Link>
                            <HashLink to="./#exhibitions">{textLanguage.navigation.exhibitions}</HashLink>
                        </Nav.Link>
                        <Nav.Link>
                            <HashLink to="./about">{textLanguage.navigation.about}</HashLink>
                        </Nav.Link>
                    </Nav>
                    <div className='align-self-center text-center'>
                        <Image size="sm" className='mx-1' src={UnitedStatesFlag} alt="usa-flag" width="25vh" height="25vh" onClick={changeLangToEN} />
                        <Image size="sm" className='mx-1' src={GreekFlag} alt="usa-flag" width="25vh" height="25vh" onClick={changeLangToEL} />
                    </div >
                </Navbar.Collapse>
            </Container >

        </Navbar >
    )
}

export default NavigationBar